import React, { useState } from 'react'
import Search from "../Images/Icons/PNG/Search.png";
import Notification from "../../src/Images/Icons/PNG/Notification.png"
import Searchformmodal from '../Modal/Searchformmodal';
import EngageIcon from "../Images/Icons/PNG/Engage Icon.png";
import Educateicon from "../Images/Icons/PNG/Educate icon.png";
import Ensure from "../Images/Icons/PNG/Ensure.png"
import { Link, NavLink } from 'react-router-dom';
const Footer = () => {
    const [serchfrm, setSerchfrm] = useState(false)
    const handlesrchfrm = () => {
        setSerchfrm(!serchfrm)
    }
    return (
        <>
            <section className='ftr_section_main'>
                <div className="footercontainer">
                    <div className=''>
                        <ul className='navbar-nav mr-auto'>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/">
                                    <div className="iconheader" >
                                        <img src={EngageIcon} className="normalHeadIcon" alt="icon" />
                                    </div>
                                    <div className="namelnktxtppl">Engage</div>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/educate">
                                    <div className="iconheader" >
                                        <img src={Educateicon} className="normalHeadIcon" alt="icon" />
                                    </div>
                                    <div className="namelnktxtppl">Educate</div>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/ensure">
                                    <div className="iconheader" >
                                        <img src={Ensure} className="normalHeadIcon" alt="icon" />
                                    </div>
                                    <div className="namelnktxtppl">Ensure</div>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link">
                                    <div className="iconheader" onClick={handlesrchfrm} >
                                        <img src={Search} className="normalHeadIcon" alt="icon" />
                                    </div>
                                    <div className="namelnktxtppl">Search</div>
                                </NavLink>
                            </li>
                            <div className="notiBtn">
                                <Link to="/" className="nav-link">
                                    <div className="iconheader">
                                        <img
                                            src={Notification}
                                            className="img-fluid normalHeadIcon"
                                            alt="icon"
                                        />

                                        {/* <div className="red_dot"></div> */}

                                    </div>
                                    <div className="namelnktxtppl">Notification</div>
                                </Link>
                            </div>
                        </ul>
                    </div>
                </div>
            </section>
            {serchfrm && <Searchformmodal closemodal={setSerchfrm} />}
        </>
    )
}

export default Footer
