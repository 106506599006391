import React, { useEffect, useRef, useState } from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png"
import HttpClientXml from '../../utils/HttpClientXml';
import HttpClient from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';


const Principlesix = () => {
  const [text, setText] = useState('');
  const divRef = useRef(null);
  const [principleOneData, setPrincipleOneData] = useState({});

  const initvalue = {
    r_totalElectricityConsumtion: {
      unitOfmeasurement: "",
      currentYear: "",
      previousYear: ""
    },
    r_totalFualConsumtion: {
      unitOfmeasurement: "",
      currentYear: "",
      previousYear: ""
    },
    r_energyConsumtionOtherSource: {
      unitOfmeasurement: "",
      currentYear: "",
      previousYear: ""
    },
    r_total: {
      unitOfmeasurement: "",
      currentYear: "",
      previousYear: ""
    },

    n_totalElectricityConsumtion: {
      unitOfmeasurement: "",
      currentYear: "",
      previousYear: ""
    },
    n_totalFualConsumtion: {
      unitOfmeasurement: "",
      currentYear: "",
      previousYear: ""
    },
    n_energyConsumtionOtherSource: {
      unitOfmeasurement: "",
      currentYear: "",
      previousYear: ""
    },
    n_total: {
      unitOfmeasurement: "",
      currentYear: "",
      previousYear: ""
    },

    totalEnergyConsumed: {
      unitOfmeasurement: "",
      currentYear: "",
      previousYear: ""
    },
    energyIntensityPerRupees: {
      unitOfmeasurement: "",
      currentYear: "",
      previousYear: ""
    },
    EnergyIntensityPerRupeesForPPP: {
      unitOfmeasurement: "",
      currentYear: "",
      previousYear: ""
    },
    energyIntensityIntermOfPhysicalOutput: {
      unitOfmeasurement: "",
      currentYear: "",
      previousYear: ""
    },
    optionalEnergyIntensity: {
      unitOfmeasurement: "",
      currentYear: "",
      previousYear: ""
    },

    details: "",
    isExternalAgency: "Yes", // [Yes/No]
    isExternalAgencyDetails: "",

    entitySiteDetails: { //2
      isEntitySite: "Yes", //Yes/No,
      isEntitySiteDetails: ""
    },

    disclosuresRelatedToWater: {
      surfaceWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      groundWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      thirdPartyWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      seawaterOrDesalinatedWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      others: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      totalVolumeWaterWithdrawal: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      totalVolumeWaterConsumption: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      turnOverOfwaterIntensity: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      waterIntensityPerRupeesForPPP: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },

      energyIntensityTurnOver: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      energyIntensityPPP: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      waterIntensityIntermOfPhysicalOutput: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      optionalWaterIntensity: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      impression: "",
      isExternalAgency: "Yes", // [Yes/No]
      isExternalAgencyDetails: ""
    },
    dischargedRelatedToWater: { // 4
      surfaceWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      noTreatmentOfSurfaceWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      withTreatmentOfSurfaceWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      toGroundWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      noTreatmentOfGroundWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      withTreatmentOfGroundWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      toSeawater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      noTreatmentOfSeawater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      withTreatmentOfSeawater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      toSpentToThirdParties: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      noTreatmentOfSpentToThirdParties: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      withTreatmentOfSpentToThirdParties: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      others: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      noTreatmentOfOthers: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      withTreatmentOfOthers: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      totalWaterDischarged: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      isExternalAgency: "Yes", // [Yes/No]
      isExternalAgencyDetails: ""
    },
    zeroLiquidDischarge: { //5
      status: true,
      details: ""
    },
    airEmissionsDetails: {
      NOx: {
        unit: "",
        currentYear: "",
        previousYear: ""
      },
      SOx: {
        unit: "",
        currentYear: "",
        previousYear: ""
      },
      particulateMatter: {
        unit: "",
        currentYear: "",
        previousYear: ""
      },
      persistentOrganicPollutants: {
        unit: "",
        currentYear: "",
        previousYear: ""
      },
      volatileOrganicCompounds: {
        unit: "",
        currentYear: "",
        previousYear: ""
      },
      hazardousAirPollutants: {
        unit: "",
        currentYear: "",
        previousYear: ""
      },
      others: {
        unit: "",
        currentYear: "",
        previousYear: ""
      },
      details: "",
      isExternalAgency: "Yes",
      isExternalAgencyDetails: ""
    },
    greenhouseGasEmissionsDetails: { //7
      totalScopeOneEmissions: {
        unit: "",
        currentYear: "",
        previousYear: ""
      },
      totalScopeTwoEmissions: {
        unit: "",
        currentYear: "",
        previousYear: ""
      },
      totalOfOneAndTwoScope: {
        unit: "",
        currentYear: "",
        previousYear: ""
      },

      oneAndTwoScopeAdjusted: {
        unit: "",
        currentYear: "",
        previousYear: ""
      },
      forPurchasingPowerParity: {
        unit: "",
        currentYear: "",
        previousYear: ""
      },
      termsOfPhysicalOutputOneAndTwoScope: {
        unit: "",
        currentYear: "",
        previousYear: ""
      },
      emissionIntesityOfScopeOneAndtwo: {
        unit: "",
        currentYear: "",
      },
      isExternalAgency: "Yes",
      isExternalAgencyDetails: ""
    },


    greenhouseGasEmissionsExternalAgncy: { //8
      status: false,
      details: ""
    },


    plasticWaste: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    eWaste: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    bioMedicalWaste: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    constructionAndDemolitionWaste: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    batteryWaste: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    radioActiveWaste: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    internalScrap: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    otherHazardousWaste: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    otherNonHazardousWaste: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    overallTotal: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    wasteIntensityPerRupee: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    wasteIntensityPerRupeeForPPP: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    wasteIntensityIntermsOfPhysicalOutput: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    optionalWasteIntensity: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },

    recycled: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    reused: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    otherRecoveryOpearation: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    cat_total: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },

    incineration: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    landfilling: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    otherDisposalOpearation: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },
    was_total: {
      unit: "",
      currentYear: "",
      previousYear: ""
    },

    was_isExternalAgency: "Yes",
    was_isExternalAgencyDetails: "",
    was_details: "",
    briflyDescribeOfWasteManagement: "",
    ecologicallySensitiveAreas: {
      impression: ""
    },

    entityComplaintRule: {
      isEntityComplaint: "Yes", //[Yes/No]
      details: ""
    },


    nameOfTheArea: "",
    natureOfOpearation: "",

    waterWithdrawalDetails: {
      surfaceWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      groundWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      thirdPartWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      seawater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      others: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      totalWithdrawalWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      totalConsumptionWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      waterConsumedTurnOver: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      optionalWaterIntensity: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      }
    },
    waterDischargedByDestination: {
      surfaceWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      noTreatmentSurfaceWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      withTreatmentSurfaceWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      groundWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      noTreatmentGroundWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      withTreatmentGroundWater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      seawater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      noTreatmentSeawater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      withTreatmentSeawater: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      thiedParties: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      noTreatmentThiedParties: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      withTreatmentThiedParties: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      others: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      noTreatmentOthers: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      withTreatmentOthers: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      totalWaterDischarged: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      }
    },

    isAvailable: "Yes",
    water_name: "",
    firstnote: "",

    detailsOfTotalScope: {
      totalScopeEmissions: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      totalScopeEmissionPerRupee: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      totalScopeEmissionOptionalIntensity: {
        unitOfmeasurement: "",
        currentYear: "",
        previousYear: ""
      },
      isExternalAgency: "Yes",
      details: ""
    },
    biodiversityImpactDetails: "",
    disasterManagementPlanDetails: "",
    motitgationOrAdaptionMeasureTakenDetails: "",
    percentageOfEnvironmentalImpactsDetails: ""

  }

  const initentityDetails = {
    sNo: "",
    location: "",
    typeOfOpration: "",
    condittionOfApproval: ""
  }

  const initentityDetails1 = {
    name: "",
    eiaNotifiactionNo: "",
    date: "",
    externalAgencyStatus: "",
    communicatedResultInPublic: "",
    releventWeblink: ""
  }

  const initentityDetails2 = {
    sNo: "",
    specifyingTheRegulation: "",
    noncomplianceDetails: "",
    fines: "",
    correctiveActonTaked: ""
  }

  const initentityDetails3 = {
    sNo: 0,
    intiativeUndertaken: "",
    weblink: "",
    outComeOfInitiative: ""
  }

  const [formvalue, setformvalue] = useState(initvalue);
  const [entityDetails, setentityDetails] = useState([initentityDetails]);
  const [entityDetails1, setentityDetails1] = useState([initentityDetails1]);
  const [entityDetails2, setentityDetails2] = useState([initentityDetails2]);
  const [entityDetails3, setentityDetails3] = useState([initentityDetails3]);
  const [notemodal1, setnotemodal1] = useState(false);
  const [notemodal2, setnotemodal2] = useState(false);
  // const [notemodal3, setnotemodal3] = useState(false);
  const [notemodal4, setnotemodal4] = useState(false);
  const [notemodal5, setnotemodal5] = useState(false);
  const [notemodal7, setnotemodal7] = useState(false)
  const [currentFY, setCurrentFY] = useState("");
  const [previousFY, setPreviousFY] = useState("");

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startMonth = 4; // April is the start of the financial year

    if (currentDate.getMonth() + 1 >= startMonth) {
      setCurrentFY(`${currentYear}-${currentYear + 1}`);
      setPreviousFY(`${currentYear - 1}-${currentYear}`);
    } else {
      setCurrentFY(`${currentYear - 1}-${currentYear}`);
      setPreviousFY(`${currentYear - 2}-${currentYear - 1}`);
    }

  }, []);

  useEffect(() => {
    getPrincipleOne();
  }, [])

  //for getting principleone
  const getPrincipleOne = async () => {
    const res = await HttpClientXml?.requestData(`get-principle`, {}, "GET");
    if (res?.status) {
      setPrincipleOneData(res?.data?.[0]);
    }
  }


  //for Input fields
  const handleInput = (category, field, value) => {
    setformvalue((prevValue) => ({
      ...prevValue,
      [category]: {
        ...prevValue[category],
        [field]: value,
      }
    }));
  }


  //for input fields
  const handleInputChange = (e, section, year, field) => {
    const value = e.currentTarget.textContent;
    setformvalue(prevState => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [year]: {
          ...prevState[section][year],
          [field]: value
        }
      }
    }));
  };


  //handle change for entityDetails add more functionality
  const handleChangeentity = (i, e, field) => {
    let newFormValues = [...entityDetails];
    newFormValues[i][field] = e.currentTarget.textContent;
    setentityDetails(newFormValues);
  };

  //for entityDetails first add more functionality 
  const addFormFieldsentityDetails = () => {
    setentityDetails([...entityDetails, initentityDetails]);
  };

  //for removing add more field
  const removeFormFieldsentityDetails = (i) => {
    let newFormValues = [...entityDetails];
    newFormValues.splice(i, 1);
    setentityDetails(newFormValues);
  };



  //handle change for entityDetails add more functionality
  const handleChangeentity1 = (i, e, field) => {
    let newFormValues = [...entityDetails1];
    newFormValues[i][field] = e.currentTarget.textContent;
    setentityDetails1(newFormValues);
  };

  //for entityDetails first add more functionality 
  const addFormFieldsentityDetails1 = () => {
    setentityDetails1([...entityDetails1, initentityDetails1]);
  };

  //for removing add more field
  const removeFormFieldsentityDetails1 = (i) => {
    let newFormValues = [...entityDetails1];
    newFormValues.splice(i, 1);
    setentityDetails1(newFormValues);
  };


  //handle change for entityDetails add more functionality
  const handleChangeentity2 = (i, e, field) => {
    let newFormValues = [...entityDetails2];
    newFormValues[i][field] = e.currentTarget.textContent;
    setentityDetails2(newFormValues);
  };

  //for entityDetails first add more functionality 
  const addFormFieldsentityDetails2 = () => {
    setentityDetails2([...entityDetails2, initentityDetails2]);
  };

  //for removing add more field
  const removeFormFieldsentityDetails2 = (i) => {
    let newFormValues = [...entityDetails2];
    newFormValues.splice(i, 1);
    setentityDetails2(newFormValues);
  };



  //handle change for entityDetails add more functionality
  const handleChangeentity3 = (i, e, field) => {
    let newFormValues = [...entityDetails3];
    newFormValues[i][field] = e.currentTarget.textContent;
    setentityDetails3(newFormValues);
  };


  //for entityDetails first add more functionality 
  const addFormFieldsentityDetails3 = () => {
    setentityDetails3([...entityDetails3, initentityDetails3]);
  };


  //for removing add more field
  const removeFormFieldsentityDetails3 = (i) => {
    let newFormValues = [...entityDetails3];
    newFormValues.splice(i, 1);
    setentityDetails3(newFormValues);
  };

  const getPdf = async () => {
    const res = await HttpClient?.requestData(`all-principle-pdf`, {}, "GET");
    console.log("downloadurl", res)
    if (res?.status) {
      // setDownloadurl(res?.data);
      downloadPdf(res?.data)
    }
  }

  // console.log("downloadurl",downloadurl)
  const downloadPdf = (downloadurl) => {
    const link = document.createElement('a');
    link.href = downloadurl;
    link.download = 'all.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const submitHandler = async (e) => {
    e.preventDefault();
    let data = {};
    data = {
      "principleSix": {
        "currentYear": currentFY,
        "previousYear": previousFY,
        "essentialIndicators": {
          "detailsOfTotalEnergy": {
            "fromRenewableSources": {
              "totalElectricityConsumtion": {
                "unitOfmeasurement": formvalue?.r_totalElectricityConsumtion?.unitOfmeasurement,
                "currentYear": formvalue?.r_totalElectricityConsumtion?.currentYear,
                "previousYear": formvalue?.r_totalElectricityConsumtion?.previousYear
              },
              "totalFualConsumtion": {
                "unitOfmeasurement": formvalue?.r_totalFualConsumtion?.unitOfmeasurement,
                "currentYear": formvalue?.r_totalFualConsumtion?.currentYear,
                "previousYear": formvalue?.r_totalFualConsumtion?.previousYear
              },
              "energyConsumtionOtherSource": {
                "unitOfmeasurement": formvalue?.r_energyConsumtionOtherSource?.unitOfmeasurement,
                "currentYear": formvalue?.r_energyConsumtionOtherSource?.currentYear,
                "previousYear": formvalue?.r_energyConsumtionOtherSource?.previousYear
              },
              "total": {
                "unitOfmeasurement": formvalue?.r_total?.unitOfmeasurement,
                "currentYear": formvalue?.r_total?.currentYear,
                "previousYear": formvalue?.r_total?.previousYear
              }
            },
            "fromNonRenewableSources": {
              "totalElectricityConsumtion": {
                "unitOfmeasurement": formvalue?.n_totalElectricityConsumtion?.unitOfmeasurement,
                "currentYear": formvalue?.n_totalElectricityConsumtion?.currentYear,
                "previousYear": formvalue?.n_totalElectricityConsumtion?.previousYear
              },
              "totalFualConsumtion": {
                "unitOfmeasurement": formvalue?.n_totalFualConsumtion?.unitOfmeasurement,
                "currentYear": formvalue?.n_totalFualConsumtion?.currentYear,
                "previousYear": formvalue?.n_totalFualConsumtion?.previousYear
              },
              "energyConsumtionOtherSource": {
                "unitOfmeasurement": formvalue?.n_energyConsumtionOtherSource?.unitOfmeasurement,
                "currentYear": formvalue?.n_energyConsumtionOtherSource?.currentYear,
                "previousYear": formvalue?.n_energyConsumtionOtherSource?.previousYear
              },
              "total": {
                "unitOfmeasurement": formvalue?.n_total?.unitOfmeasurement,
                "currentYear": formvalue?.n_total?.currentYear,
                "previousYear": formvalue?.n_total?.previousYear
              }
            },
            "totalEnergyConsumed": {
              "unitOfmeasurement": formvalue?.totalEnergyConsumed?.unitOfmeasurement,
              "currentYear": formvalue?.totalEnergyConsumed?.currentYear,
              "previousYear": formvalue?.totalEnergyConsumed?.previousYear
            },
            "energyIntensityPerRupees": {
              "unitOfmeasurement": formvalue?.energyIntensityPerRupees?.unitOfmeasurement,
              "currentYear": formvalue?.energyIntensityPerRupees?.currentYear,
              "previousYear": formvalue?.energyIntensityPerRupees?.previousYear
            },
            "EnergyIntensityPerRupeesForPPP": {
              "unitOfmeasurement": formvalue?.EnergyIntensityPerRupeesForPPP?.unitOfmeasurement,
              "currentYear": formvalue?.EnergyIntensityPerRupeesForPPP?.currentYear,
              "previousYear": formvalue?.EnergyIntensityPerRupeesForPPP?.previousYear
            },
            "energyIntensityIntermOfPhysicalOutput": {
              "unitOfmeasurement": formvalue?.energyIntensityIntermOfPhysicalOutput?.unitOfmeasurement,
              "currentYear": formvalue?.energyIntensityIntermOfPhysicalOutput?.currentYear,
              "previousYear": formvalue?.energyIntensityIntermOfPhysicalOutput?.previousYear
            },
            "optionalEnergyIntensity": {
              "unitOfmeasurement": formvalue?.optionalEnergyIntensity?.unitOfmeasurement,
              "currentYear": formvalue?.optionalEnergyIntensity?.currentYear,
              "previousYear": formvalue?.optionalEnergyIntensity?.previousYear
            },
            "details": formvalue?.details,
            "isExternalAgency": formvalue?.isExternalAgency, // [Yes/No]
            "isExternalAgencyDetails": formvalue?.isExternalAgencyDetails
          },
          "entitySiteDetails": { //2
            "isEntitySite": formvalue?.entitySiteDetails?.isEntitySite, //Yes/No,
            "isEntitySiteDetails": formvalue?.entitySiteDetails?.isEntitySiteDetails
          },
          "disclosuresRelatedToWater": {
            "surfaceWater": {
              "unitOfmeasurement": formvalue?.disclosuresRelatedToWater?.surfaceWater?.unitOfmeasurement,
              "currentYear": formvalue?.disclosuresRelatedToWater?.surfaceWater?.currentYear,
              "previousYear": formvalue?.disclosuresRelatedToWater?.surfaceWater?.previousYear
            },
            "groundWater": {
              "unitOfmeasurement": formvalue?.disclosuresRelatedToWater?.groundWater?.unitOfmeasurement,
              "currentYear": formvalue?.disclosuresRelatedToWater?.groundWater?.currentYear,
              "previousYear": formvalue?.disclosuresRelatedToWater?.groundWater?.previousYear
            },
            "thirdPartyWater": {
              "unitOfmeasurement": formvalue?.disclosuresRelatedToWater?.thirdPartyWater?.unitOfmeasurement,
              "currentYear": formvalue?.disclosuresRelatedToWater?.thirdPartyWater?.currentYear,
              "previousYear": formvalue?.disclosuresRelatedToWater?.thirdPartyWater?.previousYear
            },
            "seawaterOrDesalinatedWater": {
              "unitOfmeasurement": formvalue?.disclosuresRelatedToWater?.seawaterOrDesalinatedWater?.unitOfmeasurement,
              "currentYear": formvalue?.disclosuresRelatedToWater?.seawaterOrDesalinatedWater?.currentYear,
              "previousYear": formvalue?.disclosuresRelatedToWater?.seawaterOrDesalinatedWater?.previousYear
            },
            "others": {
              "unitOfmeasurement": formvalue?.disclosuresRelatedToWater?.others?.unitOfmeasurement,
              "currentYear": formvalue?.disclosuresRelatedToWater?.others?.currentYear,
              "previousYear": formvalue?.disclosuresRelatedToWater?.others?.previousYear
            },
            "totalVolumeWaterWithdrawal": {
              "unitOfmeasurement": formvalue?.disclosuresRelatedToWater?.totalVolumeWaterWithdrawal?.unitOfmeasurement,
              "currentYear": formvalue?.disclosuresRelatedToWater?.totalVolumeWaterWithdrawal?.currentYear,
              "previousYear": formvalue?.disclosuresRelatedToWater?.totalVolumeWaterWithdrawal?.previousYear
            },
            "totalVolumeWaterConsumption": {
              "unitOfmeasurement": formvalue?.disclosuresRelatedToWater?.totalVolumeWaterConsumption?.unitOfmeasurement,
              "currentYear": formvalue?.disclosuresRelatedToWater?.totalVolumeWaterConsumption?.currentYear,
              "previousYear": formvalue?.disclosuresRelatedToWater?.totalVolumeWaterConsumption?.previousYear
            },
            "turnOverOfwaterIntensity": {
              "unitOfmeasurement": formvalue?.disclosuresRelatedToWater?.turnOverOfwaterIntensity?.unitOfmeasurement,
              "currentYear": formvalue?.disclosuresRelatedToWater?.turnOverOfwaterIntensity?.currentYear,
              "previousYear": formvalue?.disclosuresRelatedToWater?.turnOverOfwaterIntensity?.previousYear
            },
            "waterIntensityPerRupeesForPPP": {
              "unitOfmeasurement": formvalue?.disclosuresRelatedToWater?.waterIntensityPerRupeesForPPP?.unitOfmeasurement,
              "currentYear": formvalue?.disclosuresRelatedToWater?.waterIntensityPerRupeesForPPP?.currentYear,
              "previousYear": formvalue?.disclosuresRelatedToWater?.waterIntensityPerRupeesForPPP?.previousYear
            },
            "energyIntensityTurnOver": {
              "unitOfmeasurement": formvalue?.disclosuresRelatedToWater?.energyIntensityTurnOver?.unitOfmeasurement,
              "currentYear": formvalue?.disclosuresRelatedToWater?.energyIntensityTurnOver?.currentYear,
              "previousYear": formvalue?.disclosuresRelatedToWater?.energyIntensityTurnOver?.previousYear
            },
            "energyIntensityPPP": {
              "unitOfmeasurement": formvalue?.disclosuresRelatedToWater?.energyIntensityPPP?.unitOfmeasurement,
              "currentYear": formvalue?.disclosuresRelatedToWater?.energyIntensityPPP?.currentYear,
              "previousYear": formvalue?.disclosuresRelatedToWater?.energyIntensityPPP?.previousYear
            },

            "waterIntensityTermsOfPhysicalOutput": {
              "unitOfmeasurement": formvalue?.disclosuresRelatedToWater?.waterIntensityIntermOfPhysicalOutput?.unitOfmeasurement,
              "currentYear": formvalue?.disclosuresRelatedToWater?.waterIntensityIntermOfPhysicalOutput?.currentYear,
              "previousYear": formvalue?.disclosuresRelatedToWater?.waterIntensityIntermOfPhysicalOutput?.previousYear
            },
            "optionalWaterIntensity": {
              "unitOfmeasurement": formvalue?.disclosuresRelatedToWater?.optionalWaterIntensity?.unitOfmeasurement,
              "currentYear": formvalue?.disclosuresRelatedToWater?.optionalWaterIntensity?.currentYear,
              "previousYear": formvalue?.disclosuresRelatedToWater?.optionalWaterIntensity?.previousYear
            },
            "impression": formvalue?.disclosuresRelatedToWater?.impression,
            "isExternalAgency": formvalue?.disclosuresRelatedToWater?.isExternalAgency, // [Yes/No]
            "isExternalAgencyDetails": formvalue?.disclosuresRelatedToWater?.isExternalAgencyDetails
          },
          "dischargedRelatedToWater": { // 4
            "surfaceWater": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.surfaceWater?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.surfaceWater?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.surfaceWater?.previousYear
            },
            "noTreatmentOfSurfaceWater": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.noTreatmentOfSurfaceWater?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.noTreatmentOfSurfaceWater?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.noTreatmentOfSurfaceWater?.previousYear
            },
            "withTreatmentOfSurfaceWater": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.withTreatmentOfSurfaceWater?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.withTreatmentOfSurfaceWater?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.withTreatmentOfSurfaceWater?.previousYear
            },
            "toGroundWater": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.toGroundWater?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.toGroundWater?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.toGroundWater?.previousYear
            },
            "noTreatmentOfGroundWater": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.noTreatmentOfGroundWater?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.noTreatmentOfGroundWater?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.noTreatmentOfGroundWater?.previousYear
            },
            "withTreatmentOfGroundWater": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.withTreatmentOfGroundWater?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.withTreatmentOfGroundWater?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.withTreatmentOfGroundWater?.previousYear
            },
            "toSeawater": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.toSeawater?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.toSeawater?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.toSeawater?.previousYear
            },
            "noTreatmentOfSeawater": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.noTreatmentOfSeawater?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.noTreatmentOfSeawater?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.noTreatmentOfSeawater?.previousYear
            },
            "withTreatmentOfSeawater": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.withTreatmentOfSeawater?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.withTreatmentOfSeawater?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.withTreatmentOfSeawater?.previousYear
            },
            "toSpentToThirdParties": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.toSpentToThirdParties?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.toSpentToThirdParties?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.toSpentToThirdParties?.previousYear
            },
            "noTreatmentOfSpentToThirdParties": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.noTreatmentOfSpentToThirdParties?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.noTreatmentOfSpentToThirdParties?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.noTreatmentOfSpentToThirdParties?.previousYear
            },
            "withTreatmentOfSpentToThirdParties": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.withTreatmentOfSpentToThirdParties?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.withTreatmentOfSpentToThirdParties?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.withTreatmentOfSpentToThirdParties?.previousYear
            },
            "others": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.others?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.others?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.others?.previousYear
            },
            "noTreatmentOfOthers": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.noTreatmentOfOthers?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.noTreatmentOfOthers?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.noTreatmentOfOthers?.previousYear
            },
            "withTreatmentOfOthers": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.withTreatmentOfOthers?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.withTreatmentOfOthers?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.withTreatmentOfOthers?.previousYear
            },
            "totalWaterDischarged": {
              "unitOfmeasurement": formvalue?.dischargedRelatedToWater?.totalWaterDischarged?.unitOfmeasurement,
              "currentYear": formvalue?.dischargedRelatedToWater?.totalWaterDischarged?.currentYear,
              "previousYear": formvalue?.dischargedRelatedToWater?.totalWaterDischarged?.previousYear
            },
            "isExternalAgency": formvalue?.dischargedRelatedToWater?.isExternalAgency, // [Yes/No]
            "isExternalAgencyDetails": formvalue?.dischargedRelatedToWater?.isExternalAgencyDetails
          },
          "zeroLiquidDischarge": { //5
            "status": formvalue?.zeroLiquidDischarge?.status,
            "details": formvalue?.zeroLiquidDischarge?.details
          },
          "airEmissionsDetails": {
            "NOx": {
              "unit": formvalue?.airEmissionsDetails?.NOx?.unit,
              "currentYear": formvalue?.airEmissionsDetails?.NOx?.currentYear,
              "previousYear": formvalue?.airEmissionsDetails?.NOx?.previousYear
            },
            "SOx": {
              "unit": formvalue?.airEmissionsDetails?.SOx?.unit,
              "currentYear": formvalue?.airEmissionsDetails?.SOx?.currentYear,
              "previousYear": formvalue?.airEmissionsDetails?.SOx?.previousYear
            },
            "particulateMatter": {
              "unit": formvalue?.airEmissionsDetails?.particulateMatter?.unit,
              "currentYear": formvalue?.airEmissionsDetails?.particulateMatter?.currentYear,
              "previousYear": formvalue?.airEmissionsDetails?.particulateMatter?.previousYear
            },
            "persistentOrganicPollutants": {
              "unit": formvalue?.airEmissionsDetails?.persistentOrganicPollutants?.unit,
              "currentYear": formvalue?.airEmissionsDetails?.persistentOrganicPollutants?.currentYear,
              "previousYear": formvalue?.airEmissionsDetails?.persistentOrganicPollutants?.previousYear
            },
            "volatileOrganicCompounds": {
              "unit": formvalue?.airEmissionsDetails?.volatileOrganicCompounds?.unit,
              "currentYear": formvalue?.airEmissionsDetails?.volatileOrganicCompounds?.currentYear,
              "previousYear": formvalue?.airEmissionsDetails?.volatileOrganicCompounds?.previousYear
            },
            "hazardousAirPollutants": {
              "unit": formvalue?.airEmissionsDetails?.hazardousAirPollutants?.unit,
              "currentYear": formvalue?.airEmissionsDetails?.hazardousAirPollutants?.currentYear,
              "previousYear": formvalue?.airEmissionsDetails?.hazardousAirPollutants?.previousYear
            },
            "others": {
              "unit": formvalue?.airEmissionsDetails?.others?.unit,
              "currentYear": formvalue?.airEmissionsDetails?.others?.currentYear,
              "previousYear": formvalue?.airEmissionsDetails?.others?.previousYear
            },
            "details": formvalue?.airEmissionsDetails?.details,
            "isExternalAgency": formvalue?.airEmissionsDetails?.isExternalAgency,
            "isExternalAgencyDetails": formvalue?.airEmissionsDetails?.isExternalAgencyDetails
          },
          "greenhouseGasEmissionsDetails": { //7
            "totalScopeOneEmissions": {
              "unit": formvalue?.greenhouseGasEmissionsDetails?.totalScopeOneEmissions?.unit,
              "currentYear": formvalue?.greenhouseGasEmissionsDetails?.totalScopeOneEmissions?.currentYear,
              "previousYear": formvalue?.greenhouseGasEmissionsDetails?.totalScopeOneEmissions?.previousYear
            },
            "totalScopeTwoEmissions": {
              "unit": formvalue?.greenhouseGasEmissionsDetails?.totalScopeTwoEmissions?.unit,
              "currentYear": formvalue?.greenhouseGasEmissionsDetails?.totalScopeTwoEmissions?.currentYear,
              "previousYear": formvalue?.greenhouseGasEmissionsDetails?.totalScopeTwoEmissions?.previousYear
            },
            "totalOfOneAndTwoScope": {
              "unit": formvalue?.greenhouseGasEmissionsDetails?.totalOfOneAndTwoScope?.unit,
              "currentYear": formvalue?.greenhouseGasEmissionsDetails?.totalOfOneAndTwoScope?.currentYear,
              "previousYear": formvalue?.greenhouseGasEmissionsDetails?.totalOfOneAndTwoScope?.previousYear
            },

            "totalOfOneAndTwoScopeAdjusted": {
              "unit": formvalue?.greenhouseGasEmissionsDetails?.oneAndTwoScopeAdjusted?.unit,
              "currentYear": formvalue?.greenhouseGasEmissionsDetails?.oneAndTwoScopeAdjusted?.currentYear,
              "previousYear": formvalue?.greenhouseGasEmissionsDetails?.oneAndTwoScopeAdjusted?.previousYear
            },
            "forPurchasingPowerParity": {
              "unit": formvalue?.greenhouseGasEmissionsDetails?.forPurchasingPowerParity?.unit,
              "currentYear": formvalue?.greenhouseGasEmissionsDetails?.forPurchasingPowerParity?.currentYear,
              "previousYear": formvalue?.greenhouseGasEmissionsDetails?.forPurchasingPowerParity?.previousYear
            },
            "termsOfPhysicalOutputOneAndTwoScope": {
              "unit": formvalue?.greenhouseGasEmissionsDetails?.termsOfPhysicalOutputOneAndTwoScope?.unit,
              "currentYear": formvalue?.greenhouseGasEmissionsDetails?.termsOfPhysicalOutputOneAndTwoScope?.currentYear,
              "previousYear": formvalue?.greenhouseGasEmissionsDetails?.termsOfPhysicalOutputOneAndTwoScope?.previousYear
            },
            "emissionIntesityOfScopeOneAndtwo": {
              "unit": formvalue?.greenhouseGasEmissionsDetails?.emissionIntesityOfScopeOneAndtwo?.unit,
              "currentYear": formvalue?.greenhouseGasEmissionsDetails?.emissionIntesityOfScopeOneAndtwo?.currentYear,
              "previousYear": formvalue?.greenhouseGasEmissionsDetails?.emissionIntesityOfScopeOneAndtwo?.previousYear
            },
            "isExternalAgency": formvalue?.greenhouseGasEmissionsDetails?.isExternalAgency,
            "isExternalAgencyDetails": formvalue?.greenhouseGasEmissionsDetails?.isExternalAgencyDetails
          },
          "greenhouseGasEmissionsExternalAgncy": { //8
            "status": formvalue?.greenhouseGasEmissionsExternalAgncy?.status,
            "details": formvalue?.greenhouseGasEmissionsExternalAgncy?.details
          },
          "wasteManagementDetails": { //9
            "totalWasteGenerate": {
              "plasticWaste": {
                "unit": formvalue?.plasticWaste?.unit,
                "currentYear": formvalue?.plasticWaste?.currentYear,
                "previousYear": formvalue?.plasticWaste?.previousYear
              },
              "eWaste": {
                "unit": formvalue?.eWaste?.unit,
                "currentYear": formvalue?.eWaste?.currentYear,
                "previousYear": formvalue?.eWaste?.previousYear
              },
              "bioMedicalWaste": {
                "unit": formvalue?.bioMedicalWaste?.unit,
                "currentYear": formvalue?.bioMedicalWaste?.currentYear,
                "previousYear": formvalue?.bioMedicalWaste?.previousYear
              },
              "constructionAndDemolitionWaste": {
                "unit": formvalue?.constructionAndDemolitionWaste?.unit,
                "currentYear": formvalue?.constructionAndDemolitionWaste?.currentYear,
                "previousYear": formvalue?.constructionAndDemolitionWaste?.previousYear
              },
              "batteryWaste": {
                "unit": formvalue?.batteryWaste?.unit,
                "currentYear": formvalue?.batteryWaste?.currentYear,
                "previousYear": formvalue?.batteryWaste?.previousYear
              },
              "radioActiveWaste": {
                "unit": formvalue?.radioActiveWaste?.unit,
                "currentYear": formvalue?.radioActiveWaste?.currentYear,
                "previousYear": formvalue?.radioActiveWaste?.previousYear
              },
              "internalScrap": {
                "unit": formvalue?.internalScrap?.unit,
                "currentYear": formvalue?.internalScrap?.currentYear,
                "previousYear": formvalue?.internalScrap?.previousYear
              },
              "otherHazardousWaste": {
                "unit": formvalue?.otherHazardousWaste?.unit,
                "currentYear": formvalue?.otherHazardousWaste?.currentYear,
                "previousYear": formvalue?.otherHazardousWaste?.previousYear
              },
              "otherNonHazardousWaste": {
                "unit": formvalue?.otherNonHazardousWaste?.unit,
                "currentYear": formvalue?.otherNonHazardousWaste?.currentYear,
                "previousYear": formvalue?.otherNonHazardousWaste?.previousYear
              },
              "overallTotal": {
                "unit": formvalue?.overallTotal?.unit,
                "currentYear": formvalue?.overallTotal?.currentYear,
                "previousYear": formvalue?.overallTotal?.previousYear
              },
              "wasteIntensityPerRupee": {
                "unit": formvalue?.wasteIntensityPerRupee?.unit,
                "currentYear": formvalue?.wasteIntensityPerRupee?.currentYear,
                "previousYear": formvalue?.wasteIntensityPerRupee?.previousYear
              },
              "wasteIntensityPerRupeeForPPP": {
                "unit": formvalue?.wasteIntensityPerRupeeForPPP?.unit,
                "currentYear": formvalue?.wasteIntensityPerRupeeForPPP?.currentYear,
                "previousYear": formvalue?.wasteIntensityPerRupeeForPPP?.previousYear
              },
              "wasteIntensityIntermsOfPhysicalOutput": {
                "unit": formvalue?.wasteIntensityIntermsOfPhysicalOutput?.unit,
                "currentYear": formvalue?.wasteIntensityIntermsOfPhysicalOutput?.currentYear,
                "previousYear": formvalue?.wasteIntensityIntermsOfPhysicalOutput?.previousYear
              },
              "optionalWasteIntensity": {
                "unit": formvalue?.optionalWasteIntensity?.unit,
                "currentYear": formvalue?.optionalWasteIntensity?.currentYear,
                "previousYear": formvalue?.optionalWasteIntensity?.previousYear
              }
            },
            "categoryOfWasteGenerated": {
              "recycled": {
                "unit": formvalue?.recycled?.unit,
                "currentYear": formvalue?.recycled?.currentYear,
                "previousYear": formvalue?.recycled?.previousYear
              },
              "reused": {
                "unit": formvalue?.reused?.unit,
                "currentYear": formvalue?.reused?.currentYear,
                "previousYear": formvalue?.reused?.previousYear
              },
              "otherRecoveryOpearation": {
                "unit": formvalue?.otherRecoveryOpearation?.unit,
                "currentYear": formvalue?.otherRecoveryOpearation?.currentYear,
                "previousYear": formvalue?.otherRecoveryOpearation?.previousYear
              },
              "total": {
                "unit": formvalue?.cat_total?.unit,
                "currentYear": formvalue?.cat_total?.currentYear,
                "previousYear": formvalue?.cat_total?.previousYear
              }
            },
            "wasteCategoryDisposed": {
              "incineration": {
                "unit": formvalue?.incineration?.unit,
                "currentYear": formvalue?.incineration?.currentYear,
                "previousYear": formvalue?.incineration?.previousYear
              },
              "landfilling": {
                "unit": formvalue?.landfilling?.unit,
                "currentYear": formvalue?.landfilling?.currentYear,
                "previousYear": formvalue?.landfilling?.previousYear
              },
              "otherDisposalOpearation": {
                "unit": formvalue?.otherDisposalOpearation?.unit,
                "currentYear": formvalue?.otherDisposalOpearation?.currentYear,
                "previousYear": formvalue?.otherDisposalOpearation?.previousYear
              },
              "total": {
                "unit": formvalue?.was_total?.unit,
                "currentYear": formvalue?.was_total?.currentYear,
                "previousYear": formvalue?.was_total?.previousYear
              }
            },
            "isExternalAgency": formvalue?.was_isExternalAgency,
            "isExternalAgencyDetails": formvalue?.was_isExternalAgencyDetails,
            "details": formvalue?.was_details
          },
          "briflyDescribeOfWasteManagement": formvalue?.briflyDescribeOfWasteManagement, // 10
          "ecologicallySensitiveAreas": { //11
            "entityDetails": entityDetails,
            "impression": formvalue?.impression
          },
          "environmentalImpactAssessments": { //12
            "entityDetails": entityDetails1
          },
          "entityComplaintRule": {
            "isEntityComplaint": formvalue?.entityComplaintRule?.isEntityComplaint, //[Yes/No]
            "details": formvalue?.entityComplaintRule?.details,
            "entityDetails": entityDetails2
          }
        },
        "leadershipIndicators": {
          "areasOfWaterStress": {
            "nameOfTheArea": formvalue?.nameOfTheArea,
            "natureOfOpearation": formvalue?.natureOfOpearation,
            "waterConsumptionDetails": {
              "waterWithdrawalDetails": {
                "surfaceWater": {
                  "unitOfmeasurement": formvalue?.waterWithdrawalDetails?.surfaceWater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterWithdrawalDetails?.surfaceWater?.currentYear,
                  "previousYear": formvalue?.waterWithdrawalDetails?.surfaceWater?.previousYear
                },
                "groundWater": {
                  "unitOfmeasurement": formvalue?.waterWithdrawalDetails?.groundWater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterWithdrawalDetails?.groundWater?.currentYear,
                  "previousYear": formvalue?.waterWithdrawalDetails?.groundWater?.previousYear
                },
                "thirdPartWater": {
                  "unitOfmeasurement": formvalue?.waterWithdrawalDetails?.thirdPartWater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterWithdrawalDetails?.thirdPartWater?.currentYear,
                  "previousYear": formvalue?.waterWithdrawalDetails?.thirdPartWater?.previousYear
                },
                "seawater": {
                  "unitOfmeasurement": formvalue?.waterWithdrawalDetails?.seawater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterWithdrawalDetails?.seawater?.currentYear,
                  "previousYear": formvalue?.waterWithdrawalDetails?.seawater?.previousYear
                },
                "others": {
                  "unitOfmeasurement": formvalue?.waterWithdrawalDetails?.others?.unitOfmeasurement,
                  "currentYear": formvalue?.waterWithdrawalDetails?.others?.currentYear,
                  "previousYear": formvalue?.waterWithdrawalDetails?.others?.previousYear
                },
                "totalWithdrawalWater": {
                  "unitOfmeasurement": formvalue?.waterWithdrawalDetails?.totalWithdrawalWater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterWithdrawalDetails?.totalWithdrawalWater?.currentYear,
                  "previousYear": formvalue?.waterWithdrawalDetails?.totalWithdrawalWater?.previousYear
                },
                "totalConsumptionWater": {
                  "unitOfmeasurement": formvalue?.waterWithdrawalDetails?.totalConsumptionWater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterWithdrawalDetails?.totalConsumptionWater?.currentYear,
                  "previousYear": formvalue?.waterWithdrawalDetails?.totalConsumptionWater?.previousYear
                },
                "waterConsumedTurnOver": {
                  "unitOfmeasurement": formvalue?.waterWithdrawalDetails?.waterConsumedTurnOver?.unitOfmeasurement,
                  "currentYear": formvalue?.waterWithdrawalDetails?.waterConsumedTurnOver?.currentYear,
                  "previousYear": formvalue?.waterWithdrawalDetails?.waterConsumedTurnOver?.previousYear
                },
                "optionalWaterIntensity": {
                  "unitOfmeasurement": formvalue?.waterWithdrawalDetails?.optionalWaterIntensity?.unitOfmeasurement,
                  "currentYear": formvalue?.waterWithdrawalDetails?.optionalWaterIntensity?.currentYear,
                  "previousYear": formvalue?.waterWithdrawalDetails?.optionalWaterIntensity?.previousYear
                }
              },
              "waterDischargedByDestination": {
                "surfaceWater": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.surfaceWater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.surfaceWater?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.surfaceWater?.previousYear
                },
                "noTreatmentSurfaceWater": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.noTreatmentSurfaceWater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.noTreatmentSurfaceWater?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.noTreatmentSurfaceWater?.previousYear
                },
                "withTreatmentSurfaceWater": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.withTreatmentSurfaceWater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.withTreatmentSurfaceWater?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.withTreatmentSurfaceWater?.previousYear
                },
                "groundWater": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.groundWater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.groundWater?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.groundWater?.previousYear
                },
                "noTreatmentGroundWater": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.noTreatmentGroundWater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.noTreatmentGroundWater?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.noTreatmentGroundWater?.previousYear
                },
                "withTreatmentGroundWater": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.withTreatmentGroundWater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.withTreatmentGroundWater?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.withTreatmentGroundWater?.previousYear
                },
                "seawater": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.seawater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.seawater?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.seawater?.previousYear
                },
                "noTreatmentSeawater": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.noTreatmentSeawater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.noTreatmentSeawater?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.noTreatmentSeawater?.previousYear
                },
                "withTreatmentSeawater": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.withTreatmentSeawater?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.withTreatmentSeawater?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.withTreatmentSeawater?.previousYear
                },
                "thiedParties": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.thiedParties?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.thiedParties?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.thiedParties?.previousYear
                },
                "noTreatmentThiedParties": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.noTreatmentThiedParties?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.noTreatmentThiedParties?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.noTreatmentThiedParties?.previousYear
                },
                "withTreatmentThiedParties": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.withTreatmentThiedParties?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.withTreatmentThiedParties?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.withTreatmentThiedParties?.previousYear
                },
                "others": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.others?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.others?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.others?.previousYear
                },
                "noTreatmentOthers": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.noTreatmentOthers?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.noTreatmentOthers?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.noTreatmentOthers?.previousYear
                },
                "withTreatmentOthers": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.withTreatmentOthers?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.withTreatmentOthers?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.withTreatmentOthers?.previousYear
                },
                "totalWaterDischarged": {
                  "unitOfmeasurement": formvalue?.waterDischargedByDestination?.totalWaterDischarged?.unitOfmeasurement,
                  "currentYear": formvalue?.waterDischargedByDestination?.totalWaterDischarged?.currentYear,
                  "previousYear": formvalue?.waterDischargedByDestination?.totalWaterDischarged?.previousYear
                }
              },
              "isAvailable": formvalue?.isAvailable,
              "name": formvalue?.water_name,
              "note": formvalue?.firstnote
            }
          },
          "detailsOfTotalScope": { //2
            "totalScopeEmissions": {
              "unitOfmeasurement": formvalue?.detailsOfTotalScope?.totalScopeEmissions?.unitOfmeasurement,
              "currentYear": formvalue?.detailsOfTotalScope?.totalScopeEmissions?.currentYear,
              "previousYear": formvalue?.detailsOfTotalScope?.totalScopeEmissions?.previousYear
            },
            "totalScopeEmissionPerRupee": {
              "unitOfmeasurement": formvalue?.detailsOfTotalScope?.totalScopeEmissionPerRupee?.unitOfmeasurement,
              "currentYear": formvalue?.detailsOfTotalScope?.totalScopeEmissionPerRupee?.currentYear,
              "previousYear": formvalue?.detailsOfTotalScope?.totalScopeEmissionPerRupee?.previousYear
            },
            "totalScopeEmissionOptionalIntensity": {
              "unitOfmeasurement": formvalue?.detailsOfTotalScope?.totalScopeEmissionOptionalIntensity?.unitOfmeasurement,
              "currentYear": formvalue?.detailsOfTotalScope?.totalScopeEmissionOptionalIntensity?.currentYear,
              "previousYear": formvalue?.detailsOfTotalScope?.totalScopeEmissionOptionalIntensity?.previousYear
            },
            "isExternalAgency": formvalue?.detailsOfTotalScope?.isExternalAgency,
            "details": formvalue?.detailsOfTotalScope?.details
          },
          "biodiversityImpactDetails": formvalue?.biodiversityImpactDetails, //3
          "specificIntiativesUndertakenDetails": {
            "entityDetails": entityDetails3
          },
          "disasterManagementPlanDetails": formvalue?.disasterManagementPlanDetails,//5
          "motitgationOrAdaptionMeasureTakenDetails": formvalue?.motitgationOrAdaptionMeasureTakenDetails,//6
          "percentageOfEnvironmentalImpactsDetails": formvalue?.percentageOfEnvironmentalImpactsDetails //7
        }
      }
    }

    // console.log('principlesix', data);
    const res = await HttpClient?.requestData(
      `update-enviroment-principle/${principleOneData?._id}`,
      data,
      "POST"
    );
    if (res?.status) {
      toast?.success("Principle-6 is added successfully");
      // navigate('/pricipletwotemp')
      setformvalue(initvalue);
      const contentEditableElements = document.querySelectorAll('[contentEditable]');
      contentEditableElements?.forEach(element => element.textContent = "");
      setentityDetails([initentityDetails]);
      setentityDetails1([initentityDetails1]);
      setentityDetails2([initentityDetails2]);
      setentityDetails3([initentityDetails3]);
      getPdf();

    } else {
      toast?.error(res?.response?.data?.message || "Something went wrong");
    }
  }

  // useEffect(()=>{
  //   getPdf();
  // },[])


  return (
    <>
      <div className='' >
        <h4 className='principletxtpp'>
          PRINCIPLE 6: Businesses should respect and make efforts to protect
          and restore the environment
        </h4>
        <div className='borderessentialin'>
          <p className='idicatrstxtpp'> Essential Indicators</p>
          <div className=''>
            <ul className='listoftblxsdivul'>
              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  Details of total energy consumption (in Joules or multiples) and energy intensity, in the
                  following format:
                </li>
                <table className='tablbrdrmain'>
                  <tr className='trdivmainbrdr'>
                    <th className='thdivmainbrdr principlesxdivpntonefrst'>
                      <p className='mnumbbrttprinvthreetxttppp'>
                        Parameter</p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonescnd'>
                      <p className='mnumbbrttprinvthreetxttppp'>
                        Unit Of measurement
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonethrd'>
                      <p className='mnumbbrttprinvthreetxttppp'>FY {currentFY} (Current
                        Financial Year)
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonefrth' >
                      <p className='mnumbbrttprinvthreetxttppp'>
                        FY {previousFY} (Previous
                        Financial Year)
                      </p>
                    </th>

                  </tr>

                  <tr >
                    <th colSpan="12" className='thdivmainbrdr'>
                      <p className='simlettxghjpemidjjlklettxp'>
                        From renewable sources
                      </p>
                    </th>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Total electricity consumption (A)
                      </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('r_totalElectricityConsumtion', 'unitOfmeasurement', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('r_totalElectricityConsumtion', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('r_totalElectricityConsumtion', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>


                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Total fuel consumption (B)
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('r_totalFualConsumtion', 'unitOfmeasurement', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('r_totalFualConsumtion', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('r_totalFualConsumtion', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Energy consumption through other
                        sources (C)

                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('r_totalFualConsumtion', 'unitOfmeasurement', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('r_totalFualConsumtion', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('r_totalFualConsumtion', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd'>
                        Total energy consumed from renewable
                        sources (A+B+C)
                      </p>

                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('r_total', 'unitOfmeasurement', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('r_total', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('r_total', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr >
                    <th colSpan="12" className='thdivmainbrdr'>
                      <p className='simlettxghjpemidjjlklettxp'>
                        From non-renewable sources
                      </p>
                    </th>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Total electricity consumption (A)
                      </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('n_totalElectricityConsumtion', 'unitOfmeasurement', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('n_totalElectricityConsumtion', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('n_totalElectricityConsumtion', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>


                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Total fuel consumption (B)
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('n_totalFualConsumtion', 'unitOfmeasurement', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('n_totalFualConsumtion', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('n_totalFualConsumtion', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Energy consumption through other
                        sources (C)

                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('n_totalFualConsumtion', 'unitOfmeasurement', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('n_totalFualConsumtion', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('n_totalFualConsumtion', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd'>
                        Total energy consumed from renewable
                        sources (A+B+C)
                      </p>

                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('n_total', 'unitOfmeasurement', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('n_total', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('n_total', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd'>
                        Total energy consumed
                        (A+B+C+D+E+F)
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('totalEnergyConsumed', 'unitOfmeasurement', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('totalEnergyConsumed', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('totalEnergyConsumed', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdrspclnewone'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-2'>
                        Energy intensity per rupee of turnover
                      </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        (Total energy consumed / Revenue from
                        operations)
                      </p>

                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('energyIntensityPerRupees', 'unitOfmeasurement', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('energyIntensityPerRupees', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('energyIntensityPerRupees', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>

                      <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-2'>
                        Energy intensity per rupee of turnover
                        adjusted for Purchasing Power Parity
                        (PPP)
                      </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        (Total energy consumed / Revenue from
                        operations adjusted for PPP)
                      </p>

                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('EnergyIntensityPerRupeesForPPP', 'unitOfmeasurement', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('EnergyIntensityPerRupeesForPPP', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('EnergyIntensityPerRupeesForPPP', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd'>

                        Energy intensity in terms of physical
                        output
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('energyIntensityIntermOfPhysicalOutput', 'unitOfmeasurement', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('energyIntensityIntermOfPhysicalOutput', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('energyIntensityIntermOfPhysicalOutput', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdrspclnewone'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Energy intensity (optional) – the relevant
                        metric may be selected by the entity
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('optionalEnergyIntensity', 'unitOfmeasurement', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('optionalEnergyIntensity', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('optionalEnergyIntensity', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                </table>

                <div className="rggsgttxbbedxgtsprijnd ">
                  <p className=" dtlsttfvdvgppphjp noiriittxoppdjoyyypp">Details :</p>
                  <div
                    ref={divRef}
                    contentEditable
                    className='textardibbrdrdivmain'
                    onInput={(e) => {
                      setformvalue({
                        ...formvalue,
                        details: e.currentTarget.textContent
                      });
                    }}
                  >
                  </div>
                </div>

                <div className="">
                  <p className='noteindicatepp mb-2'>  Note :
                    Indicate if any independent assessment/ evaluation/assurance has been carried out by an
                    external agency?
                    <span>  (Y/N) If yes, </span> name of the external agency.
                  </p>
                  <div className="yesnobtndivmain">
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value={"Yes"}
                        checked={formvalue?.isExternalAgency === "Yes"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            isExternalAgency: "Yes"
                          });
                        }}
                      />
                      <label>Yes</label>
                    </div>
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value={"No"}
                        checked={formvalue?.isExternalAgency === "No"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            isExternalAgency: "No"
                          });
                        }}
                      />
                      <label>No</label>
                    </div>
                  </div>
                  <div className="yestrxtwarea mt-2 mb-0">
                    <div
                      ref={divRef}
                      contentEditable
                      className='textardibbrdrdivmain'
                      onInput={(e) => {
                        setformvalue({
                          ...formvalue,
                          isExternalAgencyDetails: e.currentTarget.textContent
                        });
                      }}
                    >
                    </div>
                  </div>
                </div>
              </div>

              <div className="listoftblxsdivuldiv">
                <li className="listoftblxsdiv">Does the entity have any sites / facilities identified as designated consumers (DCs) under
                  the Performance, Achieve and Trade (PAT) Scheme of the Government of India? <span> (Y/N) </span> If
                  yes, disclose whether targets set under the PAT scheme have been achieved. In case targets
                  have not been achieved, provide the remedial action taken, if any</li>
                <div>
                  <div className="yesnobtndivmain">
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value="Yes"
                        checked={formvalue?.entitySiteDetails?.isEntitySite === "Yes"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            entitySiteDetails: {
                              ...formvalue.entitySiteDetails,
                              isEntitySite: "Yes"
                            }
                          });
                        }}
                      />
                      <label htmlFor>Yes</label>
                    </div>
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value="No"
                        checked={formvalue?.entitySiteDetails?.isEntitySite === "No"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            entitySiteDetails: {
                              ...formvalue.entitySiteDetails,
                              isEntitySite: "No"
                            }
                          });
                        }}
                      />
                      <label htmlFor>No</label>
                    </div>
                  </div>

                  <div className="yestrxtwarea mt-2 mb-2">
                    <div
                      ref={divRef}
                      contentEditable
                      className='textardibbrdrdivmain'
                      onInput={(e) => handleInput('entitySiteDetails', 'isEntitySiteDetails', e.currentTarget.textContent)}
                    >
                    </div>
                  </div>
                </div>
              </div>

              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  Provide details of the following disclosures related to water, in the following format
                </li>
                <table className='tablbrdrmain'>

                  <tr className='trdivmainbrdr'>
                    <th className='thdivmainbrdr principlesxdivpntonefrst'>
                      <p className='mnumbbrttprinvthreetxttppp'>
                        Parameter</p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonescnd'>
                      <p className='mnumbbrttprinvthreetxttppp'>
                        Unit Of measurement
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonethrd'>
                      <p className='mnumbbrttprinvthreetxttppp'>FY {currentFY} (Current
                        Financial Year)
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonefrth' >
                      <p className='mnumbbrttprinvthreetxttppp'>
                        FY {previousFY}(Previous
                        Financial Year)
                      </p>
                    </th>

                  </tr>

                  <tr >
                    <th colSpan="12" className='thdivmainbrdr'>
                      <p className='simlettxghjpemidjjlklettxp'>
                        Water withdrawal by source
                      </p>
                    </th>
                  </tr>


                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>(i) Surface water</p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'surfaceWater', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'surfaceWater', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'surfaceWater', 'previousYear')}
                      >
                      </div>
                    </td>


                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>(ii) Groundwater</p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'groundWater', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'groundWater', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'groundWater', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>(iii) Third party water</p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'thirdPartyWater', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'thirdPartyWater', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'thirdPartyWater', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>


                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>(iv) Seawater / desalinated water</p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'seawaterOrDesalinatedWater', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'seawaterOrDesalinatedWater', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'seawaterOrDesalinatedWater', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>(v) Others</p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'others', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'others', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'others', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'> <p className='Malettsgvsgphsmkjghhutxtppttlyd'>
                      Total volume of water withdrawal
                      (i + ii + iii + iv + v)
                    </p>

                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'totalVolumeWaterWithdrawal', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'totalVolumeWaterWithdrawal', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'totalVolumeWaterWithdrawal', 'previousYear')}
                      >
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className='tddivmainbrdrspclnewone'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd'>
                        Total volume of water consumption

                      </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'totalVolumeWaterConsumption', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'totalVolumeWaterConsumption', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'totalVolumeWaterConsumption', 'previousYear')}
                      >
                      </div>
                    </td>


                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'> <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-2'>
                      Water intensity per rupee of
                      turnover
                    </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        (Total water consumption / Revenue
                        from operations)
                      </p>

                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'turnOverOfwaterIntensity', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'turnOverOfwaterIntensity', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'turnOverOfwaterIntensity', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-2'>
                        Water intensity per rupee of
                        turnover adjusted for Purchasing
                        Power Parity (PPP)

                      </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        (Total water consumption / Revenue
                        from operations adjusted for PPP)
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'waterIntensityPerRupeesForPPP', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'waterIntensityPerRupeesForPPP', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'waterIntensityPerRupeesForPPP', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-2'>
                        Energy intensity per rupee of turnover
                      </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        (Total energy consumed / Revenue from
                        operations)
                      </p>

                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'energyIntensityTurnOver', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'energyIntensityTurnOver', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'energyIntensityTurnOver', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>

                      < p className='Malettsgvsgphsmkjghhutxtppttlyd mb-2'>
                        Energy intensity per rupee of turnover
                        adjusted for Purchasing Power Parity
                        (PPP)
                      </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        (Total energy consumed / Revenue from
                        operations adjusted for PPP)
                      </p>

                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'energyIntensityPPP', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'energyIntensityPPP', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'energyIntensityPPP', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdrspclnewone'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd'>
                        Water intensity in terms of physical
                        output
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'waterIntensityIntermOfPhysicalOutput', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'waterIntensityIntermOfPhysicalOutput', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'waterIntensityIntermOfPhysicalOutput', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>Water intensity (optional) – the
                        relevant metric may be selected by the
                        entity
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'optionalWaterIntensity', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'optionalWaterIntensity', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'disclosuresRelatedToWater', 'optionalWaterIntensity', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                </table>

                <div className="rggsgttxbbedxgtsprijnd ">
                  <p className="notetgvxtygfspopphjhsttxp" style={{ cursor: "pointer" }} onClick={() => setnotemodal1(!notemodal1)}>Note :</p>
                  {notemodal1 &&
                    <div className='yestrxtwarea mt-3 mb-0'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('disclosuresRelatedToWater', 'impression', e.currentTarget.textContent)}
                      >
                      </div>
                    </div>}
                </div>

                <div className="">
                  <p className='noteindicatepp mb-2'>  Note :
                    Indicate if any independent assessment/ evaluation/assurance has been carried out by an external
                    agency?
                    <span>  (Y/N) If yes,  </span> name of the external agency.
                  </p>
                  <div className="yesnobtndivmain">
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value="Yes"
                        checked={formvalue?.disclosuresRelatedToWater?.isExternalAgency === "Yes"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            disclosuresRelatedToWater: {
                              ...formvalue.disclosuresRelatedToWater,
                              isExternalAgency: "Yes"
                            }
                          });
                        }}
                      />
                      <label>Yes</label>
                    </div>
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value="No"
                        checked={formvalue?.disclosuresRelatedToWater?.isExternalAgency === "No"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            disclosuresRelatedToWater: {
                              ...formvalue.disclosuresRelatedToWater,
                              isExternalAgency: "No"
                            }
                          });
                        }}
                      />
                      <label>No</label>
                    </div>
                  </div>
                  <div className="yestrxtwarea mt-2 mb-0">
                    <div
                      ref={divRef}
                      contentEditable
                      className='textardibbrdrdivmain'
                      onInput={(e) => handleInput('disclosuresRelatedToWater', 'isExternalAgencyDetails', e.currentTarget.textContent)}

                    >
                    </div>
                  </div>
                </div>

              </div>

              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  Provide the following details related to water discharged
                </li>
                <table className='tablbrdrmain'>


                  <tr className='trdivmainbrdr'>
                    <th className='thdivmainbrdr principlesxdivpntonefrst'>
                      <p className='mnumbbrttprinvthreetxttppp'>
                        Parameter</p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonescnd'>
                      <p className='mnumbbrttprinvthreetxttppp'>
                        Unit Of measurement
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonethrd'>
                      <p className='mnumbbrttprinvthreetxttppp'>FY {currentFY} (Current
                        Financial Year)
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonefrth' >
                      <p className='mnumbbrttprinvthreetxttppp'>
                        FY {previousFY}(Previous
                        Financial Year)
                      </p>
                    </th>

                  </tr>

                  <tr >
                    <th colSpan="12" className='thdivmainbrdr'>
                      <p className='simlettxghjpemidjjlklettxp'>Water discharge by destination and level of treatment
                      </p>
                    </th>
                  </tr>


                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        (i) To Surface water
                      </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'surfaceWater', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'surfaceWater', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'surfaceWater', 'previousYear')}
                      >
                      </div>
                    </td>


                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp ml-2'>- No treatment</p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfSurfaceWater', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfSurfaceWater', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfSurfaceWater', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp ml-2'>- With treatment – please specify level of
                        treatment
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfSurfaceWater', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfSurfaceWater', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfSurfaceWater', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>


                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>(ii) To Groundwater</p>

                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'toGroundWater', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'toGroundWater', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'toGroundWater', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp ml-2'>- No treatment</p>

                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfGroundWater', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfGroundWater', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfGroundWater', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp ml-2'> - With treatment – please specify level of
                        treatment
                      </p>

                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfGroundWater', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfGroundWater', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfGroundWater', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>  (iii) To Seawater
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'toSeawater', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'toSeawater', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'toSeawater', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp ml-2'>- No treatment</p>

                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfSeawater', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfSeawater', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfSeawater', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdrspclnewone'>
                      <p className='Malettsgvsgphsmkjghhutxtpp ml-2'> - With treatment – please specify level of
                        treatment
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfSeawater', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfSeawater', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfSeawater', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        (iv) Sent to third-parties
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'toSpentToThirdParties', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'toSpentToThirdParties', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'toSpentToThirdParties', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp ml-2'> - No treatment</p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfSpentToThirdParties', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfSpentToThirdParties', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfSpentToThirdParties', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp ml-2'> - With treatment – please specify level of
                        treatment
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfSpentToThirdParties', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfSpentToThirdParties', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfSpentToThirdParties', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>(v) Others</p>

                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'others', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'others', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'others', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp ml-2'> - No treatment</p>

                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfOthers', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfOthers', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'noTreatmentOfOthers', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp ml-2'> - With treatment – please specify level of
                        treatment
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfOthers', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfOthers', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'withTreatmentOfOthers', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdrspclnewone'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd'>
                        Total water discharged
                      </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'totalWaterDischarged', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'totalWaterDischarged', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'dischargedRelatedToWater', 'totalWaterDischarged', 'previousYear')}
                      >
                      </div>
                    </td>


                  </tr>

                </table>
                <div className="">
                  <p className='noteindicatepp  mb-2'>  Note :
                    Indicate if any independent assessment/ evaluation/assurance has been carried out by an external
                    agency?
                    <span>  (Y/N) If yes, </span> name of the external agency.
                  </p>
                  <div className="yesnobtndivmain">
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value="Yes"
                        checked={formvalue?.dischargedRelatedToWater?.isExternalAgency === "Yes"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            dischargedRelatedToWater: {
                              ...formvalue.dischargedRelatedToWater,
                              isExternalAgency: "Yes"
                            }
                          });
                        }}
                      />
                      <label>Yes</label>
                    </div>
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value="No"
                        checked={formvalue?.dischargedRelatedToWater?.isExternalAgency === "No"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            dischargedRelatedToWater: {
                              ...formvalue.dischargedRelatedToWater,
                              isExternalAgency: "No"
                            }
                          });
                        }}
                      />
                      <label>No</label>
                    </div>
                  </div>
                  <div className="yestrxtwarea mt-2">
                    <div
                      ref={divRef}
                      contentEditable
                      className='textardibbrdrdivmain'
                      onInput={(e) => handleInput('dischargedRelatedToWater', 'isExternalAgencyDetails', e.currentTarget.textContent)}
                    >
                    </div>
                  </div>
                </div>
              </div>

              <div className="listoftblxsdivuldiv">
                <li className="listoftblxsdiv">Has the entity implemented a mechanism for Zero Liquid Discharge?  <span> If yes </span>, provide details
                  of its coverage and implementation.</li>

                <div className="yesnobtndivmain">
                  <div className="raditrbtntxtnemko">
                    <input type="radio"
                      value={true}
                      checked={formvalue?.zeroLiquidDischarge?.status === true}
                      onChange={(e) => {
                        setformvalue({
                          ...formvalue,
                          zeroLiquidDischarge: {
                            ...formvalue.zeroLiquidDischarge,
                            status: true
                          }
                        });
                      }}
                    />
                    <label>Yes</label>
                  </div>
                  <div className="raditrbtntxtnemko">
                    <input type="radio"
                      value={false}
                      checked={formvalue?.zeroLiquidDischarge?.status === false}
                      onChange={(e) => {
                        setformvalue({
                          ...formvalue,
                          zeroLiquidDischarge: {
                            ...formvalue.zeroLiquidDischarge,
                            status: false
                          }
                        });
                      }}
                    />
                    <label>No</label>
                  </div>
                </div>
                <div className="yestrxtwarea mt-2">
                  <div
                    ref={divRef}
                    contentEditable
                    className='textardibbrdrdivmain'
                    onInput={(e) => handleInput('zeroLiquidDischarge', 'details', e.currentTarget.textContent)}
                  >
                  </div>
                </div>

              </div>

              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  Please provide details of air emissions (other than GHG emissions) by the entity, in the
                  following format:
                </li>
                <table className='tablbrdrmain'>

                  <tr className='trdivmainbrdr'>
                    <th className='thdivmainbrdr principlesxdivpntonefrst'>
                      <p className='mnumbbrttprinvthreetxttppp'>
                        Parameter
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonescnd'>
                      <p className='mnumbbrttprinvthreetxttppp'>Please specify
                        unit
                      </p>
                    </th>

                    <th className='thdivmainbrdr principlesxdivpntonethrd'>
                      <p className='mnumbbrttprinvthreetxttppp'>FY {currentFY} (Current
                        Financial Year)
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonefrth' >
                      <p className='mnumbbrttprinvthreetxttppp'>FY {previousFY}(Previous
                        Financial Year)
                      </p>
                    </th>

                  </tr>




                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>NOx</p>

                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'NOx', 'unit')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'NOx', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'NOx', 'previousYear')}
                      >
                      </div>
                    </td>


                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'> SOx</p>

                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'SOx', 'unit')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'SOx', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'SOx', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>Particulate matter
                        (PM)</p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'particulateMatter', 'unit')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'particulateMatter', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'particulateMatter', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>Persistent organic
                        pollutants (POP)</p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'persistentOrganicPollutants', 'unit')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'persistentOrganicPollutants', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'persistentOrganicPollutants', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'> Volatile organic
                        compounds (VOC)
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'volatileOrganicCompounds', 'unit')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'volatileOrganicCompounds', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'volatileOrganicCompounds', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdrspclnewone'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>Hazardous air
                        pollutants (HAP)</p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'hazardousAirPollutants', 'unit')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'hazardousAirPollutants', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'hazardousAirPollutants', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdrspclnewone'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>Others – please
                        specify</p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'others', 'unit')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'others', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'airEmissionsDetails', 'others', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                </table>

                <div className="rggsgttxbbedxgtsprijnd ">
                  <p className=" dtlsttfvdvgppphjp noiriittxoppdjoyyypp">Details :</p>
                  <div
                    ref={divRef}
                    contentEditable
                    className='textardibbrdrdivmain'
                    onInput={(e) => handleInput('airEmissionsDetails', 'details', e.currentTarget.textContent)}
                  >
                  </div>
                </div>

                <div className="">
                  <p className='noteindicatepp  mb-2' >
                    Note :
                    Indicate if any independent assessment/ evaluation/assurance has been carried out by an
                    external agency?

                    <span>  (Y/N) If yes, </span> name of the external agency.
                  </p>
                  <div className="yesnobtndivmain">
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value="Yes"
                        checked={formvalue?.airEmissionsDetails?.isExternalAgency === "Yes"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            airEmissionsDetails: {
                              ...formvalue.airEmissionsDetails,
                              isExternalAgency: "Yes"
                            }
                          });
                        }}
                      />
                      <label>Yes</label>
                    </div>
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value="No"
                        checked={formvalue?.airEmissionsDetails?.isExternalAgency === "No"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            airEmissionsDetails: {
                              ...formvalue.airEmissionsDetails,
                              isExternalAgency: "No"
                            }
                          });
                        }}
                      />
                      <label>No</label>
                    </div>
                  </div>
                  <div className="yestrxtwarea mt-2">
                    <div
                      ref={divRef}
                      contentEditable
                      className='textardibbrdrdivmain'
                      onInput={(e) => handleInput('airEmissionsDetails', 'isExternalAgencyDetails', e.currentTarget.textContent)}
                    >
                    </div>
                  </div>

                </div>
              </div>

              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  Provide details of greenhouse gas emissions (Scope 1 and Scope 2 emissions) & its
                  intensity, in the following format:
                </li>
                <table className='tablbrdrmain'>

                  <tr className='trdivmainbrdr'>
                    <th className='thdivmainbrdr principlesxdivpntonefrst'>
                      <p className='mnumbbrttprinvthreetxttppp'>Parameter </p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonescnd'>
                      <p className='mnumbbrttprinvthreetxttppp'> Unit </p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonethrd'>
                      <p className='mnumbbrttprinvthreetxttppp'>FY {currentFY} (Current
                        Financial Year)</p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonefrth'>
                      <p className='mnumbbrttprinvthreetxttppp'>FY {previousFY}(Previous
                        Financial Year)</p>
                    </th>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-1'>
                        Total Scope 1 emissions
                      </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>(Break-up of the GHG into
                        CO2, CH4, N2O, HFCs, PFCs,
                        SF6, NF3, if available)
                      </p>
                    </td>

                    {/* <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>Metric tonnes
                        of CO2
                        equivalent
                      </p>
                    </td> */}

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'totalScopeOneEmissions', 'unit')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'totalScopeOneEmissions', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'totalScopeOneEmissions', 'previousYear')}
                      >
                      </div>
                    </td>


                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-1'>
                        Total Scope 2 emissions
                      </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        (Break-up of the GHG into
                        CO2, CH4, N2O, HFCs, PFCs,
                        SF6, NF3, if available)
                      </p>

                    </td>
                    {/* <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'> Metric tonnes
                        of CO2
                        equivalent
                      </p>
                    </td> */}
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'totalScopeTwoEmissions', 'unit')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'totalScopeTwoEmissions', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'totalScopeTwoEmissions', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdrspclnewone'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-1'>
                        Total Scope 1 and Scope 2
                        emission intensity per
                        rupee of turnover
                      </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        (Total Scope 1 and Scope 2
                        GHG emissions / Revenue
                        from operations)
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'totalOfOneAndTwoScope', 'unit')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'totalOfOneAndTwoScope', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'totalOfOneAndTwoScope', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd'>
                        Total Scope 1 and Scope 2
                        emission intensity per
                        rupee of turnover adjusted
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'oneAndTwoScopeAdjusted', 'unit')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'oneAndTwoScopeAdjusted', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'oneAndTwoScopeAdjusted', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-1'>
                        for Purchasing Power Parity
                        (PPP)
                      </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'> (Total Scope 1 and Scope 2
                        GHG emissions / Revenue
                        from operations adjusted for
                        PPP)
                      </p>

                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'forPurchasingPowerParity', 'unit')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'forPurchasingPowerParity', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'forPurchasingPowerParity', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdrspclnewone'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd'>
                        Total Scope 1 and Scope 2
                        emission intensity in terms
                        of physical output
                      </p>

                    </td>


                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'termsOfPhysicalOutputOneAndTwoScope', 'unit')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'termsOfPhysicalOutputOneAndTwoScope', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'termsOfPhysicalOutputOneAndTwoScope', 'previousYear')}
                      >
                      </div>
                    </td>


                  </tr>

                  <tr>
                    <td className='tddivmainbrdrspclnewone'> <p className='Malettsgvsgphsmkjghhutxtppttlyd'>
                      Total Scope 1 and Scope 2
                      emission intensity
                    </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>(optional)
                        – the relevant metric may be
                        selected by the entity
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'emissionIntesityOfScopeOneAndtwo', 'unit')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'emissionIntesityOfScopeOneAndtwo', 'currentYear')}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'greenhouseGasEmissionsDetails', 'emissionIntesityOfScopeOneAndtwo', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                </table>
                <div className="">
                  <p className='noteindicatepp  mb-2'>  Note :
                    Indicate if any independent assessment/ evaluation/assurance has been carried out by an external
                    agency?
                    <span>   (Y/N) If yes, </span> name of the external agency.

                  </p>
                  <div className="yesnobtndivmain">
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value="Yes"
                        checked={formvalue?.greenhouseGasEmissionsDetails?.isExternalAgency === "Yes"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            greenhouseGasEmissionsDetails: {
                              ...formvalue.greenhouseGasEmissionsDetails,
                              isExternalAgency: "Yes"
                            }
                          });
                        }}
                      />
                      <label>Yes</label>
                    </div>
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value="No"
                        checked={formvalue?.greenhouseGasEmissionsDetails?.isExternalAgency === "No"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            greenhouseGasEmissionsDetails: {
                              ...formvalue.greenhouseGasEmissionsDetails,
                              isExternalAgency: "No"
                            }
                          });
                        }}
                      />
                      <label>No</label>
                    </div>
                  </div>
                  <div className="yestrxtwarea mt-2">
                    <div
                      ref={divRef}
                      contentEditable
                      className='textardibbrdrdivmain'
                      onInput={(e) => handleInput('greenhouseGasEmissionsDetails', 'isExternalAgencyDetails', e.currentTarget.textContent)}
                    >
                    </div>
                  </div>
                </div>
              </div>

              <div className="listoftblxsdivuldiv">
                <li className="listoftblxsdiv">
                  Does the entity have any project related to reducing Green House Gas emission? <span> If Yes </span> ,
                  then provide details. </li>
                <div className="yesnobtndivmain">
                  <div className="raditrbtntxtnemko">
                    <input type="radio"
                      value={true}
                      checked={formvalue?.greenhouseGasEmissionsExternalAgncy?.status === true}
                      onChange={(e) => {
                        setformvalue({
                          ...formvalue,
                          greenhouseGasEmissionsExternalAgncy: {
                            ...formvalue.greenhouseGasEmissionsExternalAgncy,
                            status: true
                          }
                        });
                      }}
                    />
                    <label>Yes</label>
                  </div>
                  <div className="raditrbtntxtnemko">
                    <input type="radio"
                      value={false}
                      checked={formvalue?.greenhouseGasEmissionsExternalAgncy?.status === false}
                      onChange={(e) => {
                        setformvalue({
                          ...formvalue,
                          greenhouseGasEmissionsExternalAgncy: {
                            ...formvalue.greenhouseGasEmissionsExternalAgncy,
                            status: false
                          }
                        });
                      }}
                    />
                    <label>No</label>
                  </div>
                </div>
                <div className="yestrxtwarea mt-2">
                  <div
                    ref={divRef}
                    contentEditable
                    className='textardibbrdrdivmain'
                    onInput={(e) => handleInput('greenhouseGasEmissionsExternalAgncy', 'details', e.currentTarget.textContent)}
                  >
                  </div>
                </div>
              </div>

              {/* Some Changes  */}
              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  Provide details related to waste management by the entity, in the following format:
                </li>
                <table className='tablbrdrmain'>

                  <tr className='trdivmainbrdr'>
                    <th className='thdivmainbrdr principlesxdivpntonefrst'>
                      <p className='mnumbbrttprinvthreetxttppp'>
                        Parameter</p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonescnd'>
                      <p className='mnumbbrttprinvthreetxttppp'>
                        Unit Of measurement
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonethrd'>
                      <p className='mnumbbrttprinvthreetxttppp'>FY {currentFY} (Current
                        Financial Year)
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonefrth' >
                      <p className='mnumbbrttprinvthreetxttppp'>
                        FY {previousFY}(Previous
                        Financial Year)
                      </p>
                    </th>

                  </tr>

                  <tr className='trdivmainbrdr'>
                    <th className='thdivmainbrdr' colSpan="12">
                      <p className='simlettxghjpemidjjlklettxp'>
                        Total Waste generated (in metric tonnes)
                      </p>
                    </th>
                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Plastic waste <span style={{ fontWeight: "600" }}>(A)</span> </p>
                    </td>



                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('plasticWaste', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('plasticWaste', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('plasticWaste', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>


                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>

                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        E-waste <span style={{ fontWeight: "600" }}>(B)</span> </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('eWaste', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('eWaste', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('eWaste', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>


                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>

                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Bio-medical waste <span style={{ fontWeight: "600" }}>(C)</span> </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('bioMedicalWaste', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('bioMedicalWaste', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('bioMedicalWaste', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>

                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Construction and demolition
                        waste <span style={{ fontWeight: "600" }}>(D)</span> </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('constructionAndDemolitionWaste', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('constructionAndDemolitionWaste', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('constructionAndDemolitionWaste', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>

                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Battery waste <span style={{ fontWeight: "600" }}>(E)</span> </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('batteryWaste', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('batteryWaste', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('batteryWaste', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>

                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Radioactive waste <span style={{ fontWeight: "600" }}>(F)</span> </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('radioActiveWaste', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('radioActiveWaste', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('radioActiveWaste', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      {/* Other Hazardous waste. Please
                      specify, if any. <span> (G) </span> */}
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Internal Scrap <span style={{ fontWeight: "600" }}>(G)</span> </p>

                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('internalScrap', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('internalScrap', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('internalScrap', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      {/* Other Non-hazardous waste
                      generated <span> (H). </span> <br></br>
                      Please specify, if
                      any.
                      (Break-up by composition i.e. by
                      materials relevant to the sector) */}
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Other Hazardous waste. Please
                        specify, if any. <span style={{ fontWeight: "600" }}>(H)</span> </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('otherHazardousWaste', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('otherHazardousWaste', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('otherHazardousWaste', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      {/* Other Non-hazardous waste
                      generated <span> (H). </span> <br></br>
                      Please specify, if
                      any.
                      (Break-up by composition i.e. by
                      materials relevant to the sector) */}
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Other Non-hazardous waste
                        generated
                        Please specify,
                        if
                        any.
                        (Break-up by composition i.e. by
                        materials relevant to the sector) <span style={{ fontWeight: "600" }}>(I)</span> </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('otherNonHazardousWaste', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('otherNonHazardousWaste', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('otherNonHazardousWaste', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd'>   Total (A+B + C + D + E + F + G
                        + H + I) </p>

                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('overallTotal', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('overallTotal', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('overallTotal', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-1'>
                        Waste intensity per rupee of
                        turnover </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        (Total waste generated /
                        Revenue from operations)
                      </p>

                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('wasteIntensityPerRupee', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('wasteIntensityPerRupee', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('wasteIntensityPerRupee', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-1'>
                        Waste intensity per rupee of
                        turnover adjusted for
                        Purchasing Power Parity
                        (PPP)
                      </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>(Total waste generated /
                        Revenue from operations
                        adjusted for PPP)
                      </p>

                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('wasteIntensityPerRupeeForPPP', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('wasteIntensityPerRupeeForPPP', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('wasteIntensityPerRupeeForPPP', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'> Waste intensity in terms of
                        physical output

                      </p>

                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('wasteIntensityIntermsOfPhysicalOutput', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('wasteIntensityIntermsOfPhysicalOutput', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('wasteIntensityIntermsOfPhysicalOutput', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdrspclnewone'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-1'> Waste intensity </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>(optional) –
                        the relevant metric may be
                        selected by the entity
                      </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('optionalWasteIntensity', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('optionalWasteIntensity', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('optionalWasteIntensity', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <th className='thdivmainbrdr' colSpan="12">
                      <p className='simlettxghjpemidjjlklettxp'>
                        For each category of waste generated, total waste recovered through recycling, re-using or
                        other recovery operations (in metric tonnes)
                      </p>
                    </th>
                  </tr>

                  <tr>
                    <th className='thdivmainbrdr' colSpan="12">
                      <p className='simlettxghjpemidjjlklettxp'> Category of waste </p>
                    </th>
                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        (i) Recycled
                      </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('recycled', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('recycled', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('recycled', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>(ii) Re-used</p>

                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('reused', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('reused', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('reused', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'> (iii) Other recovery operations</p>

                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('otherRecoveryOpearation', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('otherRecoveryOpearation', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('otherRecoveryOpearation', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd '>  Total </p>

                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('cat_total', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('cat_total', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('cat_total', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <th className='thdivmainbrdr' colSpan="12">
                      <p className='simlettxghjpemidjjlklettxp'>
                        For each category of waste generated, total waste disposed by nature of disposal method (in
                        metric tonnes)

                      </p>
                    </th>
                  </tr>

                  <tr>
                    <th className='thdivmainbrdr' colSpan="12">

                      <p className='simlettxghjpemidjjlklettxp'>
                        Category of waste
                      </p>

                    </th>



                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>  (i) Incineration</p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('incineration', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('incineration', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('incineration', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'> (ii) Landfilling</p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('landfilling', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('landfilling', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('landfilling', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'> (iii) Other disposal operations</p>

                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('otherDisposalOpearation', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('otherDisposalOpearation', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('otherDisposalOpearation', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd '>  Total </p>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('was_total', 'unit', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('was_total', 'currentYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('was_total', 'previousYear', e.currentTarget.textContent)}
                      >
                      </div>
                    </td>

                  </tr>

                </table>

                <div className="rggsgttxbbedxgtsprijnd ">
                  <p className=" notetgvxtygfspopphjhsttxp" style={{ cursor: "pointer" }} onClick={() => setnotemodal2(!notemodal2)}>Note </p>
                  {notemodal2 &&
                    <div className='yestrxtwarea mt-3 mb-0'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => {
                          setformvalue({
                            ...formvalue,
                            was_details: e.currentTarget.textContent
                          });
                        }}
                      >
                      </div>
                    </div>}
                </div>

                <div className="">
                  <p className='noteindicatepp  mb-2'>  Note :
                    Indicate if any independent assessment/ evaluation/assurance has been carried out by an external
                    agency?
                    <span>   (Y/N) If yes,  </span> name of the external agency.

                  </p>
                  <div className="yesnobtndivmain">
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value={"Yes"}
                        checked={formvalue?.was_isExternalAgency === "Yes"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            was_isExternalAgency: "Yes"
                          });
                        }}
                      />
                      <label>Yes</label>
                    </div>
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value={"No"}
                        checked={formvalue?.was_isExternalAgency === "No"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            was_isExternalAgency: "No"
                          });
                        }}
                      />
                      <label>No</label>
                    </div>
                  </div>
                  <div className="yestrxtwarea mt-2">
                    <div
                      ref={divRef}
                      contentEditable
                      className='textardibbrdrdivmain'
                      onInput={(e) => {
                        setformvalue({
                          ...formvalue,
                          was_isExternalAgencyDetails: e.currentTarget.textContent
                        });
                      }}
                    >
                    </div>
                  </div>
                </div>

              </div>

              <div className="listoftblxsdivuldiv">
                <li className="listoftblxsdiv">
                  Briefly describe the waste management practices adopted in your establishments. Describe
                  the strategy adopted by your company to reduce usage of hazardous and toxic chemicals
                  in your products and processes and the practices adopted to manage such wastes.
                </li>
                <div className="yestrxtwarea">
                  <div
                    ref={divRef}
                    contentEditable
                    className='textardibbrdrdivmain'
                    onInput={(e) => {
                      setformvalue({
                        ...formvalue,
                        briflyDescribeOfWasteManagement: e.currentTarget.textContent
                      });
                    }}
                  >
                  </div>
                </div>
              </div>

              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  If the entity has operations/offices in/around ecologically sensitive areas (such as national
                  parks, wildlife sanctuaries, biosphere reserves, wetlands, biodiversity hotspots, forests,
                  coastal regulation zones etc.) where environmental approvals / clearances are required,
                  please specify details in the following format:

                </li>
                <table className='tablbrdrmain'>

                  <tr className='trdivmainbrdr'>
                    <th className='thdivmainbrdr principlesixpinielvedivfrst'>
                      <p className='mnumbbrttprinvthreetxttppp'>
                        Sl. No.
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesixpinielvedivscnd'>
                      <p className='mnumbbrttprinvthreetxttppp'>Location of
                        operations/offices
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesixpinielvedivthrd'>
                      <p className='mnumbbrttprinvthreetxttppp'> Type of
                        operations
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesixpinielvedivfrth'>
                      <p className='mnumbbrttprinvthreetxttppp'>Whether the conditions of
                        environmental approval / clearance
                        are being complied with? (Yes/No).
                        If no, the reasons thereof and
                        corrective action taken, if any
                      </p>
                    </th>

                  </tr>


                  {
                    entityDetails?.map((item, ind) => {
                      return (
                        <tr>

                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity(ind, e, "sNo")}
                            >
                            </div>
                          </td>

                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity(ind, e, "location")}
                            >
                            </div>
                          </td>
                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity(ind, e, "typeOfOpration")}
                            >
                            </div>
                          </td>
                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity(ind, e, "condittionOfApproval")}
                            >
                            </div>
                          </td>

                        </tr>
                      )
                    })
                  }


                  {/* <tr>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>

                  </tr> */}

                </table>

                <div className='notedivadmoredivflx'>
                  <div className=''>
                    <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal5(!notemodal5)}>Note  </p>
                  </div>

                  <button className='adddinciondivimg'>
                    <img src={Addicon} alt="..." onClick={addFormFieldsentityDetails} />
                    <span>Add More</span>
                  </button>
                </div>

                <div className="">
                  {notemodal5 &&
                    <div className='yestrxtwarea mt-3 mb-0'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInput('ecologicallySensitiveAreas', 'impression', e.currentTarget.textContent)}

                      >
                      </div>
                    </div>}
                </div>

              </div>


              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  Details of environmental impact assessments of projects undertaken by the entity based
                  on applicable laws, in the current financial year:

                </li>
                <table className='tablbrdrmain'>

                  <tr className='trdivmainbrdr'>
                    <th className='thdivmainbrdr princlplesixpinttwlddicfrst'>
                      <p className='mnumbbrttprinvthreetxttppp'>
                        Name and brief details of
                        project</p>
                    </th>
                    <th className='thdivmainbrdr princlplesixpinttwlddicscnd'>
                      <p className='mnumbbrttprinvthreetxttppp'> EIA
                        Notification
                        No.
                      </p>
                    </th>
                    <th className='thdivmainbrdr princlplesixpinttwlddicthrd'>
                      <p className='mnumbbrttprinvthreetxttppp'> Date</p>
                    </th>
                    <th className='thdivmainbrdrprincplethree princlplesixpinttwlddicfrth'>
                      <p className='mnumbbrttprinvthreetxttppp'> Whether
                        conducted by
                        independent
                        external agency
                        <span>  (Yes / No) </span>
                      </p>
                    </th>
                    <th className='thdivmainbrdrprincplethree princlplesixpinttwlddicffth'>
                      <p className='mnumbbrttprinvthreetxttppp'> Results
                        communicated
                        in public
                        domain
                        <span>  (Yes / No) </span>
                      </p>
                    </th>
                    <th className='thdivmainbrdr princlplesixpinttwlddicsxth'>
                      <p className='mnumbbrttprinvthreetxttppp'> Relevant
                        Web
                        link
                      </p>

                    </th>

                  </tr>

                  {
                    entityDetails1?.map((item, ind) => {
                      return (
                        <tr>

                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity1(ind, e, "name")}
                            >
                            </div>
                          </td>

                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity1(ind, e, "eiaNotifiactionNo")}
                            >
                            </div>
                          </td>
                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity1(ind, e, "date")}
                            >
                            </div>
                          </td>
                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity1(ind, e, "externalAgencyStatus")}
                            >
                            </div>
                          </td>
                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity1(ind, e, "communicatedResultInPublic")}
                            >
                            </div>
                          </td>
                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity1(ind, e, "releventWeblink")}
                            >
                            </div>
                          </td>

                        </tr>
                      )
                    })
                  }


                  {/* <tr>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>

                  </tr>

                  <tr>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>

                  </tr> */}



                </table>
                <div className="adddinciondivimg">
                  <img src={Addicon} alt="..." onClick={addFormFieldsentityDetails1} />
                  <span>Add More</span>
                </div>



              </div>

              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  Is the entity compliant with the applicable environmental law/ regulations/ guidelines in
                  India; such as the Water (Prevention and Control of Pollution) Act, Air (Prevention and
                  Control of Pollution) Act, Environment protection act and rules thereunder (Y/N).
                  <span>  If not,
                    provide details of all such non-compliances, in the following format: </span>

                </li>

                <div className="yesnobtndivmain">
                  <div className="raditrbtntxtnemko">
                    <input type="radio"
                      value="Yes"
                      checked={formvalue?.entityComplaintRule?.isEntityComplaint === "Yes"}
                      onChange={(e) => {
                        setformvalue({
                          ...formvalue,
                          entityComplaintRule: {
                            ...formvalue.entityComplaintRule,
                            isEntityComplaint: "Yes"
                          }
                        });
                      }}
                    />
                    <label>Yes</label>
                  </div>
                  <div className="raditrbtntxtnemko">
                    <input type="radio"
                      value="No"
                      checked={formvalue?.entityComplaintRule?.isEntityComplaint === "No"}
                      onChange={(e) => {
                        setformvalue({
                          ...formvalue,
                          entityComplaintRule: {
                            ...formvalue.entityComplaintRule,
                            isEntityComplaint: "No"
                          }
                        });
                      }}
                    />
                    <label>No</label>
                  </div>
                </div>

                <div className="rggsgttxbbedxgtsprijnd mb-2">
                  <p className=" dtlsttfvdvgppphjp noiriittxoppdjoyyypp ">Details :</p>
                  <div
                    ref={divRef}
                    contentEditable
                    className='textardibbrdrdivmain mt-1 mb-2'
                    onInput={(e) => handleInput('entityComplaintRule', 'details', e.currentTarget.textContent)}
                  >
                  </div>
                </div>


                <table className='tablbrdrmain'>

                  <tr className='trdivmainbrdr'>
                    <th className='thdivmainbrdr priciplethrtendivfrst'>
                      <p className='mnumbbrttprinvthreetxttppp'>S. No.</p>
                    </th>
                    <th className='thdivmainbrdr priciplethrtendivscnd'>
                      <p className='mnumbbrttprinvthreetxttppp'> Specify the law /
                        regulation /
                        guidelines which was
                        not complied with
                      </p>

                    </th>
                    <th className='thdivmainbrdr priciplethrtendivthrd'>
                      <p className='mnumbbrttprinvthreetxttppp'> Provide
                        details of
                        the noncompliance
                      </p>
                    </th>
                    <th className='thdivmainbrdr priciplethrtendivfrth'>
                      <p className='mnumbbrttprinvthreetxttppp'>Any fines /
                        penalties / action
                        taken by regulatory
                        agencies such as
                        pollution control
                        boards or by courts
                      </p>
                    </th>
                    <th className='thdivmainbrdr priciplethrtendivffth'>
                      <p className='mnumbbrttprinvthreetxttppp'> Corrective action
                        taken, if any
                      </p>
                    </th>

                  </tr>

                  {
                    entityDetails2?.map((item, ind) => {
                      return (
                        <tr>


                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity2(ind, e, "sNo")}
                            >
                            </div>
                          </td>

                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity2(ind, e, "specifyingTheRegulation")}
                            >
                            </div>
                          </td>

                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity2(ind, e, "noncomplianceDetails")}
                            >
                            </div>
                          </td>
                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity2(ind, e, "fines")}
                            >
                            </div>
                          </td>
                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity2(ind, e, "correctiveActonTaked")}
                            >
                            </div>
                          </td>

                        </tr>
                      )
                    })
                  }


                  {/* <tr>

                    <td className='tddivmainbrdr'>
                      <textarea id="" name="" rows="2" cols="10">

                      </textarea>
                    </td>
                    <td className='tddivmainbrdr'>
                      <textarea id="" name="" rows="2" cols="10">

                      </textarea>
                    </td>
                    <td className='tddivmainbrdr'>
                      <textarea id="" name="" rows="2" cols="10">

                      </textarea>
                    </td>
                    <td className='tddivmainbrdr'>
                      <textarea id="" name="" rows="2" cols="10">

                      </textarea>
                    </td>
                    <td className='tddivmainbrdr'>
                      <textarea id="" name="" rows="2" cols="10">

                      </textarea>
                    </td>


                  </tr> */}

                </table>

                <div className="adddinciondivimg">
                  <img src={Addicon} alt="..." onClick={addFormFieldsentityDetails2} />
                  <span>Add More</span>
                </div>


              </div>

            </ul>
          </div>
        </div>

        <div className='borderessentialin'>
          <p className='idicatrstxtpp'> Leadership Indicators</p>
          <div className=''>
            <ul className='listoftblxsdivul'>
              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  Water withdrawal, consumption and discharge in areas of water stress  :
                </li>

                <p className='rporschtxtpp'> For each facility / plant located in areas of water stress, provide the following information:  </p>
                <ul className=''>
                  <div className='sixdivmaiondio'>
                    <li className='listoftblxsdivsicxprivn'> Name of the area </li>
                    <div className='areatextaresdiv'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => {
                          setformvalue({
                            ...formvalue,
                            nameOfTheArea: e.currentTarget.textContent
                          });
                        }}
                      >
                      </div>
                    </div>
                  </div>

                  <div className='sixdivmaiondio'>
                    <li className='listoftblxsdivsicxprivn'> Nature of operations </li>
                    <div className='areatextaresdiv'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => {
                          setformvalue({
                            ...formvalue,
                            natureOfOpearation: e.currentTarget.textContent
                          });
                        }}
                      >
                      </div>
                    </div>
                  </div>

                  <div className='sixdivmaiondio'>
                    <li className='listoftblxsdivsicxprivn'> Water withdrawal, consumption and discharge in the following format:
                    </li>

                    <table className='tablbrdrmain'>
                      <tr className='trdivmainbrdr'>
                        <th className='thdivmainbrdr principlesxdivpntonefrst'>
                          <p className='mnumbbrttprinvthreetxttppp'>
                            Parameter</p>
                        </th>
                        <th className='thdivmainbrdr principlesxdivpntonescnd'>
                          <p className='mnumbbrttprinvthreetxttppp'>
                            Unit Of measurement
                          </p>
                        </th>
                        <th className='thdivmainbrdr principlesxdivpntonethrd'>
                          <p className='mnumbbrttprinvthreetxttppp'>FY {currentFY} (Current
                            Financial Year)
                          </p>
                        </th>
                        <th className='thdivmainbrdr principlesxdivpntonefrth' >
                          <p className='mnumbbrttprinvthreetxttppp'>
                            FY {previousFY}(Previous
                            Financial Year)
                          </p>
                        </th>

                      </tr>

                      <tr >
                        <th colSpan="12" className='thdivmainbrdr'>
                          <p className='simlettxghjpemidjjlklettxp'> Water withdrawal by source</p>
                        </th>
                      </tr>


                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp'>
                            (i) Surface water
                          </p>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'surfaceWater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'surfaceWater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'surfaceWater', 'previousYear')}

                          >
                          </div>
                        </td>


                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp'>
                            (ii) Groundwater
                          </p>
                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'groundWater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'groundWater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'groundWater', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp'>(iii) Third party water</p>
                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'thirdPartWater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'thirdPartWater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'thirdPartWater', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>


                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp'>(iv) Seawater / desalinated water</p>
                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'seawater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'seawater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'seawater', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdrspclnewone'>
                          <p className='Malettsgvsgphsmkjghhutxtpp'> (v) Others </p>
                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'others', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'others', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'others', 'previousYear')}

                          >
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp'> Total volume of water withdrawal</p>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'totalWithdrawalWater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'totalWithdrawalWater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'totalWithdrawalWater', 'previousYear')}

                          >
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp'>
                            Total volume of water consumption

                          </p>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'totalConsumptionWater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'totalConsumptionWater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'totalConsumptionWater', 'previousYear')}

                          >
                          </div>
                        </td>


                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-1'>
                            Water intensity per rupee of
                            turnover
                          </p>
                          <p className='Malettsgvsgphsmkjghhutxtpp'>
                            (Water consumed / turnover)
                          </p>

                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'waterConsumedTurnOver', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'waterConsumedTurnOver', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'waterConsumedTurnOver', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdrspclnewone'>
                          <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-1'>
                            Water intensity    </p>
                          <p className='Malettsgvsgphsmkjghhutxtpp'>(optional) – the
                            relevant metric may be selected by the
                            entity
                          </p>

                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'optionalWaterIntensity', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'optionalWaterIntensity', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterWithdrawalDetails', 'optionalWaterIntensity', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <th colSpan="12" className='thdivmainbrdr'>
                          <p className='simlettxghjpemidjjlklettxp'>
                            Water discharge by destination and level of treatment
                          </p>
                        </th>
                      </tr>

                      <tr>
                        <td className='tddivmainbrdrspclnewone'>
                          <p className='Malettsgvsgphsmkjghhutxtpp'>
                            (i) Into Surface water
                          </p>


                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'surfaceWater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'surfaceWater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'surfaceWater', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp ml-2'>
                            -No treatment
                          </p>
                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentSurfaceWater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentSurfaceWater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentSurfaceWater', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp ml-2'>  - With treatment – please specify
                            level of treatment
                          </p>
                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentSurfaceWater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentSurfaceWater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentSurfaceWater', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp'>
                            (ii) Into Groundwater
                          </p>

                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'groundWater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'groundWater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'groundWater', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp ml-2'>
                            - No treatment</p>
                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentGroundWater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentGroundWater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentGroundWater', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdrspclnewone'>
                          <p className='Malettsgvsgphsmkjghhutxtpp ml-2'> - With treatment – please specify
                            level of treatment
                          </p>
                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentGroundWater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentGroundWater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentGroundWater', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp'>
                            (iii) Into Seawater
                          </p>

                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'seawater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'seawater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'seawater', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp ml-2'>
                            - No treatment</p>
                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentSeawater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentSeawater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentSeawater', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdrspclnewone'>
                          <p className='Malettsgvsgphsmkjghhutxtpp ml-2'> - With treatment – please specify
                            level of treatment
                          </p>

                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentSeawater', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentSeawater', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentSeawater', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp'>
                            (iv) Sent to third-parties
                          </p>

                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'thiedParties', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'thiedParties', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'thiedParties', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp ml-2'>
                            - No treatment
                          </p>
                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentThiedParties', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentThiedParties', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentThiedParties', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdrspclnewone'>
                          <p className='Malettsgvsgphsmkjghhutxtpp ml-2'> - With treatment – please specify
                            level of treatment
                          </p>

                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentThiedParties', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentThiedParties', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentThiedParties', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdrspclnewone'>
                          <p className='Malettsgvsgphsmkjghhutxtpp'>
                            (v) Others</p>

                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'others', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'others', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'others', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdrspclnewone'>
                          <p className='Malettsgvsgphsmkjghhutxtpp ml-2'>
                            - No treatment
                          </p>
                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentOthers', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentOthers', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'noTreatmentOthers', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtpp ml-2'> - With treatment – please specify
                            level of treatment
                          </p>

                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentOthers', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentOthers', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'withTreatmentOthers', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                      <tr>
                        <td className='tddivmainbrdr'>
                          <p className='Malettsgvsgphsmkjghhutxtppttlyd '>
                            Total water discharged
                          </p>

                        </td>
                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'totalWaterDischarged', 'unitOfmeasurement')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'totalWaterDischarged', 'currentYear')}
                          >
                          </div>
                        </td>

                        <td className='tddivmainbrdr'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => handleInputChange(e, 'waterDischargedByDestination', 'totalWaterDischarged', 'previousYear')}

                          >
                          </div>
                        </td>

                      </tr>

                    </table>

                    <div className="rggsgttxbbedxgtsprijnd ">
                      <p className=" notetgvxtygfspopphjhsttxp" style={{ cursor: "pointer" }} onClick={() => setnotemodal4(!notemodal4)}>Note </p>
                      {notemodal4 &&
                        <div className='yestrxtwarea mt-3 mb-0'>
                          <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'
                            onInput={(e) => {
                              setformvalue({
                                ...formvalue,
                                firstnote: e.currentTarget.textContent
                              });
                            }}
                          >
                          </div>
                        </div>}
                    </div>

                    <div className="">
                      <p className='noteindicatepp  mb-2'>  Note:  Indicate if any independent assessment/ evaluation/assurance has been carried out by an external
                        agency?
                        <span>   (Y/N) If yes,   </span> name of the external agency.
                      </p>
                      <div className="yesnobtndivmain">
                        <div className="raditrbtntxtnemko">
                          <input type="radio"
                            value={"Yes"}
                            checked={formvalue?.isAvailable === "Yes"}
                            onChange={(e) => {
                              setformvalue({
                                ...formvalue,
                                isAvailable: "Yes"
                              });
                            }}
                          />
                          <label>Yes</label>
                        </div>
                        <div className="raditrbtntxtnemko">
                          <input type="radio"
                            value={"No"}
                            checked={formvalue?.isAvailable === "No"}
                            onChange={(e) => {
                              setformvalue({
                                ...formvalue,
                                isAvailable: "No"
                              });
                            }}
                          />
                          <label>No</label>
                        </div>
                      </div>
                      <div className="yestrxtwarea mt-2">
                        <div
                          ref={divRef}
                          contentEditable
                          className='textardibbrdrdivmain'
                          onInput={(e) => {
                            setformvalue({
                              ...formvalue,
                              water_name: e.currentTarget.textContent
                            });
                          }}
                        >
                        </div>
                      </div>
                    </div>

                  </div>


                </ul>
              </div>
              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  Please provide details of total Scope 3 emissions & its intensity, in the following format:
                </li>
                <table className='tablbrdrmain'>

                  <tr className='trdivmainbrdr'>
                    <th className='thdivmainbrdr principlesxdivpntonefrst'>
                      <p className='mnumbbrttprinvthreetxttppp'>
                        Parameter</p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonescnd'>
                      <p className='mnumbbrttprinvthreetxttppp'>
                        Unit Of measurement
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonethrd'>
                      <p className='mnumbbrttprinvthreetxttppp'>FY {currentFY} (Current
                        Financial Year)
                      </p>
                    </th>
                    <th className='thdivmainbrdr principlesxdivpntonefrth' >
                      <p className='mnumbbrttprinvthreetxttppp'>
                        FY {previousFY}(Previous
                        Financial Year)
                      </p>
                    </th>

                  </tr>


                  <tr>
                    <td className='tddivmainbrdrspclnewone'>
                      <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-1'>
                        Total Scope 3 emissions
                      </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>(Break-up of the GHG into
                        CO2, CH4, N2O, HFCs, PFCs,
                        SF6, NF3, if available)
                      </p>
                    </td>

                    {/* <td className='tddivmainbrdr'>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>
                        Metric tonnes
                        of CO2
                        equivalent
                      </p>
                    </td> */}

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'detailsOfTotalScope', 'totalScopeEmissions', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'detailsOfTotalScope', 'totalScopeEmissions', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'detailsOfTotalScope', 'totalScopeEmissions', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdrspclnewone'> <p className='Malettsgvsgphsmkjghhutxtppttlyd'>
                      Total Scope 3 emissions
                      per rupee of turnover

                    </p>

                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'detailsOfTotalScope', 'totalScopeEmissionPerRupee', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'detailsOfTotalScope', 'totalScopeEmissionPerRupee', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'detailsOfTotalScope', 'totalScopeEmissionPerRupee', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>

                  <tr>
                    <td className='tddivmainbrdrspclnewone'> <p className='Malettsgvsgphsmkjghhutxtppttlyd mb-1'>
                      Total Scope 3 emission
                      intensity    </p>
                      <p className='Malettsgvsgphsmkjghhutxtpp'>(optional) – the
                        relevant metric may be
                        selected by the entity
                      </p>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'detailsOfTotalScope', 'totalScopeEmissionOptionalIntensity', 'unitOfmeasurement')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'detailsOfTotalScope', 'totalScopeEmissionOptionalIntensity', 'currentYear')}
                      >
                      </div>
                    </td>

                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'
                        onInput={(e) => handleInputChange(e, 'detailsOfTotalScope', 'totalScopeEmissionOptionalIntensity', 'previousYear')}
                      >
                      </div>
                    </td>

                  </tr>


                </table>
                <div className="">
                  <p className='noteindicatepp  mb-2'>  Note :
                    Indicate if any independent assessment/ evaluation/assurance has been carried out by an
                    external agency?
                    <span>   (Y/N) If yes, name of the external agency.
                    </span>
                  </p>
                  <div className="yesnobtndivmain">
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value="Yes"
                        checked={formvalue?.detailsOfTotalScope?.isExternalAgency === "Yes"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            detailsOfTotalScope: {
                              ...formvalue.detailsOfTotalScope,
                              isExternalAgency: "Yes"
                            }
                          });
                        }}
                      />
                      <label>Yes</label>
                    </div>
                    <div className="raditrbtntxtnemko">
                      <input type="radio"
                        value="No"
                        checked={formvalue?.detailsOfTotalScope?.isExternalAgency === "No"}
                        onChange={(e) => {
                          setformvalue({
                            ...formvalue,
                            detailsOfTotalScope: {
                              ...formvalue.detailsOfTotalScope,
                              isExternalAgency: "No"
                            }
                          });
                        }}
                      />
                      <label>No</label>
                    </div>
                  </div>
                  <div className="yestrxtwarea mt-2">
                    <div
                      ref={divRef}
                      contentEditable
                      className='textardibbrdrdivmain'
                      onInput={(e) => handleInput('detailsOfTotalScope', 'details', e.currentTarget.textContent)}
                    >
                    </div>
                  </div>
                </div>

              </div>

              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  With respect to the ecologically sensitive areas reported at Question 11 of Essential
                  Indicators above, provide details of significant direct & indirect impact of the entity on
                  biodiversity in such areas along-with prevention and remediation activities.
                </li>
                <div className="yestrxtwarea">
                  <div
                    ref={divRef}
                    contentEditable
                    className='textardibbrdrdivmain'
                    onInput={(e) => {
                      setformvalue({
                        ...formvalue,
                        biodiversityImpactDetails: e.currentTarget.textContent
                      });
                    }}
                  >
                  </div>
                </div>
              </div>

              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  If the entity has undertaken any specific initiatives or used innovative technology or
                  solutions to improve resource efficiency, or reduce impact due to emissions / effluent
                  discharge / waste generated, please provide details of the same as well as outcome of such
                  initiatives, as per the following format:
                </li>
                <table className='tablbrdrmain'>

                  <tr className='trdivmainbrdr'>
                    <th className='thdivmainbrdr prnciplesixldrshipdivfrst'>
                      Sl. No.
                    </th>
                    <th className='thdivmainbrdr prnciplesixldrshipdivscnd'>
                      Initiative undertaken

                    </th>
                    <th className='thdivmainbrdr prnciplesixldrshipdivthrd'>
                      Details of the initiative (Web-link, if
                      any, may be provided along-with
                      summary)
                    </th>
                    <th className='thdivmainbrdr prnciplesixldrshipdivfrth'>
                      Outcome of
                      the initiative
                    </th>


                  </tr>
                  {
                    entityDetails3?.map((item, ind) => {
                      return (
                        <tr>
                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity3(ind, e, "sNo")}

                            >
                            </div>
                          </td>
                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity3(ind, e, "intiativeUndertaken")}
                            >
                            </div>
                          </td>
                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity3(ind, e, "weblink")}
                            >
                            </div>
                          </td>
                          <td className='tddivmainbrdr'>
                            <div
                              ref={divRef}
                              contentEditable
                              className='textardibbrdrdivmain'
                              onInput={(e) => handleChangeentity3(ind, e, "outComeOfInitiative")}
                            >
                            </div>
                          </td>


                        </tr>
                      )
                    })
                  }

                  {/* 
                  <tr>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>
                    <td className='tddivmainbrdr'>
                      <div
                        ref={divRef}
                        contentEditable
                        className='textardibbrdrdivmain'>
                        {text}
                      </div>
                    </td>


                  </tr> */}
                </table>

                <div className='notedivadmoredivflx'>
                  <div className=''>
                    <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal7(!notemodal7)}>Note  </p>
                  </div>

                  <button className='adddinciondivimg'>
                    <img src={Addicon} alt="..." onClick={addFormFieldsentityDetails3} />
                    <span>Add More</span>
                  </button>
                </div>
                {notemodal7 &&
                  <div className='yestrxtwarea mt-3 mb-0'>
                    <div
                      ref={divRef}
                      contentEditable
                      className='textardibbrdrdivmain'
                      onInput={(e) => {
                        setformvalue({
                          ...formvalue,
                          describe: e.currentTarget.textContent
                        });
                      }}

                    >
                    </div>
                  </div>}

              </div>

              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  Does the entity have a business continuity and disaster management plan? Give details in
                  100 words/ web link.
                </li>
                <div className="yestrxtwarea">
                  <div
                    ref={divRef}
                    contentEditable
                    className='textardibbrdrdivmain'
                    onInput={(e) => {
                      setformvalue({
                        ...formvalue,
                        disasterManagementPlanDetails: e.currentTarget.textContent
                      });
                    }}
                  >
                  </div>
                </div>
              </div>

              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  Disclose any significant adverse impact to the environment, arising from the value chain of
                  the entity. What mitigation or adaptation measures have been taken by the entity in this
                  regard.

                </li>
                <div className="yestrxtwarea">
                  <div
                    ref={divRef}
                    contentEditable
                    className='textardibbrdrdivmain'
                    onInput={(e) => {
                      setformvalue({
                        ...formvalue,
                        motitgationOrAdaptionMeasureTakenDetails: e.currentTarget.textContent
                      });
                    }}
                  >
                  </div>
                </div>
              </div>

              <div className='listoftblxsdivuldiv'>
                <li className='listoftblxsdiv'>
                  Percentage of value chain partners  (by value of business done with such partners)   that
                  were assessed for environmental impacts.

                </li>
                <div className="yestrxtwarea">
                  <div
                    ref={divRef}
                    contentEditable
                    className='textardibbrdrdivmain'
                    onInput={(e) => {
                      setformvalue({
                        ...formvalue,
                        percentageOfEnvironmentalImpactsDetails: e.currentTarget.textContent
                      });
                    }}
                  >
                  </div>
                </div>
              </div>


            </ul>
          </div>
        </div>

        <div className="homePgCreModSubmitDiv">
          <button
            type='button'
            className="homePgCreModSubmitBtn btn1"
            onClick={submitHandler}
          >
            Submit
          </button>
        </div>

      </div>
    </>
  )
}

export default Principlesix
