import React, { useState } from 'react'
import BulletinBoard from './BulletinBoard'
import Hallofframe from './Hallofframe'
import Initiative from './Initiative'
import EducateStatusCard from '../Educate/EducateStatusCard'
import EventCard from './EventCard'
import MyTeam from './MyTeam'
import Profileactivatetab from './Profileactivatetab'
import SkillValidation from './SkillValidation'
import ProfileStatusCard from './ProfileStatusCard'
import Hobbies from './Hobbies'
import Bio from './Bio'
import SaveIteams from './SaveIteams'
import Rewardpointssetailsmodal from '../../Modal/Rewardpointssetailsmodal'
import View from "../../Images/Icons/PNG/View icon.png"
import { useNavigate } from 'react-router-dom'
const Profile = () => {
    const navigate=useNavigate()
    const [profiletab, setProfiletab] = useState(1);
    const profilebtntab = (tabNumber) => {
        setProfiletab(tabNumber);
    };
    const [rewarddetailsmoadl, setRewarddetailsmoadl] = useState(false)
    const handlerewarddetails = () => {
        setRewarddetailsmoadl(!rewarddetailsmoadl)
    }

    const handleconnectionclick=()=>{
        navigate("/connection")
    }
    const handlecertificateclick=()=>{
        navigate("/allcertificatemain")
    }
    return (
        <>
            <section className='profilemainsection'>
                <div className='profilepagecontainer'>
                    <div className='engagedivmaileft'>
                        <div className=''>
                            <ProfileStatusCard />
                        </div>
                        <div className='coonextionnmabthntmanmain'>
                            <button className='coonextionnmabthntman' onClick={handleconnectionclick}>Connections</button>
                        </div>
                        <div className='hobbsiedivmain'>
                            <Hobbies />
                        </div>
                        <div className='hobbsiedivmain'>
                            <Bio />
                        </div>
                        <div className='certficatemainbrhnnfbbfkkk'>
                            <button className='coonextionnmabthntman' onClick={handlecertificateclick}>Certificate</button>
                        </div>
                        <div className='hobbsiedivmain'>
                            <div className='rewardpomntdsdiv'>
                                <div className='rwrdpintdtltop'>
                                    <div className='rewrdpointdttxt'>
                                        <h4>Reward Point Deatils</h4>
                                    </div>

                                    <div className='viewdijhjsimg' onClick={handlerewarddetails}>
                                        <img src={View} alt="..." />
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='engagedivmaimiddleprfl'>
                        <div className=''>
                            <button className={profiletab === 1 ? "profiletabfrst" : "profiletabscnd"} onClick={() => profilebtntab(1)}>
                                Activity Log
                            </button>
                            <button className={profiletab === 2 ? "profiletabfrst" : "profiletabscnd"} onClick={() => profilebtntab(2)}>
                                Skill Validation
                            </button>
                        </div>

                        <div className=''>
                            <div className=''>
                                {profiletab === 1 && (
                                    <div>
                                        <Profileactivatetab />
                                    </div>
                                )}
                                {profiletab === 2 && (
                                    <div>
                                        <SkillValidation />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='engagedivmairight'>
                        <div className="">
                            <MyTeam />
                        </div>
                        <div className='saveitemasdiv'>
                            <SaveIteams />
                        </div>


                    </div>

                </div>

            </section>

            {rewarddetailsmoadl && <Rewardpointssetailsmodal  closemodal={setRewarddetailsmoadl}  />}
           

        </>
    )
}

export default Profile

