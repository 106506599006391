import React from 'react'
import download from "../../Images/Icons/PNG/download.png";
import EloquenceBackground from "../../Images/EloquenceBackground.png";
import CertificateGalaSeal from "../../Images/CertificateGalaSeal.png";
import logcompany from "../../Images/logcompany.png";
import elitelogo from "../../Images/elitelogo.png"
const Certificateofeloquence = () => {
    return (
        <>
            <section className='cerficatefofedumnainsection'>
                <div className='detailspagecontainer'>
                    <div className='cerficatefofedubg'>
                        <div className='cerficatefofedubgtop'>
                            <div className=''>
                                <p class="traimsignbvjshnamamsnbtxtuicerdr">Next Gen Solution</p>
                            </div>
                            <div className='downliadimgicndibmimg'>
                                <img src={download} alt="..." />
                            </div>
                        </div>
                        <div className='certificateimagebgpading'>
                            <div className='certificateimagebg'
                                style={{ backgroundImage: `url(${EloquenceBackground})` }}>
                                <div className="eloquennsnpapjsjsiidery">
                                    <div className="eloquennsnpapjsjsiiderylrftrightopo" >
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <h4 className='cerifivateqleloquencettstypppbjo'>
                                                            Certificate </h4>
                                                    </th>
                                                    <th>
                                                        <p className='eloquencettxtppgjiooppp'>
                                                            of eloquence</p>
                                                    </th>
                                                    <th>
                                                        <div className style={{ marginLeft: 60, marginRight: 60 }}>
                                                            <table style={{ width: '100%' }}>
                                                                <tbody><tr>
                                                                    <th style={{ textAlign: 'left' }}>
                                                                        <p className='delightexteloquentegttxpp'>
                                                                            We are delighted to present</p>
                                                                    </th>
                                                                    <th style={{ textAlign: 'left' }}>
                                                                        <div className="logocompamyysycertieloquueyoimhj" style={{ backgroundImage: `url(${logcompany})` }} />
                                                                    </th>
                                                                </tr>
                                                                </tbody></table>
                                                            <div className>
                                                                <h4 className='certifietaceeloquencetxttpppwikjejtxp'>MR.
                                                                </h4>
                                                                <h4 className='certifietaceeloquencetxttpppwikjejtxp'>
                                                                    Lorem Ipsum
                                                                </h4>
                                                            </div>
                                                            <div>
                                                                <p className="withcertidficavgwleluqujttxppp">
                                                                    With this Certificate of eloquence for</p>
                                                                <p className='recongnizooeqloquenxtrrttxrrpp'>
                                                                    recognizing the exceptional sprit of innovation on</p>
                                                                <h4 className='nextgeneloquencettxtpppp'>
                                                                    Next gen hr solution
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </tbody></table>
                                        <div className style={{ padding: '0px 0px 0px 0px' }}>
                                            <table className='cerifivattetgteloquuefrlytrrfcbli'>
                                                <tbody>
                                                    <tr >
                                                        <th style={{ textAlign: 'left' }}>
                                                            <div className="galacealimgeloquemnnhgsttdivimg" style={{ backgroundImage: `url(${CertificateGalaSeal})` }}>
                                                            </div>
                                                        </th>
                                                        <th style={{ textAlign: 'left' }}>
                                                            <div className style={{ marginLeft: 50 }}>
                                                                <p className="Certificatenotxttpopp">
                                                                    Anirban Roy</p>
                                                                <div className='eloquencecertbrdrresignnj'>
                                                                </div>
                                                                <p className='certificateNoppplctrxrtp'>
                                                                    Signature</p>
                                                            </div>
                                                        </th>
                                                        <th style={{ textAlign: 'left' }}>
                                                            <div className style={{ marginLeft: 50 }}>
                                                                <p className="Certificatenotxttpopp" >
                                                                    Unique Certificate No.</p>
                                                                <p className='certificateNoppplctrxrtp' >
                                                                    eeeh-1111-11-111111</p>
                                                            </div>
                                                        </th>
                                                        <th style={{ textAlign: 'left' }}>
                                                            <div className style={{ marginLeft: 50 }}>
                                                                <p className="Certificatenotxttpopp">
                                                                    Date of Issue.</p>
                                                                <p className='certificateNoppplctrxrtp'>
                                                                    23/02/2024</p>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </tbody></table>
                                            <div className style={{ padding: '0px 0px 0px 0px' }}>
                                                <div className="elitelogoecerificateeloquentlogoing" style={{ backgroundImage: `url(${elitelogo})` }}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Certificateofeloquence
