import React from 'react'
import eventtesting from "../../Images/eventtesting.jpeg";
import time from "../../Images/Icons/PNG/time.png"
const Upnextcomp = () => {
    return (
        <>
            <div className='upnextdiv'>
                <div className='top'>
                    <div className='badgedivsectnflx'>
                        {/* <div className='mandatorydivimgquz'>
                            <img src={mandatory} alt="..." />
                        </div> */}
                        <p className="cmpltquzetxt">Up Next</p>
                    </div>
                    {/* <div className='expandimgdiv'>
                        <img src={expand} alt="..." />
                    </div> */}
                </div>
                <div className='buttom'>
                    <div className='mandatorydivbg'>
                        <div className='traingmaindivmain'>
                            <img src={eventtesting} alt="..." />
                        </div>
                        <div className=''>
                            <p className='mandttxtpp'>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!
                            </p>
                            <p className='writertxtpp'>Dr. Angela Yu</p>
                        </div>
                        <div className="imgtxtdivmaindddflx">

                            <div className="imgtxtdivmainddd">
                                <div className="strimgdiv">
                                    <img
                                        src={time}
                                        alt="..."
                                    />
                                </div>
                                <p className="tsgyutppp"> 1h 20m</p>
                            </div>
                            <div className="imgtxtdivmainddd">
                                <div className="strimgdiv">
                                    <img
                                        src={time}
                                        alt="..."
                                    />
                                </div>
                                <p className="tsgyutppp">200</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Upnextcomp
