import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import HomeService from '../../Services/HomeService';
import bullentinimg from "../../Images/bullentinimg.jpg";
import Expand from "../../Images/Icons/PNG/Expand.png"
import moment from 'moment';
import HttpClientXml from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';

const EventCard = () => {
    const [allEvent, setAllEvent] = useState([]);
    const [editdelte, setEditdelte] = useState(false);
    const navigate=useNavigate();

    const handleeditdlete = (id) => {
        setEditdelte(prevState => prevState === id ? !prevState : id);
        // setEditdelte(!editdelte)
    }

    // >>>>GET All Event<<<<
    const fetchAllEvent = async () => {
        const res = await HomeService.getAllEvent();
        if (res && res?.status) {
            setAllEvent(res?.data)
        }
    }

      //for delete
      const handleDelete = async (id) => {
        const res = await HttpClientXml?.requestData(`delete-event/${id}`, {}, "DELETE");
        if (res?.status) {
            toast?.success("Event is deleted succesfully");
            fetchAllEvent();
        } else {
            toast?.error(res?.message || "Something went wrong");
        }
    }

    useEffect(() => {
        fetchAllEvent()
    }, [])
  return (
    <>
     <div className='events_boardengage'>
                            <div className="top">
                                <div className="head bulleBoardHead">

                                    <h4>Events</h4>
                                </div>
                                <div className="add_seeallflx">
                                    {/* <div className="tyEvMainDiv">
                                        <figure className="bulleBoardFig">
                                            <i class="fa-solid fa-plus"></i>
                                        </figure>
                                    </div> */}
                                    <div>
                                        <Link style={{ textDecoration: "none" }} className="seeAllAn" to="/eventDetails">
                                            <figure className="bulleBoardFig" style={{ marginRight: "10px" }}>
                                              <img src={Expand} alt='...' />
                                            </figure>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='bottom'>
                                {allEvent && allEvent?.length > 0 ?
                                    allEvent?.map((ele) => {
                                        return (
                                            <div className='card_area' key={ele?._id}>
                                                <div className='event_imgtextbillentin'>
                                                    <div className='event_img'>
                                                        {ele?.image ?
                                                            <img src={ele?.image} className="img-fluid" alt="event" />
                                                            :
                                                            <img src={bullentinimg} className="img-fluid" alt="event" />
                                                        }
                                                    </div>
                                                    <div className=''>
                                                        <p className='txtevent'>{ele?.eventName}</p>
                                                        <div className='hstdadty'>
                                                            {/* <p className='txtevent'>Hosted by:{ele?.hostedBy
                                                            }</p> */}

                                                            <p className='txtevent'>Date:{moment(ele?.eventDate).format("YYYY-MM-DD")}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='dotaddipcn'>
                                                <div className='doticon' onClick={()=>handleeditdlete(ele?._id)}>
                                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                                        {editdelte === ele._id && <div className='editdltdivflxmain'>
                                                            <div className='plismnisn'>
                                                                <i className="fa-solid fa-pen-to-square"
                                                                onClick={() => navigate('/editevent', { state: ele })}
                                                                ></i>
                                                            </div>
                                                            <div className='plismnisn' onClick={()=>handleDelete(ele?._id)}>
                                                                <i className="fa-solid fa-trash"></i>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                    <div className='addicon'>
                                                        <i className="fa-solid fa-plus"></i>
                                                    </div>
                                                   
                                                </div>
                                            </div>

                                        )
                                    })
                                    :
                                    <div>No Data Found!!</div>
                                }

                            </div>
                        </div>
    </>
  )
}

export default EventCard