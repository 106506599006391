import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from 'react-hot-toast';
import Store,{ persistor } from './Redux/Store/Store';
import { ContextProvider } from './Context/AuthCtx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store} >
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <Toaster />
        <ContextProvider>
        <App />
        </ContextProvider>
      </React.StrictMode>
    </PersistGate>

  </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
