import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HttpClientXml from "../../utils/HttpClientXml";


let AffinityGroupState = {
    status: "idle",
    isLoading: false,
    isError: false,
    isSuccess: false,
    mygroup: []
};

export const getAffinityGroup = createAsyncThunk(
    "get_my_affinity_group",
    async () => {
        let response = await HttpClientXml?.requestData("view-my-affinity-group",{},"GET");
        if (response?.status) {
            return response?.data;
        } else {

        }
    }
);

export const AffinityGroupSlice = createSlice({
    name: "affinity_actions",
    initialState: AffinityGroupState,
    reducers: {
        clearAffinityGroup: (state) => {
            state.status = "idle";
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.mygroup = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAffinityGroup.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getAffinityGroup.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.mygroup = payload;
            })
            .addCase(getAffinityGroup.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
            });
    },
});
export const { clearAffinityGroup } = AffinityGroupSlice.actions;
export default AffinityGroupSlice.reducer;
