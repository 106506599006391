import React, { useEffect, useState } from 'react'
import "./Engage.css";

import 'react-tabs/style/react-tabs.css';
import BulletinBoard from './BulletinBoard';
import Hallofframe from './Hallofframe';
import Initiative from './Initiative';
import Sharethought from './Sharethought';
import ShareTeamPost from "./ShareTeamPost"
import EducateStatusCard from '../Educate/EducateStatusCard';
import EventCard from './EventCard';
import MyTeam from './MyTeam';
import Sharethoughtmodal from '../../Modal/Sharethoughtmodal';
import { useDispatch, useSelector } from 'react-redux';
import MyTeamGroup from './MyTeamGroup';
import { getComment } from '../../Redux/Slice/PostSlice';


const ShareTeam = () => {
    const [showCreatePost, setShowCreatePost] = useState(false);
    const [commentId, setCommentId] = useState("");
    const dispatch = useDispatch();
    const posts = useSelector((state) => state?.PostSlice?.team)
    let data = {
        postID: commentId
    };


    useEffect(() => {
        dispatch(getComment(data))
    }, [commentId])
    return (
        <>
            <section className='engagediv'>
                <div className='homepagecontainer'>

                    {/* Left Section */}

                    <div className='engagedivmaileft'>

                        {/* <BulletinBoard /> */}
                        <MyTeamGroup />

                        <Hallofframe />

                        <div className='initiativediv'>
                            <Initiative />
                        </div>

                    </div>

                    {/* Left Section */}

                    {/* >>>>Middle Section */}

                    <div className='engagedivmaimiddle'>
                        <Sharethought />
                        {
                            posts?.map((item) => {
                                return (
                                    <ShareTeamPost
                                        post={item}
                                        setCommentId={setCommentId}
                                        commentId={commentId}
                                    />
                                )
                            })
                        }


                    </div>

                    {/* >>>>Middle Section */}

                    {/* Right Section */}
                    <div className='engagedivmairight'>

                        <EducateStatusCard />

                        <div className='myeventcrd'>
                            <EventCard />
                        </div>

                        <div className='myteamdiv'>
                            <MyTeam />
                        </div>

                    </div>
                    {/* Right Section */}

                </div>
            </section >

            {showCreatePost && <Sharethoughtmodal closemodal={setShowCreatePost} />}

        </>
    )
}

export default ShareTeam
