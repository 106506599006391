import React from 'react';
import video from "../../Images/Icons/PNG/video.png";
import Expand from "../../Images/Icons/PNG/Expand.png";
import EducateStatusCard from './EducateStatusCard'
import Ongoing from './Ongoing'
import Requestedtraining from './Requestedtraining'
import DocumentRepositorymain from './DocumentRepositorymain'
// import EducateOngoingsingle from './EducateOngoingsingle';
import Requestedtraingcardall from './Requestedtraingcardall';

const RequestedTrainingsingle = () => {
  return (
 <>
     <section className='ongingsinglemian'>
                <div className='esgpagecontainer'>
                    <div className='educatemainflx'>
                        <div className='ongoingmainleft'>
                            <div className='videoongoing'>
                                <div className="top">
                                    <div className="head bulleBoardHead">
                                        <figure className="bulleBoardFig">
                                            <img
                                                src={video}
                                                alt="..."
                                            />
                                        </figure>
                                        <h4>Requested Training</h4>
                                    </div>
                                    <div className="add_seeallflx">
                                        <div className="tyEvMainDiv">
                                            <figure className="bulleBoardFig">
                                                <img src={Expand} alt="..." />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className='bottomsinfledivflx'>
                                    <Requestedtraingcardall />
                                </div>
                            </div>
                        </div>
                        <div className='ongoingmainright'>
                            <div className='sinfglepagerightbtm'>
                                <EducateStatusCard />
                            </div>

                            <div className='sinfglepagerightbtm'>
                                <Ongoing />
                            </div>

                            {/* <div className='sinfglepagerightbtm'>
                                <Requestedtraining />
                            </div> */}

                            <div className='sinfglepagerightbtm'>
                            <DocumentRepositorymain />
                            </div>
                           
                        </div>
                    </div>
                </div>
            </section>
 </>
  )
}

export default RequestedTrainingsingle
