import React from 'react'
import Educatetrainingard from './Educatetrainingard'
import Expand from "../../Images/Icons/PNG/Expand.png";
import { Link } from 'react-router-dom';
const Newlyadded = () => {
  return (
<div className='mandatorydiv'>
                <div className='mandatoryicontxtflx'>
                    <div className='icontxtdivmain'>
                        
                        <div className=''>
                            <p className='mandatorttxt'>Newly Added</p>
                        </div>
                    </div>
                    <Link className='Expandimg' to="/SingleMandatoryCourse" >
                        <img src={Expand} alt="..." />
                    </Link>
                </div>

                <div className='mandatorydivbtm'>
                <Educatetrainingard />
                </div>

            </div>
  )
}

export default Newlyadded
