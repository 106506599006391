import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Addicon from "../../Images/Icons/PNG/Add icon.png";
import docreoiimg from "../../Images/docreoiimg.png"
const Myaffinitydetails = () => {
  const [editedelete, setEditedelete] = useState(false)
  const handledelteediit = () => {
    setEditedelete(!editedelete)
  }
  return (
    <>
      <section className='myaffinitygrpmainsection'>
        <div className='detailspagecontainer'>
          <div className="myaffinitydetailsbg">
            <div className="singlepagetop">
              <div className="singlebulletinflx">
                <h4 className="singlepagetoptxt">
                  My Team</h4>
                <Link className='addinconhjfpnh' to="/createteam">
                  <img src={Addicon} alt='...' />
                </Link>
              </div>
            </div>
            <div className='singlepagebottommaffinity'>
              <div className='afiifnitygrpbgmin'>
                <div className='affinitysinammaibrdr'>
                  <div className='affinitysinammai'>
                    <img src={docreoiimg} alt="..." />
                  </div>
                  <div className='divdot' onClick={handledelteediit}>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    {editedelete && <div className="editdltdivflxmain">
                      <div className="plismnisn">
                        <i className="fa-solid fa-pen-to-square" />
                      </div>
                      <div className="plismnisn">
                        <i className="fa-solid fa-trash" />
                      </div>
                    </div>}

                  </div>

                </div>
                <div className=''>
                  <p className="hubEvCrePara" style={{ cursor: "pointer" }}>Star Group testing</p>
                </div>
                <div className='jnbtndivothvtrmain'>
                  <button class="jnbtndivothvtr">Join</button>
                </div>
                <div className="affinitygrpbtm">
                  <div className='admincrtdbtnmdivflclm'>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Created By :
                      </span>
                      Admin
                    </p>


                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Type :
                      </span>
                      single testing
                    </p>
                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Character :
                      </span>
                      lorem ipsum
                    </p>
                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Purpose :
                      </span>
                      A Web Designer, or Web Applications Designer, is responsible for designing the overall layout and aesthetic for websites.
                    </p>
                  </div>

                </div>
              </div>
              <div className='afiifnitygrpbgmin'>
                <div className='affinitysinammaibrdr'>
                  <div className='affinitysinammai'>
                    <img src={docreoiimg} alt="..." />
                  </div>
                  <div className='divdot' onClick={handledelteediit}>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    {editedelete && <div className="editdltdivflxmain">
                      <div className="plismnisn">
                        <i className="fa-solid fa-pen-to-square" />
                      </div>
                      <div className="plismnisn">
                        <i className="fa-solid fa-trash" />
                      </div>
                    </div>}

                  </div>

                </div>
                <div className=''>
                  <p className="hubEvCrePara" style={{ cursor: "pointer" }}>Star Group testing</p>
                </div>
                <div className='jnbtndivothvtrmain'>
                  <button class="jnbtndivothvtr">Join</button>
                </div>
                <div className="affinitygrpbtm">
                  <div className='admincrtdbtnmdivflclm'>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Created By :
                      </span>
                      Admin
                    </p>

                    {/* <div className=''>
                      <button class="jnbtndivothvtr">Join</button>
                    </div> */}
                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Type :
                      </span>
                      single testing
                    </p>
                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Character :
                      </span>
                      lorem ipsum
                    </p>
                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Purpose :
                      </span>
                      A Web Designer, or Web Applications Designer, is responsible for designing the overall layout and aesthetic for websites.
                    </p>
                  </div>

                </div>
              </div>
              <div className='afiifnitygrpbgmin'>
                <div className='affinitysinammaibrdr'>
                  <div className='affinitysinammai'>
                    <img src={docreoiimg} alt="..." />
                  </div>
                  <div className='divdot' onClick={handledelteediit}>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    {editedelete && <div className="editdltdivflxmain">
                      <div className="plismnisn">
                        <i className="fa-solid fa-pen-to-square" />
                      </div>
                      <div className="plismnisn">
                        <i className="fa-solid fa-trash" />
                      </div>
                    </div>}

                  </div>

                </div>
                <div className=''>
                  <p className="hubEvCrePara" style={{ cursor: "pointer" }}>Star Group testing</p>
                </div>
                <div className='jnbtndivothvtrmain'>
                  <button class="jnbtndivothvtr">Join</button>
                </div>
                <div className="affinitygrpbtm">
                  <div className='admincrtdbtnmdivflclm'>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Created By :
                      </span>
                      Admin
                    </p>

                    {/* <div className=''>
                      <button class="jnbtndivothvtr">Join</button>
                    </div> */}
                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Type :
                      </span>
                      single testing
                    </p>
                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Character :
                      </span>
                      lorem ipsum
                    </p>
                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Purpose :
                      </span>
                      A Web Designer, or Web Applications Designer, is responsible for designing the overall layout and aesthetic for websites.
                    </p>
                  </div>

                </div>
              </div>
              <div className='afiifnitygrpbgmin'>
                <div className='affinitysinammaibrdr'>
                  <div className='affinitysinammai'>
                    <img src={docreoiimg} alt="..." />
                  </div>
                  <div className='divdot' onClick={handledelteediit}>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    {editedelete && <div className="editdltdivflxmain">
                      <div className="plismnisn">
                        <i className="fa-solid fa-pen-to-square" />
                      </div>
                      <div className="plismnisn">
                        <i className="fa-solid fa-trash" />
                      </div>
                    </div>}

                  </div>

                </div>
                <div className=''>
                  <p className="hubEvCrePara" style={{ cursor: "pointer" }}>Star Group testing</p>
                </div>
                <div className='jnbtndivothvtrmain'>
                  <button class="jnbtndivothvtr">Join</button>
                </div>
                <div className="affinitygrpbtm">
                  <div className='admincrtdbtnmdivflclm'>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Created By :
                      </span>
                      Admin
                    </p>

                    {/* <div className=''>
                      <button class="jnbtndivothvtr">Join</button>
                    </div> */}
                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Type :
                      </span>
                      single testing
                    </p>
                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Character :
                      </span>
                      lorem ipsum
                    </p>
                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Purpose :
                      </span>
                      A Web Designer, or Web Applications Designer, is responsible for designing the overall layout and aesthetic for websites.
                    </p>
                  </div>

                </div>
              </div>
              <div className='afiifnitygrpbgmin'>
                <div className='affinitysinammaibrdr'>
                  <div className='affinitysinammai'>
                    <img src={docreoiimg} alt="..." />
                  </div>
                  <div className='divdot' onClick={handledelteediit}>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    {editedelete && <div className="editdltdivflxmain">
                      <div className="plismnisn">
                        <i className="fa-solid fa-pen-to-square" />
                      </div>
                      <div className="plismnisn">
                        <i className="fa-solid fa-trash" />
                      </div>
                    </div>}

                  </div>

                </div>
                <div className=''>
                  <p className="hubEvCrePara" style={{ cursor: "pointer" }}>Star Group testing</p>
                </div>
                <div className='jnbtndivothvtrmain'>
                  <button class="jnbtndivothvtr">Join</button>
                </div>
                <div className="affinitygrpbtm">
                  <div className='admincrtdbtnmdivflclm'>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Created By :
                      </span>
                      Admin
                    </p>

                    {/* <div className=''>
                      <button class="jnbtndivothvtr">Join</button>
                    </div> */}
                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Type :
                      </span>
                      single testing
                    </p>
                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Character :
                      </span>
                      lorem ipsum
                    </p>
                  </div>
                  <div className=''>
                    <p className='affinytdtlksttxpp'>
                      <span >
                        Purpose :
                      </span>
                      A Web Designer, or Web Applications Designer, is responsible for designing the overall layout and aesthetic for websites.
                    </p>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Myaffinitydetails
