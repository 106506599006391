import React, { useState } from "react";
import "./style.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast"; 
import AuthService from "../../Services/AuthService";


const Login = () => {
  const initValue = {
    email: "",
    password: ""
  }
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState(initValue)


  const { handleBlur } = useFormik({
    onSubmit: (e) => {
      // handleFormSubmit(e);
    },
  });


  // >>>>Handle All Inputs<<<<
  const handleChangeValue = (e) => {
    const { name, value } = e.target
    setFormValue(prev => ({ ...prev, [name]: value }))
  }


  // >>>>Handle Submit Login<<<<
  const handleSubmitLogin = async (e) => {
    e.preventDefault()
    const { email, password } = formValue;
    const isValid = email?.trim() === "" || password?.trim() === "";

    if (isValid) {
      toast.error("Please fill all the inputs!")
    } else {

      let data = {
        email,
        password
      }
      const res = await AuthService.getLogin(data);
      if (res && res?.status) {
        localStorage.setItem("EsgFrontAuth", res?.data?.token)
        localStorage.setItem("EsgUserType", res?.data?.userType)
        toast.success("Login Successfully")
        setTimeout(() => {
          navigate('/')
        }, 1000);
      } else {
        toast.error(res?.message || "Something Went Wrong")
      }

    }
  }


  return (
    <div className="container-parent">

      {/****shiltu start***/}
      <div className="loginsection_wrap">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="loginimg_wrap">
              {/* <img src={logimg} className="img-fluid" alt="image" /> */}
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-12">
            <div className="logcontent_wrap">

              <div className="responsivelogogo_design">
                <div className="logogo_icon">
                  {/* <img src={logogo} className="img-fluid" alt="..." /> */}
                </div>
              </div>

              <div className="rightcontentbox">
                <h4 className="heading">Log in to your account</h4>

                <form action="#" onSubmit={handleSubmitLogin}>

                  <div className="form-group">
                    <label style={{ marginRight: "5px" }} className="mr-3" htmlFor="InputEmail1">Email Id</label>
                    <input
                      className="form-control"
                      id="InputEmail1"
                      aria-describedby="emailHelp"
                      placeholder=""
                      name='email'
                      value={formValue?.email}
                      onChange={handleChangeValue}
                      type="email"
                    />
                  </div>

                  <div className="form-group">
                    <label style={{ marginRight: "5px" }} htmlFor="Inputpassword">Password</label>
                    <input
                      type="password"
                      onBlur={handleBlur}

                      className="form-control"
                      id="Inputpassword"
                      placeholder=""
                      name="password"
                      value={formValue?.password}
                      onChange={handleChangeValue}
                    />
                  </div>



                  <div className="submitarea_wrap">
                    <button className="login_btn" type="submit" > Login </button>
                    {/* <Link to="#" className="forgot_btn">Forgot Password? *</Link> */}
                  </div>

                </form>

                <div className="advicetext">
                  <p>* still can't log in? <br /> <span>Contact Your Company HP.</span></p>
                </div>

                <div className="bottomlogo">
                  <div className="imgwrap">
                    {/* <img src={btmlogo} className="img-fluid" alt=".." /> */}
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

      {/****shiltu end***/}


    </div >
  );
};

export default Login;
