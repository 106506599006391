import React from 'react';
import download from "../../Images/Icons/PNG/download.png";
import ExcellenceBackground from "../../Images/ExcellenceBackground.png";
import CertificateGalaSeal from "../../Images/CertificateGalaSeal.png";
import logcompany from "../../Images/logcompany.png";
import elitelogo from "../../Images/elitelogo.png"
const Certificateexcellence = () => {
    return (
        <section className='cerficatefofedumnainsection'>
            <div className='detailspagecontainer'>
                <div className='cerficatefofedubg'>
                    <div className='cerficatefofedubgtop'>
                        <div className=''>
                            <p class="traimsignbvjshnamamsnbtxtuicerdr">Employee of the Month</p>
                        </div>
                        <div className='downliadimgicndibmimg'>
                            <img src={download} alt="..." />
                        </div>
                    </div>
                    <div className='certificateimagebgpading'>
                        <div className='certificateimagebg' style={{ backgroundImage: `url(${ExcellenceBackground})` }}>
                            <div className="cerfytvatedallin">
                                <div className="certificateddivmargnlftright">
                                    <div className>

                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <div className="certificatelogocmpnybgmainnkhj"
                                                            style={{ backgroundImage: `url(${logcompany})` }} >
                                                        </div>

                                                    </th>
                                                    <th>
                                                        <h4 className='certficattsfghdbttxppplljjk'>
                                                            Certificate Of
                                                        </h4>
                                                        <p style={{ color: '#b91912', fontSize: 60, textAlign: 'center', fontFamily: 'goudy-old-style', lineHeight: 'normal', fontWeight: 100, letterSpacing: 10, textTransform: 'uppercase', marginTop: 0, marginBottom: 0 }}>
                                                            Excellence</p>
                                                    </th>
                                                    <th>
                                                        <div style={{ backgroundImage: `url(${elitelogo})` }} className="certifacelitelogiomimgbggtryh" >
                                                        </div>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className>
                                        <p className='delightedtxtt' style={{ color: '#000', textAlign: 'center' }}>We are delighted to Present</p>
                                        <h4 className='traineeNametxtpp' style={{ textAlign: 'center', margin: "30px 0px 10px 0px" }}>
                                            MR. Lorem Ipsum
                                        </h4>
                                        <div className='brtfdgtygvscerwxcvbbdbrtdcg'></div>
                                        <p className='withthiscertexcellencetxtpp'>with this certificate of excellence for</p>
                                        <p className='beggungcerexcellencettxppp'>
                                            Bagging the position of the
                                        </p>
                                        <div className="brtfdgtygvscerwxcvbbdbrtdcg"></div>
                                        <p className='employeeofthemnthtxttppphhb' style={{
                                        }}>
                                            Employee of the month
                                        </p>
                                        <div className="brtfdgtygvscerwxcvbbdbrtdcg"></div>
                                    </div>
                                    <div className="certficateeduftrui">
                                        <table className='certficateeduftruitblscertijdexcellence' >
                                            <tbody>
                                                <tr className style={{ margin: 0, padding: '40px 0px' }}>
                                                    <th style={{ textAlign: 'left' }}>
                                                        <div className="cerexcellencendgalsbgnmmskln" style={{ backgroundImage: `url(${CertificateGalaSeal})` }}>
                                                        </div>
                                                    </th>
                                                    <th style={{ textAlign: 'left' }}>
                                                        <div className>
                                                            <p className="Certificatenotxttpopp" >
                                                                Unique Certificate No.</p>
                                                            <p className='certificateNoppplctrxrtp'>
                                                                eeeh-1111-11-111111</p>
                                                        </div>
                                                        <div className="Certificatenotxttpopptoppading">
                                                            <p className="Certificatenotxttpopp">
                                                                Date of Issue.</p>
                                                            <p  className="certificateNoppplctrxrtp">
                                                                23/02/2024</p>
                                                        </div>
                                                    </th>
                                                    <th style={{ textAlign: 'left' }}>
                                                        <div className>
                                                            <p className="Certificatenotxttpopp"
                                                                    style={{ color: "black" }}>
                                                                Signature</p>
                                                            <p className='certificateNoppplctrxrtp'
                                                                    style={{ color: "black", fontSize: "25px" }}>
                                                                Anirban Roy</p>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section >
    )
}

export default Certificateexcellence
