import React from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png";
const CreateBio = () => {
  return (
    <>
      <section className='createeventdetails'>
                <div className="detailspagecontainer">
                    <div className="createdivfrmmain">
                        <form>
                            <div className=''>
                                

                                <div className="trainingrifgtdivgappp">
                                    <p className="rsntxrpnewtxt">Introduction</p>
                                    <textarea
                                        id=""
                                        rows={4}
                                        cols={50}
                                        name='addNotes'
                                        className="txtdivallartadvcacytfnew"
                                    ></textarea>

                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">
                                    Education <span className="required">*</span>
                                    </p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name="eventName"
                                        defaultValue=""
                                    />
                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">
                                    Certificate <span className="required">*</span>
                                    </p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name="eventName"
                                        defaultValue=""
                                    />
                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">
                                    Industry <span className="required">*</span>
                                    </p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name="eventName"
                                        defaultValue=""
                                    />
                                </div>

                                <div className="trainingrifgtdivgappp">
                                    <p className="rsntxrpnewtxt">About</p>
                                    <textarea
                                        id=""
                                        rows={4}
                                        cols={50}
                                        name='addNotes'
                                       
                                        className="txtdivallartadvcacytfnew"
                                    ></textarea>

                                </div>


                                
                              
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">
                                   Phone No. <span className="required">*</span>
                                    </p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name="eventName"
                                        defaultValue=""
                                    />
                                </div>

                                <div className="trainingrifgtdivgappp">
                                    <p className="rsntxrpnewtxt">Email Address</p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name="eventName"
                                        defaultValue=""
                                    />

                                </div>

                                <div className="vdoulddivpbupldgnewmmll">
                                    <p className="rsntxrpnewtxt">  Upload Cv <span className='required'>*</span></p>

                                    <div className="bgcontainerupldboxsgwgrdiv">
                                        <div className="bgcrd">
                                            <div className="bgcontaineruplddivnew">
                                                <div className="logouplddiv">
                                                    <img
                                                        src={Addicon}
                                                        alt="..."
                                                    />
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            id="hostedBy"
                                                            className="upldimagediv"
                                                            placeholder="Choose a photo"
                                                         
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <p className="upldtxtppdiv">Upload Your CV</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="bulletinsbmtbtndivv">
                                    <button
                                        type="button"
                                        className="bulletinsbmtbtn"
                                       
                                    >
                                        Submit
                                    </button>
                                </div>

                                

                            </div>
                        </form>
                    </div>

                </div>
            </section>
    </>
  )
}

export default CreateBio
