import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HttpClientXml from "../../utils/HttpClientXml";


let StatusCardState = {
    status: "idle",
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: []
};

export const getStatusCard = createAsyncThunk(
    "get_status_card",
    async () => {
        let response = await HttpClientXml?.requestData("get-profile",{},"GET");
        if (response?.status) {
            return response?.data;
        } else {

        }
    }
);

export const StatusCardSlice = createSlice({
    name: "sttus_actions",
    initialState: StatusCardState,
    reducers: {
        clearStatusCard: (state) => {
            state.status = "idle";
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.data = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStatusCard.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getStatusCard.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.data = payload;
            })
            .addCase(getStatusCard.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
            });
    },
});
export const { clearStatusCard } = StatusCardSlice.actions;
export default StatusCardSlice.reducer;
