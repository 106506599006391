import React from 'react'
import sharethght from "../../Images/sharethght.jpg";
import doc from "../../Images/Icons/PNG/doc.png"
import docimg from "../../Images/docimg.jpeg";
import eventtesting from "../../Images/eventtesting.jpeg";
import girl from "../../Images/girl.png";
import download from "../../Images/Icons/PNG/download.png"
import { Link } from 'react-router-dom';
import assmnt from "../../Images/Icons/PNG/assmnt.png"
const Fullarticleviewsingle = () => {
    return (
        <>
            <section className='fullarticlesinglesection'>
                <div className='esgpagecontainer'>
                    <div className='educatemainflx'>
                        <div className='detailsdocleft'>
                            <div className=''>
                                <div className='fullarticleviewbg'>
                                    <div className='allarticlevwimgdivmai'>
                                        <img src={eventtesting} alt='...' />
                                    </div>
                                    <div className="purchaseprfolileflx">
                                        <div className="articleprofiletextflx">
                                            <div className="profilearticlebrdr">
                                                <div className="purcahseprofile">
                                                    <img
                                                        alt="..."
                                                        src={girl}
                                                    />
                                                </div>
                                            </div>
                                            <div className="">
                                                <p className="purchseprflnametext">Owner Roy</p>
                                            </div>
                                        </div>
                                        <div className="purchsedepartmentflx">
                                            <div className="downladimgmainpl">
                                                <img src={download} alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <p class="articleversntext"> <sapn> Version : </sapn> 1</p>
                                        <p class="articleversntext"> <span> Version Approve Date : </span> 07-03-2024</p>
                                    </div>
                                    <div className="Sourcingtextppdivall">
                                        <h4 className="Sourcingtextpp">Defining “Sourcing”</h4>
                                        <p className="purchsesrcingtext">Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, tempore. Voluptate omnis, sequi expedita consequuntur laboriosam praesentium officiis eum quam! Laboriosam corrupti sed magni, cupiditate rem perferendis in libero veniam numquam. Possimus, voluptas facere magnam libero dolores rerum hic incidunt?</p>
                                    </div>
                                    <div className="purchasetextdivppcheckbx">
                                        <input type="checkbox" id="" name="" defaultValue="" />
                                        <label htmlFor=""> I have read and understood the article.</label>
                                    </div>
                                    <div className="completionassenmntflx">
                                        <button className="completeaseentbtn">
                                            <div className="Answerquizdivimg">
                                                <img src={assmnt} alt="..." />
                                            </div>
                                            Verify Completion
                                        </button>
                                        <button className="completeaseentbtn">
                                            <div className="Answerquizdivimg">
                                                <img src={assmnt} alt="..." />
                                            </div>
                                            Complete Your Assessment
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='detailsdocright'>
                            <div className=''>
                                <div className="createdcocumentdiv" style={{ cursor: 'pointer' }}>
                                    <div className="createdcocumentop">
                                        <div className="createdcocumentflx">
                                            <div className="documentbrdrprfl">
                                                <div className="documentbrdrprflimg">
                                                    <img src={sharethght} alt="..." />
                                                </div>
                                            </div>
                                            <div className>
                                                <p className="textthghtpp">Share Your Thoughts</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="createdcocumenbottom">
                                        <div className="crtdcmntflx">
                                            <div className="articleiconimgdiv">
                                                <img src={doc} alt="..." /></div>
                                            <div className>
                                                <p className="dcmnttxtpppdf">Create Document</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="recentdocdiv">
                                    <div className="top">
                                        <div className="recentdoccdiv">
                                            <div className="articleiconimgdiv">
                                                <img
                                                    src={doc}
                                                    alt="..."
                                                />
                                            </div>
                                            <div className="">
                                                <p className="dpocsttxtppp">Your Recent Docs</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="buttom">
                                        <div className="docstxtpflxdaa" style={{ cursor: "pointer" }}>
                                            <div className="articleiconimgdiv">
                                                <img
                                                    src={docimg}
                                                    alt="..."
                                                />
                                            </div>
                                            <div className="">
                                                <p className="dcmnttxtpp">What is Lorem Ipsum?</p>
                                            </div>
                                        </div>
                                        <div className="docstxtpflxdaa" style={{ cursor: "pointer" }}>
                                            <div className="articleiconimgdiv">
                                                <img
                                                    src={docimg}
                                                    alt="..."
                                                />
                                            </div>
                                            <div className="">
                                                <p className="dcmnttxtpp">Upload</p>
                                            </div>
                                        </div>
                                        <div className="docstxtpflxdaa" style={{ cursor: "pointer" }}>
                                            <div className="articleiconimgdiv">
                                                <img
                                                    src={docimg}
                                                    alt="..."
                                                />
                                            </div>
                                            <div className="">
                                                <p className="dcmnttxtpp">Finance Document 1</p>
                                            </div>
                                        </div>
                                        <div className="docstxtpflxdaa" style={{ cursor: "pointer" }}>
                                            <div className="articleiconimgdiv">
                                                <img
                                                    src={docimg}
                                                    alt="..."
                                                />
                                            </div>
                                            <div className="">
                                                <p className="dcmnttxtpp">React js Document</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Fullarticleviewsingle
