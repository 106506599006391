import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HttpClientXml from "../../utils/HttpClientXml";


let TrainingState = {
    status: "idle",
    isLoading: false,
    isError: false,
    isSuccess: false,
    training: [],
};



export const getOnboardingTraining = createAsyncThunk(
    'get_onboarding_training_data', async () => {
        let response = await HttpClientXml?.requestData("all-training-list-respectOf-user", {}, "GET");
        if (response?.status) {
            return response?.data;
        }
    }
);


export const TrainingSlice = createSlice({
    name: "training_actions",
    initialState: TrainingState,
    reducers: {
        clearTraining: (state) => {
            state.status = "idle";
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.training = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOnboardingTraining.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getOnboardingTraining.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.isLoading = false;
                state.training = payload?.reverse();
            })
            .addCase(getOnboardingTraining.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
            })
    },
});
export const { clearTraining } = TrainingSlice.actions;
export default TrainingSlice.reducer;
