import React, { useEffect, useState } from 'react'
// import Bulletin_Board from "../../Images/Icons/PNG/Bulletin_Board.png";
// import bullentinimg from "../../Images/bullentinimg.jpg";
// import sharethghts from "../../Images/sharethghts.png";
// import eventtesting from "../../Images/eventtesting.jpeg";
import { Link, useNavigate } from 'react-router-dom';
import Addicon from "../../Images/Icons/PNG/Add icon.png"
import HomeService from '../../Services/HomeService';
import moment from 'moment';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import HttpClientXml from '../../utils/HttpClientXml';

const Bulletindetails = () => {
    const navigate = useNavigate();
    const userData = useSelector((state) => state?.StatusCardSlice?.data);
    const [allBulletin, setAllBulletin] = useState([])
    const [editdeltebuletn, setEditdeltebuletn] = useState(false);

    // >>>>Handle toggling the EDIT and DELETE icon<<<<
    const handleeditdletedtls = (id) => {
        setEditdeltebuletn(prevState => prevState === id ? !prevState : id);

    }

    const handleEventView = (item) => {
        navigate('/bulletinsingle', { state: item })
    }

    // >>>>GET ALl Bulletins Data<<<<
    const fetchAllBulletin = async () => {
        const res = await HomeService.getAllBulletins();
        if (res && res?.status) {
            setAllBulletin(res?.data)
        }
    }

    //for delete
    const handleDelete = async (id) => {
        const res = await HttpClientXml?.requestData(`delete-bulletin-board/${id}`, {}, "PUT");
        if (res?.status) {
            toast?.success("Bulletin deleted succesfully");
            fetchAllBulletin();
        } else {
            toast?.error(res?.message || "Something went wrong");
        }
    }

    useEffect(() => {
        fetchAllBulletin()
    }, [])

    return (
        <>
            <section className='bulletindetailssection'>
                <div className='detailspagecontainer'>
                    <div className='bulletindetailsbg'>
                        <div className='singlepagetop'>
                            <div className="singlebulletinflx"> 
                                <h4 className='singlepagetoptxt'>Bulletin</h4>
                                <Link className='singllepageicon' to="/createbulletin">
                                    <img src={Addicon} alt="..." />
                                </Link>
                            </div>
                        </div>
                        <div className='singlepagebottomm'>
                            {allBulletin && allBulletin?.map((item) => {
                                return (
                                    <div className='singlepagebottommmaindv' key={item?._id} >
                                        <div className=''>
                                            <div className='singleimagebulletin'>
                                                <img src={item?.image} alt="..." />
                                               {
                                                userData?._id===item?.addedBy &&
                                                <div className='thredotsbtn' >
                                                    <i className="fa-solid fa-ellipsis-vertical" style={{ cursor: "pointer" }} onClick={() => handleeditdletedtls(item._id)}></i>

                                                    {editdeltebuletn === item._id && (
                                                        <div className='editdltdivflxmain'>
                                                            <div className='plismnisn'>
                                                                <i className="fa-solid fa-pen-to-square" style={{ cursor: "pointer" }}
                                                                    onClick={() => navigate('/createbulletin', { state: item })}
                                                                ></i>
                                                            </div>
                                                            <div className='plismnisn' onClick={()=>handleDelete(item?._id)}>
                                                                <i className="fa-solid fa-trash" style={{ cursor: "pointer" }}></i>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                               }
                                                
                                            </div>
                                        </div>
                                        <div className='bulletindetailsbottom' style={{cursor:"pointer"}} onClick={() => handleEventView(item)}>
                                            <div className=''>
                                                <p className='bulletintxtppsingle'>
                                                    {item?.eventName}</p>
                                                <p className='bulletintxtppsingledate'>Date :
                                                    <span>
                                                        {item?.eventDate ? moment(item?.eventDate)?.format("YYYY-MM-DD") :  moment(item?.startDate)?.format("YYYY-MM-DD")} 
                                                    </span> </p>
                                            </div>
                                            <div className='viebtndivmain'>
                                                {/* <button className='viewbtn' >View</button> */}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}

export default Bulletindetails