import React from 'react'
import imgprlsthgt from "../../src/Images/imgprlsthgt.jpg"
const Removedfollow = ({closemodal}) => {
  return (
    <section className='unfollowmodabgmain'>
    <div className='unfollowbgmmmmm'>
        <div className='unfollowcrsdivfkx'>
            <p className='unfollowdfpppbk'>
                Remove
            </p>
            <div className='crossflwax' onClick={() => closemodal()}>
                <i className="fa-solid fa-xmark"></i>
            </div>
        </div>
        <div className="unfoolowttxyupdiv">
            <div className="unfoolowttxyupdivdicflx">
                <div className="profilefollowimg">
                    <img
                        src={imgprlsthgt}
                        alt="..."
                    />
                </div>
                <p className="unfoolowttxyupllktxt">Are you Remove Komal Roy?</p>
            </div>
            <div className="canclebtbnunfolowbtndivflx">
                <button className="canclebtbndiv">Cancel</button>
                <button className="unfolowbtndiv">Follow Back</button>
                <button className="unfolowbtndiv">Remove</button>
            </div>
        </div>
    </div>
</section>
  )
}

export default Removedfollow
