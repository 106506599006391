import React from 'react'

const Rewardpointssetailsmodal = ({ closemodal }) => {
  return (
    <>
      <section className='thopughtmodabgmain' >
        <div className='ediotemodalbgrewrddiv'>
          <div className='editetopmodal'>
            <p className='editetxtppbjdl'>Rewards Point Details</p>
            <div className='cersgeditpdg' onClick={() => closemodal()}>
              <i class="fa-solid fa-xmark"></i>
            </div>

          </div>
         
            {/* <table className='rewarddetailstable'>
              <tr>
                <th className='rewrsdtlsdthf'>Coming on hallfame</th>
                <th className='rewrsdtlsdthf'>Comment</th>
                <th className='rewrsdtlsdthf'>Complete Onboarding</th>
                <th className='rewrsdtlsdthf'>Joining Affinity Groups</th>
                <th className='rewrsdtlsdthf'>Post</th>
                <th className='rewrsdtlsdthf'>Publishing Advocacy</th>
                <th className='rewrsdtlsdthf'>React</th>
                <th className='rewrsdtlsdthf'>Survey Completion</th>
                <th className='rewrsdtlsdthf'>Claim Points</th>
              </tr>

              <tr>
                <td className='rewrsdtlsdtdf'>0</td>
                <td className='rewrsdtlsdtdf'>1200</td>
                <td className='rewrsdtlsdtdf'>40</td>
                <td className='rewrsdtlsdtdf'>10</td>
                <td className='rewrsdtlsdtdf'>60</td>
                <td className='rewrsdtlsdtdf'>40</td>
                <td className='rewrsdtlsdtdf'>20</td>
                <td className='rewrsdtlsdtdf'>30</td>
                <td className='rewrsdtlsdtdf'>320</td>
              </tr>
              p            
            </table> */}

            <div className='rewrsdtlsdthfdiv'>
              <p className='rewrsdtlsdthf'>Coming on hallfame</p>
              <p className='rewrsdtlsdtdfrwdrdiv'>0</p>
            </div>
            <div className='rewrsdtlsdthfdiv'>
              <p className='rewrsdtlsdthf'>Comment</p>
              <p className='rewrsdtlsdtdfrwdrdiv'>1200</p>
            </div>
            <div className='rewrsdtlsdthfdiv'>
              <p className='rewrsdtlsdthf'>Complete Onboarding</p>
              <p className='rewrsdtlsdtdfrwdrdiv'>40</p>
            </div>
            <div className='rewrsdtlsdthfdiv'>
              <p className='rewrsdtlsdthf'>Joining Affinity Groups</p>
              <p className='rewrsdtlsdtdfrwdrdiv'>10</p>
            </div>
            <div className='rewrsdtlsdthfdiv'>
              <p className='rewrsdtlsdthf'>Post</p>
              <p className='rewrsdtlsdtdfrwdrdiv'>60</p>
            </div>

            <div className='rewrsdtlsdthfdiv'>
              <p className='rewrsdtlsdthf'>Publishing Advocacy</p>
              <p className='rewrsdtlsdtdfrwdrdiv'>40</p>
            </div>

            <div className='rewrsdtlsdthfdiv'>
              <p className='rewrsdtlsdthf'>React</p>
              <p className='rewrsdtlsdtdfrwdrdiv'>20</p>
            </div>
            <div className='rewrsdtlsdthfdiv'>
              <p className='rewrsdtlsdthf'>Survey Completion</p>
              <p className='rewrsdtlsdtdfrwdrdiv'>30</p>
            </div>

            <div className='rewrsdtlsdthfdiv'>
              <p className='rewrsdtlsdthf'>Claim Points</p>
              <p className='rewrsdtlsdtdfrwdrdiv'>320</p>
            </div>

          </div>
        
      </section >
    </>
  )
}

export default Rewardpointssetailsmodal
