import React, { useEffect, useState } from 'react'
// import trainingmain from "../../Images/trainingmain.png";
// import star from "../../Images/Icons/PNG/star.png";
import clock from "../../Images/Icons/PNG/time.png";
import reward from "../../Images/Icons/PNG/reward.png";
import HomeService from '../../Services/HomeService';
import Trainingvideomodal from '../../Modal/Trainingvideomodal';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import trainingmain from "../../Images/trainingmain.png"


const Educatetrainingard = () => {
    var settings1 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const [singleMandatory, setSingleMandatory] = useState({});
    const [allTraining, setAllTraining] = useState([])
    const [trainimgvideo, setTrainimgvideo] = useState(false)

    // const trainimgvdoclick = () => {
    //     setTrainimgvideo(!trainimgvideo)
    // }

    const handlevideoClick = (item) => {
        setSingleMandatory(item)
        setTrainimgvideo(!trainimgvideo)
    }

    // >>>>Fetch All Training<<<<
    const fetchAllTraining = async () => {
        const res = await HomeService.viewAllTraining();
        if (res && res?.status) {
            console.log("7sgdf4", res?.data)
            setAllTraining(res?.data)
        }
    }

    useEffect(() => {
        fetchAllTraining()
    }, [])
    return (
        <>
            <Slider {...settings1}>
                {
                    (allTraining && allTraining?.length > 0 ?
                        allTraining?.map((item) => {
                            return (
                                <>
                                    <div className='mandatorydivbg' key={item?._id} onClick={() => handlevideoClick(item)} >
                                        {/* <div className='trainingimg'>
                                        <img src={item?.image} alt="..." />

                                    </div> */}
                                        {
                                            (item?.image === "images" || item?.image === "" || item?.image === "image") ? (<div className='traingmaindivmain'>
                                                <img src={trainingmain} alt="..." />
                                            </div>) : (<div className='traingmaindivmain'>
                                                <img src={item?.image} alt="..." />
                                            </div>)
                                        }


                                        <div className=''>
                                            <p className='educatetrainingtext'>{item?.title}</p>
                                        </div>
                                        <div className="imgtxtdivmaindddflx">
                                            <div className="imgtxtdivmainddd">
                                                <div className="strimgdiv">
                                                    <img
                                                        src={clock}
                                                        alt="..."
                                                    />
                                                </div>
                                                <p className="tsgyutppp">{`${item?.totalhour}h ${item?.totalMinutes}m`}</p>
                                            </div>
                                            <div className="imgtxtdivmainddd">
                                                <div className="strimgfrmt">
                                                    <img
                                                        src={reward}
                                                        alt="..."
                                                    />
                                                </div>
                                                <p className="tsgyutppp">{item?.trainingReward}</p>
                                            </div>
                                        </div>

                                    </div>
                                </>
                            )
                        })
                        :
                        <>
                            <div>No Data Found...</div>
                        </>
                    )
                }
            </Slider>

            {trainimgvideo && <Trainingvideomodal closemodal={setTrainimgvideo} singleMandatory={singleMandatory}/>}

        </>
    )

}

export default Educatetrainingard
