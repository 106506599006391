import React from 'react'
// import trainingmain2 from "../../Images/trainingmain2.png";
import DocumentRepository from "../../Images/Icons/PNG/DocumentRepository.png";
import Expand from "../../Images/Icons/PNG/Expand.png";
import { useNavigate } from 'react-router-dom';
import Requestedcard from './Requestedcard';
const Requestedtraining = () => {

    const navigate=useNavigate();
    const handlerequested=()=>{
        navigate("/requestedTrainingsingle") 
    }
    return (
        <>
            <div className='videoongoing'>
                <div className="top">
                    <div className="head bulleBoardHead">
                        {/* <figure className="bulleBoardFig">
                            <img
                                src={DocumentRepository}
                                alt="..."
                            />
                        </figure> */}
                        <h4>Requested Training</h4>
                    </div>
                    <div className="add_seeallflx">
                        <div className="tyEvMainDiv">
                            <figure className="bulleBoardFig" onClick={handlerequested}>
                                <img src={Expand} alt="..." />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='bottom'>
                  <Requestedcard/>

                </div>
            </div>
        </>
    )
}

export default Requestedtraining

