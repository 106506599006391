import HttpClientXml from "../utils/HttpClientXml";


// >>>All Post Api<<<
const getLogin = async (data) => {
    let endPoint = "login";
    return HttpClientXml.post(endPoint, data);
};

export default { 

    // >>>All Post<<<
    getLogin

}