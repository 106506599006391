import React, { useEffect, useRef, useState } from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png"
import HttpClientXml from '../../utils/HttpClientXml';
import HttpClient from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';


const Principleseven = () => {
    const initentityDetails = {
        nameOfAuthority: "",
        briefOfCase: "",
        correctiveActionTaken: ""
    }

    const initvalue = {
        numberOfAffiliations: "",
        slNo1: {
            name: "",
            reach: ""
        },
        slNo2: {
            name: "",
            reach: ""
        },
        slNo3: {
            name: "",
            reach: ""
        },
        slNo4: {
            name: "",
            reach: ""
        },
        slNo5: {
            name: "",
            reach: ""
        },
        slNo6: {
            name: "",
            reach: ""
        },
        slNo7: {
            name: "",
            reach: ""
        },
        slNo8: {
            name: "",
            reach: ""
        },
        slNo9: {
            name: "",
            reach: ""
        },
        slNo10: {
            name: "",
            reach: ""
        },
        details: ""
    }

    const initentityDetails1 = {
        sNo: 0,
        publicPolicyAdvocated: "",
        methodResotredForSuchAdvocacy: "",
        isInformationAvailabelInPublicDomain: "Yes",
        frequencyOfReviewByBoard: "",
        weblink: ""
    }

    // const [text, setText] = useState('');
    const divRef = useRef(null);
    const [principleOneData, setPrincipleOneData] = useState({});
    const [formvalue, setformvalue] = useState(initvalue);
    const [detailsOfCorrectiveAction, setdetailsOfCorrectiveAction] = useState([initentityDetails]);
    const [publicPolicyPositionDetails, setpublicPolicyPositionDetails] = useState([initentityDetails1]);
    const [notemodal1, setnotemodal1] = useState(false);
    const [currentFY, setCurrentFY] = useState("");
    const [previousFY, setPreviousFY] = useState("");

    useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const startMonth = 4; // April is the start of the financial year

        if (currentDate.getMonth() + 1 >= startMonth) {
            setCurrentFY(`${currentYear}-${currentYear + 1}`);
            setPreviousFY(`${currentYear - 1}-${currentYear}`);
        } else {
            setCurrentFY(`${currentYear - 1}-${currentYear}`);
            setPreviousFY(`${currentYear - 2}-${currentYear - 1}`);
        }

    }, []);

    useEffect(() => {
        getPrincipleOne();
    }, [])

    //for getting principleone
    const getPrincipleOne = async () => {
        const res = await HttpClientXml?.requestData(`get-principle`, {}, "GET");
        if (res?.status) {
            setPrincipleOneData(res?.data?.[0]);
        }
    }


    //for Input fields
    const handleInput = (category, field, value) => {
        setformvalue((prevValue) => ({
            ...prevValue,
            [category]: {
                ...prevValue[category],
                [field]: value,
            }
        }));
    }


    //handle change for entityDetails add more functionality
    const handleChangeentity = (i, e, field) => {
        let newFormValues = [...detailsOfCorrectiveAction];
        newFormValues[i][field] = e.currentTarget.textContent;
        setdetailsOfCorrectiveAction(newFormValues);
    };

    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails = () => {
        setdetailsOfCorrectiveAction([...detailsOfCorrectiveAction, initentityDetails]);
    };

    //for removing add more field
    const removeFormFieldsentityDetails = (i) => {
        let newFormValues = [...detailsOfCorrectiveAction];
        newFormValues.splice(i, 1);
        setdetailsOfCorrectiveAction(newFormValues);
    };



    //handle change for entityDetails add more functionality
    const handleChangeentity1 = (i, e, field) => {
        let newFormValues = [...publicPolicyPositionDetails];
        newFormValues[i][field] = e.currentTarget.textContent;
        setpublicPolicyPositionDetails(newFormValues);
    };

    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails1 = () => {
        setpublicPolicyPositionDetails([...publicPolicyPositionDetails, initentityDetails1]);
    };

    //for removing add more field
    const removeFormFieldsentityDetails1 = (i) => {
        let newFormValues = [...publicPolicyPositionDetails];
        newFormValues.splice(i, 1);
        setpublicPolicyPositionDetails(newFormValues);
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        let data = {};

        data = {
            "principleSeven": {
                "currentYear": currentFY,
                "previousYear": previousFY,
                "essentialIndicators": {
                    "numberOfAffiliations": formvalue?.numberOfAffiliations,
                    "memberDetails": {
                        "slNo1": {
                            "name": formvalue?.slNo1?.name,
                            "reach": formvalue?.slNo1?.reach
                        },
                        "slNo2": {
                            "name": formvalue?.slNo2?.name,
                            "reach": formvalue?.slNo2?.reach
                        },
                        "slNo3": {
                            "name": formvalue?.slNo3?.name,
                            "reach": formvalue?.slNo3?.reach
                        },
                        "slNo4": {
                            "name": formvalue?.slNo4?.name,
                            "reach": formvalue?.slNo4?.reach
                        },
                        "slNo5": {
                            "name": formvalue?.slNo5?.name,
                            "reach": formvalue?.slNo5?.reach
                        },
                        "slNo6": {
                            "name": formvalue?.slNo6?.name,
                            "reach": formvalue?.slNo6?.reach
                        },
                        "slNo7": {
                            "name": formvalue?.slNo7?.name,
                            "reach": formvalue?.slNo7?.reach
                        },
                        "slNo8": {
                            "name": formvalue?.slNo8?.name,
                            "reach": formvalue?.slNo8?.reach
                        },
                        "slNo9": {
                            "name": formvalue?.slNo9?.name,
                            "reach": formvalue?.slNo9?.reach
                        },
                        "slNo10": {
                            "name": formvalue?.slNo10?.name,
                            "reach": formvalue?.slNo10?.reach
                        }
                    },
                    "detailsOfCorrectiveAction": detailsOfCorrectiveAction
                },
                "leadershipIndicators": {
                    "publicPolicyPositionDetails": publicPolicyPositionDetails,
                    "details": formvalue?.details
                }
            }
        }
        console.log('principleseven', data);
        const res = await HttpClient?.requestData(
            `update-enviroment-principle/${principleOneData?._id}`,
            data,
            "POST"
        );
        if (res?.status) {
            toast?.success("data is added successfully");
            // navigate('/pricipletwotemp')
            setformvalue(initvalue);
            setdetailsOfCorrectiveAction([initentityDetails]);
            setpublicPolicyPositionDetails([initentityDetails1]);
            const contentEditableElements = document.querySelectorAll('[contentEditable]');
            contentEditableElements?.forEach(element => element.textContent = "");
        } else {
            toast?.error(res?.response?.data?.message || "Something went wrong");
        }
    }


    return (
        <>
            <div className='' >
                <h4 className='principletxtpp'>
                    PRINCIPLE 7 Businesses, when engaging in influencing public and
                    regulatory policy, should do so in a manner that is responsible and
                    transparent

                </h4>
                <div className='borderessentialin'>
                    <p className='idicatrstxtpp'> Essential Indicators</p>
                    <div className=''>
                        <ul className='listoftblxsdivul'>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    a. Number of affiliations with trade and industry chambers/ associations
                                </li>
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            numberOfAffiliations: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>

                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <p className='listoftblxsdiv'>
                                    b. List the top 10 trade and industry chambers/ associations (determined based on the
                                    total members of such body) the entity is a member of/ affiliated to.
                                </p>
                                <table className='tablbrdrmain mb-2'>

                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr principlesvnndjdibsvndivfrst'>
                                            <p className='mnumbbrttprinvthreetxttppp' >
                                                Sl No.
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr principlesvnndjdibsvndivscnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>Name of the trade and industry chambers/
                                                associations
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr principlesvnndjdibsvndivthrd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>Reach of trade and industry
                                                chambers/ associations
                                                (State/National)
                                            </p>
                                        </th>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            {/* <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div> */}
                                            <p className='Malettsgvsgphsmkjghhutxtpp' style={{ textAlign: "center" }}>1</p>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo1', 'name', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo1', 'reach', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp' style={{ textAlign: "center" }}>2</p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo2', 'name', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo2', 'reach', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp' style={{ textAlign: "center" }}>3</p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo3', 'name', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo3', 'reach', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp' style={{ textAlign: "center" }}>4</p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo4', 'name', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo4', 'reach', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp' style={{ textAlign: "center" }}>5</p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo5', 'name', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo5', 'reach', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp' style={{ textAlign: "center" }}>6</p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo6', 'name', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo6', 'reach', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp' style={{ textAlign: "center" }}>7</p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo7', 'name', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo7', 'reach', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp' style={{ textAlign: "center" }}>8</p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo8', 'name', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo8', 'reach', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>


                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp' style={{ textAlign: "center" }}>9</p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo9', 'name', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo9', 'reach', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp' style={{ textAlign: "center" }}>10</p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo10', 'name', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slNo10', 'reach', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>


                                    </tr>
                                </table>

                                <div className="yestrxtwarea">
                                    <p className=" notetgvxtygfspopphjhsttxp" style={{ cursor: "pointer" }} onClick={() => setnotemodal1(!notemodal1)}>Note </p>
                                    {notemodal1 &&
                                        <div class="yestrxtwarea mt-3 mb-0">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        numberOfAffiliations: e.currentTarget.textContent
                                                    });
                                                }}
                                            >
                                            </div>
                                        </div>}
                                </div>

                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Provide details of corrective action taken or underway on any issues related to anticompetitive conduct by the entity, based on adverse orders from regulatory authorities.
                                </li>
                                <table className='tablbrdrmain'>

                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr prncplesvndivfrstscnddivfrst'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Name of authority
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prncplesvndivfrstscnddivscnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'> Brief of the case </p>
                                        </th>
                                        <th className='thdivmainbrdr prncplesvndivfrstscnddivthrd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>Corrective action taken</p>
                                        </th>

                                    </tr>

                                    {
                                        detailsOfCorrectiveAction?.map((item, ind) => {
                                            return (
                                                <tr>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "nameOfAuthority")}
                                                        >
                                                        </div>
                                                    </td>

                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "briefOfCase")}
                                                        >
                                                        </div>
                                                    </td>

                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "correctiveActionTaken")}
                                                        >
                                                        </div>
                                                    </td>


                                                </tr>
                                            )
                                        })
                                    }


                                    {/* <tr>
                                        <td className='tddivmainbrdrspclnewone'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>


                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>

                                    </tr> */}
                                </table>
                                <div className="adddinciondivimg">
                                    <img src={Addicon} alt="..." onClick={addFormFieldsentityDetails} />
                                    <span>Add More</span>
                                </div>



                            </div>
                        </ul>
                    </div>
                </div>
                <div className='borderessentialin'>
                    <p className='idicatrstxtpp'> Leadership Indicators</p>
                    <div className=''>
                        <ul className='listoftblxsdivul'>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Details of public policy positions advocated by the entity:
                                </li>
                                <table className='tablbrdrmain'>

                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr princlplelastsevebndivfsrt'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                S.No
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princlplelastsevebndivscnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>Public
                                                policy
                                                advocated
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princlplelastsevebndivthrd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>Method
                                                resorted for
                                                such advocacy</p>

                                        </th>
                                        <th className='thdivmainbrdr princlplelastsevebndivfrth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>Whether
                                                information
                                                available in public
                                                domain? (Yes/No)</p>

                                        </th>
                                        <th className='thdivmainbrdr princlplelastsevebndivffth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>Frequency of
                                                Review by
                                                Board
                                                (Annually/
                                                Half yearly/
                                                Quarterly /
                                                Others –
                                                please
                                                specify)</p>

                                        </th>
                                        <th className='thdivmainbrdrprincplethree princlplelastsevebndivsxth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>Web
                                                Link, if
                                                available</p>

                                        </th>

                                    </tr>
                                    {
                                        publicPolicyPositionDetails?.map((item, ind) => {
                                            return (
                                                <tr>
                                                    <td className='tddivmainbrdrspclnewone '>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity1(ind, e, "sNo")}
                                                        >
                                                        </div>
                                                    </td>



                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity1(ind, e, "publicPolicyAdvocated")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity1(ind, e, "methodResotredForSuchAdvocacy")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdrspclnewone'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity1(ind, e, "isInformationAvailabelInPublicDomain")}
                                                        >
                                                        </div>
                                                    </td>



                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity1(ind, e, "frequencyOfReviewByBoard")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity1(ind, e, "weblink")}
                                                        >
                                                        </div>
                                                    </td>


                                                </tr>
                                            )
                                        })
                                    }


                                    {/* <tr>
                                        <td className='tddivmainbrdrspclnewone'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdrspclnewone'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>



                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>

                                    </tr> */}



                                </table>
                                <div className="adddinciondivimg mt-2">
                                    <img src={Addicon} alt="..." onClick={addFormFieldsentityDetails1} />
                                    <span>Add More</span>
                                </div>
                                <div className=" ">
                                    <p className=" dtlsttfvdvgppphjp noiriittxoppdjoyyypp">Details :</p>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                details: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>

                            </div>
                        </ul>
                    </div>
                </div>

                <div className="homePgCreModSubmitDiv">
                    <button
                        className="homePgCreModSubmitBtn btn1"
                        type='button'
                        onClick={submitHandler}
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    )
}

export default Principleseven
