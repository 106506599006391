import React from 'react'

import "./Educate.css"
import Educatetrainingard from './Educatetrainingard'
import EducateStatusCard from './EducateStatusCard'
import Mandatory from './Mandatory';
import Skilldevelopment from './Skilldevelopment';
import Microtrainig from './Microtrainig';
import Ongoing from './Ongoing';
import Requestedtraining from './Requestedtraining';
import DocumentRepositorymain from './DocumentRepositorymain';
import { Link } from 'react-router-dom';
import NonTech from './NonTech';
import Addicon from "../../Images/Icons/PNG/Add icon.png"
import Chatbot from '../Chatbot/Chatbot';
import Newlyadded from './Newlyadded';
const Index = () => {
  return (
    <>
      <section className='educatemain'>
        <div className='esgpagecontainer'>
          <div className='educatemainflx'>
            <div className='educatemainleft'>
              <div className=''>
                {/* <Educatetrainingard /> */}
                 <Newlyadded/>
              </div>
              <div className='mandatorycoursemaindiv'>
                <Mandatory />
              </div>

              {/* <div className='mandatorycoursemaindiv'>
                <Skilldevelopment />
              </div>

              <div className='mandatorycoursemaindiv'>
                <NonTech />
              </div>


              <div className='mandatorycoursemaindiv'>
                <Microtrainig />
              </div> */}
            </div>

            <div className='educatemainright'>
              <EducateStatusCard />

              <div className='eductrtraingdiv'>
                {/* <Link className='trainingnewdiv' to="/createtraining" >Create New Training</Link> */}
                <Link className="traioningfnewcreatemaindiv" to="/createtraining" >
                  <div className="addimgdiv">
                    <img
                      src={Addicon}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <p className="crtadvocacytxtypp">Create New Training</p>
                  </div>
                </Link>
              </div>
              <div className='ongoinnghidivpding'>
                <Ongoing />
              </div>
              <div className='requestrtdrtrasijnpafdinl'>
                {/* <button className='createbtnnewdiv'>Request New Training</button> */}
                <Link className="traioningfnewcreatemaindiv" to="/createtraining" >
                  <div className="addimgdiv">
                    <img
                      src={Addicon}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <p className="crtadvocacytxtypp">Request New Training</p>
                  </div>
                </Link>
              </div>

              <div className='requesttedtrysiindibvpadin'>
                <Requestedtraining />
              </div>
              <div className='crerrrdocimmytstreyuspagdion'>
                <Link className="traioningfnewcreatemaindiv" to="/createDocumentRepository" >
                  <div className="addimgdiv">
                    <img
                      src={Addicon}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <p className="crtadvocacytxtypp">Create Document Repository</p>
                  </div>
                </Link>
              </div>

              <div className='docmntdivmnan'>
                <DocumentRepositorymain />
              </div>

            </div>
          </div>
        </div>
      </section>

      <Chatbot />
    </>
  )
}

export default Index
