import React from 'react'
import trainingmain2 from "../../Images/trainingmain2.png";
const Requestedtraingcardall = () => {
    return (

        <>
            <div className='videoongoinddivsingle'>
                <div className=''>
                    <div className='video_onflxdiv'>
                        <div className='trainingmainimgdiv'>
                            <img src={trainingmain2} alt="..." />
                        </div>
                        <div className=''>
                            <p className='lrmtxtpp'>Lorem Ipsum</p>
                            <div className=''>
                            <p class="dotstxtpp">Language : English</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='videoongoinddivsingle'>
                <div className=''>
                    <div className='video_onflxdiv'>
                        <div className='trainingmainimgdiv'>
                            <img src={trainingmain2} alt="..." />
                        </div>
                        <div className=''>
                            <p className='lrmtxtpp'>Lorem Ipsum</p>
                            <div className=''>
                            <p class="dotstxtpp">Language : English</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>


    )
}

export default Requestedtraingcardall
