import React from 'react';
import educationcertificate from "../../Images/educationcertificate.png";
import certificateofexcellence from "../../Images/certificateofexcellence.png";
import certificateofengagement from "../../Images/certificateofengagement.png";
import certificateofeloquence from "../../Images/certificateofeloquence.png";
import "./Certificate.css"
import { Navigate, useNavigate } from 'react-router-dom';
const Allcertificatemain = () => {
    const navigate=useNavigate()
    const certificateofeducationclick=()=>{
        navigate("/certificateofeducation")
    }
    const certificateofexcellenceclick=()=>{
        navigate("/certificateexcellence")
    }
    const certificateengagemenctclick =()=>{
        navigate("/certificateengagement") 
    }
    const certificateeloquemceclick =()=>{
        navigate("/Certificateofeloquence") 
    }
    return (
        <>
            <section className='allcertificatemainsection'>
                <div className='detailspagecontainer'>
                    <div className='allcertificatemaibg'>
                        <div className="cerificatemainbhbrdr" onClick={certificateofeducationclick}>
                            <div className='cerificatemainbhbrdrdivflx'>
                                <div className='certificateimage'>
                                    <img src={educationcertificate} alt="..." />
                                </div>
                                <div className=''>
                                    <h4 className='certificatetyoetxtpp'>Certificate of Education</h4>


                                    <p className='coursttiltlecertifacttxtp'> Company Name :
                                        <span>Fractals Elite </span>
                                    </p>

                                </div>
                            </div>

                        </div>

                        <div className="cerificatemainbhbrdr" onClick={certificateofexcellenceclick}>
                            <div className='cerificatemainbhbrdrdivflx'>
                                <div className='certificateimage'>
                                    <img src={certificateofexcellence} alt="..." />
                                </div>
                                <div className=''>
                                    <h4 className='certificatetyoetxtpp'>Certificate of Excellence</h4>
                                    <p className='coursttiltlecertifacttxtp'> Company Name :
                                        <span>Fractals Elite </span>
                                    </p>

                                </div>
                            </div>

                        </div>

                        <div className="cerificatemainbhbrdr" onClick={certificateengagemenctclick}>
                            <div className='cerificatemainbhbrdrdivflx'>
                                <div className='certificateimage'>
                                    <img src={certificateofengagement} alt="..." />
                                </div>
                                <div className=''>
                                    <h4 className='certificatetyoetxtpp'>Certificate of Engagement</h4>
                                    <p className='coursttiltlecertifacttxtp'> Company Name :
                                        <span>Fractals Elite </span>
                                    </p>

                                </div>
                            </div>

                        </div>

                        <div className="cerificatemainbhbrdr" onClick={certificateeloquemceclick}>
                            <div className='cerificatemainbhbrdrdivflx'>
                                <div className='certificateimage'>
                                    <img src={certificateofeloquence} alt="..." />
                                </div>
                                <div className=''>
                                    <h4 className='certificatetyoetxtpp'>Certificate of Eloquence</h4>
                                    <p className='coursttiltlecertifacttxtp'> Company Name :
                                        <span>Fractals Elite </span>
                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Allcertificatemain
