import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Expand from "../../Images/Icons/PNG/Expand.png";
import bullentinimg from "../../Images/bullentinimg.jpg";
import Edit from "../../Images/Icons/PNG/Edit.png";
import cancel from "../../Images/Icons/PNG/cancel.png"
const SaveIteams = () => {
    const [handleeditmdl, sethandleeditmdl] = useState(false)
    const handleeditdelyesave = () => {
        sethandleeditmdl(!handleeditmdl)
    }
    return (
        <>
            <div className="saveitmsmain">
                <div className="top">
                    <div className="head bulleBoardHead">
                        <h4>Save Iteams</h4>
                    </div>
                    <div className='add_seeallflx'>
                        <div>
                            <Link style={{ textDecoration: "none" }} className="seeAllAn" to="/">
                                <figure className="bulleBoardFig" style={{ marginRight: "10px" }}>

                                    <img src={Expand} alt="..." />
                                </figure>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='bottom'>
                    <div className='card_area' >
                        <div className='event_imgtextbillentin' style={{ cursor: "pointer" }} >
                            <div className='event_img'>

                                {/* <img src={image} className="img-fluid" alt="event" /> */}

                                <img src={bullentinimg} className="img-fluid" alt="event" />

                            </div>
                            <div className='textbillentin'>
                                <h2>Next js training...</h2>
                                <p>Date : 2024-05-08</p>

                            </div>
                        </div>


                        <div className='dotviewflx'>
                            <div className='divdot' onClick={handleeditdelyesave} >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                {handleeditmdl && <div className='editdltdivflxmain'>
                                    <div className='plismnisn'>
                                        <img src={Edit} alt="..." />
                                    </div>
                                    <div className='plismnisn'>
                                        <img src={cancel} alt="..." />
                                    </div>
                                </div>
                                }
                            </div>

                        </div>

                    </div>
                    <div className='card_area' >
                        <div className='event_imgtextbillentin' style={{ cursor: "pointer" }} >
                            <div className='event_img'>

                                {/* <img src={image} className="img-fluid" alt="event" /> */}

                                <img src={bullentinimg} className="img-fluid" alt="event" />

                            </div>
                            <div className='textbillentin'>
                                <h2>Next js training...</h2>
                                <p>Date : 2024-05-08</p>

                            </div>
                        </div>


                        <div className='dotviewflx'>
                            <div className='divdot' onClick={handleeditdelyesave} >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                {handleeditmdl && <div className='editdltdivflxmain'>
                                    <div className='plismnisn'>
                                        <img src={Edit} alt="..." />
                                    </div>
                                    <div className='plismnisn'>
                                        <img src={cancel} alt="..." />
                                    </div>
                                </div>
                                }
                            </div>

                        </div>

                    </div>
                    <div className='card_area' >
                        <div className='event_imgtextbillentin' style={{ cursor: "pointer" }} >
                            <div className='event_img'>

                                {/* <img src={image} className="img-fluid" alt="event" /> */}

                                <img src={bullentinimg} className="img-fluid" alt="event" />

                            </div>
                            <div className='textbillentin'>
                                <h2>Next js training...</h2>
                                <p>Date : 2024-05-08</p>

                            </div>
                        </div>


                        <div className='dotviewflx'>
                            <div className='divdot' onClick={handleeditdelyesave} >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                {handleeditmdl && <div className='editdltdivflxmain'>
                                    <div className='plismnisn'>
                                        <img src={Edit} alt="..." />
                                    </div>
                                    <div className='plismnisn'>
                                        <img src={cancel} alt="..." />
                                    </div>
                                </div>
                                }
                            </div>

                        </div>

                    </div>
                    <div className='card_area' >
                        <div className='event_imgtextbillentin' style={{ cursor: "pointer" }} >
                            <div className='event_img'>

                                {/* <img src={image} className="img-fluid" alt="event" /> */}

                                <img src={bullentinimg} className="img-fluid" alt="event" />

                            </div>
                            <div className='textbillentin'>
                                <h2>Next js training...</h2>
                                <p>Date : 2024-05-08</p>

                            </div>
                        </div>


                        <div className='dotviewflx'>
                            <div className='divdot' onClick={handleeditdelyesave} >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                {handleeditmdl && <div className='editdltdivflxmain'>
                                    <div className='plismnisn'>
                                        <img src={Edit} alt="..." />
                                    </div>
                                    <div className='plismnisn'>
                                        <img src={cancel} alt="..." />
                                    </div>
                                </div>
                                }
                            </div>

                        </div>

                    </div>

                </div>
            </div >
        </>
    )
}

export default SaveIteams
