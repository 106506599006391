import React from 'react'
import download from "../../Images/Icons/PNG/download.png";
import EducationBackground from "../../Images/EducationBackground.png";
import CertificateGalaSeal from "../../Images/CertificateGalaSeal.png";
import logcompany from "../../Images/logcompany.png";
import elitelogo from "../../Images/elitelogo.png"
const Certificateofeducation = () => {
    return (
        <>
            <section className='cerficatefofedumnainsection'>
                <div className='detailspagecontainer'>
                    <div className='cerficatefofedubg'>
                        <div className='cerficatefofedubgtop'>
                            <div className=''>
                                <p class="traimsignbvjshnamamsnbtxtuicerdr">App development Complete guide</p>
                            </div>
                            <div className='downliadimgicndibmimg'>
                                <img src={download} alt="..." />
                            </div>
                        </div>
                        <div className='certificateimagebgpading'>
                            <div className='certificateimagebg'
                                style={{ backgroundImage: `url(${EducationBackground})` }}>
                                <div className="cerfytvatedallin" >
                                    <div className="cerfytvatedalmainkft">
                                        <div classname="">
                                            <table style={{ width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <div
                                                                className="comaopnayslogoedu"
                                                                style={{ backgroundImage: `url(${logcompany})` }}></div>
                                                            <h4 className='certificatenametxtpp'
                                                            >
                                                                Certificate
                                                            </h4>
                                                            <p className='certificatenametxtppnich'
                                                            >
                                                                OF Education
                                                            </p>
                                                        </th>
                                                        <th>
                                                            <div
                                                                className="CertificateGalaSealimg"
                                                                style={{ backgroundImage: `url(${CertificateGalaSeal})` }}
                                                            ></div>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p className='delightedtxtt'
                                            style={{ color: "black" }}
                                        >
                                            We are delighted to present
                                        </p>
                                        <h4 className='traineeNametxtpp'

                                        >
                                            Niladri Roy
                                        </h4>
                                        <p className='educationttxppcrtt'
                                            style={{ color: "black" }}
                                        >
                                            with this certificate of education for
                                        </p>
                                        <p className='completingtxtppjocertxded'

                                        >
                                            Successfully completing the learning course
                                        </p>
                                        <p className='traimsignbvjshnamamsnbtxtuicerdr'

                                        >
                                            APP DEVELOPMENT COMPLETE GUIDE
                                        </p>
                                        <div className="certficateeduftrui">
                                            <table className='certficateeduftruitbls' >
                                                <tbody>
                                                    <tr className="certficateeduftruitr" >
                                                        <th style={{ textAlign: "left" }}>
                                                            <div className="elitelogoeducrtdfdivimg"
                                                                style={{ backgroundImage: `url(${elitelogo})` }}>
                                                            </div>
                                                        </th>
                                                        <th style={{ textAlign: "left" }}>
                                                            <div>
                                                                <p className="Certificatenotxttpopp" >
                                                                    Unique Certificate No.
                                                                </p>
                                                                <p className='certificateNoppplctrxrtp' >
                                                                    ED - 98805929
                                                                </p>
                                                            </div>
                                                        </th>
                                                        <th style={{ textAlign: "left" }}>
                                                            <div classname="">
                                                                <p className="Certificatenotxttpopp"

                                                                >
                                                                    Date of Issue.
                                                                </p>
                                                                <p className='certificateNoppplctrxrtp'

                                                                >
                                                                    18-06-24
                                                                </p>
                                                            </div>
                                                        </th>
                                                        <th style={{ textAlign: "left" }}>
                                                            <div classname="">
                                                                <p className="Certificatenotxttpopp"
                                                                    style={{ color: "black" }}
                                                                >
                                                                    Signature
                                                                </p>
                                                                <p className='certificateNoppplctrxrtp'
                                                                    style={{ color: "black"}}
                                                                >
                                                                    Puspa Banerjee
                                                                </p>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Certificateofeducation
