import React, { useEffect, useRef, useState } from 'react'
import AddIcon from "../../Images/Icons/PNG/Add icon.png"
import HttpClient from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';

const SectionA = () => {

    const initvalue = {
        companyIdentyNo: "",
        name: "",
        yearOfCorporation: "",
        registerOfficeAddress: "",
        corporateAddress: "",
        email: "",
        telephone: "",
        website: "",
        yearOfFinancialReportion: "",
        stockExchangeName: "",
        paidUpCapital: "",
        nameAndContactDetails: {
            contactPerson: "",
            designation: "",
            contactAddress: "",
            phoneNo: "",
            emailAddress: ""
        },
        stateMentOfAssurenceNote: "",
        reportingBoundary: {
            details: ""
        },

        businessActivitiesDetails: {
            note: ""
        },

        productSoldDetails: {
            note: ""
        },

        plantLocationDetails: {
            note: ""
        },

        slOne: {
            location: "",
            number: ""
        },
        slTwo: {
            location: "",
            number: ""
        },

        details: "",
        contributionDetails: {
            totalTurnOver: "",
            note: ""
        },
        briefTypeOfCustomers: "",

        e_e_e_permannent: {
            total: "",
            male: {
                total: "",
                percentage: ""
            },
            female: {
                total: "",
                percentage: ""
            },
            others: {
                total: "",
                percentage: ""
            }
        },
        e_e_e_otherThanPermannent: {
            total: "",
            male: {
                total: "",
                percentage: ""
            },
            female: {
                total: "",
                percentage: ""
            },
            others: {
                total: "",
                percentage: ""
            }
        },
        e_e_e_totalEmployees: {
            total: "",
            male: {
                total: "",
                percentage: ""
            },
            female: {
                total: "",
                percentage: ""
            },
            others: {
                total: "",
                percentage: ""
            }
        },

        e_e_w_permannent: {
            total: "",
            male: {
                total: "",
                percentage: ""
            },
            female: {
                total: "",
                percentage: ""
            },
            others: {
                total: "",
                percentage: ""
            }
        },
        e_e_w_otherThanPermannent: {
            total: "",
            male: {
                total: "",
                percentage: ""
            },
            female: {
                total: "",
                percentage: ""
            },
            others: {
                total: "",
                percentage: ""
            }
        },
        e_e_w_totalEmployees: {
            total: "",
            male: {
                total: "",
                percentage: ""
            },
            female: {
                total: "",
                percentage: ""
            },
            others: {
                total: "",
                percentage: ""
            }
        },
        e_impression: "",
        e_addMoreDetails: "",


        p_e_permannent: {
            total: "",
            male: {
                total: "",
                percentage: ""
            },
            female: {
                total: "",
                percentage: ""
            },
            others: {
                total: "",
                percentage: ""
            }
        },
        p_e_otherThanPermannent: {
            total: "",
            male: {
                total: "",
                percentage: ""
            },
            female: {
                total: "",
                percentage: ""
            },
            others: {
                total: "",
                percentage: ""
            }
        },
        p_e_totalEmployees: {
            total: "",
            male: {
                total: "",
                percentage: ""
            },
            female: {
                total: "",
                percentage: ""
            },
            others: {
                total: "",
                percentage: ""
            }
        },

        p_w_permannent: {
            total: "",
            male: {
                total: "",
                percentage: ""
            },
            female: {
                total: "",
                percentage: ""
            },
            others: {
                total: "",
                percentage: ""
            }
        },
        p_w_otherThanPermannent: {
            total: "",
            male: {
                total: "",
                percentage: ""
            },
            female: {
                total: "",
                percentage: ""
            },
            others: {
                total: "",
                percentage: ""
            }
        },
        p_w_totalEmployees: {
            total: "",
            male: {
                total: "",
                percentage: ""
            },
            female: {
                total: "",
                percentage: ""
            },
            others: {
                total: "",
                percentage: ""
            }
        },
        p_impression: "",
        p_addMoreDetails: "",

        pastFirstYear: "",
        pastSecondYear: "",
        pastThirdYear: "",

        boardOfDirectors: {
            total: "",
            percentageOfFemale: {
                noOfWoment: "",
                percentage: ""
            }
        },
        keyManagementPersonnel: {
            total: "",
            percentageOfFemale: {
                noOfWoment: "",
                percentage: ""
            }
        },
        seniorLeadershipTeamTwo: {
            total: "",
            percentageOfFemale: {
                noOfWoment: "",
                percentage: ""
            }
        },
        note: "",

        permanentEmployee: {
            pastFirstYear: {
                male: "",
                female: "",
                others: ""
            },
            pastSecondYear: {
                male: "",
                female: "",
                others: ""
            },
            pastThreeYear: {
                male: "",
                female: "",
                others: ""
            }
        },
        permanentWorkers: {
            pastFirstYear: {
                male: "",
                female: "",
                others: ""
            },
            pastSecondYear: {
                male: "",
                female: "",
                others: ""
            },
            pastThreeYear: {
                male: "",
                female: "",
                others: ""
            }
        },
        p_note: "",

        associteCompanies: {
            brief: ""
        },

        csrDetails: {
            isCSRApplicable: "Yes",
            turnOver: "",
            netWorth: ""
        },

        stakeHolderGroupDetails: {
            communityWeblink: "",
            investorWeblink: "",
            shareholderWeblink: "",
            employeeAndWorkerWeblink: "",
            customersWeblink: "",
            valueChainPartnerWeblink: "",
            otherWeblink: ""
        },

        communities: {
            currentYear: {
                noOfComplaint: "",
                noOfResolution: "",
                remarks: ""
            },
            previousYear: {
                noOfComplaint: "",
                noOfResolution: "",
                remarks: ""
            }
        },
        otherThanInvestor: {
            currentYear: {
                noOfComplaint: "",
                noOfResolution: "",
                remarks: ""
            },
            previousYear: {
                noOfComplaint: "",
                noOfResolution: "",
                remarks: ""
            }
        },
        shareHolder: {
            currentYear: {
                noOfComplaint: "",
                noOfResolution: "",
                remarks: ""
            },
            previousYear: {
                noOfComplaint: "",
                noOfResolution: "",
                remarks: ""
            }
        },
        employeeAndWorkers: {
            currentYear: {
                noOfComplaint: "",
                noOfResolution: "",
                remarks: ""
            },
            previousYear: {
                noOfComplaint: "",
                noOfResolution: "",
                remarks: ""
            }
        },
        customers: {
            currentYear: {
                noOfComplaint: "",
                noOfResolution: "",
                remarks: ""
            },
            previousYear: {
                noOfComplaint: "",
                noOfResolution: "",
                remarks: ""
            }
        },
        valueChainPartner: {
            currentYear: {
                noOfComplaint: "",
                noOfResolution: "",
                remarks: ""
            },
            previousYear: {
                noOfComplaint: "",
                noOfResolution: "",
                remarks: ""
            }
        },
        s_note: ""

    }

    const initentityDetails = {
        religion: "",
        entityName: ""
    }

    const initentityDetails1 = {
        slNo: "",
        mainActivityGroupCode: "",
        mainActivityGroupDescription: "",
        businessActivityCode: "",
        descriptionOfBusinessActivity: "",
        percentageOfTurnOver: ""
    }

    const initentityDetails2 = {
        slNo: "",
        nameOfService: "",
        NIC: "",
        totalTurnOver: "",
        percentageOfTurnOver: ""
    }

    const initentityDetails3 = {
        location: "",
        noOfPlants: "",
        noOfOffice: "",
        total: ""
    }


    const init = {
        sNo: "",
        materialIssue: "",
        indicateWheatherRisk: "Yes",
        rationaleRisk: "",
        caseOfRisk: "",
        financialImplications: ""
    }

    const init1 = {
        sNo: "",
        nameOfHolding: "",
        indicateHoldingOne: "",
        indicateHoldingTwo: "Yes",
        isResponsible: "Yes"
    }

    const init2 = {
        particulerRevenue: "",
        currentYearAmmount: "",
        previousYearAmmount: ""
    }

    const init3 = {
        providerName: "",
        typeOfAssurence: ""
    }


    const [text, setText] = useState('');
    const divRef = useRef(null);
    const [notemodala, setnotemodala] = useState(false)
    const [formvalue, setformvalue] = useState(initvalue);
    const [entityDetails, setentityDetails] = useState([initentityDetails]);
    const [entityDetails1, setentityDetails1] = useState([initentityDetails1]);
    const [entityDetails2, setentityDetails2] = useState([initentityDetails2]);
    const [entityDetails3, setentityDetails3] = useState([initentityDetails3]);
    const [stateMentOfAssurence, setstateMentOfAssurence] = useState([init3]);
    const [consolidatedTable, setconsolidatedTable] = useState([init2]);
    const [companyDetails, setcompanyDetails] = useState([init1]);
    const [materialResponsibleBusinessConductIssues, setmaterialResponsibleBusinessConductIssues] = useState([init]);


    const [currentFY, setCurrentFY] = useState("");
    const [previousFY, setPreviousFY] = useState("");


    useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const startMonth = 4; // April is the start of the financial year

        if (currentDate.getMonth() + 1 >= startMonth) {
            setCurrentFY(`${currentYear}-${currentYear + 1}`);
            setPreviousFY(`${currentYear - 1}-${currentYear}`);
        } else {
            setCurrentFY(`${currentYear - 1}-${currentYear}`);
            setPreviousFY(`${currentYear - 2}-${currentYear - 1}`);
        }

    }, []);


    const handlenoteclick = () => {
        setnotemodala(!notemodala)
    }

    //for Input fields
    const handleInput = (category, field, value) => {
        setformvalue((prevValue) => ({
            ...prevValue,
            [category]: {
                ...prevValue[category],
                [field]: value,
            }
        }));
    }


    //for input fields
    const handleInputChange = (e, section, year, field) => {
        const value = e.currentTarget.textContent;
        setformvalue(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [year]: {
                    ...prevState[section][year],
                    [field]: value
                }
            }
        }));
    };


    //handle change for entityDetails add more functionality
    const handleChangeentity = (i, e, field) => {
        let newFormValues = [...entityDetails];
        newFormValues[i][field] = e.currentTarget.textContent;
        setentityDetails(newFormValues);
    };


    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails = () => {
        setentityDetails([...entityDetails, initentityDetails]);
    };


    //handle change for entityDetails add more functionality
    const handleChangeentity1 = (i, e, field) => {
        let newFormValues = [...entityDetails1];
        newFormValues[i][field] = e.currentTarget.textContent;
        setentityDetails1(newFormValues);
    };

    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails1 = () => {
        setentityDetails1([...entityDetails1, initentityDetails1]);
    };


    //handle change for entityDetails add more functionality
    const handleChangestateMentOfAssurence = (i, e, field) => {
        let newFormValues = [...stateMentOfAssurence];
        newFormValues[i][field] = e.currentTarget.textContent;
        setstateMentOfAssurence(newFormValues);
    };

    //for entityDetails first add more functionality 
    const addFormFieldstateMentOfAssurence = () => {
        setstateMentOfAssurence([...stateMentOfAssurence, init3]);
    };


    //handle change for entityDetails add more functionality
    const handleChangeconsolidatedTable = (i, e, field) => {
        let newFormValues = [...consolidatedTable];
        newFormValues[i][field] = e.currentTarget.textContent;
        setconsolidatedTable(newFormValues);
    };


    //for entityDetails first add more functionality 
    const addFormFieldconsolidatedTable = () => {
        setconsolidatedTable([...consolidatedTable, init2]);
    };


    //handle change for entityDetails add more functionality
    const handleChangeentity2 = (i, e, field) => {
        let newFormValues = [...entityDetails2];
        newFormValues[i][field] = e.currentTarget.textContent;
        setentityDetails2(newFormValues);
    };

    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails2 = () => {
        setentityDetails2([...entityDetails2, initentityDetails2]);
    };


    //handle change for entityDetails add more functionality
    const handleChangeentity3 = (i, e, field) => {
        let newFormValues = [...entityDetails3];
        newFormValues[i][field] = e.currentTarget.textContent;
        setentityDetails3(newFormValues);
    };


    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails3 = () => {
        setentityDetails3([...entityDetails3, initentityDetails3]);
    };


    //handle change for entityDetails add more functionality
    const handleChangematerialResponsibleBusinessConductIssues = (i, e, field) => {
        let newFormValues = [...materialResponsibleBusinessConductIssues];
        newFormValues[i][field] = e.currentTarget.textContent;
        setmaterialResponsibleBusinessConductIssues(newFormValues);
    };

    //for entityDetails first add more functionality 
    const addFormFieldmaterialResponsibleBusinessConductIssues = () => {
        setmaterialResponsibleBusinessConductIssues([...materialResponsibleBusinessConductIssues, init]);
    };



    //handle change for entityDetails add more functionality
    const handleChangecompanyDetails = (i, e, field) => {
        let newFormValues = [...companyDetails];
        newFormValues[i][field] = e.currentTarget.textContent;
        setcompanyDetails(newFormValues);
    };

    //for entityDetails first add more functionality 
    const addFormFieldcompanyDetails = () => {
        setcompanyDetails([...companyDetails, init1]);
    };


    const submitHandler = async (e) => {
        e.preventDefault();
        let data = {};

        data = {
            "companyEntityDetails": {//I
                "currentYear": currentFY,
                "previousYear": previousFY,
                "companyIdentyNo": formvalue?.companyIdentyNo,
                "name": formvalue?.name,
                "yearOfCorporation": formvalue?.yearOfCorporation,
                "registerOfficeAddress": formvalue?.registerOfficeAddress,
                "corporateAddress": formvalue?.corporateAddress,
                "email": formvalue?.email,
                "telephone": formvalue?.telephone,
                "website": formvalue?.website,
                "yearOfFinancialReportion": formvalue?.yearOfFinancialReportion,
                "stockExchangeName": formvalue?.stockExchangeName,
                "paidUpCapital": formvalue?.paidUpCapital,
                "nameAndContactDetails": {
                    "contactPerson": formvalue?.nameAndContactDetails?.contactPerson,
                    "designation": formvalue?.nameAndContactDetails?.designation,
                    "contactAddress": formvalue?.nameAndContactDetails?.contactAddress,
                    "phoneNo": formvalue?.nameAndContactDetails?.phoneNo,
                    "emailAddress": formvalue?.nameAndContactDetails?.emailAddress
                },
                "reportingBoundary": {
                    "details": formvalue?.reportingBoundary?.details,
                    "entityDetails": entityDetails,
                },
                "stateMentOfAssurence": stateMentOfAssurence,
                "stateMentOfAssurenceNote": formvalue?.stateMentOfAssurenceNote
            },
            "productAndServices": {//II
                "businessActivitiesDetails": {
                    "entityDetails": entityDetails1,
                    "note": formvalue?.businessActivitiesDetails?.note
                },
                "productSoldDetails": {
                    "entityDetails": entityDetails2,
                    "note": formvalue?.productSoldDetails?.note
                }
            },
            "operation": {//III
                "plantLocationDetails": {
                    "entityDetails": entityDetails3,
                    "note": formvalue?.plantLocationDetails?.note
                },
                "marketServedDetails": {
                    "locationDetails": {
                        "noOfLocation": {
                            "slOne": {
                                "location": formvalue?.slOne?.location,
                                "number": formvalue?.slOne?.number
                            },
                            "slTwo": {
                                "location": formvalue?.slTwo?.location,
                                "number": formvalue?.slTwo?.number
                            }
                        },
                        "details": formvalue?.details
                    },
                    "contributionDetails": {
                        "totalTurnOver": formvalue?.contributionDetails?.totalTurnOver,
                        "consolidatedTable": consolidatedTable,
                        "note": formvalue?.contributionDetails?.note
                    },
                    "briefTypeOfCustomers": formvalue?.briefTypeOfCustomers
                }
            },
            "employees": {//IV
                "employeeAndWorkerDetails": {
                    "employee": {
                        "permannent": {
                            "total": formvalue?.e_e_e_permannent?.total,
                            "male": {
                                "total": formvalue?.e_e_e_permannent?.male?.total,
                                "percentage": formvalue?.e_e_e_permannent?.male?.percentage
                            },
                            "female": {
                                "total": formvalue?.e_e_e_permannent?.female?.total,
                                "percentage": formvalue?.e_e_e_permannent?.female?.percentage
                            },
                            "others": {
                                "total": formvalue?.e_e_e_permannent?.others?.total,
                                "percentage": formvalue?.e_e_e_permannent?.others?.percentage
                            }
                        },
                        "otherThanPermannent": {
                            "total": formvalue?.e_e_e_otherThanPermannent?.total,
                            "male": {
                                "total": formvalue?.e_e_e_otherThanPermannent?.male?.total,
                                "percentage": formvalue?.e_e_e_otherThanPermannent?.male?.percentage
                            },
                            "female": {
                                "total": formvalue?.e_e_e_otherThanPermannent?.female?.total,
                                "percentage": formvalue?.e_e_e_otherThanPermannent?.female?.percentage
                            },
                            "others": {
                                "total": formvalue?.e_e_e_otherThanPermannent?.others?.total,
                                "percentage": formvalue?.e_e_e_otherThanPermannent?.others?.percentage
                            }
                        },
                        "totalEmployees": {
                            "total": formvalue?.e_e_e_totalEmployees?.total,
                            "male": {
                                "total": formvalue?.e_e_e_totalEmployees?.male?.total,
                                "percentage": formvalue?.e_e_e_totalEmployees?.male?.percentage
                            },
                            "female": {
                                "total": formvalue?.e_e_e_totalEmployees?.female?.total,
                                "percentage": formvalue?.e_e_e_totalEmployees?.female?.percentage
                            },
                            "others": {
                                "total": formvalue?.e_e_e_totalEmployees?.others?.total,
                                "percentage": formvalue?.e_e_e_totalEmployees?.others?.percentage
                            }
                        }
                    },
                    "worker": {
                        "permannent": {
                            "total": formvalue?.e_e_w_permannent?.total,
                            "male": {
                                "total": formvalue?.e_e_w_permannent?.male?.total,
                                "percentage": formvalue?.e_e_w_permannent?.male?.percentage
                            },
                            "female": {
                                "total": formvalue?.e_e_w_permannent?.female?.total,
                                "percentage": formvalue?.e_e_w_permannent?.female?.percentage
                            },
                            "others": {
                                "total": formvalue?.e_e_w_permannent?.others?.total,
                                "percentage": formvalue?.e_e_w_permannent?.others?.percentage
                            }
                        },
                        "otherThanPermannent": {
                            "total": formvalue?.e_e_w_otherThanPermannent?.total,
                            "male": {
                                "total": formvalue?.e_e_w_otherThanPermannent?.male?.total,
                                "percentage": formvalue?.e_e_w_otherThanPermannent?.male?.percentage
                            },
                            "female": {
                                "total": formvalue?.e_e_w_otherThanPermannent?.female?.total,
                                "percentage": formvalue?.e_e_w_otherThanPermannent?.female?.percentage
                            },
                            "others": {
                                "total": formvalue?.e_e_w_otherThanPermannent?.others?.total,
                                "percentage": formvalue?.e_e_w_otherThanPermannent?.others?.percentage
                            }
                        },
                        "totalEmployees": {
                            "total": formvalue?.e_e_w_totalEmployees?.total,
                            "male": {
                                "total": formvalue?.e_e_w_totalEmployees?.male?.total,
                                "percentage": formvalue?.e_e_w_totalEmployees?.male?.percentage
                            },
                            "female": {
                                "total": formvalue?.e_e_w_totalEmployees?.female?.total,
                                "percentage": formvalue?.e_e_w_totalEmployees?.female?.percentage
                            },
                            "others": {
                                "total": formvalue?.e_e_w_totalEmployees?.others?.total,
                                "percentage": formvalue?.e_e_w_totalEmployees?.others?.percentage
                            }
                        }
                    },
                    "impression": formvalue?.e_impression,
                    "addMoreDetails": formvalue?.e_addMoreDetails
                },
                "pwdEmployeeAndWorkerDetails": {
                    "pwdEmployee": {
                        "permannent": {
                            "total": formvalue?.p_e_permannent?.total,
                            "male": {
                                "total": formvalue?.p_e_permannent?.male?.total,
                                "percentage": formvalue?.p_e_permannent?.male?.percentage
                            },
                            "female": {
                                "total": formvalue?.p_e_permannent?.female?.total,
                                "percentage": formvalue?.p_e_permannent?.female?.percentage
                            },
                            "others": {
                                "total": formvalue?.p_e_permannent?.others?.total,
                                "percentage": formvalue?.p_e_permannent?.others?.percentage
                            }
                        },
                        "otherThanPermannent": {
                            "total": formvalue?.p_e_otherThanPermannent?.total,
                            "male": {
                                "total": formvalue?.p_e_otherThanPermannent?.male?.total,
                                "percentage": formvalue?.p_e_otherThanPermannent?.male?.percentage
                            },
                            "female": {
                                "total": formvalue?.p_e_otherThanPermannent?.female?.total,
                                "percentage": formvalue?.p_e_otherThanPermannent?.female?.percentage
                            },
                            "others": {
                                "total": formvalue?.p_e_otherThanPermannent?.others?.total,
                                "percentage": formvalue?.p_e_otherThanPermannent?.others?.percentage
                            }
                        },
                        "totalEmployees": {
                            "total": formvalue?.p_e_totalEmployees?.total,
                            "male": {
                                "total": formvalue?.p_e_totalEmployees?.male?.total,
                                "percentage": formvalue?.p_e_totalEmployees?.male?.percentage
                            },
                            "female": {
                                "total": formvalue?.p_e_totalEmployees?.female?.total,
                                "percentage": formvalue?.p_e_totalEmployees?.female?.percentage
                            },
                            "others": {
                                "total": formvalue?.p_e_totalEmployees?.others?.total,
                                "percentage": formvalue?.p_e_totalEmployees?.others?.percentage
                            }
                        }
                    },
                    "pwdWorker": {
                        "permannent": {
                            "total": formvalue?.p_w_permannent?.total,
                            "male": {
                                "total": formvalue?.p_w_permannent?.male?.total,
                                "percentage": formvalue?.p_w_permannent?.male?.percentage
                            },
                            "female": {
                                "total": formvalue?.p_w_permannent?.female?.total,
                                "percentage": formvalue?.p_w_permannent?.female?.percentage
                            },
                            "others": {
                                "total": formvalue?.p_w_permannent?.others?.total,
                                "percentage": formvalue?.p_w_permannent?.others?.percentage
                            }
                        },
                        "otherThanPermannent": {
                            "total": formvalue?.p_w_otherThanPermannent?.total,
                            "male": {
                                "total": formvalue?.p_w_otherThanPermannent?.male?.total,
                                "percentage": formvalue?.p_w_otherThanPermannent?.male?.percentage
                            },
                            "female": {
                                "total": formvalue?.p_w_otherThanPermannent?.female?.total,
                                "percentage": formvalue?.p_w_otherThanPermannent?.female?.percentage
                            },
                            "others": {
                                "total": formvalue?.p_w_otherThanPermannent?.others?.total,
                                "percentage": formvalue?.p_w_otherThanPermannent?.others?.percentage
                            }
                        },
                        "totalEmployees": {
                            "total": formvalue?.p_w_totalEmployees?.total,
                            "male": {
                                "total": formvalue?.p_w_totalEmployees?.male?.total,
                                "percentage": formvalue?.p_w_totalEmployees?.male?.percentage
                            },
                            "female": {
                                "total": formvalue?.p_w_totalEmployees?.female?.total,
                                "percentage": formvalue?.p_w_totalEmployees?.female?.percentage
                            },
                            "others": {
                                "total": formvalue?.p_w_totalEmployees?.others?.total,
                                "percentage": formvalue?.p_w_totalEmployees?.others?.percentage
                            }
                        }
                    },
                    "impression": formvalue?.p_impression,
                    "addMoreDetails": formvalue?.p_addMoreDetails
                },
                "participateOfWomen": {
                    "participantDetails": {
                        "boardOfDirectors": {
                            "total": formvalue?.boardOfDirectors?.total,
                            "percentageOfFemale": {
                                "noOfWoment": formvalue?.boardOfDirectors?.percentageOfFemale?.noOfWoment,
                                "percentage": formvalue?.boardOfDirectors?.percentageOfFemale?.percentage
                            }
                        },
                        "keyManagementPersonnel": {
                            "total": formvalue?.keyManagementPersonnel?.total,
                            "percentageOfFemale": {
                                "noOfWoment": formvalue?.keyManagementPersonnel?.percentageOfFemale?.noOfWoment,
                                "percentage": formvalue?.keyManagementPersonnel?.percentageOfFemale?.percentage
                            }
                        },
                        "seniorLeadershipTeamTwo": {
                            "total": formvalue?.seniorLeadershipTeamTwo?.total,
                            "percentageOfFemale": {
                                "noOfWoment": formvalue?.seniorLeadershipTeamTwo?.percentageOfFemale?.noOfWoment,
                                "percentage": formvalue?.seniorLeadershipTeamTwo?.percentageOfFemale?.percentage
                            }
                        }

                    },
                    "note": formvalue?.note
                },
                "pastThreeYearTurnover": {
                    "pastFirstYear": formvalue?.pastFirstYear,
                    "pastSecondYear": formvalue?.pastSecondYear,
                    "pastThirdYear": formvalue?.pastThirdYear,
                    "permanentEmployee": {
                        "pastFirstYear": {
                            "male": formvalue?.permanentEmployee?.pastFirstYear?.male,
                            "female": formvalue?.permanentEmployee?.pastFirstYear?.female,
                            "others": formvalue?.permanentEmployee?.pastFirstYear?.others
                        },
                        "pastSecondYear": {
                            "male": formvalue?.permanentEmployee?.pastSecondYear?.male,
                            "female": formvalue?.permanentEmployee?.pastSecondYear?.female,
                            "others": formvalue?.permanentEmployee?.pastSecondYear?.others
                        },
                        "pastThreeYear": {
                            "male": formvalue?.permanentEmployee?.pastThreeYear?.male,
                            "female": formvalue?.permanentEmployee?.pastThreeYear?.female,
                            "others": formvalue?.permanentEmployee?.pastThreeYear?.others
                        }
                    },
                    "permanentWorkers": {
                        "pastFirstYear": {
                            "male": formvalue?.permanentWorkers?.pastFirstYear?.male,
                            "female": formvalue?.permanentWorkers?.pastFirstYear?.female,
                            "others": formvalue?.permanentWorkers?.pastFirstYear?.others
                        },
                        "pastSecondYear": {
                            "male": formvalue?.permanentWorkers?.pastSecondYear?.male,
                            "female": formvalue?.permanentWorkers?.pastSecondYear?.female,
                            "others": formvalue?.permanentWorkers?.pastSecondYear?.others
                        },
                        "pastThreeYear": {
                            "male": formvalue?.permanentWorkers?.pastThreeYear?.male,
                            "female": formvalue?.permanentWorkers?.pastThreeYear?.female,
                            "others": formvalue?.permanentWorkers?.pastThreeYear?.others
                        }
                    },
                    "note": formvalue?.p_note
                }
            },
            "associteCompanies": {//V
                "companyDetails": companyDetails,
                "brief": formvalue?.associteCompanies?.brief
            },
            "csrDetails": {//VI
                "isCSRApplicable": formvalue?.csrDetails?.isCSRApplicable,
                "turnOver": formvalue?.csrDetails?.turnOver,
                "netWorth": formvalue?.csrDetails?.netWorth
            },
            "transparencyAndDisclosuresCompliances": {//VII
                "stakeHolderGroupDetails": {
                    "communityWeblink": formvalue?.stakeHolderGroupDetails?.communityWeblink,
                    "investorWeblink": formvalue?.stakeHolderGroupDetails?.investorWeblink,
                    "shareholderWeblink": formvalue?.stakeHolderGroupDetails?.shareholderWeblink,
                    "employeeAndWorkerWeblink": formvalue?.stakeHolderGroupDetails?.employeeAndWorkerWeblink,
                    "customersWeblink": formvalue?.stakeHolderGroupDetails?.customersWeblink,
                    "valueChainPartnerWeblink": formvalue?.stakeHolderGroupDetails?.valueChainPartnerWeblink,
                    "otherWeblink": formvalue?.stakeHolderGroupDetails?.otherWeblink
                },
                "stakeHolderYearRespectedData": {
                    "communities": {
                        "currentYear": {
                            "noOfComplaint": formvalue?.communities?.currentYear?.noOfComplaint,
                            "noOfResolution": formvalue?.communities?.currentYear?.noOfResolution,
                            "remarks": formvalue?.communities?.currentYear?.remarks
                        },
                        "previousYear": {
                            "noOfComplaint": formvalue?.communities?.previousYear?.noOfComplaint,
                            "noOfResolution": formvalue?.communities?.previousYear?.noOfResolution,
                            "remarks": formvalue?.communities?.previousYear?.remarks
                        }
                    },
                    "otherThanInvestor": {
                        "currentYear": {
                            "noOfComplaint": formvalue?.otherThanInvestor?.currentYear?.noOfComplaint,
                            "noOfResolution": formvalue?.otherThanInvestor?.currentYear?.noOfResolution,
                            "remarks": formvalue?.otherThanInvestor?.currentYear?.remarks
                        },
                        "previousYear": {
                            "noOfComplaint": formvalue?.otherThanInvestor?.previousYear?.noOfComplaint,
                            "noOfResolution": formvalue?.otherThanInvestor?.previousYear?.noOfResolution,
                            "remarks": formvalue?.otherThanInvestor?.previousYear?.remarks
                        }
                    },
                    "shareHolder": {
                        "currentYear": {
                            "noOfComplaint": formvalue?.shareHolder?.currentYear?.noOfComplaint,
                            "noOfResolution": formvalue?.shareHolder?.currentYear?.noOfResolution,
                            "remarks": formvalue?.shareHolder?.currentYear?.remarks
                        },
                        "previousYear": {
                            "noOfComplaint": formvalue?.shareHolder?.previousYear?.noOfComplaint,
                            "noOfResolution": formvalue?.shareHolder?.previousYear?.noOfResolution,
                            "remarks": formvalue?.shareHolder?.previousYear?.remarks
                        }
                    },
                    "employeeAndWorkers": {
                        "currentYear": {
                            "noOfComplaint": formvalue?.employeeAndWorkers?.currentYear?.noOfComplaint,
                            "noOfResolution": formvalue?.employeeAndWorkers?.currentYear?.noOfResolution,
                            "remarks": formvalue?.employeeAndWorkers?.currentYear?.remarks
                        },
                        "previousYear": {
                            "noOfComplaint": formvalue?.employeeAndWorkers?.previousYear?.noOfComplaint,
                            "noOfResolution": formvalue?.employeeAndWorkers?.previousYear?.noOfResolution,
                            "remarks": formvalue?.employeeAndWorkers?.previousYear?.remarks
                        }
                    },
                    "customers": {
                        "currentYear": {
                            "noOfComplaint": formvalue?.customers?.currentYear?.noOfComplaint,
                            "noOfResolution": formvalue?.customers?.currentYear?.noOfResolution,
                            "remarks": formvalue?.customers?.currentYear?.remarks
                        },
                        "previousYear": {
                            "noOfComplaint": formvalue?.customers?.previousYear?.noOfComplaint,
                            "noOfResolution": formvalue?.customers?.previousYear?.noOfResolution,
                            "remarks": formvalue?.customers?.previousYear?.remarks
                        }
                    },
                    "valueChainPartner": {
                        "currentYear": {
                            "noOfComplaint": formvalue?.valueChainPartner?.currentYear?.noOfComplaint,
                            "noOfResolution": formvalue?.valueChainPartner?.currentYear?.noOfResolution,
                            "remarks": formvalue?.valueChainPartner?.currentYear?.remarks
                        },
                        "previousYear": {
                            "noOfComplaint": formvalue?.valueChainPartner?.previousYear?.noOfComplaint,
                            "noOfResolution": formvalue?.valueChainPartner?.previousYear?.noOfResolution,
                            "remarks": formvalue?.valueChainPartner?.previousYear?.remarks
                        }
                    }
                },
                "note": formvalue?.s_note
            },
            "materialResponsibleBusinessConductIssues": materialResponsibleBusinessConductIssues

        }

        console.log('principleeight', data);
        const res = await HttpClient?.requestData(
            `add-general-disclosure`,
            data,
            "POST"
        );
        if (res?.status) {
            toast?.success("data is added successfully");
            // navigate('/pricipletwotemp')
            setformvalue(initvalue);
            const contentEditableElements = document.querySelectorAll('[contentEditable]');
            contentEditableElements?.forEach(element => element.textContent = "");
            setentityDetails([initentityDetails])
            setentityDetails1([initentityDetails1]);
            setentityDetails2([initentityDetails2]);
            setentityDetails3([initentityDetails3]);
        } else {
            toast?.error(res?.response?.data?.message || "Something went wrong");
        }
    }


    return (
        <>

            <section className=''>
                <p className='sectionctxttpppprooitp'>SECTION A : GENERAL DISCLOSURES</p>

                <div className="listoftblxsdivuldiv">
                    <p className="listoftblxsdivromainstyhhpp"> I . Details of the listed entity</p>

                    <table className="tablbrdrmain">
                        <tbody>
                            <tr className="trdivmainbrdr">
                                <th className="thdivmainbrdr thsectionporntdivfrst">
                                    <p className='mnumbbrttprinvthreetxttppp'>
                                        Sl. No.</p>
                                </th>
                                <th className="thdivmainbrdr thsectionporntdivscnd">
                                    <p className='mnumbbrttprinvthreetxttppp'>Particular
                                    </p>
                                </th>
                                <th className="thdivmainbrdr  thsectionporntdivthrd">
                                    <p className='mnumbbrttprinvthreetxttppp'>
                                        Company Details

                                    </p>
                                </th>
                            </tr>
                            <tr>
                                <td className="tddivmainbrdr  ">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        1
                                    </p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Corporate Identity Number (CIN)</p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                companyIdentyNo: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="tddivmainbrdr  ">
                                    <p className='Malettsgvsgphsmkjghhutxtpp  '> 2
                                    </p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Name</p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                name: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="tddivmainbrdr  ">
                                    <p className='Malettsgvsgphsmkjghhutxtpp  '>
                                        3
                                    </p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Year of incorporation</p>
                                </td>
                                <td className="tddivmainbrdr ">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                yearOfInCorporation: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="tddivmainbrdr  ">
                                    <p className='Malettsgvsgphsmkjghhutxtpp  '>
                                        4
                                    </p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'> Registered office address</p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                registerOfficeAddress: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="tddivmainbrdr  ">
                                    <p className='Malettsgvsgphsmkjghhutxtpp  '> 5</p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'> Corporate address</p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                corporateAddress: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="tddivmainbrdr  ">
                                    <p className='Malettsgvsgphsmkjghhutxtpp  '> 6
                                    </p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>E-mail</p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                email: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="tddivmainbrdr ">
                                    <p className='Malettsgvsgphsmkjghhutxtpp '> 7
                                    </p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Telephone</p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                telephone: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="tddivmainbrdr  ">
                                    <p className='Malettsgvsgphsmkjghhutxtpp  '> 8
                                    </p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'> Website</p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                website: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="tddivmainbrdr  ">
                                    <p className='Malettsgvsgphsmkjghhutxtpp  '> 9
                                    </p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Financial year for which reporting is being done</p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                yearOfFinancialReportion: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="tddivmainbrdr  ">
                                    <p className='Malettsgvsgphsmkjghhutxtpp  '> 10
                                    </p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Name of the Stock Exchange(s) where shares are listed</p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                stockExchangeName: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="tddivmainbrdr ">
                                    <p className='Malettsgvsgphsmkjghhutxtpp  '>11</p>

                                </td>
                                <td className="tddivmainbrdr">
                                    Paid-up Capital
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                paidUpCapital: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </td>
                            </tr>


                        </tbody>
                    </table>

                    {/* <button className='adddinciondivimg'>
                        <img src={AddIcon} alt='...' />
                        <span>Add More</span>
                    </button> */}


                    {/* <h4 className='tablebottom_note'>Note :</h4>
                    <div className="yestrxtwarea">
                        <div
                            ref={divRef}
                            contentEditable
                            className='textardibbrdrdivmain'>
                            {text}
                        </div>
                    </div> */}
                </div>

                <ol start="12">
                    <div className="listoftblxsdivuldiv">
                        <li className="listoftblxsdivseca ">
                            Name and contact details of the person who may be contacted in case of any queries on the BRSR report:
                        </li>
                        <table className="tablbrdrmain">

                            <tr className="trdivmainbrdr">
                                <td className="tddivmainbrdr sectiondivponiscnddibvfsrt">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        Contact Person
                                    </p>
                                </td>
                                <td className="tddivmainbrdr sectiondivponiscnddibvscnd">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('nameAndContactDetails', 'contactPerson', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className="tddivmainbrdr sectiondivponiscnddibvfsrt">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        Designation (If any)
                                    </p>
                                </td>
                                <td className="tddivmainbrdr sectiondivponiscnddibvscnd">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('nameAndContactDetails', 'designation', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className="tddivmainbrdr sectiondivponiscnddibvfsrt">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        Contact address
                                    </p>
                                </td>
                                <td className="tddivmainbrdr sectiondivponiscnddibvscnd">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('nameAndContactDetails', 'contactAddress', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className="tddivmainbrdr sectiondivponiscnddibvfsrt">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        Phone No
                                    </p>
                                </td>
                                <td className="tddivmainbrdr sectiondivponiscnddibvscnd">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('nameAndContactDetails', 'phoneNo', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className="tddivmainbrdr sectiondivponiscnddibvfsrt">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        E-mail Address
                                    </p>
                                </td>
                                <td className="tddivmainbrdr sectiondivponiscnddibvscnd">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('nameAndContactDetails', 'emailAddress', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </td>
                            </tr>


                        </table>
                    </div>
                    <div className="listoftblxsdivuldiv">
                        <li className="listoftblxsdivseca ">
                            <b>Reporting boundary</b> - Are the disclosures under this report made on a standalone basis (i.e. only for the entity)
                            or on a consolidated basis (i.e. for the entity and all the entities which form a part of its consolidated financial
                            statements, taken together)
                        </li>

                        <div className="yestrxtwarea mt-2 mb-3">
                            <div
                                ref={divRef}
                                contentEditable
                                className='textardibbrdrdivmain'
                                onInput={(e) => handleInput('reportingBoundary', 'details', e.currentTarget.textContent)}
                            >
                            </div>
                        </div>

                        <table className="tablbrdrmain">

                            <tr className="trdivmainbrdr">
                                <th className="thdivmainbrdr sectgpontthredivfsrt">
                                    <p className='prcnttxttemplytxtpp'>
                                        Region
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectgpontthredivscnd">
                                    <p className='prcnttxttemplytxtpp'>
                                        Entity</p>
                                </th>
                            </tr>

                            {
                                entityDetails?.map((item, ind) => {
                                    return (
                                        <tr>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity(ind, e, "religion")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity(ind, e, "entityName")}
                                                >
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }



                        </table>

                        <button className='adddinciondivimg'>
                            <img src={AddIcon} alt='...' onClick={addFormFieldsentityDetails} />
                            <span>Add More</span>
                        </button>
                    </div>
                    <div className="listoftblxsdivuldiv">
                        <li className="listoftblxsdivseca ">
                            Statement of Assurance:
                        </li>

                        <table className="tablbrdrmain">
                            <tbody>
                                <tr className="trdivmainbrdr">
                                    <th className="thdivmainbrdr sectgpontthredivfsrt">
                                        <p className='prcnttxttemplytxtpp'>
                                            Name of assurance provider
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr sectgpontthredivscnd">
                                        <p className='prcnttxttemplytxtpp'>
                                            Type of assurance obtained with statemen</p>
                                    </th>
                                </tr>

                                {
                                    stateMentOfAssurence?.map((item, ind) => {
                                        return (
                                            <tr>
                                                <td className="tddivmainbrdr">
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleChangestateMentOfAssurence(ind, e, "providerName")}
                                                    >
                                                    </div>
                                                </td>
                                                <td className="tddivmainbrdr">
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleChangestateMentOfAssurence(ind, e, "typeOfAssurence")}

                                                    >
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>

                        <div className='notedivadmoredivflx'>
                            <div className=''>
                                <p className='notetgvxtygfspopphjhsttxp' onClick={handlenoteclick}>Note</p>
                            </div>

                            <button className='adddinciondivimg'>
                                <img src={AddIcon} alt='...' onClick={addFormFieldstateMentOfAssurence} />
                                <span>Add More</span>
                            </button>
                        </div>

                        {notemodala && <div className="yestrxtwarea mt-2 mb-0">
                            {/* <p className='noiriittxoppdjoyyypp'>Note :</p> */}
                            <div
                                ref={divRef}
                                contentEditable
                                className='textardibbrdrdivmainitlc'
                                onInput={(e) => {
                                    setformvalue({
                                        ...formvalue,
                                        stateMentOfAssurenceNote: e.currentTarget.textContent
                                    });
                                }}

                            >
                            </div>
                        </div>}

                    </div>
                    <div className='listoftblxsdivuldiv'>
                        <p className="listoftblxsdivromainstyhhpp"> II . Products/Services
                        </p>
                        <li className='listoftblxsdivseca'>
                            Details of business activities (accounting for 90% of the turnover):

                        </li>
                        <table className="tablbrdrmain">

                            <tr className="trdivmainbrdr">
                                <th className="thdivmainbrdr sectionfrthdivmndivfsrt">
                                    <p className='prcnttxttemplytxtpp'>
                                        Sl. No.
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectionfrthdivmndivscnd">
                                    <p className='prcnttxttemplytxtpp'>
                                        Main Activity group code
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectionfrthdivmndivthrd">
                                    <p className='prcnttxttemplytxtpp'>
                                        Description of Main Activity group
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectionfrthdivmndivfrth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Business Activity Code
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectionfrthdivmndivffth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Description of Business Activity
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectionfrthdivmndivsxth">
                                    <p className='prcnttxttemplytxtpp'>
                                        % of turnover of the Company
                                    </p>
                                </th>
                            </tr>

                            {
                                entityDetails1?.map((item, ind) => {
                                    return (
                                        <tr>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity1(ind, e, "slNo")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity1(ind, e, "mainActivityGroupCode")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity1(ind, e, "mainActivityGroupDescription")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity1(ind, e, "businessActivityCode")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity1(ind, e, "descriptionOfBusinessActivity")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity1(ind, e, "percentageOfTurnOver")}
                                                >
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }



                        </table>
                        <div className='notedivadmoredivflx'>
                            <div className=''>
                                <p className='notetgvxtygfspopphjhsttxp' onClick={handlenoteclick}>Note</p>
                            </div>
                            <button className='adddinciondivimg'>
                                <img src={AddIcon} alt='...' onClick={addFormFieldsentityDetails1} />
                                <span>Add More</span>
                            </button>
                        </div>

                        {notemodala && <div className="yestrxtwarea mt-2 mb-0">
                            <div
                                ref={divRef}
                                contentEditable
                                className='textardibbrdrdivmain'
                                onInput={(e) => handleInput('businessActivitiesDetails', 'note', e.currentTarget.textContent)}
                            >
                            </div>
                        </div>
                        }

                    </div>
                    <div className="listoftblxsdivuldiv">
                        <li className="listoftblxsdivseca ">
                            Products/Services sold by the entity (accounting for 90% of the entity’s Turnover):
                        </li>

                        <table className="tablbrdrmain">

                            <tr className="trdivmainbrdr">
                                <th className="thdivmainbrdr sectiondivsixthfrst">
                                    <p className='prcnttxttemplytxtpp'>
                                        Sl. No
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivsixthscnd">
                                    <p className='prcnttxttemplytxtpp'>
                                        Name of Product/Service
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivsixththrd">
                                    <p className='prcnttxttemplytxtpp'>
                                        NIC
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivsixthfrth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Turnover (₹ crore)
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivsixthffth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Turnover (%)
                                    </p>
                                </th>
                            </tr>

                            {
                                entityDetails2?.map((item, ind) => {
                                    return (
                                        <tr>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity2(ind, e, "slNo")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity2(ind, e, "nameOfService")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity2(ind, e, "NIC")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity2(ind, e, "totalTurnOver")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity2(ind, e, "percentageOfTurnOver")}
                                                >
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }



                        </table>
                        <div className='notedivadmoredivflx'>
                            <div className=''>
                                <p className='notetgvxtygfspopphjhsttxp' onClick={handlenoteclick}>Note</p>
                            </div>
                            <button className='adddinciondivimg'>
                                <img src={AddIcon} alt='...' onClick={addFormFieldsentityDetails2} />
                                <span>Add More</span>
                            </button>
                        </div>

                        {notemodala && <div className="yestrxtwarea mt-2 mb-0">

                            <div
                                ref={divRef}
                                contentEditable
                                className='textardibbrdrdivmain'
                                onInput={(e) => handleInput('productSoldDetails', 'note', e.currentTarget.textContent)}
                            >
                            </div>
                        </div>}

                    </div>
                    <div className='listoftblxsdivuldiv'>
                        <p className="listoftblxsdivromainstyhhpp"> III. Operations

                        </p>
                        <li className='listoftblxsdivseca'>
                            Number of locations where plants and/or operations/offices of the entity are situated:
                        </li>
                        <table className="tablbrdrmain">
                            <tr className="trdivmainbrdr">
                                <th className="thdivmainbrdr sectiondivsevnththfrst">
                                    <p className='prcnttxttemplytxtpp'>
                                        Location
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivsevnththscnd">
                                    <p className='prcnttxttemplytxtpp'>
                                        Number of plants
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivsevnthththrd">
                                    <p className='prcnttxttemplytxtpp'>
                                        Number of office
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivsevnththfrth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Total
                                    </p>
                                </th>

                            </tr>

                            {
                                entityDetails3?.map((item, ind) => {
                                    return (
                                        <tr>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity3(ind, e, "location")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity3(ind, e, "noOfPlants")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity3(ind, e, "noOfOffice")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangeentity3(ind, e, "total")}
                                                >
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })
                            }

                        </table>
                        <button className='adddinciondivimg'>
                            <img src={AddIcon} alt='...' onClick={addFormFieldsentityDetails3} />
                            <span>Add More</span>
                        </button>
                        <div className="yestrxtwarea mt-2 mb-3">
                            <p className='noiriittxoppdjoyyypp'>Details :</p>
                            <div
                                ref={divRef}
                                contentEditable
                                className='textardibbrdrdivmain'
                                onInput={(e) => handleInput('plantLocationDetails', 'note', e.currentTarget.textContent)}
                            >
                            </div>
                        </div>
                    </div>
                    <div className='listoftblxsdivuldiv'>
                        <li className="listoftblxsdivseca ">
                            Markets served by the entity:
                        </li>
                        <ol type="a">
                            <div>
                                <li className='onnomemnewdibvlistdivsttspp' >
                                    Number of locations
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className="trdivmainbrdr">
                                        <th className="thdivmainbrdr sectiondiveightthfrst">
                                            <p className='prcnttxttemplytxtpp'>
                                                Locations
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondiveightthscnd">
                                            <p className='prcnttxttemplytxtpp'>
                                                Number
                                            </p>
                                        </th>
                                    </tr>


                                    <tr>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slOne', 'location', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slOne', 'number', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>


                                    </tr>


                                    <tr>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slTwo', 'location', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slTwo', 'number', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>


                                    </tr>

                                </table>
                                <div className="yestrxtwarea mt-2 mb-3">
                                    <p className='noiriittxoppdjoyyypp'>Details :</p>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                details: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div>
                                <li className='onnomemnewdibvlistdivsttspp'>
                                    What is the contribution of exports as a percentage of the total turnover of the entity?
                                </li>
                                <div className="yestrxtwarea mb-3">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('contributionDetails', 'totalTurnOver', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>

                                <p className='consoltedtetxstppvfspp'>Consolidated Revenue table:
                                </p>
                                <table className="tablbrdrmain ">
                                    <tr className="trdivmainbrdr">
                                        <th className="thdivmainbrdr sectindivnnthduvfrst">
                                            <p className='prcnttxttemplytxtpp'>
                                                Consolidated Revenue
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectindivnnthduvscnd" colSpan="2">
                                            <p className='prcnttxttemplytxtpp'>
                                                Amt in ₹ Cr
                                            </p>
                                        </th>
                                    </tr>
                                    <tr className="trdivmainbrdr">
                                        <th className="thdivmainbrdr sectiondivsevnththfrst">
                                            <p className='prcnttxttemplytxtpp'>
                                                Particulars
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivsevnththfrst">
                                            <p className='prcnttxttemplytxtpp'>
                                                FY {currentFY}
                                                Current Financial Year

                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivsevnththfrst">
                                            <p className='prcnttxttemplytxtpp'>
                                                FY {previousFY}
                                                Previous Financial Year

                                            </p>
                                        </th>
                                    </tr>

                                    {
                                        consolidatedTable?.map((item, ind) => {
                                            return (
                                                <tr>
                                                    <td className="tddivmainbrdr">
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeconsolidatedTable(ind, e, "particulerRevenue")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className="tddivmainbrdr">
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeconsolidatedTable(ind, e, "currentYearAmmount")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className="tddivmainbrdr">
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeconsolidatedTable(ind, e, "previousYearAmmount")}
                                                        >
                                                        </div>
                                                    </td>


                                                </tr>
                                            )
                                        })
                                    }

                                </table>

                                <div className='notedivadmoredivflx'>
                                    <div className=''>
                                        <p className='notetgvxtygfspopphjhsttxp' onClick={handlenoteclick}>Note</p>
                                    </div>
                                    <button className='adddinciondivimg'>
                                        <img src={AddIcon} alt='...' onClick={addFormFieldconsolidatedTable} />
                                        <span>Add More</span>
                                    </button>
                                </div>

                                {notemodala && <div className="yestrxtwarea mt-2 mb-2">
                                    {/* <p className='noiriittxoppdjoyyypp'>Note :</p> */}
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('contributionDetails', 'note', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>}


                            </div>
                            <div>
                                <li className='onnomemnewdibvlistdivsttspp' >
                                    A brief on types of customers
                                </li>
                                <div className="yestrxtwarea">

                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                briefTypeOfCustomers: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>
                            </div>
                        </ol>
                    </div>
                    <div className='listoftblxsdivuldiv'>
                        <p className="listoftblxsdivromainstyhhpp"> IV. Employees
                        </p>
                        <li className='listoftblxsdivseca'>
                            Details as at the end of Financial Year
                        </li>
                        <ol type="a">
                            <div>
                                <li className='onnomemnewdibvlistdivsttspp'>
                                    Employees and workers (including differently abled):
                                </li>

                                <table className='tablbrdrmain mb-2'>
                                    <tr className="trdivmainbrdr">
                                        <th className=" sectiondivtenthdivfrst" style={{ borderRight: "1px solid #0006" }}>
                                            <p className='prcnttxttemplytxtpp'>
                                                Sl.No
                                            </p>
                                        </th>
                                        <th className=" sectiondivtenthdivscnd" style={{ borderRight: "1px solid #0006" }}>
                                            <p className='prcnttxttemplytxtpp'>
                                                Particulars
                                            </p>
                                        </th>
                                        <th className=" sectiondivtenthdivthrd" style={{ borderRight: "1px solid #0006" }}>
                                            <p className='prcnttxttemplytxtpp'>
                                                Total
                                                (A)
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivfrth" colSpan="2">
                                            <p className='prcnttxttemplytxtpp'>
                                                Male
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivffth" colSpan="2">
                                            <p className='prcnttxttemplytxtpp'>
                                                Female
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivsxth" colSpan="2">
                                            <p className='prcnttxttemplytxtpp'>
                                                others
                                            </p>
                                        </th>
                                    </tr>
                                    <tr className="trdivmainbrdr">
                                        <th className="" style={{ borderRight: "1px solid #0006" }}>

                                        </th>
                                        <th className="" style={{ borderRight: "1px solid #0006" }}>

                                        </th>
                                        <th className="" style={{ borderRight: "1px solid #0006" }}>

                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivsvcnth">
                                            <p className='prcnttxttemplytxtpp'>
                                                No. (B)
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivegth">
                                            <p className='prcnttxttemplytxtpp'>
                                                %(B/A)
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivnnth" >
                                            <p className='prcnttxttemplytxtpp'>
                                                No. (C)
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivetnth" >
                                            <p className='prcnttxttemplytxtpp'>
                                                % (C/A)
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdiveelvnth" >
                                            <p className='prcnttxttemplytxtpp'>
                                                No. (D)
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivetwlvth">
                                            <p className='prcnttxttemplytxtpp'>
                                                % (D/A)
                                            </p>
                                        </th>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr' colSpan="12">
                                            <p className='simlettxghjpemidjjlklettxp'>Employees</p>
                                        </th>
                                    </tr>

                                    <tr>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                1
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Permanent (D)
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_e_e_permannent', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_permannent', 'male', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_permannent', 'male', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_permannent', 'female', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_permannent', 'female', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_permannent', 'others', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_permannent', 'others', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                2
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Other than Permanent (E)
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_e_e_otherThanPermannent', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_otherThanPermannent', 'male', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_otherThanPermannent', 'male', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_otherThanPermannent', 'female', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_otherThanPermannent', 'female', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_otherThanPermannent', 'others', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_otherThanPermannent', 'others', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                3
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Total employees (D + E)
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_e_e_totalEmployees', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_totalEmployees', 'male', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_totalEmployees', 'male', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_totalEmployees', 'female', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_totalEmployees', 'female', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_totalEmployees', 'others', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_e_totalEmployees', 'others', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr' colSpan="12">
                                            <p className='simlettxghjpemidjjlklettxp'>Worker </p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                1
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Permanent (G)
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_e_w_permannent', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_permannent', 'male', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_permannent', 'male', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_permannent', 'female', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_permannent', 'female', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_permannent', 'others', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_permannent', 'others', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                2
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Other than Permanent (H)
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_e_w_otherThanPermannent', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_otherThanPermannent', 'male', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_otherThanPermannent', 'male', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_otherThanPermannent', 'female', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_otherThanPermannent', 'female', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_otherThanPermannent', 'others', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_otherThanPermannent', 'others', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                3
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Total employees (G + H)
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_e_w_totalEmployees', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_totalEmployees', 'male', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_totalEmployees', 'male', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_totalEmployees', 'female', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_totalEmployees', 'female', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_totalEmployees', 'others', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'e_e_w_totalEmployees', 'others', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                </table>

                                <div className=''>
                                    <div className=''>
                                        <p className='notetgvxtygfspopphjhsttxp' onClick={handlenoteclick}>Note</p>
                                    </div>
                                </div>

                                {notemodala &&
                                    <div className="yestrxtwarea mt-2 mb-2">
                                        {/* <p className='noiriittxoppdjoyyypp'>Impression :</p> */}
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    e_impression: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </div>}


                                <div className="yestrxtwarea mt-2 mb-0">
                                    <p className='noiriittxoppdjoyyypp'>Add more details if any:</p>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                e_addMoreDetails: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>

                            </div>
                            <div>
                                <li className='onnomemnewdibvlistdivsttspp mt-2'>
                                    Differently abled Employees and workers:
                                </li>

                                <table className='tablbrdrmain mb-2'>
                                    <tr className="trdivmainbrdr">
                                        <th className=" sectiondivtenthdivfrst" style={{ borderRight: "1px solid #0006" }}>
                                            <p className='prcnttxttemplytxtpp'>
                                                Sl.No
                                            </p>
                                        </th>
                                        <th className=" sectiondivtenthdivscnd" style={{ borderRight: "1px solid #0006" }}>
                                            <p className='prcnttxttemplytxtpp'>
                                                Particulars
                                            </p>
                                        </th>
                                        <th className=" sectiondivtenthdivthrd" style={{ borderRight: "1px solid #0006" }}>
                                            <p className='prcnttxttemplytxtpp'>
                                                Total
                                                (A)
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivfrth" colSpan="2">
                                            <p className='prcnttxttemplytxtpp'>
                                                Male
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivffth" colSpan="2">
                                            <p className='prcnttxttemplytxtpp'>
                                                Female
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivsxth" colSpan="2">
                                            <p className='prcnttxttemplytxtpp'>
                                                others
                                            </p>
                                        </th>
                                    </tr>
                                    <tr className="trdivmainbrdr">
                                        <th className="" style={{ borderRight: "1px solid #0006" }}>

                                        </th>
                                        <th className="" style={{ borderRight: "1px solid #0006" }}>

                                        </th>
                                        <th className="" style={{ borderRight: "1px solid #0006" }}>

                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivsvcnth">
                                            <p className='prcnttxttemplytxtpp'>
                                                No. (B)
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivegth">
                                            <p className='prcnttxttemplytxtpp'>
                                                %(B/A)
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivnnth" >
                                            <p className='prcnttxttemplytxtpp'>
                                                No. (C)
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivetnth" >
                                            <p className='prcnttxttemplytxtpp'>
                                                % (C/A)
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdiveelvnth" >
                                            <p className='prcnttxttemplytxtpp'>
                                                No. (D)
                                            </p>
                                        </th>
                                        <th className="thdivmainbrdr sectiondivtenthdivetwlvth">
                                            <p className='prcnttxttemplytxtpp'>
                                                % (D/A)
                                            </p>
                                        </th>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr' colSpan="12">
                                            <p className='simlettxghjpemidjjlklettxp'>DIFFERENTLY ABLED EMPLOYEES
                                            </p>
                                        </th>
                                    </tr>

                                    <tr>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                1
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Permanent (D)
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_e_permannent', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_permannent', 'male', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_permannent', 'male', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_permannent', 'female', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_permannent', 'female', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_permannent', 'others', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_permannent', 'others', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                2
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Other than Permanent (E)
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_e_otherThanPermannent', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_otherThanPermannent', 'male', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_otherThanPermannent', 'male', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_otherThanPermannent', 'female', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_otherThanPermannent', 'female', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_otherThanPermannent', 'others', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_otherThanPermannent', 'others', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                3
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Total employees (D + E)
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_e_totalEmployees', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_totalEmployees', 'male', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_totalEmployees', 'male', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_totalEmployees', 'female', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_totalEmployees', 'female', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_totalEmployees', 'others', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_totalEmployees', 'others', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr' colSpan="12">
                                            <p className='simlettxghjpemidjjlklettxp'>DIFFERENTLY ABLED WORKERS
                                            </p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                1
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Permanent (G)
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_w_permannent', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_permannent', 'male', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_permannent', 'male', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_permannent', 'female', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_permannent', 'female', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_permannent', 'others', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_permannent', 'others', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                2
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Other than Permanent (H)
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_w_otherThanPermannent', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_otherThanPermannent', 'male', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_otherThanPermannent', 'male', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_otherThanPermannent', 'female', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_otherThanPermannent', 'female', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_otherThanPermannent', 'others', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_otherThanPermannent', 'others', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                3
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Total employees (G + H)
                                            </p>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_w_totalEmployees', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_totalEmployees', 'male', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_totalEmployees', 'male', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_totalEmployees', 'female', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_totalEmployees', 'female', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_totalEmployees', 'others', 'total')}
                                            >
                                            </div>
                                        </td>
                                        <td className="tddivmainbrdr">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_totalEmployees', 'others', 'percentage')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                </table>

                                <div className=''>
                                    <div className=''>
                                        <p className='notetgvxtygfspopphjhsttxp mt-2' onClick={handlenoteclick}>Note</p>
                                    </div>
                                </div>

                                {notemodala &&
                                    <div className="yestrxtwarea mt-2 mb-2">
                                        {/* <p className='noiriittxoppdjoyyypp'>Impression :</p> */}
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    p_impression: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </div>}

                                <div className="yestrxtwarea mt-2 mb-0">
                                    <p className='noiriittxoppdjoyyypp mb-2'>Add more details if any:</p>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                p_addMoreDetails: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>
                            </div>
                        </ol>
                    </div>
                    <div className='listoftblxsdivuldiv'>
                        <li className='listoftblxsdivseca'>
                            Participation/Inclusion/Representation of women
                        </li>
                        <table className='tablbrdrmain mb-2'>
                            <tr className="trdivmainbrdr">
                                <th className=" sectiondivelvnthdivfrst" style={{ borderRight: "1px solid #0006" }}>
                                    <p className='prcnttxttemplytxtpp'>
                                        Segment
                                    </p>
                                </th>
                                <th className=" sectiondivelvnthdivscnd" style={{ borderRight: "1px solid #0006" }}>
                                    <p className='prcnttxttemplytxtpp'>
                                        Total ( A )
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivelvnthdivthrd" colSpan="2">
                                    <p className='prcnttxttemplytxtpp'>
                                        No. and percentage of Females
                                    </p>
                                </th>
                            </tr>
                            <tr className="trdivmainbrdr">
                                <th className=" sectiondivelvnthdivfrst" style={{ borderRight: "1px solid #0006" }}>

                                </th>
                                <th className=" sectiondivelvnthdivscnd" style={{ borderRight: "1px solid #0006" }}>

                                </th>
                                <th className="thdivmainbrdr sectiondivelvnthdivfrth" >
                                    <p className='prcnttxttemplytxtpp'>
                                        No. (B)
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivelvnthdivffth">
                                    <p className='prcnttxttemplytxtpp'>
                                        % (B / A)
                                    </p>
                                </th>
                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className="tddivmainbrdr">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        Board of Directors
                                    </p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('boardOfDirectors', 'total', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'boardOfDirectors', 'percentageOfFemale', 'noOfWoment')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'boardOfDirectors', 'percentageOfFemale', 'percentage')}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className="tddivmainbrdr">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        Key Management Personnel
                                    </p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('keyManagementPersonnel', 'total', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'keyManagementPersonnel', 'percentageOfFemale', 'noOfWoment')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'keyManagementPersonnel', 'percentageOfFemale', 'percentage')}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className="tddivmainbrdr">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        Senior Leadership Team2
                                    </p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('seniorLeadershipTeamTwo', 'total', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'seniorLeadershipTeamTwo', 'percentageOfFemale', 'noOfWoment')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'seniorLeadershipTeamTwo', 'percentageOfFemale', 'percentage')}
                                    >
                                    </div>
                                </td>
                            </tr>
                        </table>

                        <div className=''>
                            <div className=''>
                                <p className='notetgvxtygfspopphjhsttxp mt-2' onClick={handlenoteclick}>Note</p>
                            </div>
                        </div>
                        {notemodala &&
                            <div className="yestrxtwarea mt-2 mb-0">
                                {/* <p className='noiriittxoppdjoyyypp'>Impression :</p> */}
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            note: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>}



                    </div>
                    <div className='listoftblxsdivuldiv'>
                        <li className='listoftblxsdivseca'>
                            Turnover rate for permanent employees and workers :(Disclose trends for the past 3 years)
                        </li>
                        <table className='tablbrdrmain mb-2'>
                            <tr className="trdivmainbrdr">
                                <th className=" sectiondivtwentondedivfrst" style={{ borderRight: "1px solid #0006" }}>
                                    <p className='prcnttxttemplytxtpp'>
                                        Particular
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivtwentondedivscnd" style={{ borderRight: "1px solid #0006" }} colSpan="3">
                                    <p className='prcnttxttemplytxtpp'>
                                        FY _____
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivtwentondedivthrd" style={{ borderRight: "1px solid #0006" }} colSpan="3">
                                    <p className='prcnttxttemplytxtpp'>
                                        FY _____
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivtwentondedivfrth" style={{ borderRight: "1px solid #0006" }} colSpan="3">
                                    <p className='prcnttxttemplytxtpp'>
                                        FY _____
                                    </p>
                                </th>
                            </tr>
                            <tr>
                                <th className="thdivmainbrdr ">
                                    {/* <p className='Malettsgvsgphsmkjghhutxtpp'>
                                    Permanent Employees
                                </p> */}
                                </th>
                                <th className="thdivmainbrdr sectiondivtwentondedivffth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Male
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivtwentondedivsxth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Female
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivtwentondedivsvnth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Others
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivtwentondedivegth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Male
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivtwentondedivninth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Female
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivtwentondedivtinth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Others
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivtwentondedivelvnth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Male
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivtwentondedivtwlvnth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Female
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectiondivtwentondedivthrtn">
                                    <p className='prcnttxttemplytxtpp'>
                                        Others
                                    </p>
                                </th>


                            </tr>
                            <tr>
                                <td className="tddivmainbrdr ">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        Permanent Employees
                                    </p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentEmployee', 'pastFirstYear', 'male')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentEmployee', 'pastFirstYear', 'female')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentEmployee', 'pastFirstYear', 'others')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentEmployee', 'pastSecondYear', 'male')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentEmployee', 'pastSecondYear', 'female')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentEmployee', 'pastSecondYear', 'others')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentEmployee', 'pastThreeYear', 'male')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentEmployee', 'pastThreeYear', 'female')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentEmployee', 'pastThreeYear', 'others')}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="tddivmainbrdr ">
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        Permanent Workers
                                    </p>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentWorkers', 'pastFirstYear', 'male')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentWorkers', 'pastFirstYear', 'female')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentWorkers', 'pastFirstYear', 'others')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentWorkers', 'pastSecondYear', 'male')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentWorkers', 'pastSecondYear', 'female')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentWorkers', 'pastSecondYear', 'others')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentWorkers', 'pastThreeYear', 'male')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentWorkers', 'pastThreeYear', 'female')}
                                    >
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'permanentWorkers', 'pastThreeYear', 'others')}
                                    >
                                    </div>
                                </td>
                            </tr>

                        </table>
                        <div className=''>
                            <div className=''>
                                <p className='notetgvxtygfspopphjhsttxp mt-2' onClick={handlenoteclick}>Note</p>
                            </div>
                        </div>

                        {notemodala &&
                            <div className="yestrxtwarea mt-2 mb-2">
                                {/* <p className='noiriittxoppdjoyyypp'>Impression :</p> */}
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            p_note: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>}

                    </div>
                    <div className='listoftblxsdivuldiv'>
                        <p className="listoftblxsdivromainstyhhpp"> V. Holding, Subsidiary and Associate Companies (including joint ventures) :
                        </p>
                        <li className='listoftblxsdivseca'>
                            (a) Names of holding / subsidiary / associate companies / joint ventures
                        </li>
                        <table className='tablbrdrmain'>
                            <tr className="trdivmainbrdr">
                                <th className=" thdivmainbrdr sectionromanfivdivfrst" >
                                    <p className='prcnttxttemplytxtpp'>
                                        Sl.No
                                    </p>
                                </th>
                                <th className=" thdivmainbrdr sectionromanfivdivscnd" >
                                    <p className='prcnttxttemplytxtpp'>
                                        Name of the holding / subsidiary / associate companies / joint ventures (A)
                                    </p>
                                </th>
                                <th className=" thdivmainbrdr sectionromanfivdivthrd">
                                    <p className='prcnttxttemplytxtpp'>
                                        Indicate whether holding/ Subsidiary/ Associate/ Joint Venture
                                    </p>
                                </th>
                                <th className=" thdivmainbrdr sectionromanfivdivfrth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Indicate whether holding/ Subsidiary/ Associate/ Joint Venture
                                    </p>
                                </th>
                                <th className=" thdivmainbrdr sectionromanfivdivffth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Does the entity indicated at column A, participate in the Business Responsibility initiatives of the listed entity? (Yes/No)
                                    </p>
                                </th>

                            </tr>

                            {
                                companyDetails?.map((item, ind) => {
                                    return (
                                        <tr>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangecompanyDetails(ind, e, "sNo")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangecompanyDetails(ind, e, "nameOfHolding")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangecompanyDetails(ind, e, "indicateHoldingOne")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangecompanyDetails(ind, e, "indicateHoldingTwo")}
                                                >
                                                </div>
                                            </td>
                                            <td className="tddivmainbrdr">
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangecompanyDetails(ind, e, "isResponsible")}
                                                >
                                                </div>
                                            </td>


                                        </tr>
                                    )
                                })
                            }

                            {/* <tr>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>
                                <td className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>


                            </tr> */}
                        </table>
                        <button className='adddinciondivimg'>
                            <img src={AddIcon} alt='...' onClick={addFormFieldcompanyDetails} />
                            <span>Add More</span>
                        </button>
                        <div className="yestrxtwarea mt-2 mb-3">
                            <p className='noiriittxoppdjoyyypp'>Brief (if any):
                            </p>
                            <div
                                ref={divRef}
                                contentEditable
                                className='textardibbrdrdivmain'
                                onInput={(e) => handleInput('associteCompanies', 'brief', e.currentTarget.textContent)}

                            >
                            </div>
                        </div>
                    </div>
                    {/* Point Check */}
                    <div className='listoftblxsdivuldiv'>
                        <p className="listoftblxsdivromainstyhhpp"> VI. CSR (Corporate Social Responsibility) :
                        </p>
                        <li className='listoftblxsdivseca'>
                            Details:
                        </li>
                        <table className='tablbrdrmain'>
                            <tr className="trdivmainbrdr">
                                <th className=" thdivmainbrdr setioncsrrmnscdivfdrst " >
                                    <p className='prcnttxttemplytxtpp'>
                                        Section
                                    </p>
                                </th>
                                <th className=" thdivmainbrdr setioncsrrmnscdivfscnd" >
                                    <p className='prcnttxttemplytxtpp'>
                                        Details
                                    </p>
                                </th>
                                <th className=" thdivmainbrdr setioncsrrmnscdivthrd" >
                                    <p className='prcnttxttemplytxtpp'>
                                        Company Particulars
                                    </p>
                                </th>
                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className='tddivmainbrdr '>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        (i)
                                    </p>
                                </td>
                                <td className='tddivmainbrdr '>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        Whether CSR is applicable as per section 135 of Companies Act, 2013:
                                        <span>(Yes/No)</span>
                                    </p>
                                </td>
                                <td className='tddivmainbrdr '>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('csrDetails', 'isCSRApplicable', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className='tddivmainbrdr '>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        (ii)
                                    </p>
                                </td>
                                <td className='tddivmainbrdr '>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        Turnover (in Rs.)
                                    </p>
                                </td>
                                <td className='tddivmainbrdr '>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('csrDetails', 'turnOver', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className='tddivmainbrdr '>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        (iii)
                                    </p>
                                </td>
                                <td className='tddivmainbrdr '>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                        Net worth (in Rs.)
                                    </p>
                                </td>
                                <td className='tddivmainbrdr '>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('csrDetails', 'netWorth', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div className='listoftblxsdivuldiv'>
                        <p className="listoftblxsdivromainstyhhpp">
                            VII. Transparency and Disclosures Compliances

                        </p>
                        <li className='listoftblxsdivseca'>
                            Complaints/Grievances on any of the principles (Principles 1 to 9) under the National Guidelines on Responsible Business Conduct:
                        </li>

                        <table className='tablbrdrmain'>
                            <tr className="trdivmainbrdr">
                                <th className="thdivmainbrdr sectinfrsttwnfrromahgdhdivfrst">
                                    <p className='prcnttxttemplytxtpp'>
                                        Stakeholder Group
                                    </p>
                                </th>
                                <th className=" thdivmainbrdr sectinfrsttwnfrromahgdhdivscnd"  >
                                    <p className='prcnttxttemplytxtpp'>
                                        Grievance Redressal Mechanism in Place (Yes/No) (If Yes, then provide web-link for grievance redress policy)
                                    </p>
                                </th>

                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className='tddivmainbrdr'>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Communities </p>
                                </td>
                                <th className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('stakeHolderGroupDetails', 'communityWeblink', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </th>

                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className='tddivmainbrdr'>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Investors (other than shareholders) </p>
                                </td>
                                <th className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('stakeHolderGroupDetails', 'investorWeblink', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </th>

                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className='tddivmainbrdr'>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Shareholders  </p>
                                </td>
                                <th className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('stakeHolderGroupDetails', 'shareholderWeblink', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </th>

                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className='tddivmainbrdr'>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Employees and workers</p>
                                </td>
                                <th className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('stakeHolderGroupDetails', 'employeeAndWorkerWeblink', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </th>

                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className='tddivmainbrdr'>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Customers

                                    </p>
                                </td>
                                <th className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('stakeHolderGroupDetails', 'customersWeblink', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </th>

                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className='tddivmainbrdr'>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Value Chain Partners</p>
                                </td>
                                <th className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('stakeHolderGroupDetails', 'valueChainPartnerWeblink', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </th>

                            </tr>
                            <tr className="trdivmainbrdr">
                                <td className='tddivmainbrdr'>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Other (please specify) </p>
                                </td>
                                <th className="tddivmainbrdr">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('stakeHolderGroupDetails', 'otherWeblink', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </th>

                            </tr>
                        </table>

                        <table className='tablbrdrmain mt-3 mb-2'>
                            <tr className="trdivmainbrdr">
                                <th className=" thdivmainbrdr sectionromansevenscnddivfsrt">
                                    <p className='prcnttxttemplytxtpp'>
                                        Stakeholder
                                    </p>
                                </th>
                                <th className=" thdivmainbrdr sectionromansevenscnddivscnd" colSpan="3" >
                                    <p className='prcnttxttemplytxtpp'>
                                        FY {currentFY}
                                    </p>
                                </th>
                                <th className=" thdivmainbrdr sectionromansevenscnddivthrd" colSpan="3" >
                                    <p className='prcnttxttemplytxtpp'>
                                        FY {previousFY}
                                    </p>
                                </th>
                            </tr>
                            <tr className="trdivmainbrdr">
                                <th className=" thdivmainbrdr sectionromansevenscnddivfrth" >
                                    <p className='prcnttxttemplytxtpp'>
                                        Group
                                    </p>
                                </th>
                                <th className=" thdivmainbrdr sectionromansevenscnddivffth" >
                                    <p className='prcnttxttemplytxtpp'>
                                        Number of complaints filed during the year


                                    </p>
                                </th>
                                <th className=" thdivmainbrdr sectionromansevenscnddivsxth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Number of complaints pending resolution at close of the year
                                    </p>
                                </th>
                                <th className=" thdivmainbrdr sectionromansevenscnddivsvnth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Remarks
                                    </p>
                                </th>
                                <th className=" thdivmainbrdr sectionromansevenscnddivegth" >
                                    <p className='prcnttxttemplytxtpp'>
                                        Number of complaints filed during the year


                                    </p>
                                </th>
                                <th className=" thdivmainbrdr sectionromansevenscnddivnnth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Number of complaints pending resolution at close of the year
                                    </p>
                                </th>
                                <th className=" thdivmainbrdr sectionromansevenscnddivtnth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Remarks
                                    </p>
                                </th>
                            </tr>
                            <tr className='trdivmainbrdr'>
                                <td className='tddivmainbrdr'>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Communities </p>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'communities', 'currentYear', 'noOfComplaint')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'communities', 'currentYear', 'noOfResolution')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'communities', 'currentYear', 'remarks')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'communities', 'previousYear', 'noOfComplaint')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'communities', 'previousYear', 'noOfResolution')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'communities', 'previousYear', 'remarks')}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr className='trdivmainbrdr'>
                                <td className='tddivmainbrdr'>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Investors (other than shareholders)</p>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'otherThanInvestor', 'currentYear', 'noOfComplaint')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'otherThanInvestor', 'currentYear', 'noOfResolution')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'otherThanInvestor', 'currentYear', 'remarks')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'otherThanInvestor', 'previousYear', 'noOfComplaint')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'otherThanInvestor', 'previousYear', 'noOfResolution')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'otherThanInvestor', 'previousYear', 'remarks')}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr className='trdivmainbrdr'>
                                <td className='tddivmainbrdr'>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Shareholders </p>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'shareHolder', 'currentYear', 'noOfComplaint')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'shareHolder', 'currentYear', 'noOfResolution')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'shareHolder', 'currentYear', 'remarks')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'shareHolder', 'previousYear', 'noOfComplaint')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'shareHolder', 'previousYear', 'noOfResolution')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'shareHolder', 'previousYear', 'remarks')}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr className='trdivmainbrdr'>
                                <td className='tddivmainbrdr'>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Employees and workers </p>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'employeeAndWorkers', 'currentYear', 'noOfComplaint')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'employeeAndWorkers', 'currentYear', 'noOfResolution')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'employeeAndWorkers', 'currentYear', 'remarks')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'employeeAndWorkers', 'previousYear', 'noOfComplaint')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'employeeAndWorkers', 'previousYear', 'noOfResolution')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'employeeAndWorkers', 'previousYear', 'remarks')}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr className='trdivmainbrdr'>
                                <td className='tddivmainbrdr'>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Customers</p>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'customers', 'currentYear', 'noOfComplaint')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'customers', 'currentYear', 'noOfResolution')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'customers', 'currentYear', 'remarks')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'customers', 'previousYear', 'noOfComplaint')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'customers', 'previousYear', 'noOfResolution')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'customers', 'previousYear', 'remarks')}
                                    >
                                    </div>
                                </td>
                            </tr>
                            <tr className='trdivmainbrdr'>
                                <td className='tddivmainbrdr'>
                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Value Chain Partners </p>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'valueChainPartner', 'currentYear', 'noOfComplaint')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'valueChainPartner', 'currentYear', 'noOfResolution')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'valueChainPartner', 'currentYear', 'remarks')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'valueChainPartner', 'previousYear', 'noOfComplaint')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'valueChainPartner', 'previousYear', 'noOfResolution')}
                                    >
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInputChange(e, 'valueChainPartner', 'previousYear', 'remarks')}
                                    >
                                    </div>
                                </td>
                            </tr>
                        </table>

                        <div className=''>
                            <div className=''>
                                <p className='notetgvxtygfspopphjhsttxp mt-2' onClick={handlenoteclick}>Note</p>
                            </div>
                        </div>

                        {notemodala &&
                            <div className="yestrxtwarea mt-2 mb-2">
                                {/* <p className='noiriittxoppdjoyyypp'>Impression :</p> */}
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            s_note: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>}
                    </div>

                    <div className='listoftblxsdivuldiv'>
                        <li className='listoftblxsdivseca'>
                            Overview of the entity’s material responsible business conduct issues:
                            Please indicate material responsible business conduct and sustainability issues pertaining to environmental and social matters that present a risk or an opportunity to your business, rationale for identifying the same, approach to adapt or mitigate the risk along with its financial implications as per the following format

                        </li>
                        <table className='tablbrdrmain'>
                            <tr className="trdivmainbrdr">
                                <th className="thdivmainbrdr sectionlsttwnfuvdivfrst">
                                    <p className='prcnttxttemplytxtpp'>
                                        Sl. No.
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectionlsttwnfuvdivscnd">
                                    <p className='prcnttxttemplytxtpp'>
                                        Material issue identified
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectionlsttwnfuvdivthrd">
                                    <p className='prcnttxttemplytxtpp'>
                                        Indicate whether risk or opportunity (R/O)
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectionlsttwnfuvdivfrth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Rationale for identifying the risk / opportunity
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectionlsttwnfuvdivffth">
                                    <p className='prcnttxttemplytxtpp'>
                                        In case of risk, approach to adapt or mitigate
                                    </p>
                                </th>
                                <th className="thdivmainbrdr sectionlsttwnfuvdivsxth">
                                    <p className='prcnttxttemplytxtpp'>
                                        Financial implications(Indicate positive /negative)


                                    </p>
                                </th>
                            </tr>

                            {
                                materialResponsibleBusinessConductIssues?.map((item, ind) => {
                                    return (
                                        <tr className='trdivmainbrdr'>
                                            <td className='tddivmainbrdr'>
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangematerialResponsibleBusinessConductIssues(ind, e, "sNo")}
                                                >
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangematerialResponsibleBusinessConductIssues(ind, e, "materialIssue")}
                                                >
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangematerialResponsibleBusinessConductIssues(ind, e, "indicateWheatherRisk")}
                                                >
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangematerialResponsibleBusinessConductIssues(ind, e, "rationaleRisk")}
                                                >
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangematerialResponsibleBusinessConductIssues(ind, e, "caseOfRisk")}
                                                >
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleChangematerialResponsibleBusinessConductIssues(ind, e, "financialImplications")}
                                                >
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })
                            }

                            {/* <tr className='trdivmainbrdr'>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>

                            </tr>
                            <tr className='trdivmainbrdr'>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>
                                <td className='tddivmainbrdr'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'>
                                        {text}
                                    </div>
                                </td>

                            </tr> */}

                        </table>
                        <button className='adddinciondivimg'>
                            <img src={AddIcon} alt='...' onClick={addFormFieldmaterialResponsibleBusinessConductIssues} />
                            <span>Add More</span>
                        </button>
                    </div>


                </ol>

                <div className="homePgCreModSubmitDiv">
                    <button
                        type='button'
                        onClick={submitHandler}
                        className="homePgCreModSubmitBtn btn1">
                        Submit
                    </button>
                </div>

            </section>

        </>
    )
}

export default SectionA