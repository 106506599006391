import React, { useEffect, useState } from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png";
import Select from "react-select";
import Viewicon from "../../Images/Icons/PNG/View icon.png";
import makeAnimated from "react-select/animated";
import HttpClientXml from '../../utils/HttpClientXml';
// import download from "../../Images/Icons/PNG/download.png"
import toast from 'react-hot-toast';
import HomeService from '../../Services/HomeService';
import { useNavigate } from 'react-router-dom';
const animatedComponents = makeAnimated();

const CreateTraining = () => {

    const typeInitial = {
        trainingType: "Virtual",
        trainingCategory: "",
        title: "",
        language: "",
        description: "",
        image: "",
    }

    const iniVideoDocument = {
        document: ""
    }

    const iniVideoLearningContent = {
        content: ""
    }

    const iniVideoData = {
        videoLink: "",
        videoTitle: "",
        videoDuration: { hour: "", min: "" },
        videoDocument: [iniVideoDocument],
        videoDescription: "",
        videoLearningContent: [iniVideoLearningContent]
    }

    const iniPhysicalData = {
        Budling: "",
        RoomNumber: "",
        StartDate: "",
        startTime: "",
        duration: { hour: "", min: "" },
        Documents: "",
        LearningContent: [iniVideoLearningContent]
    }

    const initrainingquiz = {
        question: "",
        A: "",
        B: "",
        C: "",
        D: "",
        answer: "",
        ansType: "checkbox"
    }
    const navigate = useNavigate()
    const [leftSideData, setLeftSideData] = useState(typeInitial)
    const [videos, setvideos] = useState([iniVideoData]);
    const [placeTime, setplaceTime] = useState([iniPhysicalData]);
    const [trainingQuiz, settrainingQuiz] = useState([initrainingquiz]);
    const [imageLoader5, setImageLoader5] = useState(false);
    const [imageLoader4, setImageLoader4] = useState(false);
    const [imageFile, setimageFile] = useState("");
    const [link, setlink] = useState("");
    // const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [internalOption, setInternalOption] = useState([])

    const [department, setDepartment] = useState([])
    const [departmentValue, setDepartmentValue] = useState("")
    const [targetAudience, setTargetAudience] = useState([])
    const [targetAudienceValue, setTargetAudienceValue] = useState([])
    const [validUrl, setValidUrl] = useState(true);
    const [trainerName, settrainerName] = useState("");
    const [materials, setmaterials] = useState("");
    const [trainingCost, settrainingCost] = useState("");

    console.log("8g47ug846h", targetAudience)



    //>>>>for onChange functionality for duration<<<<
    const handleChangeDur = (i, e) => {
        let newFormValues = [...placeTime];
        newFormValues[i]["duration"][e.target.name] = e.target.value;
        setplaceTime(newFormValues);
    };

    //>>>>handle change for first placeTime add more functionality<<<<
    const handleChange = (i, e) => {
        let newFormValues = [...placeTime];
        newFormValues[i][e.target.name] = e.target.value;
        setplaceTime(newFormValues);
    };

    //>>>>for first add more functionality<<<<
    const addFormFields = () => {
        setplaceTime([...placeTime, iniPhysicalData]);
    };

    //>>>>for removing add more field<<<<
    const removeFormFields = (i) => {
        let newFormValues = [...placeTime];
        newFormValues.splice(i, 1);
        setplaceTime(newFormValues);
    };

    //>>>>for videoLink functionality(upload video)<<<<
    const handleVideoChange = async (ind, e) => {
        setImageLoader5(true)
        let image = e.target.files[0];
        // setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await HttpClientXml.fileUplode("image-upload", "POST", form);

        if (res && res.status) {
            toast?.success("Document is uploaded successfully")
            setplaceTime(prevVideos => {
                const newVideos = [...prevVideos];
                newVideos[ind].Documents = res.image;
                return newVideos;
            });

        } else {
            // toast.error(res?.message);
        }
        setImageLoader5(false);
    };

    //>>>>function to nested add more content fields<<<<
    const addContentFields = (ind) => {
        let newDocArr = [...placeTime[ind]?.LearningContent, {
            content: ""
        }];
        setplaceTime(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, LearningContent: newDocArr })
                } else {
                    return item
                }
            })
        })

    };

    //>>>>handle change for nested add more functionality in Content<<<<
    const handleDocumentChange = (ind, index, e) => {
        let newDocArr = [...placeTime[ind].LearningContent];
        newDocArr[index][e.target.name] = e.target.value;
        setplaceTime(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, LearningContent: newDocArr })
                } else {
                    return item
                }
            })
        })
    };

    //>>>> Function to remove Content functionality<<<<
    const removeContentDocument = (ind) => {
        let newDocArr = [...placeTime[ind]?.LearningContent];
        newDocArr.splice(ind, 1);
        setplaceTime(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, LearningContent: newDocArr })
                } else {
                    return item
                }
            })
        })
    }

    // >>>>Handle Quiz <<<<
    const handleChangeTraingRadio = (i, e) => {
        const { value } = e.target
        // console.log("dddd", value)
        let newFormValues = [...trainingQuiz];
        // settrainingQuiz(newFormValues);
        // setTrainingQuizRadio
        if (value === "yes") {
            newFormValues[i]["A"] = value;
            newFormValues[i]["B"] = "";
            newFormValues[i]["answer"] = "A";
            settrainingQuiz(newFormValues)
        } else if (value === "no") {
            newFormValues[i]["B"] = value;
            newFormValues[i]["A"] = "";
            newFormValues[i]["answer"] = "B";
            settrainingQuiz(newFormValues)
        }
    };

    //>>>>for first add more functionality for trainingquiz add more<<<<
    const addFormFieldsTrainingQuiz = () => {
        settrainingQuiz([...trainingQuiz, initrainingquiz]);
    };

    //>>>>handle change for first trainingQuiz add more functionality<<<<
    const handleChangeTraingQuiz = (i, e) => {
        let newFormValues = [...trainingQuiz];
        newFormValues[i][e.target.name] = e.target.value;
        settrainingQuiz(newFormValues);
    };


    // >>>>Function to remove videodocument onChange functionality<<<<
    const removeVideoDocument = (ind, index) => {
        const delArr = videos[ind]?.videoDocument?.filter((ele, i) => i !== index)
        const newVideo = [...videos];
        newVideo[ind]["videoDocument"] = delArr
        setvideos(newVideo)
    }

    //for handle inner videodocument onChange functionality
    const HandleVideoDoc = async (ind, index, e) => {
        setImageLoader4(true)
        let image = e.target.files[0];
        // setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await HttpClientXml.fileUplode("image-upload", "POST", form);

        if (res && res.status) {
            toast?.success("Document is uploaded successfully")
            // console.log("UploadImageRes", res);
            let newDocArr = [...videos[ind].videoDocument];
            newDocArr[index].document = res?.image;

            setvideos(prev => {
                return prev?.map((item, i) => {
                    if (i === ind) {
                        return ({ ...item, videoDocument: newDocArr })
                    } else {
                        return item
                    }
                })
            })

        } else {

        }
        setImageLoader4(false);
    };

    // Function to remove videoContent functionality
    const removeContentDocumentvideo = (ind) => {
        let newDocArr = [...videos[ind].videoLearningContent];
        newDocArr.splice(ind, 1);
        setvideos(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, videoLearningContent: newDocArr })
                } else {
                    return item
                }
            })
        })
    }

    //handle change for nested add more functionality in videoContent
    const handleDocumentChangevideo = (ind, index, e) => {
        let newDocArr = [...videos[ind].videoLearningContent];
        newDocArr[index][e.target.name] = e.target.value;
        setvideos(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, videoLearningContent: newDocArr })
                } else {
                    return item
                }
            })
        })
    };

    //function to nested add more content fields
    const addContentFieldsvideo = (ind) => {
        let newDocArr = [...videos[ind].videoLearningContent, {
            content: ""
        }];
        setvideos(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, videoLearningContent: newDocArr })
                } else {
                    return item
                }
            })
        })

    };

    //function to nested add more document fields
    const addVideosFields = (ind) => {
        let newDocArr = [...videos[ind].videoDocument, {
            document: ""
        }];
        setvideos(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, videoDocument: newDocArr })
                } else {
                    return item
                }
            })
        })

    };


    //for videoLink functionality(upload video)
    const handleVideoChangevideo = async (ind, e) => {
        setImageLoader5(true)
        let image = e.target.files[0];
        // setUploading(true);

        const form = new FormData();
        form.append("image", image);
        let res = await HttpClientXml.fileUplode("image-upload", "POST", form);

        if (res && res.status) {
            toast?.success("Video is uploaded successfully")
            setvideos(prevVideos => {
                const newVideos = [...prevVideos];
                newVideos[ind].videoLink = res.image;
                return newVideos;
            });

        } else {

        }
        setImageLoader5(false);
    };


    //for removing add more field
    const removeVideoFormFields = (i) => {
        let newFormValues = [...videos];
        newFormValues.splice(i, 1);
        setvideos(newFormValues);
    };

    //for first add more functionality
    const addVideFormFields = () => {
        setvideos([...videos, iniVideoData]);
    };

    //for onChange functionality vdo duration
    const handleChangeVideoDur = (i, e) => {
        let newFormValues = [...videos];
        newFormValues[i]["videoDuration"][e.target.name] = e.target.value;
        setvideos(newFormValues);
    };

    //handle change for first upload vdo add more functionality
    const handleVidChange = (i, e) => {
        let newFormValues = [...videos];
        newFormValues[i][e.target.name] = e.target.value;
        setvideos(newFormValues);
    };


    // >>>Handle Image Upload<<<
    const handleImage = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        setimageFile(URL.createObjectURL(file));
        let formData = new FormData();
        formData.append("image", file);

        const res = await HttpClientXml.fileUplode("image-upload", "POST", formData);

        if (res && res.status) {
            toast.success("Image Uploaded Successfully");
            setLeftSideData({ ...leftSideData, image: res?.image });
        } else {
            toast.error(res?.message || "Something Wrong");
        }
    };

    // >>>Handle Removing Image<<<
    const handleRemoveImage = () => {
        setimageFile("");
        document.getElementById("hostedBy").value = "";
    };


    // >>>>GET ALL Employee<<<< 
    const fetchAllEmployee = async () => {
        const res = await HomeService.getAllEmployees();
        if (res && res?.status) {
            setInternalOption(res?.data)
        }
    }

    // >>>>GET ALL Department<<<< 
    const fetchAllDepartment = async () => {
        const res = await HomeService.getAllDepartment();
        if (res && res?.status) {
            setDepartment(res?.data)
        }
    }

    // >>>>Get Target-Audience By Department<<<<
    const viewTargetAudience = async (value) => {
        // console.log("fg4s5d135", value)
        // return
        let data = {
            department: value

        }
        const res = await HomeService.viewEmployeeDepartmentWise(data);
        if (res && res?.status) {
            setTargetAudience(res?.data)
        }
    }

    // >>>>Handle Submit<<<<
    const handleSubmit = async () => {
        const { trainingType, trainingCategory, title, language, description, image } = leftSideData
        if (trainingType === "") {
            return toast.error("Training Type is required");
        }
        else if (trainingCategory === "") {
            return toast.error("Training Category is required");
        } else if (title === "") {
            return toast.error("Training title is required");
        } else if (language === "") {
            return toast.error("Language is required");
        } else if (description === "") {
            return toast.error("Description is required");
            // } else if (placeTime?.[0]?.Budling == "") {
            //     return toast.error("Address field is required");
            // } else if (placeTime?.[0]?.Documents == "") {
            //     return toast.error("Upload Document field is required");
            // } else if (placeTime?.[0]?.RoomNumber == "") {
            //     return toast.error("RoomNumber field is required");
            // } else if (placeTime?.[0]?.StartDate == "") {
            //     return toast.error("Start Date field is required");
            // } else if (placeTime?.[0]?.startTime == "") {
            //     return toast.error("Start Time field is required");
            // } else if (placeTime?.[0]?.duration?.min == "") {
            //     return toast.error(" Duration in min field is required");
            // } else if (placeTime?.[0]?.LearningContent?.[0]?.content == "") {
            //     return toast.error("Video content field is required");
        } else if (link === "") {
            return toast.error("Link is required");
        } else if (trainingQuiz?.[0]?.question === "") {
            return toast.error("TrainingQuiz question is required");
        } else if (departmentValue === "") {
            return toast.error("Department is required");
        } else if (targetAudienceValue === "") {
            return toast.error("Target Audience is required");
        }
        else if (materials === "") {
            return toast.error("Materials is required");
        } else {
            if (trainingType === "Virtual") {

                let data = {
                    trainingType,
                    trainingCategory,
                    title,
                    language,
                    description,
                    image,
                    videos: videos,
                    link: link,
                    trainingQuiz: trainingQuiz,
                    trainerName: trainerName,
                    mentor: selectedOptions.map(option => option.value),
                    trainingCost: trainingCost,
                    department: departmentValue?.value,
                    targetAudiance: targetAudienceValue?.map(option => option?.value),
                    materials: materials
                }
                console.log("4xv6f41vx", data)
                // return
                const res = await HomeService.addTraining(data);
                if (res && res?.status) {
                    toast.success("Training added successfully")
                    setLeftSideData("")
                    setvideos([iniVideoData])
                    setplaceTime([iniPhysicalData])
                    settrainingQuiz([initrainingquiz])
                    setimageFile("")
                    setlink("")
                    setSelectedOptions([])
                    setInternalOption([])
                    setDepartment([])
                    setDepartmentValue('')
                    setTargetAudience([])
                    setTargetAudienceValue("")
                    settrainerName('')
                    setmaterials("")
                    settrainingCost("")

                    setTimeout(() => {
                        navigate('/educate')
                    }, 1000)
                }
            } else {
                let data = {
                    trainingType,
                    trainingCategory,
                    title,
                    language,
                    description,
                    image,
                    placeTime: placeTime,
                    link: link,
                    trainingQuiz: trainingQuiz,
                    trainerName: trainerName,
                    mentor: selectedOptions.map(option => option.value),
                    trainingCost: trainingCost,
                    department: departmentValue?.value,
                    targetAudiance: targetAudienceValue?.map(option => option?.value),
                    materials: materials
                }
                console.log("4xv6f41vx", data)
                // return
                const res = await HomeService.addTraining(data);
                if (res && res?.status) {
                    toast.success("Training added successfully")
                    setLeftSideData(typeInitial)
                    setvideos([iniVideoData])
                    setplaceTime([iniPhysicalData])
                    settrainingQuiz([initrainingquiz])
                    setimageFile("")
                    setlink("")
                    setSelectedOptions([])
                    setInternalOption([])
                    setDepartment([])
                    setDepartmentValue('')
                    setTargetAudience([])
                    setTargetAudienceValue("")
                    settrainerName('')
                    setmaterials("")
                    settrainingCost("")

                    setTimeout(() => {
                        navigate('/educate')
                    }, 1000)
                }

            }

        }



    }

    // >>>>Link url check <<<<
    const handleLinkChange = (e) => {
        const inputUrl = e.target.value;
        setlink(inputUrl);
        // Regular expression for matching a URL starting with http or https
        const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
        // Check if the input matches the URL pattern
        setValidUrl(urlPattern.test(inputUrl));
    };

    useEffect(() => {
        fetchAllEmployee()
        fetchAllDepartment()
    }, [])
    return (
        <>
            <section className='trainingcreatepagemain'>
                <div className='esgpagecontainer'>
                    <div className='trainingcreatepagdivflx'>
                        <div className='trainingcreatepageleft'>
                            <div className='trainingcreatepageleftbrdr'>
                                <div className=''>
                                    <form>
                                        <div className='trainingpageleftdivmain'>
                                            <p className='typstringtxtppcrt'>Types of Traning</p>
                                            <select
                                                id=""
                                                className='typstrningwhselct'
                                                name="trainingType"
                                                onChange={(e) => setLeftSideData({ ...leftSideData, trainingType: e.target.value })}
                                            >
                                                <option value="" disabled>Select option</option>
                                                <option value="Virtual">Virtual</option>
                                                <option value="physically">Physically</option>
                                            </select>
                                        </div>
                                        <div className='trainingpageleftdivmain'>
                                            <p className='typstringtxtppcrt'>Types of Category</p>
                                            <select
                                                id=""
                                                className='typstrningwhselct'
                                                name="trainingCategory"
                                                onChange={(e) => setLeftSideData({ ...leftSideData, trainingCategory: e.target.value })}

                                            >
                                                <option value=""  >Select option</option>
                                                <option value="Mandatory">Mandatory training </option>
                                                <option value="SkillDevelopment">Skill development training </option>
                                                <option value="NonTechnical">Non technical </option>


                                            </select>
                                        </div>
                                        <div className='trainingpageleftdivmain'>
                                            <p className='typstringtxtppcrt'> Trainning Title</p>
                                            <input
                                                type="text"
                                                className='typstrningwhselct'
                                                name="title"
                                                onChange={(e) => setLeftSideData({ ...leftSideData, title: e.target.value })}

                                            />
                                        </div>
                                        <div className='trainingpageleftdivmain'>
                                            <p className='typstringtxtppcrt'>Language</p>
                                            <input
                                                type="text"
                                                className='typstrningwhselct'
                                                name='language'
                                                onChange={(e) => setLeftSideData({ ...leftSideData, language: e.target.value })}

                                            />
                                        </div>
                                        <div className='trainingpageleftdivmain'>
                                            <p className='typstringtxtppcrt'>Thumbnail Image</p>

                                            <div className="bgcontainerupldbrdr">
                                                <div className="bgcrd">
                                                    <div className="bgcontainerupld" >
                                                        <div className="logoupld" style={{ cursor: "pointer" }}>
                                                            <img src={Addicon} alt="..." />
                                                            <div className="">
                                                                <input
                                                                    type="file"
                                                                    id="hostedBy"
                                                                    class="upldimagediv"
                                                                    onChange={handleImage}
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <p className="upldtxtppdiv">Upload Your Thumbnail</p>
                                                    {imageFile && <div className="bgcontainerupldfiledivabsltcrttraindiv">
                                                        <div className="imageuplddivcrttraingnew">
                                                            <img src={imageFile} alt />
                                                            <div className="imageupldcrstraingcrt" style={{
                                                                cursor: "pointer"
                                                            }}>
                                                                <i className="fa-solid fa-xmark" onClick={handleRemoveImage} />
                                                            </div>
                                                        </div>
                                                    </div>}

                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className='trainingcreatepageright'>
                            <div className=''>
                                <form>
                                    <div className='trainingrifgtdivgappp'>
                                        <p className="rsntxrpnewtxt">
                                            Training Description
                                        </p>
                                        <textarea
                                            className='txtdivallart'
                                            type="text"
                                            id="w3review"
                                            rows={4}
                                            cols={50}
                                            name="description"
                                            onChange={(e) => setLeftSideData({ ...leftSideData, description: e.target.value })}
                                        />

                                    </div>
                                    <div className='trainernameselectflx'>
                                        <div className='trainernametextinputdiv'>
                                            <p className="rsntxrpnewtxt">
                                                External Trainer Name(if any)
                                            </p>
                                            <input
                                                type='text'
                                                className='texttraingrightdiv'
                                                name="trainerName"
                                                value={trainerName}
                                                onChange={(e) => settrainerName(e.target.value)}
                                            />
                                        </div>



                                        <div className='trainernametextinputdiv'>
                                            <p className="rsntxrpnewtxt">
                                                Internal Trainer Name(if any)
                                            </p>
                                            <Select
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                defaultValue={[]}
                                                isMulti
                                                options={internalOption && internalOption?.map(ele => ({ value: ele?._id, label: `${ele?.firstName} ${ele?.lastName}` }))}
                                                onChange={(selectedOptions) => {
                                                    setSelectedOptions(selectedOptions);
                                                }}
                                                value={selectedOptions}

                                            />

                                        </div>
                                    </div>

                                    {/* Training Type Virtual*/}
                                    {leftSideData?.trainingType === "Virtual" &&
                                        <div className='trainingrifgtdivgappp'>
                                            {
                                                videos?.map((element, ind) => {
                                                    return (<>
                                                        <div className='trainingrifgtdivgapppbrdr' key={ind}>
                                                            <p className="rsntxrpnewtxtupld">
                                                                Upload video/s
                                                            </p>

                                                            <div className='videotitledesrtpupldflx'>
                                                                <div className='videottldescrtflx'>
                                                                    <div className='videottldivwhgt'>
                                                                        <p className="rsntxrpnewtxt"> Video Title</p>
                                                                        <input
                                                                            type='text'
                                                                            className='texttraingrightdiv'
                                                                            name="videoTitle"
                                                                            placeholder={`videoTitle ${ind + 1}`}
                                                                            value={element.videoTitle || ''}
                                                                            onChange={e => handleVidChange(ind, e)}
                                                                        />
                                                                    </div>
                                                                    <div className='videottldivwhgt'>
                                                                        <p className="rsntxrpnewtxt"> Description </p>
                                                                        <textarea className='txtdivallart'
                                                                            id="w3review"

                                                                            rows={4}
                                                                            cols={50}
                                                                            name="videoDescription"
                                                                            placeholder={`videoDescription ${ind + 1}`}
                                                                            value={element.videoDescription || ''}
                                                                            onChange={e => handleVidChange(ind, e)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='vdoulddivpbupldg'>
                                                                    <p className="rsntxrpnewtxt">Upload Video</p>
                                                                    <div className="bgcontainerupldbrdrdiv">
                                                                        <div className="bgcrd">
                                                                            <div className="bgcontaineruplddiv">
                                                                                <div className="logouplddiv">
                                                                                    <img src={Addicon} alt="..." style={{ cursor: "pointer" }} />
                                                                                    <div className="">
                                                                                        <input
                                                                                            type="file"
                                                                                            className="upldimagediv"
                                                                                            onChange={e => handleVideoChangevideo(ind, e)}
                                                                                            accept="video/*"
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="bgcontainerupldfiledivabslt">
                                                                                    {
                                                                                        videos[ind]?.videoLink !== "" && (
                                                                                            <div className='imageuplddivvideo' >

                                                                                                <video
                                                                                                    src={videos[ind]?.videoLink}
                                                                                                    alt=""

                                                                                                />
                                                                                                <div className='clsoedivmainrd'
                                                                                                    onClick={() => {

                                                                                                        setvideos(prevVideos => {
                                                                                                            const newVideos = [...prevVideos];
                                                                                                            newVideos[ind].videoLink = "";
                                                                                                            return newVideos;
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    <i
                                                                                                        class="fa-solid fa-xmark"

                                                                                                    ></i>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    {imageLoader5 ? (
                                                                                        <>
                                                                                            <h5>Video uploading....</h5>
                                                                                        </>
                                                                                    ) : (null)}
                                                                                </div>

                                                                            </div>
                                                                            <p className="upldtxtppdiv">Upload Your Video</p>
                                                                        </div>



                                                                        {/* <div className='downladimgmainplmaindiv'>
                                                                            <div className='downladimgmainpl'>
                                                                                {
                                                                                    videos[ind]?.videoLink !== "" ? (<div className='dateshowlinkdiv'><img src={download} alt="..." /></div>) : (<div><img src={download} alt="..." /></div>)
                                                                                }

                                                                                <div className='downloaddivmaindiv'>

                                                                                    {
                                                                                        videos[ind]?.videoLink !== "" && (
                                                                                            <>
                                                                                                <div className='downloaddivmaindivsize' >

                                                                                                    <a href={videos[ind]?.videoLink?.startsWith("http") ? videos[ind]?.videoLink : `http://${videos[ind]?.videoLink}`} target="_blank" rel="noopener noreferrer">
                                                                                                        {videos[ind]?.videoLink?.slice(0, 20)}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div> */}


                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className=' isinthetraining'>
                                                                <p className="rsntxrpnewtxtupld">
                                                                    What is in the training
                                                                </p>
                                                                <div className='vdoulddivpbflx'>
                                                                    <div className='vdoulddivpbflxwh'>
                                                                        <div className='viewicontextflxshpwd'>
                                                                            <p className="rsntxrpnewtxt">Duration of the training  </p>
                                                                            <div className='addimgicondivggview'>
                                                                                <img src={Viewicon} alt="..." />
                                                                                <span className='hivermespaecial'>Minimum 30 mins Video Upload</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='divtrainingmainflx'>
                                                                            <div className='divtraininhfrst'>

                                                                                <input
                                                                                    type='number'
                                                                                    className='texttraingrightdiv'
                                                                                    name="hour"
                                                                                    placeholder={`Duration in hour`}
                                                                                    value={element?.videoDuration?.hour || ''}
                                                                                    onChange={e => handleChangeVideoDur(ind, e)}
                                                                                />
                                                                            </div>
                                                                            <div className='divtraininhfrst'>
                                                                                <input
                                                                                    type='number'
                                                                                    className='texttraingrightdiv'
                                                                                    name="min"
                                                                                    placeholder={`Duration in min`}
                                                                                    value={element?.videoDuration?.min || ''}
                                                                                    onChange={e => handleChangeVideoDur(ind, e)}
                                                                                /></div>

                                                                        </div>

                                                                    </div>

                                                                    {/* Documents related to the training section */}
                                                                    <div className='vdoulddivpbflxwh'>
                                                                        <p className="rsntxrpnewtxt">Documents related to the training </p>
                                                                        <div className='uploaddocumentbrdraddbtnflx'>
                                                                            {
                                                                                element?.videoDocument?.map((element, index) => {

                                                                                    return (<>
                                                                                        <div className='uplddwnlddivbrtnmain'>
                                                                                            <div className='uploaddocumentbrdraddbtn'>
                                                                                                <div className="uploaddocumentbrdr">
                                                                                                    <div className='bgcrdupload'>
                                                                                                        <div className='bgcontaineruplddocumnet'>
                                                                                                            <div className='addimgicondivgg'>
                                                                                                                <img src={Addicon} alt="..." />
                                                                                                                <div className="">
                                                                                                                    <input
                                                                                                                        type="file"
                                                                                                                        className="upldimagediv"
                                                                                                                        onChange={e => HandleVideoDoc(ind, index, e)}
                                                                                                                    />
                                                                                                                </div>


                                                                                                            </div>

                                                                                                            {imageLoader4 ? (
                                                                                                                <>
                                                                                                                    <h5> Document uploading....</h5>
                                                                                                                </>
                                                                                                            ) : null}


                                                                                                        </div>
                                                                                                        {index ? (
                                                                                                            <div className='btnalignt'>
                                                                                                                <div style={{ cursor: "pointer" }}
                                                                                                                    type="button"
                                                                                                                    className=""
                                                                                                                    onClick={() => removeVideoDocument(ind, index)}
                                                                                                                // style={{ marginTop: '9px' }}
                                                                                                                >
                                                                                                                    <i className="fa-solid fa-trash"></i>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                    </div>

                                                                                                </div>
                                                                                                {/* <div className='btnalignt'>
                                                                            <button
                                                                                type="button"
                                                                                className='addmirfgghdivny'
                                                                                onClick={() => addVideosFields(ind)}
                                                                            >Add More</button>
                                                                        </div> */}

                                                                                            </div>
                                                                                            <div className='downladimgmainplmaindiv'>
                                                                                                <div className='downladimgmainpl'>
                                                                                                    {
                                                                                                        // videos[ind]?.videoDocument[index]?.document !== "" ? (<div className='dateshowlinkdiv'><img src={download} alt="..." /></div>) : (<div><img src={download} alt="..." /></div>)
                                                                                                    }

                                                                                                    {/* <div className='downloaddivmaindiv'>

                                                                                                        {
                                                                                                            videos[ind]?.videoDocument[index]?.document !== "" && (
                                                                                                                <>
                                                                                                                    <div className='downloaddivmaindivsize' >

                                                                                                                        <a href={videos[ind]?.videoDocument[index]?.document?.startsWith("http") ? videos[ind]?.videoDocument[index]?.document : `http://${videos[ind]?.videoDocument[index]?.document}`} target="_blank" rel="noopener noreferrer">
                                                                                                                            {videos[ind]?.videoDocument[index]?.document.slice(0, 20)}
                                                                                                                        </a>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                    </div> */}
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>




                                                                                    </>)
                                                                                })
                                                                            }

                                                                            <div className=''>
                                                                                <button
                                                                                    style={{ cursor: "pointer" }}
                                                                                    type="button"
                                                                                    className='addmirfgghdivny'
                                                                                    onClick={() => addVideosFields(ind)}
                                                                                >Add More</button>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div >

                                                                {/* What Will You Lern section */}
                                                                {
                                                                    element?.videoLearningContent?.map((element, index) => {
                                                                        return (
                                                                            <div className=''>
                                                                                <div className='trainingrifgtdivgappp'>
                                                                                    <div className='trainingqustonbtndltflx'>
                                                                                        <p className="rsntxrpnewtxt">What Will You Learn</p>
                                                                                        <div className=''>
                                                                                            {index ? (
                                                                                                <div className='btndltimgbgdivdkt'>
                                                                                                    <span
                                                                                                        style={{ cursor: "pointer", border: "1px solid red", borderRadius: "10px", padding: "1px 5px" }}
                                                                                                        type="button"
                                                                                                        className=""
                                                                                                        onClick={() => removeContentDocumentvideo(ind)}
                                                                                                    // style={{ marginTop: '9px' }}
                                                                                                    >
                                                                                                        <i className="fa-solid fa-trash" style={{ color: "red" }}></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className=''>
                                                                                        <div className='lrnupldinput'>
                                                                                            <input
                                                                                                type="text"
                                                                                                className='texttraingrightdiv'
                                                                                                name="content"
                                                                                                placeholder={`Content ${index + 1}`}
                                                                                                value={element?.content}
                                                                                                onChange={e => handleDocumentChangevideo(ind, index, e)}
                                                                                            />
                                                                                        </div>
                                                                                        {/* <div className='addimngrtring'>
                                                                            <img src={Addicon} alt='...' />
                                                                        </div> */}

                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                                <div className='addimngrtring'>
                                                                    <img src={Addicon}
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => addContentFieldsvideo(ind)}
                                                                        alt='...' />
                                                                </div>
                                                            </div>

                                                        </div>


                                                        {ind ? (
                                                            <button
                                                                type="button"
                                                                style={{ cursor: "pointer", color: "red", border: "1px solid red", borderRadius: "10px", padding: "1px 5px" }}
                                                                // style={{ cursor: "pointer" }}
                                                                className="btn btn-sm btn-danger ml-1"
                                                                onClick={() => removeVideoFormFields(ind)}
                                                            >
                                                                <i class="fas fa-trash"></i>
                                                            </button>
                                                        ) : null}
                                                    </>)
                                                })
                                            }

                                            <div className='addmirfgghdivnydiv'>
                                                <button
                                                    type='button'
                                                    className='addmirfgghdivny'
                                                    onClick={() => addVideFormFields()}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Add More
                                                </button>
                                            </div>
                                        </div >
                                    }
                                    {/* Training Type Virtual*/}


                                    {/* Training Type Physically */}
                                    {leftSideData?.trainingType === "physically" &&
                                        <div className="trainingrifgtdivgappp">

                                            {placeTime?.map((element, ind) => {
                                                return (
                                                    <>
                                                        <div className="trainingrifgtdivgapppbrdr">
                                                            <p className="rsntxrpnewtxtupld">PlaceTime</p>
                                                            <div className="videotitledesrtpupldflx">
                                                                <div className="videottldescrtflx">
                                                                    <div className="videottldivwhgt">
                                                                        <p className="rsntxrpnewtxt"> Address</p>
                                                                        <input
                                                                            type="text"
                                                                            className="texttraingrightdiv"
                                                                            name="Budling"
                                                                            placeholder={`Address ${ind + 1}`}
                                                                            value={element.Budling || ''}
                                                                            onChange={e => handleChange(ind, e)}
                                                                        />
                                                                    </div>
                                                                    <div className="videottldivwhgt">
                                                                        <p className="rsntxrpnewtxt"> Room Number </p>
                                                                        <textarea
                                                                            className="txtdivallart"
                                                                            id="w3review"
                                                                            rows={4}
                                                                            cols={50}
                                                                            name="RoomNumber"
                                                                            placeholder={`Room Number ${ind + 1}`}
                                                                            value={element.RoomNumber || ''}
                                                                            onChange={e => handleChange(ind, e)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="vdoulddivpbupldg">
                                                                    <p className="rsntxrpnewtxt">Documents</p>
                                                                    <div className="bgcontainerupldbrdrdiv">
                                                                        <div className="bgcrd">
                                                                            <div className="bgcontaineruplddiv">
                                                                                <div className="logouplddiv">
                                                                                    <img
                                                                                        src={Addicon}
                                                                                        alt="..."
                                                                                    />
                                                                                    <div className="">
                                                                                        <input
                                                                                            type="file"
                                                                                            className="upldimagediv"
                                                                                            onChange={e => handleVideoChange(ind, e)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="bgcontainerupldfiledivabslt" />
                                                                            </div>
                                                                            <p className="upldtxtppdiv">Upload Document</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {imageLoader5 ? (
                                                                    <>
                                                                        <h5> Document uploading....</h5>
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                            <div className=" isinthetraining">
                                                                <p className="rsntxrpnewtxtupld">What is in the training</p>
                                                                <div className="vdoulddivpbflx">
                                                                    <div className="vdoulddivpbflxwh">
                                                                        <div className="viewicontextflxshpwd">
                                                                            <p className="rsntxrpnewtxt">Duration of the training</p>
                                                                            <div className="addimgicondivggview">
                                                                                <img
                                                                                    src={Viewicon}
                                                                                    alt="..."
                                                                                />
                                                                                <span className="hivermespaecial">
                                                                                    Minimum 30 mins Video Upload
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="divtrainingmainflx">
                                                                            <div className="divtraininhfrst">
                                                                                <input
                                                                                    type="number"
                                                                                    className="texttraingrightdiv"
                                                                                    name="hour"
                                                                                    placeholder={`duration in hour`}
                                                                                    value={element?.duration?.hour || ''}
                                                                                    onChange={e => handleChangeDur(ind, e)}
                                                                                />
                                                                            </div>
                                                                            <div className="divtraininhfrst">
                                                                                <input
                                                                                    type="number"
                                                                                    className="texttraingrightdiv"
                                                                                    name="min"
                                                                                    placeholder={`duration in min`}
                                                                                    value={element?.duration?.min || ''}
                                                                                    onChange={e => handleChangeDur(ind, e)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vdoulddivpbflxwh">
                                                                        <div className="uploaddocumentbrdraddbtnflx">

                                                                            <div className="divtraininhfrst">
                                                                                <label htmlFor="">Start Date</label>
                                                                                <input
                                                                                    type="date"
                                                                                    className="texttraingrightdiv"
                                                                                    name="StartDate"
                                                                                    placeholder={`StartDate ${ind + 1}`}
                                                                                    value={element.StartDate || ''}
                                                                                    onChange={e => handleChange(ind, e)}
                                                                                />
                                                                            </div>
                                                                            <div className="divtraininhfrst">
                                                                                <label htmlFor="">Start Time</label>
                                                                                <input
                                                                                    type="time"
                                                                                    className="texttraingrightdiv"
                                                                                    name="startTime"
                                                                                    placeholder={`Start Time ${ind + 1}`}
                                                                                    value={element.startTime || ''}
                                                                                    onChange={e => handleChange(ind, e)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {element?.LearningContent?.map((element, index) => {
                                                                    return (
                                                                        <div className="">
                                                                            <div className="trainingrifgtdivgappp">
                                                                                <div className="trainingqustonbtndltflx">
                                                                                    <p className="rsntxrpnewtxt">What Will You Learn</p>
                                                                                    {/* <div className="" /> */}
                                                                                    <div className=''>
                                                                                        {index ? (
                                                                                            <div className='btndltimgbgdivdkt'>
                                                                                                <span
                                                                                                    type="button"
                                                                                                    className=""
                                                                                                    onClick={() => removeContentDocument(ind)}
                                                                                                    style={{ cursor: "pointer", color: "red", border: "1px solid red", borderRadius: "10px", padding: "1px 5px" }}
                                                                                                >
                                                                                                    <i className="fa-solid fa-trash"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="">
                                                                                    <div className="lrnupldinput">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="texttraingrightdiv"
                                                                                            name="content"
                                                                                            placeholder={`Content ${index + 1}`}
                                                                                            value={element?.content}
                                                                                            onChange={e => handleDocumentChange(ind, index, e)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })

                                                                }

                                                                <div className="addimngrtring">
                                                                    <img
                                                                        src={Addicon}
                                                                        onClick={() => addContentFields(ind)}
                                                                        alt="..."
                                                                        style={{ cursor: "pointer" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {ind ? (
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-danger ml-1"
                                                                style={{ color: "red", border: "1px solid red", borderRadius: "10px", padding: "1px 5px", cursor: "pointer" }}
                                                                onClick={() => removeFormFields(ind)}
                                                            >
                                                                <i class="fas fa-trash"></i>
                                                            </button>
                                                        ) : null}
                                                    </>
                                                )
                                            }
                                            )
                                            }

                                            <div className="addmirfgghdivnydiv">
                                                <button type="button" className="addmirfgghdivny" style={{ cursor: "pointer" }} onClick={() => addFormFields()}>
                                                    Add More
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {/* Training Type Physically */}



                                    <div className='trainingrifgtdivgappp'>
                                        <p className="rsntxrpnewtxt">
                                            Add any URL, where they will learn
                                        </p>
                                        <input
                                            type='text'
                                            className='texttraingrightdiv'
                                            name="link"
                                            value={link}
                                            // onChange={(e) => setlink(e.target.value)}
                                            onChange={handleLinkChange}
                                        />
                                        {!validUrl && <p className="error-msg" style={{
                                            fontSize: "10px",
                                            fontWeight: "bold",
                                            color: "red"
                                        }}>Please enter a valid URL starting with http:// or https://</p>}
                                    </div>

                                    <div className='trainingrifgtdivgappp'>
                                        <p className="rsntxrpnewtxt">
                                            Training price (if any)
                                        </p>
                                        <input
                                            type='number'
                                            className='texttraingrightdiv'
                                            name="trainingCost"
                                            value={trainingCost}
                                            onChange={(e) => settrainingCost(e.target.value)}
                                        />
                                    </div>

                                    {/* Quiz Section */}
                                    <div className='trainingrifgtdivgappp'>
                                        <p className="rsntxrpnewtxt">
                                            Quiz session:
                                        </p>

                                        {
                                            trainingQuiz?.map((element, ind) => {
                                                return (
                                                    <>
                                                        <div className=''>
                                                            <div className='aaddiconinptfkld'>
                                                                <div className='quentionnmbinputqust'>
                                                                    <span className='questionnmbr'>
                                                                        {`Q${ind + 1}`}
                                                                        <input
                                                                            type='text'
                                                                            className='inptyflddiccv'
                                                                            name="question"
                                                                            placeholder={`question ${ind + 1}`}
                                                                            value={element.question || ''}
                                                                            onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className='anwrdivtyp'>
                                                                    <p className='anstxtwrttype'>Answer Type :</p>
                                                                    <select
                                                                        name="ansType"
                                                                        value={element.ansType}
                                                                        id=""
                                                                        className="answerdrpdwn"
                                                                        onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                                    >
                                                                        <option>Select option</option>
                                                                        <option value="Radio">Radio</option>
                                                                        <option value="checkbox">Checkbox</option>
                                                                    </select>

                                                                </div>


                                                                {/* Cheked button */}
                                                                {
                                                                    element.ansType === "checkbox"
                                                                    &&
                                                                    (<div className='cjhekeddivtxtdivmain'>
                                                                        <>
                                                                            {/* A  */}
                                                                            <div className='cjhekeddivtxt'>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    name="answer"
                                                                                    value="A"
                                                                                    checked={element.answer === "A"}
                                                                                    onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                                                />
                                                                                <label htmlFor="vehicle1">A. </label>

                                                                                <input
                                                                                    type="text"
                                                                                    defaultValue=""
                                                                                    name="A"
                                                                                    placeholder={`A ${ind + 1}`}
                                                                                    value={element.A || ''}
                                                                                    onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                                />
                                                                                {/* <label htmlFor="vehicle1"> I have a bike</label> */}
                                                                            </div>


                                                                            {/* B */}
                                                                            <div className='cjhekeddivtxt'>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    name="answer"
                                                                                    value="B"
                                                                                    checked={element.answer === "B"}
                                                                                    onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                                                />
                                                                                <label htmlFor="vehicle1">B. </label>
                                                                                <input
                                                                                    type="text"
                                                                                    defaultValue=""
                                                                                    name="B"
                                                                                    placeholder={`B ${ind + 1}`}
                                                                                    value={element.B || ''}
                                                                                    onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                                />
                                                                                {/* <label htmlFor="vehicle1"> I have a bike</label> */}
                                                                            </div>

                                                                            {/* C */}
                                                                            <div className='cjhekeddivtxt'>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    name="answer"
                                                                                    value="C"
                                                                                    checked={element.answer === "C"}
                                                                                    onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                                                />
                                                                                <label htmlFor="vehicle1">C. </label>
                                                                                <input
                                                                                    type="text"
                                                                                    defaultValue=""
                                                                                    name="C"
                                                                                    placeholder={`C ${ind + 1}`}
                                                                                    value={element.C || ''}
                                                                                    onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                                />
                                                                                {/* <label htmlFor="vehicle1"> I have a bike</label> */}
                                                                            </div>

                                                                            <div className='cjhekeddivtxt'>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    name="answer"
                                                                                    value="D"
                                                                                    checked={element.answer === "D"}
                                                                                    onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                                                />
                                                                                <label htmlFor="vehicle1">D. </label>
                                                                                <input
                                                                                    type="text"
                                                                                    defaultValue=""
                                                                                    name="D"
                                                                                    placeholder={`D ${ind + 1}`}
                                                                                    value={element.D || ''}
                                                                                    onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                                />
                                                                                {/* <label htmlFor="vehicle1"> I have a bike</label> */}
                                                                            </div>

                                                                            {/* <div className='cjhekeddivtxt'>
                                                                    <label htmlFor="vehicle1">Answer : </label>
                                                                    <input
                                                                        type="text"
                                                                        defaultValue=""
                                                                        name="answer"
                                                                        placeholder={`answer option ${ind + 1}`}
                                                                        value={element.answer || ''}
                                                                        onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                    />
                                                                    
                                                                </div> */}

                                                                        </>

                                                                    </div>)
                                                                }

                                                                {
                                                                    element.ansType === "Radio"
                                                                    &&
                                                                    (
                                                                        <div className=''>
                                                                            <>
                                                                                {/* <div className='radiobtntxtpp'>
                                                                            <input type="radio" id="html" name="fav_language" defaultValue="HTML" />
                                                                            <label htmlFor="html">Yes</label>
                                                                        </div>

                                                                        <div className='radiobtntxtpp'>

                                                                            <input type="radio" id="css" name="fav_language" defaultValue="CSS" />
                                                                            <label htmlFor="css">No</label>
                                                                        </div> */}

                                                                                <div className='radiobtntxtpp'>
                                                                                    <input
                                                                                        type="radio"
                                                                                        id={`radio-${ind}-A`}
                                                                                        name="answer"
                                                                                        value="yes"
                                                                                        checked={element.answer === "A"}
                                                                                        onChange={(e) => handleChangeTraingRadio(ind, e)}
                                                                                    />
                                                                                    <label htmlFor={`radio-${ind}-A`}>Yes</label>
                                                                                </div>

                                                                                <div className='radiobtntxtpp'>
                                                                                    <input
                                                                                        type="radio"
                                                                                        id={`radio-${ind}-B`}
                                                                                        name="answer"
                                                                                        value="no"
                                                                                        checked={element.answer === "B"}
                                                                                        onChange={(e) => handleChangeTraingRadio(ind, e)}
                                                                                    />
                                                                                    <label htmlFor={`radio-${ind}-B`}>No</label>
                                                                                </div>

                                                                            </>

                                                                        </div>)
                                                                }

                                                            </div>

                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                        <div className='addimngrtring'>
                                            <img src={Addicon} type='button' onClick={() => addFormFieldsTrainingQuiz()} alt="..." style={{ cursor: "pointer" }} />
                                        </div>

                                    </div>

                                    <div className="trainingrifgtdivgappp ">
                                        <p className="rsntxrpnewtxt">Select Department Name</p>

                                        <Select
                                            closeMenuOnSelect={true}
                                            options={department && department?.map(ele => ({
                                                value: ele?.departmentName, label: ele?.departmentName
                                            }))}
                                            components={animatedComponents}
                                            onChange={(data) => {
                                                setDepartmentValue(data);
                                                viewTargetAudience(data?.value)
                                            }}
                                            value={departmentValue}
                                        />
                                    </div>

                                    <div className='trainingrifgtdivgappp'>
                                        <p className="rsntxrpnewtxt">
                                            Target Audience:
                                        </p>

                                        <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            options={targetAudience && targetAudience?.map(ele => ({ value: ele?._id, label: `${ele?.firstName} ${ele?.lastName}` }))}
                                            components={animatedComponents}
                                            onChange={(val) => {
                                                setTargetAudienceValue(val)
                                            }}
                                            value={targetAudienceValue}

                                        />
                                    </div>

                                    <div className='trainingrifgtdivgappp'>
                                        <p className="rsntxrpnewtxt">
                                            Materials/Equipment Needed
                                        </p>
                                        <textarea className='txtdivallart'
                                            id="w3review"
                                            rows={4}
                                            cols={50}
                                            name="materials"
                                            value={materials}
                                            onChange={(e) => setmaterials(e.target.value)}
                                        />
                                    </div>

                                    <div className="homePgCreModSubmitDiv">
                                        <button
                                            className="homePgCreModSubmitBtn"
                                            type="button"
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </button>
                                    </div>

                                </form>


                            </div>

                        </div>
                    </div>



                </div>
            </section>

        </>
    )
}

export default CreateTraining
