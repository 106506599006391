import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import HttpClientXml from "../../utils/HttpClientXml";


let initialState = {
    status: "idle",
    posts: [],
    team:[],
    addPost: {},
    reaction: {},
    isLoading: false,
    isSuccess: false,
    isError: false,
    commentData:[]
};

//get posts

export const getAllPosts = createAsyncThunk(
    "get_all_posts",
    async (data) => {
        let response = await HttpClientXml?.requestData("view-all-post",data,"POST");
        if (response?.status) {
            // console.log("sortedData",{...response?.data,...response?.eventData})
            return response;
        } else {

        }
    }
);
//add post
export const addPosts = createAsyncThunk(
    "add_posts",
    async (data) => {
        let response =  await HttpClientXml?.requestData("user-add-post",data,"POST");
        if (response?.status) {
            return response?.data;
        } else {
            toast.error("Can't get data. Something went wrong");
        }
    }
);

// Define the updatePostsLocally action to update posts in the Redux store without API call
export const updatePostsLocally = createAsyncThunk(
    "update_posts_locally",
    (data) => {
        return data;
    }
);

//add reaction
export const addReaction = createAsyncThunk(
    'add_post_reaction', async (data) => {
        let response =await HttpClientXml?.requestData("add-reaction",data,"POST");
        // console.log("responseData", response)
        if (response.status === 200) {
            return response?.data;
        } else {
            toast.error("Can't get data. Something went wrong");
        }

    }
)

//get comment
export const getComment = createAsyncThunk(
    "get_comment",
    async (data) => {
        let response =  await HttpClientXml?.requestData("view-comment",data,"POST");
        if (response?.status) {
            return response?.data;
        } else {
            // toast.error("Can't get data. Something went wrong");
        }
    }
);

export const PostSlice = createSlice({
    name: "post_actions",
    initialState,
    reducers: {
        clearPostSate: (state) => {
            state.status = "idle";
            state.posts = [];
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.reaction = {};
        },

        toggleDescription: (state, action) => {
            const item = state?.posts?.find(item => item?._id === action?.payload);
            console.log(item, "itemmmkl")
            // if (item) {
            // item?.showDescription = !item?.showDescription
            // }
        },

        changePostDada: (state, action) => {
            console.log("itemmmklpp", state.posts)

        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllPosts.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getAllPosts.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                const newData = [...(payload?.data || []), ...(payload?.eventData || []), ...(payload?.advocacyPost || []), ...(payload?.repostData || [])];
                const sortedData = newData?.sort((a, b) => new Date(b?.createdOn) - new Date(a?.createdOn))
                state.posts = sortedData;
                state.team=payload?.data
                state.isLoading = false;
            })
            .addCase(getAllPosts.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
                state.isLoading = false;
            })
            .addCase(addReaction.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(addReaction.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.reaction = payload
                state.isLoading = false;
            })
            .addCase(addReaction.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
                state.isLoading = false;
            })
            .addCase(addPosts.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(addPosts.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.addPost = payload
                state.isLoading = false;
            })
            .addCase(addPosts.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
                state.isLoading = false;
            })


            .addCase(getComment.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(getComment.fulfilled, (state, { payload }) => {
                state.status = "success";
                state.isSuccess = true;
                state.commentData = payload
                state.isLoading = false;
            })
            .addCase(getComment.rejected, (state) => {
                state.status = "failed";
                state.isError = true;
                state.isSuccess = false;
                state.isLoading = false;
            })


            .addCase(updatePostsLocally.fulfilled, (state, { payload }) => {
                state?.posts?.push(payload);
            });
    },
});

export const { clearPostSate, toggleDescription, changePostDada } = PostSlice.actions;

export default PostSlice.reducer;