import React from 'react'
import video from "../../Images/Icons/PNG/video.png";
import Expand from "../../Images/Icons/PNG/Expand.png";
// import EducateOngoing from './EducateOngoing';
import EducateOngoingsingle from './EducateOngoingsingle';
import EducateStatusCard from './EducateStatusCard';
import Ongoing from './Ongoing';
import Requestedtraining from './Requestedtraining';
import DocumentRepositorymain from './DocumentRepositorymain';
const OngoingDetails = () => {
    return (
        <>
            <section className='ongingsinglemian'>
                <div className='esgpagecontainer'>
                    <div className='educatemainflx'>
                        <div className='ongoingmainleft'>
                            <div className='videoongoing'>
                                <div className="top">
                                    <div className="head bulleBoardHead">
                                        <figure className="bulleBoardFig">
                                            <img
                                                src={video}
                                                alt="..."
                                            />
                                        </figure>
                                        <h4>On Going</h4>
                                    </div>
                                    <div className="add_seeallflx">
                                        <div className="tyEvMainDiv">
                                            <figure className="bulleBoardFig">
                                                <img src={Expand} alt="..." />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className='bottomsinfledivflx'>
                                    <EducateOngoingsingle />
                                </div>
                            </div>
                        </div>
                        <div className='ongoingmainright'>
                            <div className='sinfglepagerightbtm'>
                                <EducateStatusCard />
                            </div>

                            {/* <div className='sinfglepagerightbtm'>
                                <Ongoing />
                            </div> */}

                            <div className='sinfglepagerightbtm'>
                                <Requestedtraining />
                            </div>

                            <div className='sinfglepagerightbtm'>
                            <DocumentRepositorymain />
                            </div>
                           
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OngoingDetails
