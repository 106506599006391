import React, { useState } from 'react'
import "./Ensure.css"
import Principleone from './Principleone';
import Principletwo from './Principletwo';
import PrinicpleThree from './PrinicpleThree';
import Principlefour from './Principlefour';
import Principlefive from './Principlefive';
import Principlesix from './Principlesix';
import Principleseven from './Principleseven';
import Chatbot from '../Chatbot/Chatbot';
import Principleeghit from './Principleeghit';
import Principlenine from './Principlenine';
import SectionA from './SectionA';
import SectionB from './SectionB';

const Index = () => {
    const [princicpletab, setPrincicpletab] = useState(0);
    const [togglesectionmdl, setTogglesectionmdl] = useState(false)
    const [princicpletabdrp, setPrincicpletabdrp] = useState(0)

    console.log("princicpletab", princicpletabdrp)

    const handlebtntab = (tabNumber) => {
        setPrincicpletab(tabNumber);
        setTogglesectionmdl(false)
        setPrincicpletabdrp(false)
        setDashboardsidebar(!dashboardsidebar)
    };

    const handlebtntabnewald = (tabNumberr) => {
        setPrincicpletabdrp(tabNumberr)
        setTogglesectionmdl(true)
        setDashboardsidebar(!dashboardsidebar)
    }

    const tooglesectioncclick = () => {
        setTogglesectionmdl(!togglesectionmdl)
        setPrincicpletab(false)
        setPrincicpletabdrp(1)
        setDashboardsidebar(!dashboardsidebar)
    }

    const [dashboardsidebar, setDashboardsidebar] = useState(false);

    const handledashboard = () => {
        setDashboardsidebar(!dashboardsidebar)
    }
    return (
        <>
            <section className=''>
                <div className='sidebardivensurediv'>
                    <div className='dashborddivflx'>
                        <div className='dashbrdricondivflx'>
                            <i className="fa-solid fa-grip"></i>
                            <div className=''>
                                <p className='dsgbrdrttpp'> Data Aggreegation</p>
                            </div>
                        </div>
                        <div className=''>
                            <i className="fa-solid fa-angle-down"></i>
                        </div>
                    </div>

                    <div className='borderdivleft'>

                        {/* <div className='envhjtygljspbndivflx'>
                                <span className='envhjtygljspbn'></span>
                                Environment

                            </div> */}
                        <div className='bsrdivtabdivlft'>
                            <div className=''>
                                <p className='brstgdutxtpp'>
                                    BRSR
                                </p>
                            </div>

                            <div className=''>
                                <button className={princicpletab === 1 ? "princoiplebtnmainactbdiv" : "princoiplebtnmain"} onClick={() => handlebtntab(1)}>
                                    Section A
                                </button>
                                <button className={princicpletab === 2 ? "princoiplebtnmainactbdiv" : "princoiplebtnmain"} onClick={() => handlebtntab(2)}>
                                    Section B
                                </button>
                            </div>

                            <div>
                                <button className={togglesectionmdl ? "princoiplebtnmainactbchng" : "princoiplebtnmain"} onClick={tooglesectioncclick}>
                                    Section C
                                </button>
                                {togglesectionmdl &&
                                    <div className='bsrdivtabdivlftdivflx'>
                                        <button className={princicpletabdrp === 1 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(1)}>
                                            Principle 1
                                        </button>
                                        <button className={princicpletabdrp === 2 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(2)}>
                                            Principle 2
                                        </button>
                                        <button className={princicpletabdrp === 3 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(3)}>
                                            Principle 3
                                        </button>
                                        <button className={princicpletabdrp === 4 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(4)}>
                                            Principle 4
                                        </button>
                                        <button className={princicpletabdrp === 5 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(5)}>
                                            Principle 5
                                        </button>
                                        <button className={princicpletabdrp === 6 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(6)}>
                                            Principle 6
                                        </button>
                                        <button className={princicpletabdrp === 7 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(7)}>
                                            Principle 7
                                        </button>
                                        <button className={princicpletabdrp === 8 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(8)}>
                                            Principle 8
                                        </button>
                                        <button className={princicpletabdrp === 9 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(9)}>
                                            Principle 9
                                        </button>
                                    </div>}
                            </div>
                        </div>

                        {/* <div className='envhjtygljspbndivflxmaindiv'>
                            <div className='envhjtygljspbndivflx'>
                                <span className='envhjtygljspbn'></span>
                                Social
                            </div>
                        </div> */}
                        {/* <div className='envhjtygljspbndivflxmaindiv'>
                            <div className='envhjtygljspbndivflx'>
                                <span className='envhjtygljspbn'></span>
                                Governence
                            </div>
                        </div> */}
                    </div>

                    <div className='envhjtygljspbndivflxbotmiocn'>
                        <div className='envhjtygljspbndivflx'>
                            <i className="fa-solid fa-location-crosshairs"></i>
                            Progress Tracker
                        </div>
                        <div className='envhjtygljspbndivflx'>
                            <i className="fa-solid fa-file-lines"></i>
                            Report
                        </div>
                        <div className='envhjtygljspbndivflx'>
                            <i className="fa-solid fa-gear"></i>
                            Settings
                        </div>
                    </div>
                </div>

                <div className="sidebardetialsdivmain">


                    <div className='pricpletacvwidh'>

                        {/* <div className='contenttabbardivflsx'> */}
                        <div className=''>
                            {princicpletab === 1 && (
                                <>
                                    <SectionA />
                                </>
                            )}

                            {princicpletab === 2 && (
                                <>
                                    <SectionB />
                                </>
                            )}

                            {princicpletabdrp === 1 && (
                                <>
                                    <Principleone setPrincicpletabdrp={setPrincicpletabdrp} setTogglesectionmdl={setTogglesectionmdl} />
                                </>
                            )}

                            {princicpletabdrp === 2 && (
                                <>
                                    <Principletwo setPrincicpletabdrp={setPrincicpletabdrp} setTogglesectionmdl={setTogglesectionmdl} />
                                </>
                            )}

                            {princicpletabdrp === 3 && (
                                <>

                                    <PrinicpleThree setPrincicpletabdrp={setPrincicpletabdrp} setTogglesectionmdl={setTogglesectionmdl} />

                                </>
                            )}

                            {princicpletabdrp === 4 && (
                                <>
                                    <Principlefour setPrincicpletabdrp={setPrincicpletabdrp} setTogglesectionmdl={setTogglesectionmdl} />
                                </>
                            )}

                            {princicpletabdrp === 5 && (
                                <>
                                    <Principlefive setPrincicpletabdrp={setPrincicpletabdrp} setTogglesectionmdl={setTogglesectionmdl} />
                                </>
                            )}

                            {princicpletabdrp === 6 && (
                                <>
                                    <Principlesix setPrincicpletabdrp={setPrincicpletabdrp} setTogglesectionmdl={setTogglesectionmdl} />
                                </>
                            )}

                            {princicpletabdrp === 7 && (
                                <>
                                    <Principleseven setPrincicpletabdrp={setPrincicpletabdrp} setTogglesectionmdl={setTogglesectionmdl} />
                                </>

                            )}

                            {princicpletabdrp === 8 && (
                                <>
                                    <Principleeghit setPrincicpletabdrp={setPrincicpletabdrp} setTogglesectionmdl={setTogglesectionmdl} />
                                </>
                            )}

                            {princicpletabdrp === 9 && (
                                <>
                                    <Principlenine setPrincicpletabdrp={setPrincicpletabdrp} setTogglesectionmdl={setTogglesectionmdl} />
                                </>
                            )}

                        </div>
                        {/* <div className='bardivmainchjy' onClick={handledashboard}>
                                <i class="fa-solid fa-bars"></i>
                            </div> */}
                        {/* </div> */}

                    </div>


                </div>

            </section>


            <div className={dashboardsidebar ? 'sidebardivensuredivnewres actv' : 'sidebardivensuredivnewres'}>
                <div className='dashborddivflx'>
                    <div className='dashbrdricondivflx'>
                        <i className="fa-solid fa-grip"></i>
                        <div className=''>
                            <p className='dsgbrdrttpp'> Data Aggreegation</p>
                        </div>
                    </div>
                    <div className=''>
                        <i className="fa-solid fa-angle-down"></i>
                    </div>
                </div>

                <div className='borderdivleft'>

                    {/* <div className='envhjtygljspbndivflx'>
                                <span className='envhjtygljspbn'></span>
                                Environment

                            </div> */}
                    <div className='bsrdivtabdivlft'>
                        <div className=''>
                            <p className='brstgdutxtpp'>
                                BRSR
                            </p>
                        </div>

                        <div className=''>
                            <button className={princicpletab === 1 ? "princoiplebtnmainactbdiv" : "princoiplebtnmain"} onClick={() => handlebtntab(1)}>
                                Section A
                            </button>
                            <button className={princicpletab === 2 ? "princoiplebtnmainactbdiv" : "princoiplebtnmain"} onClick={() => handlebtntab(2)}>
                                Section B
                            </button>
                        </div>

                        <div>
                            <button className={togglesectionmdl ? "princoiplebtnmainactbchng" : "princoiplebtnmain"} onClick={tooglesectioncclick}>
                                Section C
                            </button>
                            {togglesectionmdl &&
                                <div className='bsrdivtabdivlftdivflx'>
                                    <button className={princicpletabdrp === 1 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(1)}>
                                        Principle 1
                                    </button>
                                    <button className={princicpletabdrp === 2 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(2)}>
                                        Principle 2
                                    </button>
                                    <button className={princicpletabdrp === 3 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(3)}>
                                        Principle 3
                                    </button>
                                    <button className={princicpletabdrp === 4 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(4)}>
                                        Principle 4
                                    </button>
                                    <button className={princicpletabdrp === 5 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(5)}>
                                        Principle 5
                                    </button>
                                    <button className={princicpletabdrp === 6 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(6)}>
                                        Principle 6
                                    </button>
                                    <button className={princicpletabdrp === 7 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(7)}>
                                        Principle 7
                                    </button>
                                    <button className={princicpletabdrp === 8 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(8)}>
                                        Principle 8
                                    </button>
                                    <button className={princicpletabdrp === 9 ? "princoiplebtnmainactb" : "princoiplebtnmainkk"} onClick={() => handlebtntabnewald(9)}>
                                        Principle 9
                                    </button>
                                </div>}
                        </div>
                    </div>

                    {/* <div className='envhjtygljspbndivflxmaindiv'>
                            <div className='envhjtygljspbndivflx'>
                                <span className='envhjtygljspbn'></span>
                                Social
                            </div>
                        </div> */}
                    {/* <div className='envhjtygljspbndivflxmaindiv'>
                            <div className='envhjtygljspbndivflx'>
                                <span className='envhjtygljspbn'></span>
                                Governence
                            </div>
                        </div> */}
                </div>

                <div className='envhjtygljspbndivflxbotmiocn'>
                    <div className='envhjtygljspbndivflx'>
                        <i className="fa-solid fa-location-crosshairs"></i>
                        Progress Tracker
                    </div>
                    <div className='envhjtygljspbndivflx'>
                        <i className="fa-solid fa-file-lines"></i>
                        Report
                    </div>
                    <div className='envhjtygljspbndivflx'>
                        <i className="fa-solid fa-gear"></i>
                        Settings
                    </div>
                </div>
            </div>

            <Chatbot />


        </>
    )
}

export default Index
