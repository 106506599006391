import React, { useEffect, useState } from 'react'
import "./Engage.css";
// import add from "../../Images/Icons/PNG/Add icon.png";
// import see from "../../Images/Icons/PNG/See all icon.png";
// import Bulletin_Board from "../../Images/Icons/PNG/Bulletin_Board.png";
// import Hall_of_fame from "../../Images/Icons/PNG/Hall_of_fame.png"
import 'react-tabs/style/react-tabs.css';
import BulletinBoard from './BulletinBoard';
import EducateStatusCard from '../Educate/EducateStatusCard';
import Hallofframe from './Hallofframe';
import Initiative from './Initiative';
import MyTeam from './MyTeam';
import Sharethoughtmodal from "../../Modal/Sharethoughtmodal"
import Sharethought from './Sharethought';
import SharePost from './SharePost';
import EventCard from './EventCard';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPosts, getComment } from '../../Redux/Slice/PostSlice';
import ShareRepost from './ShareRepost';
import Chatbot from '../Chatbot/Chatbot';

const Engage = () => {
    const [showCreatePost, setShowCreatePost] = useState(false);
    const [commentId, setCommentId] = useState("");
    const posts = useSelector((state) => state?.PostSlice?.posts);
    const dispatch = useDispatch();
    let data = {
        postID: commentId
    };

    useEffect(() => {
        dispatch(getAllPosts());
    }, [])

    useEffect(() => {
        if (commentId) {
            dispatch(getComment(data))
        }
    }, [commentId])
    // console.log("commentId",commentId)
    return (
        <>
            <section className='engagediv'>
                <div className='homepagecontainer'>

                    {/* Left Section */}

                    <div className='engagedivmaileft'>

                        <div className='bullentindiv'>
                            <BulletinBoard />
                        </div>

                        <div className='halloddiv'>
                            <Hallofframe />
                        </div>

                        <div className='initiativediv'>
                            <Initiative />
                        </div>


                    </div>

                    {/* Left Section */}

                    {/* >>>>Middle Section */}

                    <div className='engagedivmaimiddle'>
                        <Sharethought />
                        {
                            posts?.map((item) => {
                                return (
                                    <>
                                        {
                                            item?.repostedBy
                                                ?
                                                <ShareRepost
                                                    post={item}
                                                    setCommentId={setCommentId}
                                                    commentId={commentId}
                                                />
                                                :
                                                <SharePost
                                                    post={item}
                                                    setCommentId={setCommentId}
                                                    commentId={commentId}
                                                />
                                        }

                                    </>
                                )
                            })
                        }

                    </div>

                    {/* >>>>Middle Section */}

                    {/* Right Section */}
                    <div className='engagedivmairight'>

                        <EducateStatusCard />

                        <div className='myeventcrd'>
                            <EventCard />
                        </div>

                        <div className='myteamdiv'>
                            <MyTeam />
                        </div>


                    </div>
                    {/* Right Section */}

                </div>
            </section >
            <Chatbot/>

            {showCreatePost && <Sharethoughtmodal closemodal={setShowCreatePost} />}

        </>
    )
}

export default Engage
