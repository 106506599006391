import React, { useState } from 'react'
import Sharethoughtmodal from '../../Modal/Sharethoughtmodal'
// import Attach from "../../Images/Icons/PNG/Attach.png"
import { Link } from 'react-router-dom'
import imgprlsthgt from "../../Images/imgprlsthgt.jpg";
import ThankyouCard from "../../Images/Icons/PNG/ThankyouCard.png";
import advocacy from "../../Images/Icons/PNG/advocacy.png";
import rewardpoints from "../../Images/Icons/PNG/rewardpoints.png";
import events from "../../Images/Icons/PNG/events.png"
import ThankYoucardmodal from '../../Modal/ThankYoucardmodal';
import CardthankyouModal from '../../Modal/CardthankyouModal';
import BirthdayCardmodal from '../../Modal/BirthdayCardmodal';
import OnBoardmodal from '../../Modal/OnBoardmodal';
import AnniversaryCardmodal from '../../Modal/AnniversaryCardmodal';
const Sharethought = () => {
    const [initialValues, setinitialValues] = useState({
        cardName: "",
        cardDesc: "",
        receiverMail: "",
        receiverImage: ""
      });
    const [showCreatePost, setShowCreatePost] = useState(false);
    const [thankcardmodal,setThankcardmodal]=useState(false);
    const [cardfirstmodal,setCardfirstmodal]=useState(false);
    const [cardsecondmodal,setCardsecondmodal]=useState(false);
    const [cardthirdmodal,setCardthirdmodal]=useState(false);
    const [cardfourthmodal,setCardfourthmodal]=useState(false);

    const handlethankcard =()=>{
        setThankcardmodal(!thankcardmodal);
    }
    const handlecardfirst=()=>{
        setCardfirstmodal(true);
        setThankcardmodal(false)
        setinitialValues({ ...initialValues, cardName: "ThankYou" });
       
    }
    const handlecardsecond=()=>{
        setCardsecondmodal(true)
        setThankcardmodal(false)
        setinitialValues({ ...initialValues, cardName: "BirthDay" });
        
    }
    const handlecardthird=()=>{
        setCardthirdmodal(true)
        setThankcardmodal(false)
        setinitialValues({ ...initialValues, cardName: "Onboarding" });
    }
    const handlecardfourth=()=>{
        setCardfourthmodal(true)
        setThankcardmodal(false)
        setinitialValues({ ...initialValues, cardName: "Anniversary" });
    }
    return (
        <>
            <section className='share_thoughts'>
                <div className='topsharemain'>
                    <div className='sharethfghbrdr' style={{    border:"2px solid rgb(249, 239, 201)"}} onClick={handlethankcard}>
                        <div className='sgahrethgthimg'>
                            <img src={ThankyouCard} alt="..." />
                        </div>
                        <div className=''>
                            <p className='shtretxtpp'>Thank You Card</p>
                        </div>
                    </div>
                    <Link className='sharethfghbrdr' to="/advocacy" style={{border: "2px solid rgb(164, 189, 255)"}}>
                        <div className='sgahrethgthimg'>
                            <img src={advocacy} alt="..." />
                        </div>
                        <div className=''>
                            <p className='shtretxtpp'>Advocacy</p>
                        </div>
                    </Link>
                    <Link className='sharethfghbrdr' to="/createClaimpoints" style={{ border: "2px solid rgb(255, 160, 183)"}}>
                        <div className='sgahrethgthimg'>
                            <img src={rewardpoints} alt="..." />
                        </div>
                        <div className=''>
                            <p className='shtretxtpp'>
                                Claims Point</p>
                        </div>
                    </Link>
                    <Link className='sharethfghbrdr' to="/eventDetails" style={{ border: "2px solid rgb(255, 161, 161)"}}>
                        <div className='sgahrethgthimg'>
                            <img src={events} alt="..." />
                        </div>
                        <div className=''>
                            <p className='shtretxtpp'>Event</p>
                        </div>
                    </Link>
                </div>

                <div className='top'>
                    <div className='prflbrdrmain'>
                        <div className='shrthgprflimg'>
                            <img src={imgprlsthgt} alt="..." />
                        </div>
                    </div>

                    <div className='crtevnttxtthght_txtdiv'>
                        <p className='thght_txt'
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                                e.stopPropagation()
                                setShowCreatePost(true)
                            }}
                        >Share Moments That Matters...
                        </p>
                    </div>


                </div>
            </section>
            {showCreatePost && <Sharethoughtmodal closemodal={setShowCreatePost}/>}

            {thankcardmodal && <ThankYoucardmodal closemodal={setThankcardmodal}
            handlecardfirst={handlecardfirst}
            handlecardsecond={handlecardsecond}
            handlecardthird={handlecardthird}
            handlecardfourth={handlecardfourth}
            />}

            {cardfirstmodal && <CardthankyouModal closemodal={setCardfirstmodal} initialValues={initialValues}/>}

            {cardsecondmodal && <BirthdayCardmodal closemodal={setCardsecondmodal} initialValues={initialValues}/>}

            {cardthirdmodal && <OnBoardmodal closemodal={setCardthirdmodal} initialValues={initialValues}/>}
            
            {cardfourthmodal && <AnniversaryCardmodal closemodal={setCardfourthmodal} initialValues={initialValues}/>}
        </>
    )
}

export default Sharethought
