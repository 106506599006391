import React, { useEffect } from 'react'
// import sharethght from "../../Images/sharethght.jpg"
import { useDispatch, useSelector } from 'react-redux'
import { getStatusCard } from '../../Redux/Slice/StatusCardSlice';


const EducateStatusCard = () => {
  const dispatch = useDispatch();
  const statusCard = useSelector((state) => state?.StatusCardSlice?.data);

  useEffect(() => {
    dispatch(getStatusCard());
  }, [])

  return (
    <>
      <div className="statusdivmain">
        <div>
          <div
            className="bg-userdtwht"
            style={{ backgroundColor: "rgb(208, 145, 124)" }}
          >
            <div className="plthclbtx">
              <p className="bghhkpp">{statusCard?.rewardCard?.[0]?.cardName}</p>
              <p className="clbpp">Club</p>
            </div>
            <div
              className=""
              style={{
                backgroundColor: "rgb(255, 255, 255)",
                height: "70%",
                borderTopRightRadius: "72px",
                borderBottomLeftRadius:"4px",
                borderBottomRightRadius:"4px"
              }}
            >
              <div className="divpaddingghh">
                <div className="prflimgdiv">
                  <div className="prflimgbrdr">
                    <div className="prflimgfh">
                      <img
                        alt="..."
                        src={statusCard?.image}
                      />
                    </div>
                  </div>
                  <div className="divpatyn">
                    <p className="thounsabpp" style={{
                      color:
                        // "rgb(208, 145, 124)" 
                        `${statusCard?.rewardCard?.[0]?.cardColor}`
                    }}>

                    </p>
                    <p className="pointshttx">{statusCard?.rewardCard?.[0]?.reward}</p>
                    <p className="pointshttx">POINTS BALANCE</p>
                   
                  </div>
                </div>
                <div className="tstdfghmrl">
                  <p className="txtppght">{statusCard?.firstName} {statusCard?.lastName}</p>
                  {/* <p className="dvjknjpp">Backend Developer</p> */}
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </>
  )
}

export default EducateStatusCard
