import React, { useState } from 'react'
import Search from "../../Images/Icons/PNG/Search.png";
import imgmain from "../../Images/imgmain.png";
import docreoiimg from "../../Images/docreoiimg.png";
import sharethght from "../../Images/sharethght.jpg";
import doc from "../../Images/Icons/PNG/doc.png"
import docimg from "../../Images/docimg.jpeg"
import { Link } from 'react-router-dom';
import documentReposiy from "../../Images/Icons/PNG/DocumentRepository.png"
import Sharethoughtmodal from '../../Modal/Sharethoughtmodal';
const Detailsdocumentrepository = () => {
    const [showCreatePost, setShowCreatePost] = useState(false)
    return (
        <>
            <section className='detailsdocrepomain'>
                <div className='esgpagecontainer'>
                    <div className='educatemainflx'>
                        <div className='detailsdocleft'>
                            <div className=''>
                                <div className="documntrepodivmainsrch">
                                    <input type="text" className="serchdivdctxtsrc" placeholder="Search in Document Repository 
" />
                                    <div className="serchiconimg">
                                        <img src={Search} alt="..." />
                                    </div>
                                </div>

                                <div className="documentbigsldimage">

                                    <div className="imgaelitedvbgmainbgaft">
                                        <img src={imgmain} alt="..." />
                                        <div className="docuemtslidetextpdiv">
                                            <p className='docrepotxtspp'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, voluptates.
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, voluptates.
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, voluptates.</p>

                                        </div>
                                    </div>


                                </div>

                                <div className="documentRepositorycategorydiv">
                                    <div className="documentRepositorycategoryflx">

                                        <Link className="documentRepositorycategorybg" to="/articlepage">
                                            <div className>
                                                <div className="certificateimgdivbg">
                                                    <img src={docreoiimg} alt="..." />
                                                    <div className="itemcategppp">
                                                        <p className="itemcategppptext">FINANCE</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="topicviweflx">
                                                <div className>
                                                    <p className="texyttpxvwpp">3</p>
                                                    <p className="texyttpxvwpp">Topics</p>
                                                </div>
                                                <div className>
                                                    <p className="texyttpxvwpp">2</p>
                                                    <p className="texyttpxvwpp">Views</p>
                                                </div>
                                            </div>
                                        </Link>

                                        <Link className="documentRepositorycategorybg" to="/articlepage">
                                            <div className>
                                                <div className="certificateimgdivbg">
                                                    <img src={docreoiimg} alt="..." />
                                                    <div className="itemcategppp">
                                                        <p className="itemcategppptext">IT</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="topicviweflx">
                                                <div className>
                                                    <p className="texyttpxvwpp">5</p>
                                                    <p className="texyttpxvwpp">Topics</p>
                                                </div>
                                                <div className>
                                                    <p className="texyttpxvwpp">1</p>
                                                    <p className="texyttpxvwpp">Views</p>
                                                </div>
                                            </div>
                                        </Link>

                                        <Link className="documentRepositorycategorybg" to="/articlepage">
                                            <div className>
                                                <div className="certificateimgdivbg">
                                                    <img src={docreoiimg} alt="..." />
                                                    <div className="itemcategppp">
                                                        <p className="itemcategppptext">R&amp;D</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="topicviweflx">
                                                <div className>
                                                    <p className="texyttpxvwpp">1</p>
                                                    <p className="texyttpxvwpp">Topics</p>
                                                </div>
                                                <div className>
                                                    <p className="texyttpxvwpp">3</p>
                                                    <p className="texyttpxvwpp">Views</p>
                                                </div>
                                            </div>
                                        </Link>

                                        <Link className="documentRepositorycategorybg" to="/articlepage">
                                            <div className>
                                                <div className="certificateimgdivbg">
                                                    <img src={docreoiimg} alt="..." />
                                                    <div className="itemcategppp">
                                                        <p className="itemcategppptext">MANAGEMENT</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="topicviweflx">
                                                <div className>
                                                    <p className="texyttpxvwpp">0</p>
                                                    <p className="texyttpxvwpp">Topics</p>
                                                </div>
                                                <div className>
                                                    <p className="texyttpxvwpp">1</p>
                                                    <p className="texyttpxvwpp">Views</p>
                                                </div>
                                            </div>
                                        </Link>

                                        <Link className="documentRepositorycategorybg" to="/articlepage">
                                            <div className>
                                                <div className="certificateimgdivbg">
                                                    <img src={docreoiimg} alt="..." />
                                                    <div className="itemcategppp">
                                                        <p className="itemcategppptext">
                                                            SALES</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="topicviweflx">
                                                <div className>
                                                    <p className="texyttpxvwpp">0</p>
                                                    <p className="texyttpxvwpp">Topics</p>
                                                </div>
                                                <div className>
                                                    <p className="texyttpxvwpp">0</p>
                                                    <p className="texyttpxvwpp">Views</p>
                                                </div>
                                            </div>
                                        </Link>

                                        <Link className="documentRepositorycategorybg" to="/articlepage">
                                            <div className>
                                                <div className="certificateimgdivbg">
                                                    <img src={docreoiimg} alt="..." />
                                                    <div className="itemcategppp">
                                                        <p className="itemcategppptext">NOT-TECH</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="topicviweflx">
                                                <div className>
                                                    <p className="texyttpxvwpp">0</p>
                                                    <p className="texyttpxvwpp">Topics</p>
                                                </div>
                                                <div className>
                                                    <p className="texyttpxvwpp">0</p>
                                                    <p className="texyttpxvwpp">Views</p>
                                                </div>
                                            </div>
                                        </Link>

                                    </div>
                                </div>



                            </div>
                        </div>
                        <div className='detailsdocright'>
                            <div className='createdcocumentdivdivpagdoimm'>
                                <div className="createdcocumentdiv" style={{ cursor: 'pointer' }}>
                                    <div className="createdcocumentop">
                                        <div className="createdcocumentflx">
                                            <div className="documentbrdrprfl">
                                                <div className="documentbrdrprflimg">
                                                    <img src={sharethght} alt="..." />
                                                </div>
                                            </div>
                                            <div className="crtevnttxtthght_txtdivcrtdct"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setShowCreatePost(true)
                                                }} >
                                                <p className="thght_txtcrt">Share Moments That Matters...</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="createdcocumenbottom">
                                        <div className="crtdcmntflx">
                                            <div className="articleiconimgdiv">
                                                <img src={documentReposiy} alt="..." /></div>
                                            <Link className="dcmnttxtpppdflnk" to="/createDocumentRepository">
                                                <p className="dcmnttxtpppdf">Create Document</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                             </div>
                             <div className='recentdocdivdivpading'>
                                <div className="recentdocdiv">
                                    <div className="top">
                                        <div className="recentdoccdiv">
                                            <div className="articleiconimgdiv">
                                                <img
                                                    src={doc}
                                                    alt="..."
                                                />
                                            </div>
                                            <div className="">
                                                <p className="dpocsttxtppp">Your Recent Docs</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="buttom">
                                        <div className="docstxtpflxdaa" style={{ cursor: "pointer" }}>
                                            <div className="articleiconimgdiv">
                                                <img
                                                    src={docimg}
                                                    alt="..."
                                                />
                                            </div>
                                            <div className="">
                                                <p className="dcmnttxtpp">What is Lorem Ipsum?</p>
                                            </div>
                                        </div>
                                        <div className="docstxtpflxdaa" style={{ cursor: "pointer" }}>
                                            <div className="articleiconimgdiv">
                                                <img
                                                    src={docimg}
                                                    alt="..."
                                                />
                                            </div>
                                            <div className="">
                                                <p className="dcmnttxtpp">Upload</p>
                                            </div>
                                        </div>
                                        <div className="docstxtpflxdaa" style={{ cursor: "pointer" }}>
                                            <div className="articleiconimgdiv">
                                                <img
                                                    src={docimg}
                                                    alt="..."
                                                />
                                            </div>
                                            <div className="">
                                                <p className="dcmnttxtpp">Finance Document 1</p>
                                            </div>
                                        </div>
                                        <div className="docstxtpflxdaa" style={{ cursor: "pointer" }}>
                                            <div className="articleiconimgdiv">
                                                <img
                                                    src={docimg}
                                                    alt="..."
                                                />
                                            </div>
                                            <div className="">
                                                <p className="dcmnttxtpp">React js Document</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showCreatePost && <Sharethoughtmodal closemodal={setShowCreatePost} />}
        </>
    )
}

export default Detailsdocumentrepository
