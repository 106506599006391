import React, { useEffect, useRef, useState } from 'react'
import HttpClientXml from '../../utils/HttpClientXml';
import HttpClient from "../../utils/HttpClientXml.js";
import toast from 'react-hot-toast';
// import { useNavigate } from 'react-router-dom';
import AddIcon from "../../Images/Icons/PNG/Add icon.png"


const Principletwo = ({setPrincicpletabdrp,setTogglesectionmdl}) => {
    // const navigate = useNavigate();
    // const [text, setText] = useState('');
    const divRef = useRef(null);
    const [principleOneData, setPrincipleOneData] = useState({});

    const initvalue = {
        rAndD: {
            currentYear: "",
            previousYear: "",
            details: ""
        },
        capex: {
            currentYear: "",
            previousYear: "",
            details: ""
        },
        note: "",
        isSustainable: "Yes",
        percentage: "",
        describeTheProcess: {
            plastisc: false,
            eWaste: false,
            hazardousWaste: false,
            otherWaste: false
        },
        summeryOnTop: "",

        secondnote: "",
        isEprApplicable: "Yes",
        eprApplicableReason: "",
        serviceIndustryDetails: {
            note: "",
            briefOfNote: ""
        },
        productActionTakenDetails: {
            note: "",
            productOne: {
                name: "",
                describe: "",
                action: ""
            },
            productTwo: {
                name: "",
                describe: "",
                action: ""
            },
            productThree: {
                name: "",
                describe: "",
                action: ""
            }
        },
        recycledMaterialDetails: {
            indicateMaterial: "",
            totalMaterial: {
                currentYear: "",
                previousYear: ""
            },
            note: ""
        },

        currentYear: {
            plastics: {
                reUsed: "",
                recycled: "",
                safetyDisposed: ""
            },
            eWaste: {
                reUsed: "",
                recycled: "",
                safetyDisposed: ""
            },
            hazardousWaste: {
                reUsed: "",
                recycled: "",
                safetyDisposed: ""
            },
            otherWaste: {
                reUsed: "",
                recycled: "",
                safetyDisposed: ""
            }
        },
        previousYear: {
            plastics: {
                reUsed: "",
                recycled: "",
                safetyDisposed: ""
            },
            eWaste: {
                reUsed: "",
                recycled: "",
                safetyDisposed: ""
            },
            hazardousWaste: {
                reUsed: "",
                recycled: "",
                safetyDisposed: ""
            },
            otherWaste: {
                reUsed: "",
                recycled: "",
                safetyDisposed: ""
            },
        },
        thirdnote: "",

        productCategoryDetails: {
            categoryName: "",
            percenteageOfCategory: ""
        }
    }

    const initialSummery = {
        entityName: "",
        internalScarap: "",
        externalScarap: ""
    }

    const initentityDetails = {
        entityName: "",
        turnOver: "",
        nicCode: {
            first: "",
            second: "",
            third: "",
            fourth: ""
        },
        productName: {
            first: "",
            second: "",
            third: "",
            fourth: ""
        },
        percentageOfTurnOver: {
            first: "",
            second: "",
            third: "",
            fourth: ""
        },
        boundary: {
            first: "",
            second: "",
            third: "",
            fourth: ""
        },
        externalAgencyStatus: {
            first: "",
            second: "",
            third: "",
            fourth: ""
        },
        addressOfPublicDomain: {
            first: "",
            second: "",
            third: "",
            fourth: ""
        }

    }

    const [formvalue, setformvalue] = useState(initvalue);
    const [briefOfSummery, setbriefOfSummery] = useState([initialSummery]);
    const [entityDetails, setentityDetails] = useState([initentityDetails]);
    const [currentFY, setCurrentFY] = useState("");
    const [previousFY, setPreviousFY] = useState("");
    const [notemodal1, setnotemodal1] = useState(false);
    const [notemodal2, setnotemodal2] = useState(false);
    const [notemodal3, setnotemodal3] = useState(false);
    const [notemodal4, setnotemodal4] = useState(false);
    const [notemodal5, setnotemodal5] = useState(false);
    // const [downloadurl, setDownloadurl] = useState("");


    useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const startMonth = 4; // April is the start of the financial year

        if (currentDate.getMonth() + 1 >= startMonth) {
            setCurrentFY(`${currentYear}-${currentYear + 1}`);
            setPreviousFY(`${currentYear - 1}-${currentYear}`);
        } else {
            setCurrentFY(`${currentYear - 1}-${currentYear}`);
            setPreviousFY(`${currentYear - 2}-${currentYear - 1}`);
        }
    }, []);

    const getPdf = async () => {
        const res = await HttpClient?.requestData(`get-pdf-for-principleTwo`, {}, "GET");
        // console.log("downloadurl",res?.data)
        if (res?.status) {
            // setDownloadurl(res?.data);
            downloadPdf(res?.data)
        }
    }

    // console.log("downloadurl",downloadurl)
    const downloadPdf = (downloadurl) => {
        const link = document.createElement('a');
        link.href = downloadurl;
        link.download = 'principletwo.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    //handle change for briefOfSummery add more functionality
    const handleChange = (i, e, field) => {
        let newFormValues = [...briefOfSummery];
        newFormValues[i][field] = e.currentTarget.textContent;
        setbriefOfSummery(newFormValues);
    };


    //for first add more functionality
    const addFormFields = () => {
        setbriefOfSummery([...briefOfSummery, initialSummery]);
    };

    //for removing add more field
    const removeFormFields = (i) => {
        let newFormValues = [...briefOfSummery];
        newFormValues.splice(i, 1);
        setbriefOfSummery(newFormValues);
    };

    //handle change for entityDetails add more functionality
    const handleChangeentityDetails = (i, e, section, field) => {
        let newFormValues = [...entityDetails];
        newFormValues[i][section][field] = e.currentTarget.textContent;
        setentityDetails(newFormValues);
    };

    //handle change for entityDetails add more functionality
    const handleChangeentity = (i, e, field) => {
        let newFormValues = [...entityDetails];
        newFormValues[i][field] = e.currentTarget.textContent;
        setentityDetails(newFormValues);
    };

    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails = () => {
        setentityDetails([...entityDetails, initentityDetails]);
    };

    //for removing add more field
    const removeFormFieldsentityDetails = (i) => {
        let newFormValues = [...entityDetails];
        newFormValues.splice(i, 1);
        setentityDetails(newFormValues);
    };

    useEffect(() => {
        getPrincipleOne();
    }, [])


    //for getting principleone
    const getPrincipleOne = async () => {
        const res = await HttpClientXml?.requestData(`get-principle`, {}, "GET");
        if (res?.status) {
            setPrincipleOneData(res?.data?.[0]);
        }
    }

    //for Input fields
    const handleInput = (category, field, value) => {
        setformvalue((prevValue) => ({
            ...prevValue,
            [category]: {
                ...prevValue[category],
                [field]: value,
            }
        }));
    }


    //for input fields
    const handleInputChange = (e, section, year, field) => {
        const value = e.currentTarget.textContent;
        setformvalue(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [year]: {
                    ...prevState[section][year],
                    [field]: value
                }
            }
        }));
    };

    //for submit functionality
    const submitHandler = async (e) => {
        e.preventDefault();
        let data = {};
        data = {

            "principleTwo": {
                "currentYear": currentFY,
                "previousYear": previousFY,
                "essentialIndicators": {
                    "technologyPercentage": {
                        "rAndD": {
                            "currentYear": formvalue?.rAndD?.currentYear,
                            "previousYear": formvalue?.rAndD?.previousYear,
                            "details": formvalue?.rAndD?.details
                        },
                        "capex": {
                            "currentYear": formvalue?.capex?.currentYear,
                            "previousYear": formvalue?.capex?.previousYear,
                            "details": formvalue?.capex?.details
                        },
                        "note": formvalue?.note
                    },
                    "sourcedSustainably": {
                        "isSustainable": formvalue?.isSustainable,
                        "percentage": formvalue?.percentage,
                    },

                    "safetyReclaimedProcess": {
                        "describeTheProcess": {
                            "plastisc": formvalue?.describeTheProcess?.plastisc,
                            "eWaste": formvalue?.describeTheProcess?.eWaste,
                            "hazardousWaste": formvalue?.describeTheProcess?.hazardousWaste,
                            "otherWaste": formvalue?.describeTheProcess?.otherWaste
                        },
                        "summeryOnTop": formvalue?.summeryOnTop,
                        "briefOfSummery": briefOfSummery,
                        "note": formvalue?.secondnote
                    },


                    // "safetyReclaimedProcess": {
                    //     "describeTheProcess": {
                    //         "plastisc": formvalue?.describeTheProcess?.plastisc,
                    //         "eWaste": formvalue?.describeTheProcess?.eWaste,
                    //         "hazardousWaste": formvalue?.describeTheProcess?.hazardousWaste,
                    //         "otherWaste": formvalue?.describeTheProcess?.otherWaste
                    //     },
                    // },
                    // "summeryOnTop": formvalue?.summeryOnTop,
                    // "briefOfSummery": briefOfSummery,
                    // "note": formvalue?.secondnote,

                    "eprApplicableDtails": {
                        "isEprApplicable": formvalue?.isEprApplicable,
                        "eprApplicableReason": formvalue?.eprApplicableReason
                    }
                }
                ,
                "leadershipIndicators": {
                    "serviceIndustryDetails": {
                        "note": formvalue?.serviceIndustryDetails?.note,
                        "entityDetails": entityDetails,
                        "briefOfNote": formvalue?.serviceIndustryDetails?.briefOfNote
                    },
                    "productActionTakenDetails": {
                        "productOne": {
                            "name": formvalue?.productActionTakenDetails?.productOne?.name,
                            "describe": formvalue?.productActionTakenDetails?.productOne?.describe,
                            "action": formvalue?.productActionTakenDetails?.productOne?.action
                        },
                        "productTwo": {
                            "name": formvalue?.productActionTakenDetails?.productTwo?.name,
                            "describe": formvalue?.productActionTakenDetails?.productTwo?.describe,
                            "action": formvalue?.productActionTakenDetails?.productTwo?.action
                        },
                        "productThree": {
                            "name": formvalue?.productActionTakenDetails?.productThree?.name,
                            "describe": formvalue?.productActionTakenDetails?.productThree?.describe,
                            "action": formvalue?.productActionTakenDetails?.productThree?.action
                        },
                        "note": formvalue?.productActionTakenDetails?.note
                    },
                    "recycledMaterialDetails": {
                        "indicateMaterial": formvalue?.recycledMaterialDetails?.indicateMaterial,
                        "totalMaterial": {
                            "currentYear": formvalue?.recycledMaterialDetails?.totalMaterial?.currentYear,
                            "previousYear": formvalue?.recycledMaterialDetails?.totalMaterial?.previousYear
                        },
                        "note": formvalue?.recycledMaterialDetails?.note
                    },
                    "productReclaimedDetails": {
                        "currentYear": {
                            "plastics": {
                                "reUsed": formvalue?.currentYear?.plastics?.reUsed,
                                "recycled": formvalue?.currentYear?.plastics?.recycled,
                                "safetyDisposed": formvalue?.currentYear?.plastics?.safetyDisposed
                            },
                            "eWaste": {
                                "reUsed": formvalue?.currentYear?.eWaste?.reUsed,
                                "recycled": formvalue?.currentYear?.eWaste?.recycled,
                                "safetyDisposed": formvalue?.currentYear?.eWaste?.safetyDisposed
                            },
                            "hazardousWaste": {
                                "reUsed": formvalue?.currentYear?.hazardousWaste?.reUsed,
                                "recycled": formvalue?.currentYear?.hazardousWaste?.recycled,
                                "safetyDisposed": formvalue?.currentYear?.hazardousWaste?.safetyDisposed
                            },
                            "otherWaste": {
                                "reUsed": formvalue?.currentYear?.otherWaste?.reUsed,
                                "recycled": formvalue?.currentYear?.otherWaste?.recycled,
                                "safetyDisposed": formvalue?.currentYear?.otherWaste?.safetyDisposed
                            }
                        },
                        "previousYear": {
                            "plastics": {
                                "reUsed": formvalue?.previousYear?.plastics?.reUsed,
                                "recycled": formvalue?.previousYear?.plastics?.recycled,
                                "safetyDisposed": formvalue?.previousYear?.plastics?.safetyDisposed
                            },
                            "eWaste": {
                                "reUsed": formvalue?.previousYear?.eWaste?.reUsed,
                                "recycled": formvalue?.previousYear?.eWaste?.recycled,
                                "safetyDisposed": formvalue?.previousYear?.eWaste?.safetyDisposed
                            },
                            "hazardousWaste": {
                                "reUsed": formvalue?.previousYear?.hazardousWaste?.reUsed,
                                "recycled": formvalue?.previousYear?.hazardousWaste?.recycled,
                                "safetyDisposed": formvalue?.previousYear?.hazardousWaste?.safetyDisposed
                            },
                            "otherWaste": {
                                "reUsed": formvalue?.previousYear?.otherWaste?.reUsed,
                                "recycled": formvalue?.previousYear?.otherWaste?.recycled,
                                "safetyDisposed": formvalue?.previousYear?.otherWaste?.safetyDisposed
                            }
                        },
                        "note": formvalue?.thirdnote
                    },
                    "productCategoryDetails": {
                        "categoryName": formvalue?.productCategoryDetails?.categoryName,
                        "percenteageOfCategory": formvalue?.productCategoryDetails?.percenteageOfCategory
                    }
                }
            }
        }

        console.log('principletwo', data);
        // return false;
        const res = await HttpClient?.requestData(
            `update-enviroment-principle/${principleOneData?._id}`,
            data,
            "POST"
        );
        if (res?.status) {
            toast?.success("Principle-2 is added successfully");
            // navigate('/pricipletwotemp')
            setformvalue(initvalue);
            const contentEditableElements = document.querySelectorAll('[contentEditable]');
            contentEditableElements?.forEach(element => element.textContent = "");
            setentityDetails([initentityDetails]);
            setbriefOfSummery([initialSummery]);
            setPrincicpletabdrp(3);
            setTogglesectionmdl(true)
            // getPdf();

        } else {
            toast?.error(res?.response?.data?.message || "Something went wrong");
        }

    }

    return (
        <div className=''>
            <h4 className='principletxtpp' >
                PRINCIPLE 2 Businesses should provide goods and services in a
                manner that is sustainable and safe
            </h4>
            <div className='borderessentialin'>
                <p className='idicatrstxtpp'> Essential Indicators</p>
                <div className=''>
                    <ul className='listoftblxsdivul'>
                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Percentage of R&D and capital expenditure (capex) investments in specific technologies
                                to improve the environmental and social impacts of product and processes to total R&D
                                and capex investments made by the entity, respectively
                            </li>

                            <table className='tablbrdrmain'>
                                <tr className='trdivmainbrdr'>
                                    <th className='thdivmainbrdr principkttwopomuntfsrt'> </th>
                                    <th className='thdivmainbrdr principkttwopomuntscnd'>
                                        <p className='prcnttxttemplytxtpp'>Current Financial
                                            Year
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr principkttwopomuntthrd'>
                                        <p className='prcnttxttemplytxtpp'>Previous Financial
                                            Year
                                        </p>

                                    </th>
                                    <th className='thdivmainbrdr principkttwopomuntfrth'>
                                        <p className='prcnttxttemplytxtpp'>Details of
                                            improvements in
                                            environmental and
                                            social impacts
                                        </p>
                                    </th>
                                </tr>
                                <tr>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>
                                            R&D
                                        </p>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        {/* <textarea
                                            id="" name="" rows="2" cols="10"
                                            value={formvalue?.rAndD?.currentYear}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    rAndD: {
                                                        ...formvalue.rAndD,
                                                        currentYear: e.target.value
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('rAndD', 'currentYear', e.currentTarget.textContent)}
                                        >
                                            {/* {text} */}
                                        </div>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.rAndD?.previousYear}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    rAndD: {
                                                        ...formvalue.rAndD,
                                                        previousYear: e.target.value
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('rAndD', 'previousYear', e.currentTarget.textContent)}
                                        >
                                            {/* {text} */}
                                        </div>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.rAndD?.details}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    rAndD: {
                                                        ...formvalue.rAndD,
                                                        details: e.target.value
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('rAndD', 'details', e.currentTarget.textContent)}
                                        >
                                            {/* {text} */}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>Capex</p>

                                    </td>
                                    <td className='tddivmainbrdr'>
                                        {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.capex?.currentYear}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    capex: {
                                                        ...formvalue.capex,
                                                        currentYear: e.target.value
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('capex', 'currentYear', e.currentTarget.textContent)}
                                        >
                                            {/* {text} */}
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        {/* <textarea
                                            id="" name="" rows="2" cols="10"
                                            value={formvalue?.capex?.previousYear}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    capex: {
                                                        ...formvalue.capex,
                                                        previousYear: e.target.value
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('capex', 'previousYear', e.currentTarget.textContent)}
                                        >
                                            {/* {text} */}
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.capex?.details}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    capex: {
                                                        ...formvalue.capex,
                                                        details: e.target.value
                                                    }
                                                });
                                            }}
                                        >
                                        </textarea> */}
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('capex', 'details', e.currentTarget.textContent)}
                                        >
                                            {/* {text} */}
                                        </div>

                                    </td>


                                </tr>
                            </table>

                            <div className='rggsgttxbbedxgtsprijnd'>
                                <p className='notetgvxtygfspopphjhsttxp ' style={{ cursor: "pointer" }} onClick={() => setnotemodal1(!notemodal1)}>Note :</p>
                                {notemodal1 &&
                                    <div className="yestrxtwarea mt-3 mb-0">
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    note: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                            {/* {text} */}
                                        </div>
                                    </div>}
                            </div>

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Does the entity have procedures in place for sustainable sourcing?
                                <span>
                                    (If yes)
                                </span>,
                                what percentage of inputs were sourced sustainably?
                            </li>

                            <div className=''>
                                <div className=''>
                                    <div className='yesnobtndivmain'>
                                        <div className='raditrbtntxt'>
                                            <input
                                                type="radio" id="" name=""
                                                value={"Yes"}
                                                checked={formvalue?.isSustainable === "Yes"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        isSustainable: "Yes"
                                                    });
                                                }}
                                            />
                                            <label htmlFor="">Yes</label>
                                        </div>

                                        <div className='raditrbtntxt'>
                                            <input type="radio" id="" name=""
                                                value={"No"}
                                                checked={formvalue?.isSustainable === "No"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        isSustainable: "No"
                                                    });
                                                }}
                                            />
                                            <label htmlFor="">No</label>
                                        </div>
                                    </div>
                                    <div className='yestrxtwarea'>
                                        {/* <textarea id="" name='' rows="2" cols="10"
                                                value={formvalue?.percentage}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        percentage: e.target.value
                                                    });
                                                }}
                                            >

                                            </textarea> */}
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    percentage: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                            {/* {text} */}
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Describe the processes in place to safely reclaim your products for reusing, recycling and
                                disposing at the end of life, for
                            </li>


                            <div className="">
                                <div className="raditrbtntxt_div_horizntl">
                                    <div className='raditrbtntxt'>
                                        <input type="checkbox" id="" name=""
                                            value={true}
                                            checked={formvalue?.describeTheProcess?.plastisc === true}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    describeTheProcess: {
                                                        ...formvalue.describeTheProcess,
                                                        plastisc: true
                                                    }
                                                });
                                            }}
                                        />
                                        <label htmlFor=""> a. Plastics (including packaging) </label>
                                    </div>

                                    <div className='raditrbtntxt'>
                                        <input type="checkbox" id="" name=""
                                            value={true}
                                            checked={formvalue?.describeTheProcess?.eWaste === true}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    describeTheProcess: {
                                                        ...formvalue.describeTheProcess,
                                                        eWaste: true
                                                    }
                                                });
                                            }}
                                        />
                                        <label htmlFor=""> b. E-waste</label>
                                    </div>

                                    <div className='raditrbtntxt'>
                                        <input type="checkbox" id="" name=""
                                            value={true}
                                            checked={formvalue?.describeTheProcess?.hazardousWaste === true}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    describeTheProcess: {
                                                        ...formvalue.describeTheProcess,
                                                        hazardousWaste: true
                                                    }
                                                });
                                            }}
                                        />
                                        <label htmlFor=""> c. Hazardous waste </label>
                                    </div>

                                    <div className='raditrbtntxt'>
                                        <input type="checkbox" id="" name=""
                                            value={true}
                                            checked={formvalue?.describeTheProcess?.otherWaste === true}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    describeTheProcess: {
                                                        ...formvalue.describeTheProcess,
                                                        otherWaste: true
                                                    }
                                                });
                                            }}
                                        />
                                        <label htmlFor=""> d. other waste </label>
                                    </div>
                                </div>

                                <div className='rggsgttxbbedxgtsprijnd clsfrextarpading'>
                                    <p className='noiriittxoppdjoyyypp mb-2'>Summery on top : </p>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                summeryOnTop: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                        {/* {text} */}
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <p className='btdrffsumtrddibpadindiv mb-1'>A brief summary of scrap recycled in FY {currentFY} : </p>
                                    </div>

                                    <table className='tbshhhqchehcjjtabbdivcsjkio'>


                                        <tr>
                                            <th className='thdivmainbrdr enitytgtcakksdivfrst '>
                                                <p className='prcnttxttemplytxtpp'>
                                                    Entity
                                                </p>
                                            </th>
                                            <th className='thdivmainbrdr enitytgtcakksdivscnd'>
                                                <p className='prcnttxttemplytxtpp'> Internal scrap
                                                    recycled (kt)
                                                </p>
                                            </th>
                                            <th className='thdivmainbrdr enitytgtcakksdivthrd'>
                                                <p className='prcnttxttemplytxtpp'> External scrap
                                                    recycled (kt)
                                                </p>
                                            </th>
                                        </tr>


                                        {
                                            briefOfSummery?.map((element, ind) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td className='tddivmainbrdr'>

                                                                {/* Tata Steel Limited */}
                                                                <div
                                                                    ref={divRef}
                                                                    contentEditable
                                                                    className='textardibbrdrdivmain'
                                                                    onInput={(e) => handleChange(ind, e, "entityName")}
                                                                >
                                                                </div>
                                                            </td>
                                                            <td className='tddivmainbrdr'>
                                                                <div
                                                                    ref={divRef}
                                                                    contentEditable
                                                                    className='textardibbrdrdivmain'
                                                                    onInput={(e) => handleChange(ind, e, "internalScarap")}
                                                                >
                                                                </div>
                                                            </td>
                                                            <td className='tddivmainbrdr'>
                                                                <div
                                                                    ref={divRef}
                                                                    contentEditable
                                                                    className='textardibbrdrdivmain'
                                                                    onInput={(e) => handleChange(ind, e, "externalScarap")}
                                                                >
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        {/* {ind ? (
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-danger ml-1"
                                                                onClick={() => removeFormFields(ind)}
                                                            >
                                                                <i class="fas fa-trash"></i>
                                                            </button>
                                                        ) : null} */}
                                                    </>
                                                )
                                            })
                                        }

                                    </table>

                                    <div className='notedivadmoredivflx'>
                                        <div className=''>
                                            <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal2(!notemodal2)}>Note  </p>
                                        </div>

                                        <button className='adddinciondivimg'>
                                            <img src={AddIcon} alt='...' onClick={addFormFields} />
                                            <span>Add More</span>
                                        </button>
                                    </div>
                                </div>



                                {notemodal2 && <div className="yestrxtwarea mt-2 mb-0">

                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                secondnote: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>}



                            </div>


                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Whether Extended Producer Responsibility (EPR) is applicable to the entity’s activities .
                                <span>
                                    If yes,
                                </span> whether the waste collection plan is in line with the Extended Producer
                                Responsibility (EPR) plan submitted to Pollution Control Boards? If not, provide steps
                                taken to address the same.
                            </li>

                            <div className=''>
                                <div className=''>
                                    <div className='yesnobtndivmain'>
                                        <div className='raditrbtntxt'>
                                            <input
                                                type="radio" id="" name=""
                                                value={"Yes"}
                                                checked={formvalue?.isEprApplicable === "Yes"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        isEprApplicable: "Yes"
                                                    });
                                                }}
                                            />
                                            <label htmlFor="">Yes</label>
                                        </div>

                                        <div className='raditrbtntxt'>
                                            <input type="radio" id="" name=""
                                                value={"No"}
                                                checked={formvalue?.isEprApplicable === "No"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        isEprApplicable: "No"
                                                    });
                                                }}
                                            />
                                            <label htmlFor="">No</label>
                                        </div>
                                    </div>

                                    <div className='yestrxtwarea'>
                                        {/* <textarea id="" name='' rows="2" cols="10"
                                                value={formvalue?.eprApplicableReason}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        eprApplicableReason: e.target.value
                                                    });
                                                }}
                                            >

                                            </textarea> */}
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    eprApplicableReason: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                            {/* {text} */}
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>

                    </ul>
                </div>
            </div>

            <div className='borderessentialin'>
                <p className='idicatrstxtpp'>Leadership Indicators
                </p>
                <div className=''>
                    <ul className='listoftblxsdivul'>
                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Has the entity conducted Life Cycle Perspective / Assessments (LCA) for any of its
                                products (for manufacturing industry) or for its services (for service industry)? If yes,
                                provide details in the following format?

                            </li>


                            <div className='rggsgttxbbedxgtsprijnd clsfrextarpading'>
                                <p className='noiriittxoppdjoyyypp'>Brief : </p>
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => handleInput('serviceIndustryDetails', 'note', e.currentTarget.textContent)}

                                >
                                </div>
                                <p className='simeerttetxstlppp'>
                                    Summery
                                </p>
                            </div>

                            <div>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr ebjsjnftfhshdibfrsrt'>
                                            <p className='prcnttxttemplytxtpp'>
                                                Entity
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr ebjsjnftfhshdibscnd'>
                                            <p className='prcnttxttemplytxtpp'>NIC CodeM</p>
                                        </th>
                                        <th className='thdivmainbrdr ebjsjnftfhshdibthrd'>
                                            <p className='prcnttxttemplytxtpp'>Name of product/
                                                service
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr ebjsjnftfhshdibfrth'>
                                            <p className='prcnttxttemplytxtpp'>Turnover
                                                (₹ Cr)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr ebjsjnftfhshdibffth'>
                                            <p className='prcnttxttemplytxtpp'>% of total
                                                turnover (of
                                                the respective
                                                entity)
                                            </p>

                                        </th>
                                        <th className='thdivmainbrdr ebjsjnftfhshdibsxth'>
                                            <p className='prcnttxttemplytxtpp'>Boundary for which the
                                                life cycle perspective
                                                / assessment was
                                                conducted
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr ebjsjnftfhshdibsvnthg'>
                                            <p className='prcnttxttemplytxtpp'>Whether
                                                conducted by
                                                independent
                                                external agency
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr ebjsjnftfhshdibegth'>
                                            <p className='prcnttxttemplytxtpp'>Results communicated in
                                                public domain
                                            </p>
                                        </th>
                                    </tr>

                                    {
                                        entityDetails?.map((item, ind) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td className='tddivmainbrdrlhhhnewhil'>
                                                            {/* Tata Steel Limited */}
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentity(ind, e, "entityName")}

                                                            >
                                                            </div>

                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "nicCode", "first")}
                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "productName", "first")}
                                                            >
                                                            </div>
                                                        </td>
                                                        <td className=''>
                                                            {/* <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div> */}
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "percentageOfTurnOver", "first")}

                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "boundary", "first")}
                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "externalAgencyStatus", "first")}

                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "addressOfPublicDomain", "first")}
                                                            >
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className=''>

                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "nicCode", "second")}
                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "productName", "second")}

                                                            >
                                                            </div>
                                                        </td>
                                                        <td className=''>

                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "percentageOfTurnOver", "second")}

                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "boundary", "second")}

                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "externalAgencyStatus", "second")}

                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "addressOfPublicDomain", "second")}

                                                            >
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className=''>

                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "nicCode", "third")}

                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "productName", "third")}

                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='p-1'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentity(ind, e, "turnOver")}
                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "percentageOfTurnOver", "third")}

                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "boundary", "third")}

                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "externalAgencyStatus", "third")}

                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "addressOfPublicDomain", "third")}

                                                            >
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className=''>

                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "nicCode", "fourth")}

                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "productName", "fourth")}
                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='foresrtggsgochhbrdrrbtmmndiv'>
                                                            {/* <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div> */}
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "percentageOfTurnOver", "fourth")}
                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "boundary", "fourth")}
                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "externalAgencyStatus", "fourth")}
                                                            >
                                                            </div>
                                                        </td>
                                                        <td className='tddivmainbrdr'>
                                                            <div
                                                                ref={divRef}
                                                                contentEditable
                                                                className='textardibbrdrdivmain'
                                                                onInput={(e) => handleChangeentityDetails(ind, e, "addressOfPublicDomain", "fourth")}
                                                            >
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </>
                                            )
                                        })
                                    }

                                </table>


                                <div className='notedivadmoredivflx'>
                                    <div className=''>
                                        <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal2(!notemodal2)}>Note  </p>
                                    </div>

                                    <button className='adddinciondivimg'>
                                        <img src={AddIcon} alt='...' onClick={addFormFieldsentityDetails} />
                                        <span>Add More</span>
                                    </button>
                                </div>

                                {notemodal2 && <div className="yestrxtwarea mt-2 mb-0">

                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('serviceIndustryDetails', 'briefOfNote', e.currentTarget.textContent)}

                                    // onInput={(e) => {
                                    //     setformvalue({
                                    //         ...formvalue,
                                    //         secondnote: e.currentTarget.textContent
                                    //     });
                                    // }}
                                    >
                                    </div>
                                </div>}

                            </div>

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                If there are any significant social or environmental concerns and/or risks arising from
                                production or disposal of your products / services, as identified in the Life Cycle
                                Perspective / Assessments (LCA) or through any other means, briefly describe the same
                                along-with action taken to mitigate the same.

                            </li>

                            <div>

                                <div className='rggsgttxbbedxgtsprijnd clsfrextarpading'>
                                    <p className='noiriittxoppdjoyyypp '>Brief : </p>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('productActionTakenDetails', 'note', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>

                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr thhdivvvProductfrst'>
                                            <p className='prcnttxttemplytxtpp'>Name of Product /
                                                Service
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr thhdivvvProductscbnd'>
                                            <p className='prcnttxttemplytxtpp'>Description of the risk /
                                                concern
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr thhdivvvProductthrd' >
                                            <p className='prcnttxttemplytxtpp'>Action Taken</p>
                                        </th>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2" cols="10"
                                                value={formvalue?.productActionTakenDetails?.productOne?.name}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        productActionTakenDetails: {
                                                            ...formvalue.productActionTakenDetails,
                                                            productOne: {
                                                                ...formvalue.productActionTakenDetails?.productOne,
                                                                name: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                            >

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'productActionTakenDetails', 'productOne', 'name')}
                                            >
                                                {/* {text} */}
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2" cols="10"
                                                value={formvalue?.productActionTakenDetails?.productOne?.describe}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        productActionTakenDetails: {
                                                            ...formvalue.productActionTakenDetails,
                                                            productOne: {
                                                                ...formvalue.productActionTakenDetails?.productOne,
                                                                describe: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                            >

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'productActionTakenDetails', 'productOne', 'describe')}
                                            >
                                                {/* {text} */}
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2" cols="10"
                                                value={formvalue?.productActionTakenDetails?.productOne?.action}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        productActionTakenDetails: {
                                                            ...formvalue.productActionTakenDetails,
                                                            productOne: {
                                                                ...formvalue.productActionTakenDetails?.productOne,
                                                                action: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                            >

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'productActionTakenDetails', 'productOne', 'action')}
                                            >
                                                {/* {text} */}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2" cols="10"
                                                value={formvalue?.productActionTakenDetails?.productTwo?.name}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        productActionTakenDetails: {
                                                            ...formvalue.productActionTakenDetails,
                                                            productTwo: {
                                                                ...formvalue.productActionTakenDetails?.productTwo,
                                                                name: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                            >

                                            </textarea> */}

                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'productActionTakenDetails', 'productTwo', 'name')}
                                            >
                                                {/* {text} */}
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2" cols="10"
                                                value={formvalue?.productActionTakenDetails?.productTwo?.describe}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        productActionTakenDetails: {
                                                            ...formvalue.productActionTakenDetails,
                                                            productTwo: {
                                                                ...formvalue.productActionTakenDetails?.productTwo,
                                                                describe: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                            >

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'productActionTakenDetails', 'productTwo', 'describe')}
                                            >
                                                {/* {text} */}
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2" cols="10"
                                                value={formvalue?.productActionTakenDetails?.productTwo?.action}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        productActionTakenDetails: {
                                                            ...formvalue.productActionTakenDetails,
                                                            productTwo: {
                                                                ...formvalue.productActionTakenDetails?.productTwo,
                                                                action: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                            >

                                            
                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'productActionTakenDetails', 'productTwo', 'action')}
                                            >
                                                {/* {text} */}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2" cols="10"
                                                value={formvalue?.productActionTakenDetails?.productThree?.name}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        productActionTakenDetails: {
                                                            ...formvalue.productActionTakenDetails,
                                                            productThree: {
                                                                ...formvalue.productActionTakenDetails?.productThree,
                                                                name: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                            >

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'productActionTakenDetails', 'productThree', 'name')}
                                            >
                                                {/* {text} */}
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2" cols="10"
                                                value={formvalue?.productActionTakenDetails?.productThree?.describe}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        productActionTakenDetails: {
                                                            ...formvalue.productActionTakenDetails,
                                                            productThree: {
                                                                ...formvalue.productActionTakenDetails?.productThree,
                                                                describe: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                            >

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'productActionTakenDetails', 'productThree', 'describe')}
                                            >
                                                {/* {text} */}
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2" cols="10"
                                                value={formvalue?.productActionTakenDetails?.productThree?.action}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        productActionTakenDetails: {
                                                            ...formvalue.productActionTakenDetails,
                                                            productThree: {
                                                                ...formvalue.productActionTakenDetails?.productThree,
                                                                action: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                            >

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'productActionTakenDetails', 'productThree', 'action')}
                                            >
                                                {/* {text} */}
                                            </div>
                                        </td>
                                    </tr>
                                </table>


                            </div>


                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Percentage of recycled or reused input material to total material (by value) used in
                                production (for manufacturing industry) or providing services (for service industry).

                            </li>

                            <div>


                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdrhjhjsttxpp thdfIndicatedibppadingfrst' >
                                            <p className='prcnttxttemplytxtpp'>Indicate input material</p>
                                        </th>
                                        <th className='thdivmainbrdr thdfIndicatedibppadingscnd' colspan="2">
                                            <p className='prcnttxttemplytxtpp'>Recycled or re-used input material to total material</p>
                                        </th>
                                    </tr>
                                    <tr className='trdivmainbrdr'>
                                        <th className=''> </th>
                                        <th className='thdivmainbrdr thdfIndicatedibppadingthrd '>
                                            <p className='prcnttxttemplytxtpp'> FY {currentFY}
                                                Current Financial Year
                                            </p>

                                        </th>
                                        <th className='thdivmainbrdr thdfIndicatedibppadingfrth' >
                                            <p className='prcnttxttemplytxtpp'> FY {previousFY}
                                                Previous Financial Year
                                            </p>

                                        </th>

                                    </tr>
                                    <tr>


                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('recycledMaterialDetails', 'indicateMaterial', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.recycledMaterialDetails?.totalMaterial?.currentYear}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    recycledMaterialDetails: {
                                                        ...formvalue.recycledMaterialDetails,
                                                        totalMaterial: {
                                                            ...formvalue.recycledMaterialDetails?.totalMaterial,
                                                            currentYear: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'recycledMaterialDetails', 'totalMaterial', 'currentYear')}

                                            >
                                                {/* {text} */}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.recycledMaterialDetails?.totalMaterial?.previousYear}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    recycledMaterialDetails: {
                                                        ...formvalue.recycledMaterialDetails,
                                                        totalMaterial: {
                                                            ...formvalue.recycledMaterialDetails?.totalMaterial,
                                                            previousYear: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'recycledMaterialDetails', 'totalMaterial', 'previousYear')}
                                            >
                                                {/* {text} */}
                                            </div>
                                        </td>

                                    </tr>

                                </table>

                            </div>

                            <div className='rggsgttxbbedxgtsprijnd'>
                                <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal4(!notemodal4)}>Note  </p>
                                {notemodal4 && <div className="yestrxtwarea mt-3 mb-0">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('recycledMaterialDetails', 'note', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>}
                            </div>

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Of the products and packaging reclaimed at end of life of products, amount (in metric
                                tonnes) reused, recycled, and safely disposed, as per the following format:
                            </li>

                            <div>
                                <div>
                                    <table className='tablbrdrmain'>
                                        <tr className='trdivmainbrdr'>
                                            <th className='enkjhkjgblnmkdivfsrttst'>

                                            </th>
                                            <th colspan="3" className='thdivmainbrdr enkjhkjgblnmkdivfsrscnnd'>
                                                <p className='prcnttxttemplytxtpp'>FY {currentFY}
                                                    (Current Financial
                                                    Year)
                                                </p>
                                            </th>
                                            <th colspan="3" className='thdivmainbrdr enkjhkjgblnmkdivfsrthhfrd' >
                                                <p className='prcnttxttemplytxtpp'>FY {previousFY}
                                                    (Previous Financial Year)
                                                </p>
                                            </th>
                                        </tr>

                                        <tr className='trdivmainbrdr'>
                                            <th className='enkjhkjgblnmkdivfsrthfrth'> </th>
                                            <th colspan="1" className='thdivmainbrdr enkjhkjgblnmkdivfsrthhffth'>
                                                <p className='prcnttxttemplytxtpp'> Re-Used</p>
                                            </th>
                                            <th colspan="1" className='thdivmainbrdr enkjhkjgblnmkdivfsrthhsxth' >
                                                <p className='prcnttxttemplytxtpp'>Recycled</p>

                                            </th>
                                            <th colspan="1" className='thdivmainbrdr enkjhkjgblnmkdivfsrthhegth'>
                                                <p className='prcnttxttemplytxtpp'>Safely
                                                    Disposed
                                                </p>

                                            </th>
                                            <th colspan="1" className='thdivmainbrdr enkjhkjgblnmkdivfsrthnnth'>
                                                <p className='prcnttxttemplytxtpp'>Re-Used</p>
                                            </th>
                                            <th colspan="1" className='thdivmainbrdr enkjhkjgblnmkdivfsrthhtntrth' >
                                                <p className='prcnttxttemplytxtpp'>Recycled</p>
                                            </th>
                                            <th colspan="1" className='thdivmainbrdr enkjhkjgblnmkdivfsrthhfelvnth'>
                                                <p className='prcnttxttemplytxtpp'>Safely
                                                    Disposed
                                                </p>
                                            </th>

                                        </tr>


                                        <tr>
                                            <td className='tddivmainbrdr'>
                                                <p className='Malettsgvsgphsmkjghhutxtpp'> Plastics
                                                    (including
                                                    packaging)
                                                </p>

                                            </td>

                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.currentYear?.plastics?.reUsed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    currentYear: {
                                                        ...formvalue.currentYear,
                                                        plastics: {
                                                            ...formvalue.currentYear?.plastics,
                                                            reUsed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'currentYear', 'plastics', 'reUsed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.currentYear?.plastics?.recycled}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    currentYear: {
                                                        ...formvalue.currentYear,
                                                        plastics: {
                                                            ...formvalue.currentYear?.plastics,
                                                            recycled: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'currentYear', 'plastics', 'recycled')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.currentYear?.plastics?.safetyDisposed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    currentYear: {
                                                        ...formvalue.currentYear,
                                                        plastics: {
                                                            ...formvalue.currentYear?.plastics,
                                                            safetyDisposed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'currentYear', 'plastics', 'safetyDisposed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.previousYear?.plastics?.reUsed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    previousYear: {
                                                        ...formvalue.previousYear,
                                                        plastics: {
                                                            ...formvalue.previousYear?.plastics,
                                                            reUsed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'previousYear', 'plastics', 'reUsed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.previousYear?.plastics?.recycled}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    previousYear: {
                                                        ...formvalue.currentYear,
                                                        plastics: {
                                                            ...formvalue.previousYear?.plastics,
                                                            recycled: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'previousYear', 'plastics', 'recycled')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.previousYear?.plastics?.safetyDisposed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    previousYear: {
                                                        ...formvalue.previousYear,
                                                        plastics: {
                                                            ...formvalue?.previousYear?.plastics,
                                                            safetyDisposed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'previousYear', 'plastics', 'safetyDisposed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>

                                        </tr>

                                        <tr>
                                            <td className='tddivmainbrdr'>
                                                <p className='Malettsgvsgphsmkjghhutxtpp'> E-waste</p>
                                            </td>

                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.currentYear?.eWaste?.reUsed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    currentYear: {
                                                        ...formvalue.currentYear,
                                                        eWaste: {
                                                            ...formvalue?.currentYear?.eWaste,
                                                            reUsed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'currentYear', 'eWaste', 'reUsed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.currentYear?.eWaste?.recycled}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    currentYear: {
                                                        ...formvalue.currentYear,
                                                        eWaste: {
                                                            ...formvalue?.currentYear?.eWaste,
                                                            recycled: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}

                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'currentYear', 'eWaste', 'recycled')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.currentYear?.eWaste?.safetyDisposed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    currentYear: {
                                                        ...formvalue.currentYear,
                                                        eWaste: {
                                                            ...formvalue?.currentYear?.eWaste,
                                                            safetyDisposed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'currentYear', 'eWaste', 'safetyDisposed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.previousYear?.eWaste?.reUsed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    previousYear: {
                                                        ...formvalue.previousYear,
                                                        eWaste: {
                                                            ...formvalue?.previousYear?.eWaste,
                                                            reUsed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'previousYear', 'eWaste', 'reUsed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.previousYear?.eWaste?.recycled}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    previousYear: {
                                                        ...formvalue.previousYear,
                                                        eWaste: {
                                                            ...formvalue?.previousYear?.eWaste,
                                                            recycled: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'previousYear', 'eWaste', 'recycled')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.previousYear?.eWaste?.safetyDisposed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    previousYear: {
                                                        ...formvalue.previousYear,
                                                        eWaste: {
                                                            ...formvalue?.previousYear?.eWaste,
                                                            safetyDisposed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'previousYear', 'eWaste', 'safetyDisposed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className='tddivmainbrdr'>
                                                <p className='Malettsgvsgphsmkjghhutxtpp'>  Hazardous
                                                    waste
                                                </p>

                                            </td>

                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.currentYear?.hazardousWaste?.reUsed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    currentYear: {
                                                        ...formvalue.currentYear,
                                                        hazardousWaste: {
                                                            ...formvalue?.currentYear?.hazardousWaste,
                                                            reUsed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'currentYear', 'hazardousWaste', 'reUsed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.currentYear?.hazardousWaste?.recycled}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    currentYear: {
                                                        ...formvalue.currentYear,
                                                        hazardousWaste: {
                                                            ...formvalue?.currentYear?.hazardousWaste,
                                                            recycled: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'currentYear', 'hazardousWaste', 'recycled')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.currentYear?.hazardousWaste?.safetyDisposed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    currentYear: {
                                                        ...formvalue.currentYear,
                                                        hazardousWaste: {
                                                            ...formvalue?.currentYear?.hazardousWaste,
                                                            safetyDisposed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'currentYear', 'hazardousWaste', 'safetyDisposed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.previousYear?.hazardousWaste?.reUsed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    previousYear: {
                                                        ...formvalue.previousYear,
                                                        hazardousWaste: {
                                                            ...formvalue?.previousYear?.hazardousWaste,
                                                            reUsed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'previousYear', 'hazardousWaste', 'reUsed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.previousYear?.hazardousWaste?.recycled}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    previousYear: {
                                                        ...formvalue.previousYear,
                                                        hazardousWaste: {
                                                            ...formvalue?.previousYear?.hazardousWaste,
                                                            recycled: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'previousYear', 'hazardousWaste', 'recycled')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.previousYear?.hazardousWaste?.safetyDisposed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    previousYear: {
                                                        ...formvalue.previousYear,
                                                        hazardousWaste: {
                                                            ...formvalue?.previousYear?.hazardousWaste,
                                                            safetyDisposed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'previousYear', 'hazardousWaste', 'safetyDisposed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                        </tr>


                                        <tr>
                                            <td className='tddivmainbrdr'>
                                                <p className='Malettsgvsgphsmkjghhutxtpp'> Other
                                                    waste
                                                </p>

                                            </td>

                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.currentYear?.otherWaste?.reUsed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    currentYear: {
                                                        ...formvalue.currentYear,
                                                        otherWaste: {
                                                            ...formvalue?.currentYear?.otherWaste,
                                                            reUsed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'currentYear', 'otherWaste', 'reUsed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.currentYear?.otherWaste?.recycled}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    currentYear: {
                                                        ...formvalue.currentYear,
                                                        otherWaste: {
                                                            ...formvalue?.currentYear?.otherWaste,
                                                            recycled: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'currentYear', 'otherWaste', 'recycled')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.currentYear?.otherWaste?.safetyDisposed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    currentYear: {
                                                        ...formvalue.currentYear,
                                                        otherWaste: {
                                                            ...formvalue?.currentYear?.otherWaste,
                                                            safetyDisposed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'currentYear', 'otherWaste', 'safetyDisposed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.previousYear?.otherWaste?.reUsed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    previousYear: {
                                                        ...formvalue.previousYear,
                                                        otherWaste: {
                                                            ...formvalue?.previousYear?.otherWaste,
                                                            reUsed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'previousYear', 'otherWaste', 'reUsed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.previousYear?.otherWaste?.recycled}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    previousYear: {
                                                        ...formvalue.previousYear,
                                                        otherWaste: {
                                                            ...formvalue?.previousYear?.otherWaste,
                                                            recycled: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'previousYear', 'otherWaste', 'recycled')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.previousYear?.otherWaste?.safetyDisposed}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    previousYear: {
                                                        ...formvalue.previousYear,
                                                        otherWaste: {
                                                            ...formvalue?.previousYear?.otherWaste,
                                                            safetyDisposed: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'previousYear', 'otherWaste', 'safetyDisposed')}
                                                >
                                                    {/* {text} */}
                                                </div>
                                            </td>
                                        </tr>

                                    </table>
                                </div>
                                <div className='rggsgttxbbedxgtsprijnd'>
                                    <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal5(!notemodal5)}>Note  </p>
                                    {notemodal5 &&
                                        <div className="yestrxtwarea mt-3 mb-0">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        thirdnote: e.currentTarget.textContent
                                                    });
                                                }}
                                            >
                                            </div>
                                        </div>}
                                </div>
                            </div>


                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Reclaimed products and their packaging materials (as percentage of products sold) for
                                each product category
                            </li>

                            <table className='tablbrdrmain'>
                                <tr className='trdivmainbrdr'>
                                    <th className='thdivmainbrdr Indicatedibjhafsrttsdivfrst'>
                                        <p className='prcnttxttemplytxtpp'>Indicate product category</p>
                                    </th>
                                    <th className='thdivmainbrdr Indicatedibjhafsrttsdivscnd'>
                                        <p className='prcnttxttemplytxtpp'>Reclaimed products and their packaging materials
                                            as % of total products sold in respective category
                                        </p>
                                    </th>

                                </tr>
                                <tr>
                                    <td className='tddivmainbrdr'>
                                        {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.productCategoryDetails?.categoryName}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    productCategoryDetails: {
                                                        ...formvalue.productCategoryDetails,
                                                        categoryName: e.target.value
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('productCategoryDetails', 'categoryName', e.currentTarget.textContent)}
                                        >
                                            {/* {text} */}
                                        </div>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        {/* <textarea id="" name="" rows="2" cols="10"
                                            value={formvalue?.productCategoryDetails?.percenteageOfCategory}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    productCategoryDetails: {
                                                        ...formvalue.productCategoryDetails,
                                                        percenteageOfCategory: e.target.value
                                                    }
                                                });
                                            }}
                                        >

                                        </textarea> */}
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('productCategoryDetails', 'percenteageOfCategory', e.currentTarget.textContent)}
                                        >
                                            {/* {text} */}
                                        </div>
                                    </td>

                                </tr>
                            </table>
                        </div>
                    </ul>
                </div>

            </div>

            <div className="homePgCreModSubmitDiv">
                <button
                    className="homePgCreModSubmitBtn btn1"
                    type="button"
                    onClick={submitHandler}
                >
                    Next
                </button>
            </div>
        </div>
    )
}

export default Principletwo
