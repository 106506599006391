import React, { useEffect, useState } from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png";
import Select from "react-select";
import HttpClientXml from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';
import HomeService from '../../Services/HomeService';
import { useNavigate } from 'react-router-dom';

const CreateEvent = () => {
    const initial = {
        eventName: "",
        eventDate: "",
        eventTime: "",
        eventTypeId: "",
        isHighLighted: "",
        postType: "",
        notes: "",
        addEmploee: [],
        image: ""
    }

    const navigate = useNavigate();
    const [imageFile, setimageFile] = useState("");
    const [addEvent, setAddEvent] = useState(initial)
    const [allEventType, setAllEventType] = useState([])
    const [allEmployeeData, setAllEmployeeData] = useState([])
    const [selectedOption, setSelectedOption] = useState(null);

    console.log("s7t4f15d6", allEventType)

    // >>>>Post Type<<<<
    const options = [
        { value: 'public', label: 'Public' },
    ];

    // >>>>Handle Change of All Input<<<<
    const handleChange = (e) => {
        const { name, value } = e.target
        setAddEvent({ ...addEvent, [name]: value })
    }

    // >>>>GET All Event Type<<<<
    const fetchAllEventType = async () => {
        const res = await HomeService.getAllEventType();
        if (res && res?.status) {
            setAllEventType(res?.data)
        }
    }
    // >>>>GeET All Employees Data<<<<
    const fetchAllEmployee = async () => {
        const res = await HomeService.getAllEmployees();
        if (res && res?.status) {
            setAllEmployeeData(res?.data)
        }
    }

    // >>>Handle Image Upload<<<
    const handleImage = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        setimageFile(URL.createObjectURL(file));
        let formData = new FormData();
        formData.append("image", file);

        const res = await HttpClientXml.fileUplode("image-upload", "POST", formData);

        if (res && res.status) {
            toast.success("Image Uploaded Successfully");
            setAddEvent({ ...addEvent, image: res?.image });
        } else {
            toast.error(res?.message || "Something Wrong");
        }
    };

    // >>>Handle Removing Image<<<
    const handleRemoveImage = () => {
        setimageFile("");
        document.getElementById("hostedBy").value = "";
    };

    // >>>>HAndle Event Submit<<<<
    const onSubmit = async () => {
        const { eventName, eventDate, eventTime, eventTypeId, isHighLighted, postType, notes, addEmploee, image } = addEvent;

        const currentDate = new Date();
        const selectedDate = new Date(eventDate);

        if (!eventName || !eventDate || !eventTime || !eventTypeId || !isHighLighted || !postType || !notes || addEmploee?.length === 0) {
            toast.error('All fields are required');
            return;
        } else if (selectedDate < currentDate) {
            toast.error('Please select a valid date');
            return;
        } else if (imageFile?.trim() === "" || imageFile === null) {
            toast.error("Please upload document")
        } else {
            let data = {
                eventName,
                eventDate,
                eventTime,
                eventTypeId,
                isHighLighted,
                postType,
                notes,
                addEmploee,
                image
            }
            // return
            const res = await HomeService.addEvent(data);
            if (res && res?.status) {
                console.log("addEvent5465d4f", data, res)
                toast.success("Event added successfully")
                setAddEvent(initial)
                setimageFile("")
                setTimeout(() => {
                    navigate('/eventDetails');
                }, 1000);
            }
        }
    }

    useEffect(() => {
        fetchAllEventType();
        fetchAllEmployee();
    }, [])

    return (
        <>
            <section className="createeventdetails">
                <div className="detailspagecontainer">
                    <div className="createdivfrmmain">
                        <form>
                            <div className=''>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Event Name <span className='required'>*</span></p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder="enter event name"
                                        className="typstrningwhselctnew"
                                        name="eventName"
                                        value={addEvent?.eventName}
                                        onChange={handleChange}
                                    />

                                </div>


                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Event Date  <span className='required'>*</span></p>
                                    <input
                                        type="date"
                                        placeholder="enter event date"
                                        className="typstrningwhselctnew"
                                        name="eventDate"
                                        value={addEvent?.eventDate}
                                        onChange={handleChange}
                                    />


                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Start Time <span className='required'>*</span></p>
                                    <input
                                        type="time"
                                        id="eventTime"
                                        aria-describedby="emailHelp"
                                        placeholder="enter event start time"
                                        className="typstrningwhselctnew"
                                        name='eventTime'
                                        value={addEvent?.eventTime}
                                        onChange={handleChange}
                                    />

                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt"> Types Of Event <span className='required'>*</span></p>

                                    <Select
                                        placeholder={"select event type"}
                                        closeMenuOnSelect={true}
                                        defaultValue={selectedOption}
                                        onChange={(selectedOption) => {
                                            setSelectedOption(selectedOption);

                                            setAddEvent({ ...addEvent, eventTypeId: selectedOption?.value });
                                        }}
                                        options={allEventType && allEventType?.map(ele => ({ value: ele?._id, label: ele?.typeName }))}
                                        className="typstrningwhselctnew"
                                    />
                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt"> Share Your Event <span className='required'>*</span></p>
                                    <Select
                                        placeholder={"select share your event"}
                                        closeMenuOnSelect={true}
                                        defaultValue={selectedOption}
                                        onChange={(selectedOption) => {
                                            setSelectedOption(selectedOption);

                                            setAddEvent({ ...addEvent, postType: selectedOption?.value })
                                        }}
                                        options={options}
                                        className="typstrningwhselctnew"
                                    />
                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Invitees<span className='required'>*</span></p>

                                    <Select
                                        isMulti
                                        placeholder={"select invitees"}
                                        closeMenuOnSelect={false}
                                        defaultValue={selectedOption}
                                        onChange={(selectedOptions) => {
                                            const employeeIds = selectedOptions.map(option => option.value);
                                            setSelectedOption(selectedOptions);
                                            setAddEvent({ ...addEvent, addEmploee: employeeIds });
                                        }}
                                        options={allEmployeeData && allEmployeeData?.map(ele => ({ value: ele?._id, label: `${ele?.firstName} ${ele?.lastName}` }))}
                                        className="typstrningwhselctnew"
                                    />
                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Is this event highlighted?</p>
                                    <div className='isHighLightedRadiodivnewdiv'>

                                        <div className='isHighLightedRadiodivnew'>
                                            <input
                                                type="radio"
                                                className=""
                                                name='isHighLighted'
                                                value="yes"
                                                onChange={(e) => {
                                                    setAddEvent({ ...addEvent, isHighLighted: e.target.value })
                                                }}
                                            />
                                            <label htmlFor="isHighLightedYes" className="radioLabel" >
                                                Yes
                                            </label>
                                        </div>

                                        <div className='isHighLightedRadiodivnew'>
                                            <input
                                                type="radio"
                                                className=""
                                                name='isHighLighted'
                                                value="no"
                                                onChange={(e) => {
                                                    setAddEvent({ ...addEvent, isHighLighted: e.target.value })
                                                }}
                                            />
                                            <label htmlFor="isHighLightedNo" className="radioLabel" >
                                                No
                                            </label>
                                        </div>

                                    </div>
                                </div>

                                <div className="trainingrifgtdivgappp">
                                    <p className="rsntxrpnewtxt">Event Details <span> ( Maximum 100 Letter you can write )</span></p>
                                    <textarea
                                        placeholder='enter event description'
                                        id=""
                                        rows={4}
                                        cols={50}
                                        className="txtdivallartadvcacytfnew"
                                        name='notes'
                                        value={addEvent?.notes}
                                        onChange={handleChange}
                                    ></textarea>

                                </div>


                                <div className="vdoulddivpbupldgnewmmll">
                                    <p className="rsntxrpnewtxt"> Image Upload <span className='required'>*</span></p>

                                    <div className="bgcontainerupldboxsgwgrdiv">
                                        <div className="bgcrd">
                                            <div className="bgcontaineruplddivnew">
                                                <div className="logouplddiv">
                                                    <img
                                                        src={Addicon}
                                                        alt="..."
                                                    />
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            id="hostedBy"
                                                            className="upldimagediv"
                                                            placeholder="Choose a photo"
                                                            onChange={handleImage}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <p className="upldtxtppdiv">Upload min 5 mb image</p>

                                            {/* After Upload Design Start */}
                                            {imageFile && <div className="bgcontainerupldfiledivabslt">
                                                <div className="imageuplddiv">
                                                    <img src={imageFile} alt />
                                                    <div className="imageupldcrs" style={{
                                                        cursor: "pointer"
                                                    }}>
                                                        <i className="fa-solid fa-xmark" onClick={handleRemoveImage} />
                                                    </div>
                                                </div>
                                            </div>}
                                            {/* After Upload Design End */}

                                        </div>
                                    </div>
                                </div>
                                <div className="bulletinsbmtbtndivv">
                                    <button
                                        type="button"
                                        className="bulletinsbmtbtn"
                                        onClick={onSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreateEvent
