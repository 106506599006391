import React from 'react'
import doc from "../../Images/Icons/PNG/doc.png"
import docimg from "../../Images/docimg.jpeg";
import DocumentRepository from "../../Images/Icons/PNG/DocumentRepository.png";
import docreoiimg from "../../Images/docreoiimg.png";
import { useNavigate } from 'react-router-dom';
const Articlepage = () => {
  const navigate=useNavigate();

  const handlesinglearticle=()=>{
    navigate("/fullarticleviewsingle")
  }

  
  
  return (
    <section className='articleoagemain'>
      <div className='esgpagecontainer'>
        <div className='educatemainflx'>
          <div className='detailsdocleft'>
            <div className=''>
              <div className='articlepagemainbgmain'>
                <div className="top">
                  <div className="documntrepotxtimgflx">
                    <div className="documentRepositoryimgicon">
                      <img
                        src={DocumentRepository}
                        alt="..."
                      />
                    </div>
                    <div className="">
                      <p className="prchsetsxppp">Document Repository &gt;&gt; Purchase</p>
                    </div>
                  </div>
                </div>
                <div className="buttom">
                  <div className="dcmntdwnldflx">
                    <div className="documentRepositorycategorybg" onClick={handlesinglearticle}>
                      <div className="">
                        <div className="certificateimgdivbg">
                          <img src={docreoiimg} alt="..." />
                          <div className="itemcategppp">
                            <p className="itemcategppptext">Daily Stock bazar....</p>
                          </div>
                        </div>
                      </div>
                      <div className="topicviweflx">
                        <div className="">
                          <p className="datetxtpp">07-03-2024</p>
                        </div>
                        <div className="">
                          <p className="datetxtpp">1 Views</p>
                        </div>
                      </div>
                    </div>
                    <div className="documentRepositorycategorybg">
                      <div className="">
                        <div className="certificateimgdivbg">
                          <img src={docreoiimg} alt="..." />
                          <div className="itemcategppp">
                            <p className="itemcategppptext">Stocks on peak...</p>
                          </div>
                        </div>
                      </div>
                      <div className="topicviweflx">
                        <div className="">
                          <p className="datetxtpp">07-03-2024</p>
                        </div>
                        <div className="">
                          <p className="datetxtpp">0 Views</p>
                        </div>
                      </div>
                    </div>
                    <div className="documentRepositorycategorybg">
                      <div className="">
                        <div className="certificateimgdivbg">
                          <img
                            src={docreoiimg} alt="..." />
                          <div className="itemcategppp">
                            <p className="itemcategppptext">React js Document</p>
                          </div>
                        </div>
                      </div>
                      <div className="topicviweflx">
                        <div className="">
                          <p className="datetxtpp">08-03-2024</p>
                        </div>
                        <div className="">
                          <p className="datetxtpp">1 Views</p>
                        </div>
                      </div>
                    </div>
                    <div className="documentRepositorycategorybg">
                      <div className="">
                        <div className="certificateimgdivbg">
                          <img src={docreoiimg} alt="..." />
                          <div className="itemcategppp">
                            <p className="itemcategppptext">Daily Stock bazar....</p>
                          </div>
                        </div>
                      </div>
                      <div className="topicviweflx">
                        <div className="">
                          <p className="datetxtpp">07-03-2024</p>
                        </div>
                        <div className="">
                          <p className="datetxtpp">1 Views</p>
                        </div>
                      </div>
                    </div>
                    <div className="documentRepositorycategorybg">
                      <div className="">
                        <div className="certificateimgdivbg">
                          <img src={docreoiimg} alt="..." />
                          <div className="itemcategppp">
                            <p className="itemcategppptext">Stocks on peak...</p>
                          </div>
                        </div>
                      </div>
                      <div className="topicviweflx">
                        <div className="">
                          <p className="datetxtpp">07-03-2024</p>
                        </div>
                        <div className="">
                          <p className="datetxtpp">0 Views</p>
                        </div>
                      </div>
                    </div>
                    <div className="documentRepositorycategorybg">
                      <div className="">
                        <div className="certificateimgdivbg">
                          <img
                            src={docreoiimg} alt="..." />
                          <div className="itemcategppp">
                            <p className="itemcategppptext">React js Document</p>
                          </div>
                        </div>
                      </div>
                      <div className="topicviweflx">
                        <div className="">
                          <p className="datetxtpp">08-03-2024</p>
                        </div>
                        <div className="">
                          <p className="datetxtpp">1 Views</p>
                        </div>
                      </div>
                    </div>
                    <div className="documentRepositorycategorybg">
                      <div className="">
                        <div className="certificateimgdivbg">
                          <img src={docreoiimg} alt="..." />
                          <div className="itemcategppp">
                            <p className="itemcategppptext">Daily Stock bazar....</p>
                          </div>
                        </div>
                      </div>
                      <div className="topicviweflx">
                        <div className="">
                          <p className="datetxtpp">07-03-2024</p>
                        </div>
                        <div className="">
                          <p className="datetxtpp">1 Views</p>
                        </div>
                      </div>
                    </div>
                    <div className="documentRepositorycategorybg">
                      <div className="">
                        <div className="certificateimgdivbg">
                          <img src={docreoiimg} alt="..." />
                          <div className="itemcategppp">
                            <p className="itemcategppptext">Stocks on peak...</p>
                          </div>
                        </div>
                      </div>
                      <div className="topicviweflx">
                        <div className="">
                          <p className="datetxtpp">07-03-2024</p>
                        </div>
                        <div className="">
                          <p className="datetxtpp">0 Views</p>
                        </div>
                      </div>
                    </div>
                    <div className="documentRepositorycategorybg">
                      <div className="">
                        <div className="certificateimgdivbg">
                          <img
                            src={docreoiimg} alt="..." />
                          <div className="itemcategppp">
                            <p className="itemcategppptext">React js Document</p>
                          </div>
                        </div>
                      </div>
                      <div className="topicviweflx">
                        <div className="">
                          <p className="datetxtpp">08-03-2024</p>
                        </div>
                        <div className="">
                          <p className="datetxtpp">1 Views</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className='detailsdocright'>
            <div className=''>
              <div className="recentdocdiv">
                <div className="top">
                  <div className="recentdoccdiv">
                    <div className="articleiconimgdiv">
                      <img
                        src={doc}
                        alt="..."
                      />
                    </div>
                    <div className="">
                      <p className="dpocsttxtppp">Your Recent Docs</p>
                    </div>
                  </div>
                </div>
                <div className="buttom">
                  <div className="docstxtpflxdaa" style={{ cursor: "pointer" }}>
                    <div className="articleiconimgdiv">
                      <img
                        src={docimg}
                        alt="..."
                      />
                    </div>
                    <div className="">
                      <p className="dcmnttxtpp">What is Lorem Ipsum?</p>
                    </div>
                  </div>
                  <div className="docstxtpflxdaa" style={{ cursor: "pointer" }}>
                    <div className="articleiconimgdiv">
                      <img
                        src={docimg}
                        alt="..."
                      />
                    </div>
                    <div className="">
                      <p className="dcmnttxtpp">Upload</p>
                    </div>
                  </div>
                  <div className="docstxtpflxdaa" style={{ cursor: "pointer" }}>
                    <div className="articleiconimgdiv">
                      <img
                        src={docimg}
                        alt="..."
                      />
                    </div>
                    <div className="">
                      <p className="dcmnttxtpp">Finance Document 1</p>
                    </div>
                  </div>
                  <div className="docstxtpflxdaa" style={{ cursor: "pointer" }}>
                    <div className="articleiconimgdiv">
                      <img
                        src={docimg}
                        alt="..."
                      />
                    </div>
                    <div className="">
                      <p className="dcmnttxtpp">React js Document</p>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Articlepage
