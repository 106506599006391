import React from 'react'

const CreateSkillValidation = () => {
    return (
        <>
            <section className='createeventdetails'>
                <div className="detailspagecontainer">
                    <div className="createdivfrmmain">
                        <form>
                            <div className=''>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">
                                        Skill Add <span className="required">*</span>
                                    </p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name="eventName"
                                        defaultValue=""
                                    />
                                </div>

                                <div className="bulletinsbmtbtndivv">
                                    <button
                                        type="button"
                                        className="bulletinsbmtbtn"
                                       
                                    >
                                        Submit
                                    </button>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>
            </section>
        </>
    )
}

export default CreateSkillValidation
