import React, { useEffect, useState } from 'react'
import trainingmain from "../../src/Images/trainingmain.png";
import time from "../../src/Images/Icons/PNG/time.png"
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import HttpClientXml from '../utils/HttpClientXml';
import toast from 'react-hot-toast';
import reward from "../../src/Images/Icons/PNG/reward.png";


const Trainingvideomodal = ({ closemodal, singleMandatory, rewardPoint }) => {
    const [notEnroll, setNotEnroll] = useState("");
    const navigate = useNavigate();
    // const [descriptionshow, setDescription] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [colourOptions, setColourOptions] = useState([]);


    //for getting internal trainer name
    const getEmployeeData = async () => {
        const response = await HttpClientXml?.requestData("view-all-employees", {}, "GET")

        if (response && response.status) {
            const formattedOptions = response?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
                image: item?.image
            }));

            setColourOptions(formattedOptions);
        }
    };


    useEffect(() => {
        const data = singleMandatory?.mentor
        const mentorData = data?.map((item) => {
            const optData = colourOptions?.find(ele => ele?.value === item)
            return optData ? optData : {}
        })
        setSelectedOptions(mentorData);
    }, [colourOptions])


    const handlehowClick = () => {
        const data = {
            selectedOptions: selectedOptions,
            singleMandatory: singleMandatory
        }
        navigate(`/enlargedNewEnrollment/${singleMandatory?.title?.replace(/\s+/g, '-')}/${singleMandatory?._id}`, { state: data })
    }

    
    const handlesubitstpClick = (e) => {
        e.stopPropagation(e)
    }

    //for Enroll functionality
    const handleEnroll = async () => {
        let data = {
            trainingId: singleMandatory?._id
        }
        const res = await HttpClientXml?.requestData(`join-training`, data, "POST");

        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, enroll it!",
        }).then((res) => {
            if (res.isConfirmed) {
                setNotEnroll(true)
                toast.success("Enrolled Successfully");
            }
            else if (res.isDismissed) {
                setNotEnroll(false)
            }
        });
    }

    //for getting status is joined in training
    const handleIsJoined = async () => {
        let res = await HttpClientXml?.requestData(`is-joined/${singleMandatory?._id}`, {}, "GET")
        if (res && res?.status) {
            setNotEnroll(res?.isJoined);
        }
    }

    useEffect(() => {
        handleIsJoined();
        getEmployeeData();
    }, [])


    const cousrevideofirstonClick = () => {
        const data = {
            selectedOptions: selectedOptions,
            singleMandatory: singleMandatory
        }
        navigate(`/coursefirstVideoPlayer/${singleMandatory?.title?.replace(/\s+/g, '-')}/${singleMandatory?._id}/${singleMandatory?.videos?.[0]?._id}`, { state: data })
    }

    return (
        <>
            <section className='thopughtmodabgmain' onClick={() => closemodal()}>
                <div className='mandatorymodalmaincontent' onClick={(e) => handlesubitstpClick(e)}>
                    <div className='vdeoimgmain'>
                        {/* <img src={trainingmain1} alt="..." /> */}
                        {
                            (singleMandatory?.image === "images" || singleMandatory?.image === "" || singleMandatory?.image === "image") ? (<div>
                                <img src={trainingmain} alt="..." />
                            </div>) : (<>
                                <img src={singleMandatory?.image} alt="..." />
                            </>)
                        }
                        <div className='videoimgdiv'>
                            {/* <img src={video} alt='...' /> */}
                            {/* {
                                singleMandatory?.videos?.[0]?.videoLink ? (<img src={singleMandatory?.videos?.[0]?.videoLink} alt="..." />) : (<img src={video} alt='...' />)
                            } */}
                        </div>
                    </div>
                    {/* <div className='divenallbootcmp'>
                        <div className="divstarimgmain">
                            <div className="divstarimgmainimg">
                                <img src={time} alt="..." />
                            </div>
                            <p className='omintxttppp'>0 min</p>
                        </div>
                        <div className="divstarimgmain">
                            <div className="divstarimgmainimg">
                                <img src={time} alt="..." />
                            </div>
                            <p className='omintxttppp'>250</p>
                        </div>

                    </div> */}
                    <div className='divenrolflx'>
                        <div className='divenallbootcmp'>
                            {
                                singleMandatory?.avgRatings !== null
                                    ?
                                    <div className='divstarimgmain'>
                                        <div className='divstarimgmainimg'>
                                            <img src={reward} alt="..." />
                                        </div>
                                        <p>{singleMandatory?.avgRatings}</p>
                                    </div>
                                    :
                                    <div className="imgtxtdivmainddd">
                                        <div className="strimgdiv">
                                            <img
                                                src={reward}
                                                alt="..."
                                            />
                                        </div>
                                        <p className="tsgyutppp">0</p>
                                    </div>
                            }
                            <div className='divstarimgmain'>
                                <div className='divstarimgmainimg'>
                                    <img src={time} alt="..." />
                                </div>
                                {/* <p>{singleMandatory?.totalDurationInMinute} min</p> */}

                                {
                                    singleMandatory?.placeTime?.length > 0
                                        ?
                                        (
                                            <>
                                                {singleMandatory?.physicalTotalDurationInMinute} min
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                {singleMandatory?.totalDurationInMinute} min
                                            </>
                                        )
                                }

                            </div>
                            <div className='divstarimgmain'>
                                <div className='divstarimgmainimg'>
                                    <img src={reward} alt="..." />
                                </div>
                                <p>{rewardPoint}</p>
                            </div>
                        </div>
                        {
                            singleMandatory?.placeTime?.length !== 0 ? (<></>) : (
                                <div className='enrolllnowdiv' style={{ cursor: "pointer" }}>
                                    {
                                        notEnroll === false ? (<div onClick={handleEnroll}>Enroll Now</div>) : (<div onClick={() => cousrevideofirstonClick(singleMandatory?._id)}>Play Now</div>)
                                    }

                                </div>
                            )
                        }

                    </div>

                    <div className=''>
                        <div className="decrtxtppdiv">
                            <p className='decrtxtpp'>Description</p>
                            <p className='descriptiondetails'>
                                {singleMandatory?.description}
                            </p>
                            <div className='showdescripttext' onClick={handlehowClick}>Show More</div>
                        </div>

                    </div>
                    {/* <div className="decrtxtppdiv">
                        <p className="decrtxtpp">Description</p>
                        <p className="descriptiondetails">
                            In this training you will be throughly trained about how to work in live
                            projects by understanding client's requirments.
                        </p>
                        <Link className="showdescripttext" to="/enlargedNewEnrollment">Show More</Link>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default Trainingvideomodal
