import HttpClientXml from "../utils/HttpClientXml";

// >>>All GET Api<<< 
const getInitiativeType = async (data) => {
    let endPoint = "view-initiative-type";
    return HttpClientXml.get(endPoint, data);
};

const getAllInitiative = async (data) => {
    let endPoint = "view-all-initiative";
    return HttpClientXml.get(endPoint, data);
};

const getAllEmployees = async (data) => {
    let endPoint = "view-all-employees";
    return HttpClientXml.get(endPoint, data);
};

const getAllBulletins = async (data) => {
    let endPoint = "view-all-bulletin-board";
    return HttpClientXml.get(endPoint, data);
};

const getAllHallFame = async (data) => {
    let endPoint = "get-hallfame";
    return HttpClientXml.get(endPoint, data);
};

const getAllEvent = async (data) => {
    let endPoint = "view-event";
    return HttpClientXml.get(endPoint, data);
};

const getAllEventType = async (data) => {
    let endPoint = "view-event-type";
    return HttpClientXml.get(endPoint, data);
};

const getAllDepartment = async (data) => {
    let endPoint = "view-department";
    return HttpClientXml.get(endPoint, data);
};

const viewAllNonTechnicalTraining = async (data) => {
    let endPoint = "view-nontechnical-training";
    return HttpClientXml.get(endPoint, data);
};

const viewAllMandatoryTraining = async (data) => {
    let endPoint = "view-mandatory-training";
    return HttpClientXml.get(endPoint, data);
};

const viewAllSkillTraining = async (data) => {
    let endPoint = "view-skill-training";
    return HttpClientXml.get(endPoint, data);
};

const viewAllTraining = async (data) => {
    let endPoint = "view-all-trainings";
    return HttpClientXml.get(endPoint, data);
};

const viewStatus = async (data) => {
    let endPoint = "get-profile";
    return HttpClientXml.get(endPoint, data);
};

// >>>All POST Api<<<    
const addInitiative = async (data) => {
    let endPoint = "add-initiative";
    return HttpClientXml.post(endPoint, data);
};

const addBulletin = async (data) => {
    let endPoint = "add-bulletin-board";
    return HttpClientXml.post(endPoint, data);
};

const addEvent = async (data) => {
    let endPoint = "add-event";
    return HttpClientXml.post(endPoint, data);
};

const addTraining = async (data) => {
    let endPoint = "add-onboarding-training";
    return HttpClientXml.post(endPoint, data);
};

const addUserPost = async (data) => {
    let endPoint = "user-add-post";
    return HttpClientXml.post(endPoint, data);
};

const viewUserPost = async (data) => {
    let endPoint = "view-all-post";
    return HttpClientXml.post(endPoint, data);
};

const viewEmployeeDepartmentWise = async (data) => {
    let endPoint = "view-employee-department-wise";
    return HttpClientXml.post(endPoint, data);
};

export default {
    // >>>All GET<<< 
    getInitiativeType, getAllInitiative, getAllEmployees, getAllBulletins, getAllHallFame, getAllEvent, getAllEventType, getAllDepartment, viewAllNonTechnicalTraining, viewAllMandatoryTraining, viewAllSkillTraining, viewAllTraining,
    viewStatus,

    // >>>All POST<<<
    addInitiative, addBulletin, addEvent, addTraining, addUserPost, viewUserPost, viewEmployeeDepartmentWise

}