// import React, { useEffect, useState } from 'react'
// // import Event_View from "../../Images/Icons/PNG/Event_View.png";
// // import bullentinimg from "../../Images/bullentinimg.jpg";
// // import sharethghts from "../../Images/sharethghts.png";
// // import eventtesting from "../../Images/eventtesting.jpeg";
// import { Link, useNavigate } from 'react-router-dom';
// import Addicon from "../../Images/Icons/PNG/Add icon.png"
// import HomeService from '../../Services/HomeService';
// import moment from 'moment';
// const EventDetails = () => {
//     const [allEvent, setAllEvent] = useState([])
//     console.log("s4t98rs74f", allEvent)
//     const navigate = useNavigate();


//     const handleEventView = (item) => {
//         navigate('/eventSingle', { state: item })
//     }


//     // >>>>GET All Event<<<<
//     const fetchAllEvent = async () => {
//         const res = await HomeService.getAllEvent();
//         if (res && res?.status) {
//             setAllEvent(res?.data)
//         }
//     }

//     useEffect(() => {
//         fetchAllEvent()
//     }, [])
//     return (
//         <>
//             <section className='eventdetailssection'>
//                 <div className='detailspagecontainer'>
//                     <div className='bulletindetailsbg'>
//                         <div className='singlepagetop'>
//                             <div className="singlebulletinflx"> 
//                                 <h4 className='singlepagetoptxt'>Event</h4>

//                                 <Link className='singllepageicon' to="/createevent">
//                                     <img src={Addicon} alt="..." />
//                                 </Link>
//                             </div>
//                         </div>
//                         <div className='singlepagebottomm'>
//                             {allEvent && allEvent?.length > 0 ?
//                                 allEvent.map((item) => {
//                                     return (
//                                         <div className='singlepagebottommmaindv' key={item?._id}>
//                                             <div className=''>
//                                                 <div className='singleimagebulletin'>
//                                                     <img src={item?.image} className="img-fluid" alt="event" />
//                                                     <div className='thredotsbtn'>
//                                                         <i className="fa-solid fa-ellipsis-vertical"></i>
//                                                     </div>

//                                                 </div>
//                                             </div>
//                                             <div className='bulletindetailsbottom'>
//                                                 <div className=''>
//                                                     <p className='bulletintxtppsingledate'>Event Name :
//                                                         <span>
//                                                             {item?.eventName}
//                                                         </span></p>
//                                                     <p className='bulletintxtppsingledate'>Date :
//                                                         <span>
//                                                             {moment(item?.eventDate).format("YYYY-MM-DD")}
//                                                         </span> </p>
//                                                 </div>
//                                                 <div className='viebtndivmain'>
//                                                     <button className='viewbtn' onClick={() => handleEventView(item)}>View</button>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     )
//                                 })
//                                 :
//                                 <div>No Data Found!!</div>
//                             }

//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     )
// }

// export default EventDetails


import React, { useEffect, useState } from 'react'
import BigCalender from '../../CustomComponent/BigCalender'
import HomeService from '../../Services/HomeService';
import Highlightedevents from './Highlightedevents';
import Mysubscribeevents from './Mysubscribeevents';
import { Link } from 'react-router-dom';
import Addicon from "../../Images/Icons/PNG/Add icon.png"
const EventDetails = () => {
    const [allEvent, setAllEvent] = useState([]);

    // >>>>GET All Event<<<<
    const fetchAllEvent = async () => {
        const res = await HomeService.getAllEvent();
        if (res && res?.status) {
            setAllEvent(res?.data)
        }
    }

    useEffect(() => {
        fetchAllEvent();
    }, [])
    return (
        <>
            <section className='eventdetailssection'>
                <div className='esgpagecontainer'>
                    <div className='calenderandithredatadivflx'>
                        <div className='clndrbigdivmaion'>
                            <div className='clndrbigdivmaiondiv'>
                                <BigCalender myEventsList={allEvent} />
                            </div>
                        </div>
                        <div className='calderdatadivright'>
                            <div className=''>
                                <Link className="traioningfnewcreatemaindiv" to="/createevent" >
                                    <div className="addimgdiv">
                                        <img
                                            src={Addicon}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="">
                                        <p className="crtadvocacytxtypp">Create Event</p>
                                    </div>
                                </Link>
                            </div>
                            <div className='highilightdeebcbtddiv'>
                                <Highlightedevents />
                            </div>
                            <div className='mysubvsevntdiv'>
                                <Mysubscribeevents />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default EventDetails