import React, { useState } from 'react'
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import imgadvcy from "../../Images/imgadvcy.png";
import sharethght from "../../Images/sharethght.jpg";
import advacyimgfr from "../../Images/advacyimgfr.png";
import advcyimgrhre from "../../Images/advcyimgrhre.jpeg";
import advcyimgtwo from "../../Images/advcyimgtwo.jpeg"
import Addicon from "../../Images/Icons/PNG/Add icon.png"
import { Link, useNavigate } from 'react-router-dom';
const Advocacy = () => {
    const [activeTab, setActiveTab] = useState(0);
    const navigate=useNavigate()
    const onTabClick = (e, index) => {
        console.log(e);
        setActiveTab(index);
    };
    const tabsArray = [
        {
            tabcontent: "All"
        },
        {
            tabcontent: "New"
        },
        {
            tabcontent: "Success Stories"
        },
        {
            tabcontent: "CSR Initiatives"
        },

        {
            tabcontent: "Knowledge Article"
        },

        {
            tabcontent: "Industry Insights"
        },
        {
            tabcontent: "All"
        },
        {
            tabcontent: "New"
        },
        {
            tabcontent: "Success Stories"
        },

    ];

    const singleadvocacyclick=()=>{
        navigate("/singleadvocacy")
    }
    return (
        <section className='advocacymainsection'>
            <div class="esgpagecontainer">
                <div className=''>
                    <Tabs activeTab={activeTab} onTabClick={onTabClick}>
                        {/* generating an array to loop through it  */}
                        {tabsArray.map((item) => (
                            <Tab key={item}> {item.tabcontent}</Tab>
                        ))}
                    </Tabs>


                    <TabScreen

                        activeTab={activeTab}
                        index={0}
                        className="some-animation-class"


                    >
                        <div className='advocaysectioondiv'>
                            <div className='advocaydivcntnt'>
                                <div className='advocaydivcntnttopimg' onClick={singleadvocacyclick}>
                                    <img src={imgadvcy} alt="..." />
                                    <div className='advcnprlnnimgrxrdivflkx'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                </div>
                            </div>
                            <div className='advocaydivcntntscnd'>
                                <Link className="advocacycreatemaindiv" to="/createAdvocacy" >
                                    <div className="addimgdiv">
                                        <img
                                            src={Addicon}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="">
                                        <p className="crtadvocacytxtypp">Create Advocacy</p>
                                    </div>
                                </Link>
                                <div className='advocaydivcntntsnmcndbmain'>
                                    <div className='advocaydivcntnttopimglsr'>
                                        <img src={imgadvcy} alt="..." />

                                    </div>
                                    <div className='advocaydivcntntbtmcntn'>
                                        <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                        <div className='advcnprlnnimgrxrdivchng'>
                                            <div className='advcnprlnnimg'>
                                                <img src={sharethght} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='avdbbvdvprflnsmm'>
                                                    Nilardri Roy
                                                </p>
                                                <p className='avdbbvdvprflnsmm'>
                                                    16-May-2024
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgtwo} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </TabScreen>

                    <TabScreen

                        activeTab={activeTab}
                        index={1}
                        className="some-animation-class"
                    >
                        <div className='advocaysectioondiv'>
                            <div className='advocaydivcntnt'>
                                <div className='advocaydivcntnttopimg'>
                                    <img src={advcyimgrhre} alt="..." />
                                    <div className='advcnprlnnimgrxrdivflkx'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                </div>
                            </div>
                            <div className='advocaydivcntntscnd'>
                                <Link className="advocacycreatemaindiv" to="/createAdvocacy" >
                                    <div className="addimgdiv">
                                        <img
                                            src={Addicon}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="">
                                        <p className="crtadvocacytxtypp">Create Advocacy</p>
                                    </div>
                                </Link>
                                <div className='advocaydivcntntsnmcndbmain'>
                                    <div className='advocaydivcntnttopimglsr'>
                                        <img src={imgadvcy} alt="..." />

                                    </div>
                                    <div className='advocaydivcntntbtmcntn'>
                                        <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                        <div className='advcnprlnnimgrxrdivchng'>
                                            <div className='advcnprlnnimg'>
                                                <img src={sharethght} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='avdbbvdvprflnsmm'>
                                                    Nilardri Roy
                                                </p>
                                                <p className='avdbbvdvprflnsmm'>
                                                    16-May-2024
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </TabScreen>

                    <TabScreen

                        activeTab={activeTab}
                        index={2}
                        className="some-animation-class"
                    >
                        <div className='advocaysectioondiv'>
                            <div className='advocaydivcntnt'>
                                <div className='advocaydivcntnttopimg'>
                                    <img src={advacyimgfr} alt="..." />
                                    <div className='advcnprlnnimgrxrdivflkx'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                </div>
                            </div>
                            <div className='advocaydivcntntscnd'>
                                <Link className="advocacycreatemaindiv" to="/createAdvocacy" >
                                    <div className="addimgdiv">
                                        <img
                                            src={Addicon}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="">
                                        <p className="crtadvocacytxtypp">Create Advocacy</p>
                                    </div>
                                </Link>
                                <div className='advocaydivcntntsnmcndbmain'>
                                    <div className='advocaydivcntnttopimglsr'>
                                        <img src={imgadvcy} alt="..." />

                                    </div>
                                    <div className='advocaydivcntntbtmcntn'>
                                        <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                        <div className='advcnprlnnimgrxrdivchng'>
                                            <div className='advcnprlnnimg'>
                                                <img src={sharethght} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='avdbbvdvprflnsmm'>
                                                    Nilardri Roy
                                                </p>
                                                <p className='avdbbvdvprflnsmm'>
                                                    16-May-2024
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </TabScreen>

                    <TabScreen

                        activeTab={activeTab}
                        index={3}
                        className="some-animation-class"
                    >
                        <div className='advocaysectioondiv'>
                            <div className='advocaydivcntnt'>
                                <div className='advocaydivcntnttopimg'>
                                    <img src={advcyimgtwo} alt="..." />
                                    <div className='advcnprlnnimgrxrdivflkx'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                </div>
                            </div>
                            <div className='advocaydivcntntscnd'>
                                <Link className="advocacycreatemaindiv" to="/createAdvocacy" >
                                    <div className="addimgdiv">
                                        <img
                                            src={Addicon}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="">
                                        <p className="crtadvocacytxtypp">Create Advocacy</p>
                                    </div>
                                </Link>
                                <div className='advocaydivcntntsnmcndbmain'>
                                    <div className='advocaydivcntnttopimglsr'>
                                        <img src={advcyimgtwo} alt="..." />

                                    </div>
                                    <div className='advocaydivcntntbtmcntn'>
                                        <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                        <div className='advcnprlnnimgrxrdivchng'>
                                            <div className='advcnprlnnimg'>
                                                <img src={sharethght} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='avdbbvdvprflnsmm'>
                                                    Nilardri Roy
                                                </p>
                                                <p className='avdbbvdvprflnsmm'>
                                                    16-May-2024
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgtwo} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </TabScreen>

                    <TabScreen

                        activeTab={activeTab}
                        index={4}
                        className="some-animation-class"


                    >
                        <div className='advocaysectioondiv'>
                            <div className='advocaydivcntnt'>
                                <div className='advocaydivcntnttopimg'>
                                    <img src={imgadvcy} alt="..." />
                                    <div className='advcnprlnnimgrxrdivflkx'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                </div>
                            </div>
                            <div className='advocaydivcntntscnd'>
                                <Link className="advocacycreatemaindiv" to="/createAdvocacy" >
                                    <div className="addimgdiv">
                                        <img
                                            src={Addicon}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="">
                                        <p className="crtadvocacytxtypp">Create Advocacy</p>
                                    </div>
                                </Link>
                                <div className='advocaydivcntntsnmcndbmain'>
                                    <div className='advocaydivcntnttopimglsr'>
                                        <img src={imgadvcy} alt="..." />

                                    </div>
                                    <div className='advocaydivcntntbtmcntn'>
                                        <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                        <div className='advcnprlnnimgrxrdivchng'>
                                            <div className='advcnprlnnimg'>
                                                <img src={sharethght} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='avdbbvdvprflnsmm'>
                                                    Nilardri Roy
                                                </p>
                                                <p className='avdbbvdvprflnsmm'>
                                                    16-May-2024
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgtwo} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </TabScreen>

                    <TabScreen

                        activeTab={activeTab}
                        index={5}
                        className="some-animation-class"
                    >
                        <div className='advocaysectioondiv'>
                            <div className='advocaydivcntnt'>
                                <div className='advocaydivcntnttopimg'>
                                    <img src={advcyimgrhre} alt="..." />
                                    <div className='advcnprlnnimgrxrdivflkx'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                </div>
                            </div>
                            <div className='advocaydivcntntscnd'>
                            <Link className="advocacycreatemaindiv" to="/createAdvocacy" >
                                    <div className="addimgdiv">
                                        <img
                                            src={Addicon}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="">
                                        <p className="crtadvocacytxtypp">Create Advocacy</p>
                                    </div>
                                </Link>
                                <div className='advocaydivcntntsnmcndbmain'>
                                    <div className='advocaydivcntnttopimglsr'>
                                        <img src={imgadvcy} alt="..." />

                                    </div>
                                    <div className='advocaydivcntntbtmcntn'>
                                        <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                        <div className='advcnprlnnimgrxrdivchng'>
                                            <div className='advcnprlnnimg'>
                                                <img src={sharethght} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='avdbbvdvprflnsmm'>
                                                    Nilardri Roy
                                                </p>
                                                <p className='avdbbvdvprflnsmm'>
                                                    16-May-2024
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </TabScreen>

                    <TabScreen

                        activeTab={activeTab}
                        index={6}
                        className="some-animation-class"
                    >
                        <div className='advocaysectioondiv'>
                            <div className='advocaydivcntnt'>
                                <div className='advocaydivcntnttopimg'>
                                    <img src={advacyimgfr} alt="..." />
                                    <div className='advcnprlnnimgrxrdivflkx'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                </div>
                            </div>
                            <div className='advocaydivcntntscnd'>
                            <Link className="advocacycreatemaindiv" to="/createAdvocacy" >
                                    <div className="addimgdiv">
                                        <img
                                            src={Addicon}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="">
                                        <p className="crtadvocacytxtypp">Create Advocacy</p>
                                    </div>
                                </Link>
                                <div className='advocaydivcntntsnmcndbmain'>
                                    <div className='advocaydivcntnttopimglsr'>
                                        <img src={imgadvcy} alt="..." />

                                    </div>
                                    <div className='advocaydivcntntbtmcntn'>
                                        <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                        <div className='advcnprlnnimgrxrdivchng'>
                                            <div className='advcnprlnnimg'>
                                                <img src={sharethght} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='avdbbvdvprflnsmm'>
                                                    Nilardri Roy
                                                </p>
                                                <p className='avdbbvdvprflnsmm'>
                                                    16-May-2024
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </TabScreen>

                    <TabScreen

                        activeTab={activeTab}
                        index={7}
                        className="some-animation-class"
                    >
                        <div className='advocaysectioondiv'>
                            <div className='advocaydivcntnt'>
                                <div className='advocaydivcntnttopimg'>
                                    <img src={advcyimgrhre} alt="..." />
                                    <div className='advcnprlnnimgrxrdivflkx'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                </div>
                            </div>
                            <div className='advocaydivcntntscnd'>
                            <Link className="advocacycreatemaindiv" to="/createAdvocacy" >
                                    <div className="addimgdiv">
                                        <img
                                            src={Addicon}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="">
                                        <p className="crtadvocacytxtypp">Create Advocacy</p>
                                    </div>
                                </Link>
                                <div className='advocaydivcntntsnmcndbmain'>
                                    <div className='advocaydivcntnttopimglsr'>
                                        <img src={imgadvcy} alt="..." />

                                    </div>
                                    <div className='advocaydivcntntbtmcntn'>
                                        <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                        <div className='advcnprlnnimgrxrdivchng'>
                                            <div className='advcnprlnnimg'>
                                                <img src={sharethght} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='avdbbvdvprflnsmm'>
                                                    Nilardri Roy
                                                </p>
                                                <p className='avdbbvdvprflnsmm'>
                                                    16-May-2024
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </TabScreen>

                    <TabScreen

                        activeTab={activeTab}
                        index={8}
                        className="some-animation-class"


                    >
                        <div className='advocaysectioondiv'>
                            <div className='advocaydivcntnt'>
                                <div className='advocaydivcntnttopimg'>
                                    <img src={imgadvcy} alt="..." />
                                    <div className='advcnprlnnimgrxrdivflkx'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                </div>
                            </div>
                            <div className='advocaydivcntntscnd'>
                            <Link className="advocacycreatemaindiv" to="/createAdvocacy" >
                                    <div className="addimgdiv">
                                        <img
                                            src={Addicon}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="">
                                        <p className="crtadvocacytxtypp">Create Advocacy</p>
                                    </div>
                                </Link>
                                <div className='advocaydivcntntsnmcndbmain'>
                                    <div className='advocaydivcntnttopimglsr'>
                                        <img src={imgadvcy} alt="..." />

                                    </div>
                                    <div className='advocaydivcntntbtmcntn'>
                                        <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                        <div className='advcnprlnnimgrxrdivchng'>
                                            <div className='advcnprlnnimg'>
                                                <img src={sharethght} alt="..." />
                                            </div>
                                            <div className=''>
                                                <p className='avdbbvdvprflnsmm'>
                                                    Nilardri Roy
                                                </p>
                                                <p className='avdbbvdvprflnsmm'>
                                                    16-May-2024
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgtwo} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={imgadvcy} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='advcyothrsdiv'>
                                <div className='advocaydivcntnttopimglsr'>
                                    <img src={advcyimgrhre} alt="..." />

                                </div>
                                <div className='advocaydivcntntbtmcntn'>
                                    <p className='advocaydivcntntbtmcntntxt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia dolorum adipisci voluptates, dolore temporibus nesciunt modi. Veniam eius voluptas totam omnis temporibus dignissimos repellendus nesciunt ab dicta. Provident, sequi.</p>
                                    <div className='advcnprlnnimgrxrdivchng'>
                                        <div className='advcnprlnnimg'>
                                            <img src={sharethght} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='avdbbvdvprflnsmm'>
                                                Nilardri Roy
                                            </p>
                                            <p className='avdbbvdvprflnsmm'>
                                                16-May-2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </TabScreen>


                </div>
            </div>
        </section>
    )
}

export default Advocacy
