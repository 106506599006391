import React, { useEffect, useState } from 'react'
import Expand from "../../Images/Icons/PNG/Expand.png";
// import EducateMandatory from './EducateMandatory';
import { Link, useNavigate } from 'react-router-dom';
import Trainingvideomodal from '../../Modal/Trainingvideomodal';
import trainingmain from "../../Images/trainingmain.png";
import clock from "../../Images/Icons/PNG/time.png";
import reward from "../../Images/Icons/PNG/reward.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
// import HomeService from '../../Services/HomeService';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getOnboardingTraining } from '../../Redux/Slice/TrainingSlice';

const Mandatory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [mandatoryData, setMandatoryData] = useState([]);
    const [trainimgvideo, setTrainimgvideo] = useState(false);
    const [singleMandatory, setSingleMandatory] = useState({});
    const [rewardPoint, setRewardPoint] = useState("");
    const training = useSelector((state) => state?.TrainingSlice?.training)
    console.log("trainingio", training)

    // const trainimgvdoclick = () => {
    //     setTrainimgvideo(!trainimgvideo)
    // }

    const handlevideoClick = (item, point) => {
        setRewardPoint(point);
        setSingleMandatory(item);
        setTrainimgvideo(!trainimgvideo)
    }

    var settings1 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }


    // >>>>Fetch Mandatory Training Data<<<<
    // const fetchMandatoryTraining = async () => {
    //     const res = await HomeService.viewAllMandatoryTraining();
    //     if (res && res?.status) {
    //         setMandatoryData(res?.data)
    //         console.log("6fg864123c", res?.data)
    //     }
    // }

    const handleDetailsClick = (ele) => {
        navigate('/SingleMandatoryCourse', { state: ele })
    }

    useEffect(() => {
        // fetchMandatoryTraining();
        dispatch(getOnboardingTraining());
    }, [])

    return (
        <>
            {
                training?.map((ele, ind) => {
                    return (
                        <div className='mandatorydiv' key={ind}>
                            <div className='mandatoryicontxtflx'>
                                <div className='icontxtdivmain'>

                                    <div className=''>
                                        <p className='mandatorttxt'>Mandatory Course</p>
                                    </div>
                                </div>
                                <Link className='Expandimg' onClick={() => handleDetailsClick(ele)} >
                                    <img src={Expand} alt="..." />
                                </Link>
                            </div>

                            <div className='mandatorydivbtm'>
                                {/* <EducateMandatory /> */}

                                <Slider {...settings1}>

                                    {
                                        ele?.trainingDetails?.length > 0 ?
                                            // mandatoryData?.map((item) => {
                                            // const hours = Math.floor(item?.totalDurationInMinute / 60);
                                            // const minutes = item?.totalDurationInMinute % 60;
                                            // const time = moment().startOf('day').add(hours, 'hours').add(minutes, 'minutes').format('H[h] m[m]');
                                            ele?.trainingDetails?.map((item, i) => {
                                                const hours1 = Math?.floor(item?.physicalTotalDurationInMinute / 60);
                                                const minutes1 = item?.physicalTotalDurationInMinute % 60;
                                                const time1 = moment().startOf('day').add(hours1, 'hours').add(minutes1, 'minutes').format('H[h] m[m]');

                                                const hours = Math?.floor(item?.totalDurationInMinute / 60);
                                                const minutes = item?.totalDurationInMinute % 60;
                                                const time = moment().startOf('day').add(hours, 'hours').add(minutes, 'minutes').format('H[h] m[m]');

                                                return (
                                                    <div className='mandatorydivbg' key={i} onClick={() => handlevideoClick(item, ele?.rewardPoint)}>
                                                        {/* <div className='traingmaindivmain'>
                                                        <img src={item?.image} alt="..." />
                                                    </div> */}
                                                        {
                                                            (item?.image === "images" || item?.image === "" || item?.image === "image") ? (<div className='traingmaindivmain'>
                                                                <img src={trainingmain} alt="..." />
                                                            </div>) : (<div className='traingmaindivmain'>
                                                                <img src={item?.image} alt="..." />
                                                            </div>)
                                                        }

                                                        <div className='descritptoinmadtorydiv'>
                                                            <p className='mandttxtpp'>
                                                                {item?.title}
                                                            </p>
                                                        </div>
                                                        <div className="imgtxtdivmaindddflx">
                                                            {
                                                                item?.avgRatings !== null
                                                                    ?
                                                                    <div className="imgtxtdivmainddd">
                                                                        <div className="strimgdiv">
                                                                            <img
                                                                                src={reward}
                                                                                alt="..."
                                                                            />
                                                                        </div>
                                                                        <p className="tsgyutppp">{item?.avgRatings}</p>
                                                                    </div>
                                                                    :
                                                                    <div className="imgtxtdivmainddd">
                                                                        <div className="strimgdiv">
                                                                            <img
                                                                                src={reward}
                                                                                alt="..."
                                                                            />
                                                                        </div>
                                                                        <p className="tsgyutppp">0</p>
                                                                    </div>
                                                            }

                                                            <div className="imgtxtdivmainddd">
                                                                <div className="strimgdiv">
                                                                    <img
                                                                        src={clock}
                                                                        alt="..."
                                                                    />
                                                                </div>
                                                                {/* {item?.videos?.[0]?.videoDuration ? (<p className="tsgyutppp">{item?.videos?.[0]?.videoDuration?.hour ? (item?.videos?.[0]?.videoDuration?.hour) : ("00")} h {item?.videos?.[0]?.videoDuration?.min ? (item?.videos?.[0]?.videoDuration?.min) : ("00")}min</p>) : (<p className="tsgyutppp">{item?.placeTime?.[0]?.duration?.hour ? (item?.placeTime?.[0]?.duration?.hour) : ("00")} h {item?.placeTime?.[0]?.duration?.min ? (item?.placeTime?.[0]?.duration?.min) : ("00")}min</p>)} */}
                                                                {/* {item?.totalDurationInMinute} min */}

                                                                {
                                                                    item?.placeTime?.length > 0
                                                                        ?
                                                                        (
                                                                            <>
                                                                                {time1} min
                                                                            </>
                                                                        )
                                                                        :
                                                                        (
                                                                            <>
                                                                                {time} min
                                                                            </>
                                                                        )
                                                                }

                                                            </div>
                                                            <div className="imgtxtdivmainddd">
                                                                <div className="strimgfrmt">
                                                                    <img
                                                                        src={reward}
                                                                        alt="..."
                                                                    />
                                                                </div>
                                                                <p className="tsgyutppp">{ele?.rewardPoint}</p>
                                                            </div>
                                                        </div>

                                                        {/* <div className="imgtxtdivmaindddflx">
                                                            <div className="imgtxtdivmainddd">
                                                                <div className="strimgdiv">
                                                                    <img
                                                                        src={clock}
                                                                        alt="..."
                                                                    />
                                                                </div>
                                                                <p className="tsgyutppp">{time}</p>
                                                            </div>
                                                            <div className="imgtxtdivmainddd">
                                                                <div className="strimgfrmt">
                                                                    <img
                                                                        src={reward}
                                                                        alt="..."
                                                                    />
                                                                </div>
                                                                <p className="tsgyutppp">{item?.trainingReward}</p>
                                                            </div>
                                                        </div> */}

                                                    </div>
                                                )
                                            })
                                            : <div>No Data Found...</div>
                                    }
                                </Slider>
                                {trainimgvideo && <Trainingvideomodal
                                    closemodal={setTrainimgvideo}
                                    singleMandatory={singleMandatory}
                                    rewardPoint={rewardPoint}
                                />}
                            </div>

                        </div>

                    )
                })
            }
        </>
    )
}

export default Mandatory
