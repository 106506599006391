import React, { useEffect, useState } from 'react'
import download from "../../Images/Icons/PNG/download.png";
import Points from "../../Images/Icons/PNG/Points.png";
import time from "../../Images/Icons/PNG/time.png";
import videospcl from "../../Images/Icons/PNG/videospcl.png";
import Certificate from "../../Images/Icons/PNG/Certificate.png";
import Mobile from "../../Images/Icons/PNG/Mobile.png";
import Article from "../../Images/Icons/PNG/Article.png";
import eventtesting from "../../Images/eventtesting.jpeg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Coursecontemt from './Coursecontemt';
import { useSelector } from 'react-redux';
import HttpClientXml from '../../utils/HttpClientXml';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import trainingmain from "../../Images/trainingmain.png";
import moment from 'moment';

const EnlargedNewEnrollment = () => {
    const userData = useSelector((state) => state?.StatusCardSlice?.data);
    const params = useParams();
    const location = useLocation();
    const singleMandatory = location?.state?.singleMandatory
    const [dropdownintrocontent, setDropdownintrocontent] = useState(false)
    const [colourOptions, setColourOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [notEnroll, setNotEnroll] = useState("");
    const [singleView, setSingleView] = useState([])
    const [showMaterial, setShowMaterial] = useState(false);
    const navigate = useNavigate();

    const takequizeclick = () => {
        navigate("/quizemain")
    }

    const toggleMaterialView = () => {
        setShowMaterial(!showMaterial);
    };

    //for view Single Page
    const viewSinglepost = async () => {
        const response = await HttpClientXml?.requestData(`view-single-trainings/${params?.id}`, {}, "GET")
        if (response?.status) {
            setSingleView(response?.data?.[0]);
        } else {

        }
    }


    const handleowndropdwncontentClick = () => {
        setDropdownintrocontent(!dropdownintrocontent);
    }

    //for viewing add more videos
    const cousrevideoonClick = (item) => {
        const data = {
            selectedOptions: selectedOptions,
            item: item,
            singleMandatory: singleMandatory
        }
        // navigate("/courseVideoPlayer", { state: data })
        navigate(`/courseVideoPlayer/${singleView?.title?.replace(/\s+/g, '-')}/${singleView?._id}/${item?._id}`, { state: data })
    }


    //for viewing first video
    const cousrevideofirstonClick = () => {
        const data = {
            selectedOptions: selectedOptions,
            singleMandatory: singleMandatory
        }
        // navigate("/coursefirstVideoPlayer", { state: data })
        navigate(`/coursefirstVideoPlayer/${singleView?.title?.replace(/\s+/g, '-')}/${singleView?._id}/${singleView?.videos?.[0]?._id}`, { state: data })
    }

    //for Enroll functionality
    const handleEnroll = async () => {
        let data = {
            trainingId: singleMandatory?._id
        }
        const res = await HttpClientXml?.requestData(`join-training`, data, "POST");
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, enroll it!",
        }).then((res) => {
            if (res.isConfirmed) {
                setNotEnroll(true)
                toast?.success("Enrolled Successfully");
            }
            else if (res.isDismissed) {
                setNotEnroll(false)
            }
        });
    }

    //for getting 
    const getEmployeeData = async () => {
        const response = await HttpClientXml?.requestData("view-all-employees", {}, "GET")

        if (response && response.status) {
            const formattedOptions = response?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
                image: item?.image
            }));

            setColourOptions(formattedOptions);
        } else {

        }
    };

    useEffect(() => {
        getEmployeeData();
        viewSinglepost();
    }, [])

    useEffect(() => {
        const data = singleView?.mentor
        const mentorData = data?.map((item) => {
            const optData = colourOptions?.find(ele => ele?.value == item)
            return optData ? optData : {}
        })

        setSelectedOptions(mentorData)
    }, [colourOptions])


    //for getting status is joined in training
    const handleIsJoined = async () => {
        let res = await HttpClientXml?.requestData(`is-joined/${singleMandatory?._id}`, {}, "GET")
        if (res && res?.status) {
            setNotEnroll(res?.data?.isJoined);
        }
    }

    //for save training functionality
    const handleSaveClick = async () => {
        let data = {
            id: singleView?._id,
        };
        let response = await HttpClientXml?.requestData(`save-item`, data, "POST");

        if (response?.status) {
            toast?.success(response?.data?.message)
        }
    }

    useEffect(() => {
        handleIsJoined();
    }, [])


    // var settings1 = {
    //     dots: false,
    //     infinite: false,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     initialSlide: 0,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //                 initialSlide: 2
    //             }
    //         },
    //         {
    //             breakpoint: 480,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1
    //             }
    //         }
    //     ]
    // }
    return (
        <>
            <section className='enlargedNewEnrollmentsection'>
                <div className='esgpagecontainer'>
                    <div className="webdevloperenrollflx">
                        <div className="developerbootcampdetails">
                            <p className='devloperbtcmptxt'>{singleView?.title}</p>
                            <div className='ratingclckrewardflx'>

                                {
                                    singleView?.avgRatings !== null
                                        ?
                                        <div className="imgtxtdivmainddd">
                                            <div className="strimgdiv">
                                                <img src={Points} alt="..." />
                                            </div>
                                            <p>{singleView?.avgRatings}</p>
                                        </div>
                                        :
                                        <div className="imgtxtdivmainddd">
                                            <div className="strimgdiv">
                                                <img src={Points} alt="..." />
                                            </div>
                                            <p>0</p>
                                        </div>
                                }

                                <div className="imgtxtdivmainddd">
                                    <div className="strimgdiv">
                                        <img src={time} alt="..." />
                                    </div>
                                    <p className="tsgyutppp">
                                        {/* {singleMandatory?.videos?.[0]?.videoDuration ?
            (<p className="tsgyutppp">{singleMandatory?.videos?.[0]?.videoDuration?.hour
                ? (singleMandatory?.videos?.[0]?.videoDuration?.hour) : ("00")} h {singleMandatory?.videos?.[0]?.videoDuration?.min ? (singleMandatory?.videos?.[0]?.videoDuration?.min) : ("00")}min</p>)
            : (<p className="tsgyutppp">{singleMandatory?.placeTime?.[0]?.duration?.hour ? (singleMandatory?.placeTime?.[0]?.duration?.hour) : ("00")} h {singleMandatory?.placeTime?.[0]?.duration?.min ? (singleMandatory?.placeTime?.[0]?.duration?.min) : ("00")}min</p>)} */}
                                        {
                                            singleView?.placeTime?.length > 0
                                                ?
                                                (
                                                    <>
                                                        {singleView?.physicalTotalDurationInMinute} min
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        {singleView?.totalDurationInMinute} min
                                                    </>
                                                )
                                        }
                                    </p>
                                </div>
                                <div className="imgtxtdivmainddd">
                                    <div className="strimgdiv">
                                        <img src={Points} alt="..." />
                                    </div>
                                    <p className="tsgyutppp">{singleView?.trainingReward}</p>
                                </div>
                            </div>
                            {/* <div className="ratingclckrewardflx">

                                <div className="imgtxtdivmainddd">
                                    <div className="strimgdiv">
                                        <img src={time} alt="..." />
                                    </div>
                                    <p className="tsgyutppp">0 min</p>
                                </div>
                                <div className="imgtxtdivmainddd">
                                    <div className="strimgdiv">
                                        <img src={Points} alt="..." />
                                    </div>
                                    <p className="tsgyutppp">250</p>
                                </div>
                            </div> */}
                            <div className='descriptionanswerdiv'>
                                <h4 className='destxtption'>Description</h4>
                                <p className='lastmonthtxtdestxt'>{singleView?.description}
                                </p>
                                {
                                    userData?.userType !== "Owner" &&
                                    <>
                                        {
                                            singleView?.placeTime?.length !== 0 || singleView?.quizData?.length === 0 ? (<></>) : (
                                                singleView?.quizStatus === "active" ?
                                                    (<button className="quizebtnvisble" onClick={() => navigate(`/feedbackquize/${singleView?._id}`)}>
                                                        Take Quiz
                                                    </button>)
                                                    :
                                                    (
                                                        <button className="quizebtnmmainiinbsibtn">
                                                            Take Quiz
                                                        </button>
                                                    )
                                            )
                                        }
                                    </>
                                }

                            </div>

                            {/* <div className=''>
                                <buttton className="quizebtnmmainiinbsibtn" onClick={() => takequizeclick()}>Take Quize</buttton>
                            </div> */}



                            {/* This is designing if quizedata is null */}
                            {/* <div className=''>
                                <buttton className="quizebtnmmainiinbsibtn">Take Quize</buttton>
                            </div> */}

                        </div>
                        {/* <div className="developerbootcampvdo">
                            <div className="developerbootcampvdodivbh">
                                <div className="trainingmaindivboot">
                                    <div className="traingmaindivmain">
                                        <img
                                            alt="..."
                                            src="/static/media/trainingmain.4f23014aab9edbadb81a.png"
                                        />
                                    </div>
                                </div>
                                <button className="enrolldivnowbtn">Park For Future</button>
                                <button className="enrolldivnowbtn">Enroll Now</button>
                            </div>
                        </div> */}
                        <div className='developerbootcampvdo'>
                            <div className='developerbootcampvdodivbh'>
                                <div className='trainingmaindivboot'>
                                    {/* <img src={trainingmain2} alt="..." /> */}
                                    {
                                        (singleView?.image === "images" || singleView?.image === "" || singleView?.image === "image") ? (<div className='traingmaindivmain'>
                                            <img src={trainingmain} alt="..." />
                                        </div>) : (<div className='traingmaindivmain'>
                                            <img src={singleView?.image} alt="..." />
                                        </div>)
                                    }
                                </div>
                                {/* <button className='enrolldivnowbtn'>Enroll Now</button> */}
                                {
                                    userData?.userType !== "Owner" &&
                                    <>
                                        {
                                            singleView?.placeTime?.length !== 0 ? (<></>) : (
                                                <div className='enrolllnowdiv' style={{ cursor: "pointer" }}>
                                                    {
                                                        notEnroll === false ? (<div onClick={handleEnroll}>Enroll Now</div>) : (<div onClick={cousrevideofirstonClick}>Play Now</div>)
                                                    }

                                                </div>
                                            )
                                        }
                                        <button className='enrolldivnowbtn' onClick={handleSaveClick}>Park For Future</button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>


                    {/* <div className='newenrollmentdivlrninclentnflx'>
                        <div className='newlerndivmainwidth'>
                            <div className="wiillrndiv">
                                <p className="lrnuniquetxt">
                                    What Will You Learn</p>
                                <div class="wiillrndivflcxdiv">
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusamus dolorem quidem harum id nulla dolore veniam at tempore, facilis doloremque impedit nam obcaecati ratione dicta rerum unde ipsam! Consectetur, unde?</p>
                                </div>
                            </div>

                            <div className="wiillrndiv">
                                <p className="lrnuniquetxt">Course Includes</p>
                                <div className="wiillrndivflcxdiv">
                                    <div className="wiillrndivflcx">
                                        <div className="coursrincldflx">
                                            <div className="cousrsedidicnimg">
                                                <img
                                                    src={videospcl}
                                                    alt="..."
                                                />
                                            </div>
                                            <p className="cousredtlstxtppas">0 minutes on-demand video</p>
                                        </div>
                                        <div className="coursrincldflx">
                                            <div className="cousrsedidicnimg">
                                                <img
                                                    src={Certificate}
                                                    alt="..."
                                                />
                                            </div>
                                            <p className="cousredtlstxtppas">Certificate of Completion</p>
                                        </div>
                                    </div>
                                    <div className="wiillrndivflcx">
                                        <div className="coursrincldflx">
                                            <div className="cousrsedidicnimg">
                                                <img
                                                    src={Mobile}
                                                    alt="..."
                                                />
                                            </div>
                                            <p className="cousredtlstxtppas">Accesss on mobile and TV</p>
                                        </div>
                                        <div className="coursrincldflx">
                                            <div className="cousrsedidicnimg">
                                                <img
                                                    src={Article}
                                                    alt="..."
                                                />
                                            </div>
                                            <p className="cousredtlstxtppas">64 Articles</p>
                                        </div>
                                    </div>
                                    <div className="wiillrndivflcx" />
                                </div>
                            </div>

                            

                            <div className=''>
                                <Coursecontemt />
                            </div>

                        </div>
                        <div className="newlerndivmainleft">
                            <div className="newlerndivmainleftbrdr">
                                <div className="crsecntnttxtp">
                                    <p className="crsecntnttxtppp">Course Information</p>
                                </div>
                                <div className="">
                                    <div className="Introductiontxtanglebgmain">
                                        <div className="Introductiontxtangle">
                                            <div className="">
                                                <span className="fa-solid fa-angle-down" />
                                            </div>
                                            <div className="">
                                                <p className="intrdivppptxts">Introduction</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opendropdownactd">
                                        <div className="">
                                            <p className="undrstndgppp">Language : English</p>
                                        </div>
                                        <div className="drpopfdivbhjkl">
                                            <div className="contetxtxttimeflx">
                                                <div className="">
                                                    <p className="undrstndgppp">2024-02-20</p>
                                                </div>
                                                <div className="">
                                                    <p className="undrstndgppp">12:00pm</p>
                                                </div>
                                                <div className="">
                                                    <p className="undrstndgppp">0 hr 00 min</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="drpopfdivbhjkl">
                                            <div className="contetxtxttimeflx">
                                                <div className="">
                                                    <p className="undrstndgppp">2024-02-20</p>
                                                </div>
                                                <div className="">
                                                    <p className="undrstndgppp">12:00pm</p>
                                                </div>
                                                <div className="">
                                                    <p className="undrstndgppp">0 hr 00 min</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>  */}
                    <div className='newenrollmentdivlrninclentnflx'>
                        <div className='newlerndivmainwidth'>
                            <div className='wiillrndiv'>
                                <p className='lrnuniquetxt'>What Will You Learn</p>
                                <div className='wiillrndivflcxdiv'>
                                    {
                                        singleView?.videos?.map((item, i) => {
                                            return (
                                                <>
                                                    {/* {
                                                        item?.videoLearningContent?.map((item, i) => {
                                                            return (
                                                                <>
                                                                 
                                                                </>
                                                            )
                                                        })
                                                    } */}
                                                    <div className='wiillrndivflcx' key={i}>
                                                        {
                                                            item?.videoLearningContent?.map((item, i) => {
                                                                return (

                                                                    <div className='lrntxtqunirppppdiv' key={i}>
                                                                        <p className='lrntxtqunirpppp'>{item?.content}</p>
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </>
                                            )
                                        })

                                    }


                                    {/* <div className='wiillrndivflcx'>
                                        <div className='lrntxtqunirppppdiv'>
                                            <p className='lrntxtqunirpppp'>Lorem ipsum dolor sit amet.</p>
                                        </div>
                                        <div className='lrntxtqunirppppdiv'>
                                            <p className='lrntxtqunirpppp'>Lorem ipsum dolor sit amet.</p>
                                        </div>
                                    </div>
                                    <div className='wiillrndivflcx'>
                                        <div className='lrntxtqunirppppdiv'>
                                            <p className='lrntxtqunirpppp'>Lorem ipsum dolor sit amet.</p>
                                        </div>
                                        <div className='lrntxtqunirppppdiv'>
                                            <p className='lrntxtqunirpppp'>Lorem ipsum dolor sit amet.</p>
                                        </div>
                                    </div> */}
                                </div>


                            </div>

                            <div className='wiillrndiv'>
                                <p className='lrnuniquetxt'>Course Includes</p>
                                <div className='wiillrndivflcxdiv'>

                                    <div className='wiillrndivflcx'>
                                        {
                                            singleView?.videos?.length !== 0 ? (
                                                <div className='coursrincldflx'>
                                                    <div className='cousrsedidicnimg'>
                                                        <img src={videospcl} alt="..." />
                                                    </div>
                                                    <p className='cousredtlstxtppas'>{singleView?.totalDurationInMinute} minutes on-demand video</p>
                                                </div>
                                            )
                                                :
                                                (
                                                    <div className='coursrincldflx'>
                                                        <div className='cousrsedidicnimg'>
                                                            <img src={videospcl} alt="..." />
                                                        </div>
                                                        <p className='cousredtlstxtppas'>On-demand Training</p>
                                                    </div>
                                                )
                                        }

                                        <div className='coursrincldflx'>
                                            <div className='cousrsedidicnimg'>
                                                <img src={Certificate} alt="..." />
                                            </div>
                                            <p className='cousredtlstxtppas'>Certificate of Completion</p>
                                        </div>
                                        {/* <div className='coursrincldflx'>
                                            <div className='cousrsedidicnimg'>
                                                <img src={download} alt="..." />
                                            </div>
                                            <p className='cousredtlstxtppas'>121 downloadable resources </p>
                                        </div> */}
                                    </div>
                                    <div className='wiillrndivflcx'>
                                        {/* <div className='coursrincldflx'>
                                            <div className='cousrsedidicnimg'>
                                                <img src={CodingIcon} alt="..." />
                                            </div>
                                            <p className='cousredtlstxtppas'>7 Coding Excercise</p>
                                        </div> */}
                                        <div className='coursrincldflx'>
                                            <div className='cousrsedidicnimg'>
                                                <img src={Mobile} alt="..." />
                                            </div>
                                            <p className='cousredtlstxtppas'>Accesss on mobile and TV</p>
                                        </div>
                                        <div className='coursrincldflx'>
                                            <div className='cousrsedidicnimg'>
                                                <img src={Article} alt="..." />
                                            </div>
                                            {
                                                singleView?.placeTime?.length !== 0 ? (
                                                    // <div className='enrolllnowdiv' style={{ cursor: "pointer" }}>
                                                    <p className='cousredtlstxtppas'>{singleView?.totalOfflineDocument} Articles</p>

                                                    // </div>
                                                ) : (
                                                    // <div className='enrolllnowdiv' style={{ cursor: "pointer" }}>
                                                    <p className='cousredtlstxtppas'>{singleView?.totalVideoDocument} Articles</p>

                                                    // </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                    <div className='wiillrndivflcx'>


                                    </div>

                                </div>
                            </div>

                            {
                                (singleView?.videos?.length !== 0 && notEnroll === true && userData?.userType !== "Owner") || (notEnroll === "" && userData?.userType !== "Owner")
                                    ?
                                    (<>
                                        <div className='wiillrndiv'>
                                            <p className='lrnuniquetxt'>Course Content</p>

                                            {
                                                singleView?.videos?.map((item, i) => {
                                                    console.log("firstitem", item)
                                                    return (
                                                        <>
                                                            <div className='coursecontentflxalll' key={i}>
                                                                <div className='cousreimgdmainimgnn'>
                                                                    <div
                                                                        className='cousreimgdmain'
                                                                        onClick={(e) => cousrevideoonClick(item, e)}
                                                                    >
                                                                        {/* <img src={trainingmain} alt="..." /> */}
                                                                        {/* <ReactPlayer
                                                                            className='reactdivimgmainplrt'
                                                                            controls
                                                                            // width='42rem'
                                                                            // height='24rem'
                                                                            // playing={false}
                                                                            url={item?.videoLink}
                                                                            onBuffer={cousrevideoonClick}
                                                                        // config={{
                                                                        //     youtube: {
                                                                        //         playerVars: { showinfo: 1 }
                                                                        //     },
                                                                        //     facebook: {
                                                                        //         appId: '12345'
                                                                        //     },
                                                                        // }}

                                                                        /> */}
                                                                        <video
                                                                            src={item?.videoLink}
                                                                            className='reactdivimgmainplrt'
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='lastmnthdesitxtflx'>
                                                                    <div className=''>
                                                                        <p className='crsecntntxt'>{i + 1}</p>
                                                                    </div>
                                                                    <div className=''>
                                                                        <p className='crsecntntxt'>{item?.videoTitle}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='cusrvideominuteflx'>
                                                                    <div className='timeimgdiv'>
                                                                        <img src={time} alt="..." />
                                                                    </div>
                                                                    <div className=''>
                                                                        {/* <p className='hrsmnttxtpp'>{item?.videoDuration?.hour ? (<>{`${item?.videoDuration?.hour} hr`}</>) : ("0")}  {item?.videoDuration?.min ? (<>{`${item?.videoDuration?.min} min`}</>) : ("00")}</p> */}
                                                                        <p className='hrsmnttxtpp'>{item?.videoDuration?.hour ? (<>{item?.videoDuration?.hour}</>) : ("0")}hr  {item?.videoDuration?.min ? (<>{item?.videoDuration?.min}</>) : ("00")}min</p>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </>


                                                    )
                                                })
                                            }

                                            {/* <ReactPlayer
                                            className='showvideo'
                                            // controls
                                            width='10rem'
                                            height='7.5rem'
                                            url={singleMandatory?.videos?.[0]?.videoLink}
                                            playing={false}
                                            onBuffer={() => {
                                                console.log("object")
                                                
                                            }}

                                        />*/}
                                        </div>
                                    </>)
                                    :

                                    singleView?.placeTime?.length !== 0 && userData?.userType !== "Owner"
                                        ?
                                        (<>

                                            <div className='wiillrndiv'>
                                                <p className='lrnuniquetxt'>Course Content</p>

                                                {
                                                    singleView?.placeTime?.map((item, i) => {
                                                        console.log("firstitem", item)
                                                        return (
                                                            <>
                                                                <div className='coursecontentflxalll' key={i}>
                                                                    {/* <div className='cousreimgdmainimgnn'>
                                                                    <div
                                                                        className='cousreimgdmain'
                                                                        onClick={(e) => cousrevideoonClick(item, e)}
                                                                    >
                                                                        
                                                                        <video
                                                                            src={item?.videoLink}
                                                                            className='reactdivimgmainplrt'
                                                                        />
                                                                    </div>
                                                                </div> */}

                                                                    <div className='downladimgmainpl'>
                                                                        <div>
                                                                            <img src={download} onClick={toggleMaterialView} alt="..." />
                                                                        </div>

                                                                        <div className='downloaddivmaindiv'>

                                                                            {
                                                                                showMaterial &&
                                                                                item?.Documents !== "" && (
                                                                                    <>
                                                                                        <div className='downloaddivmaindivsize'>
                                                                                            <iframe src={item?.Documents} title="Material Viewer" width="100%" height="600px"></iframe>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            }



                                                                        </div>
                                                                    </div>

                                                                    <div className='lastmnthdesitxtflx'>
                                                                        <div className=''>
                                                                            <p className='crsecntntxt'>1</p>
                                                                        </div>
                                                                        <div className=''>
                                                                            <p className='crsecntntxt'>Building : {item?.Budling}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='cusrvideominuteflx'>
                                                                        <div className='timeimgdiv'>
                                                                            <img src={time} alt="..." />
                                                                        </div>
                                                                        <div className=''>
                                                                            <p className='hrsmnttxtpp'>{item?.duration?.hour ? (<>{item?.duration?.hour}</>) : ("0")}hr  {item?.duration?.min ? (<>{item?.duration?.min}</>) : ("00")}min</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </>


                                                        )
                                                    })
                                                }

                                            </div>

                                        </>)
                                        :
                                        <></>
                            }


                            {/* <div className='coursecontentflxalll'>
                                    <div className='cousreimgdmainimgnn'>
                                        <div className='cousreimgdmain'>
                                            <img src={trainingmain} alt="..." />
                                        </div>
                                    </div>
                                    <div className='lastmnthdesitxtflx'>
                                        <div className=''>
                                            <p className='crsecntntxt'>2</p>
                                        </div>
                                        <div className=''>
                                            <p className='crsecntntxt'>Last month we designed Testers 10 web platform?</p>
                                        </div>
                                    </div>
                                    <div className='cusrvideominuteflx'>
                                        <div className='timeimgdiv'>
                                            <img src={time} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='hrsmnttxtpp'>26m</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='coursecontentflxalll'>
                                    <div className='cousreimgdmainimgnn'>
                                        <div className='cousreimgdmain'>
                                            <img src={trainingmain} alt="..." />
                                        </div>
                                    </div>
                                    <div className='lastmnthdesitxtflx'>
                                        <div className=''>
                                            <p className='crsecntntxt'>3</p>
                                        </div>
                                        <div className=''>
                                            <p className='crsecntntxt'>Last month we designed Testers 10 web platform?</p>
                                        </div>
                                    </div>
                                    <div className='cusrvideominuteflx'>
                                        <div className='timeimgdiv'>
                                            <img src={time} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='hrsmnttxtpp'>26m</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='coursecontentflxalll'>
                                    <div className='cousreimgdmainimgnn'>
                                        <div className='cousreimgdmain'>
                                            <img src={trainingmain} alt="..." />
                                        </div>
                                    </div>
                                    <div className='lastmnthdesitxtflx'>
                                        <div className=''>
                                            <p className='crsecntntxt'>4</p>
                                        </div>
                                        <div className=''>
                                            <p className='crsecntntxt'>Last month we designed Testers 10 web platform?</p>
                                        </div>
                                    </div>
                                    <div className='cusrvideominuteflx'>
                                        <div className='timeimgdiv'>
                                            <img src={time} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='hrsmnttxtpp'>26m</p>
                                        </div>
                                    </div>

                                </div> */}


                        </div>
                        <div className='newlerndivmainleft'>
                            <div className='newlerndivmainleftbrdr'>
                                <div className="crsecntnttxtp">
                                    <p className='crsecntnttxtppp'>Course Information</p>
                                </div>
                                <div className=''>
                                    <div className='Introductiontxtanglebgmain'>
                                        <div className='Introductiontxtangle'>
                                            <div className='' onClick={handleowndropdwncontentClick}>
                                                <span className={dropdownintrocontent ? "fa-solid fa-angle-right" : "fa-solid fa-angle-down"}></span>
                                            </div>
                                            <div className=''>
                                                <p className='intrdivppptxts'>Introduction</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={dropdownintrocontent ? "closedropdownactd" : "opendropdownactd"}>
                                        {
                                            singleView?.placeTime?.length !== 0
                                                ? (<>
                                                    <div className=''>
                                                        <p className='undrstndgppp'>Language : {singleView?.language}</p>
                                                    </div>
                                                    {
                                                        singleView?.placeTime?.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <div className='drpopfdivbhjkl' key={i}>
                                                                        <div className='contetxtxttimeflx'>
                                                                            <div className=''>
                                                                                <p className='undrstndgppp'>{moment(item.StartDate).format("YYYY-MM-DD")}</p>
                                                                            </div>
                                                                            <div className=''>
                                                                                <p className='undrstndgppp'>{item?.startTime}</p>
                                                                            </div>
                                                                            <div className=''>
                                                                                <p className='undrstndgppp'>{item?.videoDuration?.hour ? (<p>{item?.videoDuration?.hour}</p>) : ("0")} hr {item?.videoDuration?.min ? (<p>{item?.videoDuration?.min}</p>) : ("00")} min</p>
                                                                            </div>
                                                                            {/* <div className=''>
                                                                                <p className='undrstndgppp'>{item?.language}</p>
                                                                            </div> */}
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </>)
                                                : (<>
                                                    <div className=''>
                                                        <p className='undrstndgppp'>Language : {singleView?.language}</p>
                                                    </div>
                                                    {
                                                        singleView?.mentor !== 0 ?
                                                            (<div className=''>
                                                                <p className='undrstndgppp'>Mentor : {`${selectedOptions?.map((item) => item?.label)} `}</p>
                                                            </div>)
                                                            : singleView?.trainerName ? (<div className=''>
                                                                <p className='undrstndgppp'>Training Name : {singleView?.trainerName}</p>
                                                            </div>)
                                                                : (<></>)

                                                    }


                                                </>)
                                        }


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EnlargedNewEnrollment
