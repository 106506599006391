import React, { useEffect, useMemo, useState } from 'react'
import ProfileImg1 from "../../Images/girl.png";
import like from "../../Images/Icons/PNG/Reaction - Like.png";
import comments from "../../Images/Icons/PNG/Comment.png";
import share from "../../Images/Icons/PNG/Share.png";
import reporticon from "../../Images/Icons/PNG/Share.png";
import r from "../../Images/Icons/PNG/Reward - Silver.png";
import moment from 'moment';
import repost from "../../Images/Icons/PNG/repost.png"
import { useDispatch, useSelector } from 'react-redux';
import { getAllPosts, getComment } from '../../Redux/Slice/PostSlice';
import ReactionLike from "../../Images/Icons/PNG/Reaction - Like.png";
import ReactionLove from "../../Images/Icons/PNG/Reaction - Love.png";
import ReactionCelebrate from "../../Images/Icons/PNG/Reaction - Celebrate.png";
import ReactionSupport from "../../Images/Icons/PNG/Reaction - Support.png";
import ReationCurious from "../../Images/Icons/PNG/Reation - Curious.png";
import ReationInsightful from "../../Images/Icons/PNG/Reation - Insightful.png";
import PostEditmodal from '../../Modal/PostEditmodal';
import HttpClientXml from '../../utils/HttpClientXml';
import ReactionLikee from "../../Images/Icons/PNG/ReactionLike.png"
// import profile from "../../Images/cute-girl-dp.jpg"
import Attach from "../../Images/Icons/PNG/Attach.png";
import Post from "../../Images/Icons/PNG/Post.png"
import toast from 'react-hot-toast';
import { BsEmojiSmileFill } from 'react-icons/bs';
import EmojiPicker from 'emoji-picker-react';
import EditCommentmodal from "../../Modal/EditCommentmodal"
import { useNavigate } from 'react-router-dom';
import { RWebShare } from 'react-web-share';
import RepostModal from '../../Modal/RepostModal';
import EditRepostModal from '../../Modal/EditRepostModal';
import Edit from "../../Images/Icons/PNG/Edit.png";
import cancel from "../../Images/Icons/PNG/cancel.png"


const ShareRepost = ({ post, setCommentId, commentId }) => {
    console.log("post", post)
    const userData = useSelector((state) => state?.StatusCardSlice?.data);
    const commentData = useSelector((state) => state?.PostSlice?.commentData);
    const [editedeletefeed, setEditedeletefeed] = useState(false);
    const [editemodal, setEditmodal] = useState(false);

    const dispatch = useDispatch();
    const [showReaction, setShowReaction] = useState("");
    const [emojimodal, setEmojimodal] = useState(false);
    const [reactionCount, setReactionCount] = useState(post?.totalReaction);
    const [userReactions, setUserReactions] = useState(new Set());

    const [isEmoji, setIsEmoji] = useState(false);
    const [image, setImage] = useState("")
    const [uploading, setUploading] = useState(false);
    const [commentView, setCommentView] = useState(false);
    const [commentText, setCommentText] = useState("");
    const [imageURL, setImageURL] = useState("");

    const [indexShow, setIndex] = useState("");
    const [indexShowReply, setIndexReply] = useState("");
    const [replyText, setReplyText] = useState("");
    const [replyToggle, setReplyToggle] = useState([]);

    const [replyLoading, setReplyLoading] = useState(false);
    const [isReplyEmoji, setIsReplyEmoji] = useState(false);
    const [imageReplyURL, setImageReplyURL] = useState("");

    const [editdeletereport, setEditdeletereport] = useState(false);
    const [editnewthreemodal, setEditnewthreemodal] = useState(false);
    const [editComment, setEditComment] = useState({});
    const [editdeletereportReply, setEditdeletereportReply] = useState(false);
    const [editnewthreemodalReply, setEditnewthreemodalReply] = useState(false);
    const [editCommentReply, setEditCommentReply] = useState({});
    const [repostData, setRepostData] = useState({})
    const [repostModal, setRepostModal] = useState(false);
    const navigate = useNavigate();

    let data = {
        postType: "public"
    }

    let data1 = {
        postID: commentId
    };


    const handleeditdltefeed = () => {
        setEditedeletefeed(!editedeletefeed)
    }


    //for reaction functionality
    const handlereaction = async (postId, reactionType) => {
        setShowReaction(reactionType);
        setEmojimodal(false);

        if (userReactions.has(userData?._id)) {
            return;
        }

        
        const data = {
            postID: postId,
            reactionData: reactionType
        }

        const response = await HttpClientXml?.requestData("add-reaction", data, "POST");

        if (response?.status) {
            setReactionCount(prevCount => prevCount + 1);
            setUserReactions(prevReactions => new Set([...prevReactions, userData?._id]));
        } else {
        }

    };


    //for doing comment
    const handleComment = async (e) => {
        e.preventDefault();
        if (commentText === "" && imageURL === "") {
            return;
        }
        let data = {
            userId: userData?._id,
            Userimage: userData?.image,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            postID: post?._id,
            comment: commentText,
            image: imageURL
        };

        if (commentText || imageURL) {
            let res = await HttpClientXml?.requestData("add-comment", data, "POST");
            if (res?.status) {
                dispatch(getComment(data1));
                setCommentText("");
                setImageURL("");
            } else {

            }
        } else {
            toast.error("No Comment is Added");
        }
    };


    //for delete comment
    const handleDeleteComment = async (id) => {
        const res = await HttpClientXml?.requestData(`delete-comment/${id}`, data, "DELETE")

        if (res?.status) {
            dispatch(getComment(data1));
            toast?.success("Comment deleted successfully");

        } else {

        }
    };


    //for image onChange functionality
    const handleImageChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await HttpClientXml.fileUplode("image-upload", "POST", form);

        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageURL(res?.image);
        } else {
            toast.error("Unsupported Image");
        }
        setUploading(false);
    };

    //reply-image
    const handleReplyImageChange = async (e) => {
        let image = e.target.files[0];
        setReplyLoading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await HttpClientXml.fileUplode("image-upload", "POST", form);

        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageReplyURL(res?.image);
        } else {
            toast?.error("Unsupporting image");
        }
        setReplyLoading(false);
    };

    //for reply toggle
    // const handleReplyToggle = (index) => {
    //     const newToggles = [...(replyToggle || [])];
    //     newToggles[index] = !newToggles?.[index];
    //     setReplyToggle(newToggles);
    // };

    //for reply toggle
    const handleReplyToggle = (index) => {
        const newToggles = new Array(commentData?.length)?.fill(false);
        newToggles[index] = true;
        setReplyToggle(newToggles);
    };


    //for Reply of comment
    const handleReplyClick = async (id) => {
        if (replyText === "" && imageReplyURL === "") {
            return;
        }

        let data = {
            commentID: id,
            reply: replyText,
            image: imageReplyURL
        };

        if (replyText || imageReplyURL) {
            let res = await HttpClientXml?.requestData("add-reply", data, "POST");
            if (res?.status) {
                toast.success("Reply is added successfully");
                dispatch(getComment(data1));
                dispatch(getAllPosts());
                setReplyText("");
                setReplyToggle(false);
                setImageReplyURL("");
                setIsReplyEmoji(false);
            } else {

            }
        } else {
            toast.error("No Reply is Added");
        }
    };

    //for emoji clicking
    const handleemojiClick = () => {
        setEmojimodal(true);
    }

    //reaction for only post
    const reactionIconMemo = useMemo(() => {
        if (showReaction) {
            return showReaction
        } else {
            return post?.reactionData?.find(item => item?.userId === userData?._id)?.reactionData;
        }
    }, [post, userData, showReaction])



    useEffect(() => {
        const closeEmojiModal = (event) => {
            if (!event.target.closest('.reactionicondivflx actvgchng') && !event.target.closest('.reactionicon')) {
                setEmojimodal(false);
            }
        };
        document.addEventListener('click', closeEmojiModal);
        return () => {
            document.removeEventListener('click', closeEmojiModal);
        };

    }, []);

    useEffect(() => {
        const closeEmojiModalll = (event) => {
            if (!event.target.closest('.comment-emoji-icon')) {
                setIsReplyEmoji(false);
            }
        };
        document.addEventListener('click', closeEmojiModalll);
        return () => {
            document.removeEventListener('click', closeEmojiModalll);
        };
    }, [])


    useEffect(() => {
        const closeEmojiModall = (event) => {
            if (!event.target.closest('.comment-emoji-icon')) {
                setIsEmoji(false);
            }
        };
        document.addEventListener('click', closeEmojiModall);
        return () => {
            document.removeEventListener('click', closeEmojiModall);
        };
    }, [])

    const editemoadl = () => {
        setRepostData(post);
        setEditmodal(!editemodal)
    }


    const handledeleteeditreportClick = (ele, ind) => {
        setIndex((prev) => prev === ind ? "" : ind);
        setEditdeletereport(!editdeletereport);
        // console.log(ele?._id, index, "bmw", editdeletereport);
    }

    const handleeditClick = (ele) => {
        setEditnewthreemodal(!editnewthreemodal)
        setEditComment(ele);
    }


    //for reply open modal for edit delete report
    const handledeleteeditreportReplyClick = (ele, ind, i) => {
        setIndexReply((prev) => prev === `${ind}${i}` ? "" : `${ind}${i}`);
        setEditdeletereportReply(!editdeletereportReply);
    }

    //for reply handle edit modal
    const handleeditClickReply = (ele) => {
        setEditnewthreemodalReply(!editnewthreemodalReply)
        setEditCommentReply(ele);
    }

    //for delete reply
    const handleDeletereply = async (id) => {
        const res = await HttpClientXml?.requestData(`deleted-reply/${id}`, data, "PUT")
        if (res?.status) {
            toast.success("Reply is deleted successfully");
            dispatch(getComment(data1));
            dispatch(getAllPosts());
        } else {

        }
    }

    //for navigate single post
    const handleSinglePageClick = () => {
        {
            post?.repostType === "eventData"
                ?
                (
                    navigate(`/singlepage/${post?.postData1?.[0]?.docType}/${post?.postData1?.[0]?._id}`)
                )
                :
                (
                    navigate(`/singlepage/${post?.postData2?.[0]?.docType}/${post?.postData2?.[0]?._id}`)
                )
        }

    }


    //for delete repost
    const handleRepostDelete = async () => {
        const res = await HttpClientXml?.requestData(`delete-repost/${post?._id}`, data, "POST");
        if (res?.status) {
            toast.success("Repost is deleted successfully");
            dispatch(getAllPosts());
        } else {

        }
    }


    return (
        <>
            <section className='postthoughts_area'>
                {/* sarataylor */}
                <div className='sara_tylor'>
                    <div>
                        <div className='top'>
                            <div className='left'>
                                <div className='prflimgshrthgh'>
                                    <img src={post?.repostedBy?.[0]?.image} alt="..." />
                                </div>
                                <div className='sharedetails'>
                                    <div className='name'>
                                        <h2 className='nametxthh'>{post?.repostedBy?.[0]?.firstName + " " + post?.repostedBy?.[0]?.lastName}</h2>
                                        <div className='brnztztpp'>
                                            <div className='imgbrnz'>
                                                {/* <img src={r} alt="..." /> */}
                                                {
                                                    post?.repostedBy?.[0]?.rewardCard?.[0]?.cardImage
                                                        ?
                                                        <img src={post?.repostedBy?.[0]?.rewardCard?.[0]?.cardImage} alt='...' />
                                                        :
                                                        <></>
                                                }
                                            </div>
                                            {/* <div className=''>
                                                <p className='brnzttxy'>{post?.userData?.[0]?.rewardCard?.[0]?.cardImage} badge holder</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='text'>
                                        <p className='emplyrtxtpp'>{post?.repostedBy?.[0]?.userType} ,</p>
                                        <p className='emplyrtxtpp'>Posted on {moment(post?.createdOn)?.format("YYYY-MM-DD")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='dotsticondrdtdlytrlrt'>
                                {
                                    post?.repostedBy?.[0]?._id === userData?._id
                                    &&
                                    <div className='dotsticondrdtdlyt' onClick={handleeditdltefeed}>
                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                    </div>
                                }


                                {editedeletefeed && <div className='editdeletedibvflx'>
                                    <div className='plismnisn' onClick={editemoadl}>
                                    <img src={Edit} alt="..." />
                                    </div>
                                    <div className='plismnisn' onClick={handleRepostDelete}>
                                    <img src={cancel} alt="..." />
                                    </div>
                                </div>}

                            </div>
                        </div>

                        <div className='top_next' style={{ cursor: "pointer" }} onClick={handleSinglePageClick}>
                            <p className='lrmtxtpptxtpp'> {post?.text}</p>
                        </div>

                    </div>

                    {
                        post?.repostType === "eventData"
                            ?
                            (
                                <div>
                                    <div className='top'>
                                        <div className='left'>
                                            <div className='prflimgshrthgh'>
                                                <img src={post?.postData1?.[0]?.userData?.[0]?.image} alt="..." />
                                            </div>
                                            <div className='sharedetails'>
                                                <div className='name'>
                                                    <h2 className='nametxthh'>{post?.postData1?.[0]?.userData?.[0]?.firstName + " " + post?.postData1?.[0]?.userData?.[0]?.lastName}</h2>
                                                    <div className='brnztztpp'>
                                                        <div className='imgbrnz'>
                                                            {/* <img src={r} alt="..." /> */}
                                                            {
                                                                post?.postData1?.[0]?.userData?.[0]?.rewardCard?.[0]?.cardImage
                                                                    ?
                                                                    <img src={post?.postData1?.[0]?.userData?.[0]?.rewardCard?.[0]?.cardImage} alt='...' />
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='text'>
                                                    <p className='emplyrtxtpp'>{post?.postData1?.[0]?.type} ,</p>
                                                    <p className='emplyrtxtpp'>Posted on {moment(post?.createdOn)?.format("YYYY-MM-DD")}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='top_next' style={{ cursor: "pointer" }} onClick={handleSinglePageClick}>
                                        <p className='lrmtxtpptxtpp'> {post?.postData1?.[0]?.description}</p>
                                    </div>

                                    {
                                        post?.postData1?.[0]?.image
                                            ?
                                            <div className='sharethgtsimagediv'>
                                                <div className='sharethgtsbg'>
                                                    <div className='sharethghtsimg'>
                                                        <img src={post?.postData1?.[0]?.image} alt="..." />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }


                                </div>
                            )
                            :
                            (
                                <div>
                                    <div className='top'>
                                        <div className='left'>
                                            <div className='prflimgshrthgh'>
                                                <img src={post?.postData2?.[0]?.userData?.[0]?.image} alt="..." />
                                            </div>
                                            <div className='sharedetails'>
                                                <div className='name'>
                                                    <h2 className='nametxthh'>{post?.postData2?.[0]?.userData?.[0]?.firstName + " " + post?.postData2?.[0]?.userData?.[0]?.lastName}</h2>
                                                    <div className='brnztztpp'>
                                                        <div className='imgbrnz'>
                                                            {/* <img src={r} alt="..." /> */}
                                                            {
                                                                post?.postData2?.[0]?.userData?.[0]?.rewardCard?.[0]?.cardImage
                                                                    ?
                                                                    <img src={post?.postData2?.[0]?.userData?.[0]?.rewardCard?.[0]?.cardImage} alt='...' />
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>
                                                        {/* <div className=''>
                            <p className='brnzttxy'>{post?.userData?.[0]?.rewardCard?.[0]?.cardImage} badge holder</p>
                        </div> */}
                                                    </div>
                                                </div>
                                                <div className='text'>
                                                    <p className='emplyrtxtpp'>{post?.postData2?.[0]?.userData?.[0]?.userType} ,</p>
                                                    <p className='emplyrtxtpp'>Posted on {moment(post?.createdOn)?.format("YYYY-MM-DD")}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='top_next' style={{ cursor: "pointer" }} onClick={handleSinglePageClick}>
                                        <p className='lrmtxtpptxtpp'> {post?.postData2?.[0]?.description}</p>
                                    </div>

                                    {
                                        post?.postData2?.[0]?.image?.[0]
                                            ?
                                            <div className='sharethgtsimagediv'>
                                                <div className='sharethgtsbg'>
                                                    <div className='sharethghtsimg'>
                                                        <img src={post?.postData2?.[0]?.image} alt="..." />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }


                                </div>

                            )
                    }



                    <div className='like_comment_areasec'>
                        <div className='leftshare'>
                            <p className='plpotxt'> {reactionCount} Reaction</p>
                        </div>
                        <div className='rightshare'>
                            <h6 className='cmntdtxt'>{post?.totalComment} People Commented</h6>
                            <p className='shrtxt'>1 Share</p>
                        </div>
                    </div>
                    <div className='reaction_areasec'>

                        <div className='reactionmainsc'>
                            <div
                                className="reactionmainscdivflx"
                                onMouseEnter={handleemojiClick}
                                onClick={handlereaction}
                            >

                                {reactionIconMemo
                                    ?
                                    reactionIconMemo === "like" ? (<img src={ReactionLikee} alt="..." className="rcmgdivmai" />) :
                                        reactionIconMemo === "love" ? (<img src={ReactionLove} alt="..." className="rcmgdivmai" />) :
                                            reactionIconMemo === "support" ? (<img src={ReactionSupport} alt="..." className="rcmgdivmai" />) :
                                                reactionIconMemo === "celebrate" ? (<img src={ReactionCelebrate} alt="..." className="rcmgdivmai" />) :
                                                    reactionIconMemo === "curious" ? (<img src={ReationCurious} alt="..." className="rcmgdivmai" />) :
                                                        reactionIconMemo === "insightful" ? (<img src={ReationInsightful} alt="..." className="rcmgdivmai" />)
                                                            :
                                                            (<img src={like} alt="..." className="rcmgdivmai" />)
                                    :
                                    (<img src={like} alt="..." className="rcmgdivmai" />)

                                }


                                <p className='txtrecppp'>Reaction</p>
                            </div>

                            {/* emoji modal */}
                            {emojimodal &&
                                <div className='reactionicondivflx actvgchng'>
                                    <div className="reactionicon">
                                        <img src={ReactionLikee} alt="..." onClick={() => handlereaction(post?._id, "like")} />
                                    </div>
                                    <div className="reactionicon">
                                        <img src={ReactionLove} alt="..." onClick={() => handlereaction(post?._id, "love")} />
                                    </div>
                                    <div className="reactionicon">
                                        <img src={ReactionSupport} alt="..." onClick={() => handlereaction(post?._id, "support")} />
                                    </div>
                                    <div className="reactionicon">
                                        <img src={ReactionCelebrate} alt="..." onClick={() => handlereaction(post?._id, "celebrate")} />
                                    </div>
                                    <div className="reactionicon">
                                        <img src={ReationCurious} alt="..." onClick={() => handlereaction(post?._id, "curious")} />
                                    </div>
                                    <div className="reactionicon">
                                        <img src={ReationInsightful} alt="..." onClick={() => handlereaction(post?._id, "insightful")} />
                                    </div>

                                </div>
                            }
                        </div>

                        <div className='reactionmainsc'>
                            <div className='rcmgdivmai'
                                onClick={() => {
                                    // setModalId(post?._id);
                                    setCommentView(!commentView);
                                    setCommentId(post?._id)
                                }}
                            >
                                <img src={comments} alt="..." />
                            </div>
                            <p className='txtrecppp'>Comments</p>

                        </div>

                        {/* <div className='reactionmainsc'>
                            <div className='rcmgdivmai'>
                                <img src={repost} alt="..." />
                            </div>
                            <p className='txtrecppp'>Repost</p>
                        </div> */}

                        {
                            post?.docType === "advocacyPost"
                                ?
                                (<RWebShare
                                    disableNative={true}
                                    data={{
                                        text: `ESG - Powered by Fractals Global`,
                                        url: "",
                                        title: "ESG",
                                    }}
                                    sites={[
                                        "linkedin",
                                        "facebook",
                                        "twitter",
                                        "whatsapp",
                                        "mail",
                                        "copy",
                                    ]}
                                    onClick={(platform) => {

                                        if (platform === "copy") {
                                            const textToCopy = ""
                                            navigator.clipboard.writeText(textToCopy).then(() => {
                                                toast.success("Link copied to clipboard!");
                                            });
                                        } else {

                                        }
                                    }}

                                >
                                    <button className="share">

                                        <img src={share} alt="..." className="shareIconImg" />
                                        <span style={{ paddingLeft: "5px" }}>Share</span>
                                    </button>

                                </RWebShare>)
                                :
                                (
                                    //   <button className="share" onClick={handleRepostModal}>
                                    //     <img src={repost} alt="..." className="shareIconImg"
                                    //     />
                                    //     <span style={{ paddingLeft: "5px" }}>Repost</span>
                                    //   </button>

                                    <div className='reactionmainsc' onClick={handleSinglePageClick}>
                                        <div className='rcmgdivmai'>
                                            <img src={repost} alt="..." />
                                        </div>
                                        <p className='txtrecppp'>Repost</p>
                                    </div>
                                )

                        }

                    </div>

                    <div className='commentsectionare'>

                        {commentView && (
                            <>

                                <div className='cmntpprfcdivflx'>
                                    <div className='profilemcmnrtimg'>
                                        {/* <img src={profile} alt='...' /> */}

                                        {userData?.image ? (
                                            <img
                                                src={userData?.image}
                                                alt="profile"
                                            />
                                        ) : (
                                            <img
                                                src={ProfileImg1}
                                                alt="profile"
                                            />
                                        )}

                                    </div>
                                    <div className='cmntscedtdiv'>
                                        <form>
                                            <div className='cmnttsttxt'>
                                                <textarea
                                                    className=''
                                                    value={commentText}
                                                    onChange={(e) => setCommentText(e.target.value)}
                                                ></textarea>
                                                <div className='cmntstocnobdivflcpdadad'>
                                                    <div className='rcmgdivmai'>
                                                        <img src={Attach} alt="..." />
                                                        <input
                                                            type="file"
                                                            class="form-control"
                                                            id="hostedBy"
                                                            placeholder="Choose a photo"
                                                            // value={image}
                                                            onChange={handleImageChange}
                                                        />
                                                    </div>

                                                    {uploading ? <p style={{
                                                        position: 'absolute',
                                                        right: 0,
                                                        top: '-84%'
                                                    }}>image uploading......</p> : null}


                                                    <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsEmoji(prev => !prev)} />
                                                    {isEmoji &&
                                                        <EmojiPicker onEmojiClick={(emojiObject) => setCommentText(prev => prev + emojiObject.emoji)} />
                                                    }

                                                    <div className='rcmgdivmai' onClick={handleComment}>
                                                        <img src={Post} alt="..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>


                                <div className="cmntpprfcdivflxrplytxtdiv">
                                    {commentData?.length > 0 ? (
                                        commentData?.slice(0, 2)?.map((item, index) => {
                                            return (
                                                <>
                                                    <div className="cmntpprfcdivflxrply" key={index}>
                                                        <div className="profilemcmnrtimg">
                                                            {item?.Userimage ? (
                                                                <img
                                                                    src={item?.Userimage}
                                                                    alt="profile"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={ProfileImg1}
                                                                    alt="profile"
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="cmntscedtdiv">
                                                            <div className="cmnttxnmdivthreeobrdrs">
                                                                <div className="wrap_box">
                                                                    <div className="cmnttxnmdivthreeotdidtfl">
                                                                        <div className="cmntprfgname">
                                                                            <h4>{item?.firstName + " " + item?.lastName}</h4>
                                                                        </div>

                                                                        {
                                                                            (userData?._id === item?.userID) &&
                                                                            (<div className='threedotscmntdownmmd'>
                                                                                <div className="threedotscmntd" onClick={() => handledeleteeditreportClick(item, index)}>
                                                                                    <i className="fa-solid fa-ellipsis"></i>
                                                                                </div>

                                                                                {
                                                                                    indexShow === index &&
                                                                                    // editdeletereport &&

                                                                                    <div className='threedotscmntddiv' onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                    }}>
                                                                                        <div className={item?.userID === userData?._id ? "edoiytdltreptyicon" : "editdivimgthree"} onClick={() => handleeditClick(item)}>
                                                                                            <i className="fa-solid fa-pen-to-square" style={{ cursor: "pointer" }}></i>
                                                                                        </div>
                                                                                        <div className={item?.userID === userData?._id ? "edoiytdltreptyicon" : "editdivimgthreedelete"} onClick={() => handleDeleteComment(item?._id)}>
                                                                                            <i className="fa-solid fa-trash" style={{ cursor: "pointer" }}></i>
                                                                                        </div>
                                                                                        <div className="edoiytdltreptyicon">
                                                                                            <i className="fa-solid fa-flag"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                }


                                                                            </div>
                                                                            )
                                                                        }
                                                                    </div>


                                                                    {item?.image ? (
                                                                        <div className='imagesinglepost'>
                                                                            <img
                                                                                src={item?.image}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    ) : (

                                                                        <></>
                                                                    )}

                                                                    <div className="text">
                                                                        <p>{item?.comment}</p>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="rplydkltflxmainrply">
                                                                <div className="rplydltbtndiv">
                                                                    <div className='treplyrxrtdivflx' onClick={() => handleReplyToggle(index)} style={{ cursor: "pointer" }}>
                                                                        <p className='trepkcttcbbh'>Reply</p>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                            {
                                                                replyToggle?.[index] && (
                                                                    <>
                                                                        <div className="cmntpprfcdivflxchnng">
                                                                            <div className="profilemcmnrtimg">
                                                                                {userData?.image ? (
                                                                                    <img
                                                                                        src={userData?.image}
                                                                                        alt="profile"
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        src={ProfileImg1}
                                                                                        alt="profile"
                                                                                    />
                                                                                )}
                                                                            </div>



                                                                            <div className="cmntscedtdiv">
                                                                                <form>
                                                                                    <div className="cmnttsttxt">
                                                                                        <textarea
                                                                                            type="text"
                                                                                            placeholder="Reply"
                                                                                            name="replyText"
                                                                                            value={replyText}
                                                                                            onChange={(e) => setReplyText(e.target.value)}
                                                                                        ></textarea>
                                                                                        <div className='cmntstocnobdivflcpdadad'>
                                                                                            <div className="rcmgdivmai">
                                                                                                <img src={Attach} alt="..." />
                                                                                                <input
                                                                                                    type="file"
                                                                                                    id="images"
                                                                                                    placeholder="Choose a photo"
                                                                                                    name="hostedBy"

                                                                                                    onChange={handleReplyImageChange}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="rcmgdivmai">
                                                                                                <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsReplyEmoji(prev => !prev)} />
                                                                                                {isReplyEmoji &&
                                                                                                    <EmojiPicker onEmojiClick={(emojiObject) => setReplyText(prev => prev + emojiObject.emoji)} />
                                                                                                }
                                                                                            </div>
                                                                                            <div className="rcmgdivmai">
                                                                                                <img src={Post} alt="..." onClick={() => handleReplyClick(item?._id)} />
                                                                                            </div>
                                                                                        </div>



                                                                                        {replyLoading ? <p style={{
                                                                                            position: 'absolute',
                                                                                            right: 0,
                                                                                            top: '-84%'
                                                                                        }}>Reply uploading......</p> : null}

                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }

                                                        </div>
                                                    </div>


                                                    {
                                                        item?.replyData?.slice(0, 2)?.map((item, i) => {
                                                            // console.log("userId",item?.userID,userData?._id)
                                                            return (
                                                                <>
                                                                    <div className='cmntpprfcdivflxrplyanothrr'>
                                                                        <div className="profilemcmnrtimg" key={i}>
                                                                            {item?.UserImage ? (
                                                                                <img
                                                                                    src={item?.UserImage}
                                                                                    alt="profile"
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src={ProfileImg1}
                                                                                    alt="profile"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <div className="cmntscedtdiv">
                                                                            <div className="cmnttxnmdivthreeobrdrs">
                                                                                <div className="wrap_box">
                                                                                    <div className="cmnttxnmdivthreeotdidtfl">
                                                                                        <div className="cmntprfgname">
                                                                                            <h4>{item?.firstName + " " + item?.lastName}</h4>
                                                                                        </div>
                                                                                        {
                                                                                            userData?._id === item?.userID &&
                                                                                            <div
                                                                                                className="threedotscmntddiv"
                                                                                                onClick={() => handledeleteeditreportReplyClick(item, index, i)}
                                                                                            >
                                                                                                <i className="fa-solid fa-ellipsis"></i>

                                                                                                {
                                                                                                    indexShowReply === `${index}${i}` &&
                                                                                                    <div
                                                                                                        className="editdeleteupdatereportmodal"
                                                                                                        onClick={(e) => {
                                                                                                            e.stopPropagation();
                                                                                                        }}
                                                                                                    >

                                                                                                        <div className="edoiytdltreptyicon" onClick={() => handleeditClickReply(item)}>
                                                                                                            <i className="fa-solid fa-pen-to-square" style={{ cursor: "pointer" }}></i>
                                                                                                        </div>
                                                                                                        <div className="edoiytdltreptyicon" onClick={() => handleDeletereply(item?._id)}>
                                                                                                            <i className="fa-solid fa-trash" style={{ cursor: "pointer" }}></i>
                                                                                                        </div>
                                                                                                        <div className="edoiytdltreptyicon">
                                                                                                            <img src={reporticon} alt="..." />
                                                                                                        </div>
                                                                                                    </div>}

                                                                                            </div>
                                                                                        }
                                                                                    </div>

                                                                                    {item?.image ? (
                                                                                        <img
                                                                                            src={item?.image}
                                                                                            className="img-fluid"
                                                                                            alt=""
                                                                                        />
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}

                                                                                    <div className="text">
                                                                                        <p>{item?.reply}</p>
                                                                                    </div>

                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </>
                                            );
                                        })
                                    ) : (
                                        <p>No comments to show!</p>
                                    )}


                                    {imageURL !== "" && (
                                        <div className='imagjdbreokddiv'>


                                            <div className='imagjdbreokd'>
                                                <img

                                                    src={imageURL}
                                                    alt=""

                                                />
                                                <div className='crsyhhimbcfg'
                                                    onClick={() => {
                                                        setImageURL("");
                                                        setImage('')
                                                    }}
                                                >
                                                    <i
                                                        class="fa-solid fa-xmark"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>

                                    )}

                                </div>
                            </>
                        )}

                    </div>
                </div>

            </section>


            {editemodal &&
                <EditRepostModal
                    closemodal={setEditmodal}
                    repostData={repostData}
                    imagesArray={post}
                />}

            {editnewthreemodal && <EditCommentmodal
                closemodal={setEditnewthreemodal}
                postId={post?._id}
                initialValues={editComment}
            />}
            {
                repostModal && (<RepostModal
                    closemodal={setRepostModal}
                    postId={post?._id}
                    imagesArray={post}
                />)
            }
        </>
    )
}

export default ShareRepost
