import React, { useEffect, useState } from 'react'
import HomeService from '../../Services/HomeService';
import moment from 'moment';
const Halloffamedetails = () => {
    const [allHallFame, setAllHallFame] = useState([])
    console.log("68fg84fdz6", allHallFame)

    // >>>>GET All Hall Of Fame<<<<
    const fetchHallFame = async () => {
        const res = await HomeService.getAllHallFame();
        if (res && res?.status) {
            setAllHallFame(res?.data)
        }
    }

    useEffect(() => {
        fetchHallFame()
    }, [])
    return (
        <>
            <section className='hallofdetailssection'>
                <div className='detailspagecontainer'>
                    <div className='bulletindetailsbg'>
                        <div className='singlepagetop'>
                            <div className="singlebulletinflx">
                                <h4 className='singlepagetoptxt'>Hall of Fame</h4>
                            </div>
                        </div>
                        <div className='singlepagebottommhalloff'>

                            {allHallFame && allHallFame?.length > 0 ?
                                allHallFame?.map((ele) => {
                                    return (

                                        <div className='singlehallofdetilasdivbg' key={ele?._id}>
                                            <div className='singlehallofdetilasdivtop'>
                                                <div className='halloffameprofile'>

                                                    <img src={ele?.userImage} alt="..." />

                                                </div>
                                            </div>
                                            <div className='singlehallofdetilasdivbottom'>
                                                <div className='halloftxtppspcldivflx'>
                                                    <p className='halloftxtppspcldiv'>{ele?.name}</p>
                                                    <p className='halloftxtppspcldiv'>{moment(ele?.createdOn).format("MMM-YYYY")}</p>
                                                </div>
                                                <div className=''>
                                                    <p className='rsnhalloftxtp'>
                                                        <span>Reason :</span>
                                                        {ele?.reason}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div>No Data Found!!</div>
                            }


                            {/* <div className='singlehallofdetilasdivbg'>
                                <div className='singlehallofdetilasdivtop'>
                                    <div className='halloffameprofile'>
                                        <img src={a} alt="..." />
                                    </div>
                                </div>
                                <div className='singlehallofdetilasdivbottom'>
                                    <div className='halloftxtppspcldivflx'>
                                        <p className='halloftxtppspcldiv'>Roma Roy</p>
                                        <p className='halloftxtppspcldiv'>DEC-2023</p>
                                    </div>
                                    <div className=''>
                                        <p className='rsnhalloftxtp'>
                                            <span>Reason :</span>
                                            Many reason Testing
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='singlehallofdetilasdivbg'>
                                <div className='singlehallofdetilasdivtop'>
                                    <div className='halloffameprofile'>
                                        <img src={a} alt="..." />
                                    </div>
                                </div>
                                <div className='singlehallofdetilasdivbottom'>
                                    <div className='halloftxtppspcldivflx'>
                                        <p className='halloftxtppspcldiv'>Roma Roy</p>
                                        <p className='halloftxtppspcldiv'>DEC-2023</p>
                                    </div>
                                    <div className=''>
                                        <p className='rsnhalloftxtp'>
                                            <span>Reason :</span>
                                            Many reason Testing
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='singlehallofdetilasdivbg'>
                                <div className='singlehallofdetilasdivtop'>
                                    <div className='halloffameprofile'>
                                        <img src={a} alt="..." />
                                    </div>
                                </div>
                                <div className='singlehallofdetilasdivbottom'>
                                    <div className='halloftxtppspcldivflx'>
                                        <p className='halloftxtppspcldiv'>Roma Roy</p>
                                        <p className='halloftxtppspcldiv'>DEC-2023</p>
                                    </div>
                                    <div className=''>
                                        <p className='rsnhalloftxtp'>
                                            <span>Reason :</span>
                                            Many reason Testing
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='singlehallofdetilasdivbg'>
                                <div className='singlehallofdetilasdivtop'>
                                    <div className='halloffameprofile'>
                                        <img src={a} alt="..." />
                                    </div>
                                </div>
                                <div className='singlehallofdetilasdivbottom'>
                                    <div className='halloftxtppspcldivflx'>
                                        <p className='halloftxtppspcldiv'>Roma Roy</p>
                                        <p className='halloftxtppspcldiv'>DEC-2023</p>
                                    </div>
                                    <div className=''>
                                        <p className='rsnhalloftxtp'>
                                            <span>Reason :</span>
                                            Many reason Testing
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='singlehallofdetilasdivbg'>
                                <div className='singlehallofdetilasdivtop'>
                                    <div className='halloffameprofile'>
                                        <img src={a} alt="..." />
                                    </div>
                                </div>
                                <div className='singlehallofdetilasdivbottom'>
                                    <div className='halloftxtppspcldivflx'>
                                        <p className='halloftxtppspcldiv'>Roma Roy</p>
                                        <p className='halloftxtppspcldiv'>DEC-2023</p>
                                    </div>
                                    <div className=''>
                                        <p className='rsnhalloftxtp'>
                                            <span>Reason :</span>
                                            Many reason Testing
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='singlehallofdetilasdivbg'>
                                <div className='singlehallofdetilasdivtop'>
                                    <div className='halloffameprofile'>
                                        <img src={a} alt="..." />
                                    </div>
                                </div>
                                <div className='singlehallofdetilasdivbottom'>
                                    <div className='halloftxtppspcldivflx'>
                                        <p className='halloftxtppspcldiv'>Roma Roy</p>
                                        <p className='halloftxtppspcldiv'>DEC-2023</p>
                                    </div>
                                    <div className=''>
                                        <p className='rsnhalloftxtp'>
                                            <span>Reason :</span>
                                            Many reason Testing
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='singlehallofdetilasdivbg'>
                                <div className='singlehallofdetilasdivtop'>
                                    <div className='halloffameprofile'>
                                        <img src={a} alt="..." />
                                    </div>
                                </div>
                                <div className='singlehallofdetilasdivbottom'>
                                    <div className='halloftxtppspcldivflx'>
                                        <p className='halloftxtppspcldiv'>Roma Roy</p>
                                        <p className='halloftxtppspcldiv'>DEC-2023</p>
                                    </div>
                                    <div className=''>
                                        <p className='rsnhalloftxtp'>
                                            <span>Reason :</span>
                                            Many reason Testing
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='singlehallofdetilasdivbg'>
                                <div className='singlehallofdetilasdivtop'>
                                    <div className='halloffameprofile'>
                                        <img src={a} alt="..." />
                                    </div>
                                </div>
                                <div className='singlehallofdetilasdivbottom'>
                                    <div className='halloftxtppspcldivflx'>
                                        <p className='halloftxtppspcldiv'>Roma Roy</p>
                                        <p className='halloftxtppspcldiv'>DEC-2023</p>
                                    </div>
                                    <div className=''>
                                        <p className='rsnhalloftxtp'>
                                            <span>Reason :</span>
                                            Many reason Testing
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='singlehallofdetilasdivbg'>
                                <div className='singlehallofdetilasdivtop'>
                                    <div className='halloffameprofile'>
                                        <img src={a} alt="..." />
                                    </div>
                                </div>
                                <div className='singlehallofdetilasdivbottom'>
                                    <div className='halloftxtppspcldivflx'>
                                        <p className='halloftxtppspcldiv'>Roma Roy</p>
                                        <p className='halloftxtppspcldiv'>DEC-2023</p>
                                    </div>
                                    <div className=''>
                                        <p className='rsnhalloftxtp'>
                                            <span>Reason :</span>
                                            Many reason Testing
                                        </p>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Halloffamedetails