import React, { useEffect, useState } from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png";
import Select from "react-select";
import HttpClientXml from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';
import HomeService from '../../Services/HomeService';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const EditEvent = () => {
    const initial = {
        eventName: "",
        eventDate: "",
        eventTime: "",
        eventTypeId: "",
        isHighLighted: "",
        postType: "",
        notes: "",
        addEmploee: [],
        image: ""
    }
    const location=useLocation();
    const initialValues = location?.state;
    const [imageURL, setImageURL] = useState(initialValues?.image);
    const dispatch = useDispatch();
    const navigate=useNavigate();
    const [image, setImage] = useState();
    const [uploading, setUploading] = useState(false);
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([initialValues?.addEmploee]);
    const [colourOptions, setColourOptions] = useState([]);
    const [empData, setempData] = useState([]);
    const [eventTypeId, seteventTypeId] = useState([]);
    const [colourOptionseventTypeId, setColourOptionseventTypeId] = useState([]);
    const [selectedOptionseventTypeId, setSelectedOptionseventTypeId] = useState(initialValues?.eventTypeId);
    const [groupData, setGroupData] = useState([]);
    const [colourOptionsGroup, setColourOptionsGroup] = useState([]);
    const [selectedOptionsGroup, setSelectedOptionsGroup] = useState(initialValues?.affinityGroupId);
    const [selectedOptionsPostType, setSelectedOptionsPostType] = useState(initialValues?.postType);


    useEffect(() => {
        getEmployeeData();
        getEventTypeData();
        getMyGroupData();
        setFieldValue("selectedOptionsPostType", initialValues?.postType);
        setFieldValue("selectedOptionsGroup", initialValues?.affinityGroupId);
        setFieldValue("selectedOptionseventTypeId", initialValues?.eventTypeId);
        setFieldValue("selectedOptions", initialValues?.addEmploee);
    }, [])

    useEffect(() => {
        const data = initialValues?.addEmploee
        const mentorData = data?.map((item) => {
            const optData = colourOptions?.find(ele => ele?.value === item)
            return optData ? optData : {}
        })
        setSelectedOptions(mentorData)
    }, [colourOptions])


    //options for postType
    const options = [
        { value: 'public', label: 'Public' },
        { value: 'private', label: 'Affinity Group' }
    ]


    useEffect(() => {
        const data = initialValues?.postType
        const optData = options?.find(ele => ele?.value === data)
        setSelectedOptionsPostType(optData)
    }
        , []);


    //for selecting invitees
    const handleSelectChange = (e) => {
        console.log("Selected Options:", e);

        // Update the colourOptions state with the selected options
        setSelectedOptions(e);
    };

    //for selecting event type
    const handleSelectChangeEventType = (e) => {
        setSelectedOptionseventTypeId(e);
    };

    //for selecting group 
    const handleSelectChangeGroup = (e) => {
        setSelectedOptionsGroup(e);
    };

    //for selecting Post Type
    const handleSelectChangePostType = (e) => {
        setSelectedOptionsPostType(e);
    };

    //get all invitees
    const getEmployeeData = async () => {
        const response = await HttpClientXml?.requestData("view-all-employees", {}, "GET");
        if (response && response.status) {
            const formattedOptions = response?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
            }));

            setColourOptions(formattedOptions);
            setSelectedOptions(formattedOptions?.filter(e => e.value === initialValues.addEmploee))
            setempData(response?.data);
        } else {
            // toast.error(response.message);
        }
    };

    //get event types
    const getEventTypeData = async () => {
        const response = await HttpClientXml?.requestData("view-event-type", {}, "GET");
        if (response && response.status) {
            const formattedOptions = response?.data?.map((item) => ({
                value: item?._id,
                label: item?.typeName,
            }));

            setColourOptionseventTypeId(formattedOptions);
            setSelectedOptionseventTypeId(formattedOptions?.find(e => e.value === initialValues?.eventTypeId))
            seteventTypeId(response?.data);
        } else {

        }
    };


    //get my affinity groups
    const getMyGroupData = async () => {
        const response = await HttpClientXml?.requestData("view-my-affinity-group", {}, "GET");
     
        if (response && response.status) {
            const formattedOptions = response?.data?.map((item) => ({
                value: item?._id,
                label: item?.groupName,
            }));

            setColourOptionsGroup(formattedOptions);
            setSelectedOptionsGroup(formattedOptions?.find(e => e.value === initialValues?.affinityGroupId))
            setGroupData(response?.data);
        } else {
            // toast.error(response.message);
        }
    };


    const submitHandler = async (e) => {
        e.preventDefault();
        setShouldValidateOnChange(true);
        setShouldValidateOnBlur(true);

        let data = {};
        if (
            values.eventName === "" ||
            values.eventDate === "" ||
            values.eventTypeId === "" ||
            imageURL === ""
        ) {
            return toast.error("All fields required");

        }

        data = {
            eventName: values?.eventName,
            eventDate: values?.eventDate,
            image: imageURL,
            notes: values?.notes,
            addEmploee: selectedOptions?.map((item) => item?.value),
            isHighLighted: values?.isHighLighted,
            eventTypeId: selectedOptionseventTypeId?.value,
            postType: selectedOptionsPostType?.value,
            affinityGroupId: selectedOptionsGroup?.value,
            eventTime: values?.eventTime
        };

        // return false;
        const res = await HttpClientXml?.requestData(`update-event/${initialValues?._id}`, data, "PUT");
        if (res?.status) {
            toast.success("Event is updated successfully");
            resetForm();
            setTimeout(() => {
                navigate('/')
            }, 1000)
            // dispatch(getEventData());
            // closemodal();
        } else {

        }

    };


    const handleImageChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await HttpClientXml.fileUplode("image-upload", "POST", form);
        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageURL(res?.image);
        } else {

        }
        setUploading(false);
    };

    const { values, resetForm, handleChange, setFieldValue } =
        useFormik({
            initialValues,
            validateOnChange: shouldValidateOnChange,
            validateOnBlur: shouldValidateOnBlur,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });

    return (
        <>
            <section className="createeventdetails">
                <div className="detailspagecontainer">
                    <div className="createdivfrmmain">
                        <form>
                            <div className=''>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Event Name <span className='required'>*</span></p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder="enter event name"
                                        className="typstrningwhselctnew"
                                        name="eventName"
                                        value={values?.eventName}
                                        onChange={handleChange}
                                    />

                                </div>


                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Event Date  <span className='required'>*</span></p>
                                    <input
                                        type="date"
                                        placeholder="enter event date"
                                        className="typstrningwhselctnew"
                                        name="eventDate"
                                        value={moment(values?.eventDate).format("YYYY-MM-DD")}
                                        onChange={handleChange}
                                        min={new Date()?.toISOString()?.split("T")[0]}
                                    />


                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Start Time <span className='required'>*</span></p>
                                    <input
                                        type="time"
                                        id="eventTime"
                                        aria-describedby="emailHelp"
                                        placeholder="enter event start time"
                                        className="typstrningwhselctnew"
                                        name='eventTime'
                                        value={values?.eventTime}
                                        onChange={handleChange}
                                    />

                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt"> Types Of Event <span className='required'>*</span></p>

                                    <Select
                                        placeholder={"Select Event Type"}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        value={selectedOptionseventTypeId}

                                        // defaultValue={colourOptionseventTypeId?.filter(e => e.value === initialValues.eventTypeId) && null}
                                        options={colourOptionseventTypeId}
                                        onChange={handleSelectChangeEventType}
                                    />
                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt"> Share Your Event <span className='required'>*</span></p>
                                    <Select
                                        placeholder={"Select Post Type"}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        // defaultValue={[]}
                                        value={selectedOptionsPostType}
                                        options={options}
                                        onChange={handleSelectChangePostType}
                                    />
                                </div>

                                {/* <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Invitees<span className='required'>*</span></p>

                                    <Select
                                        isMulti
                                        placeholder={"select invitees"}
                                        closeMenuOnSelect={false}
                                        defaultValue={selectedOption}
                                        onChange={(selectedOptions) => {
                                            const employeeIds = selectedOptions.map(option => option.value);
                                            setSelectedOption(selectedOptions);
                                            setAddEvent({ ...addEvent, addEmploee: employeeIds });
                                        }}
                                        options={allEmployeeData && allEmployeeData?.map(ele => ({ value: ele?._id, label: `${ele?.firstName} ${ele?.lastName}` }))}
                                        className="typstrningwhselctnew"
                                    />
                                </div> */}

                                {
                                    (selectedOptionsPostType === 'private' ||
                                        selectedOptionsPostType?.value === 'private') &&
                                    (<div className="trainingpageleftdivmain">
                                        <p> Affinity Groups <span className='rsntxrpnewtxt'>*</span></p>
                                        <Select
                                            placeholder={"Select Affinity Groups"}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            value={selectedOptionsGroup}
                                            options={colourOptionsGroup}
                                            onChange={handleSelectChangeGroup}
                                        />

                                    </div>)
                                }


                                {
                                    (selectedOptionsPostType === 'public' ||
                                        selectedOptionsPostType?.value === 'public') && (
                                        <div className="trainingpageleftdivmain">
                                            <p>Invitation</p>

                                            <Select
                                                placeholder={"Select Invitees"}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                value={selectedOptions}
                                                isMulti
                                                options={colourOptions}
                                                onChange={handleSelectChange}
                                            />

                                        </div>)
                                }

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Is this event highlighted?</p>
                                    <div className='isHighLightedRadiodivnewdiv'>

                                        <div className='isHighLightedRadiodivnew'>
                                            <input
                                                type="radio"
                                                className=""
                                                name="isHighLighted"
                                                value="yes"
                                                checked={values.isHighLighted === "yes"}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="isHighLightedYes" className="radioLabel" >
                                                Yes
                                            </label>
                                        </div>

                                        <div className='isHighLightedRadiodivnew'>
                                            <input
                                                type="radio"
                                                className=""
                                                name="isHighLighted"
                                                value="no"
                                                checked={values.isHighLighted === "no"}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="isHighLightedNo" className="radioLabel" >
                                                No
                                            </label>
                                        </div>

                                    </div>
                                </div>

                                <div className="trainingrifgtdivgappp">
                                    <p className="rsntxrpnewtxt">Event Details <span> ( Maximum 100 Letter you can write )</span></p>
                                    <textarea
                                        placeholder='enter event description'
                                        id=""
                                        rows={4}
                                        cols={50}
                                        className="txtdivallartadvcacytfnew"
                                        name='notes'
                                        value={values?.notes}
                                        onChange={handleChange}
                                    ></textarea>

                                </div>


                                <div className="vdoulddivpbupldgnewmmll">
                                    <p className="rsntxrpnewtxt"> Image Upload <span className='required'>*</span></p>

                                    <div className="bgcontainerupldboxsgwgrdiv">
                                        <div className="bgcrd">
                                            <div className="bgcontaineruplddivnew">
                                                <div className="logouplddiv">
                                                    <img
                                                        src={Addicon}
                                                        alt="..."
                                                    />
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            id="hostedBy"
                                                            className="upldimagediv"
                                                            placeholder="Choose a photo"
                                                            onChange={handleImageChange}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <p className="upldtxtppdiv">Upload min 5 mb image</p>

                                            {/* After Upload Design Start */}
                                            {imageURL && <div className="bgcontainerupldfiledivabslt">
                                                <div className="imageuplddiv">
                                                    <img src={imageURL} alt />
                                                    <div className="imageupldcrs" style={{
                                                        cursor: "pointer"
                                                    }}>
                                                        <i className="fa-solid fa-xmark"
                                                            onClick={() => {
                                                                setImageURL("");
                                                                setImage("");
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>}
                                            {/* After Upload Design End */}

                                        </div>
                                    </div>
                                </div>
                                <div className="bulletinsbmtbtndivv">
                                    <button
                                        type="button"
                                        className="bulletinsbmtbtn"
                                        onClick={submitHandler}
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditEvent
