import React, { useEffect, useState } from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png";
import Select from "react-select";
import HomeService from '../../Services/HomeService';
import toast from 'react-hot-toast';
import HttpClientXml from '../../utils/HttpClientXml';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

const CreateBulletin = () => {
    const initial = {
        eventName: "",
        image: "",
        hostedBy: "",
        // eventDate: "",
        // startTime: "",
        // endTime: "",
        startDate:"",
        endDate:"",
        addEmploee: "",
        addNotes: ""
    }
    const navigate = useNavigate();
    const location = useLocation()
    const [addBulletin, setAddBulletin] = useState(initial)
    const [imageFile, setimageFile] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    const [AllEmployeeData, setAllEmployeeData] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    // console.log("sg31354g6513", location?.state)

    // >>>>HandleChange Of all Input<<<<
    const handleChange = (e) => {
        const { name, value } = e.target
        setAddBulletin({ ...addBulletin, [name]: value })
    }

    // >>>>GeET All Employees Data<<<<
    const fetchAllEmployee = async () => {
        const res = await HomeService.getAllEmployees();
        if (res && res?.status) {
            setAllEmployeeData(res?.data)
        }
    }


    // >>>Handle Image Upload<<<
    const handleImage = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        setimageFile(URL.createObjectURL(file));
        let formData = new FormData();
        formData.append("image", file);

        const res = await HttpClientXml.fileUplode("image-upload", "POST", formData);

        if (res && res.status) {
            toast.success("Image Uploaded Successfully");
            setAddBulletin({ ...addBulletin, image: res?.image });
        } else {
            toast.error(res?.message || "Something Wrong");
        }
    };

    // >>>Handle Removing Image<<<
    const handleRemoveImage = () => {
        setimageFile("");
        document.getElementById("hostedBy").value = "";
    };



    // >>>>Handle Submit<<<<
    const onSubmit = async () => {
        const { eventName, hostedBy, startDate, endDate, addEmploee, addNotes } = addBulletin;

        const currentDate = new Date();
        const selectedStartDate = new Date(startDate);
        const selectedEndDate = new Date(endDate);
        // const selectedStartTime = new Date(`01/01/2000 ${startTime}`);
        // const selectedEndTime = new Date(`01/01/2000 ${endTime}`);
        // const userType = localStorage?.getItem("EsgUserType")
        // Validation checks
        if (!eventName || !hostedBy || !startDate || !endDate || !addEmploee || !addNotes) {
            toast.error('All fields are required');
            return;
        } else if (selectedStartDate < currentDate) {
            toast.error('Please enter valid event date');
            return;
        }
        else if (selectedStartDate >= selectedEndDate) {
            toast.error('Please enter valid Start Date and End Date!');
            return;
        }
         else if (imageFile?.trim() === "" || imageFile === null) {
            toast.error("Please upload document")
        } else {
            // const startMoment = moment(startTime, 'HH:mm');
            // const endMoment = moment(endTime, 'HH:mm');

            // Format the start and end times as "9:00" and "10:35"
            // const formattedStartTime = startMoment.format('HH:mm');
            // const formattedEndTime = endMoment.format('HH:mm');
            const data = {
                eventName,
                hostedBy,
                // eventDate : `${moment(addBulletin?.eventDate).format("YYYY-MM-DD")}`,
                startDate: `${moment(addBulletin?.startDate).format("YYYY-MM-DD")}`,
                endDate: `${moment(addBulletin?.endDate).format("YYYY-MM-DD")}`,
                addEmploee,
                addNotes,
                image: addBulletin?.image
            };
            // console.log("sd7f7d41f32z", data)
            // return
            const res = await HomeService.addBulletin(data);
            if (res && res?.status) {
                toast.success("Bulletin added successfully!")
                setAddBulletin(initial);
                setimageFile("")
                setTimeout(() => {
                    navigate('/bulletinDetails');
                }, 1000);
            }
        }

    }

    useEffect(() => {
        fetchAllEmployee()
    }, [])

    useEffect(() => {
        if (location?.state) {
            setAddBulletin(location?.state)
            setIsEdit(true)
        } else {
            setIsEdit(false)
        }
    }, [location?.state])

    return (
        <>
            <section className="createeventdetails">
                <div className="detailspagecontainer">
                    <div className="createdivfrmmain">
                        <form>
                            <div className=''>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Bulletin Name <span className='required'>*</span></p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name='eventName'
                                        value={addBulletin?.eventName}
                                        onChange={handleChange}
                                    />

                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Hosted By <span className='required'>*</span></p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name='hostedBy'
                                        value={addBulletin?.hostedBy}
                                        onChange={handleChange}
                                    />

                                </div>

                                {/* <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Event Date  <span className='required'>*</span></p>
                                    <input
                                        type="date"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name='eventDate'
                                        value={isEdit !== true ? `${moment(addBulletin?.eventDate).format("YYYY-MM-DD")}` : `${moment(location?.state?.eventDate).format("YYYY-MM-DD")}`}
                                        onChange={handleChange}
                                    />
                                </div> */}

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Start Date  <span className='required'>*</span></p>
                                    <input
                                        type="date"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name='startDate'
                                        value={isEdit !== true ? `${moment(addBulletin?.startDate).format("YYYY-MM-DD")}` : `${moment(location?.state?.startDate).format("YYYY-MM-DD")}`}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">End Time  <span className='required'>*</span></p>
                                    <input
                                        type="date"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name='endDate'
                                        value={isEdit !== true ? `${moment(addBulletin?.endDate).format("YYYY-MM-DD")}` : `${moment(location?.state?.endDate).format("YYYY-MM-DD")}`}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt"> Select Invitees <span className='required'>*</span></p>

                                    <Select
                                        placeholder={"Select Event Type"}
                                        closeMenuOnSelect={true}
                                        defaultValue={selectedOption}
                                        onChange={(selectedOption) => {
                                            setSelectedOption(selectedOption);
                                            // Set the selected initiative type ID
                                            setAddBulletin({ ...addBulletin, addEmploee: selectedOption?.value });
                                        }}
                                        options={AllEmployeeData && AllEmployeeData?.map(item => ({ value: item?._id, label: `${item?.firstName} ${item?.lastName}` }))}
                                        className="typstrningwhselctnew"
                                    />
                                </div>

                                <div className="trainingrifgtdivgappp">
                                    <p className="rsntxrpnewtxt">Event Details</p>
                                    <textarea
                                        id=""
                                        rows={4}
                                        cols={50}
                                        name='addNotes'
                                        value={addBulletin?.addNotes}
                                        onChange={handleChange}
                                        className="txtdivallartadvcacytfnew"
                                    ></textarea>

                                </div>

                                <div className="vdoulddivpbupldgnewmmll">
                                    <p className="rsntxrpnewtxt"> Image Upload <span className='required'>*</span></p>

                                    <div className="bgcontainerupldboxsgwgrdiv">
                                        <div className="bgcrd">
                                            <div className="bgcontaineruplddivnew">
                                                <div className="logouplddiv">

                                                    <img
                                                        src={Addicon}
                                                        alt="..."
                                                    />
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            id="hostedBy"
                                                            className="upldimagediv"
                                                            placeholder="Choose a photo"
                                                            onChange={handleImage}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <p className="upldtxtppdiv">Upload min 5 mb image</p>

                                            {/* After Upload Design Start */}
                                            {imageFile &&
                                                <div className="bgcontainerupldfiledivabslt">
                                                    <div className="imageuplddiv">
                                                        <img src={isEdit !== true ? `${imageFile}` : `${location?.state?.image}`} alt />
                                                        <div className="imageupldcrs" style={{
                                                            cursor: "pointer"
                                                        }}>
                                                            <i className="fa-solid fa-xmark" onClick={handleRemoveImage} />
                                                        </div>
                                                    </div>

                                                </div>}
                                            {/* After Upload Design End */}

                                        </div>
                                    </div>
                                </div>
                                <div className="bulletinsbmtbtndivv">
                                    <button
                                        type="button"
                                        className="bulletinsbmtbtn"
                                        onClick={onSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreateBulletin
