import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player';
import { Rating } from "react-simple-star-rating";
// import cutegirldp from "../../Images/cute-girl-dp.jpg";
import download from "../../Images/Icons/PNG/download.png"
import Coursecontentcomp from './Coursecontentcomp';
// import Suugestionvideocomp from './Suugestionvideocomp';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import toast from 'react-hot-toast';
import HttpClientXml from '../../utils/HttpClientXml';


const Coursevideoplayer = () => {
    // const navigate=useNavigate()
    const params = useParams();
    // const location = useLocation();
    // const item = location?.state?.item
    // const ele = location?.state?.singleMandatory
    // const selectedOptions = location?.state?.selectedOptions
    // console.log(selectedOptions, "itemtyuio")
    const [dropdownintro, setDropdownintro] = useState(false)
    const [singleView, setSingleView] = useState([]);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [colourOptions, setColourOptions] = useState([]);
    const navigate = useNavigate();
    const [watchedPercentage, setWatchedPercentage] = useState(0);
    const [playedDuration, setPlayedDuration] = useState(0);
    // const [totalDuration, setTotalDuration] = useState(0);
    const previousPercentageRef = useRef(0);
    const [singleViewList, setSingleViewList] = useState([])


    //for view Single Page training
    const viewSinglepostList = async () => {
        const response = await HttpClientXml?.requestData(`view-single-trainings/${params?.tId}`, {}, "GET")
        if (response?.status) {
            setSingleViewList(response?.data?.[0]);
        } 
    }

    //for view Single Page
    const viewSinglepost = async () => {
        const response = await HttpClientXml?.requestData(`view-video-trainings/${params?.tId}/${params?.id}`, {}, "GET")
        if (response?.status) {
            setSingleView(response?.data);
        } 
    }

    const handleowndropdwnClick = () => {
        setDropdownintro(!dropdownintro)
    }


    //for getting mentor name
    const getEmployeeData = async () => {
        const response = await HttpClientXml?.requestData("view-all-employees", {}, "GET")

        if (response && response.status) {
            const formattedOptions = response?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
                image: item?.image
            }));

            setColourOptions(formattedOptions);
        } else {

        }
    };


    useEffect(() => {
        const data = singleView?.mentor
        console.log("datavbsd", data)
        const mentorData = data?.map((item) => {
            const optData = colourOptions?.find(ele => ele?.value === item)
            return optData ? optData : {}
        })
        setSelectedOptions(mentorData)
    }, [colourOptions])


    //for rating functionality
    const handleRating = async (rate, id, vId) => {
        let data = {
            trainingId: id,
            videoId: vId,
            rating: rate,
        };

        let response = await HttpClientXml?.requestData(`add-training-video-rating`, data, "PUT");
        if (response.status) {
            toast.success(response?.data?.message);
        } else {
            toast.error(response?.response?.data?.message);
        }

    };


    //for calculating percentage of video progress
    const handleProgress = (state) => {
        const { playedSeconds, played } = state;
        const playedMinutes = playedSeconds / 60; // Convert played seconds to minutes
        setPlayedDuration(Math.round(playedMinutes))
        const percentage = played * 100;
        setWatchedPercentage(Math.round(percentage)); // Round to nearest integer
        updateVideoPlaytime();
    };


    //for updating video play time
    const updateVideoPlaytime = async () => {
        const data = {
            trainingId: `${params?.tId}`,
            videoId: `${params?.id}`,
            playTimePercent: watchedPercentage,
            playtime: playedDuration
        };
        // console.log("datakl", data)
        // return false;
        const response = await HttpClientXml?.requestData(`update-playtime-byuser`, data, "POST");
        if (response.status) {
            viewSinglepostList();
        }
    };


    //for viewing add more videos
    const cousrevideoonClick = (item) => {
        const data = {
            // selectedOptions: selectedOptions,
            item: item,
            // singleMandatory: singleMandatory
        }
        navigate(`/courseVideoPlayer/${singleViewList?.title?.replace(/\s+/g, '-')}/${singleViewList?._id}/${item?._id}`, { state: data })
    }

    useEffect(() => {
        const previousPercentage = previousPercentageRef.current;
        if (Math.abs(watchedPercentage - previousPercentage) >= 5) {
            updateVideoPlaytime();
            previousPercentageRef.current = watchedPercentage;
        }
    }, [watchedPercentage]);

    useEffect(() => {
        // viewSinglepost();
        getEmployeeData();
    }, [])


    useEffect(() => {
        viewSinglepost();
        viewSinglepostList();
    }, [params?.tId, params?.id]);


    const takequizeclick = () => {
        navigate("/quizemain")
    }
    return (
        <>
            <section className='coursevideoplayermainsection'>
                <div className='esgpagecontainer'>
                    <div className='coursevideoplayedivflx'>
                        <div className='coursevideoplayedleft'>
                            <div className='coursevideoplayedfdivbg'>
                                <div className='coursevieowdopaplerrdiv'>
                                    {/* <ReactPlayer width={"100%"} height={"100%"} controls playing={true} config={{
                                        youtube: {
                                            playerVars: { showinfo: 1 }
                                        },
                                        facebook: {
                                            appId: '12345'
                                        },
                                    }} url='https://www.youtube.com/watch?v=LXb3EKWsInQ' /> */}
                                    <ReactPlayer
                                        className='reactdivimgmainplrt'
                                        controls
                                        playing={true}
                                        url={singleView?.video?.videoLink}
                                        onProgress={handleProgress}
                                        config={{
                                            youtube: {
                                                playerVars: { showinfo: 1 }
                                            },
                                            facebook: {
                                                appId: '12345'
                                            },
                                        }}

                                    />
                                </div>
                                <div className=''>
                                    {/* <div className='dotdivbgvdo'>
                                        <p className='vdodescrptntxtppdiv'>Part-1</p>
                                    </div>
                                    <div className=''>
                                        <p className='emplyetextppcrse'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                                        </p>
                                    </div> */}
                                    <div className='dotdivbgvdo'>
                                        <p className='vdodescrptntxtppdiv'>{singleView?.video?.videoTitle}</p>
                                    </div>
                                    <div className=''>
                                        <p className='emplyetextppcrse'>{singleView?.video?.videoDescription}
                                        </p>
                                    </div>
                                </div>

                                <div className='ratinhghjdibbmnammain'>
                                    {/* <Rating size={19} /> */}
                                    <Rating

                                        // key={`${key}${index}`}
                                        onClick={(rate) => {
                                            handleRating(rate, singleView?._id, singleView?.video?._id);
                                        }}
                                        initialValue={singleView?.videoRatings?.rating}
                                        size={19}
                                    />
                                </div>
                                {/* <div className="trainerdetailsdownloadshreflx">
                                    <div className="trainerprfltrainerdtls">
                                        <div className="prfledtslbrdrtrainer">
                                            <div className="prfledtslbrdrtrainerimg">
                                                <img
                                                    src={cutegirldp}
                                                    alt="..."
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="">
                                                <p className="trainermanetxt">Mamta jain </p>
                                            </div>
                                            <p className="trinrdtlstxt">Trainer</p>
                                        </div>
                                    </div>
                                    <div className="dateshowlinkdivimghhshjh">
                                        <img src={download} alt="..." />
                                    </div>

                                </div> */}

                                <div className='trainerdetailsdownloadshreflx'>
                                    <div className='trainerprfltrainerdtls'>
                                        <div className='prfledtslbrdrtrainer'>
                                            <div className='prfledtslbrdrtrainerimg'>
                                                <img
                                                    // src={advytimg} 
                                                    src={`${selectedOptions?.map((item) => item?.image)} `}
                                                    alt="..." />
                                            </div>
                                        </div>
                                        <div className=''>
                                            {/* <div className=''>
                                                    <p className='trainermanetxt'>Anirban Roy</p>
                                                </div> */}

                                            {
                                                singleView?.mentor !== 0 ?
                                                    (<div className=''>
                                                        <p className='trainermanetxt'>{`${selectedOptions?.map((item) => item?.label)} `}</p>
                                                    </div>)
                                                    // : ele?.trainerName ? (<div className=''>
                                                    //     <p className='trainermanetxt'>Training Name : {ele?.trainerName}</p>
                                                    // </div>)
                                                    : (<></>)

                                            }
                                            <p className='trinrdtlstxt'>Trainer</p>
                                        </div>
                                    </div>
                                    <div className='dwnldtrainerflx'>
                                        {/* <div className='dwnldpdfflx'>
                                                <div className='downloadimgdivtrnr'>
                                                    <img src={download} alt="..." />
                                                </div>
                                                <p className='dwldtxtppodf'>Download Pdf</p>
                                            </div> */}

                                        <div className='downladimgmainpl'>
                                            {
                                                singleView?.video?.videoDocument?.[0]?.document !== "" ? (<div className='dateshowlinkdiv'><img src={download} alt="..." /></div>) : (<div><img src={download} alt="..." /></div>)
                                            }

                                            <div className='downloaddivmaindiv'>

                                                {
                                                    singleView?.video?.videoDocument?.[0]?.document !== "" && (
                                                        <>
                                                            <div className='downloaddivmaindivsize' >

                                                                <a href={singleView?.video?.videoDocument?.[0]?.document?.startsWith("http") ? singleView?.video?.videoDocument?.[0]?.document : `http://${singleView?.video?.videoDocument?.[0]?.document}`} target="_blank" rel="noopener noreferrer">
                                                                    {singleView?.video?.videoDocument?.[0]?.document?.slice(0, 20)}
                                                                </a>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>


                                        {/* <div className='shredivimgmain'>
                                                <img src={Share} alt="..." />
                                            </div> */}
                                    </div>
                                </div>

                                <div className='trainingbbrtrrfsghdicnmmabrdr'>
                                    <div className='trainingbbrtrrfsghdicnmmabrdrpadinjjh'>
                                        <p className='crtdtxtppspcl'>
                                            {/* Created on 2024-06-21 */}
                                            Created on {moment(singleView?.createdOn).format("YYYY-MM-DD")}
                                        </p>
                                        <p className="dsrtptxtpp">Description</p>
                                        {/* <p className="empytxtppeng">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                        </p> */}
                                        <p className='empytxtppeng'>{singleView?.description}</p>

                                        <p className="empytxtppeng">KeyWords</p>
                                        <p>
                                            <span className="keywrdtagpp"> #Employee,</span>
                                            <span className="keywrdtagpp"> #Engagement,</span>
                                            <span className="keywrdtagpp"> #Company,</span>
                                            <span className="keywrdtagpp"> #Together,</span>
                                            <span className="keywrdtagpp"> #Work ,</span>
                                            <span className="keywrdtagpp"> #People</span>
                                        </p>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='coursevideoplayedright'>
                            <div className=''>
                                <Coursecontentcomp singleViewList={singleViewList} singleView={singleView}/>
                            </div>
                            <div className="quizebnnsdcrdbvooapggdivpashn">
                                <button className='quizebtnnsjjkmaoincnthhskj' onClick={() => takequizeclick()}>
                                    Take Quize
                                </button>
                            </div>
                            {/* <div className='suggestionndivpagding'>
                                <Suugestionvideocomp />
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Coursevideoplayer
