import React, { useState } from 'react'
import WhiteEdit from "../../Images/Icons/PNG/WhiteEdit.png";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import cutegirldp from "../../Images/cute-girl-dp.jpg";
import Share from "../../Images/Icons/PNG/Share.png"
import { useNavigate } from 'react-router-dom';
const SkillValidation = () => {

    const [activeTab, setActiveTab] = useState(0);
  const navigate=useNavigate();
  const handlevalidatskill=()=>{
    navigate("/createskillvalidation")
  }
    const onTabClick = (e, index) => {
        console.log(e);
        setActiveTab(index);
    };

   
    const tabsvalidate = [
        {
            skill: "Java",
        },
        {
            skill: "React Js",
        },
        {
            skill: "C++",
        },
        {
            skill: "Node",
        },
        {
            skill: "Php",
        },
        {
            skill: "dot Net",
        },
        {
            skill: "Java",
        },
        {
            skill: "Html",
        },
        {
            skill: "UI UX"
        }

    ]
    return (
        <>
            <div className=''>
                <div className='valoidatedatediv'>
                    <div className='valoidatedatedivtop'>
                        <div className=''>
                            <p className='hobbyAspHeadslklvtxt'>Validate My Skill</p>
                        </div>
                        <div className='edtdivimg' onClick={handlevalidatskill}>
                            <img src={WhiteEdit} alt="..." />
                        </div>
                    </div>
                    <div className='valoidatedatedivbtm'>
                        <Tabs activeTab={activeTab} onTabClick={onTabClick}>

                            {tabsvalidate.map((item, index) => {
                                return (
                                    <Tab key={index}>
                                        {item.skill}
                                    </Tab>
                                )
                            })}
                        </Tabs>
                    </div>

                    <TabScreen
                        activeTab={activeTab}
                        index={0}
                        // You can add animation with adding a custom class
                        className="some-animation-class"
                    >
                        <div className='somdclassdkko'>
                            <div id="rating">
                                <div className="rateJavaHeadDiv">
                                    <p className="rateJavaHead">JAVA : Validated Score</p>
                                    <div className="rateLinkShareDiv">
                                        <div className="share">
                                            <img
                                                src={Share}
                                                alt="..."
                                                className="shareIconImg"
                                            />
                                            <span />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                                        <div className="rating_text">
                                            <h2>5</h2>
                                            <p>out of</p>
                                            <h2>5.00</h2>
                                        </div>
                                    </div>
                                    <div className="col-xl-9 col-lg-9 col-md-8 col-12">
                                        <p className="totalFiveText">Total 1 reviews given</p>
                                        <div className="progress_wrap">
                                            <span className="number">5</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">4</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "80%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">3</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "60%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">2</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "40%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">1</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "20%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                    </div>
                                    <div className="comment_section">
                                        <div className="comment_left">
                                            <div className="comment_person">
                                                <img
                                                    className="img-fluid"
                                                    src={cutegirldp}
                                                    alt="pic"
                                                />
                                            </div>
                                        </div>
                                        <div className="comment_right">
                                            <div className="comment_top">
                                                <div className="comment_top_left">
                                                    <h5>Jay Agarwal</h5>
                                                    <div className="star" />
                                                </div>
                                                <div className="comment_top_right" />
                                            </div>
                                            <div className="person_description">
                                                <p>good</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </TabScreen>

                    <TabScreen
                        activeTab={activeTab}
                        index={1}
                        // You can add animation with adding a custom class
                        className="some-animation-class"
                    >
                        <div className='somdclassdkko'>
                            <div id="rating">
                                <div className="rateJavaHeadDiv">
                                    <p className="rateJavaHead">React Js : Validated Score</p>
                                    <div className="rateLinkShareDiv">
                                        <div className="share">
                                            <img
                                                src={Share}
                                                alt="..."
                                                className="shareIconImg"
                                            />
                                            <span />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                                        <div className="rating_text">
                                            <h2>5</h2>
                                            <p>out of</p>
                                            <h2>5.00</h2>
                                        </div>
                                    </div>
                                    <div className="col-xl-9 col-lg-9 col-md-8 col-12">
                                        <p className="totalFiveText">Total 1 reviews given</p>
                                        <div className="progress_wrap">
                                            <span className="number">5</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">4</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "80%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">3</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "60%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">2</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "40%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">1</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "20%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                    </div>
                                    <div className="comment_section">
                                        <div className="comment_left">
                                            <div className="comment_person">
                                                <img
                                                    className="img-fluid"
                                                    src={cutegirldp}
                                                    alt="pic"
                                                />
                                            </div>
                                        </div>
                                        <div className="comment_right">
                                            <div className="comment_top">
                                                <div className="comment_top_left">
                                                    <h5>Jay Agarwal</h5>
                                                    <div className="star" />
                                                </div>
                                                <div className="comment_top_right" />
                                            </div>
                                            <div className="person_description">
                                                <p>good</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </TabScreen>

                    <TabScreen
                        activeTab={activeTab}
                        index={2}
                        // You can add animation with adding a custom class
                        className="some-animation-class"
                    >
                        <div className='somdclassdkko'>
                            <div id="rating">
                                <div className="rateJavaHeadDiv">
                                    <p className="rateJavaHead">C++ : Validated Score</p>
                                    <div className="rateLinkShareDiv">
                                        <div className="share">
                                            <img
                                                src={Share}
                                                alt="..."
                                                className="shareIconImg"
                                            />
                                            <span />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                                        <div className="rating_text">
                                            <h2>5</h2>
                                            <p>out of</p>
                                            <h2>5.00</h2>
                                        </div>
                                    </div>
                                    <div className="col-xl-9 col-lg-9 col-md-8 col-12">
                                        <p className="totalFiveText">Total 1 reviews given</p>
                                        <div className="progress_wrap">
                                            <span className="number">5</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">4</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "80%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">3</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "60%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">2</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "40%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">1</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "20%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                    </div>
                                    <div className="comment_section">
                                        <div className="comment_left">
                                            <div className="comment_person">
                                                <img
                                                    className="img-fluid"
                                                    src={cutegirldp}
                                                    alt="pic"
                                                />
                                            </div>
                                        </div>
                                        <div className="comment_right">
                                            <div className="comment_top">
                                                <div className="comment_top_left">
                                                    <h5>Jay Agarwal</h5>
                                                    <div className="star" />
                                                </div>
                                                <div className="comment_top_right" />
                                            </div>
                                            <div className="person_description">
                                                <p>good</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </TabScreen>

                    <TabScreen
                        activeTab={activeTab}
                        index={3}
                        // You can add animation with adding a custom class
                        className="some-animation-class"
                    >
                        <div className='somdclassdkko'>
                            <div id="rating">
                                <div className="rateJavaHeadDiv">
                                    <p className="rateJavaHead">Node : Validated Score</p>
                                    <div className="rateLinkShareDiv">
                                        <div className="share">
                                            <img
                                                src={Share}
                                                alt="..."
                                                className="shareIconImg"
                                            />
                                            <span />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                                        <div className="rating_text">
                                            <h2>5</h2>
                                            <p>out of</p>
                                            <h2>5.00</h2>
                                        </div>
                                    </div>
                                    <div className="col-xl-9 col-lg-9 col-md-8 col-12">
                                        <p className="totalFiveText">Total 1 reviews given</p>
                                        <div className="progress_wrap">
                                            <span className="number">5</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">4</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "80%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">3</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "60%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">2</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "40%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">1</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "20%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                    </div>
                                    <div className="comment_section">
                                        <div className="comment_left">
                                            <div className="comment_person">
                                                <img
                                                    className="img-fluid"
                                                    src={cutegirldp}
                                                    alt="pic"
                                                />
                                            </div>
                                        </div>
                                        <div className="comment_right">
                                            <div className="comment_top">
                                                <div className="comment_top_left">
                                                    <h5>Jay Agarwal</h5>
                                                    <div className="star" />
                                                </div>
                                                <div className="comment_top_right" />
                                            </div>
                                            <div className="person_description">
                                                <p>good</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </TabScreen>

                    <TabScreen
                        activeTab={activeTab}
                        index={4}
                        // You can add animation with adding a custom class
                        className="some-animation-class"
                    >
                        <div className='somdclassdkko'>
                            <div id="rating">
                                <div className="rateJavaHeadDiv">
                                    <p className="rateJavaHead">Php : Validated Score</p>
                                    <div className="rateLinkShareDiv">
                                        <div className="share">
                                            <img
                                                src={Share}
                                                alt="..."
                                                className="shareIconImg"
                                            />
                                            <span />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                                        <div className="rating_text">
                                            <h2>5</h2>
                                            <p>out of</p>
                                            <h2>5.00</h2>
                                        </div>
                                    </div>
                                    <div className="col-xl-9 col-lg-9 col-md-8 col-12">
                                        <p className="totalFiveText">Total 1 reviews given</p>
                                        <div className="progress_wrap">
                                            <span className="number">5</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">4</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "80%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">3</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "60%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">2</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "40%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                        <div className="progress_wrap">
                                            <span className="number">1</span>
                                            <span className="icon">
                                                <i className="fa-solid fa-star" />
                                            </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow={25}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "20%" }}
                                                />
                                            </div>
                                            <span className="amount">1</span>
                                        </div>
                                    </div>
                                    <div className="comment_section">
                                        <div className="comment_left">
                                            <div className="comment_person">
                                                <img
                                                    className="img-fluid"
                                                    src={cutegirldp}
                                                    alt="pic"
                                                />
                                            </div>
                                        </div>
                                        <div className="comment_right">
                                            <div className="comment_top">
                                                <div className="comment_top_left">
                                                    <h5>Jay Agarwal</h5>
                                                    <div className="star" />
                                                </div>
                                                <div className="comment_top_right" />
                                            </div>
                                            <div className="person_description">
                                                <p>good</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </TabScreen>


                </div>
            </div>
        </>
    )
}

export default SkillValidation
