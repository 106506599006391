import React, { useState } from 'react'
import Thankyoucard from "../../src/Images/Thankyoucard.png";
import Happybirthdaycard from "../../src/Images/Happybirthdaycard.png";
import avatar from "../../src/Images/avatar.jpg";
import Onboard from "../../src/Images/Onboard.png";
import HappyAnniversary from "../../src/Images/HappyAnniversary.png";
const ThankYoucardmodal = ({ closemodal, handlecardfirst, handlecardsecond,handlecardthird,handlecardfourth}) => {

    const handlestopclick = (e) => {
        e.stopPropagation()
    }

    return (
        <>
            <section className='thopughtmodabgmain' onClick={() => closemodal()}>
                <div className='allcardbigbg' onClick={(e) => handlestopclick(e)}>
                    <div className='allcardsectionflx'>

                        <div className="allcardsectionbg" style={{ cursor: "pointer" }} onClick={handlecardfirst}>
                            <div
                                className="thankyoucrdbg"
                                style={{ backgroundImage: `url(${Thankyoucard})` }}
                            >
                                {/* <div className="companylitlleimg">
                                    <img
                                        src="/static/media/companyelite.75eb93cd70e56ebd5043.png"
                                        alt="..."
                                    />
                                </div> */}
                                <div className="profilebgthanky">
                                    <div className="profilebgthankyimg">
                                        <img
                                            src={avatar}
                                            alt="..."
                                        />
                                    </div>
                                </div>
                                <div className="thankyubrdrdiv">Thank You</div>
                                <div className="">
                                    <p className="thankyoutxtppcrd">
                                        Thank You for your supports and believe. I look forward to networking
                                        and exploring potential opportunities together
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="allcardsectionbg" style={{ cursor: "pointer" }} onClick={handlecardsecond}>
                            <div
                                className="thankyoucrdbg"
                                style={{ backgroundImage: `url(${Happybirthdaycard})` }}>
                                {/* <div className="companylitlleimg">
                                    <img
                                        src="/static/media/companyelite.75eb93cd70e56ebd5043.png"
                                        alt="..."
                                    />
                                </div> */}
                                <div className="happybrthdivmain">
                                    <p className="happytxtcrdpp">Happy</p>
                                    <h4 className="birthtxtcrdpp">Birthday</h4>
                                </div>
                                <div className="profilebgthankywh">
                                    <div className="profilebgthankyimg">
                                        <img
                                            src={avatar}
                                            alt="..."
                                        />
                                    </div>
                                </div>
                                <div className='happybrthdivmain'>
                                    <p className='happybrthdivmaintxtp'>  Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus, deserunt! </p>
                                </div>
                            </div>
                        </div>

                        <div className="allcardsectionbg" style={{ cursor: "pointer" }} onClick={handlecardthird}>
                            <div
                                className="thankyoucrdbg"
                                style={{ backgroundImage: `url(${Onboard})` }}

                            >
                                {/* <div className="companylitlleimg">
                                    <img
                                        src="/static/media/companyelite.75eb93cd70e56ebd5043.png"
                                        alt="..."
                                    />
                                </div> */}
                                <div className="textimagemainwelcmdivflx">
                                    <div className="algnonbrddivmainwh">
                                        <p className="wlcmcrdtxtpp">Welcome Onboard !</p>
                                        
                                        <h4 className='birthtxtcrdpphead'>Raju Acharya</h4>
                                    </div>
                                    <div className="algnonbrddivmainimg">
                                        <div className="profilebgthankywh">
                                            <div className="profilebgthankyimg">
                                                <img
                                                    src={avatar}
                                                    alt="..."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="allcardsectionbg" style={{ cursor: "pointer" }} onClick={handlecardfourth}>
                            <div
                                className="thankyoucrdbg"
                                style={{ backgroundImage: `url(${HappyAnniversary})` }}
                            >
                                {/* <div className="companylitlleimg">
                                    <img
                                        src="/static/media/companyelite.75eb93cd70e56ebd5043.png"
                                        alt="..."
                                    />
                                </div> */}

                                <div className="happyannyversytxtppflx">
                                    <div className="happyannyversyimgmain">
                                        <div className="happyannyversyimg">
                                            <img
                                                src={avatar}
                                                alt="..."
                                            />
                                        </div>
                                    </div>
                                    <div className="txthappyanniversydiv">
                                        <div className="yrscmpltdivmain">
                                            <p className="fiveyrsrtxtpp">5 Years</p>
                                            <p className="fiveyrsrtxtppcmplt">Completion in the family</p>
                                        </div>
                                        <div className="happypritydivtxt">
                                            <p className="happyannyvrtxtpp">Happy Anniversary</p>
                                        </div>
                                        <div className="">
                                            <p className="wishyutxtpgdpp">Wish you a good future ahead</p>
                                            <p className="wishyutxtpgdppsrpp">It's your day, dear.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default ThankYoucardmodal
