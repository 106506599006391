import React, { useEffect, useRef, useState } from 'react'
import AddIcon from "../../Images/Icons/PNG/Add icon.png"
import HttpClientXml from '../../utils/HttpClientXml';
import HttpClient from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';


const Principlefive = ({setPrincicpletabdrp, setTogglesectionmdl }) => {
    // const [text, setText] = useState('');
    const divRef = useRef(null);
    const [principleOneData, setPrincipleOneData] = useState({});

    const initvalue = {
        detials: "",
        e_c_permanent: {
            totalEmployeeOrWorkers: 0,
            totalAssociateEmployeeOrWorkers: 0,
            percentage: ""
        },
        e_c_otherThanPermanent: {
            totalEmployeeOrWorkers: 0,
            totalAssociateEmployeeOrWorkers: 0,
            percentage: ""
        },
        e_c_totalEmployees: {
            totalEmployeeOrWorkers: 0,
            totalAssociateEmployeeOrWorkers: 0,
            percentage: ""
        },


        e_p_permanent: {
            totalEmployeeOrWorkers: 0,
            totalAssociateEmployeeOrWorkers: 0,
            percentage: ""
        },
        e_p_otherThanPermanent: {
            totalEmployeeOrWorkers: 0,
            totalAssociateEmployeeOrWorkers: 0,
            percentage: ""
        },
        e_p_totalEmployees: {
            totalEmployeeOrWorkers: 0,
            totalAssociateEmployeeOrWorkers: 0,
            percentage: ""
        },


        w_c_permanent: {
            totalEmployeeOrWorkers: 0,
            totalAssociateEmployeeOrWorkers: 0,
            percentage: ""
        },
        w_c_otherThanPermanent: {
            totalEmployeeOrWorkers: 0,
            totalAssociateEmployeeOrWorkers: 0,
            percentage: ""
        },
        w_c_totalEmployees: {
            totalEmployeeOrWorkers: 0,
            totalAssociateEmployeeOrWorkers: 0,
            percentage: ""
        },


        w_p_permanent: {
            totalEmployeeOrWorkers: 0,
            totalAssociateEmployeeOrWorkers: 0,
            percentage: ""
        },
        w_p_otherThanPermanent: {
            totalEmployeeOrWorkers: 0,
            totalAssociateEmployeeOrWorkers: 0,
            percentage: ""
        },
        w_p_totalEmployees: {
            totalEmployeeOrWorkers: 0,
            totalAssociateEmployeeOrWorkers: 0,
            percentage: ""
        },

        note: "",

        minimumWagesdetails: "",
        c_e_per_male: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        c_e_per_female: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        c_e_per_others: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },

        c_e_oth_male: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        c_e_oth_female: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        c_e_oth_others: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },

        c_w_per_male: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        c_w_per_female: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        c_w_per_others: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },

        c_w_oth_male: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        c_w_oth_female: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        c_w_oth_others: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },



        p_e_per_male: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        p_e_per_female: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        p_e_per_others: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },

        p_e_oth_male: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        p_e_oth_female: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        p_e_oth_others: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },

        p_w_per_male: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        p_w_per_female: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        p_w_per_others: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },

        p_w_oth_male: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        p_w_oth_female: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },
        p_w_oth_others: {
            total: 0,
            equalToMinimumWage: {
                unit: 0,
                percent: ""
            },
            morethanMinimumWage: {
                unit: 0,
                percent: ""
            }
        },

        detailsOfWagesnote: "",
        impression: "",

        focalPointDetails: {
            focalPointStatus: "Yes",
            details: ""
        },
        describeHumanRightIssue: "",

        c_sexualHarasment: {
            fieldDuringTheYear: "",
            pendingAtTheEndOfTheYear: "",
            remarks: ""
        },
        c_discriminationAtWorkplace: {
            fieldDuringTheYear: "",
            pendingAtTheEndOfTheYear: "",
            remarks: ""
        },
        c_childLabour: {
            fieldDuringTheYear: "",
            pendingAtTheEndOfTheYear: "",
            remarks: ""
        },
        c_forcedLabour: {
            fieldDuringTheYear: "",
            pendingAtTheEndOfTheYear: "",
            remarks: ""
        },
        c_wages: {
            fieldDuringTheYear: "",
            pendingAtTheEndOfTheYear: "",
            remarks: ""
        },
        c_otherThanHumanRights: {
            fieldDuringTheYear: "",
            pendingAtTheEndOfTheYear: "",
            remarks: ""
        },


        p_sexualHarasment: {
            fieldDuringTheYear: "",
            pendingAtTheEndOfTheYear: "",
            remarks: ""
        },
        p_discriminationAtWorkplace: {
            fieldDuringTheYear: "",
            pendingAtTheEndOfTheYear: "",
            remarks: ""
        },
        p_childLabour: {
            fieldDuringTheYear: "",
            pendingAtTheEndOfTheYear: "",
            remarks: ""
        },
        p_forcedLabour: {
            fieldDuringTheYear: "",
            pendingAtTheEndOfTheYear: "",
            remarks: ""
        },
        p_wages: {
            fieldDuringTheYear: "",
            pendingAtTheEndOfTheYear: "",
            remarks: ""
        },
        p_otherThanHumanRights: {
            fieldDuringTheYear: "",
            pendingAtTheEndOfTheYear: "",
            remarks: ""
        },


        totalComplaint: {
            currentYear: 0,
            previousYearYear: 0
        },
        percentOfCOmplaintPose: {
            currentYear: 0,
            previousYearYear: 0
        },
        percentOfCOmplaintPoseUnHelp: {
            currentYear: 0,
            previousYearYear: 0
        },

        mechanismDetails: "", // 8
        humanRightsRequiremeentDetails: { // 9
            humanRightsRequiremeentStatus: "Yes", // [Yes/No]
            details: ""
        },
        assesmentOfTheYear: { // 10
            childLabour: "",
            forcedLabour: "",
            sexualHarasement: "",
            discriminationAtWorkPlace: "",
            wages: "",
            others: ""
        },
        actionTakedDetails: "",

        detailsOfBusinessProcess: "", //1
        dueDiligenceConductedDetails: "", //2
        isPremiseForPWD: "", //3
        chainPartnerAssessmentDetails: { //4
            sexualHarassment: "",
            discriminationAtWorkplace: "",
            childLabour: "",
            forcedLabour: "",
            wages: "",
            others: ""
        },
        correctiveActionDetails: ""
    }

    const initentityDetails = {
        company: "",
        perAnnumFlgs: "",
        category: "",
        male: {
            number: "",
            medianCategory: ""
        },
        female: {
            number: "",
            medianCategory: ""
        }
    }

    const initdirectorsDetails = {
        slNo: "",
        boardOfDirectors: "",
        ammount: ""
    }

    const [formvalue, setformvalue] = useState(initvalue);
    const [entityDetails, setentityDetails] = useState([initentityDetails]);
    const [currentFY, setCurrentFY] = useState("");
    const [previousFY, setPreviousFY] = useState("");
    const [directorsDetails, setdirectorsDetails] = useState([initdirectorsDetails])
    const [notemodal1, setnotemodal1] = useState(false);
    const [notemodal2, setnotemodal2] = useState(false);
    const [notemodal3, setnotemodal3] = useState(false);
    const [notemodal4, setnotemodal4] = useState(false);
    useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const startMonth = 4; // April is the start of the financial year

        if (currentDate.getMonth() + 1 >= startMonth) {
            setCurrentFY(`${currentYear}-${currentYear + 1}`);
            setPreviousFY(`${currentYear - 1}-${currentYear}`);
        } else {
            setCurrentFY(`${currentYear - 1}-${currentYear}`);
            setPreviousFY(`${currentYear - 2}-${currentYear - 1}`);
        }

    }, []);

    useEffect(() => {
        getPrincipleOne();
        // getPdf();
    }, [])

    //for getting principleone
    const getPrincipleOne = async () => {
        const res = await HttpClientXml?.requestData(`get-principle`, {}, "GET");
        if (res?.status) {
            setPrincipleOneData(res?.data?.[0]);
        }
    }

    //for Input fields
    const handleInput = (category, field, value) => {
        setformvalue((prevValue) => ({
            ...prevValue,
            [category]: {
                ...prevValue[category],
                [field]: value,
            }
        }));
    }


    //for input fields
    const handleInputChange = (e, section, year, field) => {
        const value = e.currentTarget.textContent;
        setformvalue(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [year]: {
                    ...prevState[section][year],
                    [field]: value
                }
            }
        }));
    };


    //handle change for entityDetails add more functionality
    const handleChangeentityDetails = (i, e, section, field) => {
        let newFormValues = [...entityDetails];
        newFormValues[i][section][field] = e.currentTarget.textContent;
        setentityDetails(newFormValues);
    };

    //handle change for entityDetails add more functionality
    const handleChangeentity = (i, e, field) => {
        let newFormValues = [...entityDetails];
        newFormValues[i][field] = e.currentTarget.textContent;
        setentityDetails(newFormValues);
    };

    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails = () => {
        setentityDetails([...entityDetails, initentityDetails]);
    };

    //for removing add more field
    // const removeFormFieldsentityDetails = (i) => {
    //     let newFormValues = [...entityDetails];
    //     newFormValues.splice(i, 1);
    //     setentityDetails(newFormValues);
    // };


    //handle change for directorsDetails add more functionality
    const handleChangedirectors = (i, e, field) => {
        let newFormValues = [...directorsDetails];
        newFormValues[i][field] = e.currentTarget.textContent;
        setdirectorsDetails(newFormValues);
    };

    //for directorsDetails add more functionality 
    const addFormFieldsdirectorsDetails = () => {
        setdirectorsDetails([...directorsDetails, initdirectorsDetails]);
    };

    //for removing add more field
    // const removedirectorsDetails = (i) => {
    //     let newFormValues = [...directorsDetails];
    //     newFormValues.splice(i, 1);
    //     setdirectorsDetails(newFormValues);
    // };

    // const getPdf = async () => {
    //     const res = await HttpClient?.requestData(`all-principle-pdf`, {}, "GET");
    //     console.log("downloadurl",res)
    //     if (res?.status) {
    //         // setDownloadurl(res?.data);
    //         downloadPdf(res?.data)
    //     }
    // }

    // console.log("downloadurl",downloadurl)
    // const downloadPdf = (downloadurl) => {
    //     const link = document.createElement('a');
    //     link.href = downloadurl;
    //     link.download = 'all.pdf';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    const submitHandler = async (e) => {
        e.preventDefault();
        let data = {};
        data = {
            "principleFive": {
                "currentYear": currentFY,
                "previousYear": previousFY,
                "essentialIndicators": {
                    "trainingOfHumanRightsDetails": { // 1
                        "detials": formvalue?.detials,
                        "employees": {
                            "currentYear": {
                                "permanent": {
                                    "totalEmployeeOrWorkers": +formvalue?.e_c_permanent?.totalEmployeeOrWorkers,
                                    "totalAssociateEmployeeOrWorkers": +formvalue?.e_c_permanent?.totalAssociateEmployeeOrWorkers,
                                    "percentage": formvalue?.e_c_permanent?.percentage
                                },
                                "otherThanPermanent": {
                                    "totalEmployeeOrWorkers": +formvalue?.e_c_otherThanPermanent?.totalEmployeeOrWorkers,
                                    "totalAssociateEmployeeOrWorkers": +formvalue?.e_c_otherThanPermanent?.totalAssociateEmployeeOrWorkers,
                                    "percentage": formvalue?.e_c_otherThanPermanent?.percentage
                                },
                                "totalEmployees": {
                                    "totalEmployeeOrWorkers": +formvalue?.e_c_totalEmployees?.totalEmployeeOrWorkers,
                                    "totalAssociateEmployeeOrWorkers": +formvalue?.e_c_totalEmployees?.totalAssociateEmployeeOrWorkers,
                                    "percentage": formvalue?.e_c_totalEmployees?.percentage
                                }
                            },
                            "previousYear": {
                                "permanent": {
                                    "totalEmployeeOrWorkers": +formvalue?.e_p_permanent?.totalEmployeeOrWorkers,
                                    "totalAssociateEmployeeOrWorkers": +formvalue?.e_p_permanent?.totalAssociateEmployeeOrWorkers,
                                    "percentage": formvalue?.e_p_permanent?.percentage
                                },
                                "otherThanPermanent": {
                                    "totalEmployeeOrWorkers": +formvalue?.e_p_otherThanPermanent?.totalEmployeeOrWorkers,
                                    "totalAssociateEmployeeOrWorkers": +formvalue?.e_p_otherThanPermanent?.totalAssociateEmployeeOrWorkers,
                                    "percentage": formvalue?.e_p_otherThanPermanent?.percentage
                                },
                                "totalEmployees": {
                                    "totalEmployeeOrWorkers": +formvalue?.e_p_totalEmployees?.totalEmployeeOrWorkers,
                                    "totalAssociateEmployeeOrWorkers": +formvalue?.e_p_totalEmployees?.totalAssociateEmployeeOrWorkers,
                                    "percentage": formvalue?.e_p_totalEmployees?.percentage
                                }
                            }
                        },
                        "workers": {
                            "currentYear": {
                                "permanent": {
                                    "totalEmployeeOrWorkers": +formvalue?.w_c_permanent?.totalEmployeeOrWorkers,
                                    "totalAssociateEmployeeOrWorkers": +formvalue?.w_c_permanent?.totalAssociateEmployeeOrWorkers,
                                    "percentage": formvalue?.w_c_permanent?.percentage
                                },
                                "otherThanPermanent": {
                                    "totalEmployeeOrWorkers": +formvalue?.w_c_otherThanPermanent?.totalEmployeeOrWorkers,
                                    "totalAssociateEmployeeOrWorkers": +formvalue?.w_c_otherThanPermanent?.totalAssociateEmployeeOrWorkers,
                                    "percentage": formvalue?.w_c_otherThanPermanent?.percentage
                                },
                                "totalEmployees": {
                                    "totalEmployeeOrWorkers": +formvalue?.w_c_totalEmployees?.totalEmployeeOrWorkers,
                                    "totalAssociateEmployeeOrWorkers": +formvalue?.w_c_totalEmployees?.totalAssociateEmployeeOrWorkers,
                                    "percentage": formvalue?.w_c_totalEmployees?.percentage
                                }
                            },
                            "previousYear": {
                                "permanent": {
                                    "totalEmployeeOrWorkers": +formvalue?.w_p_permanent?.totalEmployeeOrWorkers,
                                    "totalAssociateEmployeeOrWorkers": +formvalue?.w_p_permanent?.totalAssociateEmployeeOrWorkers,
                                    "percentage": formvalue?.w_p_permanent?.percentage
                                },
                                "otherThanPermanent": {
                                    "totalEmployeeOrWorkers": +formvalue?.w_p_otherThanPermanent?.totalEmployeeOrWorkers,
                                    "totalAssociateEmployeeOrWorkers": +formvalue?.w_p_otherThanPermanent?.totalAssociateEmployeeOrWorkers,
                                    "percentage": formvalue?.w_p_otherThanPermanent?.percentage
                                },
                                "totalEmployees": {
                                    "totalEmployeeOrWorkers": +formvalue?.w_p_totalEmployees?.totalEmployeeOrWorkers,
                                    "totalAssociateEmployeeOrWorkers": +formvalue?.w_p_totalEmployees?.totalAssociateEmployeeOrWorkers,
                                    "percentage": formvalue?.w_p_totalEmployees?.percentage
                                }
                            }
                        },
                        "note": formvalue?.note
                    },
                    "minimumWagesPaidDetails": { // 2
                        "details": formvalue?.minimumWagesdetails,
                        "currentYear": {
                            "employee": {
                                "permanent": {
                                    "male": {
                                        "total": +formvalue?.c_e_per_male?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.c_e_per_male?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.c_e_per_male?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.c_e_per_male?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.c_e_per_male?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "female": {
                                        "total": +formvalue?.c_e_per_female?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.c_e_per_female?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.c_e_per_female?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.c_e_per_female?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.c_e_per_female?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "others": {
                                        "total": +formvalue?.c_e_per_others?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.c_e_per_others?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.c_e_per_others?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.c_e_per_others?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.c_e_per_others?.morethanMinimumWage?.percent
                                        }
                                    }
                                },
                                "otherThanPermanent": {
                                    "male": {
                                        "total": +formvalue?.c_e_oth_male?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.c_e_oth_male?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.c_e_oth_male?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.c_e_oth_male?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.c_e_oth_male?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "female": {
                                        "total": +formvalue?.c_e_oth_female?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.c_e_oth_female?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.c_e_oth_female?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.c_e_oth_female?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.c_e_oth_female?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "others": {
                                        "total": +formvalue?.c_e_oth_others?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.c_e_oth_others?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.c_e_oth_others?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.c_e_oth_others?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.c_e_oth_others?.morethanMinimumWage?.percent
                                        }
                                    }
                                }
                            },
                            "workers": {
                                "permanent": {
                                    "male": {
                                        "total": +formvalue?.c_w_per_male?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.c_w_per_male?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.c_w_per_male?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.c_w_per_male?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.c_w_per_male?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "female": {
                                        "total": +formvalue?.c_w_per_female?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.c_w_per_female?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.c_w_per_female?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.c_w_per_female?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.c_w_per_female?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "others": {
                                        "total": +formvalue?.c_w_per_others?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.c_w_per_others?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.c_w_per_others?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.c_w_per_others?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.c_w_per_others?.morethanMinimumWage?.percent
                                        }
                                    }
                                },
                                "otherThanPermanent": {
                                    "male": {
                                        "total": +formvalue?.c_w_oth_male?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.c_w_oth_male?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.c_w_oth_male?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.c_w_oth_male?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.c_w_oth_male?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "female": {
                                        "total": +formvalue?.c_w_oth_female?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.c_w_oth_female?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.c_w_oth_female?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.c_w_oth_female?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.c_w_oth_female?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "others": {
                                        "total": +formvalue?.c_w_oth_others?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.c_w_oth_others?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.c_w_oth_others?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.c_w_oth_others?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.c_w_oth_others?.morethanMinimumWage?.percent
                                        }
                                    }
                                }
                            }
                        },
                        "previousYear": {
                            "employee": {
                                "permanent": {
                                    "male": {
                                        "total": +formvalue?.p_e_per_male?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.p_e_per_male?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.p_e_per_male?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.p_e_per_male?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.p_e_per_male?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "female": {
                                        "total": +formvalue?.p_e_per_female?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.p_e_per_female?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.p_e_per_female?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.p_e_per_female?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.p_e_per_female?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "others": {
                                        "total": +formvalue?.p_e_per_others?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.p_e_per_others?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.p_e_per_others?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.p_e_per_others?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.p_e_per_others?.morethanMinimumWage?.percent
                                        }
                                    }
                                },
                                "otherThanPermanent": {
                                    "male": {
                                        "total": +formvalue?.p_e_oth_male?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.p_e_oth_male?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.p_e_oth_male?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.p_e_oth_male?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.p_e_oth_male?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "female": {
                                        "total": +formvalue?.p_e_oth_female?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.p_e_oth_female?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.p_e_oth_female?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.p_e_oth_female?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.p_e_oth_female?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "others": {
                                        "total": +formvalue?.p_e_oth_others?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.p_e_oth_others?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.p_e_oth_others?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.p_e_oth_others?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.p_e_oth_others?.morethanMinimumWage?.percent
                                        }
                                    }
                                }
                            },
                            "workers": {
                                "permanent": {
                                    "male": {
                                        "total": +formvalue?.p_w_per_male?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.p_w_per_male?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.p_w_per_male?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.p_w_per_male?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.p_w_per_male?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "female": {
                                        "total": +formvalue?.p_w_per_female?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.p_w_per_female?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.p_w_per_female?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.p_w_per_female?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.p_w_per_female?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "others": {
                                        "total": +formvalue?.p_w_per_others?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.p_w_per_others?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.p_w_per_others?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.p_w_per_others?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.p_w_per_others?.morethanMinimumWage?.percent
                                        }
                                    }
                                },
                                "otherThanPermanent": {
                                    "male": {
                                        "total": +formvalue?.p_w_oth_male?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.p_w_oth_male?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.p_w_oth_male?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.p_w_oth_male?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.p_w_oth_male?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "female": {
                                        "total": +formvalue?.p_w_oth_female?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.p_w_oth_female?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.p_w_oth_female?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.p_w_oth_female?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.p_w_oth_female?.morethanMinimumWage?.percent
                                        }
                                    },
                                    "others": {
                                        "total": +formvalue?.p_w_oth_others?.total,
                                        "equalToMinimumWage": {
                                            "unit": +formvalue?.p_w_oth_others?.equalToMinimumWage?.unit,
                                            "percent": formvalue?.p_w_oth_others?.equalToMinimumWage?.percent
                                        },
                                        "morethanMinimumWage": {
                                            "unit": +formvalue?.p_w_oth_others?.morethanMinimumWage?.unit,
                                            "percent": formvalue?.p_w_oth_others?.morethanMinimumWage?.percent
                                        }
                                    }
                                }
                            }
                        }
                    },
                    "detailsOfWages": { //3
                        "entityDetails": entityDetails,
                        "note": formvalue?.detailsOfWagesnote,
                        "directorsDetails": directorsDetails,
                        "impression": formvalue?.impression
                    },
                    "focalPointDetails": {
                        "focalPointStatus": formvalue?.focalPointDetails?.focalPointStatus, // [Yes/No]
                        "details": formvalue?.focalPointDetails?.details
                    }, //4
                    "describeHumanRightIssue": formvalue?.describeHumanRightIssue,//5
                    "complaintDetails": { //6
                        "currentYear": {
                            "sexualHarasment": {
                                "fieldDuringTheYear": formvalue?.c_sexualHarasment?.fieldDuringTheYear,
                                "pendingAtTheEndOfTheYear": formvalue?.c_sexualHarasment?.pendingAtTheEndOfTheYear,
                                "remarks": formvalue?.c_sexualHarasment?.remarks
                            },
                            "discriminationAtWorkplace": {
                                "fieldDuringTheYear": formvalue?.c_discriminationAtWorkplace?.fieldDuringTheYear,
                                "pendingAtTheEndOfTheYear": formvalue?.c_discriminationAtWorkplace?.pendingAtTheEndOfTheYear,
                                "remarks": formvalue?.c_discriminationAtWorkplace?.remarks
                            },
                            "childLabour": {
                                "fieldDuringTheYear": formvalue?.c_childLabour?.fieldDuringTheYear,
                                "pendingAtTheEndOfTheYear": formvalue?.c_childLabour?.pendingAtTheEndOfTheYear,
                                "remarks": formvalue?.c_childLabour?.remarks
                            },
                            "forcedLabour": {
                                "fieldDuringTheYear": formvalue?.c_forcedLabour?.fieldDuringTheYear,
                                "pendingAtTheEndOfTheYear": formvalue?.c_forcedLabour?.pendingAtTheEndOfTheYear,
                                "remarks": formvalue?.c_forcedLabour?.remarks
                            },
                            "wages": {
                                "fieldDuringTheYear": formvalue?.c_wages?.fieldDuringTheYear,
                                "pendingAtTheEndOfTheYear": formvalue?.c_wages?.pendingAtTheEndOfTheYear,
                                "remarks": formvalue?.c_wages?.remarks
                            },
                            "otherThanHumanRights": {
                                "fieldDuringTheYear": formvalue?.c_otherThanHumanRights?.fieldDuringTheYear,
                                "pendingAtTheEndOfTheYear": formvalue?.c_otherThanHumanRights?.pendingAtTheEndOfTheYear,
                                "remarks": formvalue?.c_otherThanHumanRights?.remarks
                            }
                        },
                        "previousYear": {
                            "sexualHarasment": {
                                "fieldDuringTheYear": formvalue?.p_sexualHarasment?.fieldDuringTheYear,
                                "pendingAtTheEndOfTheYear": formvalue?.p_sexualHarasment?.pendingAtTheEndOfTheYear,
                                "remarks": formvalue?.p_sexualHarasment?.remarks
                            },
                            "discriminationAtWorkplace": {
                                "fieldDuringTheYear": formvalue?.p_discriminationAtWorkplace?.fieldDuringTheYear,
                                "pendingAtTheEndOfTheYear": formvalue?.p_discriminationAtWorkplace?.pendingAtTheEndOfTheYear,
                                "remarks": formvalue?.p_discriminationAtWorkplace?.remarks
                            },
                            "childLabour": {
                                "fieldDuringTheYear": formvalue?.p_childLabour?.fieldDuringTheYear,
                                "pendingAtTheEndOfTheYear": formvalue?.p_childLabour?.pendingAtTheEndOfTheYear,
                                "remarks": formvalue?.p_childLabour?.remarks
                            },
                            "forcedLabour": {
                                "fieldDuringTheYear": formvalue?.p_forcedLabour?.fieldDuringTheYear,
                                "pendingAtTheEndOfTheYear": formvalue?.p_forcedLabour?.pendingAtTheEndOfTheYear,
                                "remarks": formvalue?.p_forcedLabour?.remarks
                            },
                            "wages": {
                                "fieldDuringTheYear": formvalue?.p_wages?.fieldDuringTheYear,
                                "pendingAtTheEndOfTheYear": formvalue?.p_wages?.pendingAtTheEndOfTheYear,
                                "remarks": formvalue?.p_wages?.remarks
                            },
                            "otherThanHumanRights": {
                                "fieldDuringTheYear": formvalue?.p_otherThanHumanRights?.fieldDuringTheYear,
                                "pendingAtTheEndOfTheYear": formvalue?.p_otherThanHumanRights?.pendingAtTheEndOfTheYear,
                                "remarks": formvalue?.p_otherThanHumanRights?.remarks
                            }
                        }
                    },
                    "womenComplaintDetails": { //7
                        "totalComplaint": {
                            "currentYear": formvalue?.totalComplaint?.currentYear,
                            "previousYearYear": formvalue?.totalComplaint?.previousYearYear
                        },
                        "percentOfCOmplaintPose": {
                            "currentYear": formvalue?.percentOfCOmplaintPose?.currentYear,
                            "previousYearYear": formvalue?.percentOfCOmplaintPose?.previousYearYear
                        },
                        "percentOfCOmplaintPoseUnHelp": {
                            "currentYear": formvalue?.percentOfCOmplaintPoseUnHelp?.currentYear,
                            "previousYearYear": formvalue?.percentOfCOmplaintPoseUnHelp?.previousYearYear
                        }
                    },
                    "mechanismDetails": formvalue?.mechanismDetails, // 8
                    "humanRightsRequiremeentDetails": { // 9
                        "humanRightsRequiremeentStatus": formvalue?.humanRightsRequiremeentDetails?.humanRightsRequiremeentStatus, // [Yes/No]
                        "details": formvalue?.humanRightsRequiremeentDetails?.details
                    },
                    "assesmentOfTheYear": { // 10
                        "childLabour": formvalue?.assesmentOfTheYear?.childLabour,
                        "forcedLabour": formvalue?.assesmentOfTheYear?.forcedLabour,
                        "sexualHarasement": formvalue?.assesmentOfTheYear?.sexualHarasement,
                        "discriminationAtWorkPlace": formvalue?.assesmentOfTheYear?.discriminationAtWorkPlace,
                        "wages": formvalue?.assesmentOfTheYear?.wages,
                        "others": formvalue?.assesmentOfTheYear?.others
                    },
                    "actionTakedDetails": formvalue?.actionTakedDetails//11
                },
                "leadershipIndicators": {
                    "detailsOfBusinessProcess": formvalue?.detailsOfBusinessProcess, //1
                    "dueDiligenceConductedDetails": formvalue?.dueDiligenceConductedDetails, //2
                    "isPremiseForPWD": formvalue?.isPremiseForPWD, //3
                    "chainPartnerAssessmentDetails": { //4
                        "sexualHarassment": formvalue?.chainPartnerAssessmentDetails?.sexualHarassment,
                        "discriminationAtWorkplace": formvalue?.chainPartnerAssessmentDetails?.discriminationAtWorkplace,
                        "childLabour": formvalue?.chainPartnerAssessmentDetails?.childLabour,
                        "forcedLabour": formvalue?.chainPartnerAssessmentDetails?.forcedLabour,
                        "wages": formvalue?.chainPartnerAssessmentDetails?.wages,
                        "others": formvalue?.chainPartnerAssessmentDetails?.others
                    },
                    "correctiveActionDetails": formvalue?.correctiveActionDetails //5
                }
            }
        }
        console.log('principlefive', data);
        const res = await HttpClient?.requestData(
            `update-enviroment-principle/${principleOneData?._id}`,
            data,
            "POST"
        );
        if (res?.status) {
            toast?.success("data is added successfully");
            setformvalue(initvalue);
            const contentEditableElements = document.querySelectorAll('[contentEditable]');
            contentEditableElements?.forEach(element => element.textContent = "");
            setentityDetails([initentityDetails])
            setdirectorsDetails([initdirectorsDetails])
            setPrincicpletabdrp(6);
            setTogglesectionmdl(true)
            // getPdf();
        } else {
            toast?.error(res?.response?.data?.message || "Something went wrong");
        }
    }

    return (
        <>
            <div className='' >
                <h4 className='principletxtpp'>
                    PRINCIPLE 5 Businesses should respect and promote human rights
                </h4>
                <div className='borderessentialin'>
                    <p className='idicatrstxtpp'> Essential Indicators</p>
                    <div className=''>
                        <ul className='listoftblxsdivul'>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Employees and workers who have been provided training on human rights issues and
                                    policy(ies) of the entity, in the following format:

                                </li>

                                <div className="clsfrextarpading">
                                    <p className="noiriittxoppdjoyyypp">
                                        Details :</p>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                detials: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>

                                <table className='tablbrdrmain mb-2'>
                                    <tr className='trdivmainbrdr'>
                                        <th className=''>

                                        </th>
                                        <th colspan="3" className='thdivmainbrdr'>
                                            <p className='prcnttxttemplytxtpp'>
                                                FY {currentFY}
                                                (Current Financial
                                                Year)
                                            </p>
                                        </th>
                                        <th colspan="3" className='thdivmainbrdr'>
                                            <p className='prcnttxttemplytxtpp'>FY {previousFY}
                                                (Previous Financial Year)
                                            </p>
                                        </th>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <th className='jsgdjgcarttegdijjdivfrst'>
                                            <p className='cttsggrttxtpppc'>
                                                Category
                                            </p>
                                        </th>
                                        <th colspan="1" className='thdivmainbrdr jsgdjgcarttegdijjdivscnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Total
                                                employees /
                                                workers in
                                                respective
                                                category
                                                (A)
                                            </p>
                                        </th>
                                        <th colspan="1" className='thdivmainbrdr jsgdjgcarttegdijjdivthrd' >
                                            <p className='mnumbbrttprinvthreetxttppp'> No. of employees /
                                                workers in
                                                respective
                                                category, who are
                                                part of
                                                association(s) or
                                                Union
                                                (B)
                                            </p>

                                        </th>
                                        <th colspan="1" className='thdivmainbrdr jsgdjgcarttegdijjdivfrth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>  % (B / A)</p>

                                        </th>
                                        <th colspan="1" className='thdivmainbrdr jsgdjgcarttegdijjdivsxth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>  Total
                                                employees
                                                / workers
                                                in
                                                respective
                                                category
                                                (A)
                                            </p>
                                        </th>
                                        <th colspan="1" className='thdivmainbrdr jsgdjgcarttegdijjdivsvnth' >
                                            <p className='mnumbbrttprinvthreetxttppp'> No. of
                                                employees /
                                                workers in
                                                respective
                                                category, who
                                                are part of
                                                association(s)
                                                or Union
                                                (B)
                                            </p>
                                        </th>
                                        <th colspan="1" className='thdivmainbrdr jsgdjgcarttegdijjdivegth'>
                                            <p className='mnumbbrttprinvthreetxttppp'> % (B /
                                                A)
                                            </p>

                                        </th>

                                    </tr>

                                    <tr className='gap'>
                                        <th className='thdivmainbrdr' colSpan="12">
                                            <p className='simlettxghjpemidjjlklettxp'>
                                                Employees
                                            </p>
                                        </th>
                                    </tr>
                                    {/* <p className=''>
                                        Employees
                                    </p> */}

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Permanent
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_c_permanent', 'totalEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_c_permanent', 'totalAssociateEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_c_permanent', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_p_permanent', 'totalEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_p_permanent', 'totalAssociateEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_p_permanent', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Other than
                                                permanent
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_c_otherThanPermanent', 'totalEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_c_otherThanPermanent', 'totalAssociateEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_c_otherThanPermanent', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_p_otherThanPermanent', 'totalEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_p_otherThanPermanent', 'totalAssociateEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_p_otherThanPermanent', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtppttlyd'>
                                                Total Employees
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_c_totalEmployees', 'totalEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_c_totalEmployees', 'totalAssociateEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_c_totalEmployees', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_p_totalEmployees', 'totalEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_p_totalEmployees', 'totalAssociateEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('e_p_totalEmployees', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>


                                    <tr className='gap'>
                                        <th className='thdivmainbrdr' colSpan="12">
                                            <p className='simlettxghjpemidjjlklettxp'>
                                                Workers
                                            </p>
                                        </th>
                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Permanent
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_c_permanent', 'totalEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_c_permanent', 'totalAssociateEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_c_permanent', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_p_permanent', 'totalEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_p_permanent', 'totalAssociateEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_p_permanent', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Other than
                                                permanent</p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_c_otherThanPermanent', 'totalEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_c_otherThanPermanent', 'totalAssociateEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_c_otherThanPermanent', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_p_otherThanPermanent', 'totalEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_p_otherThanPermanent', 'totalAssociateEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_p_otherThanPermanent', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className='thdivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtppttlyd'>
                                                Total Workers
                                            </p>
                                        </th>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_c_totalEmployees', 'totalEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_c_totalEmployees', 'totalAssociateEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_c_totalEmployees', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_p_totalEmployees', 'totalEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_p_totalEmployees', 'totalAssociateEmployeeOrWorkers', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('w_p_totalEmployees', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                </table>

                                <div class="rggsgttxbbedxgtsprijnd">
                                    <p class="notetgvxtygfspopphjhsttxp " style={{ cursor: "pointer" }} onClick={() => setnotemodal1(!notemodal1)}>Note </p>
                                    {notemodal1 &&
                                        <div className='yestrxtwarea mt-3 mb-0'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        note: e.currentTarget.textContent
                                                    });
                                                }}
                                            >
                                            </div>
                                        </div>}
                                </div>
                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Details of minimum wages paid to employees and workers, in the following format:
                                </li>

                                <table className='tablbrdrmain mb-2'>
                                    <tr className='trdivmainbrdr'>
                                        <th className=''>

                                        </th>
                                        <th colspan="5" className='thdivmainbrdr'>
                                            <p className='prcnttxttemplytxtpp'>FY {currentFY}
                                                (Current Financial
                                                Year)
                                            </p>
                                        </th>
                                        <th colspan="5" className='thdivmainbrdr' >
                                            <p className='prcnttxttemplytxtpp'>FY {previousFY}
                                                (Previous Financial Year)
                                            </p>
                                        </th>
                                    </tr>
                                    <tr className='trdivmainbrdr'>
                                        <th className=' categjhggsprinciplesvndvfrst' >
                                            <p className='cttsggrttxtpppc'>
                                                Category
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr categjhggsprinciplesvndvscnd' >
                                            <p className='totyauttxttppttxpa'>
                                                Total
                                                (A)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr categjhggsprinciplesvndvtrhrd' colSpan="2">
                                            <p className='prcnttxttemplytxtpp'>
                                                Equal to
                                                Minimum
                                                Wage
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr categjhggsprinciplesvndvfrth' colSpan="2">
                                            <p className='prcnttxttemplytxtpp'>More than
                                                Minimum
                                                Wage
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr categjhggsprinciplesvndvffth'>
                                            <p className='totyauttxttppttxpa'>
                                                Total
                                                (D)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr categjhggsprinciplesvndvsxth' colSpan="2">
                                            <p className='prcnttxttemplytxtpp'>
                                                Equal to
                                                Minimum
                                                Wage
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr categjhggsprinciplesvndvsvnth' colSpan="2">
                                            <p className='prcnttxttemplytxtpp'>More than
                                                Minimum
                                                Wage
                                            </p>
                                        </th>

                                    </tr>
                                    <tr className='trdivmainbrdr'>
                                        <th className='' >
                                        </th>
                                        <th className='thdivmainbrdr' >
                                        </th>
                                        <th className='thdivmainbrdr categjhggsprinciplesvndvegth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                No.
                                                (B)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr categjhggsprinciplesvndvnnnth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>% (B
                                                / A)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr categjhggsprinciplesvndvntenth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>No.
                                                (C)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr categjhggsprinciplesvndvnelevnth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>% (C /
                                                A)
                                            </p>

                                        </th>
                                        <th className='thdivmainbrdr'  >
                                        </th>
                                        <th className='thdivmainbrdr categjhggsprinciplesvndvnelthrtn' >
                                            <p className='mnumbbrttprinvthreetxttppp'>No.
                                                (E)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr categjhggsprinciplesvndvnelfrteen'>
                                            <p className='mnumbbrttprinvthreetxttppp'>% (E
                                                / D)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr categjhggsprinciplesvndvnelffteen'>
                                            <p className='mnumbbrttprinvthreetxttppp'>No. (F)</p>
                                        </th>
                                        <th className='thdivmainbrdr categjhggsprinciplesvndvnelsixtin'>
                                            <p className='mnumbbrttprinvthreetxttppp'>% (F / D)</p>
                                        </th>

                                    </tr>

                                    <tr className='gap'>
                                        <th className='thdivmainbrdr' colSpan="12">
                                            <p className='simlettxghjpemidjjlklettxp'>
                                                Employees
                                            </p>
                                        </th>
                                    </tr>

                                    <tr className='gap'>
                                        <th className='thdivmainbrdr' colSpan="12">
                                            <p className='simlettxghjpemidjjlklettxp'>
                                                Permanent
                                            </p>
                                        </th>
                                    </tr>



                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Male</p>

                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_e_per_male', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_per_male', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_per_male', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_per_male', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_per_male', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>



                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_e_per_male', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_per_male', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_per_male', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_per_male', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_per_male', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Female</p>

                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_e_per_female', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_per_female', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_per_female', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_per_female', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_per_female', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>



                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_e_per_female', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_per_female', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_per_female', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_per_female', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_per_female', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Others</p>

                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_e_per_others', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_per_others', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_per_others', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_per_others', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_per_others', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>



                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_e_per_others', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_per_others', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_per_others', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_per_others', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_per_others', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className='gap'>
                                        <th className='thdivmainbrdr' colSpan="12">
                                            <p className='simlettxghjpemidjjlklettxp'>
                                                Other than
                                                Permanent
                                            </p>
                                        </th>
                                    </tr>



                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Male</p>

                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_e_oth_male', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_oth_male', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_oth_male', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_oth_male', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_oth_male', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>



                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_e_oth_male', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_oth_male', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_oth_male', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_oth_male', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_oth_male', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Female</p>

                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_e_oth_female', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_oth_female', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_oth_female', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_oth_female', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_oth_female', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>



                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_e_oth_female', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_oth_female', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_oth_female', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_oth_female', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_oth_female', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Others</p>

                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_e_oth_others', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_oth_others', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_oth_others', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_oth_others', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_e_oth_others', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>



                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_e_oth_others', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_oth_others', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_oth_others', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_oth_others', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_e_oth_others', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className='gap'>
                                        <th className='thdivmainbrdr' colSpan="12">
                                            <p className='simlettxghjpemidjjlklettxp'>
                                                Workers
                                            </p>
                                        </th>
                                    </tr>

                                    <tr className='gap'>
                                        <th className='thdivmainbrdr' colSpan="12">
                                            <p className='simlettxghjpemidjjlklettxp'>
                                                Permanent
                                            </p>
                                        </th>
                                    </tr>



                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Male</p>

                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_w_per_male', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_per_male', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_per_male', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_per_male', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_per_male', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>



                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_w_per_male', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_per_male', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_per_male', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_per_male', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_per_male', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Female</p>

                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_w_per_female', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_per_female', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_per_female', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_per_female', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_per_female', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>



                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_w_per_female', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_per_female', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_per_female', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_per_female', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_per_female', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Others</p>

                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_w_per_others', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_per_others', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_per_others', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_per_others', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_per_others', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>



                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_w_per_others', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_per_others', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_per_others', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_per_others', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_per_others', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className='gap'>
                                        <th className='thdivmainbrdr' colSpan="12">
                                            <p className='simlettxghjpemidjjlklettxp'>
                                                Other than
                                                Permanent
                                            </p>
                                        </th>
                                    </tr>



                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Male</p>

                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_w_oth_male', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_oth_male', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_oth_male', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_oth_male', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_oth_male', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>



                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_w_oth_male', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_oth_male', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_oth_male', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_oth_male', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_oth_male', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Female</p>

                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_w_oth_female', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_oth_female', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_oth_female', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_oth_female', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_oth_female', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>



                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_w_oth_female', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_oth_female', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_oth_female', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_oth_female', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_oth_female', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Others</p>

                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_w_oth_others', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_oth_others', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_oth_others', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_oth_others', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'c_w_oth_others', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>



                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_w_oth_others', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_oth_others', 'equalToMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_oth_others', 'equalToMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_oth_others', 'morethanMinimumWage', 'unit')}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'p_w_oth_others', 'morethanMinimumWage', 'percent')}
                                            >
                                            </div>
                                        </td>
                                    </tr>



                                </table>


                                <div class="rggsgttxbbedxgtsprijnd">
                                    <p class="notetgvxtygfspopphjhsttxp " style={{ cursor: "pointer" }} onClick={() => setnotemodal2(!notemodal2)}>Note </p>
                                    {notemodal2 &&
                                        <div className='yestrxtwarea mt-3 mb-0'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        note: e.currentTarget.textContent
                                                    });
                                                }}
                                            >
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            {/* 3no.point */}
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Details of remuneration/ salary/ wages, in the following format:
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className=''>

                                        </th>

                                        <th className=''>

                                        </th>
                                        <th className=''>

                                        </th>
                                        <th colspan="2" className='thdivmainbrdr '>
                                            <p className='prcnttxttemplytxtpp'>Male</p>
                                        </th>
                                        <th colspan="2" className='thdivmainbrdr '>
                                            <p className='prcnttxttemplytxtpp'>Female</p>
                                        </th>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr principlefiepointthredivfrst'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Company
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr principlefiepointthredivscnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'> Per annum
                                                Figs in.
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr principlefiepointthredivthrd'>
                                            <p className='mnumbbrttprinvthreetxttppp'> Category
                                            </p>
                                        </th>


                                        <th colspan="1" className='thdivmainbrdr principlefiepointthredivfrth'>
                                            <p className='mnumbbrttprinvthreetxttppp'> Number</p>

                                        </th>
                                        <th colspan="1" className='thdivmainbrdr principlefiepointthredivsffth' >
                                            <p className='mnumbbrttprinvthreetxttppp'> Median remuneration/ salary/
                                                wages of respective category
                                            </p>
                                        </th>

                                        <th colspan="1" className='thdivmainbrdr principlefiepointthredivssxth'>
                                            <p className='mnumbbrttprinvthreetxttppp'> Number</p>

                                        </th>
                                        <th colspan="1" className='thdivmainbrdr principlefiepointthredivsvnth' >
                                            <p className='mnumbbrttprinvthreetxttppp'> Median remuneration/
                                                salary/ wages of
                                                respective category
                                            </p>
                                        </th>
                                    </tr>

                                    {
                                        entityDetails?.map((item, ind) => {
                                            return (
                                                <tr>
                                                    <td className='tddivmainbrdr'>
                                                        {/* Board of Directors
                (BoD) */}
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "company")}
                                                        >
                                                        </div>

                                                    </td>

                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "perAnnumFlgs")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "category")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentityDetails(ind, e, "male", "number")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentityDetails(ind, e, "male", "medianCategory")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentityDetails(ind, e, "female", "number")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentityDetails(ind, e, "female", "medianCategory")}
                                                        >
                                                        </div>
                                                    </td>



                                                </tr>
                                            )
                                        })
                                    }




                                </table>

                                {/* <div className="yestrxtwarea">
                                    <p className="dtlsttfvdvgppphjp mt-2 mb-2" onClick={() => setnotemodal3(!notemodal3)}>Note :</p>

                                    {notemodal3 && <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                detailsOfWagesnote: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>}
                                </div> */}

                                <div className='notedivadmoredivflx'>
                                    <div className=''>
                                        <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal3(!notemodal3)}> Note  </p>
                                    </div>

                                    <button className='adddinciondivimg' onClick={addFormFieldsentityDetails}>
                                        <img src={AddIcon} alt='...' />
                                        <span>Add More</span>
                                    </button>
                                </div>

                                <div className="yestrxtwarea">
                                    {notemodal3 &&
                                        <div className="yestrxtwarea mt-2 mb-0">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        detailsOfWagesnote: e.currentTarget.textContent
                                                    });
                                                }}
                                            >
                                            </div>
                                        </div>}
                                </div>



                                <table className='tablbrdrmain mt-3'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr priclioledfivetheeedivfrst'>
                                            <p className='prcnttxttemplytxtpp'>Sl No.</p>
                                        </th>
                                        <th className='thdivmainbrdr priclioledfivetheeedivscnd '>
                                            <p className='prcnttxttemplytxtpp'>Board of Directors</p>
                                        </th>
                                        <th className='thdivmainbrdr priclioledfivetheeedivthrd'>
                                            <p className='prcnttxttemplytxtpp'> Amount (in ₹)</p>
                                        </th>
                                    </tr>

                                    {
                                        directorsDetails?.map((item, ind) => {
                                            return (
                                                <tr className='trdivmainbrdr'>
                                                    <td className='tddivmainbrdr '>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangedirectors(ind, e, "slNo")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr '>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangedirectors(ind, e, "boardOfDirectors")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr '>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangedirectors(ind, e, "ammount")}
                                                        >
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </table>

                                {/* For tr addition */}
                                <div className='adddinciondivimg'>
                                    <img src={AddIcon} alt='...' onClick={addFormFieldsdirectorsDetails} />
                                    <span>Add More</span>
                                </div>

                                <div className="yestrxtwarea">
                                    <p className="dtlsttfvdvgppphjp mb-2">Note :</p>

                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                impression: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>

                            </div>

                            {/* 4no.point */}

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Do you have a focal point <span> (Individual/ Committee)  </span>  responsible for addressing human
                                    rights impacts or issues caused or contributed to by the business?
                                </li>

                                <div className="yesnobtndivmain">
                                    <div className="raditrbtntxt">
                                        <input type="radio"
                                            value="Yes"
                                            checked={formvalue?.focalPointDetails?.focalPointStatus === "Yes"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    focalPointDetails: {
                                                        ...formvalue.focalPointDetails,
                                                        focalPointStatus: "Yes"
                                                    }
                                                });
                                            }}
                                        />
                                        <label>Yes</label>
                                    </div>
                                    <div className="raditrbtntxt">
                                        <input type="radio"
                                            value="No"
                                            checked={formvalue?.focalPointDetails?.focalPointStatus === "No"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    focalPointDetails: {
                                                        ...formvalue.focalPointDetails,
                                                        focalPointStatus: "No"
                                                    }
                                                });
                                            }}
                                        />
                                        <label>No</label>
                                    </div>
                                </div>
                                <div class="yestrxtwarea">
                                    <p class="dtlsttfvdvgppphjp mb-2">
                                        Details :
                                    </p>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('focalPointDetails', 'details', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>

                            </div>

                            {/* 5no.point */}

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Describe the internal mechanisms in place to redress grievances related to human rights issues.
                                </li>
                                <div className="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                describeHumanRightIssue: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>

                            </div>

                            {/* 6no.point */}

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Number of Complaints on the following made by employees and workers:
                                </li>

                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className=''>

                                        </th>
                                        <th colspan="3" className='thdivmainbrdr'>
                                            <p className='prcnttxttemplytxtpp'>
                                                FY {currentFY}
                                                (Current Financial Year)
                                            </p>
                                        </th>
                                        <th colspan="3" className='thdivmainbrdr' >
                                            <p className='prcnttxttemplytxtpp'>
                                                FY {previousFY}
                                                (Previous Financial Year)
                                            </p>
                                        </th>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <th className='poinuttdsixhghdsepeicdivfrst'>

                                        </th>

                                        <th colspan="1" className='thdivmainbrdr poinuttdsixhghdsepeicdivscnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Filed during
                                                the year

                                            </p>
                                        </th>
                                        <th colspan="1" className='thdivmainbrdr poinuttdsixhghdsepeicdivthrd' >
                                            <p className='mnumbbrttprinvthreetxttppp'>Pending
                                                resolution
                                                at the end
                                                of year
                                            </p>
                                        </th>
                                        <th colspan="1" className='thdivmainbrdr poinuttdsixhghdsepeicdivfrth'>
                                            <p className='mnumbbrttprinvthreetxttppp'> Remarks</p>
                                        </th>
                                        <th colspan="1" className='thdivmainbrdr poinuttdsixhghdsepeicdivffth'>
                                            <p className='mnumbbrttprinvthreetxttppp'> Filed during
                                                the year
                                            </p>
                                        </th>
                                        <th colspan="1" className='thdivmainbrdr poinuttdsixhghdsepeicdivsixtrh' >
                                            <p className='mnumbbrttprinvthreetxttppp'>Pending
                                                resolution
                                                at the end
                                                of year
                                            </p>
                                        </th>
                                        <th colspan="1" className='thdivmainbrdr poinuttdsixhghdsepeicdivsevnth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>Remarks</p>
                                        </th>


                                    </tr>


                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Sexual Harassment
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_sexualHarasment', 'fieldDuringTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_sexualHarasment', 'pendingAtTheEndOfTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_sexualHarasment', 'remarks', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>


                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_sexualHarasment', 'fieldDuringTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_sexualHarasment', 'pendingAtTheEndOfTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_sexualHarasment', 'remarks', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>

                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Discrimination at
                                                workplace
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_discriminationAtWorkplace', 'fieldDuringTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_discriminationAtWorkplace', 'pendingAtTheEndOfTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_discriminationAtWorkplace', 'remarks', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>


                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_discriminationAtWorkplace', 'fieldDuringTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_discriminationAtWorkplace', 'pendingAtTheEndOfTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_discriminationAtWorkplace', 'remarks', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Child Labour</p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_childLabour', 'fieldDuringTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_childLabour', 'pendingAtTheEndOfTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_childLabour', 'remarks', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>


                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_childLabour', 'fieldDuringTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_childLabour', 'pendingAtTheEndOfTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_childLabour', 'remarks', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'> Forced
                                                Labour/Involuntary
                                                Labour
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_forcedLabour', 'fieldDuringTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_forcedLabour', 'pendingAtTheEndOfTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_forcedLabour', 'remarks', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>


                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_forcedLabour', 'fieldDuringTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_forcedLabour', 'pendingAtTheEndOfTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_forcedLabour', 'remarks', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            Wages

                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_wages', 'fieldDuringTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_wages', 'pendingAtTheEndOfTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_wages', 'remarks', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>


                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_wages', 'fieldDuringTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_wages', 'pendingAtTheEndOfTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_wages', 'remarks', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            Other human
                                            rights related
                                            issues

                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_otherThanHumanRights', 'fieldDuringTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_otherThanHumanRights', 'pendingAtTheEndOfTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_otherThanHumanRights', 'remarks', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>


                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_otherThanHumanRights', 'fieldDuringTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_otherThanHumanRights', 'pendingAtTheEndOfTheYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_otherThanHumanRights', 'remarks', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                    </tr>

                                </table>

                                <div className='notedivadmoredivflx'>
                                    <div className=''>
                                        <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal4(!notemodal4)}>Note  </p>
                                    </div>
                                </div>
                                {notemodal4 && <div className="yestrxtwarea mt-2 mb-0">

                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                    >
                                    </div>
                                </div>}
                            </div>

                            {/* 7no.point */}

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Complaints filed under the Sexual Harassment of Women at Workplace (Prevention,
                                    Prohibition and Redressal) Act, 2013, in the following format:
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='sebvenpoinytpriciplehhdhhdivfrst'>

                                        </th>
                                        <th className='thdivmainbrdr sebvenpoinytpriciplehhdhhdivscnd'>
                                            <p className='prcnttxttemplytxtpp '>FY {currentFY}
                                                (Current Financial Year) </p>
                                        </th>
                                        <th className='thdivmainbrdr sebvenpoinytpriciplehhdhhdivthrd' >
                                            <p className='prcnttxttemplytxtpp'>FY {previousFY}
                                                (Previous Financial Year)
                                            </p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr '>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Total Complaints reported under
                                                Sexual Harassment on of Women at
                                                Workplace (Prevention, Prohibition
                                                and Redressal) Act, 2013 (POSH)
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('totalComplaint', 'currentYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('totalComplaint', 'previousYearYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>


                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdrspclnewone'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'> Complaints on POSH as a % of
                                                female employees / workers
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('percentOfCOmplaintPose', 'currentYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('percentOfCOmplaintPose', 'previousYearYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>


                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdrspclnewone'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Complaints on POSH upheld
                                            </p>

                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('percentOfCOmplaintPoseUnHelp', 'currentYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('percentOfCOmplaintPoseUnHelp', 'previousYearYear', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>


                                    </tr>

                                </table>



                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Mechanisms to prevent adverse consequences to the complainant in discrimination and
                                    harassment cases.
                                </li>
                                <div className="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                mechanismDetails: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>

                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Do human rights requirements form part of your business agreements and contracts?
                                </li>
                                <div className="yesnobtndivmain">
                                    <div className="raditrbtntxt">
                                        <input type="radio"
                                            value="Yes"
                                            checked={formvalue?.humanRightsRequiremeentDetails?.humanRightsRequiremeentStatus === "Yes"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    humanRightsRequiremeentDetails: {
                                                        ...formvalue.humanRightsRequiremeentDetails,
                                                        humanRightsRequiremeentStatus: "Yes"
                                                    }
                                                });
                                            }}
                                        /><label>Yes</label>
                                    </div>
                                    <div className="raditrbtntxt">
                                        <input type="radio"
                                            value="No"
                                            checked={formvalue?.humanRightsRequiremeentDetails?.humanRightsRequiremeentStatus === "No"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    humanRightsRequiremeentDetails: {
                                                        ...formvalue.humanRightsRequiremeentDetails,
                                                        humanRightsRequiremeentStatus: "No"
                                                    }
                                                });
                                            }}
                                        />
                                        <label>No</label>
                                    </div>
                                </div>
                                <div class="yestrxtwarea mb-2">
                                    <p class="dtlsttfvdvgppphjp">
                                        Details :
                                    </p>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('humanRightsRequiremeentDetails', 'details', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>

                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Assessments for the year:
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>

                                        <th className='thdivmainbrdr tenprindiplrkdivfrst'>

                                        </th>
                                        <th className='thdivmainbrdr tenprindiplrkdivscnd' >
                                            <p className='mnumbbrttprinvthreetxttppp'> % of your plants and offices that were assessed
                                                (by entity or statutory authorities orthird parties)
                                            </p>
                                        </th>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            Child labour
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('assesmentOfTheYear', 'childLabour', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>Forced/involuntary labour

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('assesmentOfTheYear', 'forcedLabour', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            Sexual harassment
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('assesmentOfTheYear', 'sexualHarasement', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>Discrimination at workplace

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('assesmentOfTheYear', 'discriminationAtWorkPlace', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>Wages

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('assesmentOfTheYear', 'wages', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>Others – please specify

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('assesmentOfTheYear', 'others', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Provide details of any corrective actions taken or underway to address significant risks /
                                    concerns arising from the assessments at Question 10 above.
                                </li>
                                <div className="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                actionTakedDetails: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>

                            </div>

                        </ul>
                    </div>
                </div >

                <div className='borderessentialin'>
                    <p className='idicatrstxtpp'>Leadership Indicators</p>
                    <div className=''>
                        <ul className='listoftblxsdivul'>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Details of a business process being modified / introduced as a result of addressing
                                    human rights grievances/complaints.

                                </li>
                                <div className="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                detailsOfBusinessProcess: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>

                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Details of the scope and coverage of any Human rights due-diligence conducted.
                                </li>
                                <div className="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                dueDiligenceConductedDetails: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>

                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Is the premise/office of the entity accessible to differently abled visitors, as per the requirements of the Rights of
                                    Persons with Disabilities Act, 2016?
                                </li>
                                <div className="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                isPremiseForPWD: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>

                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Details on assessment of value chain partners:
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>

                                        <th className='thdivmainbrdr leaderixhipindictysydiffrst'>

                                        </th>
                                        <th className='thdivmainbrdr leaderixhipindictysydifscnd' >
                                            % of your plants and offices that were assessed
                                            (by entity or statutory authorities orthird parties)
                                        </th>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            Sexual Harassment
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('chainPartnerAssessmentDetails', 'sexualHarassment', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>Discrimination at workplace

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('chainPartnerAssessmentDetails', 'discriminationAtWorkplace', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            Child Labour
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('chainPartnerAssessmentDetails', 'childLabour', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>Forced Labour/Involuntary Labour

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('chainPartnerAssessmentDetails', 'forcedLabour', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>Wages

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('chainPartnerAssessmentDetails', 'wages', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>Others – please specify

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('chainPartnerAssessmentDetails', 'others', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Provide details of any corrective actions taken or underway to address significant risks /
                                    concerns arising from the assessments at Question 4 above.

                                </li>
                                <div className="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                correctiveActionDetails: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>

                            </div>
                        </ul>
                    </div>
                </div>

                <div className="homePgCreModSubmitDiv">
                    <button
                        className="homePgCreModSubmitBtn btn1"
                        onClick={submitHandler}
                        type='button'
                    >
                        Next
                    </button>
                </div>
            </div >
        </>
    )
}

export default Principlefive
