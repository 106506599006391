import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Addicon from "../../Images/Icons/PNG/Add icon.png"
import HomeService from '../../Services/HomeService';
import HttpClientXml from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const Initiativedetails = () => {
    const userData = useSelector((state) => state?.StatusCardSlice?.data);
    const [editedelete, setEditedelete] = useState(false);
    const [modalId, setModalId] = useState("");
    const navigate = useNavigate()
    const [allInitiativeData, setAllInitiativeData] = useState([])
    // console.log("s654f9r4f1d", allInitiativeData)

    const handledelteediit = (id) => {
        setModalId(id);
        setEditedelete(!editedelete)
    }

    const handleEdit = async (item) => {
        navigate(`/editinitiative/${item?._id}`, { state: item })
    }


    //for delete initiative
    const handleDelete = async () => {
        const res = await HttpClientXml?.requestData(`delete-initiative/${modalId}`, {}, "DELETE");
        if (res?.status) {
            toast?.success("Initiative deleted succesfully");
            fecthAllInitiative();
        } else {
            toast?.error(res?.message || "Something went wrong");
        }
    }

    // >>>>Handle Detail View of Single Event Card<<<<
    const handleinititiveclick = (item) => {
        navigate(`/initiativesingle/${item?._id}`)
    }


    // >>>>GET All Initiative <<<<
    const fecthAllInitiative = async () => {
        const res = await HomeService.getAllInitiative();
        if (res && res?.status) {
            setAllInitiativeData(res?.data)
        }
    }

    useEffect(() => {
        fecthAllInitiative()
    }, [])
    return (
        <>
            <section className='initiativedetailsection'>
                <div className='detailspagecontainer'>
                    <div className='bulletindetailsbg'>
                        <div className='singlepagetop'>
                            <div className="singlebulletinflx">
                                <h4 className='singlepagetoptxt'>
                                    Initiative</h4>
                                <Link className='singllepageicon' to="/createinitiative">
                                    <img src={Addicon} alt="..." />
                                </Link>
                            </div>
                        </div>
                        <div className='inititaivedivbottom'>
                            {allInitiativeData && allInitiativeData?.map((item) => {
                                // console.log("s84fg86s4f", item)
                                return (
                                    <div className='inititaivedivbottobg' key={item?._id}>
                                        <div className='detailsdordivflx'>
                                            <div className='' style={{cursor:"pointer"}} onClick={() => handleinititiveclick(item)}>
                                                <div className='initiativethreetxtppflx'>
                                                    <p className='intiatvtxtppdtls'>
                                                        <span>
                                                            Name :
                                                        </span>

                                                        {item?.nameOfInitaitive}
                                                    </p>
                                                </div>
                                                <p className='intiatvtxtppdtls'>
                                                    <span>
                                                        Reward Points :
                                                    </span>
                                                    {item?.rewardPoints}
                                                </p>

                                                <div className=''>
                                                    <p className='intiatvtxtppdtls'><span> Start Date - </span>{item?.startDate}</p>

                                                    <div className='datevwdivflx'>
                                                        <p className='intiatvtxtppdtls'><span> End Date - </span>{item?.endDate}</p>
                                                        {/* <div className='viewbtninitivediv'>
                                                            <button className='viewbtn'>View</button>
                                                        </div> */}
                                                    </div>

                                                </div>
                                            </div>
                                            {
                                                userData?._id === item?.addedBy &&
                                                <div className='initiativethreedlfown'>
                                                    <div className='initiativethreedlf' onClick={() => handledelteediit(item?._id)}>
                                                        <i className="fa-solid fa-ellipsis-vertical"></i>

                                                    </div>
                                                    {item?._id === modalId && editedelete &&
                                                        <div className="editdltdivflxmain">
                                                            <div className="plismnisn" onClick={() => handleEdit(item)}>
                                                                <i className="fa-solid fa-pen-to-square" />
                                                            </div>
                                                            <div className="plismnisn" onClick={handleDelete}>
                                                                <i className="fa-solid fa-trash" />
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            }
                                        </div>

                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>

            </section>
        </>
    )

}


export default Initiativedetails