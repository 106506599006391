import React from 'react'
import Select from "react-select";
const CreateClaimpoints = () => {
    return (
        <>
            <section className="createeventdetails">
                <div className="detailspagecontainer">
                    <div className="createdivfrmmain">
                        <form>
                            <div className=''>
                         

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt"> Activity<span className='required'>*</span></p>

                                    <Select
                                        placeholder={"Select Activity"}
                                       
                                        className="typstrningwhselctnew"
                                    />
                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Claim Points <span className='required'>*</span></p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                    />

                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt"> Contribution Behaviour Shpreres<span className='required'>*</span></p>

                                    <Select
                                        placeholder={"Select Activity"}
                                       
                                        className="typstrningwhselctnew"
                                    />
                                </div>
                                <div className="trainingrifgtdivgappp">
                                    <p className="rsntxrpnewtxt">Describe the contribution Details</p>
                                    <textarea
                                        id=""
                                        rows={4}
                                        cols={50}
                                        name='addNotes'
                                        className="txtdivallartadvcacytfnew"
                                    ></textarea>

                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Add Collaborators (email ids)<span className='required'>*</span></p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                    />

                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Add Recipients or Beneficiaries<span className='required'>*</span></p>

                                    <Select
                                        placeholder={"Select Activity"}
                                       
                                        className="typstrningwhselctnew"
                                    />
                                </div>
                                <div className="bulletinsbmtbtndivv">
                                    <button
                                        type="button"
                                        className="bulletinsbmtbtn"
                                    >
                                        Submit
                                    </button>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreateClaimpoints
