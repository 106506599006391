import React, { useEffect, useRef, useState } from 'react'
import AddIcon from "../../Images/Icons/PNG/Add icon.png"
import HttpClientXml from '../../utils/HttpClientXml';
import HttpClient from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';

const Principlenine = () => {
    const [notemodal2, setnotemodal2] = useState(false);
    const initvalue = {
        describeComplaintsAndFeedback: "",
        percentageOfturnOver: { //2
            environmentAndSocail: "",
            safeAndrespinsibleUsage: "",
            recycling: ""
        },

        dataPrivacy: {
            currentYear: {
                receivedDuringTheYear: 0,
                pendingResoulutionEndOfTheYear: 0
            },
            currentYearRemark: "",
            previousYear: {
                receivedDuringTheYear: 0,
                pendingResoulutionEndOfTheYear: 0
            },
            previousYearRemark: ""
        },
        advertising: {
            currentYear: {
                receivedDuringTheYear: 0,
                pendingResoulutionEndOfTheYear: 0
            },
            currentYearRemark: "",
            previousYear: {
                receivedDuringTheYear: 0,
                pendingResoulutionEndOfTheYear: 0
            },
            previousYearRemark: ""
        },
        cyberSecurity: {
            currentYear: {
                receivedDuringTheYear: 0,
                pendingResoulutionEndOfTheYear: 0
            },
            currentYearRemark: "",
            previousYear: {
                receivedDuringTheYear: 0,
                pendingResoulutionEndOfTheYear: 0
            },
            previousYearRemark: ""
        },
        deliverOfEssentialProducts: {
            currentYear: {
                receivedDuringTheYear: 0,
                pendingResoulutionEndOfTheYear: 0
            },
            currentYearRemark: "",
            previousYear: {
                receivedDuringTheYear: 0,
                pendingResoulutionEndOfTheYear: 0
            },
            previousYearRemark: ""
        },
        restrictiveTradePractice: {
            currentYear: {
                receivedDuringTheYear: 0,
                pendingResoulutionEndOfTheYear: 0
            },
            currentYearRemark: "",
            previousYear: {
                receivedDuringTheYear: 0,
                pendingResoulutionEndOfTheYear: 0
            },
            previousYearRemark: ""
        },
        unfairTradePractice: {
            currentYear: {
                receivedDuringTheYear: 0,
                pendingResoulutionEndOfTheYear: 0
            },
            currentYearRemark: "",
            previousYear: {
                receivedDuringTheYear: 0,
                pendingResoulutionEndOfTheYear: 0
            },
            previousYearRemark: ""
        },
        productAndServiceComplaints: {
            currentYear: {
                receivedDuringTheYear: 0,
                pendingResoulutionEndOfTheYear: 0
            },
            currentYearRemark: "",
            previousYear: {
                receivedDuringTheYear: 0,
                pendingResoulutionEndOfTheYear: 0
            },
            previousYearRemark: ""
        },
        accountSafetyIssueDetails: { //4
            voluntaryRecalls: {
                number: "",
                reasonsForRecall: ""
            },
            forcedRecalls: {
                number: "",
                reasonsForRecall: ""
            }
        },
        dataPrivacyDetails: { //5
            isAvailable: "Yes",
            weblink: ""
        },

        detailsOfcorrectiveAction: "", //6
        informationOfDataBreaches: { //7
            noOfInstances: "",
            percentage: "",
            impact: ""
        },

        channelOrPlatfromInformationWeblink: "", //1
        safeAndResponsibleStratigy: "", //2
        mechanismOfdisruptionAndDiscontinuation: "",
        procurementPolicyDetails: {
            status: "Yes",
            Details: ""
        },
        surveyRegardDetails: {
            status: "Yes",
            Details: ""
        },
    }

    const initentityDetails = {
        slNo: "",
        entity:"",
        currentYear: "",
        previosYear: ""
    }

    const [text, setText] = useState('');
    const divRef = useRef(null);
    const [entityDetails, setentityDetails] = useState([initentityDetails]);
    const [currentFY, setCurrentFY] = useState("");
    const [previousFY, setPreviousFY] = useState("");
    const [principleOneData, setPrincipleOneData] = useState({});
    const [formvalue, setformvalue] = useState(initvalue);

    useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const startMonth = 4; // April is the start of the financial year

        if (currentDate.getMonth() + 1 >= startMonth) {
            setCurrentFY(`${currentYear}-${currentYear + 1}`);
            setPreviousFY(`${currentYear - 1}-${currentYear}`);
        } else {
            setCurrentFY(`${currentYear - 1}-${currentYear}`);
            setPreviousFY(`${currentYear - 2}-${currentYear - 1}`);
        }

    }, []);

    useEffect(() => {
        getPrincipleOne();
    }, [])

    //for getting principleone
    const getPrincipleOne = async () => {
        const res = await HttpClientXml?.requestData(`get-principle`, {}, "GET");
        if (res?.status) {
            setPrincipleOneData(res?.data?.[0]);
        }
    }


    //for Input fields
    const handleInput = (category, field, value) => {
        setformvalue((prevValue) => ({
            ...prevValue,
            [category]: {
                ...prevValue[category],
                [field]: value,
            }
        }));
    }


    //handle change for entityDetails add more functionality
    const handleChangeentity = (i, e, field) => {
        let newFormValues = [...entityDetails];
        newFormValues[i][field] = e.currentTarget.textContent;
        setentityDetails(newFormValues);
    };


    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails = () => {
        setentityDetails([...entityDetails, initentityDetails]);
    };


    //for removing add more field
    const removeFormFieldsentityDetails = (i) => {
        let newFormValues = [...entityDetails];
        newFormValues.splice(i, 1);
        setentityDetails(newFormValues);
    };


    //for input fields
    const handleInputChange = (e, section, year, field) => {
        const value = e.currentTarget.textContent;
        setformvalue(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [year]: {
                    ...prevState[section][year],
                    [field]: value
                }
            }
        }));
    };


    const submitHandler = async (e) => {
        e.preventDefault();
        let data = {};

        data = {
            "principleNine": {
                "currentYear": currentFY,
                "previousYear": previousFY,
                "essentialIndicators": {
                    "describeComplaintsAndFeedback": formvalue?.describeComplaintsAndFeedback, //1
                    "percentageOfturnOver": { //2
                        "environmentAndSocail": formvalue?.percentageOfturnOver?.environmentAndSocail,
                        "safeAndrespinsibleUsage": formvalue?.percentageOfturnOver?.safeAndrespinsibleUsage,
                        "recycling": formvalue?.percentageOfturnOver?.recycling
                    },
                    "consumerComplaintsDetails": {//3
                        "dataPrivacy": {
                            "currentYear": {
                                "receivedDuringTheYear": +formvalue?.dataPrivacy?.currentYear?.receivedDuringTheYear,
                                "pendingResoulutionEndOfTheYear": +formvalue?.dataPrivacy?.currentYear?.pendingResoulutionEndOfTheYear
                            },
                            "currentYearRemark": formvalue?.dataPrivacy?.currentYearRemark,
                            "previousYear": {
                                "receivedDuringTheYear": +formvalue?.dataPrivacy?.previousYear?.receivedDuringTheYear,
                                "pendingResoulutionEndOfTheYear": +formvalue?.dataPrivacy?.previousYear?.pendingResoulutionEndOfTheYear
                            },
                            "previousYearRemark": formvalue?.dataPrivacy?.previousYearRemark
                        },
                        "advertising": {
                            "currentYear": {
                                "receivedDuringTheYear": +formvalue?.advertising?.currentYear?.receivedDuringTheYear,
                                "pendingResoulutionEndOfTheYear": +formvalue?.advertising?.currentYear?.pendingResoulutionEndOfTheYear
                            },
                            "currentYearRemark": formvalue?.advertising?.currentYearRemark,
                            "previousYear": {
                                "receivedDuringTheYear": +formvalue?.advertising?.previousYear?.receivedDuringTheYear,
                                "pendingResoulutionEndOfTheYear": +formvalue?.advertising?.previousYear?.pendingResoulutionEndOfTheYear
                            },
                            "previousYearRemark": formvalue?.advertising?.previousYearRemark
                        },
                        "cyberSecurity": {
                            "currentYear": {
                                "receivedDuringTheYear": +formvalue?.cyberSecurity?.currentYear?.receivedDuringTheYear,
                                "pendingResoulutionEndOfTheYear": +formvalue?.cyberSecurity?.currentYear?.pendingResoulutionEndOfTheYear
                            },
                            "currentYearRemark": formvalue?.cyberSecurity?.currentYearRemark,
                            "previousYear": {
                                "receivedDuringTheYear": +formvalue?.cyberSecurity?.previousYear?.receivedDuringTheYear,
                                "pendingResoulutionEndOfTheYear": +formvalue?.cyberSecurity?.previousYear?.pendingResoulutionEndOfTheYear
                            },
                            "previousYearRemark": formvalue?.cyberSecurity?.previousYearRemark
                        },
                        "deliverOfEssentialProducts": {
                            "currentYear": {
                                "receivedDuringTheYear": +formvalue?.deliverOfEssentialProducts?.currentYear?.receivedDuringTheYear,
                                "pendingResoulutionEndOfTheYear": +formvalue?.deliverOfEssentialProducts?.currentYear?.pendingResoulutionEndOfTheYear
                            },
                            "currentYearRemark": formvalue?.deliverOfEssentialProducts?.currentYearRemark,
                            "previousYear": {
                                "receivedDuringTheYear": +formvalue?.deliverOfEssentialProducts?.previousYear?.receivedDuringTheYear,
                                "pendingResoulutionEndOfTheYear": +formvalue?.deliverOfEssentialProducts?.previousYear?.pendingResoulutionEndOfTheYear
                            },
                            "previousYearRemark": formvalue?.deliverOfEssentialProducts?.previousYearRemark
                        },
                        "restrictiveTradePractice": {
                            "currentYear": {
                                "receivedDuringTheYear": +formvalue?.restrictiveTradePractice?.currentYear?.receivedDuringTheYear,
                                "pendingResoulutionEndOfTheYear": +formvalue?.restrictiveTradePractice?.currentYear?.pendingResoulutionEndOfTheYear
                            },
                            "currentYearRemark": formvalue?.restrictiveTradePractice?.currentYearRemark,
                            "previousYear": {
                                "receivedDuringTheYear": +formvalue?.restrictiveTradePractice?.previousYear?.receivedDuringTheYear,
                                "pendingResoulutionEndOfTheYear": +formvalue?.restrictiveTradePractice?.previousYear?.pendingResoulutionEndOfTheYear
                            },
                            "previousYearRemark": formvalue?.restrictiveTradePractice?.previousYearRemark
                        },
                        "unfairTradePractice": {
                            "currentYear": {
                                "receivedDuringTheYear": +formvalue?.unfairTradePractice?.currentYear?.receivedDuringTheYear,
                                "pendingResoulutionEndOfTheYear": +formvalue?.unfairTradePractice?.currentYear?.pendingResoulutionEndOfTheYear
                            },
                            "currentYearRemark": formvalue?.unfairTradePractice?.currentYearRemark,
                            "previousYear": {
                                "receivedDuringTheYear": +formvalue?.unfairTradePractice?.previousYear?.receivedDuringTheYear,
                                "pendingResoulutionEndOfTheYear": +formvalue?.unfairTradePractice?.previousYear?.pendingResoulutionEndOfTheYear
                            },
                            "previousYearRemark": formvalue?.unfairTradePractice?.previousYearRemark
                        },
                        "productAndServiceComplaints": {
                            "currentYear": {
                                "receivedDuringTheYear": +formvalue?.productAndServiceComplaints?.currentYear?.receivedDuringTheYear,
                                "pendingResoulutionEndOfTheYear": +formvalue?.productAndServiceComplaints?.currentYear?.pendingResoulutionEndOfTheYear
                            },
                            "currentYearRemark": formvalue?.productAndServiceComplaints?.currentYearRemark,
                            "previousYear": {
                                "receivedDuringTheYear": +formvalue?.productAndServiceComplaints?.previousYear?.receivedDuringTheYear,
                                "pendingResoulutionEndOfTheYear": +formvalue?.productAndServiceComplaints?.previousYear?.pendingResoulutionEndOfTheYear
                            },
                            "previousYearRemark": formvalue?.productAndServiceComplaints?.previousYearRemark
                        }
                    },
                    "accountSafetyIssueDetails": { //4
                        "voluntaryRecalls": {
                            "number": formvalue?.accountSafetyIssueDetails?.voluntaryRecalls?.number,
                            "reasonsForRecall": formvalue?.accountSafetyIssueDetails?.voluntaryRecalls?.reasonsForRecall
                        },
                        "forcedRecalls": {
                            "number": formvalue?.accountSafetyIssueDetails?.forcedRecalls?.number,
                            "reasonsForRecall": formvalue?.accountSafetyIssueDetails?.forcedRecalls?.reasonsForRecall
                        }
                    },
                    "dataPrivacyDetails": { //5
                        "isAvailable": formvalue?.dataPrivacyDetails?.isAvailable,
                        "weblink": formvalue?.dataPrivacyDetails?.weblink
                    },
                    "detailsOfcorrectiveAction": formvalue?.detailsOfcorrectiveAction, //6
                    "informationOfDataBreaches": { //7
                        "noOfInstances": formvalue?.informationOfDataBreaches?.noOfInstances,
                        "percentage": formvalue?.informationOfDataBreaches?.percentage,
                        "impact": formvalue?.informationOfDataBreaches?.impact
                    }
                },
                "leadershipIndicators": {
                    "channelOrPlatfromInformationWeblink": formvalue?.channelOrPlatfromInformationWeblink, //1
                    "safeAndResponsibleStratigy": formvalue?.safeAndResponsibleStratigy, //2
                    "mechanismOfdisruptionAndDiscontinuation": formvalue?.mechanismOfdisruptionAndDiscontinuation, //3,
                    "policyDetails": {//4
                        "procurementPolicyDetails": {
                            "status": formvalue?.procurementPolicyDetails?.status, // [Yes, No, Not Applicable]
                            "Details": formvalue?.procurementPolicyDetails?.Details
                        },
                        "surveyRegardDetails": {
                            "status": formvalue?.surveyRegardDetails?.status, // [Yes, No, Not Applicable]
                            "Details": formvalue?.surveyRegardDetails?.Details
                        },
                        "entityDetails": entityDetails
                    }
                }
            }
        }

        console.log('principlenine', data);
        // return false;
        const res = await HttpClient?.requestData(
            `update-enviroment-principle/${principleOneData?._id}`,
            data,
            "POST"
        );
        if (res?.status) {
            toast?.success("data is added successfully");
            setformvalue(initvalue);
            const contentEditableElements = document.querySelectorAll('[contentEditable]');
            contentEditableElements?.forEach(element => element.textContent = "");
            setentityDetails([initentityDetails])

        } else {
            toast?.error(res?.response?.data?.message || "Something went wrong");
        }

    }

    return (
        <>
            <div className='' >
                <h4 className='principletxtpp'>
                    PRINCIPLE 9 Businesses should engage with and provide value to
                    their consumers in a responsible manner
                </h4>
                <div className='borderessentialin'>
                    <p className='idicatrstxtpp'> Essential Indicators</p>
                    <div className=''>
                        <ul className='listoftblxsdivul'>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Describe the mechanisms in place to receive and respond to consumer complaints and feedback:
                                </li>
                                <div class="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                describeComplaintsAndFeedback: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Turnover of products and/ services as a percentage of turnover from all products/service that carry information
                                    about:
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr princplnnthdscnddivfrst'>
                                            <p className='mnumbbrttprinvthreetxttppp'>

                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princplennthdscnddivscnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                As a percentage to total turnover

                                            </p>
                                        </th>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className="Malettsgvsgphsmkjghhutxtpp">Safe and responsible usage</p>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('percentageOfturnOver', 'safeAndrespinsibleUsage', e.currentTarget.textContent)}
                                            >
                                            </div>

                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className="Malettsgvsgphsmkjghhutxtpp">Environmental and social parameters relevant to the
                                                product</p>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('percentageOfturnOver', 'environmentAndSocail', e.currentTarget.textContent)}
                                            >
                                            </div>

                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className="Malettsgvsgphsmkjghhutxtpp">Recycling and/or safe disposal
                                            </p>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('percentageOfturnOver', 'recycling', e.currentTarget.textContent)}
                                            >
                                            </div>

                                        </td>

                                    </tr>
                                </table>

                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Number of consumer complaints in respect of the following:
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr pribdmnsonnhredivfrst'>

                                        </th>
                                        <th className='thdivmainbrdr pribdmnsonnhredivscnd' colspan="2">
                                            <p className='mnumbbrttprinvthreetxttppp'>FY {currentFY}
                                                (Current Financial
                                                Year)</p>
                                        </th>
                                        <th className='pribdmnsonnhredivthrd'>
                                            <p className='mnumbbrttprinvthreetxttppp'></p>
                                        </th>
                                        <th className='thdivmainbrdr pribdmnsonnhredivfrth' colspan="2">
                                            <p className='mnumbbrttprinvthreetxttppp'>FY {previousFY}
                                                (Previous Financial Year)
                                            </p>
                                        </th>
                                        <th className='pribdmnsonnhredivffth'>
                                            <p className='mnumbbrttprinvthreetxttppp'></p>
                                        </th>
                                    </tr>
                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>

                                        </td>
                                        <td className='tddivmainbrdr pribdmnsonnhredivsxth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Received during Remarks
                                                the year

                                            </p>
                                        </td>
                                        <td className='tddivmainbrdr pribdmnsonnhredivsvnth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Pending at the end
                                                of the year


                                            </p>
                                        </td>
                                        <td className=''>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Remarks
                                            </p>
                                        </td>
                                        <td className='tddivmainbrdr pribdmnsonnhredivegth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Received during Remarks
                                                the year

                                            </p>
                                        </td>
                                        <td className='tddivmainbrdr pribdmnsonnhredinnth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Pending at the end
                                                of the year


                                            </p>
                                        </td>
                                        <td className=''>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Remarks
                                            </p>
                                        </td>
                                    </tr>
                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className="Malettsgvsgphsmkjghhutxtpp">Data Privacy</p>

                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'dataPrivacy', 'currentYear', 'receivedDuringTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'dataPrivacy', 'currentYear', 'pendingResoulutionEndOfTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('dataPrivacy', 'currentYearRemark', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'dataPrivacy', 'previousYear', 'receivedDuringTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'dataPrivacy', 'previousYear', 'pendingResoulutionEndOfTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('dataPrivacy', 'previousYearRemark', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className="Malettsgvsgphsmkjghhutxtpp">Advertising
                                            </p>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'advertising', 'currentYear', 'receivedDuringTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'advertising', 'currentYear', 'pendingResoulutionEndOfTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('advertising', 'currentYearRemark', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'advertising', 'previousYear', 'receivedDuringTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'advertising', 'previousYear', 'pendingResoulutionEndOfTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('advertising', 'previousYearRemark', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className="Malettsgvsgphsmkjghhutxtpp">Cyber security</p>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'cyberSecurity', 'currentYear', 'receivedDuringTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'cyberSecurity', 'currentYear', 'pendingResoulutionEndOfTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('cyberSecurity', 'currentYearRemark', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'cyberSecurity', 'previousYear', 'receivedDuringTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'cyberSecurity', 'previousYear', 'pendingResoulutionEndOfTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('cyberSecurity', 'previousYearRemark', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>
                                    </tr>


                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className="Malettsgvsgphsmkjghhutxtpp">Essential services delivery</p>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'deliverOfEssentialProducts', 'currentYear', 'receivedDuringTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'deliverOfEssentialProducts', 'currentYear', 'pendingResoulutionEndOfTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('deliverOfEssentialProducts', 'currentYearRemark', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'deliverOfEssentialProducts', 'previousYear', 'receivedDuringTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'deliverOfEssentialProducts', 'previousYear', 'pendingResoulutionEndOfTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('deliverOfEssentialProducts', 'previousYearRemark', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>
                                    </tr>


                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className="Malettsgvsgphsmkjghhutxtpp">Restrictive trade practices</p>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'restrictiveTradePractice', 'currentYear', 'receivedDuringTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'restrictiveTradePractice', 'currentYear', 'pendingResoulutionEndOfTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('restrictiveTradePractice', 'currentYearRemark', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'restrictiveTradePractice', 'previousYear', 'receivedDuringTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'restrictiveTradePractice', 'previousYear', 'pendingResoulutionEndOfTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('restrictiveTradePractice', 'previousYearRemark', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>
                                    </tr>


                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className="Malettsgvsgphsmkjghhutxtpp">Unfair trade practices</p>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'unfairTradePractice', 'currentYear', 'receivedDuringTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'unfairTradePractice', 'currentYear', 'pendingResoulutionEndOfTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('unfairTradePractice', 'currentYearRemark', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'unfairTradePractice', 'previousYear', 'receivedDuringTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'unfairTradePractice', 'previousYear', 'pendingResoulutionEndOfTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('unfairTradePractice', 'previousYearRemark', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>
                                    </tr>


                                    <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr'>
                                            <p className="Malettsgvsgphsmkjghhutxtpp">Product and service related
                                                complaints</p>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'productAndServiceComplaints', 'currentYear', 'receivedDuringTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'productAndServiceComplaints', 'currentYear', 'pendingResoulutionEndOfTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('productAndServiceComplaints', 'currentYearRemark', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'productAndServiceComplaints', 'previousYear', 'receivedDuringTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'productAndServiceComplaints', 'previousYear', 'pendingResoulutionEndOfTheYear')}
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('productAndServiceComplaints', 'previousYearRemark', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                <div className='notedivadmoredivflx'>
                                    <div className=''>
                                        <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal2(!notemodal2)}>Note  </p>
                                    </div>

                                    <button className='adddinciondivimg'>
                                        <img src={AddIcon} alt='...' />
                                        <span>Add More</span>
                                    </button>
                                </div>

                                <div className=" ">
                                    {notemodal2 &&
                                        <div className="yestrxtwarea mt-2 mb-0">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('infromationOfCsrProject', 'impression', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </div>}
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Details of instances of product recalls on account of safety issues:

                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr princjnineifroddivfsrt'>
                                            <p className='mnumbbrttprinvthreetxttppp'>

                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princjnineifroddivscnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Number
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princjnineifroddivthrd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Reasons for recall

                                            </p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className="Malettsgvsgphsmkjghhutxtpp">Voluntary recalls</p>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'accountSafetyIssueDetails', 'voluntaryRecalls', 'number')}
                                            >
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'accountSafetyIssueDetails', 'voluntaryRecalls', 'reasonsForRecall')}
                                            >
                                            </div>

                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className="Malettsgvsgphsmkjghhutxtpp">Forced recalls
                                            </p>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'accountSafetyIssueDetails', 'forcedRecalls', 'number')}
                                            >
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'accountSafetyIssueDetails', 'forcedRecalls', 'reasonsForRecall')}
                                            >
                                            </div>

                                        </td>

                                    </tr>
                                </table>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Does the entity have a framework/ policy on cyber security and risks related to data
                                    privacy? <span> (Yes/No) </span> If available, provide a web-link of the policy.


                                </li>
                                <div className="yesnobtndivmain">
                                    <div className="raditrbtntxtnemko">
                                        <input type="radio"
                                            value={"Yes"}
                                            checked={formvalue?.dataPrivacyDetails?.isAvailable === "Yes"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    dataPrivacyDetails: {
                                                        ...formvalue.dataPrivacyDetails,
                                                        isAvailable: "Yes"
                                                    }
                                                });
                                            }}
                                        />
                                        <label>Yes</label>
                                    </div>
                                    <div className="raditrbtntxtnemko">
                                        <input type="radio"
                                            value={"No"}
                                            checked={formvalue?.dataPrivacyDetails?.isAvailable === "No"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    dataPrivacyDetails: {
                                                        ...formvalue.dataPrivacyDetails,
                                                        isAvailable: "No"
                                                    }
                                                });
                                            }}
                                        />
                                        <label>No</label>
                                    </div>
                                </div>
                                <div className='yestrxtwarea'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('dataPrivacyDetails', 'weblink', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Provide details of any corrective actions taken or underway on issues relating to
                                    advertising, and delivery of essential services; cyber security and data privacy of
                                    customers; re-occurrence of instances of product recalls; penalty / action taken by
                                    regulatory authorities on safety of products / services.

                                </li>
                                <div className='yestrxtwarea'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                detailsOfcorrectiveAction: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Provide the following information relating to data breaches:
                                </li>
                                <ul className='healthysftydivul'>
                                    <li className='healthysftydivli'>
                                        Number of instances of data breaches

                                    </li>
                                    <div className="yestrxtwarea">
                                        <p class="dtlsttfvdvgppphjp">Details :</p>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('informationOfDataBreaches', 'noOfInstances', e.currentTarget.textContent)}
                                        >
                                        </div>


                                    </div>


                                    <li className='healthysftydivli'>
                                        Percentage of data breaches involving personally identifiable information of
                                        customers
                                    </li>
                                    <div className="yestrxtwarea">
                                        <p class="dtlsttfvdvgppphjp">Details :</p>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('informationOfDataBreaches', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </div>


                                    <li className='healthysftydivli'>
                                        Impact, if any, of the data breaches
                                    </li>

                                    <div className="yestrxtwarea">
                                        <p class="dtlsttfvdvgppphjp">Details :</p>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('informationOfDataBreaches', 'impact', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </div>





                                </ul>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className='borderessentialin'>
                    <p className='idicatrstxtpp'> Leadership Indicators</p>
                    <div className=''>
                        <ul className='listoftblxsdivul'>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Channels / platforms where information on products and services of the entity can be
                                    accessed (provide web link, if available).
                                </li>
                                <div class="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                channelOrPlatfromInformationWeblink: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Steps taken to inform and educate consumers about safe and responsible usage of
                                    products and/or services.
                                </li>
                                <div class="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                safeAndResponsibleStratigy: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Mechanisms in place to inform consumers of any risk of disruption/discontinuation of
                                    essential services.
                                </li>
                                <div class="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                mechanismOfdisruptionAndDiscontinuation: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    a. Do you have a preferential procurement policy where you give preference to purchase from suppliers
                                    comprising marginalised /vulnerable groups? <span>(Yes/No/Not Applicable)</span>
                                </li>
                                <div className="yesnobtndivmain">
                                    <div className="raditrbtntxtnemko">
                                        <input type="radio"
                                            value={"Yes"}
                                            checked={formvalue?.procurementPolicyDetails?.status === "Yes"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    procurementPolicyDetails: {
                                                        ...formvalue.procurementPolicyDetails,
                                                        status: "Yes"
                                                    }
                                                });
                                            }}
                                        />
                                        <label>Yes</label>
                                    </div>
                                    <div className="raditrbtntxtnemko">
                                        <input type="radio"
                                            value={"No"}
                                            checked={formvalue?.procurementPolicyDetails?.status === "No"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    procurementPolicyDetails: {
                                                        ...formvalue.procurementPolicyDetails,
                                                        status: "No"
                                                    }
                                                });
                                            }}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="raditrbtntxtnemko">
                                        <input type="radio"
                                            value={"Not Applicabl"}
                                            checked={formvalue?.procurementPolicyDetails?.status === "Not Applicabl"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    procurementPolicyDetails: {
                                                        ...formvalue.procurementPolicyDetails,
                                                        status: "Not Applicabl"
                                                    }
                                                });
                                            }}
                                        />
                                        <label>Not Applicable</label>
                                    </div>
                                </div>
                                <div className='yestrxtwarea mt-2 mb-2'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('procurementPolicyDetails', 'Details', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <p className='listoftblxsdiv'>
                                    b. Did your entity carry out any survey with regard to consumer satisfaction relating to the major products/services
                                    of the entity, significant locations of operation of the entity or the entity as a whole?
                                    <span> (Yes/No). </span>
                                </p>
                                <div className="yesnobtndivmain">
                                    <div className="raditrbtntxtnemko">
                                        <input type="radio"
                                            value={"Yes"}
                                            checked={formvalue?.surveyRegardDetails?.status === "Yes"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    surveyRegardDetails: {
                                                        ...formvalue.surveyRegardDetails,
                                                        status: "Yes"
                                                    }
                                                });
                                            }}
                                        />
                                        <label>Yes</label>
                                    </div>
                                    <div className="raditrbtntxtnemko">
                                        <input type="radio"
                                            value={"No"}
                                            checked={formvalue?.surveyRegardDetails?.status === "No"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    surveyRegardDetails: {
                                                        ...formvalue.surveyRegardDetails,
                                                        status: "No"
                                                    }
                                                });
                                            }}
                                        />
                                        <label>No</label>
                                    </div>
                                </div>
                                <div className='yestrxtwarea mt-2 mb-2'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('surveyRegardDetails', 'Details', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>
                                {/* <div className='imghsadddscrddftfadivdflx'>
                                    <div className=' adddinciondivimgright '>
                                        <img src={AddIcon} alt='...' />
                                    </div>
                                    <div>
                                        <p className='csrfsttdffgttxttpp'>(Create the Table)</p>
                                    </div>
                                </div> */}

                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr perincninedivlastdivfrfrfst'>
                                            <p className='prcnttxttemplytxtpp'>Sl No.</p>
                                        </th>
                                        <th className='thdivmainbrdr perincninedivlastdivfrfscnd '>
                                            <p className='prcnttxttemplytxtpp'>Entity</p>
                                        </th>
                                        <th className='thdivmainbrdr perincninedivlastdivfrthrd'>
                                            <p className='prcnttxttemplytxtpp'>FY {currentFY}
                                                (Current Financial
                                                Year)</p>
                                        </th>
                                        <th className='thdivmainbrdr perincninedivlastdivfrfrth'>
                                            <p className='prcnttxttemplytxtpp'>FY {previousFY}
                                                (Previous Financial Year)</p>
                                        </th>
                                    </tr>

                                    {
                                        entityDetails?.map((item, ind) => {
                                            return (
                                                <tr className='trdivmainbrdr'>
                                                    <td className='tddivmainbrdr '>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "slNo")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr '>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "entity")}
                                                            >
                                                        </div>
                                                    </td>

                                                    <td className='tddivmainbrdr '>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "currentYear")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr '>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "previosYear")}
                                                        >
                                                        </div>
                                                    </td>

                                                </tr>
                                            )
                                        })
                                    }

                                    {/* <tr className='trdivmainbrdr'>
                                        <td className='tddivmainbrdr '>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr '>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr '>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr '>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                    </tr> */}
                                </table>

                                {/* For tr addition */}
                                <div className='adddinciondivimg'>
                                    <img src={AddIcon} alt='...' onClick={addFormFieldsentityDetails} />
                                    <span>Add More</span>
                                </div>

                            </div>
                        </ul>
                    </div>
                </div>
                <div className="homePgCreModSubmitDiv">
                    <button
                        type='button'
                        onClick={submitHandler}
                        className="homePgCreModSubmitBtn btn1">
                        Final Submit
                    </button>
                </div>
            </div>
        </>
    )
}

export default Principlenine
