import React, { useEffect, useState } from 'react'
// import add from "../../Images/Icons/PNG/Add icon.png";
import Expand from "../../Images/Icons/PNG/Expand.png";
// import Hall_of_fame from "../../Images/Icons/PNG/Hall_of_fame.png"
// import add from "../../Images/Icons/PNG/Add icon.png";
// import see from "../../Images/Icons/PNG/See all icon.png";
// import Hall_of_fame from "../../Images/Icons/PNG/Hall_of_fame.png"
import { Link } from 'react-router-dom';
import a from "../../Images/cute-girl-dp.jpg";
import 'react-tabs/style/react-tabs.css';
import HomeService from '../../Services/HomeService';
import moment from 'moment';
const Hallofframe = () => {
    const [allHallFame, setAllHallFame] = useState([])
    console.log("68fg84fdz6", allHallFame)

    // >>>>GET All Hall Of Fame<<<<
    const fetchHallFame = async () => {
        const res = await HomeService.getAllHallFame();
        if (res && res?.status) {
            setAllHallFame(res?.data)
        }
    }

    useEffect(() => {
        fetchHallFame()
    }, [])
    return (
        <>
            <div className='performer_boardengage'>
                <div className="top">
                    <div className="head bulleBoardHead">
                        {/* <figure className="bulleBoardFig">
                            <img
                                src={Hall_of_fame}
                                alt="..."
                            />
                        </figure> */}
                        <h4>Hall of Fame</h4>
                    </div>
                    <div className="add_seeallflx">
                        <div className="tyEvMainDiv">
                            {/* <figure className="bulleBoardFig" >
                                <i class="fa-solid fa-plus"></i>
                            </figure> */}
                        </div>
                        <div>
                            <Link style={{ textDecoration: "none" }} className="seeAllAn" to="/halloffamedetails">
                                <figure className="bulleBoardFig" style={{ marginRight: "10px" }}>
                                <img src={Expand} alt="..." />
                                </figure>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='bottom'>
                    {allHallFame && allHallFame?.length > 0 ?
                        allHallFame?.map((ele) => {
                            return (

                                <div className='proflenamedtflx' key={ele?._id}>
                                    <div className='prfltxtflx'>
                                        <div className='prflimgperfrm'>
                                            {ele?.userImage ?
                                                <img src={ele?.userImage} alt="..." />
                                                :
                                                <img src={a} alt="..." />

                                            }
                                        </div>
                                        <div className='txtprlnm'>
                                            <p>{ele?.name}</p>
                                        </div>
                                    </div>
                                    <div className='datediv'>
                                        <p>{moment(ele?.createdOn).format("MMM-YYYY")}</p>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div>No Data Found!!</div>
                    }
                </div>
            </div>
        </>
    )
}

export default Hallofframe