import React from 'react'
import download from "../../Images/Icons/PNG/download.png";
import EngagementBackground from "../../Images/EngagementBackground.png";
import CertificateGalaSeal from "../../Images/CertificateGalaSeal.png";
import logcompany from "../../Images/logcompany.png";
import elitelogo from "../../Images/elitelogo.png"
const Certificateengagement = () => {
    return (
        <>
            <section className='cerficatefofedumnainsection'>
                <div className='detailspagecontainer'>
                    <div className='cerficatefofedubg'>
                        <div className='cerficatefofedubgtop'>
                            <div className=''>
                                <p class="traimsignbvjshnamamsnbtxtuicerdr">Esg Technologires and It's Future</p>
                            </div>
                            <div className='downliadimgicndibmimg'>
                                <img src={download} alt="..." />
                            </div>
                        </div>
                        <div className='certificateimagebgpading'>
                            <div className='certificateimagebg'
                                style={{ backgroundImage: `url(${EngagementBackground})` }}>
                                <div className="certaficateengagedivrightlecfty" >
                                    <h4 className="certofiengagementxttpppvgujop" style={{ fontFamily: '"Jacquard", "Old English Text MT", serif' }}>
                                        Certificate of Engagement
                                    </h4>

                                    <div className="logocompanycertificatecmggemntimg" style={{ backgroundImage: `url(${logcompany})` }}>
                                    </div>
                                    <p className='delightedtxtt' style={{ color: "black", textAlign: "center" }}>We are delighted to Present</p>
                                    <div className="brdrednaggemntrdtnfrdrdiv">
                                    </div>
                                    <h4 className='certificateengagnameklrttxttpp'>
                                        MR. Lorem Ipsum
                                    </h4>
                                    <div className="brdrednaggemntrdtnfrdrdiv">
                                    </div>
                                    <p className='withthiscertificaetttxtppptttxttuo'>With this certificate of engagement for</p>
                                    <p className='forthwparticipanttxtppshhcertenggemnt'>
                                        For the Participation in the event on
                                    </p>
                                    <div className='certificatteenggemnetdivbtmjksm'></div>
                                    <p className='echnologiestxttcerttengagemttxtpp' >
                                        ESG technologies and its future
                                    </p>
                                </div>
                                <div className="certficateeduftrui">
                                    <table className='certficateeduftruitbls'>
                                        <tbody>
                                            <tr>
                                            <th>
                                                <div className="elitelogoengagementdivbjhio"  style={{ backgroundImage: `url(${elitelogo})` }}>
                                                </div>
                                            </th>
                                            <th>
                                                <div className>
                                                    <p className="Certificatenotxttpopp">
                                                        Unique Certificate No.</p>
                                                    <p className='certificateNoppplctrxrtp' >
                                                        eeeh-1111-11-111111</p>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="certificategalasecllsumnhj" style={{ backgroundImage: `url(${CertificateGalaSeal})` }}>
                                                </div>
                                            </th>
                                            <th>
                                                <p className="Certificatenotxttpopp">
                                                    Date of Issue.</p>
                                                <p className='certificateNoppplctrxrtp'>
                                                    23/02/2024</p>
                                            </th>
                                            <th>
                                                <p className="Certificatenotxttpopp">
                                                    Anirban Roy</p>
                                                <div style={{ borderBottom: '1px solid #afafafcc', width: '100%' }}>
                                                </div>
                                                <p  className='certificateNoppplctrxrtp'>
                                                    Signature</p>
                                            </th>
                                        </tr>
                                        </tbody>
                                        </table>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Certificateengagement
