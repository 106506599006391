import React, { useEffect, useState } from 'react'
import Expand from "../../Images/Icons/PNG/Expand.png";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Post from "../../Images/Icons/PNG/Post.png";
import girl from "../../Images/girl.png";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAffinityGroup } from '../../Redux/Slice/AffinityGroupSlice';
import Rewardpointssetailsmodal from '../../Modal/Rewardpointssetailsmodal';

const MyTeam = () => {
    const [checkgroup, setCheckGroup] = useState("mygroup");
    const dispatch = useDispatch();
    const myGroup = useSelector((state) => state?.AffinityGroupSlice?.mygroup)
  
    useEffect(() => {
        dispatch(getAffinityGroup());
    }, [])
    return (
        <>
            <div className='mytemadmaindiv'>
                <div className="top">
                    <div className="head bulleBoardHead">
                        <h4>My Team</h4>
                    </div>
                  
                    <div className="add_seeallflx">
                        <div>
                            <Link
                                className="seeAllAn"
                                to={
                                    checkgroup === "mygroup" ? "/myaffinitygroup" : "/myaffinitydetails"
                                }
                                style={{ textDecoration: "none" }}
                            >
                                <figure className="bulleBoardFig">
                                    <img src={Expand} alt="..." />
                                </figure>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='mytemtabmaindiv'>
                    <Tabs
                        onSelect={(index) =>
                            setCheckGroup(index === 0 ? "mygroup" : "others")
                        }
                    >
                        <TabList>
                            <Tab>My Groups</Tab>
                            <Tab>Others</Tab>
                        </TabList>

                        <TabPanel>
                            {
                                myGroup?.map((item) => {
                                    return (
                                        <>
                                            <div className="evetCreMainDiv">
                                                <div className="eveCreAfisDiv">
                                                    <div className="profile_pic">
                                                        <img
                                                            className="img-fluid"
                                                            src={item?.image}
                                                            alt="..."
                                                        />
                                                    </div>
                                                    <p className="hubEvCreParamainpp">{item?.groupName}</p>
                                                </div>
                                                <div className="myAffCreBtnsDiv">
                                                    <p className="myAffCrePara">Created by {item?.gpAdminFirstName + " " + item?.gpAdminLastName}</p>
                                                </div>
                                                <div className="myAffTypeBtnInpDiv" style={{ cursor: "pointer" }}>
                                                    <input
                                                        type="text"
                                                        placeholder="Type your message"
                                                        className="myAffChatTypeInp"
                                                    />
                                                    <div className="shareimgmn">
                                                        <img src={Post} alt="..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }

                        </TabPanel>
                        <TabPanel>
                            <div className="evetCreMainDiv">
                                <div className="eveCreAfisDiv">
                                    <div className="profile_pic">
                                        <img
                                            className="img-fluid"
                                            src={girl}
                                            alt="..."
                                        />
                                    </div>
                                    <p className="hubEvCreParamainpp">Women@work</p>
                                    <div className="jnbtndivmainjh">
                                        <button className="jnbtndiv">Join</button>
                                    </div>
                                </div>
                                <div className="myAffCreBtnsDiv">
                                    <p className="myAffCrePara">Created by Niladri Roy (Admin)</p>
                                </div>


                                <div className="myAffTypeBtnInpDiv" style={{ cursor: "pointer" }}>
                                    <input
                                        type="text"
                                        placeholder="Type your message"
                                        className="myAffChatTypeInp"
                                    />
                                    <div className="shareimgmn">
                                        <img src={Post} alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="evetCreMainDiv">
                                <div className="eveCreAfisDiv">
                                    <div className="profile_pic">
                                        <img
                                            className="img-fluid"
                                            src={girl}
                                            alt="..."
                                        />
                                    </div>
                                    <p className="hubEvCreParamainpp">Star Group testing</p>
                                    <div className="jnbtndivmainjh">
                                        <button className="jnbtndiv">Join</button>
                                    </div>
                                </div>
                                <div className="myAffCreBtnsDiv">
                                    <p className="myAffCrePara">Created by Niladri Roy (Admin)</p>
                                </div>
                                <div className="myAffTypeBtnInpDiv" style={{ cursor: "pointer" }}>
                                    <input
                                        type="text"
                                        placeholder="Type your message"
                                        className="myAffChatTypeInp"
                                    />
                                    <div className="shareimgmn">
                                        <img src={Post} alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="evetCreMainDiv">
                                <div className="eveCreAfisDiv">
                                    <div className="profile_pic">
                                        <img
                                            className="img-fluid"
                                            src={girl}
                                            alt="..."
                                        />
                                    </div>
                                    <p className="hubEvCreParamainpp">Star Group testing</p>
                                    <div className="jnbtndivmainjh">
                                        <button className="jnbtndiv">Join</button>
                                    </div>
                                </div>
                                <div className="myAffCreBtnsDiv">
                                    <p className="myAffCrePara">Created by Niladri Roy (Admin)</p>
                                </div>
                                <div className="myAffTypeBtnInpDiv" style={{ cursor: "pointer" }}>
                                    <input
                                        type="text"
                                        placeholder="Type your message"
                                        className="myAffChatTypeInp"
                                    />
                                    <div className="shareimgmn">
                                        <img src={Post} alt="..." />
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
           

        </>
    )
}

export default MyTeam
