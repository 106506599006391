import React, { useState } from 'react';
import "./Chatbot.css"
import charbot from "../../Images/charbot.png";
import Post from "../../Images/Icons/PNG/Post.png"
const Chatbot = () => {
    const [chatbotclick, setChatbotclick] = useState(false);
    const handlechatbotClick = () => {
        setChatbotclick(!chatbotclick)
    }
    const handlestopbotClick = (e) => {
        e.stopPropagation();
    }
    return (
        <>
            <div className="scrolltop_btn">
                <div className="chatbtimg" onClick={handlechatbotClick}>
                    <img src={charbot} alt="..." />
                </div>

               {chatbotclick  &&  <div className='chatboxdesign' onClick={(e) => handlestopbotClick(e)}>
                    <div className='chatbottop'>

                        <div className='chatbotimg'>
                            <img src={charbot} alt="..." />
                            <div className='chatdot'></div>
                        </div>


                        <div className='chatonlnflx'>
                            <p className='ChatBottxt'>Ask Hr</p>
                            <p className='chatonln'>Online</p>
                        </div>
                        <div className='clsusediv' onClick={handlechatbotClick}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    <div className="chatbotttom">
                        <div className="inpittdivcchtbot">
                            <input type="text" />
                            <div className="postdivomnchatbot">
                                <img src={Post} alt="..." />
                            </div>
                        </div>
                    </div>
                </div>}

            </div>


        </>
    )
}

export default Chatbot

