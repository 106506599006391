import React, { useEffect, useRef, useState } from 'react';
import toast from "react-hot-toast";
import HttpClient from "../../utils/HttpClientXml.js";
// import { useNavigate } from 'react-router-dom';
import AddIcon from "../../Images/Icons/PNG/Add icon.png"

const Principleone = ({setPrincicpletabdrp,setTogglesectionmdl}) => {
    const divRef = useRef(null);
    // const navigate = useNavigate();
    const initvalue = {
        boardOfDirectors: {
            totalNo: "",
            topics: "",
            ageOfPercentage: ""
        },
        keyManagerialPersonnel: {
            totalNo: "",
            topics: "",
            ageOfPercentage: ""
        },
        employeesBodAndKMP: {
            totalNo: "",
            topics: "",
            ageOfPercentage: ""
        },
        workers: {
            totalNo: "",
            topics: "",
            ageOfPercentage: ""
        },
        penalty: {
            ngrbcPrinciple: "",
            name: "",
            amountInINR: "",
            brief: "",
            hasAppeal: ""
        },
        settlement: {
            ngrbcPrinciple: "",
            name: "",
            amountInINR: "",
            brief: "",
            hasAppeal: ""
        },
        compoundingFee: {
            ngrbcPrinciple: "",
            name: "",
            amountInINR: "",
            brief: "",
            hasAppeal: ""
        },
        imprisonment: {
            ngrbcPrinciple: "",
            name: "",
            amountInINR: "",
            brief: "",
            hasAppeal: ""
        },
        punishment: {
            ngrbcPrinciple: "",
            name: "",
            amountInINR: "",
            brief: "",
            hasAppeal: ""
        },
        actionOfMonetaryAndNonMonetary: {
            caseDetails: "",
            name: ""
        },
        entityDetails: {
            status: "Yes",
            description: ""
        },
        disciplinaryActionDetails: {
            directors: {
                currentYear: "",
                previousYear: ""
            },
            kmps: {
                currentYear: "",
                previousYear: ""
            },
            employees: {
                currentYear: "",
                previousYear: ""
            },
            workers: {
                currentYear: "",
                previousYear: ""
            }
        },
        directorsNoOfComplaints: {
            currentYear: {
                number: "",
                remarks: ""
            },
            previousYear: {
                number: "",
                remarks: ""
            }
        },
        kmpsNoOfComplaints: {
            currentYear: {
                number: "",
                remarks: ""
            },
            previousYear: {
                number: "",
                remarks: ""
            }
        },
        provideActionDetails: {
            actionStatus: "Applicable",// Applicable/ Not Applicable,
            description: ""
        },
        noOfDaysOfAccountPayables: {
            currentYear: "",
            previousYear: ""
        },
        percentageOfTotalPurchase: {
            currentYear: "",
            previousYear: ""
        },
        noOfTradingHouse: {
            currentYear: "",
            previousYear: ""
        },
        topTenNoOfTrading: {
            currentYear: "",
            previousYear: ""
        }
        ,
        concentrationOfSale: {
            percentageOfTotalSale: {
                currentYear: "",
                previousYear: ""
            },
            noOfTradingHouse: {
                currentYear: "",
                previousYear: ""
            },
            topTenNoOfTrading: {
                currentYear: "",
                previousYear: ""
            },
        },
        totalPurchase: {
            currentYear: "",
            previousYear: ""
        },
        totalSales: {
            currentYear: "",
            previousYear: ""
        },
        totalLoan: {
            currentYear: "",
            previousYear: ""
        },
        investment: {
            currentYear: "",
            previousYear: ""
        },
        awarnessProgrammesDetails: {
            totalNoOfAwareness: "",
            topics: "",
            ageOfPercentage: ""
        },
        conflictOfInterestDetails: {
            entityManageStatus: "Yes",
            description: ""
        }
    }

    const [formvalue, setformvalue] = useState(initvalue);
    // const [downloadurl, setDownloadurl] = useState("");
    const [currentFY, setCurrentFY] = useState("");
    const [previousFY, setPreviousFY] = useState("");


    useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const startMonth = 4; // April is the start of the financial year

        if (currentDate.getMonth() + 1 >= startMonth) {
            setCurrentFY(`${currentYear}-${currentYear + 1}`);
            setPreviousFY(`${currentYear - 1}-${currentYear}`);
        } else {
            setCurrentFY(`${currentYear - 1}-${currentYear}`);
            setPreviousFY(`${currentYear - 2}-${currentYear - 1}`);
        }

    }, []);

    const getPdf = async () => {
        const res = await HttpClient?.requestData(`get-pdf-for-principleOne`, {}, "GET");
        if (res?.status) {
            // setDownloadurl(res?.data?.overDraftPdfUrl);
            downloadPdf(res?.data?.overDraftPdfUrl)
        }
    }
    // console.log("downloadurl",downloadurl)
    const downloadPdf = (downloadurl) => {
        const link = document.createElement('a');
        link.href = downloadurl;
        link.download = 'principleone.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    //for Input fields
    const handleInput = (category, field, value) => {
        setformvalue((prevValue) => ({
            ...prevValue,
            [category]: {
                ...prevValue[category],
                [field]: value,
            }
        }));
    }


    //for input fields
    const handleInputChange = (e, section, year, field) => {
        const value = e.currentTarget.textContent;
        setformvalue(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [year]: {
                    ...prevState[section][year],
                    [field]: value
                }
            }
        }));
    };

    //For Submit functionality
    const submitHandler = async (e) => {
        e.preventDefault();
        let data = {};
        data = {
            "principleOne": {
                "currentYear": currentFY,
                "previousYear": previousFY,
                "essentialIndicators": {
                    "awarenessProgrammesDetails": {
                        "boardOfDirectors": {
                            "totalNo": formvalue?.boardOfDirectors?.totalNo,
                            "topics": formvalue?.boardOfDirectors?.topics,
                            "ageOfPercentage": formvalue?.boardOfDirectors?.ageOfPercentage
                        },
                        "keyManagerialPersonnel": {
                            "totalNo": formvalue?.keyManagerialPersonnel?.totalNo,
                            "topics": formvalue?.keyManagerialPersonnel?.topics,
                            "ageOfPercentage": formvalue?.keyManagerialPersonnel?.ageOfPercentage
                        },
                        "employeesBodAndKMP": {
                            "totalNo": formvalue?.employeesBodAndKMP?.totalNo,
                            "topics": formvalue?.employeesBodAndKMP?.topics,
                            "ageOfPercentage": formvalue?.employeesBodAndKMP?.ageOfPercentage
                        },
                        "workers": {
                            "totalNo": formvalue?.workers?.totalNo,
                            "topics": formvalue?.workers?.topics,
                            "ageOfPercentage": formvalue?.workers?.ageOfPercentage
                        }
                    },
                    "monetary": {
                        "penalty": {
                            "ngrbcPrinciple": formvalue?.penalty?.ngrbcPrinciple,
                            "name": formvalue?.penalty?.name,
                            "amountInINR": +formvalue?.penalty?.amountInINR,
                            "brief": formvalue?.penalty?.brief,
                            "hasAppeal": formvalue?.penalty?.hasAppeal
                        },
                        "settlement": {
                            "ngrbcPrinciple": formvalue?.settlement?.ngrbcPrinciple,
                            "name": formvalue?.settlement?.name,
                            "amountInINR": +formvalue?.settlement?.amountInINR,
                            "brief": formvalue?.settlement?.brief,
                            "hasAppeal": formvalue?.settlement?.hasAppeal
                        },
                        "compoundingFee": {
                            "ngrbcPrinciple": formvalue?.compoundingFee?.ngrbcPrinciple,
                            "name": formvalue?.compoundingFee?.name,
                            "amountInINR": +formvalue?.compoundingFee?.amountInINR,
                            "brief": formvalue?.compoundingFee?.brief,
                            "hasAppeal": formvalue?.compoundingFee?.hasAppeal
                        }
                    },
                    "nonMonetary": {
                        "imprisonment": {
                            "ngrbcPrinciple": formvalue?.imprisonment?.ngrbcPrinciple,
                            "name": formvalue?.imprisonment?.name,
                            "amountInINR": +formvalue?.imprisonment?.amountInINR,
                            "brief": formvalue?.imprisonment?.brief,
                            "hasAppeal": formvalue?.imprisonment?.hasAppeal
                        },
                        "punishment": {
                            "ngrbcPrinciple": formvalue?.punishment?.ngrbcPrinciple,
                            "name": formvalue?.punishment?.name,
                            "amountInINR": +formvalue?.punishment?.amountInINR,
                            "brief": formvalue?.punishment?.brief,
                            "hasAppeal": formvalue?.punishment?.hasAppeal
                        }
                    },
                    "actionOfMonetaryAndNonMonetary": {
                        "caseDetails": formvalue?.actionOfMonetaryAndNonMonetary?.caseDetails,
                        "name": formvalue?.actionOfMonetaryAndNonMonetary?.name
                    },
                    "entityDetails": {
                        "status": formvalue?.entityDetails?.status,
                        "description": formvalue?.entityDetails?.description
                    },
                    "disciplinaryActionDetails": {
                        "directors": {
                            "currentYear": +formvalue?.disciplinaryActionDetails?.directors?.currentYear,
                            "previousYear": +formvalue?.disciplinaryActionDetails?.directors?.previousYear
                        },
                        "kmps": {
                            "currentYear": +formvalue?.disciplinaryActionDetails?.kmps?.currentYear,
                            "previousYear": +formvalue?.disciplinaryActionDetails?.kmps?.previousYear
                        },
                        "employees": {
                            "currentYear": +formvalue?.disciplinaryActionDetails?.employees?.currentYear,
                            "previousYear": +formvalue?.disciplinaryActionDetails?.employees?.previousYear
                        },
                        "workers": {
                            "currentYear": +formvalue?.disciplinaryActionDetails?.workers?.currentYear,
                            "previousYear": +formvalue?.disciplinaryActionDetails?.workers?.previousYear
                        }
                    },
                    "conflictOfInterestDetails": {
                        "directorsNoOfComplaints": {
                            "currentYear": {
                                "number": +formvalue?.directorsNoOfComplaints?.currentYear?.number,
                                "remarks": formvalue?.directorsNoOfComplaints?.currentYear?.remarks
                            },
                            "previousYear": {
                                "number": +formvalue?.directorsNoOfComplaints?.previousYear?.number,
                                "remarks": formvalue?.directorsNoOfComplaints?.previousYear?.remarks
                            }
                        },
                        "kmpsNoOfComplaints": {
                            "currentYear": {
                                "number": +formvalue?.kmpsNoOfComplaints?.currentYear?.number,
                                "remarks": formvalue?.kmpsNoOfComplaints?.currentYear?.remarks
                            },
                            "previousYear": {
                                "number": +formvalue?.kmpsNoOfComplaints?.previousYear?.number,
                                "remarks": formvalue?.kmpsNoOfComplaints?.previousYear?.remarks
                            }
                        }
                    },
                    "provideActionDetails": {
                        "actionStatus": formvalue?.provideActionDetails?.actionStatus,// Applicable/ Not Applicable,
                        "description": formvalue?.provideActionDetails?.description
                    },
                    "noOfDaysOfAccountPayables": {
                        "currentYear": +formvalue?.noOfDaysOfAccountPayables?.currentYear,
                        "previousYear": +formvalue?.noOfDaysOfAccountPayables?.previousYear
                    },
                    "nessBusinessProvideDetails": {
                        "concentrationOfPurchase": {
                            "percentageOfTotalPurchase": {
                                "currentYear": formvalue?.percentageOfTotalPurchase?.currentYear,
                                "previousYear": formvalue?.percentageOfTotalPurchase?.previousYear
                            },
                            "noOfTradingHouse": {
                                "currentYear": formvalue?.noOfTradingHouse?.currentYear,
                                "previousYear": formvalue?.noOfTradingHouse?.previousYear
                            },
                            "topTenNoOfTrading": {
                                "currentYear": formvalue?.topTenNoOfTrading?.currentYear,
                                "previousYear": formvalue?.topTenNoOfTrading?.previousYear
                            }
                        },
                        "concentrationOfSale": {
                            "percentageOfTotalSale": {
                                "currentYear": formvalue?.concentrationOfSale?.percentageOfTotalSale?.currentYear,
                                "previousYear": formvalue?.concentrationOfSale?.percentageOfTotalSale?.previousYear
                            },
                            "noOfTradingHouse": {
                                "currentYear": formvalue?.concentrationOfSale?.noOfTradingHouse?.currentYear,
                                "previousYear": formvalue?.concentrationOfSale?.noOfTradingHouse?.previousYear
                            },
                            "topTenNoOfTrading": {
                                "currentYear": formvalue?.concentrationOfSale?.topTenNoOfTrading?.currentYear,
                                "previousYear": formvalue?.concentrationOfSale?.topTenNoOfTrading?.previousYear
                            }
                        },
                        "shareOfRPT": {
                            "totalPurchase": {
                                "currentYear": formvalue?.totalPurchase?.currentYear,
                                "previousYear": formvalue?.totalPurchase?.previousYear
                            },
                            "totalSales": {
                                "currentYear": formvalue?.totalSales?.currentYear,
                                "previousYear": formvalue?.totalSales?.previousYear
                            },
                            "totalLoan": {
                                "currentYear": formvalue?.totalLoan?.currentYear,
                                "previousYear": formvalue?.totalLoan?.previousYear
                            },
                            "investment": {
                                "currentYear": formvalue?.investment?.currentYear,
                                "previousYear": formvalue?.totalLoan?.previousYear
                            }
                        }
                    }
                },
                "leadershipIndicators": {
                    "awarnessProgrammesDetails": {
                        "totalNoOfAwareness": formvalue?.awarnessProgrammesDetails?.totalNoOfAwareness,
                        "topics": formvalue?.awarnessProgrammesDetails?.topics,
                        "ageOfPercentage": formvalue?.awarnessProgrammesDetails?.ageOfPercentage
                    },
                    "conflictOfInterestDetails": {
                        "entityManageStatus": formvalue?.conflictOfInterestDetails?.entityManageStatus,
                        "description": formvalue?.conflictOfInterestDetails?.description
                    }
                }
            }
        }
        console.log('principleone', data);
        // return false;
        const res = await HttpClient?.requestData(
            "add-enviroment-principle",
            data,
            "POST"
        );
        if (res?.status) {
            toast?.success("Principle-1 is added successfully");
            setformvalue(initvalue);
            const contentEditableElements = document.querySelectorAll('[contentEditable]');
            contentEditableElements?.forEach(element => element.textContent = "");
            setPrincicpletabdrp(2);
            setTogglesectionmdl(true)
            // getPdf();
            // navigate('/pricipleonetemp')
        } else {
            toast?.error(res?.response?.data?.message || "Something went wrong");
        }

    };


    return (
        <>
            <div className=''>
                <p className='sectionctxttpppprooitp'> SECTION C : PRINCIPLE WISE PERFORMANCE DISCLOSURE </p>
                <p className='thisisectiontxtpppricnconettxp'>This section is aimed at helping entities demonstrate their performance in integrating the
                    Principles and Core Elements with key processes and decisions. The information sought is
                    categorized as “Essential” and “Leadership”. While the essential indicators are expected to be
                    disclosed by every entity that is mandated to file this report, the leadership indicators may be
                    voluntarily disclosed by entities which aspire to progress to a higher level in their quest to be
                    socially, environmentally and ethically responsible.</p>
                <h4 className='principletxtpp'>
                    PRINCIPLE 1 : Businesses should conduct and govern themselves with
                    integrity, and in a manner that is Ethical, Transparent and
                    Accountable.
                </h4>
                <div className='borderessentialin'>
                    <p className='idicatrstxtpp'>Essential Indicators</p>
                    <div className=''>
                        <ul className='listoftblxsdivul'>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Percentage coverage by training and awareness programmes on any of the Principles
                                    during the financial year:
                                </li>

                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr thdivbrdrwdthfrst'>
                                            <p className='prcnttxttemplytxtpp'>
                                                Segment
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr thdivbrdrwdthscnd'>
                                            <p className='prcnttxttemplytxtpp'>Total number of
                                                training and
                                                awareness
                                                programmes held
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr thdivbrdrwdththrd ' >
                                            <p className='prcnttxttemplytxtpp'>Topics /
                                                principles
                                                covered under
                                                the training  and
                                                its impact
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr thdivbrdrwdthlst'>
                                            <p className='prcnttxttemplytxtpp'> %age of persons in
                                                respective category
                                                covered by the
                                                awareness
                                                programmes
                                            </p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Board of
                                                Directors
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id=""
                                                type="number"
                                                value={formvalue?.boardOfDirectors?.totalNo}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        boardOfDirectors: {
                                                            ...formvalue.boardOfDirectors,
                                                            totalNo: e.target.value
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">
                                            </textarea> */}

                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('boardOfDirectors', 'totalNo', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>

                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id=""
                                                value={formvalue?.boardOfDirectors?.topics}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        boardOfDirectors: {
                                                            ...formvalue.boardOfDirectors,
                                                            topics: e.target.value
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('boardOfDirectors', 'topics', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id=""
                                                value={formvalue.boardOfDirectors?.ageOfPercentage}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        boardOfDirectors: {
                                                            ...formvalue.boardOfDirectors,
                                                            ageOfPercentage: e.target.value
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('boardOfDirectors', 'ageOfPercentage', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Key
                                                Managerial
                                                Personnel
                                            </p>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id=""
                                                type="number"
                                                name=""
                                                value={formvalue?.keyManagerialPersonnel?.totalNo}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        keyManagerialPersonnel: {
                                                            ...formvalue.keyManagerialPersonnel,
                                                            totalNo: e.target.value
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('keyManagerialPersonnel', 'totalNo', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id=""
                                                name=""
                                                value={formvalue?.keyManagerialPersonnel?.topics}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        keyManagerialPersonnel: {
                                                            ...formvalue.keyManagerialPersonnel,
                                                            topics: e.target.value
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                // placeholder="Type something..."
                                                onInput={(e) => handleInput('keyManagerialPersonnel', 'topics', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.keyManagerialPersonnel?.ageOfPercentage}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        keyManagerialPersonnel: {
                                                            ...formvalue.keyManagerialPersonnel,
                                                            ageOfPercentage: e.target.value
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('keyManagerialPersonnel', 'ageOfPercentage', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Employees
                                                other than
                                                BoD and
                                                KMPs
                                            </p>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                type="number"
                                                value={formvalue?.employeesBodAndKMP?.totalNo}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        employeesBodAndKMP: {
                                                            ...formvalue.employeesBodAndKMP,
                                                            totalNo: e.target.value
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('employeesBodAndKMP', 'totalNo', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.employeesBodAndKMP?.topics}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        employeesBodAndKMP: {
                                                            ...formvalue.employeesBodAndKMP,
                                                            topics: e.target.value
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('employeesBodAndKMP', 'topics', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.employeesBodAndKMP?.ageOfPercentage}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        employeesBodAndKMP: {
                                                            ...formvalue.employeesBodAndKMP,
                                                            ageOfPercentage: e.target.value
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('employeesBodAndKMP', 'ageOfPercentage', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Workers</p>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                type="number"
                                                value={formvalue?.workers?.totalNo}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        workers: {
                                                            ...formvalue.workers,
                                                            totalNo: e.target.value
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('workers', 'totalNo', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.workers?.topics}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        workers: {
                                                            ...formvalue.workers,
                                                            topics: e.target.value
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('workers', 'topics', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.workers?.ageOfPercentage}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        workers: {
                                                            ...formvalue.workers,
                                                            ageOfPercentage: e.target.value
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('workers', 'ageOfPercentage', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                </table>

                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Details of fines / penalties /punishment/ award/ compounding fees/ settlement amount
                                    paid in proceedings (by the entity or by directors / KMPs) with regulators/ law
                                    enforcement agencies/ judicial institutions, in the financial year, in the following format
                                    (Note: the entity shall make disclosures on the basis of materiality as specified in Regulation 30
                                    of SEBI (Listing Obligations and Disclosure Obligations) Regulations, 2015 and as disclosed on
                                    the entity’s website):
                                </li>

                                <div className='moniotortynoinmonotorydiv'>
                                    <div className=''>
                                        <div className='monitrxybtdrdr'>
                                            <p className='mnrtfctxtpp'>Monitory</p>
                                        </div>
                                        <table className='tablbrdrmain'>
                                            <tr className='trdivmainbrdr'>
                                                <th className='thdivmainbrdr thchngwidthfrst'>
                                                </th>
                                                <th className='thdivmainbrdr thchngwidthscnd '>
                                                    <p className='prcnttxttemplytxtpp'>
                                                        NGRBC
                                                        Principle
                                                    </p>
                                                </th>
                                                <th className='thdivmainbrdr thchngwidththrd'>
                                                    <p className='prcnttxttemplytxtpp'>Name of the
                                                        regulatory/
                                                        enforcement
                                                        agencies/
                                                        judicial
                                                        institutions
                                                    </p>
                                                </th>
                                                <th className='thdivmainbrdr thchngwidthfrth' >
                                                    <p className='prcnttxttemplytxtpp'>Amount (In
                                                        INR)
                                                    </p>
                                                </th>
                                                <th className='thdivmainbrdr thchngwidthffth'>
                                                    <p className='prcnttxttemplytxtpp'> Brief of the Case</p>
                                                </th>
                                                <th className='thdivmainbrdr thchngwidthsxth'>
                                                    <p className='prcnttxttemplytxtpp'>  Has an
                                                        appeal
                                                        been
                                                        preferred?
                                                        (Yes/No)
                                                    </p>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className='tddivmainbrdr'>
                                                    <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                        Penalty/ Fine
                                                    </p>
                                                </td>

                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.penalty?.ngrbcPrinciple}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                penalty: {
                                                                    ...formvalue.penalty,
                                                                    ngrbcPrinciple: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('penalty', 'ngrbcPrinciple', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>

                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.penalty?.name}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                penalty: {
                                                                    ...formvalue.penalty,
                                                                    name: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('penalty', 'name', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>

                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        type="number"
                                                        value={formvalue?.penalty?.amountInINR}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                penalty: {
                                                                    ...formvalue.penalty,
                                                                    amountInINR: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('penalty', 'amountInINR', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.penalty?.brief}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                penalty: {
                                                                    ...formvalue.penalty,
                                                                    brief: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('penalty', 'brief', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.penalty?.hasAppeal}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                penalty: {
                                                                    ...formvalue.penalty,
                                                                    hasAppeal: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('penalty', 'hasAppeal', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='tddivmainbrdr'>
                                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Settlement</p>
                                                </td>

                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.settlement?.ngrbcPrinciple}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                settlement: {
                                                                    ...formvalue.settlement,
                                                                    ngrbcPrinciple: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('settlement', 'ngrbcPrinciple', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id=""
                                                        value={formvalue?.settlement?.name}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                settlement: {
                                                                    ...formvalue.settlement,
                                                                    name: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        name="" rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('settlement', 'name', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id=""
                                                        type="number"
                                                        value={formvalue?.settlement?.amountInINR}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                settlement: {
                                                                    ...formvalue.settlement,
                                                                    amountInINR: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        name="" rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('settlement', 'amountInINR', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.settlement?.brief}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                settlement: {
                                                                    ...formvalue.settlement,
                                                                    brief: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('settlement', 'brief', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.settlement?.hasAppeal}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                settlement: {
                                                                    ...formvalue.settlement,
                                                                    hasAppeal: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('settlement', 'hasAppeal', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='tddivmainbrdr'>
                                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Compounding
                                                        fee
                                                    </p>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.compoundingFee?.ngrbcPrinciple}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                compoundingFee: {
                                                                    ...formvalue.compoundingFee,
                                                                    ngrbcPrinciple: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('compoundingFee', 'ngrbcPrinciple', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>

                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.compoundingFee?.name}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                compoundingFee: {
                                                                    ...formvalue.compoundingFee,
                                                                    name: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('compoundingFee', 'name', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        type="number"
                                                        value={formvalue?.compoundingFee?.amountInINR}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                compoundingFee: {
                                                                    ...formvalue.compoundingFee,
                                                                    amountInINR: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('compoundingFee', 'amountInINR', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.compoundingFee?.brief}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                compoundingFee: {
                                                                    ...formvalue.compoundingFee,
                                                                    brief: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('compoundingFee', 'brief', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.compoundingFee?.hasAppeal}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                compoundingFee: {
                                                                    ...formvalue.compoundingFee,
                                                                    hasAppeal: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('compoundingFee', 'hasAppeal', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div className=''>
                                        <div className='monitrxybtdrdrtop'>
                                            <p className='mnrtfctxtpp'>Non Monitory</p>
                                        </div>
                                        <table className='tablbrdrmain'>
                                            <tr className='trdivmainbrdr'>
                                                <th className='thdivmainbrdr thchngwidthfrst'>
                                                </th>
                                                <th className='thdivmainbrdr thchngwidthscnd  '>
                                                    <p className='prcnttxttemplytxtpp'>NGRBC
                                                        Principle
                                                    </p>
                                                </th>
                                                <th className='thdivmainbrdr thchngwidththrd '>
                                                    <p className='prcnttxttemplytxtpp'>Name of the
                                                        regulatory/
                                                        enforcement
                                                        agencies/
                                                        judicial
                                                        institutions
                                                    </p>
                                                </th>
                                                <th className='thdivmainbrdr  thchngwidthfrth' >
                                                    <p className='prcnttxttemplytxtpp'>Amount (In
                                                        INR)
                                                    </p>
                                                </th>
                                                <th className='thdivmainbrdr thchngwidthffth '>
                                                    <p className='prcnttxttemplytxtpp'>Brief of the Case</p>
                                                </th>
                                                <th className='thdivmainbrdr thchngwidthsxth'>
                                                    <p className='prcnttxttemplytxtpp'>Has an
                                                        appeal
                                                        been
                                                        preferred?
                                                        (Yes/No)
                                                    </p>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className='tddivmainbrdr'>
                                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Imprisonment</p>
                                                </td>

                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.imprisonment?.ngrbcPrinciple}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                imprisonment: {
                                                                    ...formvalue.imprisonment,
                                                                    ngrbcPrinciple: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('imprisonment', 'ngrbcPrinciple', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>

                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.imprisonment?.name}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                imprisonment: {
                                                                    ...formvalue.imprisonment,
                                                                    name: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('imprisonment', 'name', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>

                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        type="number"
                                                        value={formvalue?.imprisonment?.amountInINR}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                imprisonment: {
                                                                    ...formvalue.imprisonment,
                                                                    amountInINR: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('imprisonment', 'amountInINR', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.imprisonment?.brief}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                imprisonment: {
                                                                    ...formvalue.imprisonment,
                                                                    brief: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('imprisonment', 'brief', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.imprisonment?.hasAppeal}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                imprisonment: {
                                                                    ...formvalue.imprisonment,
                                                                    hasAppeal: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('imprisonment', 'hasAppeal', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='tddivmainbrdr'>
                                                    <p className='Malettsgvsgphsmkjghhutxtpp'>Punishment</p>

                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.punishment?.ngrbcPrinciple}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                punishment: {
                                                                    ...formvalue.punishment,
                                                                    ngrbcPrinciple: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('punishment', 'ngrbcPrinciple', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.punishment?.name}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                punishment: {
                                                                    ...formvalue.punishment,
                                                                    name: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('punishment', 'name', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        type="number"
                                                        value={formvalue?.punishment?.amountInINR}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                punishment: {
                                                                    ...formvalue.punishment,
                                                                    amountInINR: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('punishment', 'amountInINR', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.punishment?.brief}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                punishment: {
                                                                    ...formvalue.punishment,
                                                                    brief: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('punishment', 'brief', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                                <td className='tddivmainbrdr'>
                                                    {/* <textarea id="" name=""
                                                        value={formvalue?.punishment?.hasAppeal}
                                                        onChange={(e) => {
                                                            setformvalue({
                                                                ...formvalue,
                                                                punishment: {
                                                                    ...formvalue.punishment,
                                                                    hasAppeal: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        rows="2" cols="10">

                                                    </textarea> */}
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleInput('punishment', 'hasAppeal', e.currentTarget.textContent)}
                                                    // placeholder="Type something..."
                                                    >
                                                    </div>
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                </div>


                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Of the instances disclosed in Question 2 above, details of the Appeal/ Revision
                                    preferred in cases where monetary or non-monetary action has been appealed.
                                </li>

                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr casethdivwidthfrst'>
                                            <p className='prcnttxttemplytxtpp'>Case Details
                                            </p> </th>
                                        <th className='thdivmainbrdr casethdivwidthscnd'>
                                            <p className='prcnttxttemplytxtpp'>Name of the regulatory/ enforcement
                                                agencies/ judicial institutions
                                            </p>

                                        </th>

                                    </tr>
                                    <tr>


                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.actionOfMonetaryAndNonMonetary?.caseDetails}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        actionOfMonetaryAndNonMonetary: {
                                                            ...formvalue.actionOfMonetaryAndNonMonetary,
                                                            caseDetails: e.target.value
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('actionOfMonetaryAndNonMonetary', 'caseDetails', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.actionOfMonetaryAndNonMonetary?.name}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        actionOfMonetaryAndNonMonetary: {
                                                            ...formvalue.actionOfMonetaryAndNonMonetary,
                                                            name: e.target.value
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('actionOfMonetaryAndNonMonetary', 'name', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                </table>

                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Does the entity have an anti-corruption or anti-bribery policy? <span>
                                        If yes,
                                    </span> provide details in
                                    brief and if available, provide a web-link to the policy.
                                </li>

                                <div className=''>

                                    <div className='yesnobtndivmain'>
                                        <div className='raditrbtntxt'>
                                            <input type="radio" id=""
                                                // value={formvalue?.entityDetails?.status}
                                                name="status"
                                                value="Yes"
                                                checked={formvalue?.entityDetails?.status === "Yes"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        entityDetails: {
                                                            ...formvalue.entityDetails,
                                                            status: "Yes"
                                                        }
                                                    });
                                                }}
                                            />
                                            <label htmlFor="">Yes</label>
                                        </div>

                                        <div className='raditrbtntxt'>
                                            <input type="radio"
                                                // value={formvalue?.entityDetails?.status}
                                                name="status"
                                                value="Active"
                                                checked={formvalue?.entityDetails?.status === "Active"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        entityDetails: {
                                                            ...formvalue.entityDetails,
                                                            status: "Active"
                                                        }
                                                    });
                                                }}
                                                id="" />
                                            <label htmlFor="">Available</label>
                                        </div>
                                    </div>

                                    <div className='yestrxtwarea'>
                                        {/* <textarea id="" name=''
                                                    value={formvalue?.entityDetails?.description}
                                                    onChange={(e) => {
                                                        setformvalue({
                                                            ...formvalue,
                                                            entityDetails: {
                                                                ...formvalue.entityDetails,
                                                                description: e.target.value
                                                            }
                                                        });
                                                    }}
                                                    rows="2" cols="10">

                                                </textarea> */}
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('entityDetails', 'description', e.currentTarget.textContent)}
                                        // placeholder="Type something..."
                                        >
                                            {/* {formvalue?.entityDetails?.description} */}
                                        </div>
                                    </div>



                                </div>

                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Number of Directors/KMPs/employees/workers against whom disciplinary action was
                                    taken by any law enforcement agency for the charges of bribery/ corruption:
                                </li>

                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr currentdivwidthfrst'> </th>
                                        <th className='thdivmainbrdr currentdivwidthscnd'>
                                            <p className='prcnttxttemplytxtpp'>FY {currentFY}
                                                (Current Financial
                                                Year)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr currentdivwidththrd' >
                                            <p className='prcnttxttemplytxtpp'>FY {previousFY}
                                                (Previous Financial Year)
                                            </p>

                                        </th>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Directors
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.disciplinaryActionDetails?.directors?.currentYear}

                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        disciplinaryActionDetails: {
                                                            ...formvalue.disciplinaryActionDetails,
                                                            directors: {
                                                                ...formvalue.disciplinaryActionDetails?.directors,
                                                                currentYear: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}

                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'disciplinaryActionDetails', 'directors', 'currentYear')}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.disciplinaryActionDetails?.directors?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        disciplinaryActionDetails: {
                                                            ...formvalue.disciplinaryActionDetails,
                                                            directors: {
                                                                ...formvalue.disciplinaryActionDetails.directors,
                                                                previousYear: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'disciplinaryActionDetails', 'directors', 'previousYear')}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>KMPs</p>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.disciplinaryActionDetails?.kmps?.currentYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        disciplinaryActionDetails: {
                                                            ...formvalue.disciplinaryActionDetails,
                                                            kmps: {
                                                                ...formvalue.disciplinaryActionDetails.kmps,
                                                                currentYear: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'disciplinaryActionDetails', 'kmps', 'currentYear')}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.disciplinaryActionDetails?.kmps?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        disciplinaryActionDetails: {
                                                            ...formvalue.disciplinaryActionDetails,
                                                            kmps: {
                                                                ...formvalue.disciplinaryActionDetails.kmps,
                                                                previousYear: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'disciplinaryActionDetails', 'kmps', 'previousYear')}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Employees</p>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.disciplinaryActionDetails?.employees?.currentYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        disciplinaryActionDetails: {
                                                            ...formvalue.disciplinaryActionDetails,
                                                            employees: {
                                                                ...formvalue.disciplinaryActionDetails.employees,
                                                                currentYear: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'disciplinaryActionDetails', 'employees', 'currentYear')}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.disciplinaryActionDetails?.employees?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        disciplinaryActionDetails: {
                                                            ...formvalue.disciplinaryActionDetails,
                                                            employees: {
                                                                ...formvalue.disciplinaryActionDetails.employees,
                                                                previousYear: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'disciplinaryActionDetails', 'employees', 'previousYear')}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Workers</p>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.disciplinaryActionDetails?.workers?.currentYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        disciplinaryActionDetails: {
                                                            ...formvalue.disciplinaryActionDetails,
                                                            workers: {
                                                                ...formvalue.disciplinaryActionDetails.workers,
                                                                currentYear: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'disciplinaryActionDetails', 'workers', 'currentYear')}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name=""
                                                value={formvalue?.disciplinaryActionDetails?.workers?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        disciplinaryActionDetails: {
                                                            ...formvalue.disciplinaryActionDetails,
                                                            workers: {
                                                                ...formvalue.disciplinaryActionDetails.workers,
                                                                previousYear: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                                rows="2" cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'disciplinaryActionDetails', 'workers', 'previousYear')}
                                            // placeholder="Type something..."
                                            >
                                                {/* {text} */}
                                            </div>
                                        </td>

                                    </tr>

                                </table>

                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Details of complaints with regard to conflict of interest:
                                </li>

                                <table className='tablbrdrmain'>
                                    <thead>
                                        <tr className='trdivmainbrdr'>
                                            <th className='currenttsfinnnawidthfrst'></th>
                                            <th colSpan="2" className='thdivmainbrdr currenttsfinnnawidthscnd'>
                                                <p className='prcnttxttemplytxtpp'>
                                                    FY {currentFY} (Current Financial Year)</p>
                                            </th>
                                            <th colSpan="2" className='thdivmainbrdr currenttsfinnnawidththrd'>
                                                <p className='prcnttxttemplytxtpp'>FY {previousFY} (Previous Financial Year)
                                                </p>
                                            </th>
                                        </tr>
                                        <tr className='trdivmainbrdr'>
                                            <th className='thdivmainbrdremniwdthfrst'></th>
                                            <th className='thdivmainbrdr thdivmainbrdremniwdthscnd '>
                                                <p className='mnumbbrttprinvthreetxttppp'>
                                                    Numbers</p></th>
                                            <th className='thdivmainbrdr thdivmainbrdremniwdththrd'>
                                                <p className='mnumbbrttprinvthreetxttppp'>Remarks
                                                </p>
                                            </th>
                                            <th className='thdivmainbrdr thdivmainbrdremniwdthfrth'>
                                                <p className='mnumbbrttprinvthreetxttppp'>Numbers
                                                </p>
                                            </th>
                                            <th className='thdivmainbrdr thdivmainbrdremniwdthffth'>
                                                <p className='mnumbbrttprinvthreetxttppp'>Remarks
                                                </p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='tddivmainbrdrspclnewone'>
                                                <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                    Number of complaints received in relation to issues of Conflict of Interest of the Directors
                                                </p>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea
                                                    value={formvalue?.directorsNoOfComplaints?.currentYear?.number}
                                                    onChange={(e) => handleInputChange(e, 'directorsNoOfComplaints', 'currentYear', 'number')}
                                                    rows="2" cols="10">
                                                </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'directorsNoOfComplaints', 'currentYear', 'number')}
                                                // placeholder="Type something..."
                                                >
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea
                                                    value={formvalue?.directorsNoOfComplaints?.currentYear?.remarks}
                                                    onChange={(e) => handleInputChange(e, 'directorsNoOfComplaints', 'currentYear', 'remarks')}
                                                    rows="2" cols="10">
                                                </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'directorsNoOfComplaints', 'currentYear', 'remarks')}
                                                // placeholder="Type something..."
                                                >
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea
                                                    value={formvalue?.directorsNoOfComplaints?.previousYear?.number}
                                                    onChange={(e) => handleInputChange(e, 'directorsNoOfComplaints', 'previousYear', 'number')}
                                                    rows="2" cols="10">
                                                </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'directorsNoOfComplaints', 'previousYear', 'number')}
                                                // placeholder="Type something..."
                                                >
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea
                                                    value={formvalue?.directorsNoOfComplaints?.previousYear?.remarks}
                                                    onChange={(e) => handleInputChange(e, 'directorsNoOfComplaints', 'previousYear', 'remarks')}
                                                    rows="2" cols="10">
                                                </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'directorsNoOfComplaints', 'previousYear', 'remarks')}
                                                // placeholder="Type something..."
                                                >
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className='tddivmainbrdr'>
                                                <p className='Malettsgvsgphsmkjghhutxtpp'> Number of complaints received in relation to issues of Conflict of Interest of the KMPs</p>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea
                                                    type="number"
                                                    value={formvalue?.kmpsNoOfComplaints?.currentYear?.number}
                                                    onChange={(e) => handleInputChange(e, 'kmpsNoOfComplaints', 'currentYear', 'number')}
                                                    rows="2" cols="10">
                                                </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'kmpsNoOfComplaints', 'currentYear', 'number')}
                                                // placeholder="Type something..."
                                                >
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea
                                                    value={formvalue?.kmpsNoOfComplaints?.currentYear?.remarks}
                                                    onChange={(e) => handleInputChange(e, 'kmpsNoOfComplaints', 'currentYear', 'remarks')}
                                                    rows="2" cols="10">
                                                </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'kmpsNoOfComplaints', 'currentYear', 'remarks')}
                                                // placeholder="Type something..."
                                                >
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea
                                                    type="number"
                                                    value={formvalue?.kmpsNoOfComplaints?.previousYear?.number}
                                                    onChange={(e) => handleInputChange(e, 'kmpsNoOfComplaints', 'previousYear', 'number')}
                                                    rows="2" cols="10">
                                                </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'kmpsNoOfComplaints', 'previousYear', 'number')}
                                                // placeholder="Type something..."
                                                >
                                                </div>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                {/* <textarea
                                                    value={formvalue?.kmpsNoOfComplaints?.previousYear?.remarks}
                                                    onChange={(e) => handleInputChange(e, 'kmpsNoOfComplaints', 'previousYear', 'remarks')}
                                                    rows="2" cols="10">
                                                </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'kmpsNoOfComplaints', 'previousYear', 'remarks')}
                                                // placeholder="Type something..."
                                                >
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className='adddinciondivimg'>
                                    <img src={AddIcon} alt='...' />
                                    <span>Add More</span>
                                </div>
                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Provide details of any corrective action taken or underway on issues related to fines /
                                    penalties / action taken by regulators/ law enforcement agencies/ judicial institutions,
                                    on cases of corruption and conflicts of interest.
                                </li>

                                <div className='yestrxtwarea'>
                                    <div className='yesnobtndivmain'>
                                        <div className='raditrbtntxt'>
                                            <input type="radio" id=""
                                                // value={formvalue?.entityDetails?.status}
                                                name="actionStatus"
                                                value="Applicable"
                                                checked={formvalue?.provideActionDetails?.actionStatus === "Applicable"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        provideActionDetails: {
                                                            ...formvalue.provideActionDetails,
                                                            actionStatus: "Applicable"
                                                        }
                                                    });
                                                }}
                                            />
                                            <label htmlFor="">Applicable</label>
                                        </div>

                                        <div className='raditrbtntxt'>
                                            <input type="radio"
                                                // value={formvalue?.entityDetails?.status}
                                                name="actionStatus"
                                                value="Not Applicable"
                                                checked={formvalue?.provideActionDetails?.actionStatus === "Not Applicable"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        provideActionDetails: {
                                                            ...formvalue.provideActionDetails,
                                                            actionStatus: "Not Applicable"
                                                        }
                                                    });
                                                }}
                                                id="" />
                                            <label htmlFor="">Not Applicable</label>
                                        </div>
                                    </div>
                                    {/* <textarea id="" name='' rows="2"
                                        value={formvalue?.provideActionDetails}
                                        onChange={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                provideActionDetails: e.target.value
                                            });
                                        }}
                                        cols="10">

                                    </textarea> */}
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('provideActionDetails', 'description', e.currentTarget.textContent)}
                                    // placeholder="Type something..."
                                    >
                                    </div>
                                </div>

                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Number of days of accounts payables ((Accounts payable *365) / Cost of
                                    goods/services procured) in the following format:
                                </li>

                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr thdivmainbrdrfinnsmwiipdinfrst'> </th>
                                        <th className='thdivmainbrdr  thdivmainbrdrfinnsmwiipdinscnd'>
                                            <p className='prcnttxttemplytxtpp'>FY {currentFY}
                                                (Current Financial
                                                Year)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr thdivmainbrdrfinnsmwiipdinthrd' >
                                            <p className='prcnttxttemplytxtpp'>FY {previousFY}
                                                (Previous Financial Year)
                                            </p>

                                        </th>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <p className='Malettsgvsgphsmkjghhutxtpp'> Number of days of
                                                accounts payables
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.noOfDaysOfAccountPayables?.currentYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        noOfDaysOfAccountPayables: {
                                                            ...formvalue.noOfDaysOfAccountPayables,
                                                            currentYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('noOfDaysOfAccountPayables', 'currentYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.noOfDaysOfAccountPayables?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        noOfDaysOfAccountPayables: {
                                                            ...formvalue.noOfDaysOfAccountPayables,
                                                            previousYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('noOfDaysOfAccountPayables', 'previousYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                    </tr>


                                </table>

                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Open-ness of business
                                    Provide details of concentration of purchases and sales with trading houses, dealers,
                                    and related parties along-with loans and advances & investments, with related parties,
                                    in the following format:
                                </li>

                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr thdivmainbrdrninewidthfrst'>
                                            <p className='prcnttxttemplytxtpp'>
                                                Parameter</p></th>
                                        <th className='thdivmainbrdr thdivmainbrdrninewidthscnd'>
                                            <p className='prcnttxttemplytxtpp'>Metrics
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr thdivmainbrdrninewidththrd'>
                                            <p className='prcnttxttemplytxtpp'>FY {currentFY}
                                                (Current Financial
                                                Year)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr thdivmainbrdrninewidthfrth' >
                                            <p className='prcnttxttemplytxtpp'>FY {previousFY}
                                                (Previous Financial Year)
                                            </p>

                                        </th>

                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr' rowSpan="3">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                Concentration
                                                of Purchases</p>
                                        </td>
                                        <td className='tddivmainbrdr' >
                                            <p className='Malettsgvsgphsmkjghhutxtpp'> Purchases from trading
                                                houses as % of total
                                                purchases
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.percentageOfTotalPurchase?.currentYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        percentageOfTotalPurchase: {
                                                            ...formvalue.percentageOfTotalPurchase,
                                                            currentYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">


                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('percentageOfTotalPurchase', 'currentYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.percentageOfTotalPurchase?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        percentageOfTotalPurchase: {
                                                            ...formvalue.percentageOfTotalPurchase,
                                                            previousYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('percentageOfTotalPurchase', 'previousYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr' >
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>  Number of trading
                                                houses where
                                                purchases are made
                                                from
                                            </p>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.noOfTradingHouse?.currentYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        noOfTradingHouse: {
                                                            ...formvalue.noOfTradingHouse,
                                                            currentYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput("noOfTradingHouse", "currentYear", e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.noOfTradingHouse?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        noOfTradingHouse: {
                                                            ...formvalue.noOfTradingHouse,
                                                            previousYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('noOfTradingHouse', 'previousYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr' >
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>  Purchases from top 10
                                                trading houses as % of
                                                total purchases from
                                                trading houses
                                            </p>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.topTenNoOfTrading?.currentYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        topTenNoOfTrading: {
                                                            ...formvalue.topTenNoOfTrading,
                                                            currentYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('topTenNoOfTrading', 'currentYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.topTenNoOfTrading?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        topTenNoOfTrading: {
                                                            ...formvalue.topTenNoOfTrading,
                                                            previousYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('topTenNoOfTrading', 'previousYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='tddivmainbrdr' rowSpan="3">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>  Concentration
                                                of Sales
                                            </p>

                                        </th>
                                        <td className='tddivmainbrdr' >
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>  Sales to dealers /
                                                distributors as % of
                                                total sales
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.concentrationOfSale?.percentageOfTotalSale?.currentYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        concentrationOfSale: {
                                                            ...formvalue.concentrationOfSale,
                                                            percentageOfTotalSale: {
                                                                ...formvalue.concentrationOfSale?.percentageOfTotalSale,
                                                                currentYear: e.target.value
                                                            }

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'concentrationOfSale', 'percentageOfTotalSale', 'currentYear')}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.concentrationOfSale?.percentageOfTotalSale?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        concentrationOfSale: {
                                                            ...formvalue.concentrationOfSale,
                                                            percentageOfTotalSale: {
                                                                ...formvalue.concentrationOfSale?.percentageOfTotalSale,
                                                                previousYear: e.target.value

                                                            }
                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'concentrationOfSale', 'percentageOfTotalSale', 'previousYear')}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr' >
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>  Number of dealers /
                                                distributors to whom
                                                sales are made
                                            </p>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.concentrationOfSale?.noOfTradingHouse?.currentYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        concentrationOfSale: {
                                                            ...formvalue.concentrationOfSale,
                                                            noOfTradingHouse: {
                                                                ...formvalue.concentrationOfSale?.noOfTradingHouse,
                                                                currentYear: e.target.value

                                                            }
                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'concentrationOfSale', 'noOfTradingHouse', 'currentYear')}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.concentrationOfSale?.noOfTradingHouse?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        concentrationOfSale: {
                                                            ...formvalue.concentrationOfSale,
                                                            noOfTradingHouse: {
                                                                ...formvalue.concentrationOfSale?.noOfTradingHouse,
                                                                previousYear: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                                cols="10">


                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'concentrationOfSale', 'noOfTradingHouse', 'previousYear')}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr' >
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>  Sales to top 10 dealers
                                                / distributors as % of
                                                total sales to dealers /
                                                distributors
                                            </p>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.concentrationOfSale?.topTenNoOfTrading?.currentYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        concentrationOfSale: {
                                                            ...formvalue.concentrationOfSale,
                                                            topTenNoOfTrading: {
                                                                ...formvalue.concentrationOfSale?.topTenNoOfTrading,
                                                                currentYear: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'concentrationOfSale', 'topTenNoOfTrading', 'currentYear')}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.concentrationOfSale?.topTenNoOfTrading?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        concentrationOfSale: {
                                                            ...formvalue.concentrationOfSale,
                                                            topTenNoOfTrading: {
                                                                ...formvalue.concentrationOfSale?.topTenNoOfTrading,
                                                                previousYear: e.target.value
                                                            }
                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInputChange(e, 'concentrationOfSale', 'topTenNoOfTrading', 'previousYear')}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='tddivmainbrdr' rowSpan="4">
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>  Share of RPTs in</p>

                                        </th>
                                        <td className='tddivmainbrdrspclnewone' >
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>  Purchases (Purchases
                                                with related parties /
                                                Total Purchases)
                                            </p>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.totalPurchase?.currentYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        totalPurchase: {
                                                            ...formvalue.totalPurchase,
                                                            currentYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('totalPurchase', 'currentYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.totalPurchase?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        totalPurchase: {
                                                            ...formvalue.totalPurchase,
                                                            previousYear: e.target.value

                                                        }
                                                    });
                                                }}

                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('totalPurchase', 'previousYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr' >
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>  Sales (Sales to related
                                                parties / Total Sales)
                                            </p>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.totalSales?.currentYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        totalSales: {
                                                            ...formvalue.totalSales,
                                                            currentYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('totalSales', 'currentYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.totalSales?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        totalSales: {
                                                            ...formvalue.totalSales,
                                                            previousYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('totalSales', 'previousYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr' >
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Loans & advances
                                                (Loans & advances
                                                given to related parties
                                                / Total loans &
                                                advances)
                                            </p>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.totalLoan?.currentYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        totalLoan: {
                                                            ...formvalue.totalLoan,
                                                            currentYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('totalLoan', 'currentYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.totalLoan?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        totalLoan: {
                                                            ...formvalue.totalLoan,
                                                            previousYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('totalLoan', 'previousYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr' >
                                            <p className='Malettsgvsgphsmkjghhutxtpp'>Investments
                                                ( Investments in related
                                                parties / Total
                                                Investments made)
                                            </p>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.investment?.currentYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        investment: {
                                                            ...formvalue.investment,
                                                            currentYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('investment', 'currentYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.investment?.previousYear}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        investment: {
                                                            ...formvalue.investment,
                                                            previousYear: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('investment', 'previousYear', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                </table>

                            </div>

                        </ul>
                    </div>
                </div >
                <div className='borderessentialin'>
                    <p className='idicatrstxtpp'>Leadership Indicators
                    </p>
                    <div className=''>
                        <ul className='listoftblxsdivul'>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Awareness programmes conducted for value chain partners on any of the Principles
                                    during the financial year:

                                </li>

                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr thdivmainbrdrindictrrfsrt'>
                                            <p className='prcnttxttemplytxtpp'>
                                                Total number of awareness
                                                programmes held
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr thdivmainbrdrindictrrscnd'>
                                            <p className='prcnttxttemplytxtpp'>Topics / principles
                                                covered under the
                                                training
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr thdivmainbrdrindictrrthrd' >
                                            <p className='prcnttxttemplytxtpp'>%age of value chain
                                                partners covered (by value
                                                of business done with such
                                                partners) under the
                                                awareness programmes
                                            </p>

                                        </th>

                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                type="number"
                                                value={formvalue?.awarnessProgrammesDetails?.totalNoOfAwareness}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        awarnessProgrammesDetails: {
                                                            ...formvalue.awarnessProgrammesDetails,
                                                            totalNoOfAwareness: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('awarnessProgrammesDetails', 'totalNoOfAwareness', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.awarnessProgrammesDetails?.topics}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        awarnessProgrammesDetails: {
                                                            ...formvalue.awarnessProgrammesDetails,
                                                            topics: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('awarnessProgrammesDetails', 'topics', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>

                                        <td className='tddivmainbrdr'>
                                            {/* <textarea id="" name="" rows="2"
                                                value={formvalue?.awarnessProgrammesDetails?.ageOfPercentage}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        awarnessProgrammesDetails: {
                                                            ...formvalue.awarnessProgrammesDetails,
                                                            ageOfPercentage: e.target.value

                                                        }
                                                    });
                                                }}
                                                cols="10">

                                            </textarea> */}
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('awarnessProgrammesDetails', 'ageOfPercentage', e.currentTarget.textContent)}
                                            // placeholder="Type something..."
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                </table>

                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Does the entity have processes in place to avoid/ manage conflict of interests involving
                                    members of the Board? <span> (Yes/No)
                                    </span> If Yes, provide details of the same.
                                </li>

                                <div className=''>
                                    
                                        <div className='yesnobtndivmain'>
                                            <div className='raditrbtntxt'>
                                                <input type="radio" id="" name=""
                                                    value={true}
                                                    checked={formvalue?.conflictOfInterestDetails?.entityManageStatus === "Yes"}
                                                    onChange={(e) => {
                                                        setformvalue({
                                                            ...formvalue,
                                                            conflictOfInterestDetails: {
                                                                ...formvalue.conflictOfInterestDetails,
                                                                entityManageStatus: "Yes"

                                                            }
                                                        });
                                                    }}
                                                />
                                                <label htmlFor="">Yes</label>
                                            </div>

                                            <div className='raditrbtntxt'>
                                                <input type="radio" id="" name=""
                                                    // value="NO"
                                                    value={false}
                                                    checked={formvalue?.conflictOfInterestDetails?.entityManageStatus === "No"}
                                                    onChange={(e) => {
                                                        setformvalue({
                                                            ...formvalue,
                                                            conflictOfInterestDetails: {
                                                                ...formvalue.conflictOfInterestDetails,
                                                                entityManageStatus: "No"

                                                            }
                                                        });
                                                    }}
                                                />
                                                <label htmlFor="">No</label>
                                            </div>
                                        </div>

                                        {
                                            formvalue?.conflictOfInterestDetails?.entityManageStatus === "Yes" &&
                                            <div className='yestrxtwarea'>
                                                {/* <textarea id="" name='' rows="2"
                                                    value={formvalue?.conflictOfInterestDetails?.description}
                                                    onChange={(e) => {
                                                        setformvalue({
                                                            ...formvalue,
                                                            conflictOfInterestDetails: {
                                                                ...formvalue.conflictOfInterestDetails,
                                                                description: e.target.value

                                                            }
                                                        });
                                                    }}
                                                    cols="10">

                                                </textarea> */}
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInput('conflictOfInterestDetails', 'description', e.currentTarget.textContent)}
                                                // placeholder="Type something..."
                                                >
                                                </div>
                                            </div>
                                        }



                                
                                </div>

                            </div>
                        </ul>
                    </div>
                </div>
            </div >
            {/* button */}
            <div className="homePgCreModSubmitDiv">
                <button
                    className="homePgCreModSubmitBtn btn1"
                    type="button"
                    onClick={submitHandler}
                >
                    Next
                </button>
            </div>
        </>
    )
};

export default Principleone
