import React, { useState } from 'react'
import Retakequizemodal from '../../Modal/Retakequizemodal'
import Congratulationquizemodal from '../../Modal/Congratulationquizemodal'
import Certificatecomp from './Certificatecomp'
import Upnextcomp from './Upnextcomp'

const Quizemain = () => {
    const [congratulationmaodala, setCongratulationmaodala] = useState(false)
    const [rtkquizemodala, setRtkquizemodala] = useState(false)
    const submtqizeclick = () => {
        setRtkquizemodala(!rtkquizemodala);
        setCongratulationmaodala(!congratulationmaodala)
    }
    return (
        <>
            <section className='qiizesectionmain'>
                <div className='esgpagecontainer'>
                    <div className='quizedatadivflx'>
                        <div className='quizedatadivleft'>
                            <div className="quizedatadivleftbgmain">
                                <div className='quizedatadivleftbgmaintop'>
                                    <div className=''>
                                        <p className='quizemainheadrtxtpp'>
                                            Complete Your Quize
                                        </p>
                                    </div>
                                </div>
                                <div className='quizedatadivleftbgmainbottom'>
                                    <ul className='feedbnckjhsdivul'>
                                        <li className='feedbnckjhsdivli'>
                                            <div className="quizedivwidthf">
                                                <p className="questiontxtquize">Full form of RAM?</p>
                                            </div>
                                            <div className='cjhekeddivtxtquizedrp'>
                                                <input
                                                    type='checkbox'
                                                    value="A" />
                                                <label>A. Random Access Memory</label>
                                            </div>
                                            <div className='cjhekeddivtxtquizedrp'>
                                                <input
                                                    type='checkbox'
                                                    value="A" />
                                                <label>B. Read Access Memory</label>
                                            </div>
                                            <div className='cjhekeddivtxtquizedrp'>
                                                <input
                                                    type='checkbox'
                                                    value="A" />
                                                <label>C. Read Admit Memory</label>
                                            </div>
                                            <div className='cjhekeddivtxtquizedrp'>
                                                <input
                                                    type='checkbox'
                                                    value="A" />
                                                <label>D. Random Admit Memory</label>
                                            </div>

                                        </li>

                                        <li className='feedbnckjhsdivli'>
                                            <div className="quizedivwidthf">
                                                <p className="questiontxtquize">Are You Happy ?</p>
                                            </div>
                                            <div className='radiobtntxtppquizeansradionew'>
                                                <input
                                                    type="radio"
                                                    value="A"
                                                />
                                                <label>Yes</label>
                                            </div>
                                            <div className='radiobtntxtppquizeansradionew'>
                                                <input
                                                    type="radio"
                                                    value="B"
                                                />
                                                <label>No</label>
                                            </div>
                                        </li>

                                        <li className='feedbnckjhsdivli'>
                                            <div className="quizedivwidthf">
                                                <p className="questiontxtquize">Full form of RAM?</p>
                                            </div>
                                            <div className='cjhekeddivtxtquizedrp'>
                                                <input
                                                    type='checkbox'
                                                    value="A" />
                                                <label>A. Random Access Memory</label>
                                            </div>
                                            <div className='cjhekeddivtxtquizedrp'>
                                                <input
                                                    type='checkbox'
                                                    value="A" />
                                                <label>B. Read Access Memory</label>
                                            </div>
                                            <div className='cjhekeddivtxtquizedrp'>
                                                <input
                                                    type='checkbox'
                                                    value="A" />
                                                <label>C. Read Admit Memory</label>
                                            </div>
                                            <div className='cjhekeddivtxtquizedrp'>
                                                <input
                                                    type='checkbox'
                                                    value="A" />
                                                <label>D. Random Admit Memory</label>
                                            </div>

                                        </li>
                                    </ul>
                                    <button className='sbmtdbtbhmaiondivquize' onClick={submtqizeclick}>
                                        Submit
                                    </button>

                                </div>

                            </div>
                        </div>
                        <div className='quizedatadivright'>
                           <div className=''>
                            <Certificatecomp/>
                           </div>
                           <div className='upnexrttemphjjdivbtm'>
                            <Upnextcomp/>
                           </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Important Comment. This is for Retakequize Modal */}

            {/* {rtkquizemodala && <Retakequizemodal/>} */}

            {/* Important Comment. This is for Congratulationquize Modal */}

            {/* {congratulationmaodala && <Congratulationquizemodal/>} */}
        </>
    )
}

export default Quizemain
