import React, { useEffect, useRef, useState } from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png"
import HttpClientXml from '../../utils/HttpClientXml';
import HttpClient from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';


const Principleeghit = () => {
    const initvalue = {
        correstiveActionTakenDetails: {
            details: ""
        },

        detailsOfBenifitDerived: {
            impression: ""
        },

        marginalizedGroups: { //3
            isAvailbleProcurementPolicy: "Yes",
            isAvailbleProcurementPolicyDetails: "",
            nameOfGroups: "",
            percentOfTotalProcurement: ""
        },

        infromationOfCsrProject: {
            impression: ""
        },

        percentageOfInputMaterial: {
            impression: ""
        },

        describeTheMechanism: "",

        informationOfProject: { //2
            slOne: {
                sNo: 0,
                nameForRrOngoing: "",
                state: "",
                district: "",
                nameForAffectedFamilies: "",
                percentOfPafConverdByRr: "",
                accountPaidToPaf: ""
            },
            slTwo: {
                sNo: 0,
                nameForRrOngoing: "",
                state: "",
                district: "",
                nameForAffectedFamilies: "",
                percentOfPafConverdByRr: "",
                accountPaidToPaf: ""
            }
        },

        detailsOfSIA: {
            details: ""
        }

    }

    const initentityDetails = {
        nameAndDetails: "",
        dateOfNotificationNo: "",
        siaNotificationNo: "",
        externalAgencyStatus: "Yes",
        isResultCommunicatedInPublic: "Yes",
        relevantWebLink: ""
    }

    const initentityDetails1 = {
        particulars: "",
        currentYear: "",
        previousYear: ""
    }

    const initentityDetails2 = {
        slNo: 0,
        state: "",
        aspirationalDistrict: "",
        spentAmmont: 0
    }

    const initentityDetails3 = {
        sNo: 0,
        intellectualProperty: "",
        ownedOrAcquired: "Yes",
        benifitShared: "Yes",
        basicOfBeniftShare: ""
    }


    const initentityDetails4 = {
        nameOfAuthority: "",
        briefOfCase: "",
        correctiveActonTaken: ""
    }

    const initdetailsOfActionToMitigate = {
        detailsOfNegative: "",
        corrective: ""
    }

    const initjobCreationInSmallertown = {
        location: "",
        currentYear: "",
        financialYear: ""
    }

    const initdetailsOfBenificiariesOfCsrProject = {
        slNo: "",
        csrProject: "",
        benefittedName: "",
        percentOfBenificiaries: ""
    }


    // const [text, setText] = useState('');
    const divRef = useRef(null);
    const [principleOneData, setPrincipleOneData] = useState({});
    const [formvalue, setformvalue] = useState(initvalue);
    const [entityDetails, setentityDetails] = useState([initentityDetails]);
    const [entityDetails1, setentityDetails1] = useState([initentityDetails1]);
    const [entityDetails2, setentityDetails2] = useState([initentityDetails2]);
    const [entityDetails3, setentityDetails3] = useState([initentityDetails3]);
    const [entityDetails4, setentityDetails4] = useState([initentityDetails4]);
    const [notemodal1, setnotemodal1] = useState(false);
    const [notemodal2, setnotemodal2] = useState(false);
    const [jobCreationInSmallertown, setjobCreationInSmallertown] = useState([initjobCreationInSmallertown]);
    const [detailsOfActionToMitigate, setdetailsOfActionToMitigate] = useState([initdetailsOfActionToMitigate]);
    const [detailsOfBenificiariesOfCsrProject, setdetailsOfBenificiariesOfCsrProject] = useState([initdetailsOfBenificiariesOfCsrProject])
    const [currentFY, setCurrentFY] = useState("");
    const [previousFY, setPreviousFY] = useState("");

    useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const startMonth = 4; // April is the start of the financial year

        if (currentDate.getMonth() + 1 >= startMonth) {
            setCurrentFY(`${currentYear}-${currentYear + 1}`);
            setPreviousFY(`${currentYear - 1}-${currentYear}`);
        } else {
            setCurrentFY(`${currentYear - 1}-${currentYear}`);
            setPreviousFY(`${currentYear - 2}-${currentYear - 1}`);
        }

    }, []);

    useEffect(() => {
        getPrincipleOne();
    }, [])


    //for getting principleone
    const getPrincipleOne = async () => {
        const res = await HttpClientXml?.requestData(`get-principle`, {}, "GET");
        if (res?.status) {
            setPrincipleOneData(res?.data?.[0]);
        }
    }


    //for Input fields
    const handleInput = (category, field, value) => {
        setformvalue((prevValue) => ({
            ...prevValue,
            [category]: {
                ...prevValue[category],
                [field]: value,
            }
        }));
    }


    //handle change for entityDetails add more functionality
    const handleChangeentity = (i, e, field) => {
        let newFormValues = [...entityDetails];
        newFormValues[i][field] = e.currentTarget.textContent;
        setentityDetails(newFormValues);
    };


    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails = () => {
        setentityDetails([...entityDetails, initentityDetails]);
    };


    //for removing add more field
    const removeFormFieldsentityDetails = (i) => {
        let newFormValues = [...entityDetails];
        newFormValues.splice(i, 1);
        setentityDetails(newFormValues);
    };


    //handle change for entityDetails add more functionality
    const handleChangeentity1 = (i, e, field) => {
        let newFormValues = [...entityDetails1];
        newFormValues[i][field] = e.currentTarget.textContent;
        setentityDetails1(newFormValues);
    };

    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails1 = () => {
        setentityDetails1([...entityDetails1, initentityDetails1]);
    };

    //for removing add more field
    const removeFormFieldsentityDetails1 = (i) => {
        let newFormValues = [...entityDetails1];
        newFormValues.splice(i, 1);
        setentityDetails1(newFormValues);
    };


    //handle change for entityDetails add more functionality
    const handleChangeentity2 = (i, e, field) => {
        let newFormValues = [...entityDetails2];
        newFormValues[i][field] = e.currentTarget.textContent;
        setentityDetails2(newFormValues);
    };

    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails2 = () => {
        setentityDetails2([...entityDetails2, initentityDetails2]);
    };

    //for removing add more field
    const removeFormFieldsentityDetails2 = (i) => {
        let newFormValues = [...entityDetails2];
        newFormValues.splice(i, 1);
        setentityDetails2(newFormValues);
    };


    //handle change for entityDetails add more functionality
    const handleChangeentity3 = (i, e, field) => {
        let newFormValues = [...entityDetails3];
        newFormValues[i][field] = e.currentTarget.textContent;
        setentityDetails3(newFormValues);
    };


    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails3 = () => {
        setentityDetails3([...entityDetails3, initentityDetails3]);
    };


    //for removing add more field
    const removeFormFieldsentityDetails3 = (i) => {
        let newFormValues = [...entityDetails3];
        newFormValues.splice(i, 1);
        setentityDetails3(newFormValues);
    };


    //handle change for entityDetails add more functionality
    const handleChangeentity4 = (i, e, field) => {
        let newFormValues = [...entityDetails4];
        newFormValues[i][field] = e.currentTarget.textContent;
        setentityDetails4(newFormValues);
    };


    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails4 = () => {
        setentityDetails4([...entityDetails4, initentityDetails4]);
    };


    //for removing add more field
    const removeFormFieldsentityDetails4 = (i) => {
        let newFormValues = [...entityDetails4];
        newFormValues.splice(i, 1);
        setentityDetails4(newFormValues);
    };


    //handle change for entityDetails add more functionality
    const handleChangejobCreationInSmallertown = (i, e, field) => {
        let newFormValues = [...jobCreationInSmallertown];
        newFormValues[i][field] = e.currentTarget.textContent;
        setjobCreationInSmallertown(newFormValues);
    };


    //for entityDetails first add more functionality 
    const addFormFieldsjobCreationInSmallertown = () => {
        setjobCreationInSmallertown([...jobCreationInSmallertown, initjobCreationInSmallertown]);
    };


    //for removing add more field
    const removeFormFieldsjobCreationInSmallertown = (i) => {
        let newFormValues = [...jobCreationInSmallertown];
        newFormValues.splice(i, 1);
        setjobCreationInSmallertown(newFormValues);
    };


    //handle change for entityDetails add more functionality
    const handleChangedetailsOfActionToMitigate = (i, e, field) => {
        let newFormValues = [...detailsOfActionToMitigate];
        newFormValues[i][field] = e.currentTarget.textContent;
        setdetailsOfActionToMitigate(newFormValues);
    };


    //for entityDetails first add more functionality 
    const addFormFieldsdetailsOfActionToMitigate = () => {
        setdetailsOfActionToMitigate([...detailsOfActionToMitigate, initdetailsOfActionToMitigate]);
    };


    //for removing add more field
    const removeFormFieldsdetailsOfActionToMitigate = (i) => {
        let newFormValues = [...detailsOfActionToMitigate];
        newFormValues.splice(i, 1);
        setdetailsOfActionToMitigate(newFormValues);
    };


    //handle change for entityDetails add more functionality
    const handleChangedetailsOfBenificiariesOfCsrProject = (i, e, field) => {
        let newFormValues = [...detailsOfBenificiariesOfCsrProject];
        newFormValues[i][field] = e.currentTarget.textContent;
        setdetailsOfBenificiariesOfCsrProject(newFormValues);
    };


    //for entityDetails first add more functionality 
    const addFormFieldsdetailsOfBenificiariesOfCsrProject = () => {
        setdetailsOfBenificiariesOfCsrProject([...detailsOfBenificiariesOfCsrProject, initdetailsOfBenificiariesOfCsrProject]);
    };


    //for removing add more field
    const removeFormFieldsdetailsOfBenificiariesOfCsrProject = (i) => {
        let newFormValues = [...detailsOfBenificiariesOfCsrProject];
        newFormValues.splice(i, 1);
        setdetailsOfBenificiariesOfCsrProject(newFormValues);
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        let data = {};

        data = {
            "principleEight": {
                "currentYear": currentFY,
                "previousYear": previousFY,
                "essentialIndicators": {
                    "detailsOfSIA": { //1
                        "entityDetails": entityDetails,
                        "details": formvalue?.detailsOfSIA?.details
                    },
                    "informationOfProject": { //2
                        "slOne": {
                            "sNo": formvalue?.slOne?.sNo,
                            "nameForRrOngoing": formvalue?.slOne?.nameForRrOngoing,
                            "state": formvalue?.slOne?.state,
                            "district": formvalue?.slOne?.district,
                            "nameForAffectedFamilies": formvalue?.slOne?.nameForAffectedFamilies,
                            "percentOfPafConverdByRr": formvalue?.slOne?.percentOfPafConverdByRr,
                            "accountPaidToPaf": formvalue?.slOne?.accountPaidToPaf
                        },
                        "slTwo": {
                            "sNo": formvalue?.slTwo?.sNo,
                            "nameForRrOngoing": formvalue?.slTwo?.nameForRrOngoing,
                            "state": formvalue?.slTwo?.state,
                            "district": formvalue?.slTwo?.district,
                            "nameForAffectedFamilies": formvalue?.slTwo?.nameForAffectedFamilies,
                            "percentOfPafConverdByRr": formvalue?.slTwo?.percentOfPafConverdByRr,
                            "accountPaidToPaf": formvalue?.slTwo?.accountPaidToPaf
                        }
                    },
                    "describeTheMechanism": formvalue?.describeTheMechanism, //3
                    "percentageOfInputMaterial": { //4
                        "entityDetails": entityDetails1,
                        "impression": formvalue?.percentageOfInputMaterial?.impression
                    },
                    "jobCreationInSmallertown": jobCreationInSmallertown
                },
                "leadershipIndicators": {
                    "detailsOfActionToMitigate": detailsOfActionToMitigate,
                    "infromationOfCsrProject": { //2
                        "entityDetails": entityDetails2,
                        "impression": formvalue?.informationOfProject?.impression
                    },
                    "marginalizedGroups": { //3
                        "isAvailbleProcurementPolicy": formvalue?.marginalizedGroups?.isAvailbleProcurementPolicy,
                        "isAvailbleProcurementPolicyDetails": formvalue?.marginalizedGroups?.isAvailbleProcurementPolicyDetails,
                        "nameOfGroups": formvalue?.marginalizedGroups?.nameOfGroups,
                        "percentOfTotalProcurement": formvalue?.marginalizedGroups?.percentOfTotalProcurement
                    },
                    "detailsOfBenifitDerived": { //4
                        "entityDetails": entityDetails3,
                        "impression": formvalue?.detailsOfBenifitDerived?.impression
                    },
                    "correstiveActionTakenDetails": { //5
                        "entityDetails": entityDetails4,
                        "details": formvalue?.correstiveActionTakenDetails?.details
                    },
                    "detailsOfBenificiariesOfCsrProject": detailsOfBenificiariesOfCsrProject
                }
            }
        }

        console.log('principleeight', data);
        const res = await HttpClient?.requestData(
            `update-enviroment-principle/${principleOneData?._id}`,
            data,
            "POST"
        );
        if (res?.status) {
            toast?.success("data is added successfully");
            // navigate('/pricipletwotemp')
            setformvalue(initvalue);
            const contentEditableElements = document.querySelectorAll('[contentEditable]');
            contentEditableElements?.forEach(element => element.textContent = "");
            setentityDetails([initentityDetails])
            setentityDetails1([initentityDetails1]);
            setentityDetails2([initentityDetails2]);
            setentityDetails3([initentityDetails3]);
            setentityDetails4([initentityDetails4]);
            setjobCreationInSmallertown([initjobCreationInSmallertown]);
            setdetailsOfActionToMitigate([initdetailsOfActionToMitigate]);
            setdetailsOfBenificiariesOfCsrProject([initdetailsOfBenificiariesOfCsrProject])
        } else {
            toast?.error(res?.response?.data?.message || "Something went wrong");
        }
    }


    return (
        <>
            <div className='' >
                <h4 className='principletxtpp'>
                    PRINCIPLE 8 Businesses should promote inclusive growth and
                    equitable development

                </h4>
                <div className='borderessentialin'>
                    <p className='idicatrstxtpp'> Essential Indicators</p>
                    <div className=''>
                        <ul className='listoftblxsdivul'>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Details of Social Impact Assessments (SIA) of projects undertaken by the entity based on applicable laws, in the
                                    current financial year:
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr princlpeegthpintonedivfrst'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Name and brief
                                                details of project
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princlpeegthpintonedivscnd '>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                SIA
                                                Notification
                                                No.

                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princlpeegthpintonedivthrd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Date of
                                                notification

                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr  princlpeegthpintonedivfrth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Whether
                                                conducted by
                                                independent
                                                external
                                                agency (Yes /
                                                No)

                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princlpeegthpintonedivffth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Results
                                                communicated
                                                in public
                                                domain
                                                (Yes / No)

                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princlpeegthpintonedivsxth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Relevant
                                                Web
                                                link

                                            </p>
                                        </th>
                                    </tr>
                                    {
                                        entityDetails?.map((item, ind) => {
                                            return (
                                                <tr>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "nameAndDetails")}
                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "dateOfNotificationNo")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "siaNotificationNo")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "externalAgencyStatus")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "isResultCommunicatedInPublic")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity(ind, e, "relevantWebLink")}
                                                        >
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }


                                </table>
                                <div className="adddinciondivimg">
                                    <img src={Addicon} alt="..." onClick={addFormFieldsentityDetails} />
                                    <span>Add More</span>
                                </div>
                                <div className=" ">
                                    <p className=" dtlsttfvdvgppphjp noiriittxoppdjoyyypp">Details :</p>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('detailsOfSIA', 'details', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Provide information on project(s) for which ongoing Rehabilitation and Resettlement (R&R) is being undertaken
                                    by your entity, in the following format:
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr princpleeghtdscnddivfrst'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Sl.
                                                No.
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princpleeghtdscnddivscnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Name of Project
                                                for which R&R is
                                                ongoing

                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princpleeghtdscnddivthrd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                State
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princpleeghtdscnddivfrth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                District
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princpleeghtdscnddivffth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                No. of Project
                                                Affected
                                                Families (PAFs)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princpleeghtdscnddivsxth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                % of PAFs
                                                covered by
                                                R&R
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princpleeghtdscnddivegth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Amounts
                                                paid to PAFs
                                                in the FY (In
                                                INR)

                                            </p>
                                        </th>
                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slOne', 'sNo', e.currentTarget.textContent)}
                                            >
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slOne', 'nameForRrOngoing', e.currentTarget.textContent)}
                                            >
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slOne', 'state', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slOne', 'district', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slOne', 'nameForAffectedFamilies', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slOne', 'percentOfPafConverdByRr', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slOne', 'accountPaidToPaf', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slTwo', 'sNo', e.currentTarget.textContent)}
                                            >
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slTwo', 'nameForRrOngoing', e.currentTarget.textContent)}
                                            >
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slTwo', 'state', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slTwo', 'district', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slTwo', 'nameForAffectedFamilies', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slTwo', 'percentOfPafConverdByRr', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('slTwo', 'accountPaidToPaf', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>
                                </table>

                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Describe the mechanisms to receive and redress grievances of the community.
                                </li>
                                <div class="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                describeTheMechanism: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Percentage of input material (inputs to total inputs by value) sourced from suppliers:
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr princpleforsctdibfrst'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Particulars
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princpleforsctdibscnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                FY {currentFY}
                                                Current Financial
                                                Year

                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princpleforsctdibthrd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                FY {previousFY}
                                                Previous
                                                Financial Year
                                            </p>
                                        </th>
                                    </tr>


                                    {
                                        entityDetails1?.map((item, ind) => {
                                            return (
                                                <tr>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity1(ind, e, "particulars")}
                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity1(ind, e, "currentYear")}
                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity1(ind, e, "previousYear")}
                                                        >
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }


                                    {/* <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                    </tr> */}
                                </table>

                                <div className='notedivadmoredivflx'>
                                    <div className=''>
                                        <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal2(!notemodal2)}>Note  </p>
                                    </div>

                                    <button className='adddinciondivimg'>
                                        <img src={Addicon} alt='...' onClick={addFormFieldsentityDetails1} />
                                        <span>Add More</span>
                                    </button>
                                </div>

                                <div className=" ">

                                    {notemodal2 &&
                                        <div className="yestrxtwarea mt-2 mb-0">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('percentageOfInputMaterial', 'impression', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </div>}
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Job creation in smaller towns – Disclose wages paid to persons employed (including
                                    employees or workers employed on a permanent or non-permanent / on contract basis)
                                    in the following locations, as % of total wage cost
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr prncipleghtfivdivfrst'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Location
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prncipleghtfivdivsvnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                FY {currentFY}
                                                Current Financial Year

                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prncipleghtfivdivthrd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                FY {previousFY}
                                                Previous Financial Year

                                            </p>
                                        </th>
                                    </tr>

                                    {
                                        jobCreationInSmallertown?.map((item, ind) => {
                                            return (
                                                <tr>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangejobCreationInSmallertown(ind, e, "location")}
                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangejobCreationInSmallertown(ind, e, "currentYear")}
                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangejobCreationInSmallertown(ind, e, "financialYear")}
                                                        >
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                    {/* <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                    </tr> */}
                                </table>
                                <div className="adddinciondivimg">
                                    <img src={Addicon} alt="..." onClick={addFormFieldsjobCreationInSmallertown} />
                                    <span>Add More</span>
                                </div>
                            </div>
                        </ul>


                    </div>
                </div>
                <div className='borderessentialin'>
                    <p className='idicatrstxtpp'>Leadership Indicators</p>
                    <div className=''>
                        <ul className='listoftblxsdivul'>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Provide details of actions taken to mitigate any negative social impacts identified in the
                                    Social Impact Assessments (Reference: Question 1 of Essential Indicators above):
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr princlpidrshpindrrtdidfrst'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Details of negative social impact identified
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr princlpidrshpindrrtdidscnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Corrective action taken
                                            </p>
                                        </th>
                                    </tr>

                                    {
                                        detailsOfActionToMitigate?.map((item, ind) => {
                                            return (
                                                <tr>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangedetailsOfActionToMitigate(ind, e, "detailsOfNegative")}
                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangedetailsOfActionToMitigate(ind, e, "corrective")}
                                                        >
                                                        </div>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                    {/* <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                    </tr> */}
                                </table>
                                <div className="adddinciondivimg">
                                    <img src={Addicon} alt="..." onClick={addFormFieldsdetailsOfActionToMitigate} />
                                    <span>Add More</span>
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Provide the following information on CSR projects undertaken by your entity in
                                    designated aspirational districts as identified by government bodies:
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr prnclpeghtldrindictsscnddivfrst'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Sl.
                                                No.
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prnclpeghtldrindictsscnddivscnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                State
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prnclpeghtldrindictsscnddivthrd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Aspirational District
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prnclpeghtldrindictsscnddivfrth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Amount Spent (₹)
                                            </p>
                                        </th>
                                    </tr>

                                    {
                                        entityDetails2?.map((item, ind) => {
                                            return (
                                                <tr>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity2(ind, e, "slNo")}
                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity2(ind, e, "state")}
                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity2(ind, e, "aspirationalDistrict")}
                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity2(ind, e, "spentAmmont")}
                                                        >
                                                        </div>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                    {/* <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                    </tr> */}
                                </table>
                                <div className='notedivadmoredivflx'>
                                    <div className=''>
                                        <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal2(!notemodal2)}>Note  </p>
                                    </div>

                                    <button className='adddinciondivimg'>
                                        <img src={Addicon} alt='...' onClick={addFormFieldsentityDetails2} />
                                        <span>Add More</span>
                                    </button>
                                </div>

                                <div className=" ">
                                    {notemodal2 &&
                                        <div className="yestrxtwarea mt-2 mb-0">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('infromationOfCsrProject', 'impression', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </div>}
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    a. Do you have a preferential procurement policy where you give preference to purchase from suppliers
                                    comprising marginalised /vulnerable groups? <span>(Yes/ No)</span>
                                </li>
                                <div className="yesnobtndivmain">
                                    <div className="raditrbtntxtnemko">
                                        <input type="radio"
                                            value={"Yes"}
                                            checked={formvalue?.marginalizedGroups?.isAvailbleProcurementPolicy === "Yes"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    marginalizedGroups: {
                                                        ...formvalue.marginalizedGroups,
                                                        isAvailbleProcurementPolicy: "Yes"
                                                    }
                                                });
                                            }}
                                        />
                                        <label>Yes</label>
                                    </div>
                                    <div className="raditrbtntxtnemko">
                                        <input type="radio"
                                            value={"No"}
                                            checked={formvalue?.marginalizedGroups?.isAvailbleProcurementPolicy === "No"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    marginalizedGroups: {
                                                        ...formvalue.marginalizedGroups,
                                                        isAvailbleProcurementPolicy: "No"
                                                    }
                                                });
                                            }}
                                        />
                                        <label>No</label>
                                    </div>
                                </div>
                                <div className='yestrxtwarea mt-2 mb-2'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('marginalizedGroups', 'isAvailbleProcurementPolicyDetails', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>

                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <p className='listoftblxsdiv'>
                                    b. From which marginalised/vulnerable groups do you procure?
                                </p>
                                <div className='yestrxtwarea'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('marginalizedGroups', 'nameOfGroups', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <p className='listoftblxsdiv'>
                                    c. What percentage of total procurement (by value) does it constitute?
                                </p>
                                <div className='yestrxtwarea'>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('marginalizedGroups', 'percentOfTotalProcurement', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Details of the benefits derived and shared from the intellectual properties owned or acquired by your entity
                                    (in the current financial year), based on traditional knowledge:
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr prncpleegthldrindifrrdivfrst'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                S. No.
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prncpleegthldrindifrrdivscnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Intellectual Property based on
                                                traditional knowledge
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prncpleegthldrindifrrdivsthrd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Owned/
                                                Acquired
                                                (Yes/No)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prncpleegthldrindifrrdivfrth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Benefit
                                                shared
                                                (Yes / No)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prncpleegthldrindifrrdivsffth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Basis of
                                                calculating
                                                benefit
                                                share
                                            </p>
                                        </th>
                                    </tr>

                                    {
                                        entityDetails3?.map((item, ind) => {
                                            return (
                                                <tr>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity3(ind, e, "sNo")}
                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity3(ind, e, "intellectualProperty")}

                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity3(ind, e, "ownedOrAcquired")}

                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity3(ind, e, "benifitShared")}
                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity3(ind, e, "basicOfBeniftShare")}
                                                        >
                                                        </div>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                    {/* <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                    </tr> */}
                                </table>
                                <div className="adddinciondivimg">
                                    <img src={Addicon} alt="..." onClick={addFormFieldsentityDetails3} />
                                    <span>Add More</span>
                                </div>
                                <div className="yestrxtwarea mt-2">
                                    <p className=" dtlsttfvdvgppphjp noiriittxoppdjoyyypp mb-2">Add more if any</p>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('detailsOfBenifitDerived', 'impression', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Details of corrective actions taken or underway, based on any adverse order in
                                    intellectual property related disputes wherein usage of traditional knowledge is involved.
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr prmcncpleeghtldrshpindictedivfrst'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Name of authority
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prmcncpleeghtldrshpindictedivscnd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Brief of the Case
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prmcncpleeghtldrshpindictedivthrd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Corrective action taken
                                            </p>
                                        </th>


                                    </tr>

                                    {
                                        entityDetails4?.map((item, ind) => {
                                            return (
                                                <tr>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity4(ind, e, "nameOfAuthority")}
                                                        >
                                                        </div>

                                                    </td>

                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity4(ind, e, "briefOfCase")}
                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangeentity4(ind, e, "correctiveActonTaken")}
                                                        >
                                                        </div>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                    {/* <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                    </tr> */}
                                </table>
                                <div className="adddinciondivimg">
                                    <img src={Addicon} alt="..." onClick={addFormFieldsentityDetails4} />
                                    <span>Add More</span>
                                </div>
                                <div className="yestrxtwarea mt-2">
                                    <p className=" dtlsttfvdvgppphjp noiriittxoppdjoyyypp mb-2">Add more if any</p>
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => handleInput('correstiveActionTakenDetails', 'details', e.currentTarget.textContent)}
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Details of beneficiaries of Corporate Social Responsibility Project:
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr prnclpegthsxdivfrst'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                Sl. No.
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prnclpegthsxdivscnd '>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                CSR Project
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prnclpegthsxdivthrd'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                No. of People benefitted
                                                from the project

                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prnclpegthsxdivfrth'>
                                            <p className='mnumbbrttprinvthreetxttppp'>
                                                % of beneficiaries from vulnerable
                                                and marginalized groups
                                            </p>
                                        </th>


                                    </tr>

                                    {
                                        detailsOfBenificiariesOfCsrProject?.map((item, ind) => {
                                            return (
                                                <tr>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangedetailsOfBenificiariesOfCsrProject(ind, e, "slNo")}
                                                        >
                                                        </div>

                                                    </td>

                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangedetailsOfBenificiariesOfCsrProject(ind, e, "csrProject")}
                                                        >
                                                        </div>

                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangedetailsOfBenificiariesOfCsrProject(ind, e, "benefittedName")}
                                                        >
                                                        </div>

                                                    </td>

                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChangedetailsOfBenificiariesOfCsrProject(ind, e, "percentOfBenificiaries")}
                                                        >
                                                        </div>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }



                                    {/* <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>

                                        </td>
                                    </tr> */}
                                </table>
                                <div className="adddinciondivimg">
                                    <img src={Addicon} alt="..." onClick={addFormFieldsdetailsOfBenificiariesOfCsrProject} />
                                    <span>Add More</span>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="homePgCreModSubmitDiv">
                    <button
                        type='button'
                        onClick={submitHandler}
                        className="homePgCreModSubmitBtn btn1">
                        Next
                    </button>
                </div>
            </div>
        </>
    )
}

export default Principleeghit
