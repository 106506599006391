import React from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png";
import docreoiimg from "../../Images/docreoiimg.png"
const CreateDocumentRepository = () => {
    return (
        <>
            <section className="createeventdetails">
                <div className="detailspagecontainer">
                    <div className="createdivfrmmain">
                        <form>
                            <div className=''>
                                <div className="trainingrifgtdivgappp">
                                    <p className="rsntxrpnewtxt">Document Type</p>
                                    <textarea
                                        id=""
                                        rows={4}
                                        cols={50}

                                        className="txtdivallartadvcacytfnew"
                                    ></textarea>

                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Title of The Document</p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                    />

                                </div>
                                <div className="vdoulddivpbupldgnewmmll">
                                    <p className="rsntxrpnewtxt"> Add any Thumbnail Image </p>

                                    <div className="bgcontainerupldboxsgwgrdiv">
                                        <div className="bgcrd">
                                            <div className="bgcontaineruplddivnew">
                                                <div className="logouplddiv">
                                                    <img
                                                        src={Addicon}
                                                        alt="..."
                                                    />
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            id="hostedBy"
                                                            className="upldimagediv"
                                                            placeholder="Choose a photo"
                                                            
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <p className="upldtxtppdiv">Upload Your Thumbnail</p>

                                            {/* After Upload Design Start */}
                                           <div className="bgcontainerupldfiledivabslt">
                                                <div className="imageuplddiv">
                                                    <img src={docreoiimg} alt />
                                                    <div className="imageupldcrs" style={{
                                                        cursor: "pointer"
                                                    }}>
                                                        <i className="fa-solid fa-xmark" o/>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* After Upload Design End */}

                                        </div>
                                    </div>
                                </div>

                                <div className="trainingrifgtdivgappp">
                                    <p className="rsntxrpnewtxt">Details of the document.</p>
                                    <textarea
                                        id=""
                                        rows={4}
                                        cols={50}

                                        className="txtdivallartadvcacytfnew"
                                    ></textarea>

                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Version of the document.</p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                    />

                                </div>
                                <div className="vdoulddivpbupldgnewmmll">
                                    <p className="rsntxrpnewtxt">File upload </p>

                                    <div className="bgcontainerupldboxsgwgrdiv">
                                        <div className="bgcrd">
                                            <div className="bgcontaineruplddivnew">
                                                <div className="logouplddiv">
                                                    <img
                                                        src={Addicon}
                                                        alt="..."
                                                    />
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            id="hostedBy"
                                                            className="upldimagediv"
                                                            placeholder="Choose a photo"
                                                            
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <p className="upldtxtppdiv">Upload Your File</p>

                                            {/* After Upload Design Start */}
                                           <div className="bgcontainerupldfiledivabslt">
                                                <div className="imageuplddiv">
                                                    <img src={docreoiimg} alt />
                                                    <div className="imageupldcrs" style={{
                                                        cursor: "pointer"
                                                    }}>
                                                        <i className="fa-solid fa-xmark" o/>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* After Upload Design End */}

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreateDocumentRepository
