import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Addicon from "../../Images/Icons/PNG/Add icon.png";
// import docreoiimg from "../../Images/docreoiimg.png"
import { useDispatch, useSelector } from 'react-redux';
import { getAffinityGroup } from '../../Redux/Slice/AffinityGroupSlice';
import toast from 'react-hot-toast';
import HttpClientXml from '../../utils/HttpClientXml';

const Myaffinitygroup = () => {
  const [editedelete, setEditedelete] = useState(false);
  const [modalId, setModalId] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myGroup = useSelector((state) => state?.AffinityGroupSlice?.mygroup)

  const handledelteediit = (id) => {
    setModalId(id);
    setEditedelete(!editedelete)
  }

  const handleEdit = async (item) => {
    navigate(`/editteam/${item?._id}`, { state: item })
  }

  //for delete affinity group
  const handleDelete = async () => {
    const res = await HttpClientXml?.requestData(`delete-affinity-group/${modalId}`, {}, "PUT");

    if (res?.status) {
      toast.success("Group deleted succesfully");
      dispatch(getAffinityGroup());

    } else {
      toast?.error(res?.message || "Something went wrong");
    }
  }

  //for redirect single afinity group
  const handleSingleGroup=(id)=>{
    navigate(`/team/${id}`)
  }

  useEffect(() => {
    dispatch(getAffinityGroup());
  }, [])
  return (
    <>
      <section className='myaffinitygrpmainsection'>
        <div className='detailspagecontainer'>
          <div className="myaffinitydetailsbg">
            <div className="singlepagetop">
              <div className="singlebulletinflx">
                <h4 className="singlepagetoptxt">
                  My Team</h4>
                <Link className='addinconhjfpnh' to="/createteam">
                  <img src={Addicon} alt='...' />
                </Link>
              </div>
            </div>
            <div className='singlepagebottommaffinity'>
              {
                myGroup?.map((item) => {
                  return (
                    <>
                      <div className='afiifnitygrpbgmin'>
                        <div className='affinitysinammaibrdr'>
                          <div className='affinitysinammai'>
                            <img src={item?.image} alt="..." />
                          </div>
                          <div className='divdot' onClick={() => handledelteediit(item?._id)}>
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                            {item?._id === modalId && editedelete && <div className="editdltdivflxmain">
                              <div className="plismnisn" onClick={() => handleEdit(item)}>
                                <i className="fa-solid fa-pen-to-square" />
                              </div>
                              <div className="plismnisn" onClick={handleDelete}>
                                <i className="fa-solid fa-trash" />
                              </div>
                            </div>}

                          </div>

                        </div>
                        <div onClick={()=>handleSingleGroup(item?._id)} style={{cursor:"pointer"}}>
                          <div className=''>
                            <p className="hubEvCrePara" style={{ cursor: "pointer" }}>{item?.groupName}</p>
                          </div>
                          <div className="affinitygrpbtm">
                            <div className=''>
                              <p className='affinytdtlksttxpp'>
                                <span >
                                  Created By :
                                </span>
                                {item?.gpAdminFirstName + " " + item?.gpAdminLastName}
                              </p>
                            </div>
                            <div className=''>
                              <p className='affinytdtlksttxpp'>
                                <span >
                                  Type :
                                </span>
                                {item?.grouptype}
                              </p>
                            </div>
                            <div className=''>
                              <p className='affinytdtlksttxpp'>
                                <span >
                                  Character :
                                </span>
                                {item?.charter}
                              </p>
                            </div>
                            <div className=''>
                              <p className='affinytdtlksttxpp'>
                                <span >
                                  Purpose :
                                </span>
                                {item?.purpose}
                              </p>
                            </div>

                          </div>
                        </div>
                      </div>
                    </>
                  )
                })
              }

            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Myaffinitygroup
