import React from 'react'
import Search from "../../src/Images/Icons/PNG/Search.png"
const Searchformmodal = ({closemodal}) => {
  return (
    <>
    
    <section className='modalallcontentbg'>
                <div className='srchformcontent'>
                    <div className='crossdivfrm' onClick={() => closemodal()}>
                        <i className="fa-solid fa-xmark" ></i>
                    </div>
                    <div className='srchinpufldcontntchngfld'>
                        <div className='srchinputfrm'>
                            <input type='text' placeholder='Input Here' />
                            <div className='srchimggdiv'>
                                <img src={Search} />
                            </div>
                        </div>

                        <div className='contentfield'>
                            <ul className='listcontntul'>
                                <li className='listcontntulli'>Buuletin </li>
                                <li className='listcontntulli'>Hall of Fame</li>
                                <li className='listcontntulli'>Educate</li>
                                <li className='listcontntulli'>Non Tech</li>
                                <li className='listcontntulli'>Technical</li>
                                <li className='listcontntulli'>Mandatory Course</li>
                                <li className='listcontntulli'>Event</li>
                                <li className='listcontntulli'>Share Post</li>
                                <li className='listcontntulli'>Ongoing</li>
                                <li className='listcontntulli'>Requested Trainning</li>
                                <li className='listcontntulli'>Document Repository</li>
                            </ul>
                        </div>
                    </div>

                </div>

            </section>
    </>
  )
}

export default Searchformmodal
