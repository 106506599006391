import {
    createContext,
    useContext,
    useState,
  } from "react";
  
  const AuthContext = createContext(null);
  
  export const useAuthCtx = () => useContext(AuthContext);
  
  export const ContextProvider = ({ children }) => {
    const [calenderMonth, setCalenderMonth] = useState("");
    const [calenderYear, setCalenderYear] = useState("");

    return (
      <AuthContext.Provider
        value={{
          calenderMonth,
          setCalenderMonth,
          calenderYear,
          setCalenderYear
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };
  