import React, { useEffect, useMemo, useState } from 'react'
// import girl from "../../Images/girl.png";
import like from "../../Images/Icons/PNG/Reaction - Like.png";
import comments from "../../Images/Icons/PNG/Comment.png";
import share from "../../Images/Icons/PNG/Share.png";
import r from "../../Images/Icons/PNG/Reward - Silver.png";
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPosts, getComment } from '../../Redux/Slice/PostSlice';
import { Link, useParams } from 'react-router-dom';
import PostEditmodal from '../../Modal/PostEditmodal';
import profile from "../../Images/cute-girl-dp.jpg"
import Attach from "../../Images/Icons/PNG/Attach.png";
import Post from "../../Images/Icons/PNG/Post.png"
import EditCommentmodal from '../../Modal/EditCommentmodal';
import ReactionLike from "../../Images/Icons/PNG/Reaction - Like.png";
import ReactionLove from "../../Images/Icons/PNG/Reaction - Love.png";
import ReactionCelebrate from "../../Images/Icons/PNG/Reaction - Celebrate.png";
import ReactionSupport from "../../Images/Icons/PNG/Reaction - Support.png";
import ReationCurious from "../../Images/Icons/PNG/Reation - Curious.png";
import ReationInsightful from "../../Images/Icons/PNG/Reation - Insightful.png";
import HttpClientXml from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';
import { BsEmojiSmileFill } from 'react-icons/bs';
import EmojiPicker from 'emoji-picker-react';
import CommenteditModal from './CommeteditModal';
import reporticon from "../../Images/Icons/PNG/Share.png";
import ProfileImg1 from "../../Images/girl.png";
import ReactPlayer from 'react-player';

const ShareTeamPost = ({ post, setCommentId, commentId }) => {
    const params = useParams();
    const [editedeletefeed, setEditedeletefeed] = useState(false);
    const commentData = useSelector((state) => state?.PostSlice?.commentData);
    const [editdltrpt, setEditdltrpt] = useState(false)
    const [editemodal, setEditmodal] = useState(false);
    const [editecmntmodal, setEditecmntmodal] = useState(false)
    const dispatch = useDispatch();
    const userData = useSelector((state) => state?.StatusCardSlice?.data);
    const [showReaction, setShowReaction] = useState("");
    const [emojimodal, setEmojimodal] = useState(false);
    const [reactionCount, setReactionCount] = useState(post?.totalReaction);
    const [userReactions, setUserReactions] = useState(new Set());


    const [isEmoji, setIsEmoji] = useState(false);
    const [image, setImage] = useState("")
    const [uploading, setUploading] = useState(false);
    const [commentView, setCommentView] = useState(false);
    const [commentText, setCommentText] = useState("");
    const [imageURL, setImageURL] = useState("");

    const [indexShow, setIndex] = useState("");
    const [indexShowReply, setIndexReply] = useState("");
    const [replyText, setReplyText] = useState("");
    const [replyToggle, setReplyToggle] = useState([]);

    const [replyLoading, setReplyLoading] = useState(false);
    const [isReplyEmoji, setIsReplyEmoji] = useState(false);
    const [imageReplyURL, setImageReplyURL] = useState("");

    const [editdeletereport, setEditdeletereport] = useState(false);
    const [editnewthreemodal, setEditnewthreemodal] = useState(false);
    const [editComment, setEditComment] = useState({});
    const [editdeletereportReply, setEditdeletereportReply] = useState(false);
    const [editnewthreemodalReply, setEditnewthreemodalReply] = useState(false);
    const [editCommentReply, setEditCommentReply] = useState({});

    const [mediaUrl, setMediaUrl] = useState(null);
    const [platformName, setPlatformName] = useState("");

    function extractHostname(url) {
        var hostname;
        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        } else {
            hostname = url.split('/')[0];
        }

        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];

        validateDomain(hostname);
        return hostname;
    }

    function extractRootDomain(url) {
        var domain = extractHostname(url),
            splitArr = domain.split('.'),
            arrLen = splitArr.length;

        //extracting the root domain here
        //if there is a subdomain
        if (arrLen > 2) {
            domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
            if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
                domain = splitArr[arrLen - 3] + '.' + domain;
            }
        }
        validateDomain(domain);
        return domain;
    }

    const validateDomain = s => {
        try {
            new URL("https://" + s);
            return true;
        }
        catch (e) {
            console.error(e);
            return false;
        }
    };

    
    useEffect(() => {
        if (post?.description) {
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const urls = post.description.match(urlRegex);
            if (urls && urls.length > 0 && ReactPlayer.canPlay(urls[0])) {
                setMediaUrl(urls[0]);
                const test = ((method, arr) => setPlatformName(
                    `${arr?.map(url => method(url)).join("\n")}\n`));
                test(extractRootDomain, urls);
            }
        }
    }, [post]);


    const data = {
        postType: "private",
        affinityGroupId: params?.id
    }

    let data1 = {
        postID: commentId
    };

    useEffect(() => {
        dispatch(getAllPosts(data));
    }, [])




    //for reaction functionality
    const handlereaction = async (postId, reactionType) => {
        setShowReaction(reactionType);
        setEmojimodal(false);

        if (userReactions.has(userData?._id)) {
            return;
        }

        const data = {
            postID: postId,
            reactionData: reactionType
        }

        const response = await HttpClientXml?.requestData("add-reaction", data, "POST");

        if (response?.status) {
            setReactionCount(prevCount => prevCount + 1);
            setUserReactions(prevReactions => new Set([...prevReactions, userData?._id]));
        } else {
        }

    };


    //for emoji clicking
    const handleemojiClick = () => {
        setEmojimodal(true);
    }

    //reaction for only post
    const reactionIconMemo = useMemo(() => {
        if (showReaction) {
            return showReaction
        } else {
            return post?.reactionData?.find(item => item?.userId === userData?._id)?.reactionData;
        }
    }, [post, userData, showReaction])


    //delete post
    const handleDelete = async () => {
        if (post?.docType === "homePost") {
            const res = await HttpClientXml?.requestData(`delete-post/${post?._id}`, data, "PUT")
            if (res?.status) {
                toast?.success("Deleted successfully");

                dispatch(getAllPosts());
            } else {
                toast.error(res?.message || "Something went wrong");
            }
        }

        if (post?.docType === "advocacyPost") {
            const res = await HttpClientXml?.requestData(`delete-advocacy/${post?._id}`, data, "DELETE")
            if (res?.status) {
                toast?.success("Deleted successfully");

                dispatch(getAllPosts());
            } else {
                toast.error(res?.message || "Something went wrong");
            }
        }

        if (post?.docType === "eventData") {
            const res = await HttpClientXml?.requestData(`delete-event/${post?._id}`, data, "DELETE")

            if (res?.status) {
                toast.success("Deleted successfully");

                dispatch(getAllPosts());
            } else {
                toast.error(res?.message || "Something went wrong");
            }
        }

    };


    useEffect(() => {
        const closeEmojiModal = (event) => {
            if (!event.target.closest('.emojimodalbg') && !event.target.closest('.reaction')) {
                setEmojimodal(false);
            }
        };
        document.addEventListener('click', closeEmojiModal);
        return () => {
            document.removeEventListener('click', closeEmojiModal);
        };

    }, []);

    const handleeditdltefeed = () => {
        setEditedeletefeed(!editedeletefeed)
    }
    const editemoadl = () => {
        setEditmodal(!editemodal)
    }

    //for doing comment
    const handleComment = async (e) => {
        e.preventDefault();
        if (commentText === "" && imageURL === "") {
            return;
        }
        let data = {
            userId: userData?._id,
            Userimage: userData?.image,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            postID: post?._id,
            comment: commentText,
            image: imageURL
        };

        if (commentText || imageURL) {
            let res = await HttpClientXml?.requestData("add-comment", data, "POST");
            if (res?.status) {
                dispatch(getComment(data1));
                setCommentText("");
                setImageURL("");
            } else {

            }
        } else {
            toast.error("No Comment is Added");
        }
    };


    //for delete comment
    const handleDeleteComment = async (id) => {
        const res = await HttpClientXml?.requestData(`delete-comment/${id}`, data, "DELETE")

        if (res?.status) {
            dispatch(getComment(data1));
            toast?.success("Comment deleted successfully");

        } else {

        }
    };


    //for image onChange functionality
    const handleImageChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await HttpClientXml.fileUplode("image-upload", "POST", form);

        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageURL(res?.image);
        } else {
            toast.error("Unsupported Image");
        }
        setUploading(false);
    };

    //reply-image
    const handleReplyImageChange = async (e) => {
        let image = e.target.files[0];
        setReplyLoading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await HttpClientXml.fileUplode("image-upload", "POST", form);

        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageReplyURL(res?.image);
        } else {
            toast?.error("Unsupporting image");
        }
        setReplyLoading(false);
    };

    //for reply toggle
    const handleReplyToggle = (index) => {
        const newToggles = new Array(commentData?.length)?.fill(false);
        newToggles[index] = true;
        setReplyToggle(newToggles);
    };

    //for Reply of comment
    const handleReplyClick = async (id) => {
        if (replyText === "" && imageReplyURL === "") {
            return;
        }

        let data = {
            commentID: id,
            reply: replyText,
            image: imageReplyURL
        };

        if (replyText || imageReplyURL) {
            let res = await HttpClientXml?.requestData("add-reply", data, "POST");
            if (res?.status) {
                toast.success("Reply is added successfully");
                dispatch(getComment(data1));
                dispatch(getAllPosts(data));
                setReplyText("");
                setReplyToggle(false);
                setImageReplyURL("");
                setIsReplyEmoji(false);
            } else {
                // toast.error(res?.data?.message || "Error Fetching User Details");
            }
        } else {
            toast.error("No Reply is Added");
        }
    };

    const handledeleteeditreportClick = (ele, ind) => {
        setIndex((prev) => prev === ind ? "" : ind);
        setEditdeletereport(!editdeletereport);
        // console.log(ele?._id, index, "bmw", editdeletereport);
    }

    const handleeditClick = (ele) => {
        setEditnewthreemodal(!editnewthreemodal)
        setEditComment(ele);
    }


    //for reply open modal for edit delete report
    const handledeleteeditreportReplyClick = (ele, ind, i) => {
        setIndexReply((prev) => prev === `${ind}${i}` ? "" : `${ind}${i}`);
        setEditdeletereportReply(!editdeletereportReply);
    }

    //for reply handle edit modal
    const handleeditClickReply = (ele) => {
        setEditnewthreemodalReply(!editnewthreemodalReply)
        setEditCommentReply(ele);
    }

    //for delete reply
    const handleDeletereply = async (id) => {
        const res = await HttpClientXml?.requestData(`deleted-reply/${id}`, data, "PUT")
        if (res?.status) {
            toast.success("Reply is deleted successfully");
            dispatch(getComment(data1));
            dispatch(getAllPosts(data));
        } else {

        }
    }

    // const cmntedtdltrpt = () => {
    //     setEditdltrpt(!editdltrpt)
    // }
    // const editcmntmodal = () => {
    //     setEditecmntmodal(!editecmntmodal)
    // }
    return (

        <>

            <section className='postthoughts_area'>

                {/* // sarataylor */}
                <div className='sara_tylor' key={post?._id}>
                    <div>
                        <div className='top'>
                            <div className='left'>
                                <div className='prflimgshrthgh'>
                                    <img src={post?.userImage} alt="..." />
                                </div>
                                <div className='sharedetails'>
                                    <div className='name'>
                                        <h2 className='nametxthh'>{post?.userData?.[0]?.firstName + " " + post?.userData?.[0]?.lastName}</h2>
                                        <div className='brnztztpp'>
                                            <div className='imgbrnz'>
                                                {/* <img src={r} alt="..." /> */}
                                                {
                                                    post?.userData?.[0]?.rewardCard?.[0]?.cardImage
                                                        ?
                                                        <img src={post?.userData?.[0]?.rewardCard?.[0]?.cardImage} alt='...' />
                                                        :
                                                        <></>
                                                }
                                            </div>
                                            {/* <div className=''>
                                                <p className='brnzttxy'>{post?.userData?.[0]?.rewardCard?.[0]?.cardName} badge holder</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='text'>
                                        <p className='emplyrtxtpp'>{post?.type} ,</p>
                                        <p className='emplyrtxtpp'>Posted on {moment(post?.createdOn).format("YYYY-MM-DD")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='dotsticondrdtdlytrlrt'>
                                {
                                    post?.userID === userData?._id
                                    &&
                                    <div className='dotsticondrdtdlyt' onClick={handleeditdltefeed}>
                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                    </div>
                                }


                                {editedeletefeed && <div className='editdeletedibvflx'>
                                    <div className='editedleted' onClick={editemoadl}>
                                        <i className="fa-solid fa-pen-to-square"></i>
                                    </div>
                                    <div className='editedleted' onClick={handleDelete}>
                                        <i className="fa-solid fa-trash"></i>
                                    </div>
                                </div>}

                            </div>
                        </div>
                        <div className='top_next'>
                            {/* <p className='lrmtxtpptxtpp'> {post?.description}</p> */}
                            {mediaUrl ? (
                                <>
                                    <Link to={mediaUrl} target="_blank" rel="noopener noreferrer">
                                        {mediaUrl}
                                    </Link>
                                    <ReactPlayer url={mediaUrl} controls style={{ width: "100%", height: "100%" }} />
                                    <p>{platformName}</p>
                                    <p>{post?.urlTitle}</p>
                                </>
                            ) : (
                                <p className='lrmtxtpptxtpp'>{post?.description}</p>
                            )}
                        </div>

                        {
                            post?.image?.[0]
                                ?
                                <div className='sharethgtsimagediv'>
                                    <div className='sharethgtsbg'>
                                        <div className='sharethghtsimg'>
                                            <img src={post?.image} alt="..." />
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                        }



                    </div>
                    <div className='like_comment_areasec'>
                        <div className='leftshare'>
                            <p className='plpotxt'>{reactionCount} Reaction</p>
                        </div>
                        <div className='rightshare'>
                            <h6 className='cmntdtxt'>{post?.totalComment} People Commented</h6>
                            <p className='shrtxt'>1 Share</p>
                        </div>
                    </div>
                    <div className='reaction_areasec'>

                        <div className='reactionmainsc'>
                            <div
                                className="reactionmainscdivflx"
                                onMouseEnter={handleemojiClick}
                                onClick={handlereaction}
                            >

                                {reactionIconMemo
                                    ?
                                    reactionIconMemo === "like" ? (<img src={ReactionLike} alt="..." className="rcmgdivmai" />) :
                                        reactionIconMemo === "love" ? (<img src={ReactionLove} alt="..." className="rcmgdivmai" />) :
                                            reactionIconMemo === "support" ? (<img src={ReactionSupport} alt="..." className="rcmgdivmai" />) :
                                                reactionIconMemo === "celebrate" ? (<img src={ReactionCelebrate} alt="..." className="rcmgdivmai" />) :
                                                    reactionIconMemo === "curious" ? (<img src={ReationCurious} alt="..." className="rcmgdivmai" />) :
                                                        reactionIconMemo === "insightful" ? (<img src={ReationInsightful} alt="..." className="rcmgdivmai" />)
                                                            :
                                                            (<img src={like} alt="..." className="rcmgdivmai" />)
                                    :
                                    (<img src={like} alt="..." className="rcmgdivmai" />)

                                }


                                <p className='txtrecppp'>Reaction</p>
                            </div>

                            {/* emoji modal */}
                            {emojimodal &&
                                <div className='reactionicondivflx actvgchng'>
                                    <div className="reactionicon">
                                        <img src={ReactionLike} alt="..." onClick={() => handlereaction(post?._id, "like")} />
                                    </div>
                                    <div className="reactionicon">
                                        <img src={ReactionLove} alt="..." onClick={() => handlereaction(post?._id, "love")} />
                                    </div>
                                    <div className="reactionicon">
                                        <img src={ReactionSupport} alt="..." onClick={() => handlereaction(post?._id, "support")} />
                                    </div>
                                    <div className="reactionicon">
                                        <img src={ReactionCelebrate} alt="..." onClick={() => handlereaction(post?._id, "celebrate")} />
                                    </div>
                                    <div className="reactionicon">
                                        <img src={ReationCurious} alt="..." onClick={() => handlereaction(post?._id, "curious")} />
                                    </div>
                                    <div className="reactionicon">
                                        <img src={ReationInsightful} alt="..." onClick={() => handlereaction(post?._id, "insightful")} />
                                    </div>

                                </div>
                            }
                        </div>



                        <div className='reactionmainsc'>
                            <div className='rcmgdivmai'
                                onClick={() => {
                                    // setModalId(post?._id);
                                    setCommentView(!commentView);
                                    setCommentId(post?._id)
                                }}
                            >
                                <img src={comments} alt="..." />
                            </div>
                            <p className='txtrecppp'>Comments</p>
                        </div>

                        <div className='reactionmainsc'>
                            <div className='rcmgdivmai'>
                                <img src={share} alt="..." />
                            </div>
                            <p className='txtrecppp'>Share</p>
                        </div>
                    </div>
                    <div className='commentsectionare'>


                        {commentView && (
                            <>

                                <div className='cmntpprfcdivflx'>
                                    <div className='profilemcmnrtimg'>
                                        {/* <img src={profile} alt='...' /> */}

                                        {userData?.image ? (
                                            <img
                                                src={userData?.image}
                                                alt="profile"
                                            />
                                        ) : (
                                            <img
                                                src={ProfileImg1}
                                                alt="profile"
                                            />
                                        )}

                                    </div>
                                    <div className='cmntscedtdiv'>
                                        <form>
                                            <div className='cmnttsttxt'>
                                                <textarea
                                                    className=''
                                                    value={commentText}
                                                    onChange={(e) => setCommentText(e.target.value)}
                                                ></textarea>
                                                <div className='cmntstocnobdivflcpdadad'>
                                                    <div className='rcmgdivmai'>
                                                        <img src={Attach} alt="..." />
                                                        <input
                                                            type="file"
                                                            class="form-control"
                                                            id="hostedBy"
                                                            placeholder="Choose a photo"
                                                            value={image}
                                                            onChange={handleImageChange}
                                                        />
                                                    </div>

                                                    {uploading ? <p style={{
                                                        position: 'absolute',
                                                        right: 0,
                                                        top: '-84%'
                                                    }}>image uploading......</p> : null}


                                                    <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsEmoji(prev => !prev)} />
                                                    {isEmoji &&
                                                        <EmojiPicker onEmojiClick={(emojiObject) => setCommentText(prev => prev + emojiObject.emoji)} />
                                                    }

                                                    <div className='rcmgdivmai' onClick={handleComment}>
                                                        <img src={Post} alt="..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>


                                <div className="cmntpprfcdivflxrplytxtdiv">
                                    {commentData?.length > 0 ? (
                                        commentData?.slice(0, 2)?.map((item, index) => {
                                            return (
                                                <>
                                                    <div className="cmntpprfcdivflxrply" key={index}>
                                                        <div className="profilemcmnrtimg">
                                                            {item?.Userimage ? (
                                                                <img
                                                                    src={item?.Userimage}
                                                                    alt="profile"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={ProfileImg1}
                                                                    alt="profile"
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="cmntscedtdiv">
                                                            <div className="cmnttxnmdivthreeobrdrs">
                                                                <div className="wrap_box">
                                                                    <div className="cmnttxnmdivthreeotdidtfl">
                                                                        <div className="cmntprfgname">
                                                                            <h4>{item?.firstName + " " + item?.lastName}</h4>
                                                                        </div>

                                                                        {
                                                                            (userData?._id === item?.userID) &&
                                                                            (<div className='threedotscmntdownmmd'>
                                                                                <div className="threedotscmntd" onClick={() => handledeleteeditreportClick(item, index)}>
                                                                                    <i className="fa-solid fa-ellipsis"></i>


                                                                                    {
                                                                                        indexShow === index &&
                                                                                        // editdeletereport &&
                                                                                        <div
                                                                                            className="threedotscmntddiv"
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                            }}
                                                                                        >
                                                                                            <div className='threedotscmntddiv'>
                                                                                                <div className={item?.userID === userData?._id ? "edoiytdltreptyicon" : "editdivimgthree"} onClick={() => handleeditClick(item)}>
                                                                                                    <i className="fa-solid fa-pen-to-square" style={{ cursor: "pointer" }}></i>
                                                                                                </div>
                                                                                                <div className={item?.userID === userData?._id ? "edoiytdltreptyicon" : "editdivimgthreedelete"} onClick={() => handleDeleteComment(item?._id)}>
                                                                                                    <i className="fa-solid fa-trash" style={{ cursor: "pointer" }}></i>
                                                                                                </div>
                                                                                                <div className="edoiytdltreptyicon">
                                                                                                    <i className="fa-solid fa-flag"></i>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>}

                                                                                </div>
                                                                            </div>
                                                                            )
                                                                        }
                                                                    </div>



                                                                    {item?.image ? (
                                                                        <div className='imagesinglepost'>
                                                                            <img
                                                                                src={item?.image}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    ) : (

                                                                        <></>
                                                                    )}

                                                                    <div className="text">
                                                                        <p>{item?.comment}</p>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="rplydkltflxmainrply">
                                                                <div className="rplydltbtndiv">
                                                                    <div className='treplyrxrtdivflx' onClick={() => handleReplyToggle(index)} style={{ cursor: "pointer" }}>
                                                                        <p className='trepkcttcbbh'>Reply</p>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                            {
                                                                replyToggle?.[index] && (
                                                                    <>
                                                                        <div className="cmntpprfcdivflxchnng">
                                                                            <div className="profilemcmnrtimg">
                                                                                {userData?.image ? (
                                                                                    <img
                                                                                        src={userData?.image}
                                                                                        alt="profile"
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        src={ProfileImg1}
                                                                                        alt="profile"
                                                                                    />
                                                                                )}
                                                                            </div>



                                                                            <div className="cmntscedtdiv">
                                                                                <form>
                                                                                    <div className="cmnttsttxt">
                                                                                        <textarea
                                                                                            type="text"
                                                                                            placeholder="Reply"
                                                                                            name="replyText"
                                                                                            value={replyText}
                                                                                            onChange={(e) => setReplyText(e.target.value)}
                                                                                        ></textarea>
                                                                                        <div className='cmntstocnobdivflcpdadad'>
                                                                                            <div className="rcmgdivmai">
                                                                                                <img src={Attach} alt="..." />
                                                                                                <input
                                                                                                    type="file"
                                                                                                    id="images"
                                                                                                    placeholder="Choose a photo"
                                                                                                    name="hostedBy"
                                                                                                    onChange={handleReplyImageChange}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="rcmgdivmai">
                                                                                                <BsEmojiSmileFill className="comment-emoji-icon" onClick={() => setIsReplyEmoji(prev => !prev)} />
                                                                                                {isReplyEmoji &&
                                                                                                    <EmojiPicker onEmojiClick={(emojiObject) => setReplyText(prev => prev + emojiObject.emoji)} />
                                                                                                }
                                                                                            </div>
                                                                                            <div className="rcmgdivmai">
                                                                                                <img src={Post} alt="..." onClick={() => handleReplyClick(item?._id)} />
                                                                                            </div>
                                                                                        </div>



                                                                                        {replyLoading ? <p style={{
                                                                                            position: 'absolute',
                                                                                            right: 0,
                                                                                            top: '-84%'
                                                                                        }}>Reply uploading......</p> : null}

                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }

                                                        </div>
                                                    </div>


                                                    {
                                                        item?.replyData?.slice(0, 2)?.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <div className='cmntpprfcdivflxrplyanothrr'>
                                                                        <div className="profilemcmnrtimg" key={i}>
                                                                            {item?.UserImage ? (
                                                                                <img
                                                                                    src={item?.UserImage}
                                                                                    alt="profile"
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src={ProfileImg1}
                                                                                    alt="profile"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <div className="cmntscedtdiv">
                                                                            <div className="cmnttxnmdivthreeobrdrs">
                                                                                <div className="wrap_box">
                                                                                    <div className="cmnttxnmdivthreeotdidtfl">
                                                                                        <div className="cmntprfgname">
                                                                                            <h4>{item?.firstName + " " + item?.lastName}</h4>
                                                                                        </div>
                                                                                        {
                                                                                            userData?._id === item?.userID &&
                                                                                            <div
                                                                                                className="threedotscmntddiv"
                                                                                                onClick={() => handledeleteeditreportReplyClick(item, index, i)}
                                                                                            >
                                                                                                <i className="fa-solid fa-ellipsis"></i>

                                                                                                {
                                                                                                    indexShowReply === `${index}${i}` &&
                                                                                                    <div
                                                                                                        className="editdeleteupdatereportmodal"
                                                                                                        onClick={(e) => {
                                                                                                            e.stopPropagation();
                                                                                                        }}
                                                                                                    >

                                                                                                        <div className="edoiytdltreptyicon" onClick={() => handleeditClickReply(item)}>
                                                                                                            <i className="fa-solid fa-pen-to-square" style={{ cursor: "pointer" }}></i>
                                                                                                        </div>
                                                                                                        <div className="edoiytdltreptyicon" onClick={() => handleDeletereply(item?._id)}>
                                                                                                            <i className="fa-solid fa-trash" style={{ cursor: "pointer" }}></i>
                                                                                                        </div>
                                                                                                        <div className="edoiytdltreptyicon">
                                                                                                            <img src={reporticon} alt="..." />
                                                                                                        </div>
                                                                                                    </div>}

                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    {item?.image ? (
                                                                                        <img
                                                                                            src={item?.image}
                                                                                            className="img-fluid"
                                                                                            alt=""
                                                                                        />
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}

                                                                                    <div className="text">
                                                                                        <p>{item?.reply}</p>
                                                                                    </div>

                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </>
                                            );
                                        })
                                    ) : (
                                        <p>No comments to show!</p>
                                    )}


                                    {imageURL !== "" && (

                                        <div className='imagjdbreokd'>
                                            <img

                                                src={imageURL}
                                                alt=""

                                            />
                                            <div className='crsyhhimbcfg'
                                                onClick={() => {
                                                    setImageURL("");
                                                    setImage('')
                                                }}
                                            >
                                                <i
                                                    class="fa-solid fa-xmark"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                ></i>
                                            </div>
                                        </div>

                                    )}

                                </div>
                            </>
                        )}

                    </div>
                </div>

            </section>

            {editemodal && <PostEditmodal closemodal={setEditmodal} post={post} />}
            {/* {editecmntmodal && <EditCommentmodal closemodal={setEditecmntmodal} />} */}
            {editnewthreemodal && <EditCommentmodal
                closemodal={setEditnewthreemodal}
                postId={post?._id}
                initialValues={editComment}
            />}
        </>
    )
}

export default ShareTeamPost