import React from 'react'
import oimgemnusn from "../../Images/oimgemnusn.png"
import { Link } from 'react-router-dom'
const MyTeamGroup = () => {
    return (
        <>
            <section className='grptesting'>
                <Link className='grptestinglnmjd' to="/myteamgropusingle">
                    <div className='grptestingtop'>
                        <div className="grptestimg">
                            <img src={oimgemnusn} alt="..." />
                        </div>
                        <p className='sttygaghojppd'>Star Group Testing</p>
                    </div>
                    <div className='grptestingbottom'>
                        <div className='grgsmmbdtkls'>
                            <p className='grgsmmbdtklstxtop'>
                                Admin Name:
                                <span>
                                    Niladri Roy
                                </span>
                            </p>
                        </div>
                        <div className='grgsmmbdtkls'>
                            <p className='grgsmmbdtklstxtop'>
                                No of Members:
                                <span>
                                    4
                                </span>
                            </p>
                        </div>
                        <div className='grgsmmbdtkls'>
                            <p className='grgsmmbdtklstxtop'>
                                Group Members:
                                <span>
                                    Niladri Roy
                                </span>
                            </p>
                        </div>
                        <div className='grgsmmbdtkls'>
                            <p className='grgsmmbdtklstxtop'>
                                Purpose:
                                <span>
                                    A Web Designer, or Web Applications
                                </span>
                            </p>
                        </div>
                    </div>
                </Link>
            </section>
        </>
    )
}

export default MyTeamGroup
