import React, { useEffect, useRef, useState } from 'react'
import AddIcon from "../../Images/Icons/PNG/Add icon.png"
import toast from 'react-hot-toast';
import HttpClient from "../../utils/HttpClientXml.js";
import HttpClientXml from '../../utils/HttpClientXml.js';


const PrinicpleThree = ({ setPrincicpletabdrp, setTogglesectionmdl }) => {
    // const [text, setText] = useState('');
    const divRef = useRef(null);
    const [principleOneData, setPrincipleOneData] = useState({});
    const [currentFY, setCurrentFY] = useState("");
    const [previousFY, setPreviousFY] = useState("");


    useEffect(() => {
        window.scroll(0, 0);
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const startMonth = 4; // April is the start of the financial year

        if (currentDate.getMonth() + 1 >= startMonth) {
            setCurrentFY(`${currentYear}-${currentYear + 1}`);
            setPreviousFY(`${currentYear - 1}-${currentYear}`);
        } else {
            setCurrentFY(`${currentYear - 1}-${currentYear}`);
            setPreviousFY(`${currentYear - 2}-${currentYear - 1}`);
        }

    }, []);

    useEffect(() => {
        getPrincipleOne()
    }, [])

    //for getting principleone
    const getPrincipleOne = async () => {
        const res = await HttpClientXml?.requestData(`get-principle`, {}, "GET");
        if (res?.status) {
            setPrincipleOneData(res?.data?.[0]);
        }
    }


    const initvalue = {
        total: 0,
        healthInsurance: {
            number: "",
            percentage: ""
        },
        accidentInsurance: {
            number: "",
            percentage: ""
        },
        maternityBenifit: {
            number: "",
            percentage: ""
        },
        paternityBenift: {
            number: "",
            percentage: ""
        },
        dayCarefacilities: {
            number: "",
            percentage: ""
        },

        ftotal: 0,
        fhealthInsurance: {
            number: "",
            percentage: ""
        },
        faccidentInsurance: {
            number: "",
            percentage: ""
        },
        fmaternityBenifit: {
            number: "",
            percentage: ""
        },
        fpaternityBenift: {
            number: "",
            percentage: ""
        },
        fdayCarefacilities: {
            number: "",
            percentage: ""
        },

        ototal: 0,
        ohealthInsurance: {
            number: "",
            percentage: ""
        },
        oaccidentInsurance: {
            number: "",
            percentage: ""
        },
        omaternityBenifit: {
            number: "",
            percentage: ""
        },
        opaternityBenift: {
            number: "",
            percentage: ""
        },
        odayCarefacilities: {
            number: "",
            percentage: ""
        },

        ttotal: 0,
        thealthInsurance: {
            number: "",
            percentage: ""
        },
        taccidentInsurance: {
            number: "",
            percentage: ""
        },
        tmaternityBenifit: {
            number: "",
            percentage: ""
        },
        tpaternityBenift: {
            number: "",
            percentage: ""
        },
        tdayCarefacilities: {
            number: "",
            percentage: ""
        },



        othertotal: 0,
        otherhealthInsurance: {
            number: "",
            percentage: ""
        },
        otheraccidentInsurance: {
            number: "",
            percentage: ""
        },
        othermaternityBenifit: {
            number: "",
            percentage: ""
        },
        otherpaternityBenift: {
            number: "",
            percentage: ""
        },
        otherdayCarefacilities: {
            number: "",
            percentage: ""
        },

        otherftotal: 0,
        otherfhealthInsurance: {
            number: "",
            percentage: ""
        },
        otherfaccidentInsurance: {
            number: "",
            percentage: ""
        },
        otherfmaternityBenifit: {
            number: "",
            percentage: ""
        },
        otherfpaternityBenift: {
            number: "",
            percentage: ""
        },
        otherfdayCarefacilities: {
            number: "",
            percentage: ""
        },

        otherototal: 0,
        otherohealthInsurance: {
            number: "",
            percentage: ""
        },
        otheroaccidentInsurance: {
            number: "",
            percentage: ""
        },
        otheromaternityBenifit: {
            number: "",
            percentage: ""
        },
        otheropaternityBenift: {
            number: "",
            percentage: ""
        },
        otherodayCarefacilities: {
            number: "",
            percentage: ""
        },

        otherttotal: 0,
        otherthealthInsurance: {
            number: "",
            percentage: ""
        },
        othertaccidentInsurance: {
            number: "",
            percentage: ""
        },
        othertmaternityBenifit: {
            number: "",
            percentage: ""
        },
        othertpaternityBenift: {
            number: "",
            percentage: ""
        },
        othertdayCarefacilities: {
            number: "",
            percentage: ""
        },



        wtotal: 0,
        whealthInsurance: {
            number: "",
            percentage: ""
        },
        waccidentInsurance: {
            number: "",
            percentage: ""
        },
        wmaternityBenifit: {
            number: "",
            percentage: ""
        },
        wpaternityBenift: {
            number: "",
            percentage: ""
        },
        wdayCarefacilities: {
            number: "",
            percentage: ""
        },

        wftotal: 0,
        wfhealthInsurance: {
            number: "",
            percentage: ""
        },
        wfaccidentInsurance: {
            number: "",
            percentage: ""
        },
        wfmaternityBenifit: {
            number: "",
            percentage: ""
        },
        wfpaternityBenift: {
            number: "",
            percentage: ""
        },
        wfdayCarefacilities: {
            number: "",
            percentage: ""
        },

        wototal: 0,
        wohealthInsurance: {
            number: "",
            percentage: ""
        },
        woaccidentInsurance: {
            number: "",
            percentage: ""
        },
        womaternityBenifit: {
            number: "",
            percentage: ""
        },
        wopaternityBenift: {
            number: "",
            percentage: ""
        },
        wodayCarefacilities: {
            number: "",
            percentage: ""
        },

        wttotal: 0,
        wthealthInsurance: {
            number: "",
            percentage: ""
        },
        wtaccidentInsurance: {
            number: "",
            percentage: ""
        },
        wtmaternityBenifit: {
            number: "",
            percentage: ""
        },
        wtpaternityBenift: {
            number: "",
            percentage: ""
        },
        wtdayCarefacilities: {
            number: "",
            percentage: ""
        },



        wothertotal: 0,
        wotherhealthInsurance: {
            number: "",
            percentage: ""
        },
        wotheraccidentInsurance: {
            number: "",
            percentage: ""
        },
        wothermaternityBenifit: {
            number: "",
            percentage: ""
        },
        wotherpaternityBenift: {
            number: "",
            percentage: ""
        },
        wotherdayCarefacilities: {
            number: "",
            percentage: ""
        },

        wotherftotal: 0,
        wotherfhealthInsurance: {
            number: "",
            percentage: ""
        },
        wotherfaccidentInsurance: {
            number: "",
            percentage: ""
        },
        wotherfmaternityBenifit: {
            number: "",
            percentage: ""
        },
        wotherfpaternityBenift: {
            number: "",
            percentage: ""
        },
        wotherfdayCarefacilities: {
            number: "",
            percentage: ""
        },

        wotherototal: 0,
        wotherohealthInsurance: {
            number: "",
            percentage: ""
        },
        wotheroaccidentInsurance: {
            number: "",
            percentage: ""
        },
        wotheromaternityBenifit: {
            number: "",
            percentage: ""
        },
        wotheropaternityBenift: {
            number: "",
            percentage: ""
        },
        wotherodayCarefacilities: {
            number: "",
            percentage: ""
        },

        wotherttotal: 0,
        wotherthealthInsurance: {
            number: "",
            percentage: ""
        },
        wothertaccidentInsurance: {
            number: "",
            percentage: ""
        },
        wothertmaternityBenifit: {
            number: "",
            percentage: ""
        },
        wothertpaternityBenift: {
            number: "",
            percentage: ""
        },
        wothertdayCarefacilities: {
            number: "",
            percentage: ""
        },
        employeeAndWorkersNote: "",
        retirementBenifitDescription: "",
        officeAccessbiltyForPWD: {
            status: "Yes", // [Yes/ No]
            details: ""
        },
        equalOpportunityPolicyDetails: {
            status: "Yes", // [Yes/ No]
            details: ""
        },

        permanentEmployees: {
            male: {
                returnToWorkRate: "",
                retentionRate: ""
            },
            female: {
                returnToWorkRate: "",
                retentionRate: ""
            },
            others: {
                returnToWorkRate: "",
                retentionRate: ""
            },
            total: {
                returnToWorkRate: "",
                retentionRate: ""
            }
        },
        permanentWorkers: {
            male: {
                returnToWorkRate: "",
                retentionRate: ""
            },
            female: {
                returnToWorkRate: "",
                retentionRate: ""
            },
            others: {
                returnToWorkRate: "",
                retentionRate: ""
            },
            total: {
                returnToWorkRate: "",
                retentionRate: ""
            }
        },

        mechanisamDetails: {
            isAvailable: "Yes", // [Yes/No]
            briefDetailsOfMechanism: {
                permanentAddress: "",
                otherThanPermanentAddress: "",
                permanentEmployees: "",
                otherThanPermannentEmployees: ""
            },
            note: ""
        },


        c_pe_total: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        c_pe_male: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        c_pe_female: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        c_pe_others: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },


        c_pw_total: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        c_pw_male: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        c_pw_female: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        c_pw_others: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },


        p_pe_total: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        p_pe_male: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        p_pe_female: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        p_pe_others: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },


        p_pw_total: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        p_pw_male: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        p_pw_female: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        p_pw_others: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },


        c_totalPermanentEmployees: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        c_totalPermanentWorkers: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },


        p_totalPermanentEmployees: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        p_totalPermanentWorkers: {
            total: 0,
            associationParticipators: 0,
            percentage: ""
        },
        note: "",


        c_e_m_total: 0,
        c_e_m_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        c_e_m_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },

        c_e_f_total: 0,
        c_e_f_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        c_e_f_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },

        c_e_o_total: 0,
        c_e_o_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        c_e_o_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },


        c_e_t_total: 0,
        c_e_t_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        c_e_t_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },


        c_w_m_total: 0,
        c_w_m_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        c_w_m_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },

        c_w_f_total: 0,
        c_w_f_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        c_w_f_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },

        c_w_o_total: 0,
        c_w_o_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        c_w_o_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },


        c_w_t_total: 0,
        c_w_t_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        c_w_t_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },



        p_e_m_total: 0,
        p_e_m_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        p_e_m_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },

        p_e_f_total: 0,
        p_e_f_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        p_e_f_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },

        p_e_o_total: 0,
        p_e_o_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        p_e_o_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },


        p_e_t_total: 0,
        p_e_t_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        p_e_t_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },


        p_w_m_total: 0,
        p_w_m_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        p_w_m_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },

        p_w_f_total: 0,
        p_w_f_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        p_w_f_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },

        p_w_o_total: 0,
        p_w_o_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        p_w_o_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },


        p_w_t_total: 0,
        p_w_t_healthyAndSafetyMesure: {
            unit: 0,
            percent: ""
        },
        p_w_t_skillUpgradatioin: {
            unit: 0,
            percent: ""
        },

        secondnote: "",


        d_c_e_male: {
            total: 0,
            unit: 0,
            percent: ""
        },
        d_c_e_female: {
            total: 0,
            unit: 0,
            percent: ""
        },
        d_c_e_others: {
            total: 0,
            unit: 0,
            percent: ""
        },
        d_c_e_total: {
            total: 0,
            unit: 0,
            percent: ""
        },


        d_c_w_male: {
            total: 0,
            unit: 0,
            percent: ""
        },
        d_c_w_female: {
            total: 0,
            unit: 0,
            percent: ""
        },
        d_c_w_others: {
            total: 0,
            unit: 0,
            percent: ""
        },
        d_c_w_total: {
            total: 0,
            unit: 0,
            percent: ""
        },


        d_p_e_male: {
            total: 0,
            unit: 0,
            percent: ""
        },
        d_p_e_female: {
            total: 0,
            unit: 0,
            percent: ""
        },
        d_p_e_others: {
            total: 0,
            unit: 0,
            percent: ""
        },
        d_p_e_total: {
            total: 0,
            unit: 0,
            percent: ""
        },


        d_p_w_male: {
            total: 0,
            unit: 0,
            percent: ""
        },
        d_p_w_female: {
            total: 0,
            unit: 0,
            percent: ""
        },
        d_p_w_others: {
            total: 0,
            unit: 0,
            percent: ""
        },
        d_p_w_total: {
            total: 0,
            unit: 0,
            percent: ""
        },

        details: "",

        healthAndSafetyManagementStatus: {
            isImplement: "Yes", // [Yes/No]
            details: ""
        },
        usedProcessDetails: "",
        workRelatedReport: {
            status: "Yes",
            details: ""
        },
        nonOccupationalDetails: {
            status: "Yes",
            details: ""
        },


        LIIFR: {
            employees: {
                currentYear: "",
                previousYear: ""
            },
            workers: {
                currentYear: "",
                previousYear: ""
            }
        },
        totalRecordableInjuries: {
            employees: {
                currentYear: "",
                previousYear: ""
            },
            workers: {
                currentYear: "",
                previousYear: ""
            }
        },
        noOfFatalities: {
            employees: {
                currentYear: "",
                previousYear: ""
            },
            workers: {
                currentYear: "",
                previousYear: ""
            }
        },
        highConsequenceInjuries: {
            employees: {
                currentYear: "",
                previousYear: ""
            },
            workers: {
                currentYear: "",
                previousYear: ""
            }
        },
        workPlaceMesureDetails: "",

        currentYear: {
            workingCondition: {
                duringTheYear: "",
                endOfTHeYear: "",
                remarks: ""
            },
            healthAndSafety: {
                duringTheYear: "",
                endOfTHeYear: "",
                remarks: ""
            }
        },
        previousYear: {
            workingCondition: {
                duringTheYear: "",
                endOfTHeYear: "",
                remarks: ""
            },
            healthAndSafety: {
                duringTheYear: "",
                endOfTHeYear: "",
                remarks: ""
            }
        },


        assessmentsForTheYear: {
            healthAndSafetyPractice: "",
            workingCondition: "",
            note: ""
        },
        correspondingActionDetails: "",


        extendedLifeInsurance: { // 1
            employeesStatus: "Yes", //[Yes/No]
            workersStaus: "No" //[Yes/No]
        },
        extendedLifeInsuranceDetails: "",
        measureValueOfChainPartners: "",
        describe: "",
        employee: {
            totalNoOfEffected: {
                currentYear: "",
                previousYear: ""
            },
            rehabitedOrPlacedDetails: {
                currentYear: "",
                previousYear: ""
            }
        },
        worker: {
            totalNoOfEffected: {
                currentYear: "",
                previousYear: ""
            },
            rehabitedOrPlacedDetails: {
                currentYear: "",
                previousYear: ""
            }
        },
        p_note: "",

        provideTransitionDetails: { // 4
            isProvideTransition: "Yes", // [Yes/No]
            details: ""
        },
        assessmentValueOfChainPartners: {
            note: "",
            healthAndSafetyPractice: "",
            workingCondition: ""
        },
        detailsValueOfChainPartners: ""
    }

    const initretirementBenifitDetails = {
        benifit: "",
        applicabilityCountry: "",
        currentYear: {
            employeeCoverd: "",
            workerCoverd: "",
            deductedAndDepositedCoverd: ""
        },
        previousYear: {
            employeeCoverd: "",
            workerCoverd: "",
            deductedAndDepositedCoverd: ""
        }
    }
    const [formvalue, setformvalue] = useState(initvalue);
    const [retirementBenifitDetails, setretirementBenifitDetails] = useState([initretirementBenifitDetails]);
    const [notemodal1, setnotemodal1] = useState(false);
    const [notemodal2, setnotemodal2] = useState(false);
    const [notemodal3, setnotemodal3] = useState(false);
    const [notemodal4, setnotemodal4] = useState(false);
    const [notemodal5, setnotemodal5] = useState(false);
    const [notemodal6, setnotemodal6] = useState(false);
    const [notemodal7, setnotemodal7] = useState(false);


    //for Input fields
    const handleInput = (category, field, value) => {
        setformvalue((prevValue) => ({
            ...prevValue,
            [category]: {
                ...prevValue[category],
                [field]: value,
            }
        }));
    }


    //for input fields
    const handleInputChange = (e, section, year, field) => {
        const value = e.currentTarget.textContent;
        setformvalue(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [year]: {
                    ...prevState[section][year],
                    [field]: value
                }
            }
        }));
    };


    //handle change for  add more functionality
    const handleChangeretirementBenifitDetails = (i, e, section, field) => {
        let newFormValues = [...retirementBenifitDetails];
        newFormValues[i][section][field] = e.currentTarget.textContent;
        setretirementBenifitDetails(newFormValues);
    };

    //handle change nested add more functionality
    const handleChangeretirementBenifit = (i, e, field) => {
        let newFormValues = [...retirementBenifitDetails];
        newFormValues[i][field] = e.currentTarget.textContent;
        setretirementBenifitDetails(newFormValues);
    };

    //for add more functionality 
    const addretirementBenifitDetails = () => {
        setretirementBenifitDetails([...retirementBenifitDetails, initretirementBenifitDetails]);
    };

    //for removing add more field
    const removeretirementBenifitDetails = (i) => {
        let newFormValues = [...retirementBenifitDetails];
        newFormValues.splice(i, 1);
        setretirementBenifitDetails(newFormValues);
    };


    //for submit functionality
    const submitHandler = async (e) => {
        e.preventDefault();
        let data = {};
        data = {

            "principleThree": {
                "currentYear": currentFY,
                "previousYear": previousFY,
                "essentialIndicators": {
                    "employeeDetails": {
                        "permanentEmployees": {
                            "male": {
                                "total": +formvalue?.total,
                                "healthInsurance": {
                                    "number": formvalue?.healthInsurance?.number,
                                    "percentage": formvalue?.healthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.accidentInsurance?.number,
                                    "percentage": formvalue?.accidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.maternityBenifit?.number,
                                    "percentage": formvalue?.maternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.paternityBenift?.number,
                                    "percentage": formvalue?.paternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.dayCarefacilities?.number,
                                    "percentage": formvalue?.dayCarefacilities?.percentage,
                                }
                            },
                            "female": {
                                "total": +formvalue?.ftotal,
                                "healthInsurance": {
                                    "number": formvalue?.fhealthInsurance?.number,
                                    "percentage": formvalue?.fhealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.faccidentInsurance?.number,
                                    "percentage": formvalue?.faccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.fmaternityBenifit?.number,
                                    "percentage": formvalue?.fmaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.fpaternityBenift?.number,
                                    "percentage": formvalue?.fpaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.fdayCarefacilities?.number,
                                    "percentage": formvalue?.fdayCarefacilities?.percentage,
                                }
                            },
                            "others": {
                                "total": +formvalue?.ototal,
                                "healthInsurance": {
                                    "number": formvalue?.ohealthInsurance?.number,
                                    "percentage": formvalue?.ohealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.oaccidentInsurance?.number,
                                    "percentage": formvalue?.oaccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.omaternityBenifit?.number,
                                    "percentage": formvalue?.omaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.opaternityBenift?.number,
                                    "percentage": formvalue?.opaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.odayCarefacilities?.number,
                                    "percentage": formvalue?.odayCarefacilities?.percentage,
                                }
                            },
                            "total": {
                                "total": +formvalue?.ttotal,
                                "healthInsurance": {
                                    "number": formvalue?.thealthInsurance?.number,
                                    "percentage": formvalue?.thealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.taccidentInsurance?.number,
                                    "percentage": formvalue?.taccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.tmaternityBenifit?.number,
                                    "percentage": formvalue?.tmaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.tpaternityBenift?.number,
                                    "percentage": formvalue?.tpaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.tdayCarefacilities?.number,
                                    "percentage": formvalue?.tdayCarefacilities?.percentage,
                                }
                            }
                        },
                        "otherThanPermanentEmployees": {
                            "male": {
                                "total": +formvalue?.othertotal,
                                "healthInsurance": {
                                    "number": formvalue?.otherhealthInsurance?.number,
                                    "percentage": formvalue?.otherhealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.otheraccidentInsurance?.number,
                                    "percentage": formvalue?.otheraccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.othermaternityBenifit?.number,
                                    "percentage": formvalue?.othermaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.otherpaternityBenift?.number,
                                    "percentage": formvalue?.otherpaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.otherdayCarefacilities?.number,
                                    "percentage": formvalue?.otherdayCarefacilities?.percentage,
                                }
                            },
                            "female": {
                                "total": +formvalue?.otherftotal,
                                "healthInsurance": {
                                    "number": formvalue?.otherfhealthInsurance?.number,
                                    "percentage": formvalue?.otherfhealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.otherfaccidentInsurance?.number,
                                    "percentage": formvalue?.otherfaccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.otherfmaternityBenifit?.number,
                                    "percentage": formvalue?.otherfmaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.otherfpaternityBenift?.number,
                                    "percentage": formvalue?.otherfpaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.otherfdayCarefacilities?.number,
                                    "percentage": formvalue?.otherfdayCarefacilities?.percentage,
                                }
                            },
                            "others": {
                                "total": +formvalue?.otherototal,
                                "healthInsurance": {
                                    "number": formvalue?.otherohealthInsurance?.number,
                                    "percentage": formvalue?.otherohealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.otheroaccidentInsurance?.number,
                                    "percentage": formvalue?.otheroaccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.otheromaternityBenifit?.number,
                                    "percentage": formvalue?.otheromaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.otheropaternityBenift?.number,
                                    "percentage": formvalue?.otheropaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.otherodayCarefacilities?.number,
                                    "percentage": formvalue?.otherodayCarefacilities?.percentage,
                                }
                            },
                            "total": {
                                "total": +formvalue?.otherttotal,
                                "healthInsurance": {
                                    "number": formvalue?.otherthealthInsurance?.number,
                                    "percentage": formvalue?.otherthealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.othertaccidentInsurance?.number,
                                    "percentage": formvalue?.othertaccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.othertmaternityBenifit?.number,
                                    "percentage": formvalue?.othertmaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.othertpaternityBenift?.number,
                                    "percentage": formvalue?.othertpaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.othertdayCarefacilities?.number,
                                    "percentage": formvalue?.othertdayCarefacilities?.percentage,
                                }
                            }
                        }
                    },
                    "workerDetails": {
                        "permanentWorkers": {
                            "male": {
                                "total": +formvalue?.wtotal,
                                "healthInsurance": {
                                    "number": formvalue?.whealthInsurance?.number,
                                    "percentage": formvalue?.whealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.waccidentInsurance?.number,
                                    "percentage": formvalue?.waccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.wmaternityBenifit?.number,
                                    "percentage": formvalue?.wmaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.wpaternityBenift?.number,
                                    "percentage": formvalue?.wpaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.wdayCarefacilities?.number,
                                    "percentage": formvalue?.wdayCarefacilities?.percentage,
                                }
                            },
                            "female": {
                                "total": +formvalue?.wftotal,
                                "healthInsurance": {
                                    "number": formvalue?.wfhealthInsurance?.number,
                                    "percentage": formvalue?.wfhealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.wfaccidentInsurance?.number,
                                    "percentage": formvalue?.wfaccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.wfmaternityBenifit?.number,
                                    "percentage": formvalue?.wfmaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.wfpaternityBenift?.number,
                                    "percentage": formvalue?.wfpaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.wfdayCarefacilities?.number,
                                    "percentage": formvalue?.wfdayCarefacilities?.percentage,
                                }
                            },
                            "others": {
                                "total": +formvalue?.wototal,
                                "healthInsurance": {
                                    "number": formvalue?.wohealthInsurance?.number,
                                    "percentage": formvalue?.wohealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.woaccidentInsurance?.number,
                                    "percentage": formvalue?.woaccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.womaternityBenifit?.number,
                                    "percentage": formvalue?.womaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.wopaternityBenift?.number,
                                    "percentage": formvalue?.wopaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.wodayCarefacilities?.number,
                                    "percentage": formvalue?.wodayCarefacilities?.percentage,
                                }
                            },
                            "total": {
                                "total": +formvalue?.wttotal,
                                "healthInsurance": {
                                    "number": formvalue?.wthealthInsurance?.number,
                                    "percentage": formvalue?.wthealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.wtaccidentInsurance?.number,
                                    "percentage": formvalue?.wtaccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.wtmaternityBenifit?.number,
                                    "percentage": formvalue?.wtmaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.wtpaternityBenift?.number,
                                    "percentage": formvalue?.wtpaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.wtdayCarefacilities?.number,
                                    "percentage": formvalue?.wtdayCarefacilities?.percentage,
                                }
                            }
                        },
                        "otherThanPermanentWorkers": {
                            "male": {
                                "total": +formvalue?.wothertotal,
                                "healthInsurance": {
                                    "number": formvalue?.wotherhealthInsurance?.number,
                                    "percentage": formvalue?.wotherhealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.wotheraccidentInsurance?.number,
                                    "percentage": formvalue?.wotheraccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.wothermaternityBenifit?.number,
                                    "percentage": formvalue?.wothermaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.wotherpaternityBenift?.number,
                                    "percentage": formvalue?.wotherpaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.wotherdayCarefacilities?.number,
                                    "percentage": formvalue?.wotherdayCarefacilities?.percentage,
                                }
                            },
                            "female": {
                                "total": +formvalue?.wotherftotal,
                                "healthInsurance": {
                                    "number": formvalue?.wotherfhealthInsurance?.number,
                                    "percentage": formvalue?.wotherfhealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.wotherfaccidentInsurance?.number,
                                    "percentage": formvalue?.wotherfaccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.wotherfmaternityBenifit?.number,
                                    "percentage": formvalue?.wotherfmaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.wotherfpaternityBenift?.number,
                                    "percentage": formvalue?.wotherfpaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.wotherfdayCarefacilities?.number,
                                    "percentage": formvalue?.wotherfdayCarefacilities?.percentage,
                                }
                            },
                            "others": {
                                "total": +formvalue?.wotherototal,
                                "healthInsurance": {
                                    "number": formvalue?.wotherohealthInsurance?.number,
                                    "percentage": formvalue?.wotherohealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.otheroaccidentInsurance?.number,
                                    "percentage": formvalue?.otheroaccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.otheromaternityBenifit?.number,
                                    "percentage": formvalue?.otheromaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.otheropaternityBenift?.number,
                                    "percentage": formvalue?.otheropaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.otherodayCarefacilities?.number,
                                    "percentage": formvalue?.otherodayCarefacilities?.percentage,
                                }
                            },
                            "total": {
                                "total": +formvalue?.wotherttotal,
                                "healthInsurance": {
                                    "number": formvalue?.wotherthealthInsurance?.number,
                                    "percentage": formvalue?.wotherthealthInsurance?.percentage
                                },
                                "accidentInsurance": {
                                    "number": formvalue?.wothertaccidentInsurance?.number,
                                    "percentage": formvalue?.wothertaccidentInsurance?.percentage
                                },
                                "maternityBenifit": {
                                    "number": formvalue?.wothertmaternityBenifit?.number,
                                    "percentage": formvalue?.wothertmaternityBenifit?.percentage
                                },
                                "paternityBenift": {
                                    "number": formvalue?.wothertpaternityBenift?.number,
                                    "percentage": formvalue?.wothertpaternityBenift?.percentage,
                                },
                                "dayCarefacilities": {
                                    "number": formvalue?.wothertdayCarefacilities?.number,
                                    "percentage": formvalue?.wothertdayCarefacilities?.percentage,
                                }
                            }
                        }
                    },
                    "employeeAndWorkersNote": formvalue?.employeeAndWorkersNote,

                    "retirementBenifitDetails": retirementBenifitDetails,
                    "retirementBenifitDescription": formvalue?.retirementBenifitDescription,

                    "officeAccessbiltyForPWD": {
                        "status": formvalue?.officeAccessbiltyForPWD?.status, // [Yes/ No]
                        "details": formvalue?.officeAccessbiltyForPWD?.details
                    },
                    "equalOpportunityPolicyDetails": {
                        "status": formvalue?.equalOpportunityPolicyDetails?.status,// [Yes/ No]
                        "details": formvalue?.equalOpportunityPolicyDetails?.details
                    },
                    "retentionDetails": {
                        "permanentEmployees": {
                            "male": {
                                "returnToWorkRate": formvalue?.permanentEmployees?.male?.returnToWorkRate,
                                "retentionRate": formvalue?.permanentEmployees?.male?.retentionRate
                            },
                            "female": {
                                "returnToWorkRate": formvalue?.permanentEmployees?.female?.returnToWorkRate,
                                "retentionRate": formvalue?.permanentEmployees?.female?.retentionRate
                            },
                            "others": {
                                "returnToWorkRate": formvalue?.permanentEmployees?.others?.returnToWorkRate,
                                "retentionRate": formvalue?.permanentEmployees?.others?.retentionRate
                            },
                            "total": {
                                "returnToWorkRate": formvalue?.permanentEmployees?.total?.returnToWorkRate,
                                "retentionRate": formvalue?.permanentEmployees?.total?.retentionRate
                            }
                        },
                        "permanentWorkers": {
                            "male": {
                                "returnToWorkRate": formvalue?.permanentWorkers?.male?.returnToWorkRate,
                                "retentionRate": formvalue?.permanentWorkers?.male?.retentionRate
                            },
                            "female": {
                                "returnToWorkRate": formvalue?.permanentWorkers?.female?.returnToWorkRate,
                                "retentionRate": formvalue?.permanentWorkers?.female?.retentionRate
                            },
                            "others": {
                                "returnToWorkRate": formvalue?.permanentWorkers?.others?.returnToWorkRate,
                                "retentionRate": formvalue?.permanentWorkers?.others?.retentionRate
                            },
                            "total": {
                                "returnToWorkRate": formvalue?.permanentWorkers?.total?.returnToWorkRate,
                                "retentionRate": formvalue?.permanentWorkers?.total?.retentionRate
                            }
                        }
                    },
                    "mechanisamDetails": {
                        "isAvailable": formvalue?.mechanisamDetails?.isAvailable, // [Yes/No]
                        "briefDetailsOfMechanism": {
                            "permanentAddress": formvalue?.mechanisamDetails?.briefDetailsOfMechanism?.permanentAddress,
                            "otherThanPermanentAddress": formvalue?.mechanisamDetails?.briefDetailsOfMechanism?.otherThanPermanentAddress,
                            "permanentEmployees": formvalue?.mechanisamDetails?.briefDetailsOfMechanism?.permanentEmployees,
                            "otherThanPermannentEmployees": formvalue?.mechanisamDetails?.briefDetailsOfMechanism?.otherThanPermannentEmployees
                        },
                        "note": formvalue?.mechanisamDetails?.note
                    },
                    "membershipDetails": {
                        "entityDetails": {
                            "currentYear": {
                                "permanentEmployees": {
                                    "total": {
                                        "total": +formvalue?.c_pe_total?.total,
                                        "associationParticipators": +formvalue?.c_pe_total?.associationParticipators,
                                        "percentage": formvalue?.c_pe_total?.percentage
                                    },
                                    "male": {
                                        "total": +formvalue?.c_pe_male?.total,
                                        "associationParticipators": +formvalue?.c_pe_male?.associationParticipators,
                                        "percentage": formvalue?.c_pe_male?.percentage
                                    },
                                    "female": {
                                        "total": +formvalue?.c_pe_female?.total,
                                        "associationParticipators": +formvalue?.c_pe_female?.associationParticipators,
                                        "percentage": formvalue?.c_pe_female?.percentage
                                    },
                                    "others": {
                                        "total": +formvalue?.c_pe_others?.total,
                                        "associationParticipators": +formvalue?.c_pe_others?.associationParticipators,
                                        "percentage": formvalue?.c_pe_others?.percentage
                                    }
                                },
                                "permanentWorkers": {
                                    "total": {
                                        "total": +formvalue?.c_pw_total?.total,
                                        "associationParticipators": +formvalue?.c_pw_total?.associationParticipators,
                                        "percentage": formvalue?.c_pw_total?.percentage
                                    },
                                    "male": {
                                        "total": +formvalue?.c_pw_male?.total,
                                        "associationParticipators": +formvalue?.c_pw_male?.associationParticipators,
                                        "percentage": formvalue?.c_pw_male?.percentage
                                    },
                                    "female": {
                                        "total": +formvalue?.c_pw_female?.total,
                                        "associationParticipators": +formvalue?.c_pw_female?.associationParticipators,
                                        "percentage": formvalue?.c_pw_female?.percentage
                                    },
                                    "others": {
                                        "total": +formvalue?.c_pw_others?.total,
                                        "associationParticipators": +formvalue?.c_pw_others?.associationParticipators,
                                        "percentage": formvalue?.c_pw_others?.percentage
                                    }
                                }
                            },
                            "previousYear": {
                                "permanentEmployees": {
                                    "total": {
                                        "total": +formvalue?.p_pe_total?.total,
                                        "associationParticipators": +formvalue?.p_pe_total?.associationParticipators,
                                        "percentage": formvalue?.p_pe_total?.percentage
                                    },
                                    "male": {
                                        "total": +formvalue?.p_pe_male?.total,
                                        "associationParticipators": +formvalue?.p_pe_male?.associationParticipators,
                                        "percentage": formvalue?.p_pe_male?.percentage
                                    },
                                    "female": {
                                        "total": +formvalue?.p_pe_female?.total,
                                        "associationParticipators": +formvalue?.p_pe_female?.associationParticipators,
                                        "percentage": formvalue?.p_pe_female?.percentage
                                    },
                                    "others": {
                                        "total": +formvalue?.p_pe_others?.total,
                                        "associationParticipators": +formvalue?.p_pe_others?.associationParticipators,
                                        "percentage": formvalue?.p_pe_others?.percentage
                                    }
                                },
                                "permanentWorkers": {
                                    "total": {
                                        "total": +formvalue?.p_pw_total?.total,
                                        "associationParticipators": +formvalue?.c_pw_total?.associationParticipators,
                                        "percentage": formvalue?.p_pw_total?.percentage
                                    },
                                    "male": {
                                        "total": +formvalue?.p_pw_male?.total,
                                        "associationParticipators": +formvalue?.p_pw_male?.associationParticipators,
                                        "percentage": formvalue?.p_pw_male?.percentage
                                    },
                                    "female": {
                                        "total": +formvalue?.p_pw_female?.total,
                                        "associationParticipators": +formvalue?.p_pw_female?.associationParticipators,
                                        "percentage": formvalue?.p_pw_female?.percentage
                                    },
                                    "others": {
                                        "total": +formvalue?.p_pw_others?.total,
                                        "associationParticipators": +formvalue?.p_pw_others?.associationParticipators,
                                        "percentage": formvalue?.p_pw_others?.percentage
                                    }
                                }
                            },
                            "impression": formvalue?.impression

                        },
                        "overAllEntityDetails": {
                            "currentYear": {
                                "totalPermanentEmployees": {
                                    "total": formvalue?.c_totalPermanentEmployees?.total,
                                    "associationParticipators": formvalue?.c_totalPermanentEmployees?.associationParticipators,
                                    "percentage": formvalue?.c_totalPermanentEmployees?.percentage
                                },
                                "totalPermanentWorkers": {
                                    "total": formvalue?.c_totalPermanentWorkers?.total,
                                    "associationParticipators": formvalue?.c_totalPermanentWorkers?.associationParticipators,
                                    "percentage": formvalue?.c_totalPermanentWorkers?.percentage
                                }
                            },
                            "previousYear": {
                                "totalPermanentEmployees": {
                                    "total": formvalue?.p_totalPermanentEmployees?.total,
                                    "associationParticipators": formvalue?.p_totalPermanentEmployees?.associationParticipators,
                                    "percentage": formvalue?.p_totalPermanentEmployees?.percentage
                                },
                                "totalPermanentWorkers": {
                                    "total": formvalue?.p_totalPermanentWorkers?.total,
                                    "associationParticipators": formvalue?.p_totalPermanentWorkers?.associationParticipators,
                                    "percentage": formvalue?.p_totalPermanentWorkers?.percentage
                                }
                            },
                            "note": formvalue?.note
                        }
                    },
                    // },

                    "trainingGivenDetails": { //8
                        "currentYear": {
                            "employees": {
                                "male": {
                                    "total": +formvalue?.c_e_m_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.c_e_m_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.c_e_m_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.c_e_m_skillUpgradatioin?.unit,
                                        "percent": formvalue?.c_e_m_skillUpgradatioin?.percent
                                    }
                                },
                                "female": {
                                    "total": +formvalue?.c_e_f_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.c_e_f_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.c_e_f_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.c_e_f_skillUpgradatioin?.unit,
                                        "percent": formvalue?.c_e_f_skillUpgradatioin?.percent
                                    }
                                },
                                "others": {
                                    "total": +formvalue?.c_e_o_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.c_e_o_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.c_e_o_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.c_e_o_skillUpgradatioin?.unit,
                                        "percent": formvalue?.c_e_o_skillUpgradatioin?.percent
                                    }
                                },
                                "total": {
                                    "total": +formvalue?.c_e_t_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.c_e_t_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.c_e_t_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.c_e_t_skillUpgradatioin?.unit,
                                        "percent": formvalue?.c_e_t_skillUpgradatioin?.percent
                                    }
                                }
                            },
                            "workers": {
                                "male": {
                                    "total": +formvalue?.c_w_m_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.c_w_m_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.c_w_m_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.c_w_m_skillUpgradatioin?.unit,
                                        "percent": formvalue?.c_w_m_skillUpgradatioin?.percent
                                    }
                                },
                                "female": {
                                    "total": +formvalue?.c_w_f_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.c_w_f_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.c_w_f_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.c_w_f_skillUpgradatioin?.unit,
                                        "percent": formvalue?.c_w_f_skillUpgradatioin?.percent
                                    }
                                },
                                "others": {
                                    "total": +formvalue?.c_w_o_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.c_w_o_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.c_w_o_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.c_w_o_skillUpgradatioin?.unit,
                                        "percent": formvalue?.c_w_o_skillUpgradatioin?.percent
                                    }
                                },
                                "total": {
                                    "total": +formvalue?.c_w_t_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.c_w_t_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.c_w_t_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.c_w_t_skillUpgradatioin?.unit,
                                        "percent": formvalue?.c_w_t_skillUpgradatioin?.percent
                                    }
                                }
                            }
                        },
                        "previousYear": {
                            "employees": {
                                "male": {
                                    "total": +formvalue?.p_e_m_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.p_e_m_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.p_e_m_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.p_e_m_skillUpgradatioin?.unit,
                                        "percent": formvalue?.p_e_m_skillUpgradatioin?.percent
                                    }
                                },
                                "female": {
                                    "total": +formvalue?.p_e_f_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.p_e_f_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.p_e_f_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.p_e_f_skillUpgradatioin?.unit,
                                        "percent": formvalue?.p_e_f_skillUpgradatioin?.percent
                                    }
                                },
                                "others": {
                                    "total": +formvalue?.p_e_o_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.p_e_o_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.p_e_o_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.p_e_o_skillUpgradatioin?.unit,
                                        "percent": formvalue?.p_e_o_skillUpgradatioin?.percent
                                    }
                                },
                                "total": {
                                    "total": +formvalue?.p_e_t_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.p_e_t_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.p_e_t_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.p_e_t_skillUpgradatioin?.unit,
                                        "percent": formvalue?.p_e_t_skillUpgradatioin?.percent
                                    }
                                }
                            },
                            "workers": {
                                "male": {
                                    "total": +formvalue?.p_w_m_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.p_w_m_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.p_w_m_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.p_w_m_skillUpgradatioin?.unit,
                                        "percent": formvalue?.p_w_m_skillUpgradatioin?.percent
                                    }
                                },
                                "female": {
                                    "total": +formvalue?.p_w_f_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.p_w_f_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.p_w_f_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.p_w_f_skillUpgradatioin?.unit,
                                        "percent": formvalue?.p_w_f_skillUpgradatioin?.percent
                                    }
                                },
                                "others": {
                                    "total": +formvalue?.p_w_o_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.p_w_o_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.p_w_o_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.p_w_o_skillUpgradatioin?.unit,
                                        "percent": formvalue?.p_w_o_skillUpgradatioin?.percent
                                    }
                                },
                                "total": {
                                    "total": +formvalue?.p_w_t_total,
                                    "healthyAndSafetyMesure": {
                                        "unit": +formvalue?.p_w_t_healthyAndSafetyMesure?.unit,
                                        "percent": formvalue?.p_w_t_healthyAndSafetyMesure?.percent
                                    },
                                    "skillUpgradatioin": {
                                        "unit": +formvalue?.p_w_t_skillUpgradatioin?.unit,
                                        "percent": formvalue?.p_w_t_skillUpgradatioin?.percent
                                    }
                                }
                            }
                        },
                        "note": formvalue?.secondnote
                    },
                    "developmentOfReviews": { // 9
                        "currentYear": {
                            "employees": {
                                "male": {
                                    "total": +formvalue?.d_c_e_male?.total,
                                    "unit": +formvalue?.d_c_e_male?.unit,
                                    "percent": formvalue?.d_c_e_male?.percent
                                },
                                "female": {
                                    "total": +formvalue?.d_c_e_female?.total,
                                    "unit": +formvalue?.d_c_e_female?.unit,
                                    "percent": formvalue?.d_c_e_female?.percent
                                },
                                "others": {
                                    "total": +formvalue?.d_c_e_others?.total,
                                    "unit": +formvalue?.d_c_e_others?.unit,
                                    "percent": formvalue?.d_c_e_others?.percent
                                },
                                "total": {
                                    "total": +formvalue?.d_c_e_total?.total,
                                    "unit": +formvalue?.d_c_e_total?.unit,
                                    "percent": formvalue?.d_c_e_total?.percent
                                }
                            },
                            "workers": {
                                "male": {
                                    "total": +formvalue?.d_c_w_male?.total,
                                    "unit": +formvalue?.d_c_w_male?.unit,
                                    "percent": formvalue?.d_c_w_male?.percent
                                },
                                "female": {
                                    "total": +formvalue?.d_c_w_female?.total,
                                    "unit": +formvalue?.d_c_w_female?.unit,
                                    "percent": formvalue?.d_c_w_female?.percent
                                },
                                "others": {
                                    "total": +formvalue?.d_c_w_others?.total,
                                    "unit": +formvalue?.d_c_w_others?.unit,
                                    "percent": formvalue?.d_c_w_others?.percent
                                },
                                "total": {
                                    "total": +formvalue?.d_c_w_total?.total,
                                    "unit": +formvalue?.d_c_w_total?.unit,
                                    "percent": formvalue?.d_c_w_total?.percent
                                }
                            }
                        },
                        "previousYear": {
                            "employees": {
                                "male": {
                                    "total": +formvalue?.d_p_e_male?.total,
                                    "unit": +formvalue?.d_p_e_male?.unit,
                                    "percent": formvalue?.d_p_e_male?.percent
                                },
                                "female": {
                                    "total": +formvalue?.d_p_e_female?.total,
                                    "unit": +formvalue?.d_p_e_female?.unit,
                                    "percent": formvalue?.d_p_e_female?.percent
                                },
                                "others": {
                                    "total": +formvalue?.d_p_e_others?.total,
                                    "unit": +formvalue?.d_p_e_others?.unit,
                                    "percent": formvalue?.d_p_e_others?.percent
                                },
                                "total": {
                                    "total": +formvalue?.d_p_e_total?.total,
                                    "unit": +formvalue?.d_p_e_total?.unit,
                                    "percent": formvalue?.d_p_e_total?.percent
                                }
                            },
                            "workers": {
                                "male": {
                                    "total": +formvalue?.d_p_w_male?.total,
                                    "unit": +formvalue?.d_p_w_male?.unit,
                                    "percent": formvalue?.d_p_w_male?.percent
                                },
                                "female": {
                                    "total": +formvalue?.d_p_w_female?.total,
                                    "unit": +formvalue?.d_p_w_female?.unit,
                                    "percent": formvalue?.d_p_w_female?.percent
                                },
                                "others": {
                                    "total": +formvalue?.d_p_w_others?.total,
                                    "unit": +formvalue?.d_p_w_others?.unit,
                                    "percent": formvalue?.d_p_w_others?.percent
                                },
                                "total": {
                                    "total": +formvalue?.d_p_w_total?.total,
                                    "unit": +formvalue?.d_p_w_total?.unit,
                                    "percent": formvalue?.d_p_w_total?.percent
                                }
                            }
                        },
                        "details": formvalue?.details
                    },
                    "healthAndSafetyManagementDetails": { //10
                        "healthAndSafetyManagementStatus": {
                            "isImplement": formvalue?.healthAndSafetyManagementStatus?.isImplement, // [Yes/No]
                            "details": formvalue?.healthAndSafetyManagementStatus?.details
                        },
                        "usedProcessDetails": formvalue?.usedProcessDetails,
                        "workRelatedReport": {
                            "status": formvalue?.workRelatedReport?.status,
                            "details": formvalue?.workRelatedReport?.details
                        },
                        "nonOccupationalDetails": {
                            "status": formvalue?.nonOccupationalDetails?.status,
                            "details": formvalue?.nonOccupationalDetails?.details
                        }
                    },
                    "detailsOfSafetyRelated": { //11
                        "LIIFR": {
                            "employees": {
                                "currentYear": formvalue?.LIIFR?.employees?.currentYear,
                                "previousYear": formvalue?.LIIFR?.employees?.previousYear
                            },
                            "workers": {
                                "currentYear": formvalue?.LIIFR?.workers?.currentYear,
                                "previousYear": formvalue?.LIIFR?.workers?.previousYear
                            }
                        },
                        "totalRecordableInjuries": {
                            "employees": {
                                "currentYear": formvalue?.totalRecordableInjuries?.employees?.currentYear,
                                "previousYear": formvalue?.totalRecordableInjuries?.employees?.previousYear
                            },
                            "workers": {
                                "currentYear": formvalue?.totalRecordableInjuries?.workers?.currentYear,
                                "previousYear": formvalue?.totalRecordableInjuries?.workers?.previousYear
                            }
                        },
                        "noOfFatalities": {
                            "employees": {
                                "currentYear": formvalue?.noOfFatalities?.employees?.currentYear,
                                "previousYear": formvalue?.noOfFatalities?.employees?.previousYear
                            },
                            "workers": {
                                "currentYear": formvalue?.noOfFatalities?.workers?.currentYear,
                                "previousYear": formvalue?.noOfFatalities?.workers?.previousYear
                            }
                        },
                        "highConsequenceInjuries": {
                            "employees": {
                                "currentYear": formvalue?.highConsequenceInjuries?.employees?.currentYear,
                                "previousYear": formvalue?.highConsequenceInjuries?.employees?.previousYear
                            },
                            "workers": {
                                "currentYear": formvalue?.highConsequenceInjuries?.workers?.currentYear,
                                "previousYear": formvalue?.highConsequenceInjuries?.workers?.previousYear
                            }
                        }
                    },
                    "workPlaceMesureDetails": formvalue?.workPlaceMesureDetails, // 12
                    "emloyeesAndWorkersComplintsDetails": { //13
                        "currentYear": {
                            "workingCondition": {
                                "duringTheYear": formvalue?.currentYear?.workingCondition?.duringTheYear,
                                "endOfTHeYear": formvalue?.currentYear?.workingCondition?.endOfTHeYear,
                                "remarks": formvalue?.currentYear?.workingCondition?.remarks
                            },
                            "healthAndSafety": {
                                "duringTheYear": formvalue?.currentYear?.healthAndSafety?.duringTheYear,
                                "endOfTHeYear": formvalue?.currentYear?.healthAndSafety?.endOfTHeYear,
                                "remarks": formvalue?.currentYear?.healthAndSafety?.remarks
                            }
                        },
                        "previousYear": {
                            "workingCondition": {
                                "duringTheYear": formvalue?.previousYear?.workingCondition?.duringTheYear,
                                "endOfTHeYear": formvalue?.previousYear?.workingCondition?.endOfTHeYear,
                                "remarks": formvalue?.previousYear?.workingCondition?.remarks
                            },
                            "healthAndSafety": {
                                "duringTheYear": formvalue?.previousYear?.healthAndSafety?.duringTheYear,
                                "endOfTHeYear": formvalue?.previousYear?.healthAndSafety?.endOfTHeYear,
                                "remarks": formvalue?.previousYear?.healthAndSafety?.remarks
                            }
                        }
                    },
                    "assessmentsForTheYear": { //14
                        "healthAndSafetyPractice": formvalue?.assessmentsForTheYear?.healthAndSafetyPractice,
                        "workingCondition": formvalue?.assessmentsForTheYear?.workingCondition,
                        "note": formvalue?.assessmentsForTheYear?.note
                    },
                    "correspondingActionDetails": formvalue?.correspondingActionDetails,
                },

                "leadershipIndicators": {
                    "extendedLifeInsurance": { // 1
                        "employeesStatus": formvalue?.extendedLifeInsurance?.employeesStatus, //[Yes/No]
                        "workersStaus": formvalue?.extendedLifeInsurance?.workersStaus, //[Yes/No]
                        "extendedLifeInsuranceDetails": formvalue?.extendedLifeInsuranceDetails
                    },
                    "measureValueOfChainPartners": formvalue?.measureValueOfChainPartners, // 2
                    "employeeOrWorkerSufferdetails": { // 3
                        "describe": formvalue?.describe,
                        "employee": {
                            "totalNoOfEffected": {
                                "currentYear": formvalue?.employee?.totalNoOfEffected?.currentYear,
                                "previousYear": formvalue?.employee?.totalNoOfEffected?.previousYear
                            },
                            "rehabitedOrPlacedDetails": {
                                "currentYear": formvalue?.employee?.rehabitedOrPlacedDetails?.currentYear,
                                "previousYear": formvalue?.employee?.rehabitedOrPlacedDetails?.previousYear
                            }
                        },
                        "worker": {
                            "totalNoOfEffected": {
                                "currentYear": formvalue?.worker?.totalNoOfEffected?.currentYear,
                                "previousYear": formvalue?.worker?.totalNoOfEffected?.previousYear
                            },
                            "rehabitedOrPlacedDetails": {
                                "currentYear": formvalue?.worker?.rehabitedOrPlacedDetails?.currentYear,
                                "previousYear": formvalue?.worker?.rehabitedOrPlacedDetails?.previousYear
                            }
                            // }
                        },
                        "note": formvalue?.p_note
                    },

                    "provideTransitionDetails": { // 4
                        "isProvideTransition": formvalue?.provideTransitionDetails?.isProvideTransition, // [Yes/No]
                        "details": formvalue?.provideTransitionDetails?.details
                    },
                    "assessmentValueOfChainPartners": {
                        "note": formvalue?.assessmentValueOfChainPartners?.note,
                        "healthAndSafetyPractice": formvalue?.assessmentValueOfChainPartners?.healthAndSafetyPractice,
                        "workingCondition": formvalue?.assessmentValueOfChainPartners?.workingCondition
                    },
                    "detailsValueOfChainPartners": formvalue?.detailsValueOfChainPartners
                },
            }
        }

        console.log('principlethree', data);
        // return false;
        const res = await HttpClient?.requestData(
            `update-enviroment-principle/${principleOneData?._id}`,
            data,
            "POST"
        );
        if (res?.status) {
            toast?.success("Principle-3 is added successfully");
            setformvalue(initvalue);
            const contentEditableElements = document.querySelectorAll('[contentEditable]');
            contentEditableElements?.forEach(element => element.textContent = "");
            setretirementBenifitDetails([initretirementBenifitDetails]);
            setPrincicpletabdrp(4);
            setTogglesectionmdl(true)
        } else {
            toast?.error(res?.response?.data?.message || "Something went wrong");
        }

    }

    return (
        <div className=''>
            <h4 className='principletxtpp' >
                PRINCIPLE 3 Businesses should respect and promote the well-being
                of all employees, including those in their value chains

            </h4>
            <div className='borderessentialin'>
                <p className='idicatrstxtpp'> Essential Indicators</p>
                <div className=''>
                    <ul className='listoftblxsdivul'>
                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                a. Details of measures for the well-being of employees:
                            </li>

                            <table className='tablbrdrmain'>
                                <tr className='trdivmainbrdr'>
                                    <th className=''>

                                    </th>
                                    <th className='thdivmainbrdr' colSpan="11">
                                        <p className='prcnttxttemplytxtpp'>
                                            % of employees covered by
                                        </p>
                                    </th>
                                </tr>
                                <tr className='trdivmainbrdr'>
                                    <th className='spclebrdrleftwwww principlethreefisrcatedivvfrst' >
                                        <p className='cttsggrttxtpppc'>
                                            Category
                                        </p>
                                    </th>
                                    <th className=' principlethreefisrcatedivvscnd'>
                                        <p className='totyauttxttppttxpa'>
                                            Total (A)
                                        </p>

                                    </th>
                                    <th className='thdivmainbrdr principlethreefisrcatedivvthrd' colSpan="2">
                                        <p className='prcnttxttemplytxtpp'>Health
                                            insurance </p>
                                    </th>
                                    <th className='thdivmainbrdr principlethreefisrcatedivvfrth' colSpan="2">
                                        <p className='prcnttxttemplytxtpp'>
                                            Accident
                                            insurance
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr principlethreefisrcatedivvffth' colSpan="2" ><p className='prcnttxttemplytxtpp'>Maternity
                                        benefits </p>

                                    </th>
                                    <th className='thdivmainbrdr principlethreefisrcatedivvsxth' colSpan="2" > <p className='prcnttxttemplytxtpp'>Paternity
                                        Benefits </p>

                                    </th>
                                    <th className='thdivmainbrdr principlethreefisrcatedivvsvnth' colSpan="2" > <p className='prcnttxttemplytxtpp'> Day Care
                                        facilities </p>

                                    </th>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <th className='' >
                                    </th>
                                    <th className=' spclrdvrdrrrightbnbbdiv numberpermanenrepmlydivfrst' >
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivscnd'> <p
                                        className='  mnumbbrttprinvthreetxttppp'>  Number
                                        (B)</p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivthrd'> <p className='mnumbbrttprinvthreetxttppp'> (B /
                                        A) %</p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivfrth'> <p className='mnumbbrttprinvthreetxttppp'>
                                        Number
                                        (C)
                                    </p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivfvth'> <p className='mnumbbrttprinvthreetxttppp'>
                                        (C / A) %
                                    </p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivsxth'>
                                        <p className='mnumbbrttprinvthreetxttppp'>
                                            Number
                                            (D)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivegtrh'>
                                        <p className='mnumbbrttprinvthreetxttppp'> (D / A) % </p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivnnth'>
                                        <p className='mnumbbrttprinvthreetxttppp'>
                                            Number
                                            (E)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivtnth'>
                                        <p className='mnumbbrttprinvthreetxttppp'>
                                            (E /
                                            A) %
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivtelv'>
                                        <p className='mnumbbrttprinvthreetxttppp'>
                                            Number
                                            (F)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivthrtn'> <p className='mnumbbrttprinvthreetxttppp'>
                                        (F /
                                        A)%
                                    </p>
                                    </th>
                                </tr>

                                <tr className='' >
                                    <th className='thdivmainbrdr' colSpan="12">
                                        <p>
                                        </p>Permanent employees </th>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Male </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('healthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('healthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('accidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('accidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('maternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('maternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('paternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('paternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('dayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('dayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Female </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    ftotal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('fhealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('fhealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('faccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('faccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('fmaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('fmaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('fpaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('fpaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('fdayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('fdayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Others </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    ototal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('ohealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('ohealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('oaccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('oaccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('omaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('omaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('opaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('opaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('odayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('odayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Total </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    ttotal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('thealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('thealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('taccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('taccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('tmaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('tmaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('tpaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('tpaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('tdayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('tdayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>


                                <tr className='gap'>
                                    <th className='thdivmainbrdr' colspan="12">  Other than Permanent employees</th>
                                </tr>


                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Male </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    othertotal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherhealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherhealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otheraccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otheraccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('othermaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('othermaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherpaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherpaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherdayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherdayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Female </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    otherftotal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherfhealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherfhealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherfaccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherfaccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherfmaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherfmaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherfpaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherfpaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherfdayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherfdayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Others </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    otherototal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherohealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherohealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otheroaccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otheroaccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otheromaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otheromaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otheropaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otheropaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherodayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherodayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Total </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    otherttotal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherthealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('otherthealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('othertaccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('othertaccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('othertmaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('othertmaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('othertpaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('othertpaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('othertdayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('othertdayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                            </table>

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <p className='listoftblxsdiv'>
                                b. Details of measures for the well-being of employees:
                            </p>

                            <table className='tablbrdrmain'>
                                <tr className='trdivmainbrdr'>
                                    <th className=''>

                                    </th>
                                    <th className='thdivmainbrdr' colSpan="11">
                                        <p className='prcnttxttemplytxtpp'>
                                            % of workers covered by
                                        </p>
                                    </th>
                                </tr>
                                <tr className='trdivmainbrdr'>
                                    <th className='spclebrdrleftwwww principlethreefisrcatedivvfrst' >
                                        <p className='cttsggrttxtpppc'>
                                            Category
                                        </p>
                                    </th>
                                    <th className=' principlethreefisrcatedivvscnd'>
                                        <p className='totyauttxttppttxpa'>
                                            Total (A)
                                        </p>

                                    </th>
                                    <th className='thdivmainbrdr principlethreefisrcatedivvthrd' colSpan="2">
                                        <p className='prcnttxttemplytxtpp'>Health
                                            insurance </p>
                                    </th>
                                    <th className='thdivmainbrdr principlethreefisrcatedivvfrth' colSpan="2">
                                        <p className='prcnttxttemplytxtpp'>
                                            Accident
                                            insurance
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr principlethreefisrcatedivvffth' colSpan="2" ><p className='prcnttxttemplytxtpp'>Maternity
                                        benefits </p>

                                    </th>
                                    <th className='thdivmainbrdr principlethreefisrcatedivvsxth' colSpan="2" > <p className='prcnttxttemplytxtpp'>Paternity
                                        Benefits </p>

                                    </th>
                                    <th className='thdivmainbrdr principlethreefisrcatedivvsvnth' colSpan="2" > <p className='prcnttxttemplytxtpp'> Day Care
                                        facilities </p>

                                    </th>
                                </tr>
                                <tr className='trdivmainbrdr'>
                                    <th className='' >
                                    </th>
                                    <th className=' spclrdvrdrrrightbnbbdiv numberpermanenrepmlydivfrst' >
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivscnd'> <p
                                        className='  mnumbbrttprinvthreetxttppp'>  Number
                                        (B)</p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivthrd'> <p className='mnumbbrttprinvthreetxttppp'> (B /
                                        A) %</p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivfrth'> <p className='mnumbbrttprinvthreetxttppp'>
                                        Number
                                        (C)
                                    </p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivfvth'> <p className='mnumbbrttprinvthreetxttppp'>
                                        (C / A) %
                                    </p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivsxth'>
                                        <p className='mnumbbrttprinvthreetxttppp'>
                                            Number
                                            (D)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivegtrh'>
                                        <p className='mnumbbrttprinvthreetxttppp'> (D / A) % </p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivnnth'>
                                        <p className='mnumbbrttprinvthreetxttppp'>
                                            Number
                                            (E)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivtnth'>
                                        <p className='mnumbbrttprinvthreetxttppp'>
                                            (E /
                                            A) %
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivtelv'>
                                        <p className='mnumbbrttprinvthreetxttppp'>
                                            Number
                                            (F)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr numberpermanenrepmlydivthrtn'> <p className='mnumbbrttprinvthreetxttppp'>
                                        (F /
                                        A)%
                                    </p>
                                    </th>
                                </tr>

                                <tr className='' >
                                    <th className='thdivmainbrdr' colSpan="12">
                                        <p>
                                        </p>Permanent workers </th>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Male </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    wtotal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('whealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('whealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('waccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('waccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wmaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wmaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wpaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wpaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wdayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wdayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Female </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    wftotal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wfhealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wfhealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wfaccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wfaccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wfmaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wfmaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wfpaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wfpaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wfdayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wfdayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Others </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    wototal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wohealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wohealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('woaccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('woaccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('womaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('womaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wopaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wopaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wodayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wodayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Total </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    wttotal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wthealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wthealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wtaccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wtaccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wtmaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wtmaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wtpaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wtpaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wtdayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wtdayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>


                                <tr className='gap'>
                                    <th className='thdivmainbrdr' colspan="12"> Other than Permanent workers</th>
                                </tr>


                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Male </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    wothertotal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherhealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherhealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotheraccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotheraccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wothermaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wothermaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherpaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherpaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherdayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherdayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Female </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    wotherftotal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherfhealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherfhealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherfaccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherfaccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherfmaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherfmaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherfpaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherfpaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherfdayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherfdayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Others </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    wotherototal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherohealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherohealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotheroaccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotheroaccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotheromaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotheromaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotheropaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotheropaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherodayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherodayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Total </p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    wotherttotal: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherthealthInsurance', 'number', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wotherthealthInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wothertaccidentInsurance', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wothertaccidentInsurance', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wothertmaternityBenifit', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wothertmaternityBenifit', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wothertpaternityBenift', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wothertpaternityBenift', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wothertdayCarefacilities', 'number', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('wothertdayCarefacilities', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                            </table>

                            <div className='notedivadmoredivflx'>
                                <div className=''>
                                    <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal1(!notemodal1)}>Note  </p>
                                </div>
                            </div>
                            {notemodal1 && <div className="yestrxtwarea mt-2 mb-0">

                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            employeeAndWorkersNote: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>}

                            <div className='rggsgttxbbedxgtsprijnd'>
                                <p class="noiriittxoppdjoyyypp" >Add More Details :</p>
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            employeeAndWorkersNote: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>
                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Details of retirement benefits, for Current FY and Previous Financial Year
                            </li>

                            <table className='tablbrdrmain'>
                                <tr className='trdivmainbrdr'>
                                    <th className='' style={{ borderRight: "1px solid #0006" }}>

                                    </th>

                                    <th className='' style={{ borderRight: "1px solid #0006" }}>

                                    </th>

                                    <th colspan="3" className='thdivmainbrdr'>
                                        <p className='prcnttxttemplytxtpp'>FY {currentFY}
                                            (Current Financial
                                            Year) </p>
                                    </th>
                                    <th colspan="3" className='thdivmainbrdr'>
                                        <p className='prcnttxttemplytxtpp'>
                                            FY {previousFY}
                                            (Previous Financial Year) </p>
                                    </th>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <th className='' style={{ borderRight: "1px solid #0006" }}>
                                        <p className='prcnttxttemplytxtpp'> Benefits</p>
                                    </th>
                                    <th className='' style={{ borderRight: "1px solid #0006" }}>
                                        <p className='mnumbbrttprinvthreetxttppp'>Applicability
                                            (Country)</p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr'>
                                        <p className="  mnumbbrttprinvthreetxttppp"> No. of
                                            employees
                                            covered as
                                            a % of
                                            total
                                            employees
                                        </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr' >
                                        <p className="  mnumbbrttprinvthreetxttppp"> No. of
                                            workers
                                            covered
                                            as a % of
                                            total
                                            workers
                                        </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr'>
                                        <p className="  mnumbbrttprinvthreetxttppp">  Deducted
                                            and
                                            deposited
                                            with the
                                            authority
                                            (Y/N/N.A.)
                                        </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr'>

                                        <p className="  mnumbbrttprinvthreetxttppp">
                                            No. of
                                            employees
                                            covered as
                                            a % of
                                            total
                                            employees</p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr' >
                                        <p className="  mnumbbrttprinvthreetxttppp">No. of
                                            workers
                                            covered as a
                                            % of total
                                            workers </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr'>
                                        <p className="  mnumbbrttprinvthreetxttppp">
                                            Deducted and
                                            deposited with
                                            the authority
                                            (Y/N/N.A.)
                                        </p>

                                    </th>


                                </tr>

                                {
                                    retirementBenifitDetails?.map((item, ind) => {
                                        return (
                                            <tr>
                                                <td className='tddivmainbrdr bentsdtffdfsudigbbdffsrt'>
                                                    {/* <p className='Malettsgvsgphsmkjghhutxtpp'>PF</p> */}

                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleChangeretirementBenifit(ind, e, "benifit")}

                                                    >
                                                    </div>

                                                </td>

                                                <td className='tddivmainbrdr bentsdtffdfsudigbbdfscnd'>
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleChangeretirementBenifit(ind, e, "applicabilityCountry")}
                                                    >
                                                    </div>
                                                </td>

                                                <td className='tddivmainbrdr bentsdtffdfsudigbbdfthrd'>
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleChangeretirementBenifitDetails(ind, e, "currentYear", "employeeCoverd")}
                                                    >
                                                    </div>
                                                </td>

                                                <td className='tddivmainbrdr bentsdtffdfsudigbbdffrth'>
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleChangeretirementBenifitDetails(ind, e, "currentYear", "workerCoverd")}
                                                    >
                                                    </div>
                                                </td>

                                                <td className='tddivmainbrdr bentsdtffdfsudigbbdffth'>
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleChangeretirementBenifitDetails(ind, e, "currentYear", "deductedAndDepositedCoverd")}
                                                    >
                                                    </div>
                                                </td>

                                                <td className='tddivmainbrdr bentsdtffdfsudigbbdsxth'>
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleChangeretirementBenifitDetails(ind, e, "previousYear", "employeeCoverd")}

                                                    >
                                                    </div>
                                                </td>

                                                <td className='tddivmainbrdr bentsdtffdfsudigbbdfsvnth'>
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleChangeretirementBenifitDetails(ind, e, "previousYear", "workerCoverd")}
                                                    >
                                                    </div>
                                                </td>

                                                <td className='tddivmainbrdr bentsdtffdfsudigbbdfegtth'>
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleChangeretirementBenifitDetails(ind, e, "previousYear", "deductedAndDepositedCoverd")}
                                                    >
                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    })
                                }

                            </table>

                            <div className='adddinciondivimg'>
                                <img src={AddIcon} alt='...' onClick={addretirementBenifitDetails} />
                                <span>Add More</span>
                            </div>

                            <div className=''>
                                <p class="noiriittxoppdjoyyypp mb-2">Brief Description :</p>
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            retirementBenifitDescription: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Are the premises / offices of the entity accessible to differently abled employees and
                                workers, as per the requirements of the Rights of Persons with Disabilities Act, 2016?
                                <span> If not </span>,  whether any steps are being taken by the entity in this regard
                            </li>

                            <div className=''>
                                <div className=''>
                                    <div className='yesnobtndivmain'>
                                        <div className='raditrbtntxt'>
                                            <input
                                                type="radio"
                                                id="" name=""
                                                value="Yes"
                                                checked={formvalue?.officeAccessbiltyForPWD?.status === "Yes"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        officeAccessbiltyForPWD: {
                                                            ...formvalue.officeAccessbiltyForPWD,
                                                            status: "Yes"
                                                        }
                                                    });
                                                }}
                                            />
                                            <label htmlFor="">Yes</label>
                                        </div>

                                        <div className='raditrbtntxt'>
                                            <input type="radio" id="" name=""
                                                value="No"
                                                checked={formvalue?.officeAccessbiltyForPWD?.status === "No"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        officeAccessbiltyForPWD: {
                                                            ...formvalue.officeAccessbiltyForPWD,
                                                            status: "No"
                                                        }
                                                    });
                                                }}
                                            />
                                            <label htmlFor="">No</label>
                                        </div>
                                    </div>
                                    <div className='yestrxtwarea '>
                                        <p className='dtlsttfvdvgppphjp mb-2'>Details :</p>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('officeAccessbiltyForPWD', 'details', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Does the entity have an equal opportunity policy as per the Rights of Persons with
                                Disabilities Act, 2016? <span> If so
                                </span>, provide a web-link to the policy.
                            </li>

                            <div className=''>
                                <div className=''>
                                    <div className='yesnobtndivmain'>
                                        <div className='raditrbtntxt'>
                                            <input type="radio" id="" name=""
                                                value="Yes"
                                                checked={formvalue?.equalOpportunityPolicyDetails?.status === "Yes"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        equalOpportunityPolicyDetails: {
                                                            ...formvalue.equalOpportunityPolicyDetails,
                                                            status: "Yes"
                                                        }
                                                    });
                                                }}
                                            />
                                            <label htmlFor="">Yes</label>
                                        </div>

                                        <div className='raditrbtntxt'>
                                            <input type="radio" id="" name=""
                                                value="No"
                                                checked={formvalue?.equalOpportunityPolicyDetails?.status === "No"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        equalOpportunityPolicyDetails: {
                                                            ...formvalue.equalOpportunityPolicyDetails,
                                                            status: "No"
                                                        }
                                                    });
                                                }}
                                            />
                                            <label htmlFor="">No</label>
                                        </div>
                                    </div>

                                    <div className='yestrxtwarea'>
                                        <p className='dtlsttfvdvgppphjp mb-2'>Details :</p>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('equalOpportunityPolicyDetails', 'details', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Return to work and Retention rates of permanent employees and workers that took
                                parental leave
                            </li>

                            <table className='tablbrdrmain'>
                                <tr className='trdivmainbrdr'>
                                    <th className=''>

                                    </th>
                                    <th colspan="2" className='thdivmainbrdr'>
                                        <p className='prcnttxttemplytxtpp'> Permanent employees</p>
                                    </th>
                                    <th colspan="2" className='thdivmainbrdr' >
                                        <p className='prcnttxttemplytxtpp'>Permanent workers</p>
                                    </th>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <th className='thdivmainbrdr gennjdhhjhbdtdivfrst'>
                                        <p className='prcnttxttemplytxtpp'>
                                            Gender
                                        </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr gennjdhhjhbdtdivscnd'>
                                        <p className='prcnttxttemplytxtpp'>
                                            Return to work
                                            rate </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr gennjdhhjhbdtdivthrddr' >
                                        <p className='prcnttxttemplytxtpp'>
                                            Retention rate
                                        </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr  gennjdhhjhbdtdivfrth'>
                                        <p className='prcnttxttemplytxtpp'>
                                            Return to work
                                            rate
                                        </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr gennjdhhjhbdtdivffth' >
                                        <p className=''>
                                            Retention rate</p>

                                    </th>


                                </tr>


                                <tr>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>
                                            Male
                                        </p>

                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentEmployees', 'male', 'returnToWorkRate')}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentEmployees', 'male', 'retentionRate')}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentWorkers', 'male', 'returnToWorkRate')}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentWorkers', 'male', 'retentionRate')}

                                        >
                                        </div>
                                    </td>


                                </tr>

                                <tr>
                                    <td className='tddivmainbrdr'>
                                        <p className='Male'>
                                            Female
                                        </p>

                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentEmployees', 'female', 'returnToWorkRate')}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentEmployees', 'female', 'retentionRate')}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentWorkers', 'female', 'returnToWorkRate')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentWorkers', 'female', 'retentionRate')}
                                        >
                                        </div>
                                    </td>

                                </tr>

                                <tr>
                                    <td className='tddivmainbrdr'>
                                        <p className='Male'>
                                            others
                                        </p>

                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentEmployees', 'others', 'returnToWorkRate')}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentEmployees', 'others', 'retentionRate')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentWorkers', 'others', 'returnToWorkRate')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentWorkers', 'others', 'retentionRate')}
                                        >
                                        </div>
                                    </td>

                                </tr>

                                <tr>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtppttlyd'>
                                            Total</p>

                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentEmployees', 'total', 'returnToWorkRate')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentEmployees', 'total', 'retentionRate')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentWorkers', 'total', 'returnToWorkRate')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'permanentWorkers', 'total', 'retentionRate')}
                                        >
                                        </div>
                                    </td>

                                </tr>




                            </table>

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Is there a mechanism available to receive and redress grievances for the following
                                categories of employees and worker? If yes, give details of the mechanism in brief.
                            </li>

                            <div className=''>
                                <div className=''>
                                    <div className='yesnobtndivmain'>
                                        <div className='raditrbtntxt'>
                                            <input type="radio" id="" name=""
                                                value="Yes"
                                                checked={formvalue?.mechanisamDetails?.isAvailable === "Yes"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        mechanisamDetails: {
                                                            ...formvalue.mechanisamDetails,
                                                            isAvailable: "Yes"
                                                        }
                                                    });
                                                }}
                                            />
                                            <label htmlFor="">Yes</label>
                                        </div>

                                        <div className='raditrbtntxt'>
                                            <input type="radio" id="" name=""
                                                value="No"
                                                checked={formvalue?.mechanisamDetails?.isAvailable === "No"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        mechanisamDetails: {
                                                            ...formvalue.mechanisamDetails,
                                                            isAvailable: "No"
                                                        }
                                                    });
                                                }}
                                            />
                                            <label htmlFor="">No</label>
                                        </div>

                                    </div>

                                    <table className='tablbrdrmain'>
                                        <tr className='trdivmainbrdr '>

                                            <th className='thdivmainbrdr wokekjkekjdnnbjansupyudggdivfrst'>

                                            </th>
                                            <th className='thdivmainbrdr wokekjkekjdnnbjansupyudggdivscnd' >
                                                <p className='Permanent Workers'>
                                                    Yes/No
                                                    (If Yes, then give details of the
                                                    mechanism in brief)</p>
                                            </th>

                                        </tr>
                                        <tr>
                                            <td className='tddivmainbrdr '>
                                                <p className='Malettsgvsgphsmkjghhutxtpp'>Permanent Workers </p>
                                            </td>

                                            <td className='tddivmainbrdr'>
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'mechanisamDetails', 'briefDetailsOfMechanism', 'permanentAddress')}
                                                >
                                                </div>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className='tddivmainbrdr'>
                                                <p className='Malettsgvsgphsmkjghhutxtpp'></p>Other than Permanent Workers
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'mechanisamDetails', 'briefDetailsOfMechanism', 'otherThanPermanentAddress')}
                                                >
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='tddivmainbrdr'>
                                                <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                    Permanent Employees
                                                </p>

                                            </td>
                                            <td className='tddivmainbrdr'>
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'mechanisamDetails', 'briefDetailsOfMechanism', 'permanentEmployees')}
                                                >
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className='tddivmainbrdr'>
                                                <p className='Malettsgvsgphsmkjghhutxtpp'>
                                                    Other than Permanent Employees
                                                </p>
                                            </td>
                                            <td className='tddivmainbrdr'>
                                                <div
                                                    ref={divRef}
                                                    contentEditable
                                                    className='textardibbrdrdivmain'
                                                    onInput={(e) => handleInputChange(e, 'mechanisamDetails', 'briefDetailsOfMechanism', 'otherThanPermannentEmployees')}
                                                >
                                                </div>
                                            </td>


                                        </tr>

                                    </table>


                                    <div className='rggsgttxbbedxgtsprijnd'>
                                        <p class="noiriittxoppdjoyyypp">Brief :</p>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('mechanisamDetails', 'note', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Membership of employees and worker in association(s) or Unions recognised by the
                                listed entity:

                            </li>

                            <table className='tablbrdrmain'>
                                <tr className='trdivmainbrdr'>
                                    <th className='thdivmainbrdrnewtwo'>
                                        {/* <p className='cttsggrttxtpppc'>
                                            Category</p> */}
                                    </th>
                                    <th colspan="3" className='thdivmainbrdr'>
                                        <p className='prcnttxttemplytxtpp'>
                                            FY {currentFY} (Current Financial
                                            Year)
                                        </p>
                                    </th>
                                    <th colspan="3" className='thdivmainbrdr' >
                                        <p className='prcnttxttemplytxtpp'>
                                            FY {previousFY} (Previous Financial Year)
                                        </p>
                                    </th>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <th className='categiidggdhgbvdibfrst'>
                                        <p className='cttsggrttxtpppc '>
                                            Category</p>
                                    </th>

                                    <th colspan="1" className='thdivmainbrdr categiidggdhgbvdibscnd'>
                                        <p className='  mnumbbrttprinvthreetxttppp'>
                                            Total
                                            employees /
                                            workers in
                                            respective
                                            category
                                            (A)
                                        </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr categiidggdhgbvdibthrd'>
                                        <p className='  mnumbbrttprinvthreetxttppp'>
                                            No. of employees /
                                            workers in
                                            respective
                                            category, who are
                                            part of
                                            association(s) or
                                            Union
                                            (B)</p>


                                    </th>
                                    <th colspan="1" className='thdivmainbrdr categiidggdhgbvdibfrth'>
                                        <p className='  mnumbbrttprinvthreetxttppp'>
                                            % (B / A)
                                        </p>

                                    </th>
                                    <th colspan="1" className='thdivmainbrdr categiidggdhgbvdibffth'>
                                        <p className='  mnumbbrttprinvthreetxttppp'>
                                            Total
                                            employees
                                            / workers
                                            in
                                            respective
                                            category
                                            (C) </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr categiidggdhgbvdibscxth'>
                                        <p className='  mnumbbrttprinvthreetxttppp'>No. of
                                            employees /
                                            workers in
                                            respective
                                            category, who
                                            are part of
                                            association(s)
                                            or Union
                                            (D) </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr categiidggdhgbvdibsvnth'>
                                        <p className='  mnumbbrttprinvthreetxttppp'>  % (D /
                                            C)
                                        </p>
                                    </th>

                                </tr>

                                <tr>
                                    <th className='thdivmainbrdr'>
                                        <p className=' Malettsgvsgphsmkjghhutxtppttlyd'>
                                            Total
                                            Permanent
                                            Employees

                                        </p>
                                    </th>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pe_total', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pe_total', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pe_total', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pe_total', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pe_total', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pe_total', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>

                                <tr>
                                    <td className='tddivmainbrdr'>

                                        <p className='Malettsgvsgphsmkjghhutxtpp'>
                                            Male
                                        </p>

                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pe_male', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pe_male', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pe_male', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pe_male', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pe_male', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pe_male', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>
                                            Female
                                        </p>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pe_female', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pe_female', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pe_female', 'percentage', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pe_female', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pe_female', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pe_female', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>
                                            Others
                                        </p>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pe_others', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pe_others', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pe_others', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pe_others', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pe_others', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pe_others', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <th className='thdivmainbrdr'>
                                        <p className=' Malettsgvsgphsmkjghhutxtppttlyd'>
                                            Total
                                            Permanent
                                            Workers

                                        </p>
                                    </th>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pw_total', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pw_total', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pw_total', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pw_total', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pw_total', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pw_total', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>

                                <tr>
                                    <td className='tddivmainbrdr'>

                                        <p className='Malettsgvsgphsmkjghhutxtpp'>
                                            Male
                                        </p>

                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pw_male', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pw_male', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pw_male', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pw_male', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pw_male', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pw_male', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>
                                            Female
                                        </p>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pw_female', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pw_female', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pw_female', 'percentage', e.currentTarget.textContent)}

                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pw_female', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pw_female', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pw_female', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>
                                            Others
                                        </p>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pw_others', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pw_others', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_pw_others', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pw_others', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pw_others', 'associationParticipators', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_pw_others', 'percentage', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <div className=''>
                                <p className='dtlsttfvdvgppphjp mt-2 mb-2'>Impression :</p>
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            impression: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>


                            <div className='jhgsgjgiuhkhdjjdpojajklenm mb-2'>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdrnewtwo'>
                                            {/* <p className='cttsggrttxtpppc'>
                                            Category</p> */}
                                        </th>
                                        <th colspan="3" className='thdivmainbrdr'>
                                            <p className='prcnttxttemplytxtpp'>
                                                FY {currentFY} (Current Financial
                                                Year)
                                            </p>
                                        </th>
                                        <th colspan="3" className='thdivmainbrdr' >
                                            <p className='prcnttxttemplytxtpp'>
                                                FY {previousFY} (Previous Financial Year)
                                            </p>
                                        </th>
                                    </tr>

                                    <tr className='trdivmainbrdr'>
                                        <th className='categiidggdhgbvdibfrst'>
                                            <p className='cttsggrttxtpppc '>
                                                Category</p>
                                        </th>

                                        <th colspan="1" className='thdivmainbrdr categiidggdhgbvdibscnd'>
                                            <p className='  mnumbbrttprinvthreetxttppp'>
                                                Total
                                                employees /
                                                workers in
                                                respective
                                                category
                                                (A)
                                            </p>
                                        </th>
                                        <th colspan="1" className='thdivmainbrdr categiidggdhgbvdibthrd'>
                                            <p className='  mnumbbrttprinvthreetxttppp'>
                                                No. of employees /
                                                workers in
                                                respective
                                                category, who are
                                                part of
                                                association(s) or
                                                Union
                                                (B)</p>


                                        </th>
                                        <th colspan="1" className='thdivmainbrdr categiidggdhgbvdibfrth'>
                                            <p className='  mnumbbrttprinvthreetxttppp'>
                                                % (B / A)
                                            </p>

                                        </th>
                                        <th colspan="1" className='thdivmainbrdr categiidggdhgbvdibffth'>
                                            <p className='  mnumbbrttprinvthreetxttppp'>
                                                Total
                                                employees
                                                / workers
                                                in
                                                respective
                                                category
                                                (C) </p>
                                        </th>
                                        <th colspan="1" className='thdivmainbrdr categiidggdhgbvdibscxth'>
                                            <p className='  mnumbbrttprinvthreetxttppp'>No. of
                                                employees /
                                                workers in
                                                respective
                                                category, who
                                                are part of
                                                association(s)
                                                or Union
                                                (D) </p>
                                        </th>
                                        <th colspan="1" className='thdivmainbrdr categiidggdhgbvdibsvnth'>
                                            <p className='  mnumbbrttprinvthreetxttppp'>  % (D /
                                                C)
                                            </p>
                                        </th>

                                    </tr>

                                    <tr>
                                        <th className='thdivmainbrdr'>
                                            <p className=' Malettsgvsgphsmkjghhutxtppttlyd'>
                                                Total
                                                Permanent
                                                Employees

                                            </p>
                                        </th>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_totalPermanentEmployees', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_totalPermanentEmployees', 'associationParticipators', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_totalPermanentEmployees', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_totalPermanentEmployees', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_totalPermanentEmployees', 'associationParticipators', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_totalPermanentEmployees', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <th className='thdivmainbrdr'>
                                            <p className=' Malettsgvsgphsmkjghhutxtppttlyd'>
                                                Total
                                                Permanent
                                                Workers
                                            </p>

                                        </th>

                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_totalPermanentWorkers', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_totalPermanentWorkers', 'associationParticipators', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('c_totalPermanentWorkers', 'percentage', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_totalPermanentWorkers', 'total', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_totalPermanentWorkers', 'associationParticipators', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('p_totalPermanentWorkers', 'percentage', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </td>
                                    </tr>

                                </table>
                            </div>

                            <div>
                                <p className='notetgvxtygfspopphjhsttxp ' style={{ cursor: "pointer" }} onClick={() => setnotemodal2(!notemodal2)}>Note </p>
                                {notemodal2 && <div className="yestrxtwarea mt-2 mb-0">

                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                note: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>}
                            </div>

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Details of training given to employees and workers:
                            </li>
                            <table className='tablbrdrmain'>
                                <tr className='trdivmainbrdr'>
                                    <th className='thdivmainbrdrnewtwo'>

                                    </th>
                                    <th colspan="5" className='thdivmainbrdr'>
                                        <p className='prcnttxttemplytxtpp'>
                                            FY {currentFY}
                                            (Current Financial
                                            Year)
                                        </p>
                                    </th>
                                    <th colspan="5" className='thdivmainbrdr' >
                                        <p className='prcnttxttemplytxtpp'>FY {previousFY}
                                            (Previous Financial Year)
                                        </p>
                                    </th>
                                </tr>
                                <tr className='trdivmainbrdr'>
                                    <th className='categjhggsprinciplesvndvfrst' >
                                        <p className='cttsggrttxtpppc '>
                                            Category
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr categjhggsprinciplesvndvscnd' >
                                        <p className='  mnumbbrttprinvthreetxttppp'>
                                            Total
                                            (A)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr categjhggsprinciplesvndvtrhrd' colSpan="2">
                                        <p className='  mnumbbrttprinvthreetxttppp'>On Health
                                            and safety
                                            measures
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr categjhggsprinciplesvndvfrth' colSpan="2"  >
                                        <p className='  mnumbbrttprinvthreetxttppp'>On Skill
                                            upgradation
                                        </p>

                                    </th>
                                    <th className='thdivmainbrdr categjhggsprinciplesvndvffth' >
                                        <p className='  mnumbbrttprinvthreetxttppp'>Total
                                            (D)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr categjhggsprinciplesvndvsxth' colSpan="2">
                                        <p className='  mnumbbrttprinvthreetxttppp'>On Health
                                            and safety
                                            measures
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr categjhggsprinciplesvndvsvnth' colSpan="2"  >
                                        <p className='  mnumbbrttprinvthreetxttppp'>
                                            On Skill
                                            upgradation
                                        </p>

                                    </th>

                                </tr>
                                <tr className='trdivmainbrdr'>
                                    <th className='' >
                                    </th>
                                    <th className='thdivmainbrdr' >
                                    </th>
                                    <th className='thdivmainbrdr categjhggsprinciplesvndvegth'>

                                        <p className='  mnumbbrttprinvthreetxttppp'> No.
                                            (B)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr categjhggsprinciplesvndvnnnth'>

                                        <p className='  mnumbbrttprinvthreetxttppp'>% (B
                                            / A)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr categjhggsprinciplesvndvntenth' >

                                        <p className='  mnumbbrttprinvthreetxttppp'>No.
                                            (C)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr categjhggsprinciplesvndvnelevnth'>

                                        <p className='  mnumbbrttprinvthreetxttppp'>% (C /
                                            A)
                                        </p>

                                    </th>
                                    <th className='thdivmainbrdr'  >
                                    </th>
                                    <th className='thdivmainbrdr categjhggsprinciplesvndvnelthrtn'>

                                        <p className='  mnumbbrttprinvthreetxttppp '>No.
                                            (E)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr categjhggsprinciplesvndvnelfrteen'>

                                        <p className='  mnumbbrttprinvthreetxttppp'>%(E/D)</p>
                                    </th>
                                    <th className='thdivmainbrdr categjhggsprinciplesvndvnelffteen'>

                                        <p className='  mnumbbrttprinvthreetxttppp '>No.
                                            (F)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr categjhggsprinciplesvndvnelsixtin'>

                                        <p className='  mnumbbrttprinvthreetxttppp'>% (F / D)</p>
                                    </th>

                                </tr>

                                <tr className='gap'>
                                    <th className='thdivmainbrdr' colSpan="12"> Permanent Employess</th>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr' >Male

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    c_e_m_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_m_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_m_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_m_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_m_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    p_e_m_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            onInput={(e) => handleInput('p_e_m_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                            className='textardibbrdrdivmain'>
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_e_m_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_e_m_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_e_m_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>


                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr' >Female

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    c_e_f_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_f_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_f_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_f_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_f_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    p_e_f_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            onInput={(e) => handleInput('p_e_f_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                            className='textardibbrdrdivmain'>
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_e_f_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_e_f_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_e_f_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>


                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr' >Others

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    c_e_o_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_o_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_o_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_o_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_o_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    p_e_o_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            onInput={(e) => handleInput('p_e_o_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                            className='textardibbrdrdivmain'>
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_e_o_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_e_o_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_e_o_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>


                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr' >Total

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    c_e_t_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_t_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_t_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_t_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_e_t_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    p_e_t_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            onInput={(e) => handleInput('p_e_t_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                            className='textardibbrdrdivmain'>
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_e_t_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_e_t_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_e_t_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>


                                <tr className='gap'>
                                    <th className='thdivmainbrdr' colSpan="12">
                                        Permanent Worker
                                    </th>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr' >Male

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    c_w_m_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_m_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_m_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_m_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_m_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    p_w_m_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            onInput={(e) => handleInput('p_w_m_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                            className='textardibbrdrdivmain'>
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_w_m_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_w_m_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_w_m_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>


                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr' >Female

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    c_w_f_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_f_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_f_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_f_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_f_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    p_w_f_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            onInput={(e) => handleInput('p_w_f_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                            className='textardibbrdrdivmain'>
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_w_f_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_w_f_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_w_f_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>


                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr' >Others

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    c_w_o_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_o_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_o_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_o_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_o_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    p_w_o_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            onInput={(e) => handleInput('p_w_o_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                            className='textardibbrdrdivmain'>
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_w_o_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_w_o_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_w_o_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>


                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr' >Total

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    c_w_t_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_t_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_t_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_t_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('c_w_t_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    p_w_t_total: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            onInput={(e) => handleInput('p_w_t_healthyAndSafetyMesure', 'unit', e.currentTarget.textContent)}
                                            className='textardibbrdrdivmain'>
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_w_t_healthyAndSafetyMesure', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_w_t_skillUpgradatioin', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('p_w_t_skillUpgradatioin', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>

                            </table>
                            <div className='rggsgttxbbedxgtsprijnd'>
                                <p class="noiriittxoppdjoyyypp mt-2 mb-2">Details :</p>
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            secondnote: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>
                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Details of performance and career development reviews of employees and worker:
                            </li>
                            <table className='tablbrdrmain'>
                                <tr className='trdivmainbrdr'>
                                    <th className='thdivmainbrdrnewtwo categrypeinsipletwodivfrst'>
                                        <p className='cttsggrttxtpppc '>
                                            Category
                                        </p>
                                    </th>
                                    <th colspan="3" className='thdivmainbrdr categrypeinsipletwodivscnd'>
                                        <p className='prcnttxttemplytxtpp'>
                                            FY {currentFY} (Current Financial
                                            Year)
                                        </p>
                                    </th>
                                    <th colspan="3" className='thdivmainbrdr categrypeinsipletwodivthrd' >
                                        <p className='prcnttxttemplytxtpp'>
                                            FY {previousFY} (Previous Financial Year)</p>
                                    </th>
                                </tr>
                                <tr className='trdivmainbrdr'>
                                    <th className='' >
                                    </th>
                                    <th className='thdivmainbrdr categrypeinsipletwodivtfrth' >
                                        <p className='totyauttxttppttxpa'>
                                            Total
                                            (A)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr categrypeinsipletwodivtffth'>
                                        <p className='totyauttxttppttxpa'>No. (B)</p>
                                    </th>
                                    <th className='thdivmainbrdr categrypeinsipletwodivtsixth'>
                                        <p className='totyauttxttppttxpa'>
                                            % (B / A)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr categrypeinsipletwodivtegth'>
                                        <p className='totyauttxttppttxpa'>
                                            Total
                                            (C)
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr categrypeinsipletwodivtnnth'> <p className='totyauttxttppttxpa'> No. (D)</p>
                                    </th>
                                    <th className='thdivmainbrdr categrypeinsipletwodivttinnth'>  <p className='totyauttxttppttxpa'>
                                        % (D / C)
                                    </p>

                                    </th>

                                </tr>


                                <tr className='gap'>
                                    <th className='thdivmainbrdr' colSpan="12"> Permanent Employees</th>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Male</p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_e_male', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_e_male', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_e_male', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_e_male', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_e_male', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_e_male', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>Female</p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_e_female', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_e_female', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_e_female', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_e_female', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_e_female', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_e_female', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>Others</p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_e_others', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_e_others', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_e_others', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_e_others', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_e_others', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_e_others', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>Total</p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_e_total', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_e_total', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_e_total', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_e_total', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_e_total', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_e_total', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>

                                <tr className='gap'>
                                    <th className='thdivmainbrdr' colSpan="12">
                                        Permanent Workers
                                    </th>
                                </tr>


                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'> Male</p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_w_male', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_w_male', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_w_male', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_w_male', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_w_male', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_w_male', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>Female</p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_w_female', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_w_female', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_w_female', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_w_female', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_w_female', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_w_female', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>Others</p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_w_others', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_w_others', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_w_others', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_w_others', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_w_others', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_w_others', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>Total</p>

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_w_total', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_w_total', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_c_w_total', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_w_total', 'total', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_w_total', 'unit', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('d_p_w_total', 'percent', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>

                            </table>

                            <div className='rggsgttxbbedxgtsprijnd'>
                                <p class="noiriittxoppdjoyyypp mt-2 mb-2">Details :</p>
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            details: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>
                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Health and safety management system:
                            </li>
                            <ul className='healthysftydivul'>
                                <li className='healthysftydivli'>
                                    Whether an occupational health and safety management system has been
                                    implemented by the entity? <span>

                                        (Yes/ No)
                                    </span> If yes, the coverage such system?

                                    <div className='yesnobtndivmain'>
                                        <div className="raditrbtntxtnemko"><input
                                            type="radio"
                                            id name
                                            value={"Yes"}
                                            checked={formvalue?.healthAndSafetyManagementStatus?.isImplement === "Yes"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    healthAndSafetyManagementStatus: {
                                                        ...formvalue.healthAndSafetyManagementStatus,
                                                        isImplement: "Yes"
                                                    }
                                                });
                                            }}
                                        />
                                            <label htmlFor>Yes</label></div>
                                        <div className="raditrbtntxtnemko"><input type="radio"
                                            id name
                                            value={"No"}
                                            checked={formvalue?.healthAndSafetyManagementStatus?.isImplement === "No"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    healthAndSafetyManagementStatus: {
                                                        ...formvalue.healthAndSafetyManagementStatus,
                                                        isImplement: "No"
                                                    }
                                                });
                                            }}
                                        /><label htmlFor>No</label></div>
                                    </div>
                                    <div className="yestrxtwarea">
                                        <p class="dtlsttfvdvgppphjp mt-2 mb-2">Describe :</p>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('healthAndSafetyManagementStatus', 'details', e.currentTarget.textContent)}
                                        >
                                        </div>


                                    </div>
                                </li>

                                <li className='healthysftydivli'>
                                    What are the processes used to identify work-related hazards and assess risks on a
                                    routine and non-routine basis by the entity?


                                    <div className="yestrxtwarea">
                                        <p class="dtlsttfvdvgppphjp mt-2 mb-2">Describe :</p>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    usedProcessDetails: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </div>
                                </li>

                                <li className='healthysftydivli'>
                                    Whether you have processes for workers to report the work related hazards and to
                                    remove themselves from such risks. <span>
                                        (Y/N)
                                    </span>
                                    <div className='yesnobtndivmain'>
                                        <div className="raditrbtntxtnemko"><input type="radio" id name
                                            value={"Yes"}
                                            checked={formvalue?.workRelatedReport?.status === "Yes"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    workRelatedReport: {
                                                        ...formvalue.workRelatedReport,
                                                        status: "Yes"
                                                    }
                                                });
                                            }}
                                        /><label htmlFor>Yes</label></div>
                                        <div className="raditrbtntxtnemko"><input type="radio" id name
                                            value={"No"}
                                            checked={formvalue?.workRelatedReport?.status === "No"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    workRelatedReport: {
                                                        ...formvalue.workRelatedReport,
                                                        status: "No"
                                                    }
                                                });
                                            }}
                                        /><label htmlFor>No</label></div>
                                    </div>
                                    <div className="yestrxtwarea">
                                        <p class="dtlsttfvdvgppphjp mt-2 mb-2">Describe :</p>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('workRelatedReport', 'details', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </div>
                                </li>

                                <li className='healthysftydivli'>
                                    Do the employees/ worker of the entity have access to non-occupational medical
                                    and healthcare services?
                                    <span>  (Yes/ No)
                                    </span>

                                    <div className='yesnobtndivmain'>
                                        <div className="raditrbtntxtnemko"><input type="radio" id name
                                            value={"Yes"}
                                            checked={formvalue?.nonOccupationalDetails?.status === "Yes"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    nonOccupationalDetails: {
                                                        ...formvalue.nonOccupationalDetails,
                                                        status: "Yes"
                                                    }
                                                });
                                            }}
                                        /><label htmlFor>Yes</label></div>
                                        <div className="raditrbtntxtnemko"><input type="radio" id name
                                            value={"No"}
                                            checked={formvalue?.nonOccupationalDetails?.status === "No"}
                                            onChange={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    nonOccupationalDetails: {
                                                        ...formvalue.nonOccupationalDetails,
                                                        status: "No"
                                                    }
                                                });
                                            }}
                                        /><label htmlFor>No</label></div>
                                    </div>
                                    <div className="yestrxtwarea">
                                        <p class="dtlsttfvdvgppphjp mt-2 mb-2">Describe :</p>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('nonOccupationalDetails', 'details', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </div>
                                </li>


                            </ul>
                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Details of safety related incidents, in the following format:
                            </li>

                            <table className='tablbrdrmain'>
                                <tr className='trdivmainbrdr'>
                                    <th className='thdivmainbrdr safetysoimnjdivhbhfvfsrt'>
                                        <p className='prcnttxttemplytxtpp '>
                                            Safety Incident/Number
                                        </p>
                                    </th>
                                    <th className='thdivmainbrdr safetysoimnjdivhbhfvscnd'>
                                        <p className='prcnttxttemplytxtpp '>
                                            Category*</p>
                                    </th>
                                    <th className='thdivmainbrdr safetysoimnjdivhbhfvthrd'>
                                        <p className='prcnttxttemplytxtpp '>
                                            FY {currentFY} (Current Financial
                                            Year)</p>
                                    </th>
                                    <th className='thdivmainbrdr safetysoimnjdivhbhfvffrth' >
                                        <p className='prcnttxttemplytxtpp '>FY {previousFY}
                                            (Previous Financial Year)
                                        </p>

                                    </th>

                                </tr>
                                <tr>
                                    <td className='tddivmainbrdrspclnewone' rowSpan="2">
                                        Lost Time Injury Frequency Rate
                                        (LTIFR) (per one million-person
                                        hours worked)

                                    </td>
                                    <td className='tddivmainbrdr' >
                                        Employees
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'LIIFR', 'employees', 'currentYear')}
                                        >
                                        </div>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'LIIFR', 'employees', 'previousYear')}
                                        >
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td className='tddivmainbrdr' >
                                        Workers

                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'LIIFR', 'workers', 'currentYear')}
                                        >
                                        </div>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'LIIFR', 'workers', 'previousYear')}
                                        >
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className='tddivmainbrdrspclnewone' rowSpan="2">
                                        Total recordable work-related
                                        injuries
                                    </th>
                                    <td className='tddivmainbrdr' >
                                        Employees

                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'totalRecordableInjuries', 'employees', 'currentYear')}
                                        >
                                        </div>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'totalRecordableInjuries', 'employees', 'previousYear')}
                                        >
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td className='tddivmainbrdr' >
                                        Workers
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'totalRecordableInjuries', 'workers', 'currentYear')}
                                        >
                                        </div>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'totalRecordableInjuries', 'workers', 'previousYear')}
                                        >
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className='tddivmainbrdrspclnewone' rowSpan="2">
                                        No. of fatalities

                                    </th>
                                    <td className='tddivmainbrdr' >
                                        Employees

                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'noOfFatalities', 'employees', 'currentYear')}
                                        >
                                        </div>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'noOfFatalities', 'employees', 'previousYear')}
                                        >
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td className='tddivmainbrdr' >
                                        Workers

                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'noOfFatalities', 'workers', 'currentYear')}
                                        >
                                        </div>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'noOfFatalities', 'workers', 'previousYear')}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <th className='tddivmainbrdrspclnewone' rowSpan="2">
                                        High consequence work-related
                                        injury or ill-health (excluding
                                        fatalities)s
                                    </th>
                                    <td className='tddivmainbrdr' >
                                        Employees

                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'highConsequenceInjuries', 'employees', 'currentYear')}
                                        >
                                        </div>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'highConsequenceInjuries', 'employees', 'previousYear')}
                                        >
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td className='tddivmainbrdr' >
                                        Workers

                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'highConsequenceInjuries', 'workers', 'currentYear')}
                                        >
                                        </div>
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'highConsequenceInjuries', 'workers', 'previousYear')}
                                        >
                                        </div>
                                    </td>
                                </tr>


                            </table>
                            <div className='notedivadmoredivflx'>
                                <div className=''>
                                    <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal5(!notemodal5)}>Note  </p>
                                </div>
                            </div>
                            {notemodal5 && <div className="yestrxtwarea mt-2 mb-0">

                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            secondnote: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>}

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Describe the measures taken by the entity to ensure a safe and healthy work place.
                            </li>
                            <div className='yestrxtwarea'>
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            workPlaceMesureDetails: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>
                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Number of Complaints on the following made by employees and workers:
                            </li>

                            <table className='tablbrdrmain'>
                                <tr className='trdivmainbrdr'>
                                    <th className=''>

                                    </th>
                                    <th colspan="3" className='thdivmainbrdr'>
                                        <p className='prcnttxttemplytxtpp '> FY {currentFY}
                                            (Current Financial Year)
                                        </p>
                                    </th>
                                    <th colspan="3" className='thdivmainbrdr'>
                                        <p className='prcnttxttemplytxtpp '>FY {previousFY}
                                            (Previous Financial Year)
                                        </p>
                                    </th>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <th className='filedduringggsdivvvbsfrstataspcl'>

                                    </th>

                                    <th colspan="1" className='thdivmainbrdr '>
                                        <p className='totyauttxttppttxpa'>
                                            Filed during
                                            the year
                                        </p>

                                    </th>
                                    <th colspan="1" className='thdivmainbrdr filedduringggsdivvvbsscnd' >
                                        <p className='totyauttxttppttxpa'>
                                            Pending
                                            resolution
                                            at the end
                                            of year
                                        </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr filedduringggsdivvvbsthrd'>
                                        <p className='totyauttxttppttxpa'>Remarks </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr filedduringggsdivvvbsfrth '>
                                        <p className='totyauttxttppttxpa '>
                                            Filed during
                                            the year

                                        </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr filedduringggsdivvvbsffth' >
                                        <p className='totyauttxttppttxpa '> Pending
                                            resolution
                                            at the end
                                            of year</p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr filedduringggsdivvvbssxth'>
                                        <p className='totyauttxttppttxpa'>
                                            Remarks </p>
                                    </th>


                                </tr>


                                <tr>
                                    <td className='tddivmainbrdr'>
                                        Working
                                        Conditions

                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'currentYear', 'workingCondition', 'duringTheYear')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'currentYear', 'workingCondition', 'endOfTHeYear')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'currentYear', 'workingCondition', 'remarks')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'previousYear', 'workingCondition', 'duringTheYear')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'previousYear', 'workingCondition', 'endOfTHeYear')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'previousYear', 'workingCondition', 'remarks')}
                                        >
                                        </div>
                                    </td>

                                </tr>

                                <tr>
                                    <td className='tddivmainbrdr'>

                                        Health &
                                        Safety
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'currentYear', 'healthAndSafety', 'duringTheYear')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'currentYear', 'healthAndSafety', 'endOfTHeYear')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'currentYear', 'healthAndSafety', 'remarks')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'previousYear', 'healthAndSafety', 'duringTheYear')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'previousYear', 'healthAndSafety', 'endOfTHeYear')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'previousYear', 'healthAndSafety', 'remarks')}
                                        >
                                        </div>
                                    </td>

                                </tr>
                            </table>

                            {/* <div className='adddinciondivimg'>
                                <img src={AddIcon} alt='...' />
                            </div> */}
                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Assessments for the year:
                            </li>
                            <table className='tablbrdrmain'>
                                <tr className='trdivmainbrdr'>

                                    <th className='thdivmainbrdr plantsdgtdcyrmmnbbvdivfrst'>

                                    </th>
                                    <th className='thdivmainbrdr plantsdgtdcyrmmnbbvdivscnd' >
                                        <p className='prcnttxttemplytxtpp '>
                                            % of your plants and offices that were assessed
                                            (by entity or statutory authorities orthird parties)
                                        </p>
                                    </th>

                                </tr>
                                <tr>
                                    <td className='tddivmainbrdr'>
                                        Health and safety practices
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('assessmentsForTheYear', 'healthAndSafetyPractice', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td className='tddivmainbrdr'>Working Conditions

                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('assessmentsForTheYear', 'workingCondition', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>

                            </table>
                            <div className='notedivadmoredivflx'>
                                <div className=''>
                                    <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal6(!notemodal6)}>Note  </p>
                                </div>
                            </div>
                            {notemodal6 && <div className="yestrxtwarea mt-2 mb-0">

                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    // onInput={(e) => {
                                    //     setformvalue({
                                    //         ...formvalue,
                                    //         secondnote: e.currentTarget.textContent
                                    //     });
                                    // }}
                                    onInput={(e) => handleInput('assessmentsForTheYear', 'note', e.currentTarget.textContent)}

                                >
                                </div>
                            </div>}

                            {/* <div className='adddinciondivimg'>
                                <img src={AddIcon} alt='...' />
                            </div> */}

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Provide details of any corrective action taken or underway to address safety-related
                                incidents (if any) and on significant risks / concerns arising from assessments of health
                                & safety practices and working conditions
                            </li>
                            <div className="yestrxtwarea">
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            correspondingActionDetails: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>

                        </div>
                    </ul>
                </div>
            </div >

            <div className='borderessentialin'>
                <p className='idicatrstxtpp'>Leadership Indicators</p>
                <div className=''>
                    <ul className='listoftblxsdivul'>
                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Does the entity extend any life insurance or any compensatory package in the event of
                                death of (A) Employees (Y/N) (B) Workers (Y/N).
                            </li>

                            <div className='empliyesworkesdivflxdsjkdivflx'>
                                <div className=''>
                                    <p className='emplkkdjujhtxttjopstxtp'>
                                        Employees
                                    </p>
                                    <div className="yesnobtndivmain">
                                        <div className="raditrbtntxtnemko">
                                            <input type="radio"
                                                value={"Yes"}
                                                checked={formvalue?.extendedLifeInsurance?.employeesStatus === "Yes"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        extendedLifeInsurance: {
                                                            ...formvalue.extendedLifeInsurance,
                                                            employeesStatus: "Yes"
                                                        }
                                                    });
                                                }}
                                            />
                                            <label>Yes</label>
                                        </div>
                                        <div className="raditrbtntxtnemko">
                                            <input type="radio"
                                                value={"No"}
                                                checked={formvalue?.extendedLifeInsurance?.employeesStatus === "No"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        extendedLifeInsurance: {
                                                            ...formvalue.extendedLifeInsurance,
                                                            employeesStatus: "No"
                                                        }
                                                    });
                                                }}
                                            />
                                            <label>No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <p className='emplkkdjujhtxttjopstxtp'>
                                        Workers
                                    </p>
                                    <div className="yesnobtndivmain">
                                        <div className="raditrbtntxtnemko">
                                            <input type="radio"
                                                value={"Yes"}
                                                checked={formvalue?.extendedLifeInsurance?.workersStaus === "Yes"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        extendedLifeInsurance: {
                                                            ...formvalue.extendedLifeInsurance,
                                                            workersStaus: "Yes"
                                                        }
                                                    });
                                                }}
                                            />
                                            <label>Yes</label>
                                        </div>
                                        <div className="raditrbtntxtnemko">
                                            <input type="radio"
                                                value={"No"}
                                                checked={formvalue?.extendedLifeInsurance?.workersStaus === "No"}
                                                onChange={(e) => {
                                                    setformvalue({
                                                        ...formvalue,
                                                        extendedLifeInsurance: {
                                                            ...formvalue.extendedLifeInsurance,
                                                            workersStaus: "No"
                                                        }
                                                    });
                                                }}
                                            />
                                            <label>No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="yestrxtwarea">
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            extendedLifeInsuranceDetails: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Provide the measures undertaken by the entity to ensure that statutory dues have been
                                deducted and deposited by the value chain partners.
                            </li>
                            <div className="yestrxtwarea">
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            measureValueOfChainPartners: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>
                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Provide the number of employees / workers having suffered high consequence <span> work related injury / ill-health / fatalities (as reported in Q11 of Essential Indicators above) </span>,
                                who have been are rehabilitated and placed in suitable employment or whose family
                                members have been placed in suitable employment:

                            </li>

                            <div className='yestrxtwarea mt-3 mb-0' style={{paddingBottom:"30px"}}>
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            describe: e.currentTarget.textContent
                                        });
                                    }}

                                >
                                </div>
                            </div>

                            <table className='tablbrdrmain mb-2'>
                                <tr className='trdivmainbrdr'>
                                    <th className=''>

                                    </th>
                                    <th colspan="2" className='thdivmainbrdr'>
                                        <p className='prcnttxttemplytxtpp '>
                                            Total no. of affected
                                            employees/ workers
                                        </p>

                                    </th>
                                    <th colspan="2" className='thdivmainbrdr'>
                                        <p className='prcnttxttemplytxtpp '>
                                            No. of employees/workers that are
                                            rehabilitated and placed in suitable
                                            employment or whose family
                                            members have been placed in suitable
                                            employment
                                        </p>

                                    </th>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <th className='thdivmainbrdr emplyeeetstdivfrst'></th>
                                    <th colspan="1" className='thdivmainbrdr emplyeeetstdivscnd'>
                                        <p className='totyauttxttppttxpa'>
                                            FY {currentFY}
                                            (Current
                                            Financial
                                            Year)
                                        </p>

                                    </th>
                                    <th colspan="1" className='thdivmainbrdr emplyeeetstdivthrd' >
                                        <p className='totyauttxttppttxpa'>
                                            FY {previousFY}
                                            (Previous
                                            Financial
                                            Year)
                                        </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr emplyeeetstdivfrth'>
                                        <p className='totyauttxttppttxpa'>
                                            FY {currentFY}
                                            (Current Financial
                                            Year)
                                        </p>
                                    </th>
                                    <th colspan="1" className='thdivmainbrdr emplyeeetstdivffth' >
                                        <p className='totyauttxttppttxpa'>
                                            FY {previousFY}
                                            (Previous
                                            Financial Year)
                                        </p>
                                    </th>


                                </tr>


                                <tr>
                                    <td className='tddivmainbrdr '>
                                        Employees
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'employee', 'totalNoOfEffected', 'currentYear')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'employee', 'totalNoOfEffected', 'previousYear')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'employee', 'rehabitedOrPlacedDetails', 'currentYear')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'employee', 'rehabitedOrPlacedDetails', 'previousYear')}
                                        >
                                        </div>
                                    </td>


                                </tr>

                                <tr>
                                    <td className='tddivmainbrdr '>
                                        Workers
                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'worker', 'totalNoOfEffected', 'currentYear')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'worker', 'totalNoOfEffected', 'previousYear')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'worker', 'rehabitedOrPlacedDetails', 'currentYear')}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInputChange(e, 'worker', 'rehabitedOrPlacedDetails', 'previousYear')}
                                        >
                                        </div>
                                    </td>

                                </tr>

                            </table>

                            <div className='extrapagdhijspscvvbdiv'>
                                <p class="notetgvxtygfspopphjhsttxp" style={{ cursor: "pointer" }} onClick={() => setnotemodal7(!notemodal7)}>Note :</p>
                                {notemodal7 &&
                                    <div className='yestrxtwarea mt-3 mb-0'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    p_note: e.currentTarget.textContent
                                                });
                                            }}

                                        >
                                        </div>
                                    </div>}
                            </div>



                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Does the entity provide transition assistance programs to facilitate continued
                                employability and the management of career endings resulting from retirement or
                                termination of employment?
                            </li>
                            <div className='yesnobtndivmain'>
                                <div className="raditrbtntxt">
                                    <input type="radio" id name
                                        value={"Yes"}
                                        checked={formvalue?.provideTransitionDetails?.isProvideTransition === "Yes"}
                                        onChange={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                provideTransitionDetails: {
                                                    ...formvalue.provideTransitionDetails,
                                                    isProvideTransition: "Yes"
                                                }
                                            });
                                        }}
                                    />
                                    <label htmlFor>Yes</label>
                                </div>
                                <div className="raditrbtntxt">
                                    <input type="radio" id name
                                        value={"No"}
                                        checked={formvalue?.provideTransitionDetails?.isProvideTransition === "No"}
                                        onChange={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                provideTransitionDetails: {
                                                    ...formvalue.provideTransitionDetails,
                                                    isProvideTransition: "No"
                                                }
                                            });
                                        }}
                                    />
                                    <label htmlFor>No</label>
                                </div>
                            </div>
                            <div className="yestrxtwarea">
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => handleInput('provideTransitionDetails', 'details', e.currentTarget.textContent)}
                                >
                                </div>
                            </div>

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Details on assessment of value chain partners:
                            </li>



                            <table className='tablbrdrmain mb-2'>
                                <tr className='trdivmainbrdr'>

                                    <th className='thdivmainbrdr'>

                                    </th>
                                    <th className='thdivmainbrdr' >
                                        % of value chain partners (by value of business done
                                        with such partners) that were assessed

                                    </th>

                                </tr>
                                <tr>
                                    <td className='tddivmainbrdr'>
                                        Health and safety practices

                                    </td>

                                    <td className='tddivmainbrdr'>
                                        <div className="yestrxtwarea">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('assessmentValueOfChainPartners', 'healthAndSafetyPractice', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td className='tddivmainbrdr'>Working Conditions

                                    </td>
                                    <td className='tddivmainbrdr'>
                                        <div className="yestrxtwarea">
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('assessmentValueOfChainPartners', 'workingCondition', e.currentTarget.textContent)}

                                            >
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            </table>

                            <div className=" extrapagdhijspscvvbdiv">
                                <p className="notetgvxtygfspopphjhsttxp" style={{ cursor: "pointer" }} onClick={() => setnotemodal4(!notemodal4)}>Note :</p>
                                {notemodal4 &&
                                    <div className='yestrxtwarea mt-3 mb-0'>
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('assessmentValueOfChainPartners', 'note', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </div>}
                            </div>

                            {/* <div class="adddinciondivimg">
                                <img src={AddIcon} alt="..." />
                            </div> */}

                        </div>

                        <div className='listoftblxsdivuldiv'>
                            <li className='listoftblxsdiv'>
                                Provide details of any corrective actions taken or underway to address significant risks /
                                concerns arising from assessments of health and safety practices and working conditions
                                of value chain partners
                            </li>
                            <div className="yestrxtwarea">
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            detailsValueOfChainPartners: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>

                        </div>
                    </ul>
                </div>
            </div>

            <div className="homePgCreModSubmitDiv">
                <button
                    className="homePgCreModSubmitBtn btn1"
                    type="button"
                    onClick={submitHandler}
                >
                    Next
                </button>
            </div>


        </div >
    )
}

export default PrinicpleThree
