import React, { useEffect, useRef, useState } from 'react'
import AddIcon from "../../Images/Icons/PNG/Add icon.png"
import toast from 'react-hot-toast'
import HttpClientXml from '../../utils/HttpClientXml'


const SectionB = () => {
    const initvalue = {
        note: "",
        isPolicyApproved: "",
        weblink: "",
        hasTranslatedPolicy: "",
        enlistedPolicy: "",
        entityAndMappedPrinciple: "",
        specificCommitment: "",
        performanceOfSpecificCommitment: "",
        placementOfDisclosure: "",
        detailsOfResponsibiltyPolicy: "",
        sustainabilityRelatedIssue: "",
        performanceAction: {
            reviewByDirector: "",
            frequentlyReview: ""
        },
        compliance: {
            reviewByDirector: "",
            frequentlyReview: ""
        },
        yesReasonOfExternalPolicy: "",
        noReasonOfExternalPolicy: ""
    }

    const initial = {
        policyName: "",
        ngrbcPrinciple: {
            p1: false,
            p2: false,
            p3: false,
            p4: false,
            p5: false,
            p6: false,
            p7: false,
            p8: false,
            p9: false
        }
    }

    const divRef = useRef(null);
    // const [checked, setChecked] = useState(false);
    const [formvalue, setformvalue] = useState(initvalue);
    const [policyManagementProcess, setpolicyManagementProcess] = useState([initial]);


    // const handleClickcheck = () => {
    //     setChecked(!checked);
    // };


    const [notemodala, setnotemodala] = useState(false);
    const handlenoteclick = () => {
        setnotemodala(!notemodala)
    }

    // const handleClickcheck = (i, principle) => {
    //     let newFormValues = [...policyManagementProcess];
    //     newFormValues[i].ngrbcPrinciple[principle] = !newFormValues[i].ngrbcPrinciple[principle];
    //     setpolicyManagementProcess(newFormValues);
    // };

    // handle checkbox change
    const handleCheckboxChange = (index, principle) => {
        let newFormValues = [...policyManagementProcess];
        newFormValues[index].ngrbcPrinciple[principle] = !newFormValues[index].ngrbcPrinciple[principle];
        setpolicyManagementProcess(newFormValues);
    }

    //handle change for entityDetails add more functionality
    const handleChangeentityDetails = (i, e, section, field) => {
        let newFormValues = [...policyManagementProcess];
        newFormValues[i][section][field] = e.currentTarget.textContent;
        setpolicyManagementProcess(newFormValues);
    };

    //handle change for entityDetails add more functionality
    const handleChangeentity = (i, e, field) => {
        let newFormValues = [...policyManagementProcess];
        newFormValues[i][field] = e.currentTarget.textContent;
        setpolicyManagementProcess(newFormValues);
    };

    //for entityDetails first add more functionality 
    const addFormFieldsentityDetails = () => {
        setpolicyManagementProcess([...policyManagementProcess, initial]);
    };


    //for Input fields
    const handleInput = (category, field, value) => {
        setformvalue((prevValue) => ({
            ...prevValue,
            [category]: {
                ...prevValue[category],
                [field]: value,
            }
        }));
    }


    const submitHandler = async (e) => {
        e.preventDefault();
        let data = {};
        data = {
            "principleAndCoreElement": {
                "policyManagementProcess": policyManagementProcess,
                "note": formvalue?.note,
                "isPolicyApproved": formvalue?.isPolicyApproved,
                "weblink": formvalue?.weblink
            },
            "hasTranslatedPolicy": formvalue?.hasTranslatedPolicy,
            "enlistedPolicy": formvalue?.enlistedPolicy,
            "entityAndMappedPrinciple": formvalue?.entityAndMappedPrinciple,
            "specificCommitment": formvalue?.specificCommitment,
            "performanceOfSpecificCommitment": formvalue?.performanceOfSpecificCommitment,
            "placementOfDisclosure": formvalue?.placementOfDisclosure,
            "detailsOfResponsibiltyPolicy": formvalue?.detailsOfResponsibiltyPolicy,
            "sustainabilityRelatedIssue": formvalue?.sustainabilityRelatedIssue,
            "reviewOfNGRBC": {
                "performanceAction": {
                    "reviewByDirector": formvalue?.performanceAction?.reviewByDirector,
                    "frequentlyReview": formvalue?.performanceAction?.frequentlyReview
                },
                "compliance": {
                    "reviewByDirector": formvalue?.compliance?.reviewByDirector,
                    "frequentlyReview": formvalue?.compliance?.frequentlyReview
                }
            },
            "yesReasonOfExternalPolicy": formvalue?.yesReasonOfExternalPolicy,
            "noReasonOfExternalPolicy": formvalue?.noReasonOfExternalPolicy
        }
        // console.log('principlefour', data);
        const res = await HttpClientXml?.requestData(
            `add-process-disclosure`,
            data,
            "POST"
        );
        if (res?.status) {
            toast?.success("data is added successfully");
            setformvalue(initvalue);
            const contentEditableElements = document.querySelectorAll('[contentEditable]');
            contentEditableElements?.forEach(element => element.textContent = "");
            setpolicyManagementProcess([initial])
        } else {
            toast?.error(res?.response?.data?.message || "Something went wrong");
        }
    }

    return (
        <>
            <section className=''>
                <p className='sectionctxttpppprooitp'>SECTION B: MANAGEMENT AND PROCESS DISCLOSURES</p>

                <div>
                    <ol className='sectiontetxboldiv' start="1">
                        <div className="listoftblxsdivuldiv">
                            <li className="listoftblxsdivseca ">
                                a) This section is aimed at helping businesses demonstrate the structures, policies and processes put in place towards adopting
                                the National Guidelines for Responsible Business Conduct (NGRBC) Principles and Core Elements.
                            </li>
                            <p className='plytxtpmanmgmenttxpprcdivpp'>
                                Policy and management processes
                            </p>
                            <table className="tablbrdrmain">
                                <tr className="trdivmainbrdr">
                                    <th className="thdivmainbrdr sectionpointondivfrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            Policies
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr sectionpointondivscnd " colSpan="9">
                                        <p className='prcnttxttemplytxtpp'>
                                            NGRBC Principle
                                        </p>
                                    </th>
                                </tr>
                                <tr className='trdivmainbrdr'>
                                    <th className=" ">
                                        {/* <p className='prcnttxttemplytxtpp'>
                                           p1
                                        </p> */}
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            p1
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            p2
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            p3
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            p4
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            p5
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            p6
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            p7
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            p8
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            p9
                                        </p>
                                    </th>
                                </tr>

                                {
                                    policyManagementProcess?.map((item, ind) => {
                                        return (
                                            <tr className='trdivmainbrdr'>
                                                <td className="tddivmainbrdr">
                                                    <div
                                                        ref={divRef}
                                                        contentEditable
                                                        className='textardibbrdrdivmain'
                                                        onInput={(e) => handleChangeentity(ind, e, "policyName")}
                                                    >
                                                    </div>
                                                </td>
                                                <td className="tddivmainbrdr  cjheckboscxdibbdffrst">
                                                    {/* <div className='checkbpsosdivpstn' onClick={() => handleClickcheck()}>
                                                        {checked && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i class="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div> */}
                                                    <div className='checkbpsosdivpstn' onClick={() => handleCheckboxChange(ind, "p1")}>
                                                        {item?.ngrbcPrinciple?.p1 && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i className="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="tddivmainbrdr  cjheckboscxdibbdffrst">
                                                    {/* <div className='checkbpsosdivpstn' onClick={handleClickcheck}>
                                                        {checked && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i class="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div> */}

                                                    <div className='checkbpsosdivpstn' onClick={() => handleCheckboxChange(ind, "p2")}>
                                                        {item.ngrbcPrinciple.p2 && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i className="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="tddivmainbrdr  cjheckboscxdibbdffrst">
                                                    {/* <div className='checkbpsosdivpstn' onClick={handleClickcheck}>
                                                        {checked && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i class="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div> */}
                                                    <div className='checkbpsosdivpstn' onClick={() => handleCheckboxChange(ind, "p3")}>
                                                        {item.ngrbcPrinciple.p3 && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i className="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="tddivmainbrdr  cjheckboscxdibbdffrst">
                                                    {/* <div className='checkbpsosdivpstn' onClick={handleClickcheck}>
                                                        {checked && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i class="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div> */}

                                                    <div className='checkbpsosdivpstn' onClick={() => handleCheckboxChange(ind, "p4")}>
                                                        {item.ngrbcPrinciple.p4 && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i className="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="tddivmainbrdr  cjheckboscxdibbdffrst">
                                                    {/* <div className='checkbpsosdivpstn' onClick={handleClickcheck}>
                                                        {checked && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i class="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div> */}
                                                    <div className='checkbpsosdivpstn' onClick={() => handleCheckboxChange(ind, "p5")}>
                                                        {item.ngrbcPrinciple.p5 && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i className="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="tddivmainbrdr  cjheckboscxdibbdffrst">
                                                    {/* <div className='checkbpsosdivpstn' onClick={handleClickcheck}>
                                                        {checked && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i class="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div> */}
                                                    <div className='checkbpsosdivpstn' onClick={() => handleCheckboxChange(ind, "p6")}>
                                                        {item.ngrbcPrinciple.p6 && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i className="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="tddivmainbrdr  cjheckboscxdibbdffrst">
                                                    {/* <div className='checkbpsosdivpstn' onClick={handleClickcheck}>
                                                        {checked && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i class="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div> */}
                                                    <div className='checkbpsosdivpstn' onClick={() => handleCheckboxChange(ind, "p7")}>
                                                        {item.ngrbcPrinciple.p7 && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i className="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="tddivmainbrdr  cjheckboscxdibbdffrst">
                                                    {/* <div className='checkbpsosdivpstn' onClick={handleClickcheck}>
                                                        {checked && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i class="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div> */}
                                                    <div className='checkbpsosdivpstn' onClick={() => handleCheckboxChange(ind, "p8")}>
                                                        {item.ngrbcPrinciple.p8 && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i className="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="tddivmainbrdr  cjheckboscxdibbdffrst">
                                                    {/* <div className='checkbpsosdivpstn' onClick={handleClickcheck}>
                                                        {checked && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i class="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div> */}
                                                    <div className='checkbpsosdivpstn' onClick={() => handleCheckboxChange(ind, "p9")}>
                                                        {item.ngrbcPrinciple.p9 && <div className='chekdivsfgsgdivdflrtdov'>
                                                            <i className="fa-solid fa-check"></i>
                                                        </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                            </table>


                            <div className='notedivadmoredivflx'>
                                <div className=''>
                                    <p className='notetgvxtygfspopphjhsttxp mt-2' onClick={handlenoteclick}>Note</p>
                                </div>
                                <button className='adddinciondivimg'>
                                    <img src={AddIcon} alt='...' onClick={addFormFieldsentityDetails} />
                                    <span>Add More</span>
                                </button>
                            </div>

                            {notemodala &&
                                <div className="yestrxtwarea mt-2 mb-2">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                note: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>}
                        </div>

                        <div className="listoftblxsdivuldiv">
                            <table className="tablbrdrmain">
                                <tr className="trdivmainbrdr">
                                    <th className="thdivmainbrdr sectionpointondivfrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            Disclosure Questions
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr sectionpointondivscnd " colSpan="9">
                                        <p className='prcnttxttemplytxtpp'>
                                            NGRBC Principle
                                        </p>
                                    </th>
                                </tr>

                                <tr className='trdivmainbrdr '>
                                    <th className="sectionpointondivfrst ">

                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p1
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p2
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p3
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p4
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p5
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p6
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p7
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p8
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p9
                                        </p>
                                    </th>
                                </tr>


                                <tr className='trdivmainbrdr'>
                                    <td className="tddivmainbrdr">
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>
                                            b) Has the policy been
                                            approved by the
                                            Board? (Yes/No)
                                        </p>
                                    </td>
                                    <td className="tddivmainbrdr" colSpan={9}>

                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    isPolicyApproved: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className="tddivmainbrdr">
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>
                                            c) Web Link of the
                                            Policies, if available
                                        </p>
                                    </td>
                                    <td className="tddivmainbrdr" colSpan={9}>

                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    weblink: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div className="listoftblxsdivuldiv">
                            <table className="tablbrdrmain">
                                <tr className="trdivmainbrdr">
                                    <th className="thdivmainbrdr sectionpointondivfrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            Disclosure Questions
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr sectionpointondivscnd " colSpan="9">
                                        <p className='prcnttxttemplytxtpp'>
                                            NGRBC Principle
                                        </p>
                                    </th>
                                </tr>

                                <tr className='trdivmainbrdr '>
                                    <th className="sectionpointondivfrst ">

                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p1
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p2
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p3
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p4
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p5
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p6
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p7
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p8
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p9
                                        </p>
                                    </th>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className="tddivmainbrdr">
                                        <li className='listoftblxsdivseca mb-0' >
                                            Whether the entity
                                            has translated
                                            the policy into
                                            procedures. (Yes/No)
                                        </li>
                                    </td>
                                    <td className="tddivmainbrdr" colSpan={9}>

                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    hasTranslatedPolicy: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>

                                    </td>

                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className="tddivmainbrdr">
                                        <li className='listoftblxsdivseca mb-0'>
                                            Do the enlisted
                                            policies extend to
                                            your value chain
                                            partner? (Yes/No)
                                        </li>
                                    </td>
                                    <td className="tddivmainbrdr" colSpan={9}>

                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    enlistedPolicy: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>

                                    </td>

                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className="tddivmainbrdr">
                                        <li className='listoftblxsdivseca mb-0'>
                                            Name of the national
                                            and international
                                            codes/ certifications/
                                            labels/ standards
                                            (e.g. Forest
                                            Stewardship Council,
                                            Fairtrade, Rainforest
                                            Alliance, Trustea)
                                            standards (e.g. SA
                                            8000, OHSAS, ISO,
                                            BIS) adopted by your
                                            entity and mapped
                                            to each principle
                                        </li>
                                    </td>
                                    <td className="tddivmainbrdr" colSpan={9}>

                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    entityAndMappedPrinciple: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>

                                    </td>

                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className="tddivmainbrdr">
                                        <li className='listoftblxsdivseca mb-0'>
                                            Specific
                                            commitments, goals
                                            and targets set
                                            by the entity with
                                            defined timelines, if
                                            any.
                                        </li>
                                    </td>
                                    <td className="tddivmainbrdr" colSpan={9}>

                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    specificCommitment: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>

                                    </td>

                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className="tddivmainbrdr">
                                        <li className='listoftblxsdivseca mb-0'>
                                            Performance of
                                            the entity against
                                            the specific
                                            commitments, goals
                                            and targets along
                                            with reasons in case
                                            the same are not
                                            met.
                                        </li>
                                    </td>
                                    <td className="tddivmainbrdr" colSpan={9}>

                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    performanceOfSpecificCommitment: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>

                                    </td>

                                </tr>

                            </table>
                        </div>

                        <div className="listoftblxsdivuldiv">
                            <p className='plytxtpmanmgmenttxpprcdivpp'>
                                Governance, Leadership and Oversight
                            </p>
                            <li className="listoftblxsdivseca ">
                                Statement by director responsible for the business responsibility report, highlighting ESG
                                related challenges, targets and achievements (listed entity has flexibility regarding the
                                placement of this disclosure)
                            </li>

                            <div className="yestrxtwarea mt-2 mb-0">
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            placementOfDisclosure: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>

                        </div>

                        <div className="listoftblxsdivuldiv">
                            <table className="tablbrdrmain">
                                <tr className="trdivmainbrdr">
                                    <th className="thdivmainbrdr sectionpointondivfrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            Disclosure Questions
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr sectionpointondivscnd " colSpan="9">
                                        <p className='prcnttxttemplytxtpp'>
                                            Statement
                                        </p>
                                    </th>
                                </tr>

                                <tr className='trdivmainbrdr '>
                                    <th className="sectionpointondivfrst ">

                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p1
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p2
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p3
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p4
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p5
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p6
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p7
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p8
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr cjheckboscxdibbdffrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            p9
                                        </p>
                                    </th>
                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className="tddivmainbrdr">
                                        <li className='listoftblxsdivseca mb-0' >
                                            Details of the highest
                                            authority responsible
                                            for implementation
                                            and oversight
                                            of the Business
                                            Responsibility
                                            policy (ies).
                                        </li>
                                    </td>
                                    <td className="tddivmainbrdr" colSpan={9}>

                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    detailsOfResponsibiltyPolicy: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>

                                    </td>

                                </tr>

                                <tr className='trdivmainbrdr'>
                                    <td className="tddivmainbrdr">
                                        <li className='listoftblxsdivseca mb-0'>
                                            Does the entity
                                            have a specified
                                            Committee of the
                                            Board/Director
                                            responsible for
                                            decision-making on
                                            sustainability related
                                            issues? (Yes/No). If
                                            yes, provide details.
                                        </li>
                                    </td>
                                    <td className="tddivmainbrdr" colSpan={9}>

                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => {
                                                setformvalue({
                                                    ...formvalue,
                                                    sustainabilityRelatedIssue: e.currentTarget.textContent
                                                });
                                            }}
                                        >
                                        </div>

                                    </td>

                                </tr>

                            </table>
                        </div>

                        <div className="listoftblxsdivuldiv">
                            <li className="listoftblxsdivseca ">
                                Details of Review of NGRBCs by the Company:
                            </li>
                            <table className="tablbrdrmain">
                                <tr className="trdivmainbrdr">
                                    <th className="thdivmainbrdr sectnbbdivtendivfrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            Subject for Review
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr sectnbbdivtendivscnd" colSpan="9">
                                        <p className='prcnttxttemplytxtpp'>
                                            Indicate whether review was undertaken by Director/
                                            Committee of the Board/ Any other Committee
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr sectnbbdivtendivsthrd" colSpan="9">
                                        <p className='prcnttxttemplytxtpp'>
                                            Frequency
                                            (Annually/ Half yearly/
                                            Quarterly/ Any other – please
                                            specify)
                                        </p>
                                    </th>
                                </tr>
                                <tr className="trdivmainbrdr">
                                    <th className="thdivmainbrdr sectnbbdivtendivfrst">
                                        <p className='prcnttxttemplytxtpp'>
                                            Principle
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P1
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P2
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P3
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P4
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P5
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P6
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P7
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P8
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P9
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P1
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P2
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P3
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P4
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P5
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P6
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P7
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P8
                                        </p>
                                    </th>
                                    <th className="thdivmainbrdr ">
                                        <p className='prcnttxttemplytxtpp'>
                                            P9
                                        </p>
                                    </th>
                                </tr>
                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>
                                            Performance against
                                            above policies and
                                            follow up action
                                        </p>
                                    </td>
                                    <td className='tddivmainbrdr' colSpan="9">
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('performanceAction', 'reviewByDirector', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' colSpan="9">
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('performanceAction', 'frequentlyReview', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>
                                <tr className='trdivmainbrdr'>
                                    <td className='tddivmainbrdr'>
                                        <p className='Malettsgvsgphsmkjghhutxtpp'>
                                            Compliance with
                                            statutory requirements
                                            of relevance to
                                            the principles, and
                                            rectification of any
                                            non-compliances
                                        </p>
                                    </td>
                                    <td className='tddivmainbrdr' colSpan="9">
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('compliance', 'reviewByDirector', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                    <td className='tddivmainbrdr' colSpan="9">
                                        <div
                                            ref={divRef}
                                            contentEditable
                                            className='textardibbrdrdivmain'
                                            onInput={(e) => handleInput('compliance', 'frequentlyReview', e.currentTarget.textContent)}
                                        >
                                        </div>
                                    </td>
                                </tr>
                            </table>

                        </div>

                        <div className="listoftblxsdivuldiv">

                            <li className="listoftblxsdivseca ">
                                Has the entity carried out independent
                                assessment/ evaluation of the working of
                                its policies by an external agency?
                                (Yes/No). If yes, provide name of the
                                agency.
                            </li>

                            <div className="yestrxtwarea mt-2 mb-0">
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            yesReasonOfExternalPolicy: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>

                        </div>

                        <div className="listoftblxsdivuldiv">

                            <li className="listoftblxsdivseca ">
                                If answer to question (1) above is “No” i.e. not all Principles are covered by a policy, reasons to be stated:
                            </li>

                            <div className="yestrxtwarea mt-2 mb-0">
                                <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                    onInput={(e) => {
                                        setformvalue({
                                            ...formvalue,
                                            noReasonOfExternalPolicy: e.currentTarget.textContent
                                        });
                                    }}
                                >
                                </div>
                            </div>

                        </div>

                    </ol>
                </div >

                <div className="homePgCreModSubmitDiv">
                    <button
                        type='button'
                        onClick={submitHandler}
                        className="homePgCreModSubmitBtn btn1">
                        Submit
                    </button>
                </div>

            </section >
        </>
    )
}

export default SectionB
