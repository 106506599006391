import { reactLocalStorage } from 'reactjs-localstorage';
const BASE_URL = "https://admin.innoverseesg.com/api/v1/user/";
// const ORIGIN_URL = "https://admin.innoverseesg.com"
const ALLOW_ORIGIN = "https://admin.innoverseesg.com";
const USER_TYPE = "User";

function get(endpoint, params) {
  return requestData(endpoint, params);
}

function post(endpoint, params) {
  return requestData(endpoint, params, 'POST');
}

function put(endpoint, params) {
  return requestData(endpoint, params, 'PUT');
}

function deletemethod(endpoint, params) {
  return requestData(endpoint, params, 'DELETE');
}

async function requestData(url, data = {}, method = 'GET') {
  let xhr = new XMLHttpRequest();
  xhr.open(method, BASE_URL + url);
  if (checkingAuth()) xhr.setRequestHeader('authorization', checkingAuth());
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.setRequestHeader('Access-Control-Allow-Origin', ALLOW_ORIGIN);
  xhr.setRequestHeader('userType', USER_TYPE);

  return new Promise((resolve, reject) => {
    // if (method === 'GET') {
    //   xhr.send();
    // } else {
    //   xhr.send(JSON?.stringify(data));
    // }

    if (method === 'GET') {
      xhr.send();
    } else {
      xhr.send(stringifySafe(data));
    }

    // xhr.onload = () => {
    //   console.log(xhr.status);
    //   // console.log("jdjgfdgdfgdgfdgfdgdf", xhr);
    //   let response = JSON?.parse(xhr.response);
    //   // console.log(response ,"sdgdgdfgdfgdf");

    //   if (xhr.status === 200 || xhr.status === 400 || xhr.status === 403 || xhr.status === 500 || xhr.status === 406) {
    //     resolve(response);
    //   } else {
    //     reject(response);
    //   }
    // };


    xhr.onload = () => {
      console.log(xhr.status);

      let contentType = xhr.getResponseHeader('Content-Type');
      let response;

      if (contentType && contentType.includes('application/json')) {
        try {
          response = JSON.parse(xhr.responseText);
        } catch (e) {
          reject({ error: 'Invalid JSON response' });
          return;
        }
      } else {
        response = xhr.responseText; // Handle non-JSON response
      }

      if (xhr.status === 200 || xhr.status === 400 || xhr.status === 403 || xhr.status === 500 || xhr.status === 406) {
        resolve(response);
      } else {
        reject(response);
      }
    };

    xhr.onerror = () => {
      reject({ error: 'Network error' });
    };

  });
}



async function fileUplode(url, method, file, object_get = {}, tokenCustom = null) {
  let token = '';
  let user = reactLocalStorage.get('EsgFrontAuth');

  if (user && user !== null && Object.keys(user).length > 0) {
    token = user;
  }
  // let bash_url = "http://13.127.101.102:5011/api/v1/";
  let apiUrl = BASE_URL + url;



  if (token !== '') {
    var toooo = token;
  }
  //  console.log("tokenn",toooo);
  let headers = {
    // 'Accept': 'application/json',
    // "Content-Type": "multipart/form-data",
    // "Access-Control-Allow-Origin": "http://13.127.101.102:5008",
    authorization: toooo,
    userType: 'User',
    // 'Authorization': 'Bearer ' + login_status,
  };



  return await fetch(apiUrl, {
    method: method,
    body: file,
    redirect: 'follow',
    headers: headers,
  })
    .then(response => {
      console.log("responseee", response, headers);
      return response.json();
    })
    .then(
      result => {
        console.log('ResponIMG', result);
        return result;
      },
      error => {
        return error;
      }
    );
}

async function fileVideoUplode(url, method, file, object_get = {}, tokenCustom = null) {
  let token = '';
  let user = reactLocalStorage.get('EsgFrontAuth');
  // console.log("UserTok", user);
  if (user && user !== null && Object.keys(user).length > 0) {
    token = user;
  }
  // let bash_url = "http://13.127.101.102:5011/api/v1/";
  let apiUrl = BASE_URL + url;
  // let data = new FormData();
  // data.append("image", file);
  // Object.keys(object_get).forEach(function (key) {
  //   let ddd = object_get[key];
  //   data.append(key, ddd);
  // });

  if (token !== '') {
    var toooo = token;
  }
  //  console.log("tokenn",toooo);
  let headers = {
    // 'Accept': 'application/json',
    // "Content-Type": "multipart/form-data",
    // "Access-Control-Allow-Origin": "http://13.127.101.102:5008",
    authorization: toooo,
    userType: 'User',
    // 'Authorization': 'Bearer ' + login_status,
  };



  return await fetch(apiUrl, {
    method: method,
    body: file,
    redirect: 'follow',
    headers: headers,
  })
    .then(response => {
      // console.log("responseee", response, headers);
      return response.json();
    })
    .then(
      result => {
        console.log('ResponIMG', result);
        return result;
      },
      error => {
        return error;
      }
    );
}

function checkingAuth() {
  let token = reactLocalStorage.get('EsgFrontAuth');
  // console.log('TOKEn', token);

  if (token) {
    return token;
  }
  return false;
}


// function stringifySafe(obj) {
//   const seen = new WeakSet();
//   return JSON.stringify(obj, function(key, value) {
//       if (typeof value === 'object' && value !== null) {
//           if (seen.has(value)) {
//               return '[Circular]';
//           }
//           seen.add(value);
//       }
//       return value;
//   });
// }

function stringifySafe(obj) {
  const stack = [];
  return JSON.stringify(obj, function (key, value) {
    if (typeof value === 'object' && value !== null) {
      if (stack.includes(value)) {
        return '[Circular]';
      }
      stack.push(value);
    }
    return value;
  });
}


export default {
  requestData,
  get,
  post,
  put,
  deletemethod,
  fileUplode,
  fileVideoUplode,
}