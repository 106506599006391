import React, { useEffect } from 'react'
import Expand from "../../Images/Icons/PNG/Expand.png";
// import advcyimgrhre from "../../Images/advcyimgrhre.jpeg"
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionEventData } from '../../Redux/Slice/subscriptionEventSlice';
import moment from 'moment';

const Mysubscribeevents = () => {
    const subscribed = useSelector((state) => state?.subscriptionEventSlice?.subscription);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSubscriptionEventData())
    }, [])
    console.log("subscribed",subscribed)
  return (
  <>
      <div className='hightlightedevntsdiv'>
                <div className="top">
                    <div className="head bulleBoardHead">
                        <h4>My Subscribed Events</h4>
                    </div>
                    <div className="add_seeallflx">
                        <div className="tyEvMainDiv">
                            <figure className="bulleBoardFig">
                                <img
                                    src={Expand}
                                    alt="..."
                                />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    {
                        subscribed?.map((item)=>{
                            return(
                                <>
                                <div className="card_area">
                        <div className='event_imgtextbillentin'>
                            <div className='event_img'>
                                <img src={item?.image} alt="..." />
                            </div>
                            <div className="">
                                <p className="txtevent">{item?.eventName}</p>
                                {/* <p className="txtevent">Company Name</p> */}
                                <div className="hstdadty">
                                    <p className="txtevent">Date : {moment(item?.eventDate)?.format("YYYY-MM-DD")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                                </>
                            )
                        })
                    }
                    
                   
                </div>
            </div>
  
  </>
  )
}

export default Mysubscribeevents
