import { combineReducers, configureStore  } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import StatusCardSlice from "../Slice/StatusCardSlice";
import PostSlice from "../Slice/PostSlice";
import AffinityGroupSlice from "../Slice/AffinityGroupSlice";
import subscriptionEventSlice from "../Slice/subscriptionEventSlice";
import TrainingSlice from "../Slice/TrainingSlice";
// import thunk from "redux-thunk";

let rootReducer = combineReducers({
  StatusCardSlice: StatusCardSlice,
  PostSlice:PostSlice,
  AffinityGroupSlice:AffinityGroupSlice,
  subscriptionEventSlice:subscriptionEventSlice,
  TrainingSlice:TrainingSlice
})

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const Store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  // middleware,
});

export const persistor = persistStore(Store);

export default Store;