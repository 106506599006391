import React, { useEffect, useState } from 'react'
import Expand from "../../Images/Icons/PNG/Expand.png";
// import advcyimgrhre from "../../Images/advcyimgrhre.jpeg"
import HttpClientXml from '../../utils/HttpClientXml';
import moment from 'moment';

const Highlightedevents = () => {
    const [data, setData] = useState([]);

    const highlightEvent = async () => {
        const response = await HttpClientXml?.requestData(`get-highlights-events`, {}, "GET");

        if (response.status) {
            setData(response?.data);
        } else {
        }
    };



    useEffect(() => {
        highlightEvent();
    }, []);
    return (
        <>
            <div className='hightlightedevntsdiv'>
                <div className="top">
                    <div className="head bulleBoardHead">
                        <h4>Highlighted Events</h4>
                    </div>
                    <div className="add_seeallflx">
                        <div className="tyEvMainDiv">
                            <figure className="bulleBoardFig">
                                <img
                                    src={Expand}
                                    alt="..."
                                />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    {
                        data?.map((item) => {
                            return (
                                <>
                                    <div className="card_area">
                                        <div className='event_imgtextbillentin'>
                                            <div className='event_img'>
                                                <img src={item?.image} alt="..." />
                                            </div>
                                            <div className="">
                                                <p className="txtevent">{item?.eventName}</p>
                                                {/* <p className="txtevent">Company Name</p> */}
                                                <div className="hstdadty">
                                                    <p className="txtevent">Date : {moment(item?.eventDate)?.format("YYYY-MM-DD")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="addiconplushh">
                                            <i className="fa-solid fa-plus" />
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }

                   
                </div>
            </div>
        </>
    )
}

export default Highlightedevents

