import React from 'react'
import imgadvcy from "../../Images/imgadvcy.png";
import Share from "../../Images/Icons/PNG/Share.png";
import sharethght from "../../Images/sharethght.jpg";
import download from "../../Images/Icons/PNG/download.png"
const Singleadvocacy = () => {
  return (
    <>
      <section className="bulletinsinglemain">
        <div className="detailspagecontainer">
          <div className='bulletinimgmain'>
            <img src={imgadvcy} alt="..." />
          </div>
          <div className='bulletinimgmaindtlstxt'>
            <div className='advocaydatashrdivfkx'>
              <div className='tetsccdivfkjsg'>
                <p className='tetsccdivfkjsgtstpp'>Testing purpose Advcacy fromHr 10</p>
              </div>
              <div className='rpstiocnimg'>
                <img src={Share} alt="..." />
              </div>
            </div>
            <div className="prflimgtxtflx">

              <div className="prflsadvctimgmain">
                <img src={sharethght}
                  alt="..."

                />
              </div>

              <div className="prfdtlsadvc">
                <p className="prfladtxt">Niladri Roy</p>
                <p className="prfldttxt">07-May-2024</p>
              </div>
            </div>
            <div className="advcacylanfdtlsdivmain">
              <p className="loremsingleadvocaytext">Language : <span>
                English
              </span></p>
              <p className="loremsingleadvocaytextdtlspp">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the
                1500s, when an unknown printer took a galley of type and scrambled it to
                make a type specimen book. It has survived not only five centuries, but also
                the leap into electronic typesetting, remaining essentially unchanged. It
                was popularised in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing software
                like Aldus PageMaker including versions of Lorem Ipsum.
              </p>
              <div className='dwldafiledivfxlaad'>
                <p className='dwnldflxtxtp'> Download File : </p>
                <div className='dwnlddivomg'>
                  <img src={download} alt="..." />
                </div>
              </div>
            </div>




          </div>
        </div>
      </section>

    </>
  )
}

export default Singleadvocacy
