import React from 'react'
import imgprlsthgt from "../../Images/imgprlsthgt.jpg";
import Addicon from "../../Images/Icons/PNG/Add icon.png";
const CreateProfile = () => {
    return (
        <>
            <section className="createeventdetails">
                <div className="detailspagecontainer">
                    <div className="createdivfrmmain">
                        <form>
                            <div className=''>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">First Name </p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"

                                    />

                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Last Name </p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"

                                    />

                                </div>
                                <div className="vdoulddivpbupldgnewmmll">
                                    <p className="rsntxrpnewtxt">  Upload Profile Picture <span className='required'>*</span></p>

                                    <div className="bgcontainerupldboxsgwgrdiv">
                                        <div className="bgcrd">
                                            <div className="bgcontaineruplddivnew">
                                                <div className="logouplddiv">
                                                    <img
                                                        src={Addicon}
                                                        alt="..."
                                                    />
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            id="hostedBy"
                                                            className="upldimagediv"
                                                            placeholder="Choose a photo"

                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            {/* <p className="upldtxtppdiv">Upload min 5 mb image</p> */}

                                            {/* After Upload Design Start */}
                                            <div className="bgcontainerupldfiledivabslt">
                                                <div className="imageuplddiv">
                                                    <img src={imgprlsthgt} alt />
                                                    <div className="imageupldcrs" style={{
                                                        cursor: "pointer"
                                                    }}>
                                                        <i className="fa-solid fa-xmark"  />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* After Upload Design End */}

                                        </div>
                                    </div>
                                </div>
                                <div className="bulletinsbmtbtndivv">
                                    <button
                                        type="button"
                                        className="bulletinsbmtbtn"
                                        
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreateProfile
