import React, { useEffect, useRef, useState } from 'react'
import Search from "../../Images/ESG gradient Icons/Search.png";
import Info from "../../Images/ESG gradient Icons/Info.png";
import cutegirldp from "../../Images/cute-girl-dp.jpg";
import Attach from "../../Images/ESG gradient Icons/Attach.png";
import Camera from "../../Images/ESG gradient Icons/Camera.png";
import Mic from "../../Images/ESG gradient Icons/Mic.png";
import Post from "../../Images/ESG gradient Icons/Post.png"
import "./Message.css"
const Message = () => {
  const [text, setText] = useState("Type a Message");
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      const maxHeight = 200; // 200 pixels height
      if (divRef.current.scrollHeight > maxHeight) {
        divRef.current.style.overflowY = 'hidden';
        setText()
      } else {
        divRef.current.style.overflowY = 'auto';
      }
    }
  }, [text]);

  return (
    <>
      <section className="messagesectionmain">
        <div className='esgpagecontainer'>
          <div className='messagediv_maindiv'>
            <div className='messagedivleft'>
              <div className='messagedivbgg'>
                <div className='messagedivbgg_top'>
                  <div className="inptdivtxtdippplcd">
                    <input type="text" placeholder="search" />
                    <div className="srchdivimgicn">
                      <img src={Search} alt="..." />
                    </div>
                  </div>

                </div>
                <div className='messagedivbgg_bottom'>
                  <div className="msgprofiledsgtmflc" style={{ cursor: "pointer" }}>
                    <div className="msgprofjdsgfxl">
                      <div className="">
                        <div className="prfldivimhbg">
                          <img
                            src={cutegirldp}
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="">
                        <p className="profilenamemsgtxt">kaveri</p>
                        <p className="profilenamemsgdsgtxtp">Yes I think its a good idea</p>
                      </div>
                    </div>
                    <div className="timedivhpp">
                      <p className="timemsgppp">10:03 PM</p>
                    </div>
                  </div>
                  <div className="msgprofiledsgtmflc" style={{ cursor: "pointer" }}>
                    <div className="msgprofjdsgfxl">
                      <div className="">
                        <div className="prfldivimhbg">
                          <img
                            src={cutegirldp}
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="">
                        <p className="profilenamemsgtxt">kaveri</p>
                        <p className="profilenamemsgdsgtxtp">Yes I think its a good idea</p>
                      </div>
                    </div>
                    <div className="timedivhpp">
                      <p className="timemsgppp">10:03 PM</p>
                    </div>
                  </div>
                  <div className="msgprofiledsgtmflc" style={{ cursor: "pointer" }}>
                    <div className="msgprofjdsgfxl">
                      <div className="">
                        <div className="prfldivimhbg">
                          <img
                            src={cutegirldp}
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="">
                        <p className="profilenamemsgtxt">kaveri</p>
                        <p className="profilenamemsgdsgtxtp">Yes I think its a good idea</p>
                      </div>
                    </div>
                    <div className="timedivhpp">
                      <p className="timemsgppp">10:03 PM</p>
                    </div>
                  </div>
                  <div className="msgprofiledsgtmflc" style={{ cursor: "pointer" }}>
                    <div className="msgprofjdsgfxl">
                      <div className="">
                        <div className="prfldivimhbg">
                          <img
                            src={cutegirldp}
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="">
                        <p className="profilenamemsgtxt">kaveri</p>
                        <p className="profilenamemsgdsgtxtp">Yes I think its a good idea</p>
                      </div>
                    </div>
                    <div className="timedivhpp">
                      <p className="timemsgppp">10:03 PM</p>
                    </div>
                  </div>
                  <div className="msgprofiledsgtmflc" style={{ cursor: "pointer" }}>
                    <div className="msgprofjdsgfxl">
                      <div className="">
                        <div className="prfldivimhbg">
                          <img
                            src={cutegirldp}
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="">
                        <p className="profilenamemsgtxt">kaveri</p>
                        <p className="profilenamemsgdsgtxtp">Yes I think its a good idea</p>
                      </div>
                    </div>
                    <div className="timedivhpp">
                      <p className="timemsgppp">10:03 PM</p>
                    </div>
                  </div>
                  <div className="msgprofiledsgtmflc" style={{ cursor: "pointer" }}>
                    <div className="msgprofjdsgfxl">
                      <div className="">
                        <div className="prfldivimhbg">
                          <img
                            src={cutegirldp}
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="">
                        <p className="profilenamemsgtxt">kaveri</p>
                        <p className="profilenamemsgdsgtxtp">Yes I think its a good idea</p>
                      </div>
                    </div>
                    <div className="timedivhpp">
                      <p className="timemsgppp">10:03 PM</p>
                    </div>
                  </div>
                  <div className="msgprofiledsgtmflc" style={{ cursor: "pointer" }}>
                    <div className="msgprofjdsgfxl">
                      <div className="">
                        <div className="prfldivimhbg">
                          <img
                            src={cutegirldp}
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="">
                        <p className="profilenamemsgtxt">kaveri</p>
                        <p className="profilenamemsgdsgtxtp">Yes I think its a good idea</p>
                      </div>
                    </div>
                    <div className="timedivhpp">
                      <p className="timemsgppp">10:03 PM</p>
                    </div>
                  </div>
                  <div className="msgprofiledsgtmflc" style={{ cursor: "pointer" }}>
                    <div className="msgprofjdsgfxl">
                      <div className="">
                        <div className="prfldivimhbg">
                          <img
                            src={cutegirldp}
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="">
                        <p className="profilenamemsgtxt">kaveri</p>
                        <p className="profilenamemsgdsgtxtp">Yes I think its a good idea</p>
                      </div>
                    </div>
                    <div className="timedivhpp">
                      <p className="timemsgppp">10:03 PM</p>
                    </div>
                  </div>
                  <div className="msgprofiledsgtmflc" style={{ cursor: "pointer" }}>
                    <div className="msgprofjdsgfxl">
                      <div className="">
                        <div className="prfldivimhbg">
                          <img
                            src={cutegirldp}
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="">
                        <p className="profilenamemsgtxt">kaveri</p>
                        <p className="profilenamemsgdsgtxtp">Yes I think its a good idea</p>
                      </div>
                    </div>
                    <div className="timedivhpp">
                      <p className="timemsgppp">10:03 PM</p>
                    </div>
                  </div>
                  <div className="msgprofiledsgtmflc" style={{ cursor: "pointer" }}>
                    <div className="msgprofjdsgfxl">
                      <div className="">
                        <div className="prfldivimhbg">
                          <img
                            src={cutegirldp}
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="">
                        <p className="profilenamemsgtxt">kaveri</p>
                        <p className="profilenamemsgdsgtxtp">Yes I think its a good idea</p>
                      </div>
                    </div>
                    <div className="timedivhpp">
                      <p className="timemsgppp">10:03 PM</p>
                    </div>
                  </div>
                  <div className="msgprofiledsgtmflc" style={{ cursor: "pointer" }}>
                    <div className="msgprofjdsgfxl">
                      <div className="">
                        <div className="prfldivimhbg">
                          <img
                            src={cutegirldp}
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="">
                        <p className="profilenamemsgtxt">kaveri</p>
                        <p className="profilenamemsgdsgtxtp">Yes I think its a good idea</p>
                      </div>
                    </div>
                    <div className="timedivhpp">
                      <p className="timemsgppp">10:03 PM</p>
                    </div>
                  </div>
                  <div className="msgprofiledsgtmflc" style={{ cursor: "pointer" }}>
                    <div className="msgprofjdsgfxl">
                      <div className="">
                        <div className="prfldivimhbg">
                          <img
                            src={cutegirldp}
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="">
                        <p className="profilenamemsgtxt">kaveri</p>
                        <p className="profilenamemsgdsgtxtp">Yes I think its a good idea</p>
                      </div>
                    </div>
                    <div className="timedivhpp">
                      <p className="timemsgppp">10:03 PM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='messagedivright'>
              <div className='messagedivrightbg'>
                <div className="messagedivrighttop">
                  <div className="messagerighttopflxdiv">
                    <div className="messagedivprfldsgrightflx">
                      <div className="prfldivimhbg">
                        <img
                          src={cutegirldp}
                          alt="..."
                        />
                      </div>

                      <div className="">
                        <p className="profilenamemsgtxtrighttop">kaveri</p>
                        <p className="profilenamemsgdsgtxtprightdsgtop"> </p>
                      </div>
                    </div>
                    <div className="infoimgsicvimg">
                      <img src={Info} alt="..." />
                    </div>
                  </div>
                </div>
                <div className="messagedivrightbottom">
                  <div className="">
                    <div className="profileotherdiv">
                      <div className="prfldivimhbg">
                        <img
                          src={cutegirldp}
                          alt="..."
                        />
                      </div>
                      <div className="profileotherdivbg">
                        <p className="profileotherdivtxt">Hello </p>
                        <p className="profileotherdivtxttime">06:22 PM</p>
                      </div>
                    </div>
                    <div className="profileowndiv">
                      <div className="profileotherdivbgsntimg">
                        <div className="profileowndivbg">
                          <p className="profileotherdivtxt">hi</p>
                          <p className="profileotherdivtxttime">06:23 PM</p>
                          <div className="profilearrowdiv" />
                        </div>
                        <div className="">
                          <div className="">
                            <p className="snttxtpppcbh">Sent</p>
                          </div>
                          <div className="senncheckdivimg">
                            <img
                              src={cutegirldp}
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profileotherdiv">
                      <div className="prfldivimhbg">
                        <img
                          src={cutegirldp}
                          alt="..."
                        />
                      </div>
                      <div className="profileotherdivbg">
                        <p className="profileotherdivtxt">hello </p>
                        <p className="profileotherdivtxttime">06:28 PM</p>
                      </div>
                    </div>
                    <div className="profileowndiv">
                      <div className="profileotherdivbgsntimg">
                        <div className="profileowndivbg">
                          <p className="profileotherdivtxt">Yes</p>
                          <p className="profileotherdivtxttime">06:28 PM</p>
                          <div className="profilearrowdiv" />
                        </div>
                        <div className="">
                          <div className="">
                            <p className="snttxtpppcbh">Sent</p>
                          </div>
                          <div className="senncheckdivimg">
                            <img
                              src={cutegirldp}
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profileotherdiv">
                      <div className="prfldivimhbg">
                        <img
                          src={cutegirldp}
                          alt="..."
                        />
                      </div>
                      <div className="profileotherdivbg">
                        <p className="profileotherdivtxt">No </p>
                        <p className="profileotherdivtxttime">06:30 PM</p>
                      </div>
                    </div>
                    <div className="profileotherdiv">
                      <div className="prfldivimhbg">
                        <img
                          src={cutegirldp}
                          alt="..."
                        />
                      </div>
                      <div className="profileotherdivbg">
                        <p className="profileotherdivtxt">How funny you are!! </p>
                        <p className="profileotherdivtxttime">06:47 PM</p>
                      </div>
                    </div>
                    <div className="profileowndiv">
                      <div className="profileotherdivbgsntimg">
                        <div className="profileowndivbg">
                          <p className="profileotherdivtxt">No, I am not a funny man.</p>
                          <p className="profileotherdivtxttime">06:48 PM</p>
                          <div className="profilearrowdiv" />
                        </div>
                        <div className="">
                          <div className="">
                            <p className="snttxtpppcbh">Sent</p>
                          </div>
                          <div className="senncheckdivimg">
                            <img
                              src={cutegirldp}
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profileotherdiv">
                      <div className="prfldivimhbg">
                        <img
                          src={cutegirldp}
                          alt="..."
                        />
                      </div>
                      <div className="profileotherdivbg">
                        <p className="profileotherdivtxt">Ok </p>
                        <p className="profileotherdivtxttime">06:49 PM</p>
                      </div>
                    </div>
                    <div className="profileowndiv">
                      <div className="profileotherdivbgsntimg">
                        <div className="profileowndivbg">
                          <p className="profileotherdivtxt">Good Evening</p>
                          <p className="profileotherdivtxttime">06:50 PM</p>
                          <div className="profilearrowdiv" />
                        </div>
                        <div className="">
                          <div className="">
                            <p className="snttxtpppcbh">Sent</p>
                          </div>
                          <div className="senncheckdivimg">
                            <img
                              src={cutegirldp}
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profileowndiv">
                      <div className="profileotherdivbgsntimg">
                        <div className="profileowndivbg">
                          <p className="profileotherdivtxt">Yes I think its a good idea</p>
                          <p className="profileotherdivtxttime">10:03 PM</p>
                          <div className="profilearrowdiv" />
                        </div>
                        <div className="">
                          <div className="">
                            <p className="snttxtpppcbh">Sent</p>
                          </div>
                          <div className="senncheckdivimg">
                            <img
                              src={cutegirldp}
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='messagedivrightbottomsend'>
                  <div className="messagedivsrchdivflx">
                    <div className='msgicondivallsivtextflx'>
                      <div className='msgicondivallsiv_aklli'>
                        <div className='msgicondivallsiv_akllitilu'>
                          <div className='texticonimgsaa'>
                            <img src={Attach} alt="..." />
                            <div className="inputtypeprfls">
                              <input type="file" id name />
                            </div>
                          </div>
                          <div className="cameraspclarea">
                            <img
                              src={Camera}
                              alt="..."
                            />
                          </div>
                          <div className="micaaspclarea">
                            <img
                              src={Mic}
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className='textardibbrdrdivspclakiltuy'>
                          <div className='textardibbrdrdivcntntdiv'>
                            <div
                              ref={divRef}
                              contentEditable={true}
                              className="textardibbrdrdivmaindiv"
                              style={{
                                maxHeight: '200px',
                                overflowY: 'hidden' // Initial overflow set to hidden
                              }}
                            >
                              <p className='scallabletetxtedivmsg'>
                              {text}
                              </p>
                            </div>
                          </div>
                          <div className='postmessage'>
                            <img src={Post} alt='...' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Message
