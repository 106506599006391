import React, { useEffect, useState } from 'react'
// import profile from "../../src/Images/cute-girl-dp.jpg"
import Attach from "../../src/Images/Icons/PNG/Attach.png";
import Post from "../../src/Images/Icons/PNG/Post.png"
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import { BsEmojiSmileFill } from "react-icons/bs";
import EmojiPicker from 'emoji-picker-react';
import { useDispatch, useSelector } from 'react-redux';
import HttpClientXml from '../utils/HttpClientXml';
import { getAllPosts, getComment } from '../Redux/Slice/PostSlice';
import { useNavigate } from 'react-router-dom';

const EditCommentmodal = ({ closemodal, postId, initialValues }) => {
    const [isEmoji, setIsEmoji] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState("");
    const userData = useSelector((state) => state?.StatusCardSlice?.data);
    const [imageURL, setImageURL] = useState(initialValues?.image);
    const dispatch = useDispatch();
    const [image, setImage] = useState("");
    const [uploading, setUploading] = useState(false);
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);

    let data = {
        postID: postId
    };

    useEffect(() => {
        dispatch(getComment(data))
    }, [postId])

    // image change
    const handleImageChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await HttpClientXml.fileUplode("image-upload", "POST", form);

        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageURL(res?.image);
        } else {
            toast.error("Error uploading image");
        }
        setUploading(false);
    };

    //for editing comment
    const handleEditComment = async (e) => {
        e.preventDefault();
        setShouldValidateOnChange(true);

        let data = {
            postID: postId,
            comment: `${values?.comment} ${selectedEmoji}`,
            image: imageURL
        };

        if (values?.comment || values?.image) {
            let res = await HttpClientXml?.requestData(`update-comment/${initialValues?._id}`, data, "PUT")
            if (res?.status) {
                dispatch(getComment(postId));
                dispatch(getAllPosts());
                setImageURL("");
                closemodal();
            } else {
                toast.error(res?.data?.message || "Error Fetching User Details");
            }
        } else {
            toast.error("No Comment is Added");
        }
    };


    const { values, handleChange, setFieldValue } =
        useFormik({
            initialValues,
            validateOnChange: shouldValidateOnChange,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });

    return (
        <>
            <section className='thopughtmodabgmain'>
                <div className='editcmntmodalbg'>
                    <div className='editetopmodal'>
                        <p className='editetxtppbjdl'>Edit Your Comment</p>
                        <div className='cersgeditpdg' onClick={() => closemodal()}>
                            <i class="fa-solid fa-xmark"></i>
                        </div>

                    </div>
                    <div className='editbottommodal'>
                        <div className='cmntpprfcdivflx'>
                            <div className='profilemcmnrtimg'>
                                {/* <img src={profile} alt='...' /> */}
                                <img src={userData?.image} alt='...' />
                            </div>
                            <div className='cmntscedtdiv'>
                                <form>
                                    <div className='cmnttsttxt'>
                                        <textarea
                                            className=''
                                            name='comment'
                                            value={`${values?.comment}`}
                                            onChange={handleChange}
                                        ></textarea>


                                        <div className='cmntstocnobdivflcpdadad'>
                                            <div className='rcmgdivmai'>
                                                <img src={Attach} alt="..." />
                                                <input
                                                    type="file"
                                                    class="form-control"
                                                    id="hostedBy"
                                                    placeholder="Choose a photo"

                                                    name="image"
                                                    value={image}
                                                    onChange={handleImageChange}

                                                />

                                                {imageURL !== "" && (

                                                    <div style={{
                                                        display: "flex", position: "relative",
                                                        bottom: "0", left: '0', paddingTop: "10px"
                                                    }}>
                                                        <img
                                                            style={{ marginTop: "1rem" }}
                                                            src={imageURL}
                                                            alt=""
                                                            height="200rem"
                                                            width="200rem"
                                                        />
                                                        <div
                                                            onClick={() => {
                                                                setImageURL("");
                                                                setImage('')
                                                            }}
                                                        >
                                                            <i
                                                                class="fa-solid fa-xmark"
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "2rem",
                                                                    cursor: "pointer",
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </div>

                                                )}
                                            </div>

                                            {uploading ? <p style={{
                                                position: 'absolute',
                                                right: 0,
                                                top: '-84%'
                                            }}>image uploading......</p> : null}


                                            <BsEmojiSmileFill className="rcmgdivmai"
                                                onClick={() => setIsEmoji(prev => !prev)}
                                            />
                                            {isEmoji &&
                                                <EmojiPicker
                                                    onEmojiClick={(emojiObject) => setFieldValue("comment", values?.comment + emojiObject?.emoji)}
                                                />
                                            }

                                            <div
                                                className='rcmgdivmai'
                                                type='button'
                                                onClick={handleEditComment}
                                            >
                                                <img src={Post} alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditCommentmodal
