import React from 'react'
// import bullentinimg from "../../Images/bullentinimg.jpg"
import { useLocation } from 'react-router-dom'
import moment from 'moment';
const Bulletinsingle = () => {
    const location = useLocation();
    const bulletindata = location?.state
    return (
        <>
            <section className='bulletinsinglemain'>
                <div className='detailspagecontainer'>
                    <div className='bulletinimgmain'>
                        <img src={bulletindata?.image} alt="..." />
                    </div>
                    <div className='bulletinimgmaindtlstxt'>
                        <div className=''>
                            <p className='builletindtlstsxtpp' >Event Name :
                                <span>
                                    {bulletindata?.eventName}
                                </span>
                            </p>
                        </div>
                        <div className=''>
                            <p className='builletindtlstsxtpp'>Note :
                                <span>
                                    {bulletindata?.addNotes}
                                </span>
                            </p >
                        </div>
                        <div className=''>
                            <p className='builletindtlstsxtpp' >Date :
                                <span>
                                {bulletindata?.eventDate ? moment(bulletindata?.eventDate).format("YYYY-MM-DD") :  moment(bulletindata?.startDate)?.format("YYYY-MM-DD")} 
                                </span>
                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Bulletinsingle
