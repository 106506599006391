import React from 'react'
import EducateStatusCard from './EducateStatusCard'
import Ongoing from './Ongoing'
import Requestedtraining from './Requestedtraining'
import DocumentRepositorymain from './DocumentRepositorymain'
import trainingmain from "../../Images/trainingmain.png"
import clock from "../../Images/Icons/PNG/time.png";
import reward from "../../Images/Icons/PNG/reward.png";
import { Link } from 'react-router-dom';
import Addicon from "../../Images/Icons/PNG/Add icon.png"
const Singlenontech = () => {
  const mandatorydata = [
    {
      image: trainingmain,
      note: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
      writer: "Dr. Angela Yu",

      hour: "1hr 20min",
      rewards: "20"
    },
    {
      image: trainingmain,
      note: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
      writer: "Dr. Angela Yu",

      hour: "1hr 20min",
      rewards: "20"
    },
    {
      image: trainingmain,
      note: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
      writer: "Dr. Angela Yu",

      hour: "1hr 20min",
      rewards: "20"
    },
    {
      image: trainingmain,
      note: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
      writer: "Dr. Angela Yu",

      hour: "1hr 20min",
      rewards: "20"
    },

    {
      image: trainingmain,
      note: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
      writer: "Dr. Angela Yu",

      hour: "1hr 20min",
      rewards: "20"
    },
    {
      image: trainingmain,
      note: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
      writer: "Dr. Angela Yu",

      hour: "1hr 20min",
      rewards: "20"
    },
    {
      image: trainingmain,
      note: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
      writer: "Dr. Angela Yu",

      hour: "1hr 20min",
      rewards: "20"
    },
    {
      image: trainingmain,
      note: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
      writer: "Dr. Angela Yu",

      hour: "1hr 20min",
      rewards: "20"
    },
    {
      image: trainingmain,
      note: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
      writer: "Dr. Angela Yu",

      hour: "1hr 20min",
      rewards: "20"
    },
    {
      image: trainingmain,
      note: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
      writer: "Dr. Angela Yu",

      hour: "1hr 20min",
      rewards: "20"
    },
    {
      image: trainingmain,
      note: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
      writer: "Dr. Angela Yu",

      hour: "1hr 20min",
      rewards: "20"
    },

    {
      image: trainingmain,
      note: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
      writer: "Dr. Angela Yu",

      hour: "1hr 20min",
      rewards: "20"
    },
    {
      image: trainingmain,
      note: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum quidem deleniti perspiciatis inventore pariatur asperiores explicabo harum! Eveniet, aliquid quisquam!",
      writer: "Dr. Angela Yu",

      hour: "1hr 20min",
      rewards: "20"
    },
  ]
  return (
    <>
      <section className='singlemandatorysection'>
        <div className='esgpagecontainer'>
          <div className='singleeducatemainflx'>
            <div className='educatemainleft'>
              <div className='educatemainleftbg'>
                {mandatorydata && mandatorydata.map((item, index) => {
                  return (
                    <div className='mandatorydivbgmain' key={index}>
                      <div className='traingmaindivmain'>
                        <img src={item.image} alt="..." />
                      </div>

                      <div className='descritptoinmadtorydiv'>
                        <p className='mandttxtpp'>
                          {item.note}
                        </p>
                        <p className='writertxtpp'>{item.writer}</p>
                      </div>
                      <div className="imgtxtdivmaindddflx">
                        {/* <div className="imgtxtdivmainddd">
                                    <div className="strimgdiv">
                                        <img
                                            src={star}
                                            alt="..."
                                        />
                                    </div>
                                    <p className="tsgyutppp">{item.ratings}</p>
                                </div> */}
                        <div className="imgtxtdivmainddd">
                          <div className="strimgdiv">
                            <img
                              src={clock}
                              alt="..."
                            />
                          </div>
                          <p className="tsgyutppp">{item.hour}</p>
                        </div>
                        <div className="imgtxtdivmainddd">
                          <div className="strimgfrmt">
                            <img
                              src={reward}
                              alt="..."
                            />
                          </div>
                          <p className="tsgyutppp">{item.rewards}</p>
                        </div>
                      </div>

                    </div>
                  )
                })}
              </div>
            </div>
            <div className='educatemainright'>
              <EducateStatusCard />

              <div className='eductrtraingdiv'>
                <Link className="traioningfnewcreatemaindiv" to="/createtraining" >
                  <div className="addimgdiv">
                    <img
                      src={Addicon}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <p className="crtadvocacytxtypp">Create New Training</p>
                  </div>
                </Link>
              </div>
              <div className=''>
                <Ongoing />
              </div>
              <div className='eductrtraingdiv'>
                <Link className="traioningfnewcreatemaindiv" to="/createtraining" >
                  <div className="addimgdiv">
                    <img
                      src={Addicon}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <p className="crtadvocacytxtypp">Request New Training</p>
                  </div>
                </Link>
              </div>
              
              <div className=''>
                <Requestedtraining />
              </div>
              <div className='eductrtraingdiv'>
                <Link className="traioningfnewcreatemaindiv" to="/createDocumentRepository" >
                  <div className="addimgdiv">
                    <img
                      src={Addicon}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <p className="crtadvocacytxtypp">Create Document Repository</p>
                  </div>
                </Link>
              </div>

              <div className='docmntdivmnan'>
                <DocumentRepositorymain />
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Singlenontech
