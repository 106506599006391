import React from "react";
// import Eventimg2 from "../Images/No-Image-Placeholder.png";
import moment from "moment";
// import { RWebShare } from "react-web-share";
// import table_icon from "../Images/Icons/PNG/share-icon-elite-web.svg"
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
// import repost from "../Images/Repost Icon.png";
import HttpClientXml from "../utils/HttpClientXml";
import { getAllPosts } from "../Redux/Slice/PostSlice";
import repost from "../../src/Images/Icons/PNG/repost.png"
import { getSubscriptionEventData } from "../Redux/Slice/subscriptionEventSlice";

export default function EventsBigCalenderModal({ eventsForSelectedDate, eventsForSelectedDateMore, closemodal }) {
    const dispatch = useDispatch();

    //for joinning event
    const handleJoinClick = async (id) => {
        const data = {
            eventId: id,
        };
        const response = await HttpClientXml?.requestData("join-event", data, "POST");

        if (response.status) {
            toast?.success(response?.message);
            dispatch(getSubscriptionEventData());
        } else {
            toast.error(response?.response?.data?.message);
        }
    }

    //   useEffect(() => {
    //     dispatch(getSubscriptionEventData());
    //   }, [])


    //for Repost event in home page
    const handlePost = async (e, item) => {
        let data = {
            description: `${item?.title} at ${moment(item?.start)?.format("YYYY-MM-DD")}`,
            image: [item?.image],
            postType: "public",
        };
        let response = await HttpClientXml?.requestData("user-add-post", data, "POST");

        if (response?.status) {
            closemodal();
            toast?.success(response?.message);
            dispatch(getAllPosts());
        } else {
            toast?.error(response?.response?.data?.message);
        }

    };

    return (
        <>
            <section className="thopughtmodabgmain">
                <div
                    className="editcmntmodalbg"

                >
                    <div className='editetopmodal'>
                        <p className='editetxtppbjdl'> Joined Event</p>
                        <div className='cersgeditpdg' onClick={() => closemodal()}>
                            <i class="fa-solid fa-xmark"></i>
                        </div>

                    </div>


                    <div
                        className="jointimgdgtxtmain"

                    >
                        <div className="event_imgmfdll">
                            {eventsForSelectedDate?.image ? (
                                <img
                                    src={eventsForSelectedDate?.image}
                                    className="img-fluid"
                                    alt="event"
                                />
                            ) : (

                                <></>
                            )}
                        </div>

                        <div className="event_details">
                            <div>
                                <div className="head">
                                    <p className="evtnttsiohkipopx">
                                    Event Title :
                                        <span>
                                            {eventsForSelectedDate?.eventName}
                                        </span>


                                    </p>
                                </div>
                            </div>

                            <div className="hosted">
                                <p className="evtnttsiohkipopx">
                                    Start :{" "}
                                    <span>
                                        {moment(eventsForSelectedDate?.eventDate)?.format("YYYY-MM-DD")}
                                    </span>
                                </p>
                            </div>


                            <div className="head">
                                <p className="evntShortDesc">
                                    Note :
                                    <span>
                                       {eventsForSelectedDate?.notes}
                                    </span>
                                
                                </p>
                            </div>

                        </div>
                        <div className="shareusereventflx">
                            <div className="shareimghivh"
                                onClick={(e) => handlePost(e, eventsForSelectedDate)}
                            >
                                <img
                                    src={repost}
                                    alt="..." className="shareIconImg"
                                />
                            </div>

                            <div onClick={() => handleJoinClick(eventsForSelectedDate?.id)}>
                                <i class="fa fa-user-plus" aria-hidden="true"></i>
                            </div>
                        </div>

                    </div>



                    {
                        eventsForSelectedDateMore?.map((item, ind) => {
                            return (
                                <>
                                    <div className="bottom" key={ind}>
                                        <div
                                            s
                                            className="card_area"
                                            style={{
                                                background: "#EAEBFB",
                                                position: "relative",
                                                marginTop: "30px",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div className="event_img">
                                                {item?.image ? (
                                                    <img
                                                        src={item?.image}
                                                        className="img-fluid"
                                                        alt="event"
                                                    />
                                                ) : (
                                                    //   <img src={Eventimg2} className="img-fluid" alt="event" />
                                                    <></>
                                                )}
                                            </div>

                                            <div className="event_details">
                                                <div style={{ marginBottom: "9px" }}>
                                                    <div className="head">
                                                        <h2>Event Title : {item?.title}</h2>
                                                    </div>
                                                </div>

                                                <div className="hosted">
                                                    <p>
                                                        Start : {" "}
                                                        {moment(item?.start)?.format("YYYY-MM-DD")}
                                                    </p>
                                                </div>
                                                <div className="hosted">
                                                    <p>
                                                        End : {" "}
                                                        {moment(item?.end)?.format("YYYY-MM-DD")}
                                                    </p>
                                                </div>

                                                <div className="head">
                                                    <p className="evntShortDesc">
                                                        {item?.notes}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="shareusereventflx">



                                                <div className="share"
                                                    onClick={(e) => handlePost(e, item)}
                                                >
                                                    <img
                                                        src={repost}
                                                        alt="..." className="shareIconImg"
                                                    />
                                                </div>

                                                <div onClick={() => handleJoinClick(item?.id)}>
                                                    <i class="fa fa-user-plus" aria-hidden="true"></i>
                                                </div>
                                            </div>



                                        </div>


                                    </div>
                                </>
                            )
                        })
                    }

                </div>
            </section>


        </>
    );
}
