import React, { useEffect, useState } from 'react'
// import docreoiimg from "../../Images/docreoiimg.png"
import Addicon from "../../Images/Icons/PNG/Add icon.png"
import { useDispatch } from 'react-redux';
import HttpClientXml from '../../utils/HttpClientXml';
import { getAffinityGroup } from '../../Redux/Slice/AffinityGroupSlice';
import { useFormik } from 'formik';
import { toast } from "react-hot-toast";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();


const Editteam = () => {
    const params = useParams();
    const location = useLocation();
    const initialValues = location?.state;
    const [imageURL, setImageURL] = useState(initialValues?.image);
    const dispatch = useDispatch();
    const [image, setImage] = useState("");
    const [uploading, setUploading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [colourOptions, setColourOptions] = useState([]);
    const navigate=useNavigate();
    // const [singleView, setSingleView] = useState({});

    useEffect(() => {
        const data = initialValues?.addEmp
        const mentorData = data?.map((item) => {
            const optData = colourOptions?.find(ele => ele?.value === item)
            return optData ? optData : {}
        })
        setSelectedOptions(mentorData)
    }, [colourOptions])


    //single affinity group
    // const viewSingleGroup = async () => {
    //     const response = HttpClientXml?.requestData(`view-single-affinity-group/${params?.id}`, {}, "GET");
    //     if (response.status) {
    //         setSingleView(response?.data);
    //     } else {

    //     }
    // }


    //for image onChange functionality
    const handleImageChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await HttpClientXml.fileUplode("image-upload", "POST", form);

        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageURL(res?.image);
        } else {
            toast.error("Unsupported Image");
        }
        setUploading(false);
    };

    //for selecting functionality employee
    const handleSelectChange = (e) => {
        setSelectedOptions(e);
    };

    //for getting all employees name
    const getEmployeeData = async () => {
        const response = await HttpClientXml?.requestData("view-all-employees", {}, "GET")

        if (response && response.status) {
            const formattedOptions = response?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
            }));

            setColourOptions(formattedOptions);
            setSelectedOptions(formattedOptions?.filter(e => e.value === initialValues?.addEmp))
        } else {

        }
    };

    //Submit Functionality
    const submitHandler = async (e) => {
        e.preventDefault();
        console.log("handleSubmit", values);

        let data = {};
        if (values.groupName === "") {
            return toast.error("All fields required");
        }

        if (values.grouptype === "") {
            return toast.error("All fields required");
        }

        if (values.purpose === "") {
            return toast.error("All fields required");
        }

        if (imageURL === "") {
            return toast.error("All fields required");
        }

        data = {
            groupName: values?.groupName,
            grouptype: values?.grouptype,
            charter: values?.charter,
            purpose: values?.purpose,
            image: imageURL,
            addEmp: selectedOptions?.map((item) => item?.value),
        };
        const res =await HttpClientXml?.requestData(`update-affinity-group/${params?.id}`, data, "PUT");
        if (res?.status) {
            toast.success("Group is Updated Successfully");
            resetForm();
            dispatch(getAffinityGroup());
            setTimeout(() => {
                navigate('/myaffinitygroup')
            }, 1000)
        } else {
            toast.error(res?.message || "Something went wrong");
        }

    };

    const { values, resetForm, handleChange } =
        useFormik({
            initialValues,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });

    useEffect(() => {
        getEmployeeData();
    }, [])
    // useEffect(() => {
    //     viewSingleGroup();
    // }, [params?.id])
    return (
        <>
            <section className='createeventdetails'>
                <div className="detailspagecontainer">
                    <div className="createdivfrmmain">
                        <form>
                            <div className=''>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Group Name <span className='required'>*</span></p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder="Group Name"
                                        className="typstrningwhselctnew"
                                        value={values?.groupName}
                                        name="groupName"
                                        onChange={handleChange}
                                    />

                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Group Type<span className='required'>*</span></p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder="Group Type"
                                        className="typstrningwhselctnew"
                                        value={values?.grouptype}
                                        name="grouptype"
                                        onChange={handleChange}
                                    />

                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Group Charter<span className='required'>*</span></p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter Charter"
                                        className="typstrningwhselctnew"
                                        value={values?.charter}
                                        name="charter"
                                        onChange={handleChange}
                                    />

                                </div>
                                <div className="trainingrifgtdivgappp">
                                    <p className="rsntxrpnewtxt">Group Purpose</p>
                                    <textarea
                                        placeholder='Group Purpose'
                                        id=""
                                        rows={4}
                                        cols={50}
                                        className="txtdivallartadvcacytfnew"
                                        value={values?.purpose}
                                        name="purpose"
                                        onChange={handleChange}
                                    ></textarea>

                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Employee <span className='required'>*</span></p>

                                    {/* <Select
                                        placeholder={"select employees "}
                                        closeMenuOnSelect={true}
                                        className="typstrningwhselctnew"
                                        isMulti
                                        defaultValue={[]}
                                        options={colourOptions}
                                        onChange={handleSelectChange}
                                    /> */}
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        value={selectedOptions}
                                        isMulti
                                        options={colourOptions}
                                        onChange={handleSelectChange}
                                    />
                                </div>

                                <div className="vdoulddivpbupldgnewmmll">
                                    <p className="rsntxrpnewtxt"> Image Upload <span className='required'>*</span></p>

                                    <div className="bgcontainerupldboxsgwgrdiv">
                                        <div className="bgcrd">
                                            <div className="bgcontaineruplddivnew">
                                                <div className="logouplddiv">
                                                    <img
                                                        src={Addicon}
                                                        alt="..."
                                                    />
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            id="hostedBy"
                                                            className="upldimagediv"
                                                            placeholder="Choose a photo"
                                                            value={image}
                                                            accept="image/*"
                                                            name="image"
                                                            onChange={handleImageChange}

                                                        />
                                                    </div>

                                                </div>

                                            </div>

                                            <p className="upldtxtppdiv">Upload min 5 mb image</p>

                                            {/* After Upload Design Start */}
                                            {/* <div className="bgcontainerupldfiledivabslt">
                                                <div className="imageuplddiv">
                                                    <img src={docreoiimg} alt />
                                                    <div className="imageupldcrs" style={{
                                                        cursor: "pointer"
                                                    }}>
                                                        <i className="fa-solid fa-xmark" />
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="bgcontainerupldfiledivabslt">

                                                {imageURL !== "" && (
                                                    <div className="imageuplddiv" >
                                                        <img
                                                            src={imageURL}
                                                            alt="" />
                                                        <div className="clsoedivmainrd"
                                                            onClick={() => {
                                                                setImageURL("");
                                                                setImage("");
                                                            }}>
                                                            <i className="fa-solid fa-xmark"></i>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {/* After Upload Design End */}
                                            {uploading ? <p>image uploading......</p> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="bulletinsbmtbtndivv">
                                <button
                                    className="bulletinsbmtbtn"
                                    type="button"
                                    onClick={submitHandler}
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Editteam
