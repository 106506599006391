import React from 'react'
// import EducateOngoing from "../../View/TrainingPage/EducateOngoing";
import EducateOngoing from "../Educate/EducateOngoing"
import Expand from "../../Images/Icons/PNG/Expand.png";
import video from "../../Images/Icons/PNG/video.png";
import { useNavigate } from 'react-router-dom';
const Ongoing = () => {
    const navigate = useNavigate()
    const handleongoing = () => {
        navigate("/ongoingdetails")
    }
    return (
        <>
            <div className='videoongoing'>
                <div className="top">
                    <div className="head bulleBoardHead">
                        {/* <figure className="bulleBoardFig">
                            <img
                                src={video}
                                alt="..."
                            />
                        </figure> */}
                        <h4>On Going</h4>
                    </div>
                    <div className="add_seeallflx">
                        <div className="tyEvMainDiv">
                            <figure className="bulleBoardFig" onClick={handleongoing}>
                                <img src={Expand} alt="..." />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='bottom'>
                    <EducateOngoing />
                </div>
            </div>
        </>
    )
}

export default Ongoing

