import React, { useState } from 'react'
import imgprlsthgt from "../../Images/imgprlsthgt.jpg";
import Thankyoucard from "../../Images/Thankyoucard.png";
import avatar from "../../Images/avatar.jpg";
import ReactionLike from "../../Images/Icons/PNG/Reaction - Like.png";
import Comment from "../../Images/Icons/PNG/Comment.png";
import ReactionLove from "../../Images/Icons/PNG/Reaction - Love.png";
import ReactionCelebrate from "../../Images/Icons/PNG/Reaction - Celebrate.png";
import ReactionSupport from "../../Images/Icons/PNG/Reaction - Support.png";
import ReationCurious from "../../Images/Icons/PNG/Reation - Curious.png";
import ReationInsightful from "../../Images/Icons/PNG/Reation - Insightful.png";
import ReactionLikee from "../../Images/Icons/PNG/ReactionLike.png";
import Edit from "../../Images/Icons/PNG/Edit.png";
import cancel from "../../Images/Icons/PNG/cancel.png"
import PostEditmodal from '../../Modal/PostEditmodal';
const Profileactivatetab = ({ post }) => {
    const [emojimodal, setEmojimodal] = useState(false);
    const [editdeltepostmodal, setEditdeltepostmodal] = useState(false);
    // const [editemodalprofile, setEditemodalprofile] = useState(false)
    const handlereaction = () => {
        setEmojimodal(false);
    }
    const handleeditdeltepostt = () => {
        setEditdeltepostmodal(!editdeltepostmodal)
    }
    // const editemoadlprofile = () => {

    //     setEditemodalprofile(!editemodalprofile)
    // }
    return (
        <>
            <div className=''>
                <div className='profilevrthdaycardbg'>
                    <div className="topprofilediv">
                        <div className="leftprofilediv">
                            <div className="prflimgshrthgh">
                                <img
                                    src={imgprlsthgt}
                                    alt="..."
                                />
                            </div>
                            <div className="sharedetails">
                                <div className="name">
                                    <h2 className="nametxthh">Simran Agarwal</h2>
                                    <div className="brnztztpp">
                                        <div className="imgbrnz">
                                            <img src="image" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="text">
                                    <p className="emplyrtxtpp">HR ,</p>
                                    <p className="emplyrtxtpp">Posted on 2024-05-20</p>
                                </div>
                            </div>
                        </div>
                        <div className="dotsticondrdtdlytrlrt">
                            <div className="dotsticondrdtdlyt" onClick={handleeditdeltepostt}>
                                <i className="fa-solid fa-ellipsis-vertical" />
                            </div>
                            {editdeltepostmodal && <div className='editdltdivflxmain'>
                                <div className='plismnisn' >
                                    <img src={Edit} alt="..." />
                                </div>
                                <div className='plismnisn'>
                                    <img src={cancel} alt="..." />
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className=''>
                        <div className="allcardsectionbgprofile" style={{ cursor: "pointer" }}>
                            <div
                                className="thankyoucrdbg"
                                style={{ backgroundImage: `url(${Thankyoucard})` }}
                            >
                                {/* <div className="companylitlleimg">
                                    <img
                                        src="/static/media/companyelite.75eb93cd70e56ebd5043.png"
                                        alt="..."
                                    />
                                </div> */}
                                <div className="profilebgthanky">
                                    <div className="profilebgthankyimg">
                                        <img
                                            src={avatar}
                                            alt="..."
                                        />
                                    </div>
                                </div>
                                <div className="thankyubrdrdiv">Thank You</div>
                                <div className="">
                                    <p className="thankyoutxtppcrd">
                                        Thank You for your supports and believe. I look forward to networking
                                        and exploring potential opportunities together
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>

                        <div className='like_comment_areasec'>
                            <div className='leftshare'>
                                <p className='plpotxt'> Reaction</p>
                            </div>
                            <div className='rightshare'>
                                <h6 className='cmntdtxt'>People Commented</h6>
                            </div>
                        </div>
                        <div className='reaction_areasec'>
                            <div className="reactionmainsc">
                                <div className="reactionmainscdivflx" onClick={handlereaction}>
                                    <img
                                        src={ReactionLike}
                                        alt="..."
                                        className="rcmgdivmai"
                                    />
                                    <p className="txtrecppp">Reaction</p>
                                </div>
                                {emojimodal && <div className="reactionicondivflx">
                                    <div className="reactionicon">
                                        <img
                                            src={ReactionLikee}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReactionLove}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReactionSupport}
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReactionCelebrate}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReationCurious}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReationInsightful}
                                        />
                                    </div>
                                </div>}

                            </div>

                            <div className="reactionmainsc">
                                <div className="rcmgdivmai">
                                    <img src={Comment} alt="..." />
                                </div>
                                <p className="txtrecppp">Comments</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='profilevrthdaycardbg'>
                    <div className="topprofilediv">
                        <div className="leftprofilediv">
                            <div className="prflimgshrthgh">
                                <img
                                    src={imgprlsthgt}
                                    alt="..."
                                />
                            </div>
                            <div className="sharedetails">
                                <div className="name">
                                    <h2 className="nametxthh">Simran Agarwal</h2>
                                    <div className="brnztztpp">
                                        <div className="imgbrnz">
                                            <img src="image" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="text">
                                    <p className="emplyrtxtpp">HR ,</p>
                                    <p className="emplyrtxtpp">Posted on 2024-05-20</p>
                                </div>
                            </div>
                        </div>
                        <div className="dotsticondrdtdlytrlrt">
                            <div className="dotsticondrdtdlyt" onClick={handleeditdeltepostt}>
                                <i className="fa-solid fa-ellipsis-vertical" />
                                {editdeltepostmodal && <div className='editdltdivflxmain'>
                                    <div className='plismnisn' >
                                        <img src={Edit} alt="..." />
                                    </div>
                                    <div className='plismnisn'>
                                        <img src={cancel} alt="..." />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="allcardsectionbgprofile" style={{ cursor: "pointer" }}>
                            <div
                                className="thankyoucrdbg"
                                style={{ backgroundImage: `url(${Thankyoucard})` }}
                            >
                                {/* <div className="companylitlleimg">
                                    <img
                                        src="/static/media/companyelite.75eb93cd70e56ebd5043.png"
                                        alt="..."
                                    />
                                </div> */}
                                <div className="profilebgthanky">
                                    <div className="profilebgthankyimg">
                                        <img
                                            src={avatar}
                                            alt="..."
                                        />
                                    </div>
                                </div>
                                <div className="thankyubrdrdiv">Thank You</div>
                                <div className="">
                                    <p className="thankyoutxtppcrd">
                                        Thank You for your supports and believe. I look forward to networking
                                        and exploring potential opportunities together
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>

                        <div className='like_comment_areasec'>
                            <div className='leftshare'>
                                <p className='plpotxt'> Reaction</p>
                            </div>
                            <div className='rightshare'>
                                <h6 className='cmntdtxt'>People Commented</h6>
                            </div>
                        </div>
                        <div className='reaction_areasec'>
                            <div className="reactionmainsc">
                                <div className="reactionmainscdivflx" onClick={handlereaction}>
                                    <img
                                        src={ReactionLike}
                                        alt="..."
                                        className="rcmgdivmai"
                                    />
                                    <p className="txtrecppp">Reaction</p>
                                </div>
                                {emojimodal && <div className="reactionicondivflx">
                                    <div className="reactionicon">
                                        <img
                                            src={ReactionLikee}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReactionLove}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReactionSupport}
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReactionCelebrate}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReationCurious}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReationInsightful}
                                        />
                                    </div>
                                </div>}

                            </div>

                            <div className="reactionmainsc">
                                <div className="rcmgdivmai">
                                    <img src={Comment} alt="..." />
                                </div>
                                <p className="txtrecppp">Comments</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='profilevrthdaycardbg'>
                    <div className="topprofilediv">
                        <div className="leftprofilediv">
                            <div className="prflimgshrthgh">
                                <img
                                    src={imgprlsthgt}
                                    alt="..."
                                />
                            </div>
                            <div className="sharedetails">
                                <div className="name">
                                    <h2 className="nametxthh">Simran Agarwal</h2>
                                    <div className="brnztztpp">
                                        <div className="imgbrnz">
                                            <img src="image" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="text">
                                    <p className="emplyrtxtpp">HR ,</p>
                                    <p className="emplyrtxtpp">Posted on 2024-05-20</p>
                                </div>
                            </div>
                        </div>
                        <div className="dotsticondrdtdlytrlrt">
                            <div className="dotsticondrdtdlyt" onClick={handleeditdeltepostt}>
                                <i className="fa-solid fa-ellipsis-vertical" />
                                {editdeltepostmodal && <div className='editdltdivflxmain'>
                                    <div className='plismnisn' >
                                        <img src={Edit} alt="..." />
                                    </div>
                                    <div className='plismnisn'>
                                        <img src={cancel} alt="..." />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="allcardsectionbgprofile" style={{ cursor: "pointer" }}>
                            <div
                                className="thankyoucrdbg"
                                style={{ backgroundImage: `url(${Thankyoucard})` }}
                            >
                                {/* <div className="companylitlleimg">
                                    <img
                                        src="/static/media/companyelite.75eb93cd70e56ebd5043.png"
                                        alt="..."
                                    />
                                </div> */}
                                <div className="profilebgthanky">
                                    <div className="profilebgthankyimg">
                                        <img
                                            src={avatar}
                                            alt="..."
                                        />
                                    </div>
                                </div>
                                <div className="thankyubrdrdiv">Thank You</div>
                                <div className="">
                                    <p className="thankyoutxtppcrd">
                                        Thank You for your supports and believe. I look forward to networking
                                        and exploring potential opportunities together
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>

                        <div className='like_comment_areasec'>
                            <div className='leftshare'>
                                <p className='plpotxt'> Reaction</p>
                            </div>
                            <div className='rightshare'>
                                <h6 className='cmntdtxt'>People Commented</h6>
                            </div>
                        </div>
                        <div className='reaction_areasec'>
                            <div className="reactionmainsc">
                                <div className="reactionmainscdivflx" onClick={handlereaction}>
                                    <img
                                        src={ReactionLike}
                                        alt="..."
                                        className="rcmgdivmai"
                                    />
                                    <p className="txtrecppp">Reaction</p>
                                </div>
                                {emojimodal && <div className="reactionicondivflx">
                                    <div className="reactionicon">
                                        <img
                                            src={ReactionLikee}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReactionLove}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReactionSupport}
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReactionCelebrate}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReationCurious}
                                            alt="..."
                                        />
                                    </div>
                                    <div className="reactionicon">
                                        <img
                                            src={ReationInsightful}
                                        />
                                    </div>
                                </div>}

                            </div>

                            <div className="reactionmainsc">
                                <div className="rcmgdivmai">
                                    <img src={Comment} alt="..." />
                                </div>
                                <p className="txtrecppp">Comments</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* {editemodalprofile && <PostEditmodal closemodal={setEditemodalprofile} post={post} />} */}
        </>
    )
}

export default Profileactivatetab
