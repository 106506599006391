import React from 'react'
import certificaterightbg from "../../Images/certificaterightbg.png"
const Certificatecomp = () => {
    return (
        <>
            <div className="certificatediv">
                <div className="top">
                    <div className="certificatedivflx">
                        <div className="mandatorydivimgquz">
                            <img src={certificaterightbg} alt="..." />
                        </div>
                        <p className="cmpltquzetxt">Your Certificate</p>
                    </div>
                </div>
                <div className="buttom">
                    <div className="certificatedivimage">
                        <img src={certificaterightbg} alt="..." />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Certificatecomp
