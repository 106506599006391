import React, { useEffect, useState } from 'react'
import "./Modal.css";
import CreateNewPost from "../../src/Images/Icons/PNG/CreateNewPost.png";
import sharethght from "../../src/Images/sharethght.jpg";
import Attach from "../../src/Images/Icons/PNG/Attach.png";
import Post from "../Images/Icons/PNG/Post.png"
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAffinityGroup } from '../Redux/Slice/AffinityGroupSlice';
import HttpClientXml from '../utils/HttpClientXml';
import toast from 'react-hot-toast';
import { addPosts, getAllPosts, updatePostsLocally } from '../Redux/Slice/PostSlice';
import { BsEmojiSmileFill } from "react-icons/bs";
import EmojiPicker from "emoji-picker-react";
import ProfileImg1 from "../../src/Images/girl.png";

const Sharethoughtmodal = ({ closemodal }) => {
    const userData = useSelector((state) => state?.StatusCardSlice?.data);
    const [image, setImage] = useState("");
    const [uploading, setUploading] = useState(false);
    const [sharePublicly, setSharePublicly] = useState("publicly");
    const [showAffinityDropdown, setShowAffinityDropdown] = useState(false);
    const [groupid, setGroupId] = useState("");
    const [isEmoji, setIsEmoji] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isChecked, setChecked] = useState(false);
    const grpData = useSelector((state) => state?.AffinityGroupSlice?.mygroup)
    const [url, setUrl] = useState({});

    const [post, setPost] = useState({
        description: "",
        image: [],
        video: [],
        descType: "text"
    });

    // Function to check if text contains a URL
    const containsURL = (text) => {
        const urlPattern = new RegExp('https?://[^\s/$.?#].[^\s]*', 'i');
        return urlPattern.test(text);
    };


    // Update descType based on the description content
    useEffect(() => {
        if (containsURL(post.description)) {
            setPost((prev) => ({ ...prev, descType: "link" }));
        } else {
            setPost((prev) => ({ ...prev, descType: "text" }));
        }
        getUrl();
    }, [post.description]);


    //for getting url details 
    const getUrl = async () => {
        let data = {
            url: post.description
        };
        let response = await HttpClientXml?.requestData("get-url-data", data, "POST");
        if (response?.status) {
            setUrl(response?.data);
        }
    }

    const handleemojioffclick = () => {
        setIsEmoji(false)
    }

    // const [tgleon, setTgleon] = useState(false)
    // const tggleclick = () => [
    //     setTgleon(!tgleon)
    // ]

    //for images upload
    const handleImageChange = async (e) => {
        let files = Array.from(e.target.files);
        console.log("files", files)
        let imageArray = [];
        let videoArray = [];
        setUploading(true);

        for (let i = 0; i < files.length; i++) {
            const form = new FormData();
            form.append("image", files[i]);

            // Check if the file is an image
            if (files[i].type.startsWith('image/')) {
                let res = await HttpClientXml.fileUplode("image-upload", "POST", form);
                if (res?.status) {
                    toast?.success("Image uploaded successfully");
                    imageArray?.push(res?.image);
                } else {
                    toast?.error("Unsupported image");
                }
            }
            // Check if the file is a video
            else if (files[i]?.type?.startsWith('video/')) {
                let res = await HttpClientXml.fileUplode("image-upload", "POST", form);
                if (res?.status) {
                    toast?.success("Video uploaded successfully");
                    videoArray?.push(res?.image);
                } else {
                    toast?.error("Unsupported video");
                }
            }
            else {
                toast.error("Unsupported file type");
            }
        }

        // Update state with uploaded images and videos
        setPost({ ...post, image: imageArray, video: videoArray });
        setUploading(false);
    };

    //for remove image or video
    const removeImage = (index, type) => {
        if (type === 'image') {
            let images = [...post.image];
            images.splice(index, 1);
            setPost({ ...post, image: images });
        } else if (type === 'video') {
            let videos = [...post.video];
            videos.splice(index, 1);
            setPost({ ...post, video: videos });
        }
    };

    //for onChange functionality
    const handleChange = (e) => {
        e.preventDefault();
        setPost(prev => ({ ...prev, [e.target.name]: e.target.value }));
    };

    // Handle emoji click
    const handleEmojiClick = (emojiObject) => {
        const emoji = emojiObject.emoji;
        setPost((prevPost) => ({
            ...prevPost,
            description: prevPost.description + emoji,
        }));
    };


    // Modify handlePost function to dispatch addPosts action conditionally
    const handlePost = async (e) => {
        e.preventDefault();
        if (post.description === "" && post?.image?.length === 0 && post?.video?.length === 0) {
            toast.error("Please enter description or add image or video!");
            return;
        }

        if (sharePublicly === "publicly") {
            dispatch(addPosts({ ...post, postType: "public" }))
                .then((response) => {
                    toast?.success("Posted Successfully");
                    // setPost(prevData => [post,...prevData ]);
                    // dispatch(updatePostsLocally({ ...post, postType: "public" }));
                    dispatch(getAllPosts());
                    setUploading(false);
                    closemodal();
                })
                .catch((error) => {
                    toast.error("Can't Post. Something went wrong");
                    setUploading(false);
                });
        } else if (sharePublicly === "affinity") {
            if (!groupid) {
                toast.error("Please select an affinity group!");
                return;
            }

            dispatch(addPosts({ ...post, postType: "private", affinityGroupId: groupid }))
                .then((response) => {
                    toast.success("Posted Successfully");
                    dispatch(updatePostsLocally({ ...post, postType: "private", affinityGroupId: groupid }));
                    setUploading(false);
                    closemodal();
                    navigate(`/team/${groupid}`);

                })
                .catch((error) => {
                    toast.error("Can't Post. Something went wrong");
                    setUploading(false);
                });
        }
    };


    //toggle part
    const handleclicktggle = () => {
        setChecked(!isChecked);
        if (!isChecked) {
            setSharePublicly("affinity");
            setShowAffinityDropdown(true);
        } else {
            setSharePublicly("publicly");
            setShowAffinityDropdown(false);
        }
    };

    const handlestopClose = (e) => {
        e.stopPropagation();
    }
    const handleset = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        dispatch(getAffinityGroup());
    }, [])
    return (
        <>
            <section className='thopughtmodabgmain'
                onClick={() => {
                    closemodal()
                    setIsEmoji(false)
                }}>
                <div className='thopughtmodabblck' onClick={(e) => handleset(e)}>
                    <div className='thoyghttop'>
                        <div className='crtnewpost'>
                            <img src={CreateNewPost} alt="..." />

                        </div>
                        <div className=''>
                            <p className='crtpsttxty'>Create New Post</p>
                        </div>
                        <div className='crossiconmofdl' onClick={() => closemodal()}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    <div className='thoghtmid'>
                        <div className='thghtimgmainimhgbrdr'>
                            <div className='thghtimgmainimhg'>
                                {/* <img src={sharethght} alt="..." /> */}
                                {userData?.image ? (
                                    <img
                                        src={userData?.image}
                                        alt="profile"
                                    />
                                ) : (
                                    <img
                                        src={ProfileImg1}
                                        alt="profile"
                                    />
                                )}
                            </div>
                        </div>
                        <div className='textshthoght' onClick={handleemojioffclick}>
                            <form>
                                <textarea
                                    id=""
                                    name="description"
                                    value={post?.description}
                                    rows="4"
                                    cols="10"
                                    placeholder='Share Moments That Matters...'
                                    onChange={(e) => handleChange(e)}
                                ></textarea>

                                {
                                    url?.urlSiteName && (
                                        <>
                                            <div className='yutubeyrlsdivimgmaindiv'>
                                                <div className='yutubeyrlsdivimg'>
                                                    <img src={url?.urlImage} alt="" />
                                                </div>
                                            </div>

                                            <div className='frmdivmainyuoutuber'>
                                                <p className='fromnsyiuybusnm'> <span> From : </span>  {url?.urlSiteName}</p>
                                                <p className='fromnsyiuybusnm'>{url?.urlTitle}</p>
                                            </div>
                                           
                                        </>
                                    )
                                }

                                {uploading ? (
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        {/* <Loader /> */}
                                        image loading...
                                    </div>
                                ) : (
                                    <div className="prt_img_flx">
                                        {post?.image &&
                                            post?.image?.map((item, index) => {
                                                return (
                                                    <div className="prt_img" key={index}>
                                                        <img src={item} alt="" />

                                                        <div className="close_icon_img">
                                                            <i
                                                                class="fa-solid fa-xmark"
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "24px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => removeImage(index, 'image')}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                    </div>
                                )}


                                {uploading ? (
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        {/* <Loader /> */}
                                        video uploading...
                                    </div>
                                ) : (
                                    <div className="prt_img_flx">
                                        {post?.video &&
                                            post?.video?.map((item, index) => {
                                                return (
                                                    <div className="prt_img" key={index}>
                                                        <video src={item} style={{ width: "90px", height: "90px" }} alt="" />

                                                        <div className="close_icon_img">
                                                            <i
                                                                class="fa-solid fa-xmark"
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "24px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => removeImage(index, 'video')}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                    </div>
                                )}
                            </form>



                        </div>
                    </div>
                    <div className='thoghtbtm'>
                        <div className='swictvchpublickdiv'>
                            <div className='switch'
                                // onClick={tggleclick}
                                onClick={handleclicktggle}
                            >
                                <span className={
                                    isChecked ? "sliderown actv" : "sliderown"}
                                // tgleon ? "sliderown actv" : "sliderown"
                                >
                                </span>
                            </div>
                            <p className="afntytxtpp">{isChecked ? ' Share Affinity Group' : 'Share publicly'}</p>

                            <div>

                                {showAffinityDropdown && (
                                    <div className="emplListDiv">
                                        {/* <p className="empListSubHead">Groups Name</p> */}
                                        <select
                                            class="empListSelectdiv"
                                            aria-label="Select Employee name"
                                            name="groupid"
                                            value={groupid}

                                            onChange={(e) => setGroupId(e.target.value)}
                                        >
                                            <option value={""} disabled>
                                                Select Group
                                            </option>
                                            {grpData?.map((item, i) => (
                                                <option key={i} value={item?._id}>
                                                    {item?.groupName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}


                            </div>
                        </div>
                        <div className='allcicnsgrtdivflx'>
                            <div className='attachshnimgync'>
                                <img src={Attach} alt="..." />
                                <div>
                                    <input
                                        id=""
                                        type="file"
                                        value={image}
                                        onChange={handleImageChange}
                                        style={{ cursor: "pointer" }}
                                    />
                                </div>
                            </div>
                            <div className='attachshnimgync'>
                                {/* <img src={Attach} alt="..." /> */}

                                <div className="bsimgicon" onClick={(e) => handlestopClose(e)} >
                                    <BsEmojiSmileFill
                                        className="comment-emoji-icon"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setIsEmoji((prev) => !prev)

                                        }}
                                    />
                                    {isEmoji && (
                                        <EmojiPicker
                                            onEmojiClick={handleEmojiClick}
                                        />

                                    )}

                                </div>

                            </div>
                            <div className='attachshnimgync'>
                                <img src={Post} alt="..." onClick={(e) => handlePost(e)} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sharethoughtmodal
