import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { BsEmojiSmileFill } from "react-icons/bs";
import EmojiPicker from 'emoji-picker-react';
import post from "../../src/Images/Icons/PNG/Post.png";
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import moment from 'moment';
import HttpClientXml from '../utils/HttpClientXml';
import { getAllPosts } from '../Redux/Slice/PostSlice';
import ProfileImg1 from "../../src/Images/girl.png";


const EditRepostModal = ({ closemodal, imagesArray, repostData }) => {
    const userData = useSelector((state) => state?.StatusCardSlice?.data);
    const [isEmoji, setIsEmoji] = useState(false);
    const [text, setText] = useState(repostData?.text);
    const dispatch = useDispatch();

    //for  repost in home page
    const handlePost = async () => {
        let data = {
            text: text,
        }
        // console.log("repost",response)
        // return false;
        let response = await HttpClientXml?.requestData(`edit-repost/${imagesArray?._id}`, data, "POST");
        if (response?.status) {
            setText("");
            closemodal();
            toast.success(response?.msg);
            dispatch(getAllPosts());
        } else {
            toast.error(response?.msg);
        }
    }


    return (
        <>

            <div className='thopughtmodabgmain'>
                <div className='editcmntmodalbg'>

                    <div className='editetopmodal'>
                        <p className='editetxtppbjdl'>Edit Repost</p>
                        <div className='cersgeditpdg' onClick={() => closemodal()}>
                            <i class="fa-solid fa-xmark"></i>
                        </div>

                    </div>



                    <div className='cmntpprfcdivflxedtjb'>
                        <div className='cmntpprfcdivflxsigfghbvh'>
                            <div className='profilemcmnrtimg'>
                                {/* <img src={profile} alt='...' /> */}

                                {userData?.image ? (
                                    <img
                                        src={userData?.image}
                                        alt="profile"
                                    />
                                ) : (
                                    <img
                                        src={ProfileImg1}
                                        alt="profile"
                                    />
                                )}

                            </div>

                            <div className='cmntscedtdiv'>
                                <form>
                                    <div className='cmnttsttxt'>
                                        <textarea
                                            className=''
                                            name='text'
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                        ></textarea>
                                        <div className='cmntstocnobdivflcpdadad'>

                                            <BsEmojiSmileFill className="comment-emoji-icon"
                                                onClick={() => setIsEmoji(prev => !prev)}
                                            />
                                            {isEmoji &&
                                                <EmojiPicker
                                                    onEmojiClick={(emojiObject) => setText(prev => prev + emojiObject.emoji)}
                                                />
                                            }

                                            <div className='rcmgdivmai' onClick={handlePost}>
                                                <img src={post} alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>



                    <div className='topdivrepstdiv'>
                        <div className='left'>
                            <div className='prflimgshrthgh'>
                                <img src={imagesArray?.postId?.[0]?.userData?.[0]?.image} alt="..." />
                            </div>
                            <div className='sharedetails'>
                                <div className='name'>
                                    <h2 className='nametxthh'>{imagesArray?.postId?.[0]?.userData?.[0]?.firstName + " " + imagesArray?.postId?.[0]?.userData?.[0]?.lastName}</h2>
                                    <div className='brnztztpp'>
                                        <div className='imgbrnz'>

                                            {
                                                imagesArray?.postId?.[0]?.userData?.[0]?.rewardCard?.[0]?.cardImage
                                                    ?
                                                    <img src={imagesArray?.postId?.[0]?.userData?.[0]?.rewardCard?.[0]?.cardImage} alt='...' />
                                                    :
                                                    <></>
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className='text'>
                                    <p className='emplyrtxtpp'>{imagesArray?.postId?.[0]?.type} ,</p>
                                    <p className='emplyrtxtpp'>Posted on {moment(imagesArray?.createdOn)?.format("YYYY-MM-DD")}</p>
                                </div>

                            </div>
                        </div>

                    </div>


                    <div className="">
                        <div className="content"
                            style={{ cursor: "pointer" }
                            }
                        >
                            <div className='top_nextsedipsg' style={{ cursor: "pointer" }}>
                                <p className='lrmtxtpptxtpp'> {imagesArray?.postId?.[0]?.description}</p>
                            </div>
                        </div>

                        <div className="gallery_areareptsdiv">

                            {
                                imagesArray?.postId?.[0]?.image?.[0]
                                    ?

                                    <div className='sharethghtsimgrepdygd'>
                                        <img src={imagesArray?.postId?.[0]?.image} alt="..." />
                                    </div>

                                    :
                                    <></>
                            }


                            {
                                imagesArray?.postId?.[0]?.video?.[0] ? (<div className='trainingdivmainedt' >
                                    <ReactPlayer
                                        className='reactdivimgmainplrt'
                                        controls
                                        width='40rem'
                                        height='20rem'
                                        playing={false}
                                        url={imagesArray?.postId?.[0]?.video}
                                        config={{
                                            youtube: {
                                                playerVars: { showinfo: 1 }
                                            },
                                            facebook: {
                                                appId: '12345'
                                            },
                                        }}

                                    />
                                </div>) : (<></>)
                            }
                            <div>



                            </div>

                        </div>

                    </div>

                </div>

            </div >
        </>
    )
}

export default EditRepostModal