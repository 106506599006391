import React, { useEffect, useState } from 'react'
import HttpClientXml from '../utils/HttpClientXml';

const Principleonetemp = () => {
    const [principleOneData, setPrincipleOneData] = useState({});

    //for getting principleone
    const getPrincipleOne = async () => {
        const res = await HttpClientXml?.requestData(`get-principle`, {}, "GET");
        if (res?.status) {
            setPrincipleOneData(res?.data?.[0]);
        }
    }

    useEffect(() => {
        getPrincipleOne();
    }, [])

    // console.log("principleOneData", principleOneData)
    return (
        <>
            <section className style={{ width: '100%', maxWidth: '80%', margin: 'auto' }}>

                <div style={{ border: '1px solid #fff', boxShadow: '0px 0px 5px 0px #ccc', padding: '30px 60px' }}>
                    <div>
                        <h4 style={{ fontSize: '20px', color: '#000', fontWeight: 600, lineHeight: '25px', fontFamily: 'Noto Sans, sans-serif', margin: 0 }}>
                            PRINCIPLE 1 : Businesses should conduct and govern themselves with
                            integrity, and in a manner that is Ethical, Transparent and
                            Accountable.
                        </h4>
                    </div>
                    <div style={{ border: '1px solid #000000a1', margin: '30px 0px' }}>
                        <p style={{ fontSize: "16px", color: '#000', fontWeight: 600, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', borderBottom: '1px solid #000', padding: "5px", margin: 0 }}> Essential Indicators</p>

                        <ul style={{ padding: '10px 15px', listStyleType: 'decimal' }}>
                            <div style={{ padding: '10px 0px' }}>
                                <li style={{ fontSize: "17px", color: '#000', fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', marginLeft: "30px", marginBottom: "15px" }}>
                                    Percentage coverage by training and awareness programmes on any of the Principles
                                    during the financial year:
                                </li>
                                <table style={{ border: '1px solid #000', padding: "10px", borderCollapse: 'collapse', width: '100%' }}>
                                    <tbody><tr>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif' }}>Segment </th>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif' }}>Total number of
                                            training and
                                            awareness
                                            programmes held
                                        </th>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif' }}>Topics /
                                            principles
                                            covered under
                                            the training and
                                            its impact
                                        </th>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif' }}>
                                            %age of persons in
                                            respective category
                                            covered by the
                                            awareness
                                            programmes
                                        </th>
                                    </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>Board of
                                                Directors
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                <textarea
                                                    id
                                                    name
                                                    rows={2}
                                                    cols={10}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff' }}
                                                    value={principleOneData?.principleOne?.essentialIndicators?.awarenessProgrammesDetails?.boardOfDirectors?.totalNo}
                                                />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                <textarea
                                                    id
                                                    name
                                                    rows={2} cols={10}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', height: "60px" }}
                                                    value={principleOneData?.principleOne?.essentialIndicators?.awarenessProgrammesDetails?.boardOfDirectors?.topics}

                                                />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif' }}>
                                                <textarea
                                                    id
                                                    name
                                                    rows={2} cols={10}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', height: "60px", fontFamily: 'Noto Sans, sans-serif', color: '#000' }}
                                                    value={principleOneData?.principleOne?.essentialIndicators?.awarenessProgrammesDetails?.boardOfDirectors?.ageOfPercentage}

                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', margin: 0 }}>Key
                                                Managerial
                                                Personne
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', margin: 0 }}>
                                                <textarea
                                                    id
                                                    name
                                                    rows={2} cols={10}
                                                    value={principleOneData?.principleOne?.essentialIndicators?.awarenessProgrammesDetails?.keyManagerialPersonnel?.totalNo}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', margin: 0 }}>
                                                <textarea
                                                    id
                                                    name
                                                    rows={2} cols={10}
                                                    value={principleOneData?.principleOne?.essentialIndicators?.awarenessProgrammesDetails?.keyManagerialPersonnel?.topics}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                <textarea
                                                    id
                                                    name
                                                    rows={2} cols={10}
                                                    value={principleOneData?.principleOne?.essentialIndicators?.awarenessProgrammesDetails?.keyManagerialPersonnel?.ageOfPercentage}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>Employees
                                                other than
                                                BoD and
                                                KMPs
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                <textarea
                                                    id
                                                    name
                                                    rows={2} cols={10}
                                                    value={principleOneData?.principleOne?.essentialIndicators?.awarenessProgrammesDetails?.employeesBodAndKMP?.totalNo}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff' }}
                                                />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                <textarea
                                                    id
                                                    name
                                                    rows={2} cols={10}
                                                    value={principleOneData?.principleOne?.essentialIndicators?.awarenessProgrammesDetails?.employeesBodAndKMP?.topics}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                <textarea
                                                    id name
                                                    rows={2} cols={10}
                                                    value={principleOneData?.principleOne?.essentialIndicators?.awarenessProgrammesDetails?.employeesBodAndKMP?.ageOfPercentage}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>Workers
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                <textarea
                                                    id name
                                                    rows={2} cols={10}
                                                    value={principleOneData?.principleOne?.essentialIndicators?.awarenessProgrammesDetails?.workers?.totalNo}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }}
                                                />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                <textarea
                                                    id name
                                                    rows={2} cols={10}
                                                    value={principleOneData?.principleOne?.essentialIndicators?.awarenessProgrammesDetails?.workers?.topics}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                <textarea
                                                    id name rows={2} cols={10}
                                                    value={principleOneData?.principleOne?.essentialIndicators?.awarenessProgrammesDetails?.workers?.ageOfPercentage}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                    </tbody></table>
                            </div>
                            <div style={{ padding: '10px 0px' }}>
                                <li style={{ padding: "17px", color: '#000', fontSize: "17px", fontWeight: 400, fontFamily: 'poppinslight', marginLeft: 30, marginBottom: 15 }}>
                                    Details of fines / penalties /punishment/ award/ compounding fees/ settlement amount
                                    paid in proceedings (by the entity or by directors / KMPs) with regulators/ law
                                    enforcement agencies/ judicial institutions, in the financial year, in the following
                                    format
                                    (Note: the entity shall make disclosures on the basis of materiality as specified in
                                    Regulation 30
                                    of SEBI (Listing Obligations and Disclosure Obligations) Regulations, 2015 and as
                                    disclosed on
                                    the entity’s website):
                                </li>
                                <div>
                                    <div>
                                        <div style={{ borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000' }}>
                                            <p style={{ textAlign: 'center', fontSize: "15px", color: '#000', fontSize: "18px", fontWeight: 800, fontFamily: 'notosans', margin: 0 }}>Monitory</p>
                                        </div>
                                        <table style={{ border: '1px solid #000', padding: "10px", borderCollapse: 'collapse', width: '100%' }}>
                                            <tbody><tr>
                                                <th style={{ border: '1px solid #000', fontSize: "15px", padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0 }}>
                                                </th>
                                                <th style={{ border: '1px solid #000', fontSize: "15px", padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "17px" }}>NGRBC
                                                    Principle
                                                </th>
                                                <th style={{ border: '1px solid #000', fontSize: "15px", padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "17px" }}>
                                                    Name
                                                    of the
                                                    regulatory / <br></br>
                                                    enforcement
                                                    agencies/<br></br>
                                                    judicial
                                                    institutions
                                                </th>
                                                <th style={{ border: '1px solid #000', fontSize: "15px", padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "17px" }}>Amount
                                                    (In
                                                    INR)
                                                </th>
                                                <th style={{ border: '1px solid #000', fontSize: "15px", padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "17px" }}>
                                                    Brief of the Case
                                                </th>
                                                <th style={{ border: '1px solid #000', fontSize: "15px", padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "17px" }}>
                                                    Has an
                                                    appeal <br></br>
                                                    been <br></br>
                                                    preferred? <br></br>
                                                    (Yes/No)
                                                </th>
                                            </tr>
                                                <tr>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>Penalty/ Fine
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea
                                                            id name
                                                            rows={2} cols={10}
                                                            value={principleOneData?.principleOne?.essentialIndicators?.monetary?.penalty?.ngrbcPrinciple}
                                                            style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name 
                                                        rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.monetary?.penalty?.name}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name 
                                                        rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.monetary?.penalty?.amountInINR}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name 
                                                        rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.monetary?.penalty?.brief}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name 
                                                        rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.monetary?.penalty?.hasAppeal}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>Settlement
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name 
                                                        rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.monetary?.settlement?.ngrbcPrinciple}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.monetary?.settlement?.name}
                                                        rows={2} cols={10} 
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name
                                                        rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.monetary?.settlement?.amountInINR}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name 
                                                        rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.monetary?.settlement?.brief}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name rows={2} 
                                                        cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.monetary?.settlement?.hasAppeal}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>Compounding
                                                        fee
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name 
                                                        rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.monetary?.compoundingFee?.ngrbcPrinciple}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name 
                                                        rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.monetary?.compoundingFee?.name}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name 
                                                        rows={2} cols={10}
                                                        value={principleOneData?.principleOne?.essentialIndicators?.monetary?.compoundingFee?.amountInINR}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name 
                                                        rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.monetary?.compoundingFee?.brief}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name 
                                                        rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.monetary?.compoundingFee?.hasAppeal}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </div>
                                    <div>
                                        <div style={{ borderLeft: '1px solid #000', borderRight: '1px solid #000' }}>
                                            <p style={{ textAlign: 'center', color: '#000', fontSize: "15px", fontWeight: 800, fontFamily: 'notosans', margin: 0 }}>Non Monitory</p>
                                        </div>
                                        <table style={{ border: '1px solid #000', padding: "10px", borderCollapse: 'collapse', width: '100%' }}>
                                            <tbody><tr>
                                                <th style={{ border: '1px solid #000', padding: "10px", fontSize: "15px", lineHeight: "17px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0 }}>
                                                </th>
                                                <th style={{ border: '1px solid #000', padding: "10px", fontSize: "15px", lineHeight: "17px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>NGRBC
                                                    <br></br>
                                                    Principle
                                                </th>
                                                <th style={{ border: '1px solid #000', padding: "10px", fontSize: "15px", lineHeight: "17px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>Name
                                                    of the
                                                    regulatory/ <br></br>
                                                    enforcement
                                                    agencies/ <br></br>
                                                    judicial
                                                    institutions
                                                </th>
                                                <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", lineHeight: "17px", fontSize: "15px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>Amount
                                                    (In
                                                    INR)
                                                </th>
                                                <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", lineHeight: "17px", fontSize: "15px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>
                                                    Brief of the Case
                                                </th>
                                                <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", lineHeight: "17px", fontSize: "15px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>
                                                    Has an
                                                    appeal  <br></br>
                                                    been
                                                    preferred?  <br></br>
                                                    (Yes/No)
                                                </th>
                                            </tr>
                                                <tr>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>Imprisonment
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.nonMonetary?.imprisonment?.ngrbcPrinciple}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.nonMonetary?.imprisonment?.name}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.nonMonetary?.imprisonment?.amountInINR}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name 
                                                        rows={2}
                                                        cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.nonMonetary?.imprisonment?.brief}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name 
                                                        rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.nonMonetary?.imprisonment?.hasAppeal}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>Punishment
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.nonMonetary?.punishment?.ngrbcPrinciple}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.nonMonetary?.punishment?.name}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.nonMonetary?.punishment?.amountInINR}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.nonMonetary?.punishment?.brief}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                    <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                        <textarea 
                                                        id name rows={2} cols={10} 
                                                        value={principleOneData?.principleOne?.essentialIndicators?.nonMonetary?.punishment?.hasAppeal}
                                                        style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: '10px 0px' }}>
                                <li style={{ padding: "17px", color: '#000', fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', marginLeft: 30, marginBottom: 15 }}>
                                    Of the instances disclosed in Question 2 above, details of the Appeal/ Revision
                                    preferred in cases where monetary or non-monetary action has been appealed.
                                </li>
                                <table style={{ border: '1px solid #000', padding: "10px", borderCollapse: 'collapse', width: '100%' }}>
                                    <tbody><tr>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>Case Details </th>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>Name of the regulatory/ enforcement
                                            agencies/ judicial institutions
                                        </th>
                                    </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.actionOfMonetaryAndNonMonetary?.caseDetails}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.actionOfMonetaryAndNonMonetary?.name}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                    </tbody></table>
                            </div>
                            <div style={{ padding: '10px 0px' }}>
                                <li style={{ padding: "17px", color: '#000', fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', marginLeft: 30, marginBottom: 15 }}>
                                    Does the entity have an anti-corruption or anti-bribery policy? <span style={{ fontWeight: 'bold' }}>
                                        If yes,
                                    </span> provide details in
                                    brief and if available, provide a web-link to the policy.
                                </li>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <input type="radio" id name 
                                                    checked={principleOneData?.principleOne?.essentialIndicators?.entityDetails?.status === "Yes"}

                                                />
                                                <label htmlfor style={{ fontSize: 20, lineHeight: 'normal', fontWeight: 700, fontFamily: 'noirproregular', marginLeft: 5 }}>Yes</label>
                                            </div>
                                            <div>
                                                <input type="radio" id name 
                                                    checked={principleOneData?.principleOne?.essentialIndicators?.entityDetails?.status === "Active"}
                                                />
                                                <label htmlfor style={{ fontSize: 20, lineHeight: 'normal', fontWeight: 700, fontFamily: 'noirproregular', marginLeft: 5 }}>Available</label>
                                            </div>
                                            <div>
                                                <textarea 
                                                id name rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.entityDetails?.description}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', margin: '10px 0px 0px 0px', color: '#000', boxShadow: '0px 0px 5px 0px #ccc' }} defaultValue={"\n                                            "} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: '10px 0px' }}>
                                <li style={{ padding: "17px", color: '#000', fontWeight: 400, fontFamily: 'poppinslight', marginLeft: 30, marginBottom: 15 }}>
                                    Number of Directors/KMPs/employees/workers against whom disciplinary action was
                                    taken by any law enforcement agency for the charges of bribery/ corruption:
                                </li>
                                <table style={{ border: '1px solid #000', padding: "10px", borderCollapse: 'collapse', width: '100%' }}>
                                    <tbody><tr>
                                        <th style={{ borderRight: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', borderBottom: '1px solid #000', margin: 0, lineHeight: "19px" }}> </th>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>FY _____
                                            (Current Financial
                                            Year)
                                        </th>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>FY _____
                                            (Previous Financial Year)
                                        </th>
                                    </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                Directors
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.disciplinaryActionDetails?.directors?.currentYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.disciplinaryActionDetails?.directors?.previousYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>KMPs
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.disciplinaryActionDetails?.kmps?.currentYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10}
                                                value={principleOneData?.principleOne?.essentialIndicators?.disciplinaryActionDetails?.kmps?.previousYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>Employees
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.disciplinaryActionDetails?.employees?.currentYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.disciplinaryActionDetails?.employees?.previousYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>Workers
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.disciplinaryActionDetails?.workers?.currentYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.disciplinaryActionDetails?.workers?.previousYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                    </tbody></table>
                            </div>
                            <div style={{ padding: '10px 0px' }}>
                                <li style={{ padding: "17px", color: '#000', fontWeight: 400, fontFamily: 'poppinslight', marginLeft: 30, marginBottom: 15 }}>
                                    Details of complaints with regard to conflict of interest:
                                </li>
                                <table style={{ border: '1px solid #000', padding: "10px", borderCollapse: 'collapse', width: '100%' }}>
                                    <tbody><tr>
                                        <th style={{
                                            borderRight: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', /* borderBottom: '1px solid #000', */
                                            margin: 0, lineHeight: "19px"
                                        }}>
                                        </th>
                                        <th colSpan={2} style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>FY _____
                                            (Current Financial Year)
                                        </th>
                                        <th colSpan={2} style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>FY _____
                                            (Previous Financial Year)
                                        </th>
                                    </tr>
                                        <tr>
                                            <th> </th>
                                            <th colSpan={1} style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>
                                                Numbers
                                            </th>
                                            <th colSpan={1} style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>
                                                Remarks
                                            </th>
                                            <th colSpan={1} style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>
                                                Numbers
                                            </th>
                                            <th colSpan={1} style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>
                                                Remarks
                                            </th>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Number of
                                                complaints received
                                                in relation to issues
                                                of Conflict of Interest
                                                of the Directors
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.conflictOfInterestDetails?.directorsNoOfComplaints?.currentYear?.number}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', height: "60px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                <textarea 
                                                 name 
                                                 rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.conflictOfInterestDetails?.directorsNoOfComplaints?.currentYear?.remarks}
                                                 style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', height: "60px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.conflictOfInterestDetails?.directorsNoOfComplaints?.previousYear?.number}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.conflictOfInterestDetails?.directorsNoOfComplaints?.previousYear?.remarks}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Number of
                                                complaints received
                                                in relation to issues
                                                of Conflict of Interest
                                                of the KMPs
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.conflictOfInterestDetails?.kmpsNoOfComplaints?.currentYear?.number}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.conflictOfInterestDetails?.kmpsNoOfComplaints?.currentYear?.remarks}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.conflictOfInterestDetails?.kmpsNoOfComplaints?.previousYear?.number}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.conflictOfInterestDetails?.kmpsNoOfComplaints?.previousYear?.remarks}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                    </tbody></table>
                            </div>
                            <div style={{ padding: '10px 0px' }}>
                                <li style={{ padding: "17px", color: '#000', fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', marginLeft: 30, marginBottom: 15 }}>
                                    Provide details of any corrective action taken or underway on issues related to fines /
                                    penalties / action taken by regulators/ law enforcement agencies/ judicial institutions,
                                    on cases of corruption and conflicts of interest.
                                </li>
                                <div>
                                    <textarea 
                                    id name 
                                    rows={2} cols={10} 
                                    value={principleOneData?.principleOne?.essentialIndicators?.provideActionDetails}
                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', margin: '10px 0px 0px 0px', color: '#000', height: "60px", boxShadow: '0px 0px 5px 0px #ccc' }} defaultValue={"\n                                "} />
                                </div>
                            </div>
                            <div style={{ padding: '10px 0px' }}>
                                <li style={{ padding: "17px", color: '#000', fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', marginLeft: 30, marginBottom: 15 }}>
                                    Number of days of accounts payables ((Accounts payable *365) / Cost of
                                    goods/services procured) in the following format:
                                </li>
                                <table style={{ border: '1px solid #000', padding: "10px", borderCollapse: 'collapse', width: '100%' }}>
                                    <tbody><tr>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}> </th>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>FY _____
                                            (Current Financial
                                            Year)
                                        </th>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>FY _____
                                            (Previous Financial Year)
                                        </th>
                                    </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Number of days of
                                                accounts payables
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.noOfDaysOfAccountPayables?.currentYear}
                                                style={{ border: 'none', height: "60px", height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.noOfDaysOfAccountPayables?.previousYear}
                                                style={{ border: 'none', height: "60px", height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                    </tbody></table>
                            </div>
                            <div style={{ padding: '10px 0px' }}>
                                <li style={{ padding: "17px", color: '#000', fontWeight: 400, fontFamily: 'poppinslight', marginLeft: 30, marginBottom: 15 }}>
                                    Open-ness of business
                                    Provide details of concentration of purchases and sales with trading houses, dealers,
                                    and related parties along-with loans and advances &amp; investments, with related parties,
                                    in the following format:
                                </li>
                                <table style={{ border: '1px solid #000', padding: "10px", borderCollapse: 'collapse', width: '100%' }}>
                                    <tbody><tr>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}> Parameter</th>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>Metrics </th>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>FY _____
                                            (Current Financial
                                            Year)
                                        </th>
                                        <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif', margin: 0, lineHeight: "19px" }}>FY _____
                                            (Previous Financial Year)
                                        </th>
                                    </tr>
                                        <tr>
                                            <td rowSpan={3} style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Concentration
                                                of Purchases
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Purchases from trading
                                                houses as % of total
                                                purchases
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10}
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.concentrationOfPurchase?.percentageOfTotalPurchase?.currentYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.concentrationOfPurchase?.percentageOfTotalPurchase?.previousYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Number of trading
                                                houses where
                                                purchases are made
                                                from
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.concentrationOfPurchase?.noOfTradingHouse?.currentYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.concentrationOfPurchase?.noOfTradingHouse?.previousYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Purchases from top 10
                                                trading houses as % of
                                                total purchases from
                                                trading houses
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.concentrationOfPurchase?.topTenNoOfTrading?.currentYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10}
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.concentrationOfPurchase?.topTenNoOfTrading?.previousYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={3} style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Concentration
                                                of Sales
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Sales to dealers /
                                                distributors as % of
                                                total sales
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.concentrationOfSale?.percentageOfTotalSale?.currentYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.concentrationOfSale?.percentageOfTotalSale?.previousYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Number of dealers /
                                                distributors to whom
                                                sales are made
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.concentrationOfSale?.noOfTradingHouse?.currentYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.concentrationOfSale?.noOfTradingHouse?.previousYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Sales to top 10 dealers
                                                / distributors as % of
                                                total sales to dealers /
                                                distributors
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.concentrationOfSale?.topTenNoOfTrading?.currentYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.concentrationOfSale?.topTenNoOfTrading?.previousYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={4} style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Share of RPTs in
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Purchases (Purchases
                                                with related parties /
                                                Total Purchases)
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.shareOfRPT?.totalPurchase?.currentYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.shareOfRPT?.totalPurchase?.previousYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Sales (Sales to related
                                                parties / Total Sales)
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.shareOfRPT?.totalSales?.currentYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.shareOfRPT?.totalSales?.previousYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Loans &amp; advances
                                                (Loans &amp; advances
                                                given to related parties
                                                / Total loans &amp;
                                                advances)
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.shareOfRPT?.totalLoan?.currentYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.shareOfRPT?.totalLoan?.previousYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', width: '100%', maxWidth: '20%' }}>
                                                Investments
                                                ( Investments in related
                                                parties / Total
                                                Investments made)
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.shareOfRPT?.investment?.currentYear}
                                                rows={2} cols={10} 
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                            <td style={{ border: '1px solid #000', padding: "4px", color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'noirprolight', textAlign: 'center' }}>
                                                <textarea 
                                                id name 
                                                rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.essentialIndicators?.nessBusinessProvideDetails?.shareOfRPT?.investment?.previousYear}
                                                style={{ border: 'none', height: "60px", width: '100%', resize: 'none', height: "60px", backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', color: '#000' }} />
                                            </td>
                                        </tr>
                                    </tbody></table>
                            </div>
                        </ul>
                    </div>
                    <div style={{ border: '1px solid #000000a1', margin: '30px 0px' }}>
                        <p style={{ fontSize: 16, color: '#000', fontWeight: 600, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center', borderBottom: '1px solid #000', padding: 5, margin: 0 }}>Leadership Indicators
                        </p>
                        <div>
                            <ul style={{ padding: '10px 15px', listStyleType: 'decimal' }}>
                                <div style={{ padding: '10px 0px' }}>
                                    <li style={{ padding: "17px", color: '#000', fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', marginLeft: 30, marginBottom: 15 }}>
                                        Awareness programmes conducted for value chain partners on any of the Principles
                                        during the financial year:
                                    </li>
                                    <table style={{ border: '1px solid #000', padding: "10px", borderCollapse: 'collapse', width: '100%' }}>
                                        <tbody><tr>
                                            <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif' }}>Total number of awareness
                                                programmes held
                                            </th>
                                            <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif' }}>Topics / principles
                                                covered under the
                                                training
                                            </th>
                                            <th style={{ border: '1px solid #000', padding: "10px", color: '#000', padding: "17px", fontWeight: 800, fontFamily: 'Noto Sans, sans-serif' }}>%age of value chain
                                                partners covered (by value
                                                of business done with such
                                                partners) under the
                                                awareness programmes
                                            </th>
                                        </tr>
                                            <tr>
                                                <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                    <textarea 
                                                    id name 
                                                    rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.leadershipIndicators?.awarnessProgrammesDetails?.totalNoOfAwareness}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', height: "60px" }} defaultValue={"\n                                            "} />
                                                </td>
                                                <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                    <textarea 
                                                    id name 
                                                    rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.leadershipIndicators?.awarnessProgrammesDetails?.topics}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', height: "60px" }} defaultValue={"\n                                            "} />
                                                </td>
                                                <td style={{ border: '1px solid #000', padding: '4px 10px', color: '#000', fontSize: "16px", fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', textAlign: 'center' }}>
                                                    <textarea 
                                                    id name 
                                                    rows={2} cols={10} 
                                                value={principleOneData?.principleOne?.leadershipIndicators?.awarnessProgrammesDetails?.ageOfPercentage}
                                                    style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', height: "60px" }} defaultValue={"\n                                            "} />
                                                </td>
                                            </tr>
                                        </tbody></table>
                                </div>
                                <div>
                                    <li style={{ padding: "17px", color: '#000', fontWeight: 400, fontFamily: 'Noto Sans, sans-serif', marginLeft: 30, marginBottom: 15 }}>
                                        Does the entity have processes in place to avoid/ manage conflict of interests involving
                                        members of the Board? <span style={{ fontWeight: 700 }}> (Yes/No)
                                        </span> If Yes, provide details of the same.
                                    </li>
                                    <div>
                                        <div>
                                            <input 
                                            type="radio" 
                                            id name 
                                            checked={principleOneData?.principleOne?.leadershipIndicators?.conflictOfInterestDetails?.entityManageStatus === true}
                                            />
                                            <label htmlfor style={{ fontSize: 20, lineHeight: 'normal', fontWeight: 700, fontFamily: 'noirproregular', marginLeft: 5 }}>Yes</label>
                                        </div>
                                        <div>
                                            <input 
                                            type="radio" 
                                            id name 
                                            checked={principleOneData?.principleOne?.leadershipIndicators?.conflictOfInterestDetails?.entityManageStatus === false}
                                            />
                                            <label htmlfor style={{ fontSize: 20, lineHeight: 'normal', fontWeight: 700, fontFamily: 'noirproregular', marginLeft: 5 }}>No</label>
                                        </div>
                                        <div>
                                            <textarea 
                                            id name 
                                            rows={2} cols={10} 
                                            value={principleOneData?.principleOne?.leadershipIndicators?.conflictOfInterestDetails?.description}
                                            style={{ border: 'none', height: "60px", width: '100%', resize: 'none', backgroundColor: '#fff', fontFamily: 'Noto Sans, sans-serif', margin: '10px 0 0 0', color: '#000', boxShadow: '0 0 5px 0 #ccc', textAlign: 'left' }} />
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>

            </section>


        </>
    )
}

export default Principleonetemp
