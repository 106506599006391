import React, { useState } from 'react'
import { SketchPicker } from 'react-color';
const CreateHobbies = () => {
    const [colorPickerVisible, setColorPickerVisible] = useState(false);
    const [background, setBackground] = useState('#fff');
    const handleChangeComplete = (color) => {
        setBackground(color.hex);
      };
    return (
        <>
            <section className='createeventdetails'>
                <div className="detailspagecontainer">
                    <div className="createdivfrmmain">
                        <form>
                            <div className=''>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">
                                        Hobbies <span className="required">*</span>
                                    </p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name="eventName"
                                        defaultValue=""
                                    />
                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">
                                        Hobby Background Color <span className="required">*</span>
                                    </p>
                                    <input

                                        id="hobbyBackColor"
                                        className="typstrningwhselctnew"
                                        placeholder=""
                                        value="#ff0000"
                                        name="hobbyBackColor"
                                        onClick={() => setColorPickerVisible(!colorPickerVisible)}
                                    />
                                </div>
                                <div className="">
                                    {colorPickerVisible && (
                                        <SketchPicker
                                        color={background}
                                        onChangeComplete={handleChangeComplete}
                                        />
                                    )}
                                </div>

                                <div className="bulletinsbmtbtndivv">
                                    <button
                                        type="button"
                                        className="bulletinsbmtbtn"
                                       
                                    >
                                        Submit
                                    </button>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>
            </section>
        </>
    )
}

export default CreateHobbies
