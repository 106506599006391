import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Addicon from "../../Images/Icons/PNG/Add icon.png";
import cancel from "../../Images/Icons/PNG/cancel.png"
const Hobbies = () => {
  
    return (
        <>
            <div className='hobbisessectionmai'>
                <div className="top">
                    <div className="head bulleBoardHead">
                        <h4>Hobbies</h4>
                    </div>
                    <div className="add_seeallflx">
                        <div className="tyEvMainDiv" />
                        <div>
                            <Link to="/createhobbies"
                                className="seeAllAn"
                            >
                                <figure className="bulleBoardFig" >
                                    <img
                                        src={Addicon}
                                    />
                                </figure>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='bottom'>
                    <div className='hobbiescarddiv'
                        style={{ backgroundColor: "rgb(245, 166, 35)", display: "flex", justifyContent: "space-between" }}
                    >
                      <div className=''>
                        <p className='wrkHobbyText'>Web Development</p>
                      </div>
                      <div className='cancledibvimg'>
                        <img src={cancel} alt='...' />
                      </div>
                    </div>
                    <div className='hobbiescarddiv'
                        style={{ backgroundColor: "rgb(245, 166, 35)", display: "flex", justifyContent: "space-between" }}
                    >
                      <div className=''>
                        <p className='wrkHobbyText'>Web Development</p>
                      </div>
                      <div className='cancledibvimg'>
                        <img src={cancel} alt='...' />
                      </div>
                    </div>
                    <div className='hobbiescarddiv'
                        style={{ backgroundColor: "rgb(245, 166, 35)", display: "flex", justifyContent: "space-between" }}
                    >
                      <div className=''>
                        <p className='wrkHobbyText'>Web Development</p>
                      </div>
                      <div className='cancledibvimg'>
                        <img src={cancel} alt='...' />
                      </div>
                    </div>
                    <div className='hobbiescarddiv'
                        style={{ backgroundColor: "rgb(245, 166, 35)", display: "flex", justifyContent: "space-between" }}
                    >
                      <div className=''>
                        <p className='wrkHobbyText'>Web Development</p>
                      </div>
                      <div className='cancledibvimg'>
                        <img src={cancel} alt='...' />
                      </div>
                    </div>
                </div>

            </div>

          
        </>
    )
}

export default Hobbies
