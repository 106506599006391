import React, { useEffect, useState } from 'react'
import headerlogo from "../../src/Images/Innoverse ESG logo.png"
import { Link, NavLink, useNavigate } from 'react-router-dom';
// import profile from "../../src/Images/cute-girl-dp.jpg";
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import Search from "../Images/Icons/PNG/Search.png";
import Notification from "../../src/Images/Icons/PNG/Notification.png"
import Searchformmodal from '../Modal/Searchformmodal';
import EngageIcon from "../Images/Icons/PNG/Engage Icon.png";
import Educateicon from "../Images/Icons/PNG/Educate icon.png";
import Ensure from "../Images/Icons/PNG/Ensure.png";
import Bars from "../../src/Images/Icons/PNG/Bars.png"
const Header = () => {
    const navigate = useNavigate();
    const statusCard = useSelector((state) => state?.StatusCardSlice?.data);
    // console.log("opio",statusCard)
    const [headersticky, setHeadersticky] = useState(false);
    const [droepdownhhe, setdroepdownhhe] = useState(false);
    const [serchfrm, setSerchfrm] = useState(false)
    const [sidebarop, setSidebarop] = useState(false);
    useEffect(() => {
        function setFixed() {
            if (window.scrollY > 200) {
                setHeadersticky(true)
            }
            else {
                setHeadersticky(false)
            }
        }
        window.addEventListener("scroll", setFixed)
    }, [])
    const handledropdoewn = () => {
        setdroepdownhhe(!droepdownhhe)
    }


    const handleLogOut = (e) => {
        e.preventDefault()
        Swal.fire({
            title: 'Are you sure?',
            // text: "You won't  to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Log Out!',
        }).then(result => {
            if (result.isConfirmed) {
                // alert("ghfdsja")
                localStorage.clear()
                navigate("/login")
            }
        })
        // alert("svdje")
    }
    const handlesrchfrm = () => {
        setSerchfrm(!serchfrm)
    }
    const sidebaropen = () => {
        setSidebarop(true);
    }
    const sidebarclose = () => {
        setSidebarop(false)
    }
    return (
        <>
            <section className={headersticky ? "mainheadersectionactive" : "mainheadersection"}>
                <div className='esgpagecontainer'>
                    <div className='headermaincontentflx'>

                        <div className=''>
                            <div className='hedrlogo'>
                                <img src={headerlogo} alt="..." />
                            </div>
                        </div>

                        <div className='logomenudivspcl'>
                            <ul className='navbar-nav-own'>

                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/">
                                        <div className="iconheader" >
                                            <img src={EngageIcon} className="normalHeadIcon" alt="icon" />
                                        </div>
                                        <div className="namelnktxtppl">Engage</div>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/educate">
                                        <div className="iconheader" >
                                            <img src={Educateicon} className="normalHeadIcon" alt="icon" />
                                        </div>
                                        <div className="namelnktxtppl">Educate</div>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/ensure">
                                        <div className="iconheader" >
                                            <img src={Ensure} className="normalHeadIcon" alt="icon" />
                                        </div>
                                        <div className="namelnktxtppl">Ensure</div>
                                    </NavLink>
                                </li>

                            </ul>
                        </div>

                        <div className='logomenusrchnotispcl'>
                            <ul className='navbar-nav-lnkitmown'>

                                <li className="nav-item">
                                    <NavLink className="nav-link">
                                        <div className="iconheader" onClick={handlesrchfrm}>
                                            <img src={Search} className="normalHeadIcon" alt="icon" />
                                        </div>
                                        <div className="namelnktxtppl">Search</div>
                                    </NavLink>
                                </li>
                                <div className="notiBtn">
                                    <Link to="/" className="nav-link">
                                        <div className="iconheader">
                                            <img
                                                src={Notification}
                                                className="img-fluid normalHeadIcon"
                                                alt="icon"
                                            />

                                            {/* <div className="red_dot"></div> */}

                                        </div>
                                        <div className="namelnktxtppl">Notification</div>
                                    </Link>
                                </div>

                                <div className='prflheaderothrs'>
                                    <div className=' prflheader'>
                                        <div className='profilebrdrhead'>
                                            <div className='profilebrdrimg'>
                                                <img src={statusCard?.image} alt="..." />
                                            </div>
                                        </div>

                                        <div className='empleearr' onClick={handledropdoewn}>
                                            <div className='resnamedesgimgdiv'>
                                                <p className='empltxtpp'>{statusCard?.firstName + " " + statusCard?.lastName}</p>
                                                <p className='empltxtpp'>{statusCard?.userType}</p>
                                            </div>
                                            <div className=''  >
                                                <i className="fa-solid fa-angle-down"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={droepdownhhe ? 'dropwdowndivchng' : "dropwdowndiv"}>
                                        <Link className='prifoledivmaoin' to="/profile">
                                            <div className=''>
                                                <i className="fa-solid fa-user"></i>
                                            </div>
                                            <div className=''>
                                                <p className='lgdhstgtxtpp'>
                                                    Profile
                                                </p>
                                            </div>
                                        </Link>
                                        <div className='prifoledivmaoin'>
                                            <div className=''>
                                                <i className="fa-solid fa-right-from-bracket"></i>
                                            </div>
                                            <div className='' onClick={handleLogOut} style={{ cursor: "pointer" }}>
                                                <p className='lgdhstgtxtpp'>
                                                    Log Out
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className='barsgdhres' onClick={sidebaropen}>
                                  <img src={Bars} alt="..." />
                                </div>
                            </ul>
                        </div>

                    </div>
                </div>
            </section>

            <section className={sidebarop ? "sidebardivchng" : "sidebardiv"}>
                <div className='sidetop'>
                    <div className='frtsalonlogo' to="/">
                        <img src={headerlogo} alt="..." />
                    </div>
                    <div className='crosssideiocn' onClick={sidebarclose}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                </div>
                <div className='sidebottom'>
                    <ul className='headermenulistul'>

                        <li className='resMenuLi' onClick={sidebarclose}>
                            <NavLink className="resMenuLiAn" to="/bulletinDetails">
                                Bulletin
                            </NavLink>
                        </li>
                        <li className='resMenuLi' onClick={sidebarclose}>
                            <NavLink className="resMenuLiAn" to="/halloffamedetails">
                                Hall of Fame
                            </NavLink>
                        </li>
                        <li className='resMenuLi' onClick={sidebarclose}>
                            <NavLink className="resMenuLiAn" to="/initiativedetails">
                                Join Initiative
                            </NavLink>
                        </li>
                        <li className='resMenuLi' onClick={sidebarclose}>
                            <NavLink className="resMenuLiAn" to="/eventDetails">
                                Events
                            </NavLink>
                        </li>
                        <li className='resMenuLi' onClick={sidebarclose}>
                            <NavLink className="resMenuLiAn">
                                My Team
                            </NavLink>
                        </li>
                        <li className='resMenuLi' onClick={sidebarclose}>
                            <NavLink className="resMenuLiAn" to="/ongoingdetails">
                                On Going
                            </NavLink>
                        </li>
                        <li className='resMenuLi'>
                            <NavLink className="resMenuLiAn" to="/requestedTrainingsingle">
                                Requested Training
                            </NavLink>
                        </li>
                        <li className='resMenuLi'>
                            <NavLink className="resMenuLiAn" to="/detailsdocumentrepository">
                                Document Repository
                            </NavLink>
                        </li>

                    </ul>
                </div>
            </section>
            {serchfrm && <Searchformmodal closemodal={setSerchfrm} />}
        </>
    )
}

export default Header
