import React from 'react'
import imgprlsthgt from "../../src/Images/imgprlsthgt.jpg"
const Unfollow = ({closemodal}) => {
    return (
        <>
            <section className='unfollowmodabgmain'>
                <div className='unfollowbgmmmmm'>
                    <div className='unfollowcrsdivfkx'>
                        <p className='unfollowdfpppbk'>
                            Unfollow
                        </p>
                        <div className='crossflwax' onClick={() => closemodal()}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    <div className="unfoolowttxyupdiv">
                        <div className="unfoolowttxyupdivdicflx">
                            <div className="profilefollowimg">
                                <img
                                    src={imgprlsthgt}
                                    alt="..."
                                />
                            </div>
                            <p className="unfoolowttxyupllktxt">Are you unfollow Komal Roy?</p>
                        </div>
                        <div className="canclebtbnunfolowbtndivflx">
                            <button className="canclebtbndiv">Cancel</button>
                            <button className="unfolowbtndiv">Unfollow</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Unfollow
