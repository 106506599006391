import React, { useState } from 'react'
// import eventtesting from "../../Images/eventtesting.jpeg";
import time from "../../Images/Icons/PNG/time.png"
import { useNavigate } from 'react-router-dom';

const Coursecontentcomp = ({ singleViewList }) => {
    const navigate = useNavigate();
    const [dropdownintro, setDropdownintro] = useState(false);

    const handleowndropdwnClick = () => {
        setDropdownintro(!dropdownintro)
    }

    //for viewing add more videos
    const cousrevideoonClick = (item) => {
        const data = {
            item: item,
        }
        navigate(`/courseVideoPlayer/${singleViewList?.title?.replace(/\s+/g, '-')}/${singleViewList?._id}/${item?._id}`, { state: data })
    }
    return (
        <>
            <div className='coursecontentcommapn'>
                <div className='couusersecntntop'>
                    <h4 className='crssntshhtoptxtpp'>
                        Course Content
                    </h4>
                </div>
                <div className='couusersecntnbtm'>
                    {/* <div className='arrowdivtxtdivflx'>
                        <i className="fa-solid fa-chevron-down"></i>
                        <p className='vdoplylusttxtpp'> Video PlayList</p>
                    </div> */}
                    <div className='arrowdivtxtdivflx'>
                        <div className='' onClick={handleowndropdwnClick}>
                            <span className={dropdownintro ? "fa-solid fa-angle-right" : "fa-solid fa-angle-down"}></span>
                        </div>
                        <div className=''>
                            <p className='vdoplylusttxtpp'>Video Playlist</p>
                        </div>
                    </div>
                    <div className='tableconttxtcrsrtdivmainbtm'>
                        <table className='tableconttxtcrsrtdiv'>

                            {/* <tr className='tsblleuujcjoint'>
                                <th>
                                    <div className='coustrsnttdibimhg'>
                                        <img src={eventtesting} alt='...' />
                                    </div>
                                </th>
                                <th>
                                    <div className='partcniidbdibbjjdflx'>
                                        <p className='partttstnonjtxtp'>
                                            1
                                        </p>
                                        <p className='parttcnttsggtxfhupsjjdspo'>
                                            Lorem ipsum dolor sit amet.
                                        </p>
                                    </div>

                                </th>
                                <th>
                                    <div className='timedimnhhshcnoyysbvdodibvflx'>
                                        <div className='timedivbndinnfjmimg'>
                                            <img src={time} alt='...' />
                                        </div>
                                        <p className='timeuygsggttxtcntdyttctx'> 0hh 1min </p>
                                    </div>
                                </th>
                            </tr>

                            <tr className='tsblleuujcjoint'>
                                <th>
                                    <div className='coustrsnttdibimhg'>
                                        <img src={eventtesting} alt='...' />
                                    </div>
                                </th>
                                <th>
                                    <div className='partcniidbdibbjjdflx'>
                                        <p className='partttstnonjtxtp'>
                                            1
                                        </p>
                                        <p className='parttcnttsggtxfhupsjjdspo'>
                                            Lorem ipsum dolor sit amet.
                                        </p>
                                    </div>

                                </th>
                                <th>
                                    <div className='timedimnhhshcnoyysbvdodibvflx'>
                                        <div className='timedivbndinnfjmimg'>
                                            <img src={time} alt='...' />
                                        </div>
                                        <p className='timeuygsggttxtcntdyttctx'> 0hh 1min </p>
                                    </div>
                                </th>
                            </tr> */}


                            {
                                singleViewList?.videos?.map((item, i) => {
                                    return (
                                        <>
                                            <tr className='tsblleuujcjoint'>
                                                <th>
                                                    <div className='coustrsnttdibimhg'
                                                        onClick={(e) => cousrevideoonClick(item, e)}
                                                    >
                                                        {/* <img src={eventtesting} alt='...' /> */}
                                                        <video
                                                            src={item?.videoLink}
                                                        // className='reactdivimgmainplrt'
                                                        />
                                                    </div>
                                                </th>

                                                {/* <div className='cousreimgdmainimgnn'>
                                                    <div
                                                        className='cousreimgdmain'
                                                        onClick={(e) => cousrevideoonClick(item, e)}
                                                    >

                                                        <video
                                                            src={item?.videoLink}
                                                            className='reactdivimgmainplrt'
                                                        />
                                                    </div>
                                                </div> */}

                                                <th>
                                                    <div className='partcniidbdibbjjdflx'>
                                                        <p className='partttstnonjtxtp'>
                                                            {i + 1}
                                                        </p>
                                                        <p className='parttcnttsggtxfhupsjjdspo'>
                                                            {item?.videoTitle}
                                                        </p>
                                                    </div>

                                                </th>

                                                {/* <div className='lastmnthdesitxtflx'>
                                                    <div className=''>
                                                        <p className='crsecntntxt'>{i + 1}</p>
                                                    </div>
                                                    <div className=''>
                                                        <p className='crsecntntxt'>{item?.videoTitle}</p>
                                                    </div>
                                                </div> */}

                                                <th>
                                                    <div className='timedimnhhshcnoyysbvdodibvflx'>
                                                        <div className='timedivbndinnfjmimg'>
                                                            <img src={time} alt='...' />
                                                        </div>
                                                        <p className='timeuygsggttxtcntdyttctx'> {item?.videoDuration?.hour ? (<>{item?.videoDuration?.hour}</>) : ("0")}hr  {item?.videoDuration?.min ? (<>{item?.videoDuration?.min}</>) : ("00")}min </p>
                                                    </div>
                                                </th>
                                                {/* <div className='cusrvideominuteflx'>
                                                    <div className='timeimgdiv'>
                                                        <img src={time} alt="..." />
                                                    </div>
                                                    <div className=''>
                                                        <p className='hrsmnttxtpp'>{item?.videoDuration?.hour ? (<>{item?.videoDuration?.hour}</>) : ("0")}hr  {item?.videoDuration?.min ? (<>{item?.videoDuration?.min}</>) : ("00")}min</p>

                                                    </div>
                                                </div> */}

                                            </tr>
                                        </>


                                    )
                                })
                            }


                        </table>
                    </div>
                </div>
            </div>

            {/* <div className='coursedivplrtdivbgintr'>
                <div className='coursecntntdiv'>
                    <div className=''>
                        <p className='crstntpptxtp'>Course Content</p>
                    </div>


                    <div className=''>
                        <div className='introbgpdf'>
                            <div className='Introductiontxtangle'>
                                <div className='' onClick={handleowndropdwnClick}>
                                    <span className={dropdownintro ? "fa-solid fa-angle-right" : "fa-solid fa-angle-down"}></span>
                                </div>
                                <div className=''>
                                    <p className='intrdivppptxts'>Video Playlist</p>
                                </div>
                            </div>
                        </div>

                        <div className={dropdownintro ? "closedropdownactd" : "opendropdownactd"}>

                            {
                                singleViewList?.videos?.map((item, i) => {
                                    return (
                                        <>
                                            <div className='coursecontentflxalll' key={i}>
                                                <div className='cousreimgdmainimgnn'>
                                                    <div
                                                        className='cousreimgdmain'
                                                        onClick={(e) => cousrevideoonClick(item, e)}
                                                    >

                                                        <video
                                                            src={item?.videoLink}
                                                            className='reactdivimgmainplrt'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='lastmnthdesitxtflx'>
                                                    <div className=''>
                                                        <p className='crsecntntxt'>{i + 1}</p>
                                                    </div>
                                                    <div className=''>
                                                        <p className='crsecntntxt'>{item?.videoTitle}</p>
                                                    </div>
                                                </div>
                                                <div className='cusrvideominuteflx'>
                                                    <div className='timeimgdiv'>
                                                        <img src={time} alt="..." />
                                                    </div>
                                                    <div className=''>
                                                        <p className='hrsmnttxtpp'>{item?.videoDuration?.hour ? (<>{item?.videoDuration?.hour}</>) : ("0")}hr  {item?.videoDuration?.min ? (<>{item?.videoDuration?.min}</>) : ("00")}min</p>

                                                    </div>
                                                </div>

                                            </div>
                                        </>


                                    )
                                })
                            }



                        </div>
                    </div>


                </div>

                {
                    singleViewList?.quizStatus === "active" ?
                        (<button className="quizebtnvisble" onClick={() => navigate(`/feedbackquize/${singleViewList?._id}`)}>
                            Take Quiz
                        </button>)
                        :
                        (
                            <button className="enrolllnowdiv">
                                Take Quiz
                            </button>
                        )
                }

            </div> */}
        </>
    )
}

export default Coursecontentcomp
