import React, { useEffect, useState } from 'react'
import Select from "react-select";
import HomeService from '../../Services/HomeService';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
const CreateInitiative = () => {
    const initial = {
        nameOfInitaitive: "",
        description: "",
        rewardPoints: "",
        startDate: "",
        endDate: "",
        initiativeTypeID: "",
    }
    const navigate = useNavigate()
    const [selectedOption, setSelectedOption] = useState(null);
    const [addInitiative, setAddInitiative] = useState(initial);
    const [initiativeType, setInitiativeType] = useState([])

    console.log("s5f68s4fd", initiativeType)

    // >>>>HandleChange Of all Input<<<<
    const handleChange = (e) => {
        const { name, value } = e.target
        setAddInitiative({ ...addInitiative, [name]: value })
    }

    // >>>>Get All Initiative Type<<<<
    const fetchInitiaitveType = async () => {
        const res = await HomeService.getInitiativeType()
        if (res && res?.status) {
            setInitiativeType(res?.data)
        }
    }

    // >>>>Handle Submit<<<<
    const onSubmit = async (e) => {
        e.preventDefault()

        const { nameOfInitaitive, description, rewardPoints, startDate, endDate, initiativeTypeID } = addInitiative;

        // Validation checks
        if (!nameOfInitaitive || !description || !rewardPoints || !startDate || !endDate || !initiativeTypeID) {
            toast.error('All fields are required');
            return;
        }
        else if (parseInt(rewardPoints) < 0) {
            toast.error('Reward points cannot be negative');
            return;
        }
        else if (new Date(startDate) > new Date(endDate)) {
            toast.error('Please enter valid start date and end date!');
            return;
        } else {
            let data = {
                nameOfInitaitive,
                description,
                rewardPoints: parseInt(rewardPoints),
                startDate,
                endDate,
                initiativeTypeID
            }
            console.log("sg46sfd46s", data)
            // return
            const res = await HomeService.addInitiative(data)
            if (res && res?.status) {
                toast.success("Initiative added successfully..!");
                setAddInitiative(initial);
                setTimeout(() => {
                    navigate('/initiativedetails')
                }, 1000)
            }

        }
    }

    useEffect(() => {
        fetchInitiaitveType()
    }, [])
    return (
        <>
            <section className="createeventdetails">
                <div className="detailspagecontainer">
                    <div className="createdivfrmmain">
                        <form>
                            <div className=''>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt"> Initiative List <span className='required'>*</span></p>

                                    <Select
                                        placeholder={"Select Event Type"}
                                        closeMenuOnSelect={false}
                                        defaultValue={selectedOption}
                                        onChange={(selectedOption) => {
                                            setSelectedOption(selectedOption);
                                            setAddInitiative({ ...addInitiative, initiativeTypeID: selectedOption.value });
                                        }}
                                        options={initiativeType.map(item => ({ value: item._id, label: item.initiativetype }))}
                                        className="typstrningwhselctnew"
                                    />
                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Name of Initiative <span className='required'>*</span></p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name="nameOfInitaitive"
                                        value={addInitiative?.nameOfInitaitive}
                                        onChange={handleChange}
                                    />

                                </div>

                                <div className="trainingrifgtdivgappp">
                                    <p className="rsntxrpnewtxt">Description</p>
                                    <textarea
                                        id=""
                                        rows={4}
                                        cols={50}
                                        name="description"
                                        value={addInitiative?.description}
                                        onChange={handleChange}
                                        className="txtdivallartadvcacytfnew"
                                    ></textarea>

                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Start Date  <span className='required'>*</span></p>
                                    <input
                                        type="date"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name="startDate"
                                        value={addInitiative?.startDate}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">End Date  <span className='required'>*</span></p>
                                    <input
                                        type="date"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name='endDate'
                                        value={addInitiative?.endDate}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Reward Points <span className='required'>*</span></p>
                                    <input
                                        type="number"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name='rewardPoints'
                                        value={addInitiative?.rewardPoints}
                                        onChange={handleChange}
                                    />

                                </div>

                                <div className="bulletinsbmtbtndivv">
                                    <button
                                        type="button"
                                        className="bulletinsbmtbtn"
                                        onClick={onSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreateInitiative
