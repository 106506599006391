import React, { useEffect, useState } from 'react'
import HomeService from '../../Services/HomeService';
import { Link, useNavigate } from 'react-router-dom';
import Expand from "../../Images/Icons/PNG/Expand.png"
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import HttpClientXml from '../../utils/HttpClientXml';

const Initiative = () => {
    const userData = useSelector((state) => state?.StatusCardSlice?.data);
    const [allInitiativeData, setAllInitiativeData] = useState([])
    const [editedelete, setEditedelete] = useState(false);
    const [modalId, setModalId] = useState("");
    const navigate = useNavigate()

    const handledelteediit = (id) => {
        setModalId(id);
        setEditedelete(!editedelete)
    }

    const handleEdit = async (item) => {
        navigate(`/editinitiative/${item?._id}`, { state: item })
    }

    // >>>>GET All Initiative <<<< 
    const fecthAllInitiative = async () => {
        const res = await HomeService.getAllInitiative();
        if (res && res?.status) {
            setAllInitiativeData(res?.data)
        }
    }

    //for delete initiative
    const handleDelete = async () => {
        const res = await HttpClientXml?.requestData(`delete-initiative/${modalId}`, {}, "DELETE");
        if (res?.status) {
            toast?.success("Initiative deleted succesfully");
            fecthAllInitiative();
        } else {
            toast?.error(res?.message || "Something went wrong");
        }
    }

    // >>>>Handle Detail View of Single Event Card<<<<
    const handleinititiveclick = (item) => {
        navigate(`/initiativesingle/${item?._id}`)
    }

    useEffect(() => {
        fecthAllInitiative()
    }, []);

    return (
        <>
            <div className='joiniative_mainright'>
                <div className="top">
                    <div className="head bulleBoardHead">
                        <h4>Join Initiative</h4>
                    </div>
                    <div className="add_seeallflx">
                        <div>
                            <Link style={{ textDecoration: "none" }} className="seeAllAn" to="/initiativedetails">
                                <figure className="bulleBoardFig" style={{ marginRight: "10px" }}>
                                    <img src={Expand} alt='...' />
                                </figure>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='bottom'>
                    {allInitiativeData && allInitiativeData?.length > 0 ?
                        allInitiativeData?.map((ele) => {
                            return (
                                <div className='card_area' key={ele?._id}>
                                    <div className='initidtdltsdivflxmain'>

                                        <div className='' style={{ cursor: "pointer" }} onClick={() => handleinititiveclick(ele)}>
                                            <div className='textcontent'>
                                                <h4 className='txstyppp'>{ele?.nameOfInitaitive}</h4>
                                            </div>
                                            <div className=''>
                                                <p className='rwdtxtpp'>Reward Points-{ele?.rewardPoints}</p>
                                                <p className='rwdtxtpp'>Start Date - {ele?.startDate}</p>
                                                <p className='rwdtxtpp'>End Date - {ele?.endDate}</p>
                                            </div>
                                        </div>
                                        {
                                            userData?._id === ele?.addedBy &&
                                            <div className='initiativethreedlfown'>
                                                <div className='initiativethreedlf' onClick={() => handledelteediit(ele?._id)}>
                                                    <i className="fa-solid fa-ellipsis-vertical"></i>

                                                </div>


                                                {ele?._id === modalId && editedelete && <div className="editdltdivflxmain">
                                                    <div className="plismnisn" onClick={() => handleEdit(ele)}>
                                                        <i className="fa-solid fa-pen-to-square" />
                                                    </div>
                                                    <div className="plismnisn" onClick={handleDelete}>
                                                        <i className="fa-solid fa-trash" />
                                                    </div>
                                                </div>}


                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div>No Data Found!</div>
                    }
                </div>
            </div>
        </>
    )
}

export default Initiative