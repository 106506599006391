import React, { useEffect, useState } from 'react'
// import add from "../../Images/Icons/PNG/Add icon.png";
import Expand from "../../Images/Icons/PNG/Expand.png";
// import Bulletin_Board from "../../Images/Icons/PNG/Bulletin_Board.png";
// import add from "../../Images/Icons/PNG/Add icon.png";
// import see from "../../Images/Icons/PNG/See all icon.png";
// import Bulletin_Board from "../../Images/Icons/PNG/Bulletin_Board.png";
import bullentinimg from "../../Images/bullentinimg.jpg";
import 'react-tabs/style/react-tabs.css';
import { Link, useNavigate } from 'react-router-dom';
import HomeService from '../../Services/HomeService';
import moment from 'moment';
import { useSelector } from 'react-redux';
import HttpClientXml from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';

const BulletinBoard = () => {
    const navigate = useNavigate();
    const [editdeltebuletn, setEditdeltebuletn] = useState(false);
    const [allBulletin, setAllBulletin] = useState([]);
    const userData = useSelector((state) => state?.StatusCardSlice?.data);

    // >>>>Handle toggling the EDIT and DELETE icon<<<<
    const handleeditdletedtls = (id) => {
        setEditdeltebuletn(prevState => prevState === id ? !prevState : id);
    }

    const handlebulletinedt = () => {
        setEditdeltebuletn(!editdeltebuletn)
    }
    const handleEventView = (item) => {
        navigate('/bulletinsingle', { state: item })
    }

    // >>>>GET ALl Bulletins Data<<<<
    const fetchAllBulletin = async () => {
        const res = await HomeService.getAllBulletins();
        if (res && res?.status) {
            setAllBulletin(res?.data)
        }
    }

    //for delete
    const handleDelete = async (id) => {
        const res = await HttpClientXml?.requestData(`delete-bulletin-board/${id}`, {}, "PUT");
        if (res?.status) {
            toast?.success("Bulletin deleted succesfully");
            fetchAllBulletin();
        } else {
            toast?.error(res?.message || "Something went wrong");
        }
    }

    useEffect(() => {
        fetchAllBulletin()
    }, [])

    return (
        <>
            <div className='bulletin_boardengage'>
                <div className="top">
                    <div className="head bulleBoardHead">
                        {/* <figure className="bulleBoardFig">
                            <img
                                src={Bulletin_Board}
                                alt="..."
                            />
                        </figure> */}
                        <h4>Bulletin Board</h4>
                    </div>
                    <div className="add_seeallflx">
                        {/* <div className="tyEvMainDiv">
                            <figure className="bulleBoardFig">
                                <i class="fa-solid fa-plus"></i>
                            </figure>
                        </div> */}
                        <div>
                            <Link style={{ textDecoration: "none" }} className="seeAllAn" to="/bulletinDetails">
                                <figure className="bulleBoardFig" style={{ marginRight: "10px" }}>
                                    {/* <p style={{color:"#000"}}>view</p> */}
                                    <img src={Expand} alt="..." />
                                </figure>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='bottom'>
                    {allBulletin && allBulletin?.length > 0 ?
                        allBulletin?.map((item) => {
                            return (

                                <div className='card_area' key={item?._id} >
                                    <div className='event_imgtextbillentin' style={{cursor:"pointer"}} onClick={() => handleEventView(item)}>
                                        <div className='event_img'>
                                            {item?.image ?
                                                <img src={item?.image} className="img-fluid" alt="event" />
                                                :
                                                <img src={bullentinimg} className="img-fluid" alt="event" />
                                            }
                                        </div>
                                        <div className='textbillentin'>
                                            <h2>{item?.eventName}</h2>
                                            <p>Date:{item?.eventDate ? moment(item?.eventDate).format("YYYY-MM-DD") : moment(item?.startDate)?.format("YYYY-MM-DD")} </p>

                                        </div>
                                    </div>
                                    {
                                        userData?._id === item?.addedBy &&
                                        <div className='dotviewflx'>
                                            <div className='divdot' onClick={() => handleeditdletedtls(item._id)}>
                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                                {editdeltebuletn === item._id && <div className='editdltdivflxmain'>
                                                    <div className='plismnisn'>
                                                        <i className="fa-solid fa-pen-to-square" style={{cursor:"pointer"}}
                                                         onClick={() => navigate('/createbulletin', { state: item })}
                                                        ></i>
                                                    </div>
                                                    <div className='plismnisn' onClick={()=>handleDelete(item?._id)}>
                                                        <i className="fa-solid fa-trash"></i>
                                                    </div>
                                                </div>}
                                            </div>
                                            {/* <div className='vwbtnmain'>
                                            <button className='viewbtn'>View</button>
                                        </div> */}
                                        </div>
                                    }
                                </div>
                            )
                        })
                        :
                        <div>No Data Found!!</div>
                    }
                </div>
            </div >

            
        </>
    )
}

export default BulletinBoard