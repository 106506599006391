import React, { useEffect, useState } from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// import { ApiHelperFunction, fileUpload } from '../../services/api/apiHelpers';
import toast from 'react-hot-toast';
import Viewicon from "../../Images/Icons/PNG/View icon.png"
import HttpClientXml from '../../utils/HttpClientXml';
import HomeService from '../../Services/HomeService';
const animatedComponents = makeAnimated();

const TrainingCreateVirtual = ({ trainingType, trainingCategory, title, language, imageURL,
    settrainingType,
    settrainingCategory,
    settitle,
    setlanguage,
    setImage,
    setImageURL
}) => {

    const iniVideoDocument = {
        document: ""
    }

    const iniVideoLearningContent = {
        content: ""
    }

    const iniVideoData = {
        videoLink: "",
        videoTitle: "",
        videoDuration: { hour: "", min: "" },
        videoDocument: [iniVideoDocument],
        videoDescription: "",
        videoLearningContent: [iniVideoLearningContent]
    }

    const initrainingquiz = {
        question: "",
        A: "",
        B: "",
        C: "",
        D: "",
        answer: "",
        quizType: "checkBox"
    }

    const [videos, setvideos] = useState([iniVideoData]);
    const [trainingQuiz, settrainingQuiz] = useState([initrainingquiz]);
    const [description, setdescription] = useState("");
    const [link, setlink] = useState("");
    const [mentor, setmentor] = useState([]);
    const [targetAudiance, settargetAudiance] = useState([]);
    const [materials, setmaterials] = useState("");
    const [trainerName, settrainerName] = useState("");
    const [trainingCost, settrainingCost] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [colourOptions, setColourOptions] = useState([]);
    const [empData, setempData] = useState([]);
    const [empDataaudience, setempDataaudience] = useState([]);
    // const [selectedOptionsaudience, setSelectedOptionsaudience] = useState([]);
    const [colourOptionsaudience, setColourOptionsaudience] = useState([]);
    const [imageLoader4, setImageLoader4] = useState(false);
    const [imageLoader5, setImageLoader5] = useState(false);
    const [DepartmentName, setDepartmentName] = useState("");
    const [options, setOptions] = useState([]);
    const [DepartmentDropdown, setDepartmentDropdown] = useState([]);

    // console.log("videos", videos)

    //pick department name
    const handleSelectDepartName = setSelectedOptionsaudience => {
        setDepartmentName(setSelectedOptionsaudience ? setSelectedOptionsaudience.value : "");
    }


    // Target Audiance data 
    const handleSelectChangeaudiance = setSelectedOptionsaudience => {
        settargetAudiance(setSelectedOptionsaudience ? setSelectedOptionsaudience?.map(option => option.value) : []);
    }

    // get Designation/Department Data
    const getDepartmentData = async () => {
        // setIsLoading(true);
        const res =  await HttpClientXml?.requestData("view-department", {}, "GET")
        console.log("resGetCat", res)
        let apiData = []
        if (res && res?.status) {
            // setIsLoading(false);
            apiData = res?.data?.map((item, i) => ({
                label: item?.departmentName,
                value: item?._id,

            }));
        } else {
            // setIsLoading(false);
        }
        setDepartmentDropdown(apiData);
    }

    //Fetch target audiance Data
    const getTargetAudianceData = async () => {
        const res = await HttpClientXml?.requestData("view-all-employees", {}, "GET")
        
        // console.log("resGetCatman", res)
        let apiData = []
        if (res && res?.status) {

            apiData = res?.data?.map((item, i) => ({
                label: `${item.firstName} ${item.lastName}`,
                value: item._id,

            }));
        } 
        setOptions(apiData);
    }


    //handle change for first upload vdo add more functionality
    const handleChange = (i, e) => {
        let newFormValues = [...videos];
        newFormValues[i][e.target.name] = e.target.value;
        setvideos(newFormValues);
    };

    //for onChange functionality vdo duration
    const handleChangeVideoDur = (i, e) => {
        let newFormValues = [...videos];
        newFormValues[i]["videoDuration"][e.target.name] = e.target.value;
        setvideos(newFormValues);
    };


    //for first add more functionality for trainingquiz add more
    const addFormFieldsTrainingQuiz = () => {
        settrainingQuiz([...trainingQuiz, initrainingquiz]);
    };


    //for first add more functionality
    const addFormFields = () => {
        setvideos([...videos, iniVideoData]);
    };

    //for removing add more field
    const removeFormFields = (i) => {
        let newFormValues = [...videos];
        newFormValues.splice(i, 1);
        setvideos(newFormValues);
    };


    //for videoLink functionality(upload video)
    const handleVideoChange = async (ind, e) => {
        setImageLoader5(true)
        let image = e.target.files[0];
        // setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res =  await HttpClientXml.fileUplode("image-upload", "POST", form);

        if (res && res.status) {
            toast?.success("Video is uploaded successfully")
            // console.log("UploadImageResww", res);
            setvideos(prevVideos => {
                const newVideos = [...prevVideos];
                newVideos[ind].videoLink = res.image;
                return newVideos;
            });

        } else {
            toast.error("Video is not supported");
        }
        setImageLoader5(false);
    };


    //function to nested add more document fields
    const addVideosFields = (ind) => {
        let newDocArr = [...videos[ind].videoDocument, {
            document: ""
        }];
        setvideos(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, videoDocument: newDocArr })
                } else {
                    return item
                }
            })
        })

    };


    //function to nested add more content fields
    const addContentFields = (ind) => {
        let newDocArr = [...videos[ind].videoLearningContent, {
            content: ""
        }];
        setvideos(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, videoLearningContent: newDocArr })
                } else {
                    return item
                }
            })
        })

    };

    //handle change for nested add more functionality in videoContent
    const handleDocumentChange = (ind, index, e) => {
        let newDocArr = [...videos[ind].videoLearningContent];
        newDocArr[index][e.target.name] = e.target.value;
        setvideos(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, videoLearningContent: newDocArr })
                } else {
                    return item
                }
            })
        })
    };

    // Function to remove videoContent functionality
    const removeContentDocument = (ind) => {
        let newDocArr = [...videos[ind].videoLearningContent];
        newDocArr.splice(ind, 1);
        setvideos(prev => {
            return prev?.map((item, i) => {
                if (i === ind) {
                    return ({ ...item, videoLearningContent: newDocArr })
                } else {
                    return item
                }
            })
        })
    }

    //for handle inner videodocument onChange functionality
    const HandleVideoDoc = async (ind, index, e) => {
        setImageLoader4(true)
        let image = e.target.files[0];
        // setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await HttpClientXml.fileUplode("image-upload", "POST", form);

        if (res && res.status) {
            toast?.success("Document is uploaded successfully")
            // console.log("UploadImageRes", res);
            let newDocArr = [...videos[ind].videoDocument];
            newDocArr[index].document = res.image;

            setvideos(prev => {
                return prev?.map((item, i) => {
                    if (i === ind) {
                        return ({ ...item, videoDocument: newDocArr })
                    } else {
                        return item
                    }
                })
            })

        } else {
            // toast.error(res?.message);
        }
        setImageLoader4(false);
    };

    // Function to remove videodocument onChange functionality
    const removeVideoDocument = (ind, index) => {
        const delArr = videos[ind]?.videoDocument?.filter((ele, i) => i !== index)
        const newVideo = [...videos];
        newVideo[ind]["videoDocument"] = delArr
        setvideos(newVideo)
    }

    //for getting internal trainer name
    const getEmployeeData = async () => {
        const response = await HttpClientXml?.requestData("view-all-employees", {}, "GET")
        if (response && response.status) {
            const formattedOptions = response?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
            }));

            const formattedOptionsaudience = response?.data?.map((item) => ({
                value: item?._id,
                label: item?.userName,
            }));

            setColourOptions(formattedOptions);
            setempData(response?.data);
            setempDataaudience(response?.data)
            setColourOptionsaudience(formattedOptionsaudience)

        } else {

        }
    };

    //for getting selected internal trainer
    const handleSelectChange = (e) => {
        setSelectedOptions(e);
    };

    //for getting selected targeted audience 
    // const handleSelectChangeaudience = (e) => {
    //     setSelectedOptionsaudience(e);
    // };

    //for submitting form functionality 
    const submitHandler = async (e) => {
        e.preventDefault();
        let data = {};
        // setLoading(true);
        if (trainingType === "") {
            return toast.error("Training Type is required");
        }

        if (trainingCategory === "") {
            return toast.error("Training Category is required");
        }

        if (trainingCategory === "") {
            return toast.error("Training Category is required");
        }

        if (title === "") {
            return toast.error("Training title is required");
        }

        if (language === "") {
            return toast.error("Language is required");
        }

        if (imageURL === "") {
            return toast.error("Image is required");
        }
        if (description === "") {
            return toast.error("Description is required");
        }

        if (!selectedOptions?.value) {
            return toast.error("Internal Mentor is required");
        }
        if (videos?.[0]?.videoTitle === "") {
            return toast.error("Video Title field is required");
        }
        if (videos?.[0]?.videoDescription === "") {
            return toast.error("Video Description field is required");
        }
        if (videos?.[0]?.videoLink === "") {
            return toast.error("Upload Video field is required");
        }
        if (videos?.[0]?.videoDocument?.[0]?.document === "") {
            return toast.error("Video document field is required");
        }

        if (videos?.[0]?.videoLearningContent?.[0]?.content === "") {
            return toast.error("Video content field is required");
        }

        if (link === "") {
            return toast.error("Link is required");
        }

        if (trainingQuiz?.[0]?.question === "") {
            return toast.error("TrainingQuiz question is required");
        }

        // if (trainingQuiz?.[0]?.A == "") {
        //     return toast.error("Option A is required");
        // }

        // if (trainingQuiz?.[0]?.B == "") {
        //     return toast.error("Option B is required");
        // }
        if (trainingQuiz?.[0]?.answer == "") {
            return toast.error("Answer is required");
        }

        if (materials === "") {
            return toast.error("Materials is required");
        }



        data = {
            trainingType: trainingType,
            trainingCategory: trainingCategory,
            title: title,
            language: language,
            image: imageURL,
            description: description,
            trainerName: trainerName,
            trainingCost: trainingCost,
            videos: videos,
            link: link,
            materials: materials,
            trainingQuiz: trainingQuiz,
            department: DepartmentName,
            targetAudiance: targetAudiance,
            mentor: selectedOptions?.value
        };

        console.log("hhhhhhhhhhh", data);

        const res =await HomeService.addTraining(data);
        if (res?.status) {
            toast.success("Training(Virtual) is added successfully");
            settrainingType("");
            settrainingCategory("");
            settitle("");
            setlanguage("");
            setImage("");
            setImageURL("");
            setdescription("");
            settrainerName("");
            settrainingCost("");
            setDepartmentName("");
            setDepartmentDropdown([]);
            setSelectedOptions("");
            setvideos([iniVideoData]);
            setlink("");
            setmaterials("");
            settrainingQuiz([initrainingquiz]);
            settargetAudiance([]);
            setmentor([]);
        } else {
            toast.error(res?.response?.data?.message || "Something went wrong");
            console.log("ERROR CREATING USER3", res);
        }
        // setLoading(false);
    };


    useEffect(() => {
        getEmployeeData();
        getDepartmentData();
    }, [])

    useEffect(() => {
        getTargetAudianceData()
    }, [DepartmentName]);


    //handle change for first trainingQuiz add more functionality
    const handleChangeTraingQuiz = (i, e) => {
        let newFormValues = [...trainingQuiz];
        newFormValues[i][e.target.name] = e.target.value;
        settrainingQuiz(newFormValues);
    };

    //for radio button functionality
    const handleChangeTraingRadio = (i, e) => {
        const { value } = e.target
        // console.log("dddd", value)
        let newFormValues = [...trainingQuiz];
        if (value === "yes") {
            newFormValues[i]["A"] = value;
            newFormValues[i]["B"] = "";
            newFormValues[i]["answer"] = "A";
            settrainingQuiz(newFormValues)
        } else if (value === "no") {
            newFormValues[i]["B"] = value;
            newFormValues[i]["A"] = "";
            newFormValues[i]["answer"] = "B";
            settrainingQuiz(newFormValues)
        }
    };

    return (
        <>
            <div className='trainingcreatepageright'>
                <div className=''>
                    <form>
                        <div className='trainingrifgtdivgappp'>
                            <p className="rsntxrpnewtxt">
                                Training Description
                            </p>
                            <textarea className='txtdivallart'
                                id="w3review"
                                rows={4}
                                cols={50}
                                name="description"
                                value={description}
                                onChange={(e) => setdescription(e.target.value)}
                            />

                        </div>
                        <div className='trainernameselectflx'>
                            <div className='trainernametextinputdiv'>
                                <p className="rsntxrpnewtxt">
                                    External Trainer Name(if any)
                                </p>
                                <input
                                    type='text'
                                    className='texttraingrightdiv'
                                    name="trainerName"
                                    value={trainerName}
                                    onChange={(e) => settrainerName(e.target.value)}
                                />
                            </div>
                            <div className='trainernametextinputdiv'>
                                <p className="rsntxrpnewtxt">
                                    Internal Trainer Name(if any)
                                </p>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    defaultValue={[]}
                                    // isMulti
                                    options={colourOptions}
                                    onChange={handleSelectChange}
                                />

                                {/* <select name="" id="" className="typstrningwhselct">
                                    <option value="Virtual">Tarinner name</option>
                                    <option value="Physically">
                                        Tarinner name1</option>
                                    <option value="Physically">Tarinner name2</option>
                                </select> */}
                            </div>
                        </div>

                        {/* Upload Video section */}
                        <div className='trainingrifgtdivgappp'>
                            {
                                videos?.map((element, ind) => {
                                    return (<>
                                        <div className='trainingrifgtdivgapppbrdr' key={ind}>
                                            <p className="rsntxrpnewtxtupld">
                                                Upload video/s
                                            </p>

                                            <div className='videotitledesrtpupldflx'>
                                                <div className='videottldescrtflx'>
                                                    <div className='videottldivwhgt'>
                                                        <p className="rsntxrpnewtxt"> Video Title</p>
                                                        <input
                                                            type='text'
                                                            className='texttraingrightdiv'
                                                            name="videoTitle"
                                                            placeholder={`videoTitle ${ind + 1}`}
                                                            value={element.videoTitle || ''}
                                                            onChange={e => handleChange(ind, e)}
                                                        />
                                                    </div>
                                                    <div className='videottldivwhgt'>
                                                        <p className="rsntxrpnewtxt"> Description </p>
                                                        <textarea className='txtdivallart'
                                                            id="w3review"

                                                            rows={4}
                                                            cols={50}
                                                            name="videoDescription"
                                                            placeholder={`videoDescription ${ind + 1}`}
                                                            value={element.videoDescription || ''}
                                                            onChange={e => handleChange(ind, e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='vdoulddivpbupldg'>
                                                    <p className="rsntxrpnewtxt">Upload Video</p>
                                                    <div className="bgcontainerupldbrdrdiv">
                                                        <div className="bgcrd">
                                                            <div className="bgcontaineruplddiv">
                                                                <div className="logouplddiv">
                                                                    <img src={Addicon} alt="..." />
                                                                    <div className="">
                                                                        <input
                                                                            type="file"
                                                                            className="upldimagediv"
                                                                            onChange={e => handleVideoChange(ind, e)}
                                                                            accept="video/*"
                                                                        ></input>
                                                                    </div>
                                                                </div>
                                                                <div class="bgcontainerupldfiledivabslt">
                                                                    {
                                                                        videos[ind]?.videoLink !== "" && (
                                                                            <div className='imageuplddivvideo' >

                                                                                <video
                                                                                    src={videos[ind]?.videoLink}
                                                                                    alt=""

                                                                                />
                                                                                <div className='clsoedivmainrd'
                                                                                    onClick={() => {

                                                                                        setvideos(prevVideos => {
                                                                                            const newVideos = [...prevVideos];
                                                                                            newVideos[ind].videoLink = "";
                                                                                            return newVideos;
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <i
                                                                                        class="fa-solid fa-xmark"

                                                                                    ></i>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {imageLoader5 ? (
                                                                        <>
                                                                            <h5>Video uploading....</h5>
                                                                        </>
                                                                    ) : (null)}
                                                                </div>
                                                            </div>
                                                            <p className="upldtxtppdiv">Upload Your Video</p>
                                                        </div>

                                                    </div>
                                                    {/* {imageLoader5 ? (
                                                        <>
                                                            <h5>Video uploading....</h5>
                                                        </>
                                                    ) : null} */}
                                                </div>

                                            </div>
                                            <div className=' isinthetraining'>
                                                <p className="rsntxrpnewtxtupld">
                                                    What is in the training
                                                </p>
                                                <div className='vdoulddivpbflx'>
                                                    <div className='vdoulddivpbflxwh'>
                                                        <div className='viewicontextflxshpwd'>
                                                            <p className="rsntxrpnewtxt">Duration of the training  </p>
                                                            <div className='addimgicondivggview'>
                                                                <img src={Viewicon} alt="..." />
                                                                <span className='hivermespaecial'>Minimum 30 mins Video Upload</span>
                                                            </div>
                                                        </div>
                                                        <div className='divtrainingmainflx'>
                                                            <div className='divtraininhfrst'>

                                                                <input
                                                                    type='number'
                                                                    className='texttraingrightdiv'
                                                                    name="hour"
                                                                    placeholder={`Duration in hour`}
                                                                    value={element?.videoDuration?.hour || ''}
                                                                    onChange={e => handleChangeVideoDur(ind, e)}
                                                                    min="0"
                                                                />
                                                            </div>
                                                            <div className='divtraininhfrst'>
                                                                <input
                                                                    type='number'
                                                                    className='texttraingrightdiv'
                                                                    name="min"
                                                                    placeholder={`Duration in min`}
                                                                    value={element?.videoDuration?.min || ''}
                                                                    onChange={e => handleChangeVideoDur(ind, e)}
                                                                    min="0"
                                                                /></div>

                                                        </div>

                                                    </div>

                                                    {/* Documents related to the training section */}
                                                    <div className='vdoulddivpbflxwh'>
                                                        {/* <p className="rsntxrpnewtxt">Documents related to the training </p> */}
                                                        <div className='uploaddocumentbrdraddbtnflx'>
                                                            {
                                                                element?.videoDocument?.map((element, index) => {

                                                                    return (<>

                                                                        <div className='uplddwnlddivbrtnmain'>
                                                                            <div className='uploaddocumentbrdraddbtn'>
                                                                                <div className="uploaddocumentbrdr">
                                                                                    <div className='bgcrdupload'>
                                                                                        <div className='bgcontaineruplddocumnet'>
                                                                                            <div className='addimgicondivgg'>
                                                                                                <img src={Addicon} alt="..." />
                                                                                                <div className="">
                                                                                                    <input
                                                                                                        type="file"
                                                                                                        className="upldimagediv"
                                                                                                        onChange={e => HandleVideoDoc(ind, index, e)}
                                                                                                    />
                                                                                                </div>


                                                                                            </div>

                                                                                            {imageLoader4 ? (
                                                                                                <>
                                                                                                    <h5> Document uploading....</h5>
                                                                                                </>
                                                                                            ) : null}
                                                                                        </div>
                                                                                        {index ? (
                                                                                            <div className='btnalignt'>
                                                                                                <div
                                                                                                    type="button"
                                                                                                    className=""
                                                                                                    onClick={() => removeVideoDocument(ind, index)}
                                                                                                // style={{ marginTop: '9px' }}
                                                                                                >
                                                                                                    <i className="fa-solid fa-trash"></i>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : null}
                                                                                    </div>

                                                                                </div>
                                                                                {/* <div className='btnalignt'>
                                                                            <button
                                                                                type="button"
                                                                                className='addmirfgghdivny'
                                                                                onClick={() => addVideosFields(ind)}
                                                                            >Add More</button>
                                                                        </div> */}

                                                                            </div>
                                                                        </div>

                                                                    </>)
                                                                })
                                                            }

                                                            <div className=''>
                                                                <button
                                                                    type="button"
                                                                    className='addmirfgghdivny'
                                                                    onClick={() => addVideosFields(ind)}
                                                                >Add More</button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div >

                                                {/* What Will You Lern section */}
                                                {
                                                    element?.videoLearningContent?.map((element, index) => {
                                                        return (
                                                            <div className=''>
                                                                <div className='trainingrifgtdivgappp'>
                                                                    <div className='trainingqustonbtndltflx'>
                                                                        <p className="rsntxrpnewtxt">What Will You Learn</p>
                                                                        <div className=''>
                                                                            {index ? (
                                                                                <div className='btndltimgbgdivdkt'>
                                                                                    <div
                                                                                        type="button"
                                                                                        className=""
                                                                                        onClick={() => removeContentDocument(ind)}
                                                                                    // style={{ marginTop: '9px' }}
                                                                                    >
                                                                                        <i className="fa-solid fa-trash"></i>
                                                                                    </div>
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>

                                                                    <div className=''>
                                                                        <div className='lrnupldinput'>
                                                                            <input
                                                                                type="text"
                                                                                className='texttraingrightdiv'
                                                                                name="content"
                                                                                placeholder={`Content ${index + 1}`}
                                                                                value={element?.content}
                                                                                onChange={e => handleDocumentChange(ind, index, e)}
                                                                            />
                                                                        </div>
                                                                        {/* <div className='addimngrtring'>
                                                                            <img src={Addicon} alt='...' />
                                                                        </div> */}

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    })
                                                }

                                                <div className='addimngrtring'>
                                                    <img src={Addicon}
                                                        onClick={() => addContentFields(ind)}
                                                        alt='...' />
                                                </div>
                                            </div>

                                        </div>


                                        {ind ? (
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-danger ml-1"
                                                onClick={() => removeFormFields(ind)}
                                            >
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        ) : null}
                                    </>)
                                })
                            }

                            <div className='addmirfgghdivnydiv'>
                                <button
                                    type='button'
                                    className='addmirfgghdivny'
                                    onClick={() => addFormFields()}
                                >
                                    Add More
                                </button>
                            </div>
                        </div >


                        <div className='trainingrifgtdivgappp'>
                            <p className="rsntxrpnewtxt">
                                Add any URL, where they will learn
                            </p>
                            <input
                                type='text'
                                className='texttraingrightdiv'
                                name="link"
                                value={link}
                                onChange={(e) => setlink(e.target.value)}
                            />

                        </div>

                        <div className='trainingrifgtdivgappp'>
                            <p className="rsntxrpnewtxt">
                                Training price (if any)
                            </p>
                            <input
                                type='number'
                                className='texttraingrightdiv'
                                name="trainingCost"
                                value={trainingCost}
                                onChange={(e) => settrainingCost(e.target.value)}
                            />
                        </div>

                        {/* Quiz Section */}
                        <div className='trainingrifgtdivgappp'>
                            <p className="rsntxrpnewtxt">
                                Quiz session:
                            </p>
                            <div className='aaddiconinptfkldimgiconaddflx'>
                                {
                                    trainingQuiz?.map((element, ind) => {
                                        return (
                                            <>
                                                <div className='aaddiconinptfklddibwhhj'>
                                                    <div className='aaddiconinptfkld'>
                                                        <div className='quentionnmbinputqust'>
                                                            <span className='questionnmbr'>
                                                                {`Q${ind + 1}`}
                                                                <input
                                                                    type='text'
                                                                    className='inptyflddiccv'
                                                                    name="question"
                                                                    placeholder={`question ${ind + 1}`}
                                                                    value={element.question || ''}
                                                                    onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className='anwrdivtyp'>
                                                            <p className='anstxtwrttype'>Answer Type :</p>
                                                            <select
                                                                name="quizType"
                                                                value={element.quizType}
                                                                id=""
                                                                className="answerdrpdwn"
                                                                onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                            >
                                                                <option>Select option</option>
                                                                <option value="Radio">Radio</option>
                                                                <option value="checkBox">Checkbox</option>
                                                            </select>

                                                        </div>


                                                        {/* Cheked button */}
                                                        {
                                                            element.quizType === "checkBox"
                                                            &&
                                                            (<div className='cjhekeddivtxtdivmain'>
                                                                <>
                                                                    {/* A  */}
                                                                    <div className='cjhekeddivtxt'>
                                                                        <input
                                                                            type='checkbox'
                                                                            name="answer"
                                                                            value="A"
                                                                            checked={element.answer === "A"}
                                                                            onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        <label htmlFor="vehicle1">A. </label>

                                                                        <input
                                                                            type="text"
                                                                            defaultValue=""
                                                                            name="A"
                                                                            placeholder={`A ${ind + 1}`}
                                                                            value={element.A || ''}
                                                                            onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        {/* <label htmlFor="vehicle1"> I have a bike</label> */}
                                                                    </div>


                                                                    {/* B */}
                                                                    <div className='cjhekeddivtxt'>
                                                                        <input
                                                                            type='checkbox'
                                                                            name="answer"
                                                                            value="B"
                                                                            checked={element.answer === "B"}
                                                                            onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        <label htmlFor="vehicle1">B. </label>
                                                                        <input
                                                                            type="text"
                                                                            defaultValue=""
                                                                            name="B"
                                                                            placeholder={`B ${ind + 1}`}
                                                                            value={element.B || ''}
                                                                            onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        {/* <label htmlFor="vehicle1"> I have a bike</label> */}
                                                                    </div>

                                                                    {/* C */}
                                                                    <div className='cjhekeddivtxt'>
                                                                        <input
                                                                            type='checkbox'
                                                                            name="answer"
                                                                            value="C"
                                                                            checked={element.answer === "C"}
                                                                            onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        <label htmlFor="vehicle1">C. </label>
                                                                        <input
                                                                            type="text"
                                                                            defaultValue=""
                                                                            name="C"
                                                                            placeholder={`C ${ind + 1}`}
                                                                            value={element.C || ''}
                                                                            onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        {/* <label htmlFor="vehicle1"> I have a bike</label> */}
                                                                    </div>

                                                                    <div className='cjhekeddivtxt'>
                                                                        <input
                                                                            type='checkbox'
                                                                            name="answer"
                                                                            value="D"
                                                                            checked={element.answer === "D"}
                                                                            onChange={(e) => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        <label htmlFor="vehicle1">D. </label>
                                                                        <input
                                                                            type="text"
                                                                            defaultValue=""
                                                                            name="D"
                                                                            placeholder={`D ${ind + 1}`}
                                                                            value={element.D || ''}
                                                                            onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                        />
                                                                        {/* <label htmlFor="vehicle1"> I have a bike</label> */}
                                                                    </div>

                                                                    {/* <div className='cjhekeddivtxt'>
                                                                    <label htmlFor="vehicle1">Answer : </label>
                                                                    <input
                                                                        type="text"
                                                                        defaultValue=""
                                                                        name="answer"
                                                                        placeholder={`answer option ${ind + 1}`}
                                                                        value={element.answer || ''}
                                                                        onChange={e => handleChangeTraingQuiz(ind, e)}
                                                                    />
                                                                    
                                                                </div> */}

                                                                </>

                                                            </div>)
                                                        }

                                                        {
                                                            element.quizType === "Radio"
                                                            &&
                                                            (
                                                                <div className=''>
                                                                    <>
                                                                        {/* <div className='radiobtntxtpp'>
                                                                            <input type="radio" id="html" name="fav_language" defaultValue="HTML" />
                                                                            <label htmlFor="html">Yes</label>
                                                                        </div>

                                                                        <div className='radiobtntxtpp'>

                                                                            <input type="radio" id="css" name="fav_language" defaultValue="CSS" />
                                                                            <label htmlFor="css">No</label>
                                                                        </div> */}

                                                                        <div className='radiobtntxtpp'>
                                                                            <input
                                                                                type="radio"
                                                                                id={`radio-${ind}-A`}
                                                                                name="answer"
                                                                                value="yes"
                                                                                checked={element.answer === "A"}
                                                                                onChange={(e) => handleChangeTraingRadio(ind, e)}
                                                                            />
                                                                            <label htmlFor={`radio-${ind}-A`}>Yes</label>
                                                                        </div>

                                                                        <div className='radiobtntxtpp'>
                                                                            <input
                                                                                type="radio"
                                                                                id={`radio-${ind}-B`}
                                                                                name="answer"
                                                                                value="no"
                                                                                checked={element.answer === "B"}
                                                                                onChange={(e) => handleChangeTraingRadio(ind, e)}
                                                                            />
                                                                            <label htmlFor={`radio-${ind}-B`}>No</label>
                                                                        </div>

                                                                    </>

                                                                </div>)
                                                        }

                                                    </div>
                                                    {/* <div className='addimngrtring'>
                                                    <img src={Addicon} type='button' onClick={() => addFormFieldsTrainingQuiz()} alt="..." />
                                                </div> */}
                                                </div>
                                            </>
                                        )
                                    })
                                }
                                <div className='addimngrtring'>
                                    <img src={Addicon} type='button' onClick={() => addFormFieldsTrainingQuiz()} alt="..." />
                                </div>
                            </div>
                        </div>


                        <div className="trainingrifgtdivgappp ">
                            <p className="rsntxrpnewtxt">Select Department Name</p>

                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}

                                options={DepartmentDropdown}
                                onChange={handleSelectDepartName}
                                value={DepartmentDropdown?.find(option => option.value === DepartmentName)}
                            />
                        </div>

                        <div className='trainingrifgtdivgappp'>
                            <p className="rsntxrpnewtxt">
                                Target Audience:
                            </p>
                            

                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                defaultValue={[]}
                                isMulti
                                options={options}
                                onChange={handleSelectChangeaudiance}
                                value={options?.filter(option => targetAudiance?.includes(option.value))}
                            />
                        </div>

                        <div className='trainingrifgtdivgappp'>
                            <p className="rsntxrpnewtxt">
                                Materials/Equipment Needed
                            </p>
                            <textarea className='txtdivallart'
                                id="w3review"
                                rows={4}
                                cols={50}
                                name="materials"
                                value={materials}
                                onChange={(e) => setmaterials(e.target.value)}
                            />
                        </div>

                        <div className="homePgCreModSubmitDiv">
                            <button
                                className="homePgCreModSubmitBtn"
                                type="button"
                                onClick={submitHandler}
                            >
                                Submit
                            </button>
                        </div>

                    </form>


                </div>

            </div>
        </>
    )
}

export default TrainingCreateVirtual