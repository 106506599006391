import React, { useState,useEffect } from 'react'
// import Select from "react-select";
// import advcyimgtwo from "../../Images/advcyimgtwo.jpeg";
import Addicon from "../../Images/Icons/PNG/Add icon.png"
import toast from 'react-hot-toast';
import HttpClientXml from '../../utils/HttpClientXml';

const CreateAdvocacy = () => {
    const iniData = {
        AdvocacyName: "",
        advocacyCatId: "",
        desc: "",
        language: ""
    }
    const [formData, setFormData] = useState(iniData);
    const [uploading, setUploading] = useState(false);
    const [image, setImage] = useState("");
    const [imageURL, setImageURL] = useState("");
    const [uploadingFile, setUploadingFile] = useState(false);
    const [fileURL, setFileURL] = useState("");
    const [fileData, setFile] = useState("");
    const [catdata, setCatData] = useState([]);


    //for onChange functionality
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }))
    }

    //for thumbnail image uploading
    const handleImageChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await HttpClientXml.fileUplode("image-upload", "POST", form);

        if (res.status) {
            toast.success("Image uploaded successfully");
            setImageURL(res?.image);
        } else {
            toast.error("Error uploading image");
        }
        setUploading(false);
    };

    //for file uploading
    const handleFileChange = async (e) => {
        let image = e.target.files[0];
        setUploadingFile(true);

        const form = new FormData();
        form.append("image", image);

        let res = await HttpClientXml.fileUplode("image-upload", "POST", form);

        if (res.status) {
            toast.success("File is uploaded successfully");
            setFileURL(res?.image);
        } else {
            toast.error("Error uploading image");
        }
        setUploadingFile(false);
    };

    //for getting all Category data
    const getCategoryData = async () => {
        const response = await HttpClientXml?.requestData(`view-advocacy-category`, {}, "GET");
        console.log("catdata",response)
        if (response.status) {
            setCatData(response?.data);
        } else {

        }
    };


    //for submit section
    const handleSubmit = async () => {
        if (formData?.AdvocacyName === "") {
            toast.error("Document Type is required");
            return false;
        }

        if (formData?.language === "") {
            toast.error("Language is required");
            return false;
        }

        if (formData?.advocacyCatId === "") {
            toast.error("Advocacy catagory is required");
            return false;
        }

        if (imageURL === "") {
            toast.error("Advocacy image is required");
            return false;
        }

        if (formData?.desc === "") {
            toast.error("Description is required");
            return false;
        }

        if (fileURL === "") {
            toast.error("Material is required");
            return false;
        }

        let data = {
            AdvocacyName: formData?.AdvocacyName,
            advocacyCatId: formData?.advocacyCatId,
            language: formData?.language,
            image: imageURL,
            desc: formData?.desc,
            material: fileURL
        };

        let response = await HttpClientXml?.requestData(`add-advocacy`, data, "POST");
        if (response.status) {
            toast.success(response?.message);
            setFormData(iniData)
            setImageURL("");
            setFileURL("");

        } else {
            toast.error(response?.response?.data?.message);
        }
    };


    useEffect(() => {
        getCategoryData();
    }, [])

    return (
        <>
            <section className='createeventdetails'>
                <div className='detailspagecontainer'>
                    <div className='createdivfrmmain'>
                        <form>
                            <div className=''>
                                <div className='vdoulddivpbupldgteodivflx'>
                                    <div className="vdoulddivpbupldgteodivfrst">
                                        <p className="rsntxrpnewtxt">  Upload Image <span className='required'>*</span></p>

                                        <div className="bgcontainerupldboxsgwgrdiv">
                                            <div className="bgcrd">
                                                <div className="bgcontaineruplddivnew">
                                                    <div className="logouplddiv">

                                                        <img
                                                            src={Addicon}
                                                            alt="..."
                                                        />
                                                        <div className="">
                                                            <input
                                                                type="file"
                                                                id="hostedBy"
                                                                className="upldimagediv"
                                                                placeholder="Choose a photo"
                                                                name="image"
                                                                value={image}
                                                                onChange={handleImageChange}

                                                            />
                                                        </div>
                                                    </div>

                                                </div>

                                                <p className="upldtxtppdiv">Upload min 5 mb image</p>

                                                {/* After Upload Design Start */}

                                                <div className="bgcontainerupldfiledivabslt">
                                                    {
                                                        imageURL && (
                                                            <div className="imageuplddiv">
                                                                <img src={imageURL} alt />
                                                                <div className="imageupldcrs" style={{
                                                                    cursor: "pointer"
                                                                }}>
                                                                    <i className="fa-solid fa-xmark"
                                                                        onClick={() => {
                                                                            setImageURL("");
                                                                            setImage("");
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    }


                                                </div>
                                                {/* After Upload Design End */}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="vdoulddivpbupldgteodivfrst">
                                        <p className="rsntxrpnewtxt">  Upload Documents <span className='required'>*</span></p>

                                        <div className="bgcontainerupldboxsgwgrdiv">
                                            <div className="bgcrd">
                                                <div className="bgcontaineruplddivnew">
                                                    <div className="logouplddiv">

                                                        <img
                                                            src={Addicon}
                                                            alt="..."
                                                        />
                                                        <div className="">
                                                            <input
                                                                type="file"
                                                                id="hostedBy"
                                                                className="upldimagediv"
                                                                placeholder="Choose a file"
                                                                name="fileData"
                                                                defaultValue=""
                                                                value={fileData}
                                                                onChange={handleFileChange}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>

                                                <p className="upldtxtppdiv">Upload Document</p>

                                                {/* After Upload Design Start */}

                                                <div className="bgcontainerupldfiledivabslt">
                                                    {
                                                        fileURL && (
                                                            <>
                                                                <div className="imageuplddiv">
                                                                    <img src={fileURL} alt />
                                                                    <div className="imageupldcrs" style={{
                                                                        cursor: "pointer"
                                                                    }}>
                                                                        <i className="fa-solid fa-xmark"
                                                                            onClick={() => {
                                                                                setFileURL("");
                                                                                setFile("");
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }


                                                </div>
                                                {/* After Upload Design End */}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt"> Select Category <span className='required'>*</span></p>

                                    {/* <Select
                                        placeholder={"Select Event Type"}

                                        className="typstrningwhselctnew"
                                    /> */}
                                    <select id=""
                                        className="typstrningwhselctnew"
                                        name="advocacyCatId"
                                        value={formData?.advocacyCatId}
                                        onChange={(e) => handleChange(e)}
                                    >

                                        <option value={""} disabled="">
                                            Select option
                                        </option>
                                        {catdata?.map((item, i) => (
                                            <option key={i} value={item?._id}>
                                                {item?.categoryName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Advocacy Language <span className='required'>*</span></p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name="language"
                                        value={formData?.language}
                                        defaultValue=""
                                        onChange={(e) => handleChange(e)}
                                    />

                                </div>
                                <div className="trainingpageleftdivmain">
                                    <p className="rsntxrpnewtxt">Advocacy Title <span className='required'>*</span></p>
                                    <input
                                        type="text"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        className="typstrningwhselctnew"
                                        name="AdvocacyName"
                                        value={formData?.AdvocacyName}
                                        onChange={(e) => handleChange(e)}
                                    />

                                </div>
                                <div className="trainingrifgtdivgappp">
                                    <p className="rsntxrpnewtxt">Description</p>
                                    <textarea
                                        id=""
                                        rows={4}
                                        cols={50}
                                        className="txtdivallartadvcacytfnew"
                                        name="desc"
                                        value={formData?.desc}
                                        onChange={(e) => handleChange(e)}
                                    ></textarea>

                                </div>
                                <div className="bulletinsbmtbtndivv">
                                    <button
                                        className="bulletinsbmtbtn"
                                        type="button" 
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreateAdvocacy
