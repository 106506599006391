import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import imgprlsthgt from "../../Images/imgprlsthgt.jpg"
import Unfollow from '../../Modal/Unfollow';
import Removedfollow from '../../Modal/Removedfollow';
const Connection = () => {
    const [unfollowmodal,setUnfollowmodal]=useState(false);
    const [removefollowmodal,setRemovefollowmodal]=useState(false)
    const unfollowclick =()=>{
        setUnfollowmodal(!unfollowmodal)
    }
    const followbackclick =()=>{
        setRemovefollowmodal(!removefollowmodal)
    }
    return (
        <>
            <section className='connectgionmain'>
                <div className='esgpagecontainer'>
                    <div className='connectiondivmainflex'>
                        <div className='connectiondivright'>

                        </div>

                        <div className='connectiondivleft'>
                            <div className='connectiondileftbg'>
                                <div className="followerrighttopup">
                                    <p className="followtoppppxyu">
                                        Niladri Roy's Network</p>
                                </div>
                                <div className='followerrighttopbtm'>
                                   
                                        <Tabs>
                                        <div className='followerrighttopbtmden'>
                                            <TabList>
                                                <Tab>Following</Tab>
                                                <Tab>Follwers</Tab>
                                            </TabList>
                                            </div>
                                            <div className='followerrighttopbtmdwnl'>
                                                
                                            <TabPanel>
                                                <div className="prflimgdtlsfllowdivflxflwbtn">
                                                    <div className="prflimgdtlsfllowdivflx">
                                                        <div className="profilefollowimg">
                                                            <img
                                                                src={imgprlsthgt}
                                                                className="img-fluid"
                                                                alt="profile"
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <p className="followtxtpppnmjl">Komal Roy</p>
                                                            <p className="followtxtpppnmjldsgnty">Node.js</p>
                                                        </div>
                                                    </div>
                                                    <div className="messageflowwingdivflx">
                                                        <button className="followingbtndivnm">Message</button>
                                                        <button className="followingbtndivnm" onClick={unfollowclick}>Unfollow</button>
                                                    </div>
                                                </div>
                                                <div className="prflimgdtlsfllowdivflxflwbtn">
                                                    <div className="prflimgdtlsfllowdivflx">
                                                        <div className="profilefollowimg">
                                                            <img
                                                                src={imgprlsthgt}
                                                                className="img-fluid"
                                                                alt="profile"
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <p className="followtxtpppnmjl">Komal Roy</p>
                                                            <p className="followtxtpppnmjldsgnty">Node.js</p>
                                                        </div>
                                                    </div>
                                                    <div className="messageflowwingdivflx">
                                                        <button className="followingbtndivnm">Message</button>
                                                        <button className="followingbtndivnm" onClick={unfollowclick}>Unfollow</button>
                                                    </div>
                                                </div>
                                                <div className="prflimgdtlsfllowdivflxflwbtn">
                                                    <div className="prflimgdtlsfllowdivflx">
                                                        <div className="profilefollowimg">
                                                            <img
                                                                src={imgprlsthgt}
                                                                className="img-fluid"
                                                                alt="profile"
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <p className="followtxtpppnmjl">Komal Roy</p>
                                                            <p className="followtxtpppnmjldsgnty">Node.js</p>
                                                        </div>
                                                    </div>
                                                    <div className="messageflowwingdivflx">
                                                        <button className="followingbtndivnm">Message</button>
                                                        <button className="followingbtndivnm" onClick={unfollowclick}>Unfollow</button>
                                                    </div>
                                                </div>
                                                <div className="prflimgdtlsfllowdivflxflwbtn">
                                                    <div className="prflimgdtlsfllowdivflx">
                                                        <div className="profilefollowimg">
                                                            <img
                                                                src={imgprlsthgt}
                                                                className="img-fluid"
                                                                alt="profile"
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <p className="followtxtpppnmjl">Komal Roy</p>
                                                            <p className="followtxtpppnmjldsgnty">Node.js</p>
                                                        </div>
                                                    </div>
                                                    <div className="messageflowwingdivflx">
                                                        <button className="followingbtndivnm">Message</button>
                                                        <button className="followingbtndivnm" onClick={unfollowclick}>Unfollow</button>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="prflimgdtlsfllowdivflxflwbtn">
                                                    <div className="prflimgdtlsfllowdivflx">
                                                        <div className="profilefollowimg">
                                                            <img
                                                                src={imgprlsthgt}
                                                                className="img-fluid"
                                                                alt="profile"
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <p className="followtxtpppnmjl">Komal Roy</p>
                                                            <p className="followtxtpppnmjldsgnty">Node.js</p>
                                                        </div>
                                                    </div>
                                                    <div className="messageflowwingdivflx">
                                                        <button className="followingbtndivnm">Message</button>
                                                        <button className="followingbtndivnm" onClick={followbackclick}>Follow Back</button>
                                                    </div>
                                                </div>
                                                <div className="prflimgdtlsfllowdivflxflwbtn">
                                                    <div className="prflimgdtlsfllowdivflx">
                                                        <div className="profilefollowimg">
                                                            <img
                                                                src={imgprlsthgt}
                                                                className="img-fluid"
                                                                alt="profile"
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <p className="followtxtpppnmjl">Komal Roy</p>
                                                            <p className="followtxtpppnmjldsgnty">Node.js</p>
                                                        </div>
                                                    </div>
                                                    <div className="messageflowwingdivflx">
                                                        <button className="followingbtndivnm">Message</button>
                                                        <button className="followingbtndivnm" onClick={followbackclick}>Follow Back</button>
                                                    </div>
                                                </div>
                                                <div className="prflimgdtlsfllowdivflxflwbtn">
                                                    <div className="prflimgdtlsfllowdivflx">
                                                        <div className="profilefollowimg">
                                                            <img
                                                                src={imgprlsthgt}
                                                                className="img-fluid"
                                                                alt="profile"
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <p className="followtxtpppnmjl">Komal Roy</p>
                                                            <p className="followtxtpppnmjldsgnty">Node.js</p>
                                                        </div>
                                                    </div>
                                                    <div className="messageflowwingdivflx">
                                                        <button className="followingbtndivnm">Message</button>
                                                        <button className="followingbtndivnm" onClick={followbackclick}>Follow Back</button>
                                                    </div>
                                                </div>
                                                <div className="prflimgdtlsfllowdivflxflwbtn">
                                                    <div className="prflimgdtlsfllowdivflx">
                                                        <div className="profilefollowimg">
                                                            <img
                                                                src={imgprlsthgt}
                                                                className="img-fluid"
                                                                alt="profile"
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <p className="followtxtpppnmjl">Komal Roy</p>
                                                            <p className="followtxtpppnmjldsgnty">Node.js</p>
                                                        </div>
                                                    </div>
                                                    <div className="messageflowwingdivflx">
                                                        <button className="followingbtndivnm">Message</button>
                                                        <button className="followingbtndivnm" onClick={followbackclick}>Follow Back</button>
                                                    </div>
                                                </div>

                                            </TabPanel>
                                            
                                            </div>
                                        </Tabs>
                                    </div>

                                </div>

                           
                        </div>
                    </div>
                </div>
            </section>

            {unfollowmodal && <Unfollow closemodal={setUnfollowmodal}/>}
            {removefollowmodal && <Removedfollow closemodal={setRemovefollowmodal}/>}
        </>
    )
}

export default Connection

