import moment from 'moment';
import React from 'react'
import { useLocation } from 'react-router-dom'
const EventSingle = () => {
    const location=useLocation();
    const eventData=location?.state; 
    return (
        <>
            <section className='bulletinsinglemain'>
                <div className='detailspagecontainer'>
                    <div className='bulletinimgmain'>
                        <img src={eventData?.image} alt="..." />
                    </div>
                    <div className='bulletinimgmaindtlstxt'>
                        <div className=''>
                            <p className='builletindtlstsxtpp' >Event Name :
                                <span>
                                    {eventData?.eventName}
                                </span>
                            </p>
                        </div>
                        <div className=''>
                            <p className='builletindtlstsxtpp'>Note :
                                <span>
                                   {eventData?.notes}
                                </span>
                            </p >
                        </div>
                        <div className=''>
                            <p className='builletindtlstsxtpp' >Date :
                                <span>
                                 {moment(eventData?.eventDate).format("YYYY-MM-DD")}
                                </span>
                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default EventSingle
