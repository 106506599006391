import moment from 'moment';
import React, { useEffect, useState } from 'react'

import "react-big-calendar/lib/css/react-big-calendar.css";
import EventsBigCalenderModal from '../Modal/EventBigCalenderModal';
import { useAuthCtx } from '../Context/AuthCtx';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';

const localizer = momentLocalizer(moment);


const BigCalender = ({ myEventsList }) => {
    //   console.log("myEventsList", myEventsList)
    const { setCalenderMonth, setCalenderYear } =
        useAuthCtx();
    const [isListViewActive, setIsListViewActive] = useState(false);
    const [viewType, setViewType] = useState(Views.MONTH);
    const [eventsForSelectedDate, setEventsForSelectedDate] = useState([]);
    const [eventsForSelectedDateMore, setEventsForSelectedDateMore] = useState([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);


    //for navigating functionality
    const handleNavigate = (date, view, action) => {
        // console.log("dateVVaa", date, view, action)
        let NewDate;

        if (!date) {
            NewDate = new Date();
        } else {
            NewDate = new Date(date);
        }

        if (action === 'TODAY') {
            setViewType(Views.MONTH);
        } else if (action === 'PREV') {
            NewDate.setMonth(NewDate.getMonth() - 1);
        } else if (action === 'NEXT') {
            NewDate.setMonth(NewDate.getMonth() + 1);
        }

        const getmonth = NewDate.getMonth() + 1;
        const getYear = NewDate.getFullYear();

        setCalenderYear(getYear);
        setCalenderMonth(getmonth);

        // Check if the agenda view is active
        if (action === 'agenda') {
            setIsListViewActive(true);
        } else {
            setIsListViewActive(false);
        }

        if (action === 'ANNUALLY') { // New condition for handling the annual view
            setViewType(Views.AGENDA);
            // return;
        }
    };

    //for header part of the calender
    const CustomToolbar = (toolbar) => {
        return (
            <div className="rbc-toolbar">
                <span className="rbc-btn-group">
                    <button type="button" onClick={() => toolbar.onNavigate('PREV')}>
                        {"<"}
                    </button>
                    <button type="button" onClick={() => toolbar.onNavigate('TODAY')}>
                        Month
                    </button>
                    <button type="button" onClick={() => toolbar.onNavigate('NEXT')}>
                        {">"}
                    </button>

                </span>
                <span className="rbc-toolbar-label">{toolbar.label}</span>

                <span className="rbc-btn-group">
                    <button type="button" onClick={() => handleNavigate(null, null, 'ANNUALLY')}>
                        List of events
                    </button>

                </span>
            </div>
        );
    };


    //event details in modal
    const convertedEvents = myEventsList?.reduce((prev, current) => {
        console.log("first", current)
        let data = {
            id: current._id,
            title: current.eventName,
            image: current.image,
            notes: current.notes,
            start: new Date(current.eventDate.toString()),
            end: new Date(current.eventDate.toString()),
            count: 0,
            additionalEvents: [],
        };
        let index = prev.findIndex(
            (it) =>
                moment(it?.start).format("YYYY-MM-DD") ===
                moment(current?.eventDate).format("YYYY-MM-DD")
        );
        if (index >= 0) {
            prev[index].count += 1;
            prev[index].additionalEvents.push(data);
            return [...prev];
        } else {
            return [...prev, data];
        }
    }, []);


    //event image or title show in calender
    const CustomEvent = ({ event, onClickMore }) => {

        return (<>
            {event?.image === "" ? (
                <>
                    <div onClick={() => onClickMore(event)}>{event?.title}</div>
                </>
            ) : (
                <div className="eventimagebgoverlay">
                    <img
                        src={event?.image}
                        alt="Event Image"
                        onClick={() => onClickMore(event)}
                    />
                </div>
            )}
            {
                event.count > 0 ?
                    <h5 className="hhmoretxtevent"
                        // onClick={(e) => {
                        //   e.stopPropagation()
                        //   onClickMore(e)
                        //   // console.log("stoppdd")
                        // }}
                        onClick={() => onClickMore(event)}
                    >

                        +{event?.count} more
                    </h5>
                    :
                    null
            }
        </>)
    }

    //for openning modal
    const handleDateChange = (e) => {
        // console.log("first",e)
        setIsOpen(true);
        setEventsForSelectedDate(e);
    };

    //for openning modal for more events in the modal
    const handleMoreEvents = (event) => {
        const additionalEvents = event?.additionalEvents || [];
        setEventsForSelectedDateMore(additionalEvents)
    };


    //for time slot
    const MyAgendaTime = () => {
        <></>
    }

    //for close Eventbigcalendermodal
    const closemodal = () => {
        setIsOpen(false)
    }


    //display list of events
    const renderListView = () => {
        if (isListViewActive) {
            return (
                <div>
                    <button onClick={() => setIsListViewActive(false)}>back</button>
                    <div>
                        {
                            convertedEvents?.map((item, i) =>
                                <p>
                                    <span className="mr-5">{moment(item?.start).format("LL")}</span>
                                    {
                                        item?.image ? (<img src={item?.image} style={{ height: "50px", width: "50px" }} onClick={() => handleDateChange(item)} />) : (<span onClick={() => handleDateChange(item)}> {item?.title}</span>)
                                    }

                                </p>
                            )
                        }
                    </div>
                </div>
            );
        }
        return null;
    };

    useEffect(() => {
        handleNavigate();
    }, []);


    return (
        <>
            <div>
                {
                    !isListViewActive &&
                    <Calendar
                        localizer={localizer}
                        events={convertedEvents}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 650 }}
                        onNavigate={(date, view, action) => handleNavigate(date, view, action)}
                        views={[Views.MONTH, Views.AGENDA]}
                        onView={(view) => setViewType(view)}
                        view={viewType}
                        messages={{ agenda: "List Of Events", previous: "<", next: ">", time: "" }}

                        components={{
                            toolbar: CustomToolbar,
                            event: (props) => (
                                <CustomEvent {...props} onClickMore={handleMoreEvents} />
                            ),
                            time: MyAgendaTime,
                        }}
                        onSelectEvent={(e) => handleDateChange(e)}
                    />
                }


               
                  {  modalIsOpen &&  <EventsBigCalenderModal
                        closemodal={closemodal}
                        eventsForSelectedDate={eventsForSelectedDate}
                        eventsForSelectedDateMore={eventsForSelectedDateMore}
                    />
                  }
              

                {/* Display the list of events */}
                {renderListView()}

            </div>

        </>
    )
}

export default BigCalender