import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { BsEmojiSmileFill } from "react-icons/bs";
import EmojiPicker from 'emoji-picker-react';
import post from "../../src/Images/Icons/PNG/Post.png";
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { Link } from 'react-router-dom';
import HttpClientXml from '../utils/HttpClientXml';
import { getAllPosts } from '../Redux/Slice/PostSlice';
import ProfileImg1 from "../../src/Images/girl.png";


const RepostModal = ({ closemodal, postId, imagesArray }) => {
    const userData = useSelector((state) => state?.StatusCardSlice?.data);
    const [isEmoji, setIsEmoji] = useState(false);
    const [text, setText] = useState("");
    const dispatch = useDispatch();

    //for  repost in home page
    const handlePost = async () => {
        let data = {
            postId: postId,
            repostType:imagesArray?.docType,
            text: text,
        }
        // console.log("repost",data)
        // return false;
        let response = await HttpClientXml?.requestData("repost", data, "POST");
        if (response?.status) {
            setText("");
            closemodal();
            toast.success(response?.msg);
            dispatch(getAllPosts());
        } else {
            toast.error(response?.msg);
        }
    }


    // console.log("ghrtmh", imagesArray)
    return (
        <>

            <div className='thopughtmodabgmain'>
                <div className='editcmntmodalbg'>
                    <div className='editetopmodal'>
                        <p className='editetxtppbjdl'>Repost</p>
                        <div className='cersgeditpdg' onClick={() => closemodal()}>
                            <i class="fa-solid fa-xmark"></i>
                        </div>

                    </div>
                   
                    <div className='cmntpprfcdivflxedtjb'>

                        <div className='cmntpprfcdivflxsigfghbvh'>

                            <div className='profilemcmnrtimg'>
                                {/* <img src={profile} alt='...' /> */}

                                {userData?.image ? (
                                    <img
                                        src={userData?.image}
                                        alt="profile"
                                    />
                                ) : (
                                    <img
                                        src={ProfileImg1}
                                        alt="profile"
                                    />
                                )}

                            </div>

                            <div className='cmntscedtdiv'>
                                <form>
                                    <div className='cmnttsttxt'>
                                        <textarea
                                            className=''
                                            name='text'
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                        ></textarea>
                                        <div className='cmntstocnobdivflcpdadad'>

                                            <BsEmojiSmileFill className="comment-emoji-icon"
                                                onClick={() => setIsEmoji(prev => !prev)}
                                            />
                                            {isEmoji &&
                                                <EmojiPicker
                                                    onEmojiClick={(emojiObject) => setText(prev => prev + emojiObject.emoji)}
                                                />
                                            }

                                            <div className='rcmgdivmai' onClick={handlePost}>
                                                <img src={post} alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                  

                        <div className='topdivrepstdiv'>
                            <div className='left'>
                                <div className='prflimgshrthgh'>
                                    <img src={imagesArray?.userData?.[0]?.image || imagesArray?.userImage} alt="..." />
                                </div>
                                <div className='sharedetails'>
                                    <div className='name'>
                                        <h2 className='nametxthh'>{imagesArray?.userData?.[0]?.firstName + " " + imagesArray?.userData?.[0]?.lastName}</h2>
                                        <div className='brnztztpp'>
                                            <div className='imgbrnz'>
                                                {/* <img src={r} alt="..." /> */}
                                                {
                                                    imagesArray?.userData?.[0]?.rewardCard?.[0]?.cardImage
                                                        ?
                                                        <img src={imagesArray?.userData?.[0]?.rewardCard?.[0]?.cardImage} alt='...' />
                                                        :
                                                        <></>
                                                }
                                            </div>
                                            {/* <div className=''>
                                                <p className='brnzttxy'>{post?.userData?.[0]?.rewardCard?.[0]?.cardImage} badge holder</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='text'>
                                        <p className='emplyrtxtpp'>{imagesArray?.type} ,</p>
                                        <p className='emplyrtxtpp'>Posted on {moment(imagesArray?.createdOn)?.format("YYYY-MM-DD")}</p>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="">
                            <div className="content"
                                style={{ cursor: "pointer" }
                                }
                            >

                                <div className='top_next' style={{ cursor: "pointer" }}>
                                    <p className='lrmtxtpptxtpp'> {imagesArray?.description}</p>
                                </div>


                            </div>

                            <div className="gallery_area">

                                {
                                    imagesArray?.image?.[0]
                                        ?
                                      
                                           
                                                <div className='sharethghtsimgrepdygd'>
                                                    <img src={imagesArray?.image} alt="..." />
                                                </div>
                                           
                                      
                                        :
                                        <></>
                                }


                                {
                                    imagesArray?.video?.[0] ? (<div className='trainingdivmainedt' >
                                        <ReactPlayer
                                            className='reactdivimgmainplrt'
                                            controls
                                            width='40rem'
                                            height='20rem'
                                            playing={false}
                                            url={imagesArray?.video}
                                            config={{
                                                youtube: {
                                                    playerVars: { showinfo: 1 }
                                                },
                                                facebook: {
                                                    appId: '12345'
                                                },
                                            }}

                                        />
                                    </div>) : (<></>)
                                }
                                <div>



                                </div>

                            </div>

                        </div>

                 
                </div>

            </div >



        </>
    )
}

export default RepostModal