import React from 'react'
import oimgemnusn from "../../Images/oimgemnusn.png"
const Myteamgropusingle = () => {
  return (
    <>
      <section className='myteamgrpsinglemainsection'>
        <div className='detailspagecontainer'>
          <div className='bulletinimgmain'>
            <img src={oimgemnusn} alt="..." />
          </div>
          <div className='bulletinimgmaindtlstxt'>
            <div className=''>
              <h4 className='grptxtsstxtpp'>
                Star Group Testing
              </h4>

              <p className='grotsxttdxpphjh'>Admin Name :
                <span>
                  Niladri Roy
                </span>
              </p>
              <p className='grotsxttdxpphjh'>No. of Members :
                <span>
                 4
                </span>
              </p>
              <p className='grotsxttdxpphjh'>Group Member :
                <span>
                  Niladri Roy, Rohit Roy, Simran Agarwal, Niladri Pal
                </span>
              </p>
              <p className='grotsxttdxpphjh'>Purpose :
                <span>
                 Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, debitis ad iure dolor ratione eaque officiis nulla! Voluptas reiciendis repellendus ipsa aliquid unde ut mollitia nostrum quam consectetur explicabo cum officia, deserunt saepe, aperiam nam iste. Suscipit corrupti ea eligendi doloribus quibusdam omnis fuga placeat eos iure deserunt unde excepturi hic harum nam blanditiis ad magni est impedit, perferendis vero dolor. Quas ipsum atque ratione earum fugiat exercitationem molestiae laudantium nobis qui modi itaque iusto, est esse, recusandae odit praesentium non accusamus fugit nisi eos libero magni sit corporis. Quia molestias deserunt quod molestiae minus necessitatibus maxime fugit incidunt ullam?     
                 </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Myteamgropusingle
