import React, { useEffect, useState } from 'react'
import Addicon from "../../src/Images/Icons/PNG/Add icon.png";
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import HttpClientXml from '../utils/HttpClientXml';
import { getAllPosts } from '../Redux/Slice/PostSlice';
import { useFormik } from 'formik';

const PostEditmodal = ({ closemodal, post }) => {
    const dispatch = useDispatch();
    const [images, setImages] = useState([]);
    const [image, setImage] = useState([]);
    const [video, setVideo] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const initialValues = post;

    //for updation functionality
    const submitHandler = async (e) => {
        e.preventDefault();
        setShouldValidateOnChange(true);

        let data = {};
        data = {

            description: values?.description,
            image: images,
            video: video
        };

        if (post?.docType === "homePost") {
            const res = await HttpClientXml?.requestData(`edit-post/${initialValues?._id}`, data, "PUT")

            if (res?.status) {
                toast?.success("Post is updated successfully");
                resetForm();
                dispatch(getAllPosts());
                closemodal();
            } else {

            }
        }


        if (post?.docType === "eventData") {
            data = {
                notes: values?.notes,
                image: image
            };
            const res = await HttpClientXml?.requestData(`update-event/${initialValues?._id}`, data, "PUT");

            if (res?.status) {
                toast?.success("Post is updated successfully");
                resetForm();
                dispatch(getAllPosts());

                closemodal();
            } else {

            }
        }


        if (post?.docType === "advocacyPost") {

            data = {

                description: values?.description,
                image: image
            };
            const res = await HttpClientXml?.requestData(`update-approved-advocacy/${initialValues?._id}`, data, "PUT");

            if (res?.status) {
                toast?.success("Post is updated successfully");
                resetForm();
                dispatch(getAllPosts());
                closemodal();
            } else {

            }
        }

        if (post === undefined) {
            const res = await HttpClientXml?.requestData(`edit-post/${initialValues?._id}`, data, "PUT")
            if (res?.status) {
                toast?.success("Post is updated successfully");
                resetForm();
                dispatch(getAllPosts());
                closemodal();
            } else {

            }
        }

    };


    //for homepost images updation
    const handleImagesChange = async (e) => {
        let files = Array.from(e.target.files);
        console.log("files", files)
        let imageArray = [];
        let videoArray = [];
        setUploading(true);

        for (let i = 0; i < files.length; i++) {
            const form = new FormData();
            form.append("image", files[i]);

            // Check if the file is an image
            if (files[i].type.startsWith('image/')) {
                let res = await HttpClientXml.fileUplode("image-upload", "POST", form);
                console.log("resnm", res)
                if (res?.status) {
                    toast.success("Image uploaded successfully");
                    imageArray?.push(res?.image);
                    setImages(imageArray);
                } else {
                    toast.error("Error uploading image");
                }
            }
            // Check if the file is a video
            else if (files[i]?.type?.startsWith('video/')) {
                let res = await HttpClientXml.fileUplode("image-upload", "POST", form);

                if (res?.status) {
                    toast.success("Video uploaded successfully");
                    videoArray.push(res?.image);
                    setVideo(videoArray)
                } else {
                    toast.error("Error uploading video");
                }
            }
            else {
                toast.error("Unsupported file type");
            }
        }
        setUploading(false);
    };


    //for advocacy and event post single image updation
    const handleImageChange = async (e) => {
        let image = e.target.files[0];
        setUploading(true);

        const form = new FormData();
        form.append("image", image);

        let res = await HttpClientXml.fileUplode("image-upload", "POST", form);

        if (res.status) {
            toast.success("Image uploaded successfully");
            setImage(res?.image);
        } else {
            toast.error("Unsupported image");
        }
        setUploading(false);
    };



    const { values, handleSubmit, resetForm, handleChange } =
        useFormik({
            initialValues,
            validateOnChange: shouldValidateOnChange,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });


    useEffect(() => {
        setImages(post?.image)
        setVideo(post?.video)
        setImage(post?.image)
    }, [])


    //for remove images or videos
    const removeImages = (index, type) => {
        if (type === 'image') {
            let images = [...image];
            images.splice(index, 1);
            setImages(images);
        } else if (type === 'video') {
            let videos = [...video];
            videos.splice(index, 1);
            setVideo(videos);
        }
    };

    return (
        <section className='thopughtmodabgmain'>
            <div className='ediotemodalbg'>
                <div className='editetopmodal'>
                    <p className='editetxtppbjdl'>Edit Post</p>
                    <div className='cersgeditpdg' onClick={() => closemodal()}>
                        <i class="fa-solid fa-xmark"></i>
                    </div>

                </div>
                <div className='editebottommodal'>
                    <form
                        onSubmit={(e) => {
                            setShouldValidateOnChange(true);
                            handleSubmit(e);
                        }}>
                        <div className='descriptttxtare'>
                            <p className='descrptiontxtpp'>Description</p>
                            <textarea
                                rows="4"
                                cols="50"
                                value={values[post?.docType === "eventData" ? 'notes' : 'description']}
                                name={post?.docType === "eventData" ? 'notes' : 'description'}
                                onChange={handleChange}
                            >

                            </textarea>
                        </div>


                        {
                            post?.docType === "homePost"
                                ?
                                (
                                    <>
                                        <div className="vdoulddivpbupldgnewmmll">
                                            <p className='descrptiontxtpp'>Image/Video Upload</p>
                                            <div className="bgcontainerupldboxsgwgrdiv">
                                                <div className="bgcrd">
                                                    <div className="bgcontaineruplddivnew">
                                                        <div className="logouplddiv">
                                                            <img
                                                                src={Addicon}
                                                                alt="..."
                                                            />
                                                            <div className="">
                                                                <input
                                                                    type="file"
                                                                    id=""
                                                                    className="upldimagediv"
                                                                    placeholder="Choose a photo"
                                                                    name="images"
                                                                    onChange={handleImagesChange}
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="upldtxtppdiv">Upload min 5 mb image</p>


                                                    <>
                                                        {uploading ? (
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                image loading...
                                                            </div>
                                                        ) : (
                                                            <div className="bgcontainerupldfiledivabslt">
                                                                {images &&
                                                                    images?.map((item, index) => {
                                                                        return (
                                                                            <div className="imageuplddiv" key={index}>
                                                                                <img src={item} alt="" />

                                                                                <div className="imageupldcrs">
                                                                                    <i
                                                                                        class="fa-solid fa-xmark"
                                                                                        onClick={() => removeImages(index, 'image')}
                                                                                    ></i>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}



                                                            </div>
                                                        )}


                                                        {uploading ? (
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                video uploading...
                                                            </div>
                                                        ) : (
                                                            <div className="bgcontainerupldfiledivabslt">
                                                                {video &&
                                                                    video?.map((item, index) => {
                                                                        return (
                                                                            <div className="imageuplddiv" key={index}>
                                                                                <video src={item} style={{ width: "90px", height: "90px" }} alt="" />

                                                                                <div className="imageupldcrs">
                                                                                    <i
                                                                                        class="fa-solid fa-xmark"
                                                                                        onClick={() => removeImages(index, 'video')}
                                                                                    ></i>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}


                                                            </div>
                                                        )}
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div className="vdoulddivpbupldgnewmmll">
                                            <p className='descrptiontxtpp'>Image Upload</p>
                                            <div className="bgcontainerupldboxsgwgrdiv">
                                                <div className="bgcrd">
                                                    <div className="bgcontaineruplddivnew">
                                                        <div className="logouplddiv">
                                                            <img
                                                                src={Addicon}
                                                                alt="..."
                                                            />
                                                            <div className="">
                                                                <input
                                                                    type="file"
                                                                    id=""
                                                                    className="upldimagediv"
                                                                    placeholder="Choose a photo"
                                                                    name="image"
                                                                    onChange={handleImageChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="upldtxtppdiv">Upload min 5 mb image</p>


                                                    <>
                                                        {uploading ? <p>image uploading......</p> : null}

                                                        {image !== "" && (
                                                            <div  className="imageuplddiv" >
                                                                <img
                                                                    src={image}
                                                                    alt="..."
                                                                />
                                                               <div className="imageupldcrs"
                                                                    onClick={() => {
                                                                        setImage("");
                                                                    }}
                                                                    style={{cursor:"pointer"}}
                                                                >
                                                                    <i class="fa-solid fa-xmark"
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>

                                                 

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                        }


                        <div className='updtbrnbmain'
                            type="submit"
                            onClick={submitHandler}
                        >
                            Update
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default PostEditmodal
