import React, { useState } from 'react'
import Addicon from "../../Images/Icons/PNG/Add icon.png";
import { Link } from 'react-router-dom';
import Edit from "../../Images/Icons/PNG/Edit.png";
import cancel from "../../Images/Icons/PNG/cancel.png";
const Bio = () => {
    const [editdlete,setEditdlete]=useState(false)
    const editdelyteclick =()=>{
        setEditdlete(!editdlete)
    }
    return (
        <>
            <div className='hobbisessectionmai'>
                <div className="top">
                    <div className="head bulleBoardHead">
                        <h4>Bio</h4>
                    </div>
                    <div className="add_seeallflx">
                        <div className="tyEvMainDiv" />
                        <div>
                            <Link to="/createbio"
                                className="seeAllAn"
                            >
                                <figure className="bulleBoardFig" >
                                    <img
                                        src={Addicon}
                                    />
                                </figure>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='biocardareadivbtm'>
                    <div className='biocardarea'>
                        <div className=''>
                            <p className='biotxtmanippyyy'>introduction1</p>
                            <p className='biotxtmanipp'>
                                Education :
                                <span>
                                    M.Tech
                                </span>
                            </p>
                            <p className='biotxtmanipp'>
                                Address :
                                <span>
                                    Kolkata,West Bengal
                                </span>
                            </p>
                            <p className='biotxtmanipp'>
                                Industry :
                                <span>
                                    IT
                                </span>
                            </p>
                        </div>
                        <div className='divdot' onClick={editdelyteclick}>
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                           {editdlete  && <div className='editdltdivflxmain'>
                                <div className='cancledibvimg'>
                                    <img src={Edit} alt='...' />
                                </div>
                                <div className='cancledibvimg'>
                                    <img src={cancel} alt='...' />
                                </div>
                            </div>}
                        </div>



                    </div>
                    <div className='biocardarea'>
                        <div className=''>
                            <p className='biotxtmanippyyy'>introduction1</p>
                            <p className='biotxtmanipp'>
                                Education :
                                <span>
                                    M.Tech
                                </span>
                            </p>
                            <p className='biotxtmanipp'>
                                Address :
                                <span>
                                    Kolkata,West Bengal
                                </span>
                            </p>
                            <p className='biotxtmanipp'>
                                Industry :
                                <span>
                                    IT
                                </span>
                            </p>
                        </div>
                        <div className='divdot' onClick={editdelyteclick}>
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                           {editdlete  && <div className='editdltdivflxmain'>
                                <div className='cancledibvimg'>
                                    <img src={Edit} alt='...' />
                                </div>
                                <div className='cancledibvimg'>
                                    <img src={cancel} alt='...' />
                                </div>
                            </div>}
                        </div>



                    </div>
                </div>
            </div>
        </>
    )
}

export default Bio
