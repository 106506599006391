import React, { useEffect, useRef, useState } from 'react'
import AddIcon from "../../Images/Icons/PNG/Add icon.png"
import HttpClientXml from '../../utils/HttpClientXml';
import HttpClient from '../../utils/HttpClientXml';
import toast from 'react-hot-toast';


const Principlefour = ({setPrincicpletabdrp, setTogglesectionmdl }) => {
    // const [text, setText] = useState('');
    const divRef = useRef(null);
    const [principleOneData, setPrincipleOneData] = useState({});

    const initvalue = {
        describeStakeholderGroup: "",
        boardFeedback: "",
        stakeHolderConsultation: {
            supportStatus: "Yes",
            details: ""
        },
        addressOfStakeHoldergroups: ""
    }

    const initial = {
        slNo: "",
        stakeHolderGroup: "",
        isVulnerableIdenty: "Yes",
        channelOfCommunication: "",
        frequency: "",
        purpose: ""
    }

    const [formvalue, setformvalue] = useState(initvalue);
    const [identyfiedKeyOfstakeholder, setidentyfiedKeyOfstakeholder] = useState([initial]);
    const [currentFY, setCurrentFY] = useState("");
    const [previousFY, setPreviousFY] = useState("");

    useEffect(() => {
        window.scroll(0,0);
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const startMonth = 4; // April is the start of the financial year

        if (currentDate.getMonth() + 1 >= startMonth) {
            setCurrentFY(`${currentYear}-${currentYear + 1}`);
            setPreviousFY(`${currentYear - 1}-${currentYear}`);
        } else {
            setCurrentFY(`${currentYear - 1}-${currentYear}`);
            setPreviousFY(`${currentYear - 2}-${currentYear - 1}`);
        }

    }, []);

    useEffect(() => {
        getPrincipleOne();
        // getPdf();
    }, [])

    //for getting principleone
    const getPrincipleOne = async () => {
        const res = await HttpClientXml?.requestData(`get-principle`, {}, "GET");
        if (res?.status) {
            setPrincipleOneData(res?.data?.[0]);
        }
    }

    //for Input fields
    const handleInput = (category, field, value) => {
        setformvalue((prevValue) => ({
            ...prevValue,
            [category]: {
                ...prevValue[category],
                [field]: value,
            }
        }));
    }

    //handle change for briefOfSummery add more functionality
    const handleChange = (i, e, field) => {
        let newFormValues = [...identyfiedKeyOfstakeholder];
        newFormValues[i][field] = e.currentTarget.textContent;
        setidentyfiedKeyOfstakeholder(newFormValues);
    };


    //for first add more functionality
    const addFormFields = () => {
        setidentyfiedKeyOfstakeholder([...identyfiedKeyOfstakeholder, initial]);
    };

    //for removing add more field
    // const removeFormFields = (i) => {
    //     let newFormValues = [...identyfiedKeyOfstakeholder];
    //     newFormValues.splice(i, 1);
    //     setidentyfiedKeyOfstakeholder(newFormValues);
    // };


    // const getPdf = async () => {
    //     const res = await HttpClient?.requestData(`all-principle-pdf`, {}, "GET");
    //     console.log("downloadurl",res)
    //     if (res?.status) {
    //         // setDownloadurl(res?.data);
    //         downloadPdf(res?.data)
    //     }
    // }

    // console.log("downloadurl",downloadurl)
    // const downloadPdf = (downloadurl) => {
    //     const link = document.createElement('a');
    //     link.href = downloadurl;
    //     link.download = 'all.pdf';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    const submitHandler = async (e) => {
        e.preventDefault();
        let data = {};
        data = {
            principleFour: {
                currentYear: currentFY,
                previousYear: previousFY,
                essentialIndicators: {
                    describeStakeholderGroup: formvalue?.describeStakeholderGroup,
                    identyfiedKeyOfstakeholder: identyfiedKeyOfstakeholder
                },
                leadershipIndicators: {
                    boardFeedback: formvalue?.boardFeedback,
                    stakeHolderConsultation: {
                        supportStatus: formvalue?.stakeHolderConsultation?.supportStatus,
                        details: formvalue?.stakeHolderConsultation?.details
                    },
                    addressOfStakeHoldergroups: formvalue?.addressOfStakeHoldergroups
                }
            }
        }
        console.log('principlefour', data);
        const res = await HttpClient?.requestData(
            `update-enviroment-principle/${principleOneData?._id}`,
            data,
            "POST"
        );
        if (res?.status) {
            toast?.success("Principle-4 is added successfully");
            // navigate('/pricipletwotemp')
            setformvalue(initvalue);
            const contentEditableElements = document.querySelectorAll('[contentEditable]');
            contentEditableElements?.forEach(element => element.textContent = "");
            setidentyfiedKeyOfstakeholder([initial]);
            setPrincicpletabdrp(5);
            setTogglesectionmdl(true)
            // getPdf();
        } else {
            toast?.error(res?.response?.data?.message || "Something went wrong");
        }
    }

    const [notemodal2, setnotemodal2] = useState(false)


    return (
        <>
            <div className='' >
                <h4 className='principletxtpp'>
                    PRINCIPLE 4: Businesses should respect the interests of and be
                    responsive to all its stakeholders
                </h4>
                <div className='borderessentialin'>
                    <p className='idicatrstxtpp'> Essential Indicators</p>
                    <div className=''>
                        <ul className='listoftblxsdivul'>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Describe the processes for identifying key stakeholder groups of the entity.
                                </li>
                                <div className="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                describeStakeholderGroup: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>

                            </div>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    List stakeholder groups identified as key for your entity and the frequency of
                                    engagement with each stakeholder group.
                                </li>
                                <table className='tablbrdrmain'>
                                    <tr className='trdivmainbrdr'>
                                        <th className='thdivmainbrdr prikjmjmsdibbsjnttsfbkdivfst'>
                                            <p className='prcnttxttemplytxtpp'>
                                                Sl.
                                                No
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prikjmjmsdibbsjnttsfbkdivscnd'>
                                            <p className='prcnttxttemplytxtpp'>Stakeholder
                                                Group
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prikjmjmsdibbsjnttsfbkdivthrd'>
                                            <p className='prcnttxttemplytxtpp'>Whether
                                                identified as
                                                Vulnerable &
                                                Marginalized
                                                Group
                                                (Yes/No)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prikjmjmsdibbsjnttsfbkdivtfrth' >
                                            <p className='prcnttxttemplytxtpp'>Channels of
                                                communication
                                                (Email, SMS,
                                                Newspaper,
                                                Pamphlets,
                                                Advertisement,
                                                Community
                                                Meetings,
                                                Notice Board,
                                                Website), Other
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prikjmjmsdibbsjnttsfbkdivffth'>
                                            <p className='prcnttxttemplytxtpp'>Frequency of
                                                engagement
                                                (Annually/ Half
                                                yearly/
                                                Quarterly /
                                                others – please
                                                specify)
                                            </p>
                                        </th>
                                        <th className='thdivmainbrdr prikjmjmsdibbsjnttsfbkdivsxth'>
                                            <p className='prcnttxttemplytxtpp'>Purpose and scope
                                                of engagement
                                                including key
                                                topics and
                                                concerns raised
                                                during such
                                                engagement
                                            </p>
                                        </th>
                                    </tr>

                                    {
                                        identyfiedKeyOfstakeholder?.map((item, ind) => {
                                            return (
                                                <tr>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChange(ind, e, "slNo")}
                                                        >
                                                        </div>
                                                    </td>

                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChange(ind, e, "stakeHolderGroup")}
                                                        >
                                                        </div>
                                                    </td>

                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChange(ind, e, "isVulnerableIdenty")}
                                                        >
                                                        </div>
                                                    </td>

                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChange(ind, e, "channelOfCommunication")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChange(ind, e, "frequency")}
                                                        >
                                                        </div>
                                                    </td>
                                                    <td className='tddivmainbrdr'>
                                                        <div
                                                            ref={divRef}
                                                            contentEditable
                                                            className='textardibbrdrdivmain'
                                                            onInput={(e) => handleChange(ind, e, "purpose")}
                                                        >
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                    {/* <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                        <td className='tddivmainbrdr'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'>
                                                {text}
                                            </div>
                                        </td>
                                    </tr> */}

                                </table>


                                <div className='notedivadmoredivflx'>
                                    <div className=''>
                                        <p className='notetgvxtygfspopphjhsttxp' style={{ cursor: "pointer" }} onClick={() => setnotemodal2(!notemodal2)}>Note  </p>
                                    </div>

                                    <button className='adddinciondivimg'>
                                        <img src={AddIcon} alt='...' onClick={addFormFields} />
                                        <span>Add More</span>
                                    </button>
                                </div>

                                {notemodal2 && <div className="yestrxtwarea mt-2 mb-0">

                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                secondnote: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>}

                            </div>
                        </ul>
                    </div>
                </div>

                <div className='borderessentialin'>
                    <p className='idicatrstxtpp'>Leadership Indicators
                    </p>
                    <div className=''>
                        <ul className='listoftblxsdivul'>
                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Provide the processes for consultation between stakeholders and the Board on
                                    economic, environmental, and social topics or if consultation is delegated, how is
                                    feedback from such consultations provided to the Board.
                                </li>
                                <div className="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                boardFeedback: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>

                            </div>

                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Whether stakeholder consultation is used to support the identification and
                                    management of environmental, and social topics <span> (Yes / No)
                                    </span>. If so, provide details of
                                    instances as to how the inputs received from stakeholders on these topics were
                                    incorporated into policies and activities of the entity.
                                </li>

                                <div className=''>
                                    <div >
                                        <div className='yesnobtndivmain'>
                                            <div className='raditrbtntxt'>
                                                <input type="radio" id="" name=""
                                                    value={"Yes"}
                                                    checked={formvalue?.stakeHolderConsultation?.supportStatus === "Yes"}
                                                    onChange={(e) => {
                                                        setformvalue({
                                                            ...formvalue,
                                                            stakeHolderConsultation: {
                                                                ...formvalue.stakeHolderConsultation,
                                                                supportStatus: "Yes"
                                                            }
                                                        });
                                                    }}
                                                />
                                                <label htmlFor="">Yes</label>
                                            </div>

                                            <div className='raditrbtntxt'>
                                                <input type="radio" id="" name=""
                                                    value={"No"}
                                                    checked={formvalue?.stakeHolderConsultation?.supportStatus === "No"}
                                                    onChange={(e) => {
                                                        setformvalue({
                                                            ...formvalue,
                                                            stakeHolderConsultation: {
                                                                ...formvalue.stakeHolderConsultation,
                                                                supportStatus: "No"
                                                            }
                                                        });
                                                    }}
                                                />
                                                <label htmlFor="">No</label>
                                            </div>

                                        </div>
                                        <div className='yestrxtwarea'>
                                            <div
                                                ref={divRef}
                                                contentEditable
                                                className='textardibbrdrdivmain'
                                                onInput={(e) => handleInput('stakeHolderConsultation', 'details', e.currentTarget.textContent)}
                                            >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='listoftblxsdivuldiv'>
                                <li className='listoftblxsdiv'>
                                    Provide details of instances of engagement with, and actions taken to, address the
                                    concerns of vulnerable/ marginalized stakeholder groups.
                                </li>
                                <div className="yestrxtwarea">
                                    <div
                                        ref={divRef}
                                        contentEditable
                                        className='textardibbrdrdivmain'
                                        onInput={(e) => {
                                            setformvalue({
                                                ...formvalue,
                                                addressOfStakeHoldergroups: e.currentTarget.textContent
                                            });
                                        }}
                                    >
                                    </div>
                                </div>

                            </div>
                        </ul>
                    </div>
                </div>

                <div className="homePgCreModSubmitDiv">
                    <button
                        className="homePgCreModSubmitBtn btn1"
                        type="button"
                        onClick={submitHandler}
                    >
                        Next
                    </button>
                </div>
            </div>

        </>
    )
}

export default Principlefour
