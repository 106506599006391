import React, { useEffect, useState } from 'react'
import Select from "react-select";
import Happybirthdaycard from "../../src/Images/Happybirthdaycard.png";
import avatar from "../../src/Images/avatar.jpg";
import toast from 'react-hot-toast';
import HttpClientXml from '../utils/HttpClientXml';
import makeAnimated from "react-select/animated";
import { useFormik } from 'formik';

const animatedComponents = makeAnimated();

const BirthdayCardmodal = ({ closemodal, initialValues }) => {
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [shouldValidateOnBlur, setShouldValidateOnBlur] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [colourOptions, setColourOptions] = useState([]);
    const [empData, setempData] = useState([]);

    //for getting internal trainer name
    const getEmployeeData = async () => {
        const response = await HttpClientXml?.requestData(`view-all-employees`, {}, "GET");

        if (response && response.status) {
            const formattedOptions = response?.data?.map((item) => ({
                value: item?._id,
                label: item?.email,
                image: item?.image
            }));
            setColourOptions(formattedOptions);
            setempData(response?.data);

        } else {

        }
    };

    const handleSelectChange = (e) => {
        setSelectedOptions(e);
    };


    const submitHandler = async (e) => {
        e.preventDefault();
        setShouldValidateOnChange(true);
        setShouldValidateOnBlur(true);

        let data = {};
        if (values.cardName === "") {
            return toast.error("Card Name is required");
        }

        if (values.cardDesc === "") {
            return toast.error("Card Description is required");
        }

        if (!selectedOptions?.label) {
            return toast.error("Receiver Mail is required");
        }
        if (!selectedOptions?.image) {
            return toast.error("Receiver Image is required");
        }

        data = {
            cardName: values?.cardName,
            cardDesc: values?.cardDesc,
            receiverMail: selectedOptions?.label,
            receiverImage: selectedOptions?.image
        };

        const res = await HttpClientXml?.requestData(`user-send-giftcard`, data, "POST");

        if (res?.status) {
            // console.log(res,"datadata")
            toast.success("Send Successfully");
            resetForm();
            closemodal();
        } else {

        }
    };

    useEffect(() => {
        getEmployeeData();
    }, [])


    const { values, resetForm, handleChange } =
        useFormik({
            initialValues,
            validateOnChange: shouldValidateOnChange,
            validateOnBlur: shouldValidateOnBlur,
            onSubmit: (val) => {
                console.log("val", val);
            },
        });

    const handlestopClose = (e) => {
        e.stopPropagation()
    }
    return (
        <>
            <section className='thopughtmodabgmain' onClick={() => closemodal()}>
                <div className='allcardonebigbg' onClick={(e) => handlestopClose(e)}>
                    <div className="allcardsectionbgonecard" >
                        <div className=''>
                            <form>
                                <div className='toinpyrgpltpp'>
                                    {/* <input type='text' placeholder='to...' /> */}
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue={[]}
                                        options={colourOptions}
                                        onChange={handleSelectChange}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className='thankyoucrdbg' style={{ backgroundImage: `url(${Happybirthdaycard})` }}>
                            {/* <div className='companylitlleimg'>
                                <img src={companyelite} alt="..." />
                            </div> */}
                            <div className='happybrthdivmain'>
                                <p className='happytxtcrdpp'>Happy</p>
                                <h4 className='birthtxtcrdpp'>Birthday</h4>
                            </div>
                            <div className='profilebgthankywh'>
                                <div className='profilebgthankyimg'>
                                    {/* <img
                                        src={avatar}
                                        alt="..."
                                    /> */}
                                    {
                                        selectedOptions?.image ? (<img src={selectedOptions?.image} alt="..." />) : (<img src={"https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png"} alt="..." />)
                                    }

                                </div>
                            </div>
                            <div className='happybrthdivmain'>
                                {/* <p className='happytxtcrdppname'>Prity Sinha</p> */}
                                <h4 className='birthtxtcrdpphead'>{values?.cardDesc}</h4>
                            </div>
                        </div>
                        <div className=''>
                            <form>
                                <div className='toinpyrgpltpp'>
                                    {/* <input type='text' placeholder='for...' /> */}
                                    <input
                                        type='text'
                                        placeholder='for...'
                                        name='cardDesc'
                                        value={values?.cardDesc}
                                        onChange={handleChange}
                                    />

                                </div>
                            </form>
                        </div>
                        <div className='sndbtnmaibtnkk'>
                            <button
                                type='button'
                                className='sndbtnmaibbg'
                                onClick={submitHandler}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BirthdayCardmodal
