import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import HttpClientXml from '../../utils/HttpClientXml';

const Initiativesingle = () => {
    const [singleView, setSingleView] = useState({});
    const params = useParams();
   

    //for view Single Page
    const viewSinglepost = async () => {
        const response = await HttpClientXml?.requestData(`view-single-initiative/${params?.id}`, {}, "GET")
        if (response.status) {
            setSingleView(response?.data?.[0]);
        } else {

        }
    }

    useEffect(() => {
        viewSinglepost();
    }, [params?.id])

    return (
        <>
            <section className='initiativesinglesection'>
                <div className='detailspagecontainer'>
                    <div className='initiatvesinglebg'>
                        <div className=''>
                            <p className='builletindtlstsxtpp'>
                                Name :
                                <span>
                                    {singleView?.nameOfInitaitive}
                                </span>
                            </p>
                        </div>
                        <div className=''>
                            <p className='builletindtlstsxtpp'>

                                Reward Points :

                                <span>
                                    {singleView?.rewardPoints}
                                </span>
                            </p>
                        </div>
                        <div className=''>
                            <p className='builletindtlstsxtpp'>
                                Description :
                                <span>
                                    {singleView?.description}
                                </span>
                            </p>
                        </div>
                        <div className=''>
                            <p className='builletindtlstsxtpp'>
                                Start Date :
                                <span>
                                    {singleView?.startDate}
                                </span>
                            </p>
                        </div>
                        <div className=''>
                            <p className='builletindtlstsxtpp'>
                                End Date :
                                <span>
                                    {singleView?.endDate}
                                </span>
                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Initiativesingle
