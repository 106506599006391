
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainLayout from './Layout/MainLayout';
import Engage from './View/Engage/Index';
import Educate from "./View/Educate/Index";
import Ensure from "./View/Ensure/Index"
import SingleMandatoryCourse from './View/Educate/SingleMandatoryCourse';
import Bulletindetails from './View/Engage/Bulletindetails';
import Halloffamedetails from './View/Engage/Halloffamedetails';
import Initiativedetails from './View/Engage/Initiativedetails';
import EventDetails from './View/Engage/EventDetails';
import Bulletinsingle from "./View/Engage/Bulletinsingle"
import EventSingle from './View/Engage/EventSingle';
import CreateEvent from './View/Engage/CreateEvent';
import CreateBulletin from './View/Engage/CreateBulletin';
import Initiativesingle from './View/Engage/Initiativesingle';
import CreateInitiative from './View/Engage/CreateInitiative';
import PrivateRoute from './View/privateRouter/PrivateRoute';
import Login from './View/Login/Login';
import CreateTraining from './View/Educate/CreateTraining';
import OngoingDetails from './View/Educate/OngoingDetails';
import RequestedTrainingsingle from './View/Educate/RequestedTrainingsingle';
import Singleskilldevelopment from './View/Educate/Singleskilldevelopment';
import Singlenontech from './View/Educate/Singlenontech';
import Singlemicrotraining from './View/Engage/Singlemicrotraining';
import Detailsdocumentrepository from './View/Educate/Detailsdocumentrepository';
import Createteam from './View/Engage/Createteam';
import Myaffinitygroup from './View/Engage/Myaffinitygroup';
import Myaffinitydetails from './View/Engage/Myaffinitydetails';
import Articlepage from "./View/Educate/Articlepage"
import Fullarticleviewsingle from './View/Educate/Fullarticleviewsingle';
import Editteam from './View/Engage/Editteam';
import EditInitiative from './View/Engage/EditInitiative';
import EnlargedNewEnrollment from './View/Educate/EnlargedNewEnrollment';
import SingleTeam from './View/Engage/SingleTeam';
import CreateClaimpoints from './View/Engage/CreateClaimpoints';
import Myteamgropusingle from './View/Engage/Myteamgropusingle';
import Advocacy from './View/Engage/Advocacy';
import CreateAdvocacy from './View/Engage/CreateAdvocacy';
import Singleadvocacy from './View/Engage/Singleadvocacy';
import SinglePage from './View/Engage/SinglePage';
import CreateDocumentRepository from './View/Educate/CreateDocumentRepository';
import Profile from './View/Engage/Profile';
import EditEvent from './View/Engage/EditEvent';
import CreateHobbies from './View/Engage/CreateHobbies';
import CreateBio from './View/Engage/CreateBio';
import CreateSkillValidation from './View/Engage/CreateSkillValidation';
import CreateProfile from './View/Engage/CreateProfile';
import Connection from './View/Engage/Connection';
import Allcertificatemain from './View/Allcerificate/Allcertificatemain';
import Certificateofeducation from './View/Allcerificate/Certificateofeducation';
import Certificateexcellence from './View/Allcerificate/Certificateexcellence';
import Certificateengagement from './View/Allcerificate/Certificateengagement';
import Certificateofeloquence from './View/Allcerificate/Certificateofeloquence';
import Quizemain from './View/Educate/Quizemain';
import Principleonetemp from './Component/Principleonetemp';
import Coursevideoplayer from './View/Educate/Coursevideoplayer';
import Message from './View/Message/Message';
import TrainingCreatePage from './View/Educate/TrainingCreatePage';
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<MainLayout />}>
              <Route path="/" element={<Engage />} />
              <Route path="singlepage/:doc/:postId" element={<SinglePage />} />
              <Route path="/educate" element={<Educate />} />
              <Route path="/SingleMandatoryCourse" element={<SingleMandatoryCourse />} />
              <Route path="/ensure" element={<Ensure />} />
              <Route path="/bulletindetails" element={<Bulletindetails />} />
              <Route path="/halloffamedetails" element={<Halloffamedetails />} />
              <Route path="/initiativedetails" element={<Initiativedetails />} />
              <Route path="/eventdetails" element={<EventDetails />} />
              <Route path="/bulletinsingle" element={<Bulletinsingle />} />
              <Route path="/eventSingle" element={<EventSingle />} />
              <Route path="/createevent" element={<CreateEvent />} />
              <Route path="/editevent" element={<EditEvent />} />
              <Route path="/createbulletin" element={<CreateBulletin />} />
              <Route path="/initiativesingle/:id" element={<Initiativesingle />} />
              <Route path="/createinitiative" element={<CreateInitiative />} />
              <Route path='/editinitiative/:id' element={<EditInitiative />} />
              {/* <Route path="/createtraining" element={<CreateTraining />} /> */}
              <Route path="/createtraining" element={<TrainingCreatePage />} />
              <Route path="/ongoingdetails" element={<OngoingDetails />} />
              <Route path="/requestedTrainingsingle" element={<RequestedTrainingsingle />} />
              <Route path="/singleskilldevelopment" element={<Singleskilldevelopment />} />
              <Route path="/singlemicrotraining" element={<Singlemicrotraining />} />
              <Route path="/singlenontech" element={<Singlenontech />} />
              <Route path="/detailsdocumentrepository" element={<Detailsdocumentrepository />} />
              <Route path="/createteam" element={<Createteam />} />
              <Route path='/editteam/:id' element={<Editteam />} />
              <Route path='/team/:id' element={<SingleTeam />} />
              <Route path="/articlepage" element={<Articlepage />} />
              <Route path="/fullarticleviewsingle" element={<Fullarticleviewsingle />} />
              <Route path="/myaffinitygroup" element={<Myaffinitygroup />} />
              <Route path="/myaffinitydetails" element={<Myaffinitydetails />} />
              {/* <Route path="/enlargedNewEnrollment" element={<EnlargedNewEnrollment />} /> */}
              <Route path="enlargedNewEnrollment/:title/:id" element={<EnlargedNewEnrollment />} />
              <Route path="/createClaimpoints" element={<CreateClaimpoints />} />
              <Route path="/myteamgropusingle" element={<Myteamgropusingle />} />
              <Route path="/advocacy" element={<Advocacy />} />
              <Route path="/createAdvocacy" element={<CreateAdvocacy />} />
              <Route path="/singleadvocacy" element={<Singleadvocacy />} />
              <Route path="/createDocumentRepository" element={<CreateDocumentRepository />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/createhobbies" element={<CreateHobbies />} />
              <Route path="/createbio" element={<CreateBio />} />
              <Route path="/createskillvalidation" element={<CreateSkillValidation />} />
              <Route path="/createProfile" element={<CreateProfile />} />
              <Route path="/connection" element={<Connection />} />
              <Route path="/message" element={<Message />} />
              <Route path="/allcertificatemain" element={<Allcertificatemain />} />
              <Route path="/quizemain" element={<Quizemain />} />
              <Route path="/courseVideoPlayer/:title/:tId/:id" element={<Coursevideoplayer />} />
            </Route>
          </Route>
          <Route path="/certificateofeducation" element={<Certificateofeducation />} />
          <Route path="/certificateexcellence" element={<Certificateexcellence />} />
          <Route path='/certificateengagement' element={<Certificateengagement />} />
          <Route path="/Certificateofeloquence" element={<Certificateofeloquence />} />
          <Route path="/pricipleonetemp" element={<Principleonetemp />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
