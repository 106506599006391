import React from 'react'
import trainingmain2 from "../../Images/trainingmain2.png";
import Points from "../../Images/Icons/PNG/Points.png"
const EducateOngoing = () => {
    const educateongoing = [
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "Language : English",
            percentage: "75",
            points: "100",
            imagepoints: Points,

        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "Language : English",
            percentage: "75",
            points: "100",
            imagepoints: Points,
        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "Language : English",
            percentage: "75",
            points: "100",
            imagepoints: Points,
        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "Language : English",
            percentage: "75",
            points: "100",
            imagepoints: Points,
        },
        {
            image: trainingmain2,
            text: "Lorem Ipsum",
            notification: "Language : English",
            percentage: "75",
            points: "100",
            imagepoints: Points,
        },

    ]
    return (
        <>
            {educateongoing.map((item, index) => {
                return(
                <div className='videoongoinddiv' key={index}>
                    <div className=''>
                        <div className='video_onflx'>
                            <div className='trainingmainimgdiv'>
                                <img src={item.image} alt="..." />
                            </div>
                            <div className='playtextbtnvwwh'>
                                <div className='playtxtflx'>
                                    <p className='lrmtxtpp'>{item.text}</p>
                                    <div className=''>
                                        <button className='bg-play'>
                                            Play
                                        </button>
                                    </div>

                                </div>
                                <div className=''>
                                    <p className="dotstxtpp">{item.notification}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="prgrsbartxtdivpading">
                        <div className='prgrsbartxtdiv'>
                            <div className=''>
                                <p className='prgrstextper'>{item.percentage}%</p>
                            </div>
                            <div className='imgtxtppdivprgrs'>
                                <div className='prgrsdivimg'>
                                    <img src={item.imagepoints} alt="..." />
                                </div>
                                <p className='prgrstextper'>{item.points}</p>
                            </div>
                        </div>

                        <div className="borderprgs">
                            <div className="brdrgrey" style={{ height: "6px", width: "40%" }}>
                            </div>
                        </div>
                    </div>
                </div>
                )
            })}

        </>
    )
}

export default EducateOngoing
